angular.module('RocketWash').directive(
  'rwNav',
  () => ({
    restrict: 'E',
    templateUrl: 'directives/rw-nav/rw-nav.slim',
    controller: ($scope, $state, userSession, accessMenuService) => {
      $scope.stateBasename = () => $state.current.name.split('.')[2];
      $scope.stateTabName = () => $state.current.name.split('.')[3];

      $scope.$watch(() => $state.current.name, () => {
        const tabs = $state.current.data && $state.current.data.tabs || [];
        $scope.tabs = tabs.filter((tab) => {
          const state = $state.get(`app.${userSession.controllerScope()}.${$scope.stateBasename()}.${tab}`);
          return accessMenuService.canVisit(state);
        });
        $scope.$evalAsync(() => {
          $scope.tabIndex = $scope.tabs.indexOf($scope.stateTabName());
        });
      });

      $scope.goToTab = (tab) => {
        $scope.tabIndex = $scope.tabs.indexOf(tab);
      }

      $scope.$watch('tabIndex', () => {
        const tab = $scope.tabs[$scope.tabIndex];
        if (tab) {
          $state.go(`app.${userSession.controllerScope()}.${$scope.stateBasename()}.${tab}`);
        }
      });
    },
  }),
);
