angular.module('RocketWash').config(($stateProvider) => {
  return $stateProvider.state('app.taxi.analytics.external_payments', {
    url: '/external_payments',
    tab: 'external_payments',
    data: {
      permission: 'ExternalPaymentsTab',
    },
    views: {
      'content@app.taxi.analytics': {
        controller: 'TaxiAnalyticsExternalPaymentsIndexController',
        templateUrl: 'pages/taxi/analytics/external_payments/index.slim',
      },
    },
  });
});

