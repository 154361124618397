angular.module('RocketWash').controller(
  'TaxiSettingsIntegrationsController',
  (
    $scope, $route, $location, $window,
    userSession, SettingValue, serverConstants, Organization, Sms,
  ) => {
    $scope.searchQuery = '';
    $scope.predicate = 'name';
    $scope.reverse = true;
    $scope.progress = { balance: false };

    $scope.loadOrganization = () => {
      Organization.get(userSession.organization.id).then((organization) => {
        $scope.organization = organization;
      });
    };

    SettingValue.query().then((settings) => {
      $scope.settings = settings.filter(x => x.keyType === 'CustomSmsGatewaySettingKey');
      $scope.useSetting = $scope.settings.find(x => x.key === 'use_custom_sms_gateway');
      $scope.useSetting.readonly = !$scope.userSession.organization.custom_sms_gateway_available;
      $scope.loginSetting = $scope.settings.find(x => x.key === 'custom_sms_gateway_login');
      $scope.passwordSetting = $scope.settings.find(x => x.key === 'custom_sms_gateway_password');
    });

    $scope.SETTING_VALUES = serverConstants.setting_values;

    $scope.save = function (setting) {
      setting.save().then(() => {
        userSession.reload();
        $scope.refreshGatewayBalance();
        $route.reload();
      });
    };

    $scope.refreshGatewayBalance = () => {
      $scope.progress.balance = true;
      Sms.$get('wash/sms/balance').then((data) => {
        $scope.balance = data.balance;
        $scope.useCustomSmsGateway = data.useCustomSmsGateway;
        $scope.customSmsGatewayBalance = data.customSmsGatewayBalance;
        $scope.gatewayConnectionError = $scope.customSmsGatewayBalance === null;
      }).finally(() => { $scope.progress.balance = false; });
    };

    $scope.loadOrganization();

    $scope.refreshGatewayBalance();
  },
);
