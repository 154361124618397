// workaround for resources which /index route responds with:
// { items: [], total: {count: } }
angular.module('RocketWash').factory(
  'PaginatedQueryInterceptor',
  () => ({
    beforeResponse: (response, resourceConstructor, instance) => {
      if (response.data.items) {
        response.data = response.data.items;
      }
      console.log(response);
      return response;
    },
  }),
);
