angular.module('RocketWash').service('serverConstants', ($http) => {
  const constants = {};

  // I know it's smells but whatever
  constants.promise = $http.get('/constants').then((resp) => {
    angular.extend(constants, resp.data);

    constants.car_makes = _.sortBy(constants.car_makes, 'name');
    constants.car_makes.forEach((cm) => {
      cm.car_models = _.sortBy(cm.car_models, 'name');
    });
    constants.indexed_car_makes = _.keyBy(constants.car_makes, 'id');
  });

  constants.getCarMake = (id) => {
    return constants.indexed_car_makes[id];
  };constants

  return constants;
});
