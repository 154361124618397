angular.module('RocketWash').config(($stateProvider) => {
  $stateProvider.state('app.admin.charts.balances', {
    url: '/balances',
    tab: 'balances',
    views: {
      'content@app.admin.charts': {
        controller: 'AdminChartsBalancesController',
        templateUrl: 'pages/admin/charts/balances/index.slim',
      },
    },
  });
});
