angular.module('RocketWash').controller(
  'AdminOrganizationsSettingsFinancialCentersController',
  (
    $scope, $compile, $translate, $stateParams,
    Organization, FinancialCenter, PaymentType, LegalEntity, ServiceLocation, ApiKey, Device
  ) => {
    FinancialCenter.tenantId = $stateParams.orgId;
    LegalEntity.tenantId = $stateParams.orgId;
    ApiKey.tenantId = $stateParams.orgId;

    $scope.pts = [];
    $scope.sls = [];
    $scope.les = [];

    Organization.get($stateParams.orgId).then((org) => {
      $scope.org = org;
    });
    PaymentType.query().then((pts) => {
      $scope.pts = pts;
    });
    LegalEntity.query({ organization_id: $stateParams.orgId }).then((les) => {
      $scope.les = les;
    });
    ServiceLocation.query({
      organization_id: $stateParams.orgId,
    }).then((sls) => {
      $scope.sls = sls;
    });
    FinancialCenter.query({
      organization_id: $stateParams.orgId,
      type: 'FinancialCenterForWashAndLegalEntity',
    }).then((fcs) => {
      $scope.fcs = fcs;
    });
    Device.query({ organization_id: $stateParams.orgId }).then(devices => {
      $scope.devices = devices

      devices.forEach(device => {
        $scope.deviceIdentifiers.push({
          name: device.name,
          deviceIdentifier: _.snakeCase(device.type.slice(0, -6)),
          value: device.id
        })
      })
    })

    $scope.new = () => {
      const newFc = new FinancialCenter({
        organizationId: $scope.org.id,
        type: 'FinancialCenterForWashAndLegalEntity',
        amount: 0,
      });
      $scope.fcs.unshift(newFc);
    };
    $scope.onSlUpdate = (fc) => {
      fc.serviceLocationIds = fc.serviceLocationIds || [];
      if (!fc.serviceLocationIds.includes(fc.serviceLocationId)) {
        fc.serviceLocationIds.push(fc.serviceLocationId);
      }
      $scope.save(fc);
    };
    $scope.save = (fc) => {
      fc.save();
    };
    $scope.delete = (fc) => {
      fc.delete().then(() => {
        $scope.fcs = $scope.fcs.filter(x => x !== fc);
      });
    };

    $scope.ptNames = (ptIds) => {
      const names = (ptIds || []).map((id) => {
        const pt = $scope.pts.find(x => x.id === id);
        return pt && pt.name;
      });
      return names.join(', ');
    };
    $scope.slName = (id) => {
      const sl = $scope.sls.find(x => x.id === id);
      return sl && sl.name;
    };
    $scope.leName = (id) => {
      const le = $scope.les.find(x => x.id === id);
      return le && le.name;
    };

    $scope.deviceIdentifiers = [
      { name: 'Не указан', value: '', id: null },
    ];

    ApiKey.query().then((keys) => {
      keys.forEach((key) => {
        if (key.type == 'ApiKeyForElectronicCashier') {
          $scope.deviceIdentifiers.push({
            name: `Эл. кассир ${key.name || key.value || '-'}`,
            value: `api_key_for_electronic_cashier ${key.value}`,
          });
        };
      });
    });
  },
);
