angular.module('RocketWash').config(function ($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.income.salary_bonus', {
    url: '/salary_bonus',
    tab: 'salary_bonus',
    data: {
      permission: 'SalaryBonusTab'
    },
    views: {
      'content@app.wash.income': {
        controller: 'WashIncomeSalaryBonusController',
        templateUrl: 'pages/wash/income/salary_bonus/index.slim'
      }
    }
  }).state('app.wash.income.salary_bonus.form', {
    url: '/form',
    views: {
      'content@app.wash.income': {
        controller: 'WashIncomeSalaryBonusFormController',
        templateUrl: 'pages/wash/income/salary_bonus/form.slim'
      }
    }
  });
});
