angular.module('RocketWash').directive('rwWebcam', (webcamService) => {
  return {
    template: '<div class="webcam" ng-transclude></div>',
    restrict: 'E',
    replace: true,
    transclude: true,
    scope:
      {
      onError: '&',
      onStream: '&',
      onStreaming: '&',
      placeholder: '=',
    },
    link: function postLink($scope, element) {
      let videoElem = document.querySelector('#rw-webcam-live-video');

      const logError = (error) => {
        console.log(error);
        webcamService.showOverlay = false;
      }

      webcamService.stop = () => {
        console.log('Stopping cameras');
        if (webcamService.webcam.stream) {
          webcamService.webcam.stream.getTracks().forEach(track => {
            track.stop();
          });
          webcamService.webcam.stream = undefined;
          webcamService.webcam.video = undefined;
        }
      }

      let gotStream = (stream) => {
        window.stream = stream;
        console.log('Got camera stream', stream);
        webcamService.webcam.stream = stream;
        videoElem.srcObject = stream;
        webcamService.webcam.video = videoElem;
      }

      let constraints = {
        video: {
          deviceId: {
            exact: undefined
          }
        }
      };
      window.constraints = constraints;

      webcamService.start = (snapshotCallback) => {
        webcamService.stop();

        webcamService.snapshotCallback = snapshotCallback;

        console.log('Starting camera');

        const getMedia = () => navigator.mediaDevices.getUserMedia(constraints).then(gotStream).catch(logError);

        if (constraints.video.deviceId.exact) {
          console.log('Fast');
          getMedia();
        } else {
          console.log('Slow');
          // Get user approval
          navigator.mediaDevices.getUserMedia({video: true}).then((stream) => {
            webcamService.webcam.stream = stream;
            webcamService.stop();

            // Select back camera
            navigator.mediaDevices.enumerateDevices().then((devices) => {
              window.devices = devices;
              const backCamera = devices.find(device => device.label.search(/back/i) >= 0);
              const firstVideoDevice = devices.find(device => device.kind == 'videoinput');

              const videoDevice = backCamera || firstVideoDevice;
              constraints.video.deviceId.exact = videoDevice.deviceId;

              // Get max resolution
              navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
                const track = stream.getVideoTracks()[0];
                const capabilities = track.getCapabilities();
                const width = Math.min(capabilities.width.max, 1920);
                const height = Math.min(capabilities.height.max, 1080);

                webcamService.webcam.stream = stream;
                webcamService.stop();

                constraints.video.width = {ideal: width};
                constraints.video.height = {ideal: height};

                getMedia();
              }).catch(logError);
            }).catch(logError);
          }).catch(logError);
        }
      }

      $scope.$on('$destroy', webcamService.stop);

    }
  };
});
