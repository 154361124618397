angular.module('RocketWash').controller('WashChartsServicesStructureController', function($scope, $rootScope, $http, $translate, $timeout, $q, CarType, userSession) {
  var ctReady, dataReady;
  const carType = { name: "Группа типов кузова" }
  $rootScope.loading = 'services_structure';
  $scope.updateChartData = (drawOptions) => {
    ctReady = CarType.query({ with_inactive: true }).then(function (carTypes) {
      carTypes.unshift(carType)
      return $scope.carTypes = carTypes;
    });
    dataReady = $http.get("/wash/charts/services_structure", {params: $scope.group}).then(function(response) {
      return $scope.rawData = response.data;
    });
    $q.all([ctReady, dataReady]).then(function() {
      $scope.updateChart();
      if ($rootScope.loading === 'services_structure') {
        return $rootScope.loading = false;
      }
    });
  };
  $scope.updateChartData();

  $scope.options = {
    name: 'amount',
    percentage: false
  };
  $scope.updateChart = function(options) {
    var carType, data, j, len, ref, ref1, ref2, selected, serieName, series, timestamp, total, update, values;
    if (options) {
      $scope.options = options;
    }
    serieName = $scope.options.name;
    if ($scope.options.percentage) {
      serieName += '_percentage';
    }
    if (!$scope.rawData) {
      return;
    }
    $scope.groupedData = _.groupBy($scope.rawData, function(point) {
      var date;
      date = moment(point.timestamp);
      if ($scope.group.range) {
        date = date.startOf($scope.group.range);
      }
      return +date;
    });
    data = {};
    ref = $scope.carTypes;
    for (j = 0, len = ref.length; j < len; j++) {
      carType = ref[j];
      data[carType.id] = [];
      ref1 = $scope.groupedData;
      for (timestamp in ref1) {
        values = ref1[timestamp];
        selected = _.filter(values, function(val) {
          return val.car_type_id === carType.id;
        });
        total = _.reduce(selected, ((function(_this) {
          return function(total, val) {
            return total + (parseFloat(val[$scope.options.name]) || 0);
          };
        })(this)), 0);
        total = Math.round(total * 100) / 100;
        data[carType.id].push([parseInt(timestamp), total]);
      }
      data[carType.id] = _.sortBy(data[carType.id], function(o) {
        return o[0];
      });
    }
    series = _.map($scope.carTypes, function(carType, i) {
      return {
        index: i,
        name: carType.name,
        tooltip: {
          valueSuffix: $translate.instant("charts.services_structure.tooltip.valueSuffix." + serieName, {
            currency: userSession.service_location.currency
          })
        },
        color: carType.color,
        data: data[carType.id]
      };
    });
    if ((ref2 = $scope.chart) != null) {
      ref2.destroy();
    }
    update = function() {
      if ($('#charts-container-services-structure').length) {
        return $scope.chart = new Highcharts.StockChart({
          yAxis: {
            title: {
              text: $translate.instant("charts.services_structure.title." + serieName, {
                currency: userSession.service_location.currency
              })
            }
          },
          title: {
            text: $translate.instant("charts.services_structure.title.turnover")
          },
          xAxis: {
            type: "category"
          },
          chart: {
            renderTo: 'charts-container-services-structure',
            borderWidth: 1,
            height: 700,
            type: "column"
          },
          tooltip: {
            borderColor: '#ffffff',
            backgroundColor: 'rgba(255, 255, 255, 0.85)',
            headerFormat: '<span style="font-size: 1.0em">{this.total}</span><br/> <span style="font-size: 1.0em">{point.key}</span><br/>',
            formatter: function() {
              var result;
              result = _.reduce(this.points, (function(_this) {
                return function(result, point) {
                  var value;
                  value = ($scope.options.percentage ? point.point.percentage.toFixed(2) : point.point.y) || 0;
                  return result += "<span style=\"color: " + point.series.color + "\"> " + point.series.name + " </span>: <b>" + value + " " + ($translate.instant("charts.services_structure.tooltip.valueSuffix." + serieName, {
                    currency: userSession.service_location.currency
                  })) + "</b> <br/>";
                };
              })(this), "<span> " + (moment(this.points[0].key).format('MMMM Do YYYY')) + "</span> <br/>");
              if (!$scope.options.percentage) {
                result += "<span> " + ($translate.instant('charts.income.total')) + "</span>: <b>" + this.points[0].total + " " + ($translate.instant("charts.services_structure.tooltip.valueSuffix." + serieName, {
                  currency: userSession.service_location.currency
                })) + "</b> <br/>";
              }
              console.log(result)
              return result;
            }
          },
          plotOptions: {
            column: {
              stacking: $scope.options.percentage ? 'percent' : 'normal'
            }
          },
          exporting: {
            enabled: false
          },
          series: series
        });
      }
    };
    return $timeout(update);
  };
  $scope.$watch('group', () => {
    return $scope.updateChartData();
  }, true);
  $scope.$on('left-menu-state-changed', () => {
    $timeout(() => {
      if (!$scope.chart) {
        return;
      }
      $scope.chart.reflow();
    });
  });
});
