angular.module('RocketWash').controller('WashSettingsMobileServicesBaseController', function($scope, $state, $translate, viewStore, BasicService, CarType, GlobalMobileService, GlobalMobileServiceMapping) {
  const reloadMappings = () => {
    GlobalMobileService.query().then((items) => {
      $scope.globalMobileServices = items;
    })

    GlobalMobileServiceMapping.query().then((items) => {
      $scope.globalMobileServiceMappings = items;
    })

    BasicService.indexMobile().then(function(data) {
      $scope.services = _.map(data, function(o) {
        return new BasicService(o);
      });
    });
  };
  reloadMappings();

  $scope.saveMapping = (s) => {
    const mapping = new GlobalMobileServiceMapping({
      serviceId: s.serviceId,
      globalMobileServiceId: s.id,
    });
    mapping.save().then(() => {
      reloadMappings();
    });
  };

  $scope.deleteMapping = (mappingId) => {
    const mapping = new GlobalMobileServiceMapping({
      id: mappingId
    });
    mapping.delete().then(() => {
      reloadMappings();
    });
  };
});
