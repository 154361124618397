angular.module('RocketWash').factory(
  'User',
  (
    railsResourceFactory, railsSerializer, userSession, $http,
    TenantSwitchInterceptor, rwApi,
  ) => {
    const resource = railsResourceFactory({
      url: (context) => { return "/" + (context.queryRole || userSession.controllerScope()) + "/users/" + (context.id || '') },
      name: 'user',
      serializer: railsSerializer(function () {
        this.nestedAttribute('cars');
      }),
    });

    resource.addInterceptor(TenantSwitchInterceptor);

    resource.prototype.sendManualSmsNotification = function (message) {
      const _class = Object.getPrototypeOf(this).constructor;
      return $http({
        method: 'POST',
        url: `${_class.resourceUrl(this)}/send_manual_sms_notification`,
        params: { message },
      }).then((response) => {
        return response.data;
      });
    };

    resource.prototype.sendPasswordViaSms = function () {
      const _class = Object.getPrototypeOf(this).constructor;
      return $http({
        method: 'POST',
        url: `${_class.resourceUrl(this)}/send_password_via_sms`,
      }).then((response) => {
        return response.data;
      });
    };

    resource.prototype.changePassword = function (password) {
      const _class = Object.getPrototypeOf(this).constructor;
      return $http({
        method: 'POST',
        url: `${_class.resourceUrl(this)}/change_password`,
        params: {
          password
        }
      }).then((response) => {
        return response.data;
      });
    };

    resource.loadPartners = function loadPartners(params) {
      return $http.get(
        `/admin/users/partners`,
        params,
        { performRootWrapping: false },
      ).then((response) => {
        const partners = response.data.items;

        return partners;
      });
    };

    resource.loadAllPartners = function loadAllPartners(params) {
      return $http.get(
        `/admin/users/all_partners`,
        params,
        { performRootWrapping: false },
      ).then((response) => {
        const partners = response.data.items;

        return partners;
      });
    };

    resource.resetIndividualDiscounts = function () {
      return $http.post(
        '/wash/bulk_actions/reset_individual_discounts',
      ).then((response) => {
        return response.data;
      });
    };

    resource.resetIndividualBonuses = function () {
      return $http.post(
        '/wash/bulk_actions/reset_individual_bonuses',
      ).then((response) => {
        return response.data;
      });
    };

    resource.resetIndividualBonusAccounts = function () {
      return $http.post(
        '/wash/bulk_actions/reset_individual_bonus_accounts',
      ).then((response) => {
        return response.data;
      });
    };

    resource.prototype.sendPersonalCabinetLink = function (params) {
      const _class = Object.getPrototypeOf(this).constructor;
      return $http({
        method: 'POST',
        params: params,
        url: `${_class.resourceUrl(this)}/send_personal_cabinet_link`,
      }).then((response) => {
        return response.data;
      });
    };

    resource.prototype.reloadAvailablePriceLists = function () {
      return rwApi.sendRequest({
        method: 'POST',
        path: 'reservation_form/price_lists/available',
        data: {
          user_id: this.id,
          service_location_id: userSession.service_location.id,
        }
      }).then((data) => {
        this.availableCtgs = data;
      });
    };

    resource.prototype.reassignDefaultPriceLists = function () {
      return rwApi.sendRequest({
        method: 'POST',
        path: 'reservation_form/price_lists/default',
        data: {
          user_id: this && this.id,
          service_location_id: userSession.service_location.id,
        }
      }).then((data) => {
        this.users_price_lists = this.users_price_lists || [];
        data.forEach((item) => {
          let sameGroupItem = this.users_price_lists.find((cct) => {
            return cct.car_types_group_id == item.car_types_group_id
          });

          if (sameGroupItem) {
            sameGroupItem.price_list_id = item.price_list_id
          } else {
            this.users_price_lists.push(item)
          }
        });
      });
    };

    resource.prototype.togglePriceList = function ({ price_list, car_types_group }) {
      this.removeUsersPriceList({ car_types_group });

      this.users_price_lists.push({
        price_list_id: price_list.id,
        car_types_group_id: car_types_group.id,
        price_list: price_list,
        car_types_group: car_types_group,
      });
    };

    resource.prototype.removeUsersPriceList = function ({ car_types_group }) {
      const currentItem = this.findUsersPriceList({ car_types_group });

      this.users_price_lists = _.without(this.users_price_lists, currentItem);
    };

    resource.prototype.findUsersPriceList = function ({ car_types_group }) {
      return this.users_price_lists.find((item) => {
        return item.car_types_group_id == car_types_group.id;
      });
    };

    return resource;
  },
);
