angular.module('RocketWash').component('rwReservationPhotos', {
  templateUrl: 'components/rw-reservation/steps/photos/index.slim',
  bindings: {
  },
  controller: function (
    $scope, $q, reservationForm,
  ) {
    $scope.rf = reservationForm;

    reservationForm.photos.loadAll();

    $scope.upload = (files) => {
      // File did not pass validation
      if (!files || files.length == 0) {
        alertService.show({
          text: $translate.instant('settings.import.file_is_invalid'),
          type: 'error'
        });
        return;
      }

      const promises = files.map((file) => {
        return reservationForm.photos.create(file);
      });

      $q.all(promises).then(() => {
        alertService.show({
          text: 'Все фотографии успешно загружены!',
        });
      });
    };
  },
});
