angular.module('RocketWash').controller('WashChartsContractorsController', (
  $scope, $q, $filter, $translate, Customer, ServiceLocation,
) => {
  $scope.selected = {};
  $scope.types = [
    {name: 'All', value: undefined},
    {name: 'Individual', value: 'Individual'},
    {name: 'Entrepreneur', value: 'Entrepreneur'},
    {name: 'Company', value: 'Company'},
  ]

  $scope.getUsers = (value) => {
    return Customer.typeahead(value).then((items) => {
      items.forEach((user) => {
        user.line1 = [user.name, user.phone].join(' ');
      });

      return items;
    });
  };

  $scope.rightMenuConfig = {
    buttons: [
      {
        type: 'export_xls',
        text: $translate.instant('buttons.export_xls'),
        action: () => { $scope.exportXLS(); },
      },
    ],
  };

  $scope.exportXLS = () => {
    window.open(`${$scope.env.apiUrl}/wash/charts/contractors_report.xlsx?${$.param($scope.pds.pg.query)}`, '_blank');
  };

  $scope.updateTable = (field) => {
    if (field == 'type') {
      $scope.selected.user = undefined;
      $scope.selected.userSearchText = undefined;
    } else if (field == 'user' && $scope.selected.userSearchText && $scope.selected.user) {
      $scope.selected.type = undefined;
    }

    $scope.pds.pg.query.type = $scope.selected.type;
    $scope.pds.pg.query.user_id = $scope.selected.user && $scope.selected.user.id;
    $scope.pds.pg.query.sl_id = $scope.selected.slId;
    $scope.$broadcast('pds-update');
  };

  $scope.pds = $scope.pageDataService.new({
    page: 'contractors_report',
    pg: {
      url: 'wash/charts/contractors_report',
      query: {
        fromToField: 'time_end',
        page: 1,
        per: 10,
      },
    },
    definePaginateMethodsOn: $scope,
  });

  $scope.sumColumn = (attribute) => {
    return $scope.pds.pg.items.map(item => item[attribute]).reduce((a,b) => a + b, 0);
  };

});
