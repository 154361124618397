angular.module('RocketWash').controller(
  'AdminPlansBaseController',
  (
    $scope, $translate, $filter, Plan, Permission, PlansPermission, alertService,
    dummySettingsSchemaService, serverConstants,
  ) => {
    $scope.permissionTree = {};
    $scope.dummy = dummySettingsSchemaService;
    $scope.settingValues = {};
    Object.keys(serverConstants.setting_types).map((key) => {
      $scope.settingValues[$filter('toCamelCase')(key)] = true;
    });

    // Load
    $scope.plansReady = Plan.query().then((plans) => {
      $scope.plans = plans.map(x => new Plan(x));
      $scope.selectLastPlan();
      $scope.$watch('selectedPlan', $scope.loadPlan);
    });
    $scope.schemaPromise = Plan.get('settings_schema').then((settingsSchema) => {
      $scope.settingsSchema = {};

      Object.keys(settingsSchema).forEach((key) => {
        if (!key.endsWith('Active')) {
          $scope.settingsSchema[key] = settingsSchema[key];
        }
      });

      return true;
    });
    $scope.permissionsReady = Permission.query().then((permissions) => {
      $scope.permissions = permissions;
    });

    // Actions
    $scope.buildPermissionTree = () => {
      $scope.permissionTree = {};
      $scope.plansPermissions.forEach((pp) => {
        $scope.permissionTree[pp.permissionId] = true;
      });
    };
    $scope.selectLastPlan = () => {
      $scope.selectedPlan = $scope.plans[$scope.plans.length - 1];
    };
    $scope.loadPlan = () => {
      console.info($scope.selectLastPlan.id)
      //return
      PlansPermission.query({ plan_id: $scope.selectedPlan.id })
        .then((plansPermissions) => {
          $scope.plansPermissions = plansPermissions
            .map(pp => (new PlansPermission(pp)));
          $scope.buildPermissionTree();
        });
    };
    $scope.savePermission = (permission) => {
      if ($scope.permissionTree[permission.id]) {
        new PlansPermission({
          permission_id: permission.id,
          plan_id: $scope.selectedPlan.id,
        }).save().then((pp) => {
          $scope.plansPermissions.push(new PlansPermission(pp));
        });
      } else {
        $scope.plansPermissions.find(r => r.permissionId === permission.id)
          .delete().then(() => {
            $scope.plansPermissions = $scope.plansPermissions
              .filter(x => x.permissionId !== permission.id);
          });
      }
    };
    $scope.togglePermission = (permission) => {
      $scope.permissionTree[permission.id] = !$scope.permissionTree[permission.id];
      $scope.savePermission(permission);
    };
    $scope.addPlan = () => {
      new Plan({ name: $translate.instant('plans.index.no_name') })
        .save().then((plan) => {
          $scope.plans.push(plan);
          $scope.selectLastPlan();
        }, (error) => {
          console.log(error);
          alertService.show({
            text: $translate.instant('plans.error.name_taken'),
          });
        });
    };
    $scope.savePlan = (plan) => {
      plan.save();
    };
    $scope.destroyPlan = () => {
      if (confirm($translate.instant('alert.are_you_sure'))) {
        $scope.selectedPlan.delete().then(() => {
          $scope.plans = $scope.plans.filter(x => (x !== $scope.selectedPlan));
          $scope.selectLastPlan();
        });
      }
    };

    // Helpers
    $scope.permissionClass = (permission) => {
      const level = (permission.ordinal.match(/\./g) || []).length;
      return `permission-level-${level}`;
    };
  },
);
