angular.module('RocketWash').service('treeHelpers', () => {
  const dummyTree0 = {
    key: 'a0',
    children: [
      {
        key: 'a1',
        children: [
          { key: 'a2' },
          {
            key: 'b2',
            children: [
              { key: 'a3' },
            ],
          },
          { key: 'c2' },
        ],
      },
      {
        key: 'b1',
        children: [
          { key: 'a2' },
          { key: 'b2' },
        ],
      },
    ],
  };

  const dummyTree1 = {
    key: 'secondhead',
    children: [
      {
        key: 'a1',
        children: [
          {
            key: 'a2',
            children: [
              { key: 'd3' },
            ],
          },
          {
            key: 'b2',
            children: [
              { key: 'x3' },
            ],
          },
          { key: 'c2' },
        ],
      },
      {
        key: 'b1',
        children: [
          { key: 'd2' },
          { key: 'b2' },
        ],
      },
    ],
  };

  const service = {
    dummyTree0: dummyTree0,
    dummyTree1: dummyTree1,

    // params: array of objects with "children" key being
    // either null or array of objects with the same constraints
    mergeTwoTrees: (t1, t2) => {
      const result = { key: t1.key, children: [] };
      const allChildren = (t1.children || []).concat(t2.children || []);
      allChildren.forEach((child) => {
        const index = result.children.findIndex(x => x.key === child.key);
        if (index < 0) {
          result.children.push(service.mergeTwoTrees(child, {}));
        } else {
          result.children[index] = service.mergeTwoTrees(result.children[index], child);
        }
      });
      return result;
    },

    mergeTrees: (trees) => {
      return trees.reduce(service.mergeTwoTrees);
    },

    $flattenTreeInternal: (path, tree) => {
      path = path.concat([tree.key]);
      const results = [path];
      (tree.children || []).forEach((child) => {
        results.push(...service.$flattenTreeInternal(path, child));
      });
      return results;
    },

    flattenTree: (tree) => {
      return service.$flattenTreeInternal([], tree);
    },

    dig: (tree, path) => {
      path = path.slice(1, path.length);
      if (path.length === 0) {
        return tree;
      }
      tree = (tree.children || []).find(x => x.key === path[0]);
      if (!tree) {
        return null;
      }
      return service.dig(tree, path);
    },
  };

  return service;
});
