angular.module('RocketWash').controller('WashIncomeReportController',
  (
    $filter, $scope, $http, $interval, $translate, ServiceLocationLane,
    $uibModal, alertService, Reservation, rwConfirmationService, viewStore, ServiceLocation, userSession
  ) => {
    $scope.allSlsAllowed = ['admin', 'organization_director', 'director'].includes($scope.userSession.getRole())

    $scope.useComplexSalary = userSession.useComplexSalaryCalculationWithEmployeeParticipations()

    ServiceLocation.query().then((serviceLocations) => {
      $scope.serviceLocations = serviceLocations;

      if (!viewStore.data.activeServiceLocation) {
        viewStore.store($scope.allSlsAllowed ? { activeServiceLocation: 'all_sls' } : { activeServiceLocation: $scope.serviceLocations[0].id });
      }
      $scope.activeServiceLocation = viewStore.data.activeServiceLocation;

      $scope.updatePage()
    });

    $scope.pds = $scope.pageDataService.new({
      page: 'income_report',
      filters: {
        attributes: [
          { name: 'time_start', type: 'datetime' },
          { name: 'time_end', type: 'datetime' },
          { name: 'employee_name', type: 'string' },
          { name: 'employee_job', type: 'string' },
          { name: 'sll_name', type: 'string' },
          { name: 'car_make_model', type: 'string' },
          { name: 'car_tag', type: 'string' },
          { name: 'duration', type: 'number' },
          { name: 'reservation_ordinal', type: 'number' },
          { name: 'fot', type: 'number' },
          { name: 'total_amount', type: 'number' },
        ],
        scopes: ['reservation_ordinal', 'employee_name', 'employee_job', 'sll_name', 'car_make_model', 'car_tag'],
        beforeSend: (filters) => {
          if ($scope.activeServiceLocation !== 'all_sls') {
            filters.push({
              attr: 'service_location_id',
              condition: 'equals',
              value: $scope.activeServiceLocation || -1,
            })
          }
        },
      },
      pg: {
        url: '/wash/employee_reservations',
        query: {
          fromToField: 'time_mark',
          predicate: '-time_start',
          per: 10,
          page: 1,
        },
      },
      definePaginateMethodsOn: $scope,
    });

    $scope.jobStatuses = {};
    const updateJobStatuses = () => {
      $http.get('/wash/reservations/job_statuses').then((response) => {
        angular.extend($scope.jobStatuses, response.data);
        console.log(response.data);
      });
    };

    $scope.jobStatusActive = (status) => {
      return ['queued', 'working'].includes(status);
    };

    $scope.jobButtonLabel = (jobName) => {
      let result = $translate.instant(`income.index.${jobName}`);
      const active = $scope.jobStatusActive($scope.jobStatuses[jobName]);
      if (!active) {
        return result;
      }
      result += ': ';
      result += $translate.instant(`job_status.${$scope.jobStatuses[jobName]}`);
      return result;
    };

    updateJobStatuses();
    console.log('enabling jobStatuses interval');
    $scope.jobStatusesTimer = $interval(updateJobStatuses, 10000);
    $scope.$on('$destroy', () => {
      console.log('disabling jobStatuses interval');
      $interval.cancel($scope.jobStatusesTimer);
    });

    ServiceLocationLane.query().then((lanes) => {
      $scope.lanes = lanes;
    });

    $scope.isResaveActive = () => (
      moment($scope.pds.pg.query.to).diff($scope.pds.pg.query.from, 'hours') <= 240
    );

    $scope.resaveReservations = () => {
      $uibModal.open({
        templateUrl: 'pages/wash/income/report/resave_reservations_popup.slim',
        controller: 'WashIncomeReportResaveReservationsPopupController',
        windowClass: 'small',
        size: 'sm',
        resolve: {
          from: moment($scope.pds.pg.query.from),
          to: moment($scope.pds.pg.query.to),
        },
      }).result.then((email) => {
        updateJobStatuses();
      });
    };

    $scope.downloadXls = function () {
      $uibModal.open({
        templateUrl: 'pages/common/email_popup/email_popup.slim',
        controller: 'CommonEmailPopupController',
        windowClass: 'small',
        size: 'sm',
      }).result.then((email) => {
        const options = angular.extend({ email }, $scope.pds.pg.query);
        return $http({
          url: `${$scope.pds.pg.url}.xls`,
          method: 'GET',
          params: options,
        }).then(() => alertService.show({
          text: $filter('translate')('email_popup.success'),
        }));
      });
    };

    $scope.rightMenuConfig = {
      width: 5,
      buttons: [
        {
          type: 'refresh',
          text: $scope.jobButtonLabel('resave_reservations'),
          action: () => { $scope.resaveReservations(); },
          ngDisabled: () => (
            $scope.jobStatusActive($scope.jobStatuses.resave_reservations) ||
            !$scope.isResaveActive()
          ),
          refreshIsInProgress: () => {
            return $scope.jobStatusActive($scope.jobStatuses.resave_reservations);
          },
        },
        {
          type: 'export_xls',
          text: $translate.instant('buttons.export_xls'),
          action: () => { $scope.downloadXls(); },
          ngIf: () => {
            return userSession.can('export', 'EmployeeResrvationsExport')
          }
        },
      ],
    };

    $scope.allExpanded = () => $scope.pds.pg.items.every(x => x.expanded);
    $scope.toggleAll = () => {
      const newValue = !$scope.allExpanded();
      $scope.pds.pg.items.forEach((x) => {
        x.expanded = newValue;
      });
    };

    $scope.setValue = () => {
      viewStore.store({ activeServiceLocation: $scope.activeServiceLocation });
      setTimeout($scope.updatePage, 1000);
    }
  });
