

angular.module('RocketWash').controller('AdminSettingsResourceKindsBaseController', function($rootScope, $scope, $state, $translate, viewStore, ResourceKind) {
  ResourceKind.query().then(function(kinds) {
    return $scope.kinds = _.map(kinds, function(k) {
      return new ResourceKind(k);
    });
  });
  $scope.newResourceKind = function() {
    return $scope.kinds.unshift(new ResourceKind({
      name: ''
    }));
  };
  $scope.save = function(kind) {
    return kind.save();
  };
  return $scope["delete"] = function(kind) {
    kind["delete"]();
    return $scope.kinds = _.without($scope.kinds, kind);
  };
});
