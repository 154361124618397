angular.module('RocketWash').service('ctgSelectServiceForCharts', ($rootScope, $timeout, CarTypesGroup, ctSelectServiceForCharts, plSelectService) => {
  const service = {
    items: null,
    selected: null,
    load: () => {
      if (!service.items) {
        return CarTypesGroup.query({}, { cache: false }).then((carTypesGroups) => {
          service.items = carTypesGroups;
          service.items.unshift(undefined)
          service.select(service.items[0]);// all
        });
      } else {
        Promise.resolve();
      }
    },
    reload: () => {
      service.reset();
      return service.load();
    },
    reset: () => {
      service.selected = null;
      service.items = null;
    },
    select: (ctg) => {
      if (ctg) {
        service.selected = service.items.find(x => x && x.id == ctg.id);
      }else{
        service.selected = undefined
      }
      $rootScope.$broadcast('ctgSelectService-selected');
      ctSelectServiceForCharts.reload();
      plSelectService.reload();
    },
  };

  $timeout(service.load);

  return service;
});
