angular.module('RocketWash').controller(
  'AdminServiceLocationSettingsGeneralController',
  (
    $scope, $stateParams,
    ServiceLocation, Organization, Plan, TimeZone,
  ) => {
    ServiceLocation.tenantId = $stateParams.orgId;

    $scope.plans = [];
    $scope.timeZones = [];

    ServiceLocation.get($stateParams.slId).then((sl) => {
      $scope.sl = sl;
    });
    Organization.get($stateParams.orgId).then((org) => {
      $scope.organization = org;
    });
    Plan.query().then((plans) => {
      $scope.plans = plans;
    });
    TimeZone.query().then((timeZones) => {
      timeZones.forEach((tz) => {
        const offset = moment().utcOffset(tz.utcOffset / 60).format('Z');
        tz.title = `(GMT ${offset}) ${tz.name}`;
      });
      $scope.timeZones = timeZones;
    });

    $scope.planName = (planId) => {
      const plan = $scope.plans.find(p => p.id === planId);
      return plan && plan.name;
    };
    $scope.save = (serviceLocation) => {
      serviceLocation.save();
    };
  },
);
