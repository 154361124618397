angular.module('RocketWash').factory(
  'YandexTaxiDriver',
  (railsResourceFactory, userSession, $http) => {
    const resource = railsResourceFactory({
      url: (context) => { return "/" + (context.queryRole || userSession.controllerScope()) + "/drivers/" + (context.id || '') },
      name: 'driver',
    });

    angular.extend(resource, {
      all(queryParams) {
        return $http({
          url: `${this.$url()}/all`,
          params: queryParams,
          method: 'GET',
        }).then(response => response.data);
      }
    });

    return resource;
  },
);
