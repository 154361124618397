angular.module('RocketWash').controller('WashChartsMarketingActivitiesController', (
  $scope, $uibModal, MarketingActivity, rwConfirmationService,
) => {
  $scope.pds = $scope.pageDataService.new({
    page: 'marketing_activities',
    pg: {
      url: '/wash/marketing_activities',
      model: MarketingActivity,
      query: {
        predicate: 'date_start',
        reverse: true,
        per: 10,
        page: 1,
      },
    },
    definePaginateMethodsOn: $scope,
  });
  $scope.pg = $scope.pds.pg;

  $scope.new = () => {
    $scope.edit(new MarketingActivity({ name: '' }));
  };

  $scope.save = (marketingActivity) => {
    marketingActivity.save().then((response) => {
      $scope.errors = { save_marketing_activity: response.errors };
    });
  };

  $scope.delete = (marketingActivity) => {
    rwConfirmationService.confirm(
      { textTranslation: 'alert.are_you_sure' },
      () => { marketingActivity.delete().then($scope.resetAndUpdate); },
    );
  };

  $scope.edit = (marketingActivity) => {
    $uibModal.open({
      templateUrl: 'pages/wash/charts/marketing_activities/form_popup.slim',
      controller: 'WashMarketingActivityFormController',
      resolve: {
        resolvedMarketingActivity: () => marketingActivity,
      },
    }).result.finally(() => {
      $scope.resetAndUpdate();
    });
  };
});
