angular.module('RocketWash').controller('ClientMonitorController', function (
  $scope, $http, $interval, $timeout, $stateParams, $sce, Reservation,
) {

  $scope.TIME_INTERVALS = {
    RESERVATIONS_UPDATE: 15 * 1000,
  }
  const notification = new Audio('monitor_notifications/default_notification.mp3');
  const announcementPanel = $('#announcementPanel');
  const clientMonitorUUID = $stateParams.monitorUUID;

  $http({
    url: '/client_monitor/settings',
    method: 'GET',
    params: {
      client_monitor_uuid: clientMonitorUUID,
    },
  }).then((response) => {
    let playlistID = response.data['youtube_playlist'].split(/list=/)[1];
    let bgImage = response.data['bg_image'];

    $scope.TIME_INTERVALS.RESERVATION_ANNOUNCEMENT = response.data['announcement_period'] * 1000;

    if (bgImage) {
      $('body.client_monitor').css({
        'background-image': `url('${bgImage}')`
      });
    }

    if (playlistID) {
      $scope.youtubeUrl = $sce.trustAsResourceUrl(`https://www.youtube.com/embed/videoseries?list=${playlistID}&enablejsapi=1&autoplay=1&loop=1`);
    };
  });

  $scope.announcementInProgress = false;
  $scope.reservations = {
    toAnnounce: [],
    queue: [],
  }

  // Fullscreen announcement of finished reservations in
  // a popup
  const announceFullscreenReservation = (reservations) => {
    $scope.announcementInProgress = true;

    const r = reservations.pop();

    notification.play().catch((error) => {
      console.log('Caught DOMExc., trying to reload');
    }).then(() => {
      notification.play();
    });

    $timeout(() => {
      notification.play()
    }, $scope.TIME_INTERVALS.RESERVATION_ANNOUNCEMENT / 2);

    announcementPanel.removeClass('hidden');

    $scope.announcedReservation = r;

    return $timeout($scope.TIME_INTERVALS.RESERVATION_ANNOUNCEMENT).then(() => {
      $scope.announcementInProgress = false;
      announcementPanel.addClass('hidden');
    });
  };

  $scope.announceFullscreenReservations = () => {
    announceFullscreenReservation($scope.reservations.toAnnounce).then(() => {
      if ($scope.reservations.toAnnounce.length != 0) {
        $scope.announceFullscreenReservations();
      }
    })
  };

  $scope.updateReservations = () => {
    if ($scope.announcementInProgress) {
      return
    };

    return $http({
      url: '/client_monitor',
      method: 'GET',
      params: {
        client_monitor_uuid: clientMonitorUUID,
      },
    }).then((response) => {
      let newQueue = response.data['reservations_to_announce'];

      $scope.reservations.toAnnounce = reservationQueuesDiff(newQueue,
        $scope.reservations.queue);

      $scope.reservations.queue = newQueue;

      console.log('Finished reservations were fetched');

      if ($scope.reservations.toAnnounce.length > 0) {
        $scope.announceFullscreenReservations();
      }
    });
  };

  $scope.updateReservations();
  $interval($scope.updateReservations, $scope.TIME_INTERVALS.RESERVATIONS_UPDATE);

  const reservationQueuesDiff = (newQueue, oldQueue) => {
    return newQueue.filter((newRes) => {
      return !oldQueue.some((oldRes) => {
        return (newRes.id == oldRes.id) && (newRes.type == oldRes.type);
      });
    });
  };

  $scope.manualInfo = (r) => {
    let parts = r.announcement_text.split('|');
    return {
      tag: parts[0],
      make: parts[1],
      model: parts[2]
    }
  }
}, );
