angular.module('RocketWash').controller('WashSettingsDevicesEditController', function($scope, $state, $stateParams, Device, serverConstants) {
  if ($stateParams.id) {
    Device.get($stateParams.id).then(function(res) {
      return $scope.device = res;
    });
  } else {
    $scope.device = new Device({
      type: serverConstants.device_types[0],
      settings: {}
    });
  }
  return $scope.save = function() {
    $scope.errors = {
      device: {}
    };
    $scope.saveDisabled = true;
    return $scope.device.save().then(function(data) {
      $scope.errors.device = data.errors;
      if (_.isEmpty($scope.errors.device)) {
        return $state.go('^');
      }
    }).finally(() => {
      $scope.saveDisabled = false;
    });
  };
});
