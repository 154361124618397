angular.module('RocketWash').controller('WashIncomeJobsController', (
  $q, $scope, $uibModal, Job, BasicService, JobOptionsForService
) => {
  $scope.active = { jobs: [], tabIndex: 0 };

  $scope.reload = () => {
    let jobsPromise = Job.query().then((jobs) => {
      $scope.jobs = _.sortBy(jobs, 'name');
      $scope.active.jobs.push($scope.jobs[0]);
    });

    JobOptionsForService.query().then((data) => {
      $scope.jofs = data.map(x => new JobOptionsForService(x));
    });


  };

  $scope.updateActiveJobs = () => {
    $scope.jobs.forEach((job) => {
      job.active = $scope.active.jobs.indexOf(job) >= 0;
    });
  };

  $scope.openFormPopup = (job) => {
    $uibModal.open({
      templateUrl: 'pages/wash/income/jobs/form.slim',
      controller: 'WashIncomeJobsFormController',
      resolve: {
        resolvedJob: () => job,
      },
    }).result.finally(() => {
      setTimeout($scope.reload, 100);
    });
  };

  $scope.reload();

  $scope.getServiceCategories = (services) => {
    const groups = _.groupBy(services, service => service.category || '');
    const groupsArray =
      Object.entries(groups).map(([groupName, groupServices]) => [
      groupName,
      _.sortBy(groupServices, (s) => {
        return s.name && s.name.toLowerCase();
      }),
    ]);
    return _.sortBy(groupsArray, g => g[0].toLowerCase());
  };

  $scope.saveJobOptionsForService = (jofs) => {
    jofs.save().then((result) => {
      $scope.jofs.push(result);
      $scope.recalculateGroupedJOFS();
    });
  };

  $scope.deletePLI = function(pli) {
    const index = $scope.jofs.indexOf(pli);
    pli['delete']().then(() => {
      $scope.jofs.splice(index, 1);
      $scope.recalculateGroupedJOFS();
    });
  };

  $scope.valueWithFallback = (a, b) => {
    if (a === null || a === undefined) {
      return b;
    } else {
      return a;
    }
  };
});
