angular.module('RocketWash').controller('WashIncomeMotivationFixedController', (
  $q, $scope, $uibModal, Job, BasicService, CarType, BrigadeMotivation, ctSelectService, ctgSelectService
) => {
  $scope.active = { jobs: [] };

  $scope.recalculateGroupedMotivations = () => {
    let grouped = {};

    $scope.services.forEach((service) => {
      grouped[service.id] = {};
      $scope.jobs.forEach((job) => {
        grouped[service.id][job.id] = {};
        ctSelectService.items.forEach((carType) => {
          grouped[service.id][job.id][carType.id] = new BrigadeMotivation({ jobId: job.id, serviceId: service.id, carTypeId: carType.id });
        });
      });
    });

    $scope.motivations.forEach((item) => {
      grouped[item.serviceId] = grouped[item.serviceId] || {};
      grouped[item.serviceId][item.jobId] = grouped[item.serviceId][item.jobId] || {};
      grouped[item.serviceId][item.jobId][item.carTypeId] = item;
    });

    $scope.groupedMotivations = grouped;
  };

  let jobsPromise = Job.query().then((jobs) => {
    $scope.jobs = _.sortBy(jobs, 'name');
    $scope.active.jobs.push($scope.jobs[0]);
  });

  $scope.reload = () => {
    if (ctgSelectService.selected) {
      let basicServicesPromise = BasicService.indexSimple({
        car_types_group_id: ctgSelectService.selected.id
      }).then((data) => {
        $scope.services = data.map(x => new BasicService(x));
        $scope.serviceCategories = $scope.getServiceCategories($scope.services);
      });

      BrigadeMotivation.query().then((data) => {
        $scope.motivations = data.map(x => new BrigadeMotivation(x));

        $q.all([jobsPromise, basicServicesPromise]).then(() => {
          $scope.recalculateGroupedMotivations();
        })
      });
    };
  };

  $scope.updateActiveJobs = () => {
    if (ctSelectService.items) {
      $scope.jobs.forEach((job) => {
        job.active = $scope.active.jobs.map(x => x.id).includes(job.id);
      });

      $scope.jobsCarTypesArray = [];
      _.orderBy($scope.active.jobs, 'name').forEach((job) => {
        ctSelectService.items.forEach((carType) => {
          $scope.jobsCarTypesArray.push({ job, carType });
        });
      });
    }
  };

  $scope.reload();
  $scope.$on('ctgSelectService-selected', () => {
    $scope.reload();
  });
  $scope.$on('ctSelectService-selected', () => {
    $scope.updateActiveJobs();
  });

  $scope.getServiceCategories = (services) => {
    const groups = _.groupBy(services, service => service.category || '');
    const groupsArray =
      Object.entries(groups).map(([groupName, groupServices]) => [
      groupName,
      _.sortBy(groupServices, (s) => {
        return s.name && s.name.toLowerCase();
      }),
    ]);
    return _.sortBy(groupsArray, g => g[0].toLowerCase());
  };

  $scope.saveMotivation = (motivation) => {
    motivation.save().then((result) => {
      $scope.motivations.push(result);
      $scope.recalculateGroupedMotivations();
    });
  };

  $scope.deletePLI = function(pli) {
    const index = $scope.motivations.indexOf(pli);
    pli['delete']().then(() => {
      $scope.motivations.splice(index, 1);
      $scope.recalculateGroupedMotivations();
    });
  };

  $scope.valueWithFallback = (a, b) => {
    if (a === null || a === undefined) {
      return b;
    } else {
      return a;
    }
  };
});
