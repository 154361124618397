angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.client_cabinet.charts.contractors', {
    url: '/contractors',
    tab: 'contractors',
    data: {
      publicPage: true,
      permission: 'ContractorsReportTab'
    },
    views: {
      'content@app.client_cabinet.charts': {
        controller: 'ClientCabinetChartsContractorsController',
        templateUrl: 'pages/client_cabinet/charts/contractors/index.slim'
      }
    }
  });
});
