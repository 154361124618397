angular.module('RocketWash').directive('rwIframePreview', () => {
  function link(scope, element) {
    let iframe = document.createElement('iframe');
    let element0 = element[0];
    element0.appendChild(iframe);

    scope.$watch('content', function () {
      iframe.contentWindow.document.open();
      iframe.contentWindow.document.write(scope.content);
      iframe.contentWindow.document.close();

      iframe.style.width = `${scope.width}mm`;
      iframe.style.height = `${scope.height}mm`;

      iframe.contentDocument.body.style.overflow = 'scroll';
    });
  }

  return {
    link: link,
    restrict: 'E',
    scope: {
      content: '=',
      width: '=',
      height: '=',
    }
  };
});
