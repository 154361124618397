import { env } from '@env';

require('./locales/ru_ru');

window.translationsPromise = null;

angular.module('RocketWash', [
  'rails',
  'xeditable',
  'ui.bootstrap',
  'pascalprecht.translate',
  'ui.select',
  'ui.mask',
  'ui.validate',
  'ui.router',
  'ui.tree',
  'ngQuickDate',
  'ngRoute',
  'ui.bootstrap.datetimepicker',
  'ngSanitize',
  'ngMaterial',
  'ngMaterialDatePicker',
  'md.data.table',
  'angular-ladda',
  'mgo-angular-wizard',
  'ngFileUpload',
  'ngTagsInput',
  'ngCookies',
  'ngActionCable',
  'angularRipple',
  'ngRateIt'
])
  .config((tagsInputConfigProvider) => {
    tagsInputConfigProvider.setDefaults('tagsInput', {
      replaceSpacesWithDashes: false,
      minLength: 1,
    });
    tagsInputConfigProvider.setDefaults('autoComplete', {
      minLength: 1,
    });
  })
  .config((laddaProvider) => {
    laddaProvider.setOption({
      style: 'zoom-in',
    });
  })
  .config((
    $httpProvider, $translateProvider, ngQuickDateDefaultsProvider, $qProvider,
    $ariaProvider, $mdAriaProvider, $compileProvider
  ) => {
    $qProvider.errorOnUnhandledRejections(false);

    $ariaProvider.config({bindKeydown: false});
    $mdAriaProvider.disableWarnings();

    const lang = $('html').data('lang');
    $translateProvider.preferredLanguage(lang);
    $translateProvider.useSanitizeValueStrategy('escape');

    window.translationsPromise = $.get(`${env.apiUrl}/translations`, (response) => {
      const translations = response;

      Object.keys(translations).forEach((locale) => {
        const trs = translations[locale];
        $translateProvider.translations(locale, trs);
      });

      const highchartsLang = translations[lang].charts.highcharts;
      if (highchartsLang) { Highcharts.setOptions({ lang: highchartsLang }); }

      window.translationsLoaded = true;
    });

    moment.locale(lang);

    $httpProvider.defaults.withCredentials = true;

    const showIpAddressErrorAlert = _.once(() => {
      alert('Вход в приложение с вашего IP адреса запрещен. Пожалуйста обратитесь к директору вашей организации за дополнительной информацией.');
    });
    $httpProvider.interceptors.push(($q) => {
      return {
        // FIXME: rewrite
        request: function (config) {
          const isTemplate = config.url[0] === '@';
          const isHtml = config.url.slice(-4) === 'html';
          const isSlim = config.url.slice(-4) === 'slim';
          const isHttpRequest = config.url.slice(0, 4) === 'http';
          const isSortArrowRequest = config.url.endsWith('arrow-up.svg');
          const isPicRequest = config.url.endsWith('pic.png');
          const isServerCall = !(
            isTemplate || isHtml || isSlim || isHttpRequest || isSortArrowRequest || isPicRequest
          );
          if (isServerCall) { // templates start with @tmpl
            config.url = env.apiUrl + config.url;
            config.headers.Accept = 'application/json';
          }

          if (location.href.match(/\/client_cabinet\//)) {
            const clientCabinetUUID = location.href.match(/client_cabinet\/([^\/]+?)\//)[1];
          config.headers['X-WWW-PERSONAL-CABINET-UUID'] = clientCabinetUUID;
          }

          return config || $q.when(config);
        },

        responseError: function(response) {
          if (response.status == 401) {
            console.log('you performed action where you do not have proper authorization');
          }
          if (response.status == 403) {
            console.log('Your ip address is not whitelisted!');
            showIpAddressErrorAlert();
          }
          return $q.reject(response);
        }
      };
    });

    ngQuickDateDefaultsProvider.set({
      nextLinkHtml: "<i class='fa fa-angle-right'></i>",
      prevLinkHtml: "<i class='fa fa-angle-left'></i>",
      dayAbbreviations: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс', ],
      disableClearButton: true,
      momentDateFormat: 'DD/MM/YYYY',
      dateFormat: 'dd/MM/yyyy',
      timeFormat: 'HH:mm',
      labelFormat: 'H:mm d/MM/yyyy',
      dateLabelHtml: 'Дата',
      timeLabelHtml: 'Время',
      parseDateFunction: (str) => {
        const dateFormat = ngQuickDateDefaultsProvider.options.momentDateFormat;
        const timeFormat = ngQuickDateDefaultsProvider.options.timeFormat;

        let mdate;
        if (typeof str === 'string') { // String date
          if (str.indexOf('/') >= 0) { // Our date-time format
            mdate = moment(str, `${dateFormat} ${timeFormat}`);
            if (!mdate.isValid()) { mdate = moment(str, dateFormat); }
            if (!mdate.isValid()) { mdate = moment(`${str} 0:0`, `${dateFormat} ${timeFormat}`); }
          } else { // ISO8601 date-time format
            mdate = moment(str);
          }
        } else { // Date object
          mdate = moment(str);
        }

        if (mdate.isValid()) {
          return mdate.toDate();
        }
        return moment().toDate();
      },
    });

    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|tel|tg|viber):/);
  })
  .run(() => {
    const http = location.href.match(/^http:\/\//);
    const development = location.href.match(/:8080\//) || location.href.match(/localhost/);
    if (http && !development) {
      const httpsUrl = location.href.replace(/http:\/\//, 'https://');
      location.href= httpsUrl;
    }
  })
  .run((editableOptions, editableThemes) => {
    editableThemes.material = {
      formTpl: '<form class="editable-wrap"></form>',
      noformTpl: '<span class="editable-wrap"></span>',
      controlsTpl: '<md-input-container class="editable-controls" ng-class="{\'md-input-invalid\': $error}"></md-input-container>',
      inputTpl: '',
      errorTpl: '<div ng-messages="{message: $error}"><div class="editable-error" ng-message="message">{{$error}}</div></div>',
      buttonsTpl: '<span class="editable-buttons"></span>',
      submitTpl: '<md-button type="submit" class="md-icon-button"><md-icon>save</md-icon></md-button>',
      cancelTpl: '<md-button type="button" class="md-icon-button" ng-click="$form.$cancel()"><md-icon>cancel</md-icon></md-button>',
    };

    editableOptions.theme = 'material';
    editableOptions.buttons = 'no';
    editableOptions.blurElem = 'submit';
  })
  .run(($rootScope, $state, $q, userSession, progress, pageDataService, leftMenuStateService) => {
    $rootScope.leftMenuStateService = leftMenuStateService;
    $rootScope.pageDataService = pageDataService;

    $rootScope.$on('$stateChangeStart', (event, toState, _toParams, fromState, _fromParams) => {
      const permission = toState.data && toState.data.permission;
      const publicPage = toState.data && (toState.data.publicPage || toState.data.public_page);

      progress.hide();

      $q.all([userSession.promise, window.translationsPromise]).then(() => {
        // Redirect organization director to setup_wizard if signed in but did not finish it
        if (userSession.isSignedIn() && !userSession.organization.is_taxi && !userSession.organization.finished_setup_wizard && userSession.is('organization_director')) {
          $state.go('app.wash.setup_wizard');
        }

        const validScope = toState.name.indexOf(userSession.controllerScope()) === 4;
        let canVisit = userSession.can('visit', permission) || userSession.is('admin') || userSession.is('organization_director');

        canVisit = publicPage || (validScope && canVisit);
        if (!canVisit) {
          console.log("Seems like you tried accessing a route you don't have access to...");
          event.preventDefault();

          if (fromState.url === '^') {
            if (userSession.isSignedIn()) {
              if (toState.name !== userSession.homeState()) {
                userSession.goHomeState();
              }
            } else {
              $state.go('sign_in');
            }
          } else {
            $state.go(fromState.name);
          }
        }
      }, () => {
        if (!publicPage) {
          console.log("Seems like UserSession or Translations were not loaded properly. Redirecting to sign_in.");
          event.preventDefault();
          $state.go('sign_in');
        }
      });
    });
  })
  .config(function(treeConfig) {
    treeConfig.defaultCollapsed = true; // collapse nodes by default
    // treeConfig.appendChildOnHover = true; // append dragged nodes as children by default
  })
  .config(function($provide) {
    // extend Angular Material's mdAutoCompleteDirective
    $provide.decorator(
      'mdAutocompleteDirective',
      mdAutoCompleteDirectiveOverride
    );

    mdAutoCompleteDirectiveOverride.$inject = ['$delegate'];

    function mdAutoCompleteDirectiveOverride($delegate) {
      // grab the directive
      var directive = $delegate[0];

      // need to append to base compile function
      var compile = directive.compile;

      // add our custom attribute to the directive's scope
      angular.extend(directive.scope, {
        menuContainerClass: '@?mdMenuContainerClass'
      });

      // recompile directive and add our class to the virtual container
      directive.compile = function(element, attr) {
        var template = compile.apply(this, arguments);
        var menuContainerClass = attr.mdMenuContainerClass ? attr.mdMenuContainerClass : '';
        var menuContainer = element.find('md-virtual-repeat-container');

        menuContainer.addClass(menuContainerClass);

        // recompile the template
        return function(e, a) {
          template.apply(this, arguments);
        };
      };

      return $delegate;
    }
  })
  .run(['$templateCache', function ($templateCache) {
    $templateCache.put('md-table-pagination.html', `
                       <div class="thumb-container">
                       <div class="left multiplicity">
                       <span>Показывать на странице:</span>
                       <a ng-repeat="option in $pagination.limitOptions"
                       ng-value=""
                       ng-class="{ active: (option.value ? $pagination.eval(option.value) : option) == $pagination.limit }"
                       ng-click="$pagination.limit = option.value ? $pagination.eval(option.value) : option; $pagination.onPaginationChange()"
                       >
                       {{::option.label ? option.label : option}}
                       </a>
                       </div>
                       <div class="page-selection">
                       <a ng-click="$pagination.previous()">
                       <i class="mi">&#xE314;</i>
                       </a>
                       <span class="page-amount">страница</span>
                       <input class="actual-page-number editable" ng-model="$pagination.page"
                       ng-model-options="{ debounce: 600 }"
                       ng-change="$pagination.onPaginationChange()">
                       <span class="page-amount">
                       из
                       <span>{{$pagination.pages()}}</span>
                       </span>
                       <a ng-click="$pagination.next()">
                       <i class="mi">&#xE315;</i>
                       </a>
                       </div>
                       </div>
                       `);
  }])
  .run(['ActionCableConfig', function (ActionCableConfig) {
    ActionCableConfig.wsUri = env.apiUrl.replace(/(http|https)/, 'ws') + '/cable';
    // ActionCableConfig.debug = true;
  }]);

  function requireAll(r) { r.keys().forEach(r); }

  requireAll(require.context('@constants', true, /\.js$/));
  requireAll(require.context('@pages', true, /\.js$/));
  requireAll(require.context('@pages', true, /\.sass$/));
  requireAll(require.context('@components', true, /\.js$/));
  requireAll(require.context('@components', true, /\.sass$/));
  requireAll(require.context('@directives', true, /\.js$/));
  requireAll(require.context('@filters', true, /\.js$/));
  requireAll(require.context('@resources', true, /\.js$/));
  requireAll(require.context('@services', true, /\.js$/));

  requireAll(require.context('/', true, /\.slim$/));
