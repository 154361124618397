angular.module('RocketWash').directive('rwRightMenu', () => {
  return {
    restrict: 'E',
    scope: {
      config: '=',
    },
    templateUrl: 'directives/rw-right-menu/rw-right-menu.slim',
    link(scope, _element, _attrs) {
      scope.config.width = scope.config.width || 4;

      scope.config.buttons.forEach((button) => {
        button.ngDisabled = button.ngDisabled || (() => false);
        button.ngIf = button.ngIf || (() => true);
      });
    },
  };
});
