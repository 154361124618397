angular.module('RocketWash').component('rwUserTags', {
  templateUrl: 'components/rw-reservation/common/rw-user-tags/index.slim',
  bindings: {
    user: '=',
  },
  controller: function ($scope, reservationForm) {
    $scope.rf = reservationForm;
    $scope.config = {
      text: '',
    };

    $scope.onTagAdding = (tag) => {
      if (tag.id) {
        reservationForm.marketing.addTag(tag);
      } else {
        reservationForm.marketing.createTag(tag).then((response) => {
          if (!response.id) {
            throw new Error('failed to create tag');
          }
          tag.id = response.id;
          reservationForm.marketing.addTag(tag);
        });
      }

      $scope.config.text = '';

      return false;
    };

    $scope.removeTag = (tag) => {
      $scope.rf.marketing.tags = _.without($scope.rf.marketing.tags, tag);
    };
  },
});
