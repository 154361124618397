angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.admin.service_locations.service_location.settings', {
    url: '/settings',
    views: {
      'content@app.admin.service_locations': {
        controller: 'AdminServiceLocationsSettingsController',
        templateUrl: 'pages/admin/service_locations/settings/index.slim'
      }
    }
  });
});
