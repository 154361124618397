

angular.module('RocketWash').controller('WashSettingsDiscountActionsStepFormController', function($http, $rootScope, $scope, $state, $filter, $stateParams, DiscountAction, DiscountActionStep) {
  DiscountActionStep.get({
    id: $stateParams.id
  }).then(function(das) {
    return $scope.das = das;
  });
  $scope.save = function() {
    return $http.put($scope.das.$url(), $scope.das).then(function() {
      return $state.go('^.show', {
        id: $scope.das.discountActionId
      });
    });
  };
  return $scope.goBack = function() {
    return $state.go('^.show', {
      id: $scope.das.discountActionId
    });
  };
});
