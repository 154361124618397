require('highcharts/modules/sankey.src.js')

angular.module('RocketWash').controller('WashChartsClientActivityCategoriesController', (
  $scope, $q, $rootScope, $http, $translate, $timeout, userSession, dateRangeStorage) => {
  $rootScope.loading = 'client_activity_categories';
  $scope.ACTIVITY_CATEGORIES = [
    'new_client',
    'potential_client',
    'loyal_client',
    'regular_client',
    'snoozing_client',
    'awakened_client',
    'gone_client',
    'returned_client',
    'unclassified',
  ]
  $scope.ignoredCategories = [];

  dateRangeStorage.update({
    from: moment().subtract(3, 'month').toDate(),
    to: moment().toDate(),
  });

  $scope.updateChartData = () => {
    const chartPromise = $http({
      url: '/wash/charts/user_activity_categories',
      method: 'GET',
      params: {
        from: dateRangeStorage.from(),
        to: dateRangeStorage.to(),
        ignored_categories: JSON.stringify($scope.ignoredCategories),
      },
    }).then((response) => {
      $scope.weeks = response.data.weeks;
      $scope.translations = response.data.translations;

      $scope.updateChart({
        data: response.data.data,
        nodes: response.data.nodes,
      });
    });
  };

  $scope.updateChart = (drawOptions) => {
    if ($scope.chart != null) {
      $scope.chart.destroy();
      $scope.chart = null;
    };

    const update = () => {
      $scope.chart = new Highcharts.chart('charts-container-user-activity', {
        title: {
          text: 'Категории пользователей'
        },
        series: [
          {
            keys: ['from', 'to', 'weight', 'color'],
            data: drawOptions.data,
            nodes: drawOptions.nodes,
            type: 'sankey',
          }
        ],
        chart: {
          type: 'sankey',
        },
        tooltip: {
          formatter: function() {
            if (this.point.from && this.point.to) {
              return `${this.point.fromNode.name} -> ${this.point.toNode.name} (${this.point.weight}шт)`
            } else {
              return `${this.key} (${this.point.sum})<br>${this.point.month}`;
            }
          }
        }
      })
    };
    $timeout(update);
  };
  $scope.$on('left-menu-state-changed', () => {
    $timeout(() => {
      if (!$scope.chart) {
        return;
      }
      $scope.chart.reflow();
    });
  });

  $scope.$on('date-range-updated', () => {
    $scope.updateChartData();
  });

  $scope.updateChartData();

  $scope.toggleSeries = (toggledCategory) => {
    if ($scope.ignoredCategories.includes(toggledCategory)) {
      $scope.ignoredCategories = $scope.ignoredCategories.filter((el) => {
        return el != toggledCategory;
      });
    } else {
      $scope.ignoredCategories.push(toggledCategory);
    };
    console.log($scope.ignoredCategories);

    $scope.updateChartData();
  };
});
