angular.module('RocketWash').constant('PREDEFINED_FILTERS_YA_FO', {
  wash_financial_operations: [
    {
      name: 'Только Яндекс Автомобили',
      filters: [
        {
          custom: true,
          condition: 'custom__only_yandex_fin_operations',
        },
      ],
    },
  ],
  admin_financial_operations: [
    {
      name: 'Только Яндекс Автомобили',
      filters: [
        {
          custom: true,
          condition: 'custom__only_yandex_fin_operations',
        },
      ],
    },
  ],
});
