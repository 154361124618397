angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.income.jobs', {
    url: '/jobs',
    tab: 'jobs',
    data: {
      permission: 'JobsTab'
    },
    views: {
      'content@app.wash.income': {
        controller: 'WashIncomeJobsController',
        templateUrl: 'pages/wash/income/jobs/index.slim'
      }
    }
  }).state('app.wash.income.jobs.form', {
    url: '/form/:id',
    tab: 'form',
    data: {
      permission: 'JobsTab'
    },
    views: {
      'content@app.wash.income': {
        controller: 'WashIncomeJobsFormController',
        templateUrl: 'pages/wash/income/jobs/form.slim'
      }
    }
  });
});
