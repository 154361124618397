angular.module('RocketWash').config(($stateProvider) => {
  $stateProvider.state('app.admin.settings.car_types_groups', {
    url: '/car_types_groups',
    tab: 'car_types_groups',
    views: {
      'content@app.admin.settings': {
        controller: 'AdminSettingsCarTypesGroupsBaseController',
        templateUrl: 'pages/admin/settings/car_types_groups/index.slim',
      },
    },
  });
});
