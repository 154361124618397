angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.charts.revenue', {
    url: '/revenue',
    tab: 'revenue',
    data: {
      permission: 'IncomeReportTab'
    },
    views: {
      'content@app.wash.charts': {
        controller: 'WashChartsRevenueController',
        templateUrl: 'pages/wash/charts/revenue/index.slim'
      }
    }
  });
});
