angular.module('RocketWash').controller('WashDashboardReservationsCreateController', (
  $scope, $uibModal, rwApi, userSession, reservationForm, alertService
) => {
  $scope.rf = reservationForm;

  reservationForm.config.setDefaultPopupMode();

  $scope.$on('$destroy', () => {
    reservationForm.resetAll();
  });

  rwApi.sendRequest({
    method: 'GET',
    path: 'devices',
    params: {
      service_location_id: userSession.service_location_id
    }
  }).then((devices) => {
    $scope.rocketKassaDevices = devices.filter(device => device.type === "ModuleKassaDevice")
  });

  // cancel new payments with plastic card if user clicked on "save" instead of "print"
  $scope.saveReservation = () => {

    $scope.reservation = $scope.rf.reservations.reservation
    let reservation_created_at = new Date($scope.reservation.created_at)
    let reservation_paid_at = new Date($scope.reservation.paid_at)

    if (userSession.can('access', 'ReservationAllowChangePaidAt') ? false : reservation_paid_at < reservation_created_at && !!$scope.reservation.paid_at) {
      alertService.show({
        text: 'Дата оплаты не может быть раньше чем дата заказа',
        type: 'error',
      });
      return;
    }

    // validate only new payments
    if (!$scope.rf.payments.grouped.undefined) return $scope.rf.reservations.save()

    const anyKassaDevices = !_.isEmpty($scope.rocketKassaDevices)
    const containsCardPayments = !_.isEmpty($scope.rf.payments.all.filter((payment) => payment.payment_destination_id.match(/^5_/)))

    if (anyKassaDevices && containsCardPayments) {
      $uibModal.open({
        templateUrl: 'pages/common/confirmation-popup/index.slim',
        controller: 'CommonConfirmationPopupController',
        resolve: {
          config: {
            title: 'Сохранение заказа',
            text: `Внимание! Чек не будет распечатан.`,
            buttons: [
              {
                text: 'Отмена',
                class: 'btn blue-btn fix-btn',
                click: (scope) => {
                  scope.$dismiss();
                }
              },
              {
                text: 'Ок',
                class: 'right btn white-btn fix-btn',
                click: () => {
                  $scope.rf.payments.regroupPayments()

                  $scope.rf.reservations.save()
                }
              },
            ]
          },
        },
      })
    } else {
      $scope.rf.payments.regroupPayments()

      $scope.rf.reservations.save()
    }
  }
});
