angular.module('RocketWash').factory(
  'PrintRequest',
  ($http, railsResourceFactory, userSession) => {
    const resource = railsResourceFactory({
      url: (context) => { return "/" + (context.queryRole || userSession.controllerScope()) + "/print_requests/" + (context.id || '') },
      name: 'print_request',
    });

    return resource;
  },
);
