import { env } from '@env';

angular.module('RocketWash').controller('WashIncomeEmployeesFormController', (
  $scope, $uibModal, userSession, User, Role, Job, resolvedEmployee,
  serverConstants, ServiceLocation, Employee, resolvedReadonly,
  alertService, $translate, Upload
) => {
  $scope.readonly = resolvedReadonly;
  $scope.serverConstants = serverConstants;
  $scope.userSession = userSession
  $scope.serviceLocations = [];
  $scope.progress = { upload: undefined, changePassword: false };

  $scope.changePasswordAllowed = ['organization_director', 'director'].includes($scope.userSession.getRole())

  ServiceLocation.query().then((serviceLocations) => {
    $scope.serviceLocations = serviceLocations;
  });

  $scope.employee = new Employee(resolvedEmployee);
  if (!$scope.employee.serviceLocationIds) {
    $scope.employee.serviceLocationIds = [];
  }
  $scope.employee.serviceLocationId = userSession.service_location_id;
  if ($scope.employee.id) {
    $scope.employee.get().then(() => {
      console.log('reloaded');
    });
  }

  Role.query().then((roles) => {
    $scope.roles = roles;
  });

  Job.query().then((jobs) => {
    $scope.jobs = jobs;
  });

  $scope.validate = () => {
    const errors = {};
    if (!$scope.employee.name) {
      errors.name = true;
    }
    if (!$scope.employee.phone) {
      errors.phone = true;
    }
    if (!$scope.employee.employeeStatus) {
      errors.employeeStatus = true;
    }
    if (!$scope.employee.jobId) {
      errors.job = true;
    }
    if (!$scope.employee.role) {
      errors.role = true;
    }
    if (Object.keys(errors).length === 0) {
      return true;
    }
    $scope.errors = { create_employee: errors };
    return false;
  };

  $scope.save = () => {
    if (!$scope.validate()) {
      return;
    }
    $scope.formDisabled = true;
    $scope.employee.save().then((employee) => {
      if (_.isEmpty(employee.errors)) {
        $scope.$dismiss();
      } else {
        $scope.errors = { create_employee: employee.errors };
      }
    }).finally(() => { $scope.formDisabled = false; });
  };

  $scope.changePassword = (employee) => {
    employee = new User(employee);
    $scope.progress.changePassword = true;
    $uibModal.open({
      templateUrl: 'pages/common/input-modal/index.slim',
      controller: 'CommonInputModalController',
      resolve: {
        config: {
          title: 'Смена пароля сотрудника',
          label: `Введите новый пароль`,
          buttons: [
            {
              text: 'Отмена',
              class: 'btn fix-btn blue-btn',
              click: (scope) => {
                scope.$dismiss();
              }
            },
            {
              text: 'Сохранить',
              class: 'right btn fix-btn white-btn',
              click: (scope) => {
                employee.changePassword(scope.input).then((employee) => {
                  if (_.isEmpty(employee.errors)) {
                    scope.$dismiss();
                  } else {
                    $scope.errors = { create_employee: employee.errors };
                  }
                })
              }
            },
          ]
        },
      },
    })
    $scope.progress.changePassword = false;
  };

  $scope.upload = (file) => {
    // File did not pass validation
    if (!file) {
      alertService.show({
        text: $translate.instant('employees.popup.photo_upload_error'),
        type: 'error',
      });
      return;
    }

    $scope.progress.upload = 0;
    Upload.upload({
      url: `${env.apiUrl}wash/employees/${$scope.employee.id}/change_avatar`,
      method: 'POST',
      data: { employee: { avatar: file } },
      withCredentials: true,
    }).then((_response) => {
      alertService.show({
        text: $translate.instant('employees.popup.photo_upload_success'),
      });
      $scope.progress.upload = undefined;
      $scope.employee.get();
    }, (_error) => {
      $scope.progress.upload = undefined;
    }, (evt) => {
      const progress = parseInt(100.0 * evt.loaded / evt.total, 10);
      console.log(`progress: ${progress}`);
      $scope.progress.upload = progress;
    });
  };
});
