angular.module('RocketWash').directive(
  'rwHelpArticle',
  ($state, rwHelpArticlesService, HelpArticle) => ({
    restrict: 'E',
    templateUrl: 'directives/rw-help-article/rw-help-article.slim',
    link: (scope) => {
      scope.fullscreen = false;
      scope.rwHelpArticlesService = rwHelpArticlesService;
    },
  }),
);
