angular.module('RocketWash').controller('WashIncomeSalaryBonusController', (
  $scope, $uibModal, $translate, $timeout, viewStore, Job, SalaryBonus
) => {
  $scope.calculation_periods = [
    { value: '0', label: 'day' },
    { value: '1', label: 'day_work_shift' },
    { value: '2', label: 'night_work_shift' },
    { value: '3', label: 'cash_shift' },
    { value: '4', label: 'work_shift' },
    { value: '5', label: 'weekly' },
    { value: '6', label: 'monthly' },
    { value: '7', label: 'quarterly' },
    { value: '8', label: 'annually' },
  ]

  $scope.types = [
    { value: '0', label: 'percent' },
    { value: '1', label: 'fix' },
  ]

  $scope.amountsOfOutput = [
    { value: '0', label: 'all_orders' },
    { value: '1', label: 'orders_with_percent_participation' },
    { value: '2', label: 'orders_without_percent_participation' },
  ]

  $scope.reservationStatuses = [
    { value: '0', label: 'completed_reservations' },
    { value: '1', label: 'completed_and_paid_reservations' },
    { value: '2', label: 'not_completed_and_paid_reservations' },
  ]

  $scope.pds = $scope.pageDataService.new({
    page: 'income_salary_bonus',
    filters: {
      attributes: [
        { name: 'name', type: 'string' },
        {
          name: 'calculation_period', type: 'enum', values: [
            { value: '0', label: 'day' },
            { value: '1', label: 'day_work_shift' },
            { value: '2', label: 'night_work_shift' },
            { value: '3', label: 'cash_shift' },
            { value: '4', label: 'work_shift' },
            { value: '5', label: 'weekly' },
            { value: '6', label: 'monthly' },
            { value: '7', label: 'quarterly' },
            { value: '8', label: 'annually' },
          ]
        },
        {
          name: 'salary_bonus_type', type: 'enum', values: [
            { value: '0', label: 'percent' },
            { value: '1', label: 'fix' },
          ]
        },
        {
          name: 'salary_bonus_sum', type: 'number'
        },
        {
          name: 'amount_of_output', type: 'enum', values: [
            { value: '0', label: 'all_orders' },
            { value: '1', label: 'orders_with_percent_participation' },
            { value: '2', label: 'orders_without_percent_participation' },
          ]
        },
        {
          name: 'calculation_from', type: 'number'
        },
        {
          name: 'calculation_to', type: 'number'
        },
        {
          name: 'reservation_status', type: 'enum', values: [
            { value: '0', label: 'completed_reservations' },
            { value: '1', label: 'completed_and_paid_reservations' },
            { value: '2', label: 'not_completed_and_paid_reservations' },
          ]
        }
      ],
      scopes: ['name'],
      beforeSend: (filters) => {
        filters.push({
          attr: 'job_id',
          condition: 'equals',
          value: $scope.jobId || 0
        })
      },
    },
    pg: {
      url: '/wash/salary_bonuses',
      model: SalaryBonus,
      query: {
        skipDateFilter: true,
        predicate: 'name',
      },
    },
    definePaginateMethodsOn: $scope,
  });

  $scope.updateActiveJob = () => {
    viewStore.store({ activeJob: $scope.jobId })
    $scope.updatePage()
  }

  Job.query().then(jobs => {
    $scope.jobs = jobs
    $scope.jobId = jobs[0].id
    $scope.updateActiveJob()
  })

  $scope.delete = (salaryBonus) => {
    if (confirm($translate.instant('alert.are_you_sure'))) {
      salaryBonus.delete().then(() => {
        $scope.pds.pg.items = _.without($scope.pds.pg.items, salaryBonus);
      });
      console.log('deleted')
    }
  };

  $scope.openFormPopup = (salaryBonus) => {
    $uibModal.open({
      templateUrl: 'pages/wash/income/salary_bonus/form.slim',
      controller: 'WashIncomeSalaryBonusFormController',
      resolve: {
        resolvedSalaryBonus: () => salaryBonus,
        resolvedReadonly: false,
      },
    }).result.finally(() => {
      $timeout($scope.resetAndUpdate, 1000);
    });
  };
});
