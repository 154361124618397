angular.module('RocketWash').directive('ngClickOutside', ($document) => {
  return {
    restrict: 'A',
    scope: {
      ngClickOutside: '=',
    },
    link: (scope, element, _attrs) => {
      const callback = (event) => {
        const clickInside = element.find(event.target).length > 0;
        if (!clickInside && typeof scope.ngClickOutside === 'function') {
          scope.$apply(() => {
            scope.ngClickOutside(clickInside);
          });
        }
      };
      $document.on('click', callback);

      scope.$on('$destroy', () => {
        $document.off(callback);
      });
    },
  };
});

