angular.module('RocketWash').service('reservationFormMarketing', ($injector, rwApi, userSession) => {
  const service = {
    tags: null,
    calculated_tags: null,
    user_category: null,

    loadAll: () => {
      if (!service.tags) {
        const rf = $injector.get('reservationForm');

        return rwApi.sendRequest({
          method: 'GET',
          path: 'reservation_form/marketing',
          params: {
            service_location_id: userSession.service_location_id,
            user_id: rf.carContractor.car.contractor.id,
          },
        }).then((data) => {
          service.tags = data.tags;
          service.calculated_tags = data.calculated_tags;
          service.user_category = data.user_category;
        });
      } else {
        return Promise.resolve();
      }
    },

    addTag: (tag) => {
      service.tags = service.tags || [];
      service.tags.push(tag);
    },

    createTag: (tag) => {
      return rwApi.sendRequest({
        method: 'POST',
        path: 'reservation_form/marketing/tags',
        data: {
          body: tag.body
        },
      });
    },

    searchTags: (query) => {
      return rwApi.sendRequest({
        method: 'GET',
        path: 'reservation_form/marketing/tags/search',
        params: {
          query
        },
      });
    },

    prepareTagsForSave: () => {
      return service.tags;
    },

    reset: () => {
      service.tags = null;
      service.calculated_tags = null;
      service.user_category = null;
    }
  };

  return service;
});
