angular.module('RocketWash').config(($stateProvider) => {
  $stateProvider.state('ya_fuel_reservations', {
    url: '/ya_fuel_reservations?org_param',
    controller: 'YaFuelReservationsController',
    templateUrl: 'pages/wash/ya_fuel_reservations/ya_fuel.slim',
    data: {
      permission: 'SignUp',
      publicPage: true,
    },
    resolve: {
      _serverTranslations: () => {
        return window.translationsPromise;
      },
    },
  });
});
