import {env} from '@env';

angular.module('RocketWash').controller('AppController', ($scope, $window, alertService) => {
  $scope.env = env;
  $scope.translationsLoaded = () => {
    return window.translationsLoaded;
  };

  // if (!$window.navigator.userAgent.match(/Chrome/)) {
  //   alertService.show({
  //     text: `
  //       <i class="icon icon-sprite warning-icon"></i>
  //       Для корректной работы приложения установите браузер
  //       <a class="blue-link" target="_blank" href="https://www.google.ru/chrome/">Google Chrome</a>
  //     `.trim().replace('\n', ' '),
  //     type: 'warning',
  //     timeout: 60000,
  //   });
  // }
});
