angular.module('RocketWash').controller(
  'WashFinanceWashFinancialOperationsBaseController',
  (
    $scope, $translate, $uibModal, $http, $filter,
    userSession, FinancialCenter, FinancialOperation,
  ) => {
    if ($scope.userSession.service_location.yandex_fuel_available) {
      $scope.financialCentersYaFuelId = $scope.userSession.organization.financial_center_yandex_fuel_balance.id
    }
    $scope.userSession.organization.financial_center_yandex_fuel_balance.id;

    const FINANCIAL_OPERATION_TYPES = ["financial_operation_income_client_bonuses",
      "financial_operation_income_employee_salary",
      "financial_operation_income_cash_from_reservation",
      "financial_operation_income_card_from_reservation",
      "financial_operation_income_manual_replenishment",
      "financial_operation_internal_transfer",
      "financial_operation_outcome_buy_consumables",
      "financial_operation_outcome_online_reservation_payment",
      "financial_operation_outcome_pay_salary",
      "financial_operation_outcome_sms_payment",
      "financial_operation_outcome_spend_bonuses",
      "financial_operation_income_prepayment_cash",
      "financial_operation_income_prepayment_card",
      "financial_operation_income_prepayment_wire",
      "financial_operation_outcome_spend_internal_client_money",
      "financial_operation_outcome_manual_expenditure",
      "financial_operation_correction",
      "financial_operation_periodic_charge",
      "financial_operation_external_payment",
      "financial_operation_income_supplier_reward",
      "financial_operation_outcome_supplier_payment",
      "financial_operation_income_mobile_payment",
      "financial_operation_income_manual_to_rw_balance",
      "financial_operation_outcome_manual_from_rw_balance",
      "financial_operation_correction_rw_balance"
    ];

    $scope.pds = $scope.pageDataService.new({
      page: 'wash_financial_operations',
      filters: {
        attributes: [
          { name: 'date', type: 'datetime' },
          { name: 'created_at', type: 'datetime' },
          { name: 'ordinal', type: 'number' },
          { name: 'amount', type: 'number' },
          { name: 'record_type', type: 'string' },
          { name: 'comment', type: 'string' },
          { name: 'document', type: 'string' },
          { name: 'finance_clause', type: 'string' },
          { name: 'from_financial_center_name', type: 'string' },
          { name: 'to_financial_center_name', type: 'string' },
          { name: 'from_second_financial_center_name', type: 'string' },
          { name: 'to_second_financial_center_name', type: 'string' },
        ],
        scopes: ['record_type', 'comment', 'document', 'finance_clause', 'created_at',
          'from_financial_center_name', 'to_financial_center_name',
          'from_second_financial_center_name',
          'to_second_financial_center_name'],
      },
      pg: {
        url: '/wash/financial_operations',
        model: FinancialOperation,
        query: {
          fromToField: 'date',
          predicate: '-date',
          reverse: true,
          per: 10,
          page: 1,
        },
      },
      definePaginateMethodsOn: $scope,
    });

    $scope.isUpdateAllowed = function (fo) {
      let canUpdateFinancialOperation = userSession.can('access', 'UpdateFinancialOperation');
      let isFcYaFuelId = (fo.fromFinancialCenterId || fo.toFinancialCenterId) === $scope.financialCentersYaFuelId;
      let YaFoEditAllowed = isFcYaFuelId ? userSession.superuser : true
      return canUpdateFinancialOperation && YaFoEditAllowed;
    };

    $scope.pdsTotals = () => {
      let existingTypes = Object.keys($scope.pds.pg.total || {}).filter(x => x != 'count');
      let absentTypes = FINANCIAL_OPERATION_TYPES.filter(exs => !existingTypes.some(nexs => exs === nexs));

      var absentValues = {};
      angular.forEach(absentTypes, (type) => {
        return absentValues[type] = 0;
      });

      let result = Object.assign({}, $scope.pds.pg.total, absentValues);

      return result;
    };

    $scope.rightMenuConfig = {
      buttons: [
        {
          type: 'export_xls',
          text: $translate.instant('buttons.export_xls'),
          action: () => { $scope.exportXLS(); },
          ngDisabled: () => (
            !$scope.isEnable()
          ),
        },
      ],
      tooltip: () => (
        !$scope.isEnable() ? $translate.instant('buttons.tooltip_message') : undefined
      ),
    };

    $scope.exportXLS = () => {
      $uibModal.open({
        templateUrl: 'pages/common/email_popup/email_popup.slim',
        controller: 'CommonEmailPopupController',
        windowClass: 'small',
        size: 'sm',
      }).result.then((email) => {
        const options = angular.extend({ email }, $scope.pds.pg.query);
        return $http({
          url: `${$scope.env.apiUrl}/wash/financial_operations.xlsx`,
          method: 'GET',
          params: options,
        }).then(() => alertService.show({
          text: $filter('translate')('email_popup.success'),
        }));
      });
    };

    $scope.isEnable = () => (
      moment($scope.pds.pg.query.to).diff($scope.pds.pg.query.from, 'hours') <= 745
    );

    FinancialCenter.query({
      service_location_id: userSession.service_location_id,
      type: 'FinancialCenterForWashAndLegalEntity',
    }).then((fcs) => {
      $scope.fcs = fcs;
    });

    $scope.openFormPopup = (fo) => {
      $uibModal.open({
        templateUrl: 'pages/wash/finance/wash_financial_operations/form.slim',
        controller: 'WashFinanceWashFinancialOperationsFormController',
        resolve: {
          resolvedFinancialOperation: () => fo,
        },
      }).result.finally(() => {
        setTimeout($scope.updatePage, 1000);
      });
    };
  },
);
