angular.module('RocketWash').factory(
  'FinancialCenter',
  (
    railsResourceFactory, userSession, $http, $translate,
    TenantSwitchInterceptor,
  ) => {
    const resource = railsResourceFactory({
      url: (context) => { return "/" + (context.queryRole || userSession.controllerScope()) + "/financial_centers/" + (context.id || '') },
      name: 'financial_center',
    });

    const decapitalize = string => (string.charAt(0).toLowerCase() + string.substring(1));

    resource.prototype.anyAttr = function anyAttr(key) {
      return (
        this[`org${key}`] ||
        (this.organization && this.organization[decapitalize(key)]) ||
        this[`sl${key}`] ||
        (this.serviceLocation && this.serviceLocation[decapitalize(key)]) ||
        this[`user${key}`] ||
        (this.user && this.user[decapitalize(key)]) ||
        this[decapitalize(key)] ||
        this[key] ||
        ''
      );
    };

    resource.prototype.accessPermitted = function () {
      if (userSession.is('admin')) {
        return true;
      }
      return (this.effectiveServiceLocationIds || [])
        .includes(userSession.service_location && userSession.service_location.id);
    };

    resource.prototype.typeaheadLabel = function typeahead() {
      const le = $translate.instant('models.financial_center.legal_entity');
      const sl = $translate.instant('models.financial_center.service_location');
      const remaining = $translate.instant('models.financial_center.remaining');
      const amount = (this.accessPermitted() || !this.hideBalance) ? this.amount : 'скрыт';
      const labels = (function labelSwitch() {
        // use types only for elasticsearch
        switch (this.recordType) {
        case 'FinancialCenterEmployeeSalaryBalance':
          return [`${this.userName} ${this.userPhone}`, `${remaining}: ${amount}`];
        case 'FinancialCenterForWashAndLegalEntity':
          return [`${this.fcName}, ${sl}: ${this.serviceLocationName}`, `${remaining}: ${amount}, ${le}: ${this.legalEntityName}`];
        case 'FinancialCenterRocketwashBalance':
          return [`${this.organizationName}`, `${remaining}: ${amount}`];
        case 'FinancialCenterUserMoneyBalance':
          return [`${this.userName} ${this.userPhone}`, `${remaining}: ${amount}`];
        case 'FinancialCenterUserBonusesBalance':
          return [`${this.userName} ${this.userPhone}`, `${remaining}: ${amount}`];
        case 'FinancialCenterYandexFuelBalance':
          if (userSession.superuser) {
            return [`${this.name}`, `${remaining}: ${amount}`];
          } else {
            return;
          }
        default:
          return [`${this.anyAttr('Name')} ${this.anyAttr('Phone')} ${this.fcName || this.name || ''}`, `${remaining}: ${amount}`];
        }
      }).call(this);
      const translation = $translate
        .instant(`models.financial_center.types.${this.recordType || this.type}`);
      
      if (!userSession.superuser && this.recordType === 'FinancialCenterYandexFuelBalance') {
        return;
      } else {
        labels[1] = [`${translation}.`, labels[1]].join(' ');
        return labels;
      }

    };

    resource.typeahead = function typeahead(params, hideBalance) {
      return $http.post(
        `${this.$url()}/typeahead`,
        params,
        { performRootWrapping: false },
      ).then((response) => {
        const centers = response.data.map(center => new this(center));
        centers.forEach((c) => {
          c.hideBalance = hideBalance;
          c.typeahead = c.typeaheadLabel();
        });
        if (userSession.superuser) {
          return centers;
        } else {
          return centers.filter(c=> c.recordType !== 'FinancialCenterYandexFuelBalance');
        }
      });
    };

    resource.partnerCommonDetails = function partnerCommonDetails(params) {
      return $http.get(
        `${this.$url()}/partner_common_details`,
        params,
        { performRootWrapping: false },
      ).then((response) => {
        const partnerFinCenterSummary = response.data

        return partnerFinCenterSummary;
      });
    };

    resource.addInterceptor(TenantSwitchInterceptor);

    return resource;
  },
);
