angular.module('RocketWash').controller(
  'AdminSettingsHelpArticlesFormController',
  ($scope, $state, $stateParams, HelpArticle) => {
    if ($stateParams.id) {
      HelpArticle.get($stateParams.id).then((ha) => { $scope.helpArticle = ha; });
    } else {
      $scope.helpArticle = new HelpArticle();
    }

    $scope.save = () => {
      $scope.helpArticle.save().then(() => {
        $state.go('^');
      });
    };
  },
);
