import { env } from '@env';

angular.module('RocketWash').controller(
  'WashSettingsImportIndexController',
  (
    $scope, Upload, alertService, $translate, ImportTask, $http, rwConfirmationService,
  ) => {
    $scope.resources = [
      'Individual',
      'Entrepreneur',
      'Company',
      'BasicServiceList',
      'BasicServicePriceLists',
      'WarehouseProduct',
    ];

    $scope.progress = {};
    $scope.progressFor = (resource) => {
      return { progress: $scope.progress[resource] };
    };

    $scope.upload = (files, resource) => {
      // File did not pass validation
      if (files.length == 0) {
        alertService.show({
          text: $translate.instant('settings.import.file_is_invalid'),
          type: 'error'
        });
        return;
      }

      const promise = Upload.upload({
        url: `${env.apiUrl}wash/import_tasks`,
        data: { import_task:
          {file: files[0], resource: resource}
        },
        withCredentials: true,
      });

      $scope.progress[resource] = 0;

      promise.then(function(resp) {
        alertService.show({
          text: $translate.instant('settings.import.uploaded_successfully'),
        });
        $scope.progress[resource] = undefined;
      }, function(resp) {
        $scope.progress[resource] = undefined;
      }, function(evt) {
        const progress = parseInt(100.0 * evt.loaded / evt.total);
        console.log('progress: ' + progress);
        $scope.progress[resource] = progress;
      });
    };

    ImportTask.query().then(its => $scope.importTasks = its);


    const DESTROY_ALL_MAPPING = {
      BasicServiceList: 'BasicService',
      BasicServicePriceLists: 'PriceListItem',
      WarehouseProduct: 'Product',
    };

    $scope.destroyAll = (resource) => {
      if (DESTROY_ALL_MAPPING[resource]) {
        resource = DESTROY_ALL_MAPPING[resource];
      };

      rwConfirmationService.confirm(
        { textTranslation: 'alert.import_destroy_all_can_not_be_reverted' },
        () => {
          $http.post(`${env.apiUrl}wash/import_tasks/destroy_all`, {resource}).then(() => {
            alertService.show({
              text: 'Данные будут удалены в течение нескольких минут',
            });
          });
        },
      );
    };
  },
);
