angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.warehouse.consumables', {
    url: '/consumables',
    tab: 'consumables',
    data: {
      permission: 'WarehouseOperationsTab'
    },
    views: {
      'content@app.wash.warehouse': {
        controller: 'WashWarehouseConsumablesBaseController',
        templateUrl: 'pages/wash/warehouse/consumables/index.slim'
      }
    }
  });
});
