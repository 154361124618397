

angular.module('RocketWash').controller('AdminStatsController', function($scope, $translate, ServiceLocation, paginator) {
  $scope.wday = moment().day();
  $scope.pg = {
    url: '/admin/service_location_statistics',
    model: ServiceLocation,
    query: {
      searchQuery: '',
      predicate: 'name',
      reverse: true
    }
  };
  $scope.updatePage = function(page, limit) {
    if(_.isNumber(page)) {
      $scope.pg.query.page = page;
    } else {
      $scope.pg.query.page = 1;
      $scope.pg.query.reverse = !$scope.pg.query.reverse;
    }
    $scope.pg.query.per = limit || 10;
    paginator.getData($scope.pg);
  };
  $scope.updatePage(1);
  $scope.$on('search-change', function(ev, data) {
    $scope.pg.query.searchQuery = data.value;
    $scope.updatePage(1)
  });
});
