angular.module('RocketWash').controller('WashFinancialProjectsBaseController', (
  $scope, $state, $translate, userSession, FinancialProject, FinancialCenter, paginator, dateRanges,
) => {
  $scope.pds = $scope.pageDataService.new({
    page: 'completed_reservations',
    filters: {
      attributes: [
        { name: 'name', type: 'string' },
      ],
      scopes: [
        'name'
      ],
    },
    pg: {
      url: '/wash/financial_projects',
      model: FinancialProject,
      query: {
        skipDateFilter: true,
        page: 1,
        per: 10,
      },
    },
    definePaginateMethodsOn: $scope,
  });
  $scope.rightMenuConfig = {
    buttons: [
      { type: 'create', text: $translate.instant('buttons.create'), action: () => { $state.go('.form') } },
    ]
  };

  $scope.delete = (t) => {
    if (confirm($translate.instant('alert.are_you_sure'))) {
      t.delete().then(() => {
        $scope.updatePage();
      });
    }
  };
});
