angular.module('RocketWash').component('rwReservationCashCalculator', {
  templateUrl: 'components/rw-reservation/common/cash-calculator/index.slim',
  controllerAs: 'vm',
  controller: function ($scope, $translate, userSession, reservationForm, printService) {
    $scope.rf = reservationForm;
    $scope.open = false;

    $scope.values = [
      50,
      100,
      200,
      500,
      1000,
      2000,
    ];

    $scope.calculator = {
      paid_amount: undefined,
      change: undefined,
    };

    $scope.$watch('[rf.payments.total.paid_with_cash, calculator]', () => {
      const paid = parseFloat($scope.calculator.paid_amount) || 0;
      const change = paid - $scope.rf.payments.total.paid_with_cash;
      $scope.calculator.change = Math.max(change, 0).toFixed(2);
    }, true);

    $scope.clear = () => {
      $scope.calculator.paid_amount = undefined;
    };

    $scope.add = (value) => {
      const paid = parseFloat($scope.calculator.paid_amount) || 0;
      $scope.calculator.paid_amount = paid + value;
    };

  },
});
