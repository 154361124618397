angular.module('RocketWash').factory('Product', function($http, railsResourceFactory, railsSerializer, userSession) {
  var Factory;
  Factory = railsResourceFactory({
    url: (context) => { return "/" + (context.queryRole || userSession.controllerScope()) + "/products/" + (context.id || '') },
    name: 'product',
    serializer: railsSerializer(function() {
      this.resource('priceListItems', 'PriceListItem');
      this.nestedAttribute('priceListItems');
      return this.add('type', 'Product');
    })
  });

  Factory.all = (options) => {
    return Factory.$get(
      `${Factory.resourceUrl()}/all`,
      options,
    );
  };

  return Factory;
});
