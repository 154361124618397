angular.module('RocketWash').controller(
  'WashSettingsDashboardIndexController',
  (
    $scope, $state, userSession,
  ) => {
    $scope.buttons = [
      {
        name: 'demo_organization',
        class: 'fa fa-desktop',
        callback: () => {
          const demoSubdomain = userSession.organization.test_organization.subdomain_name;
          const demoHref = window.location.href.replace(/\/\/.+?\./, `//${demoSubdomain}.`);
          window.location.href = demoHref;
        },
        showIf: () => {
          return userSession.organization.test_organization;
        },
      },
      { name: 'control_panel', class: 'fa fa-arrow-up', sref: 'app.wash.dashboard.control_panel' },
      { name: 'import_data', class: 'fa fa-download', sref: 'app.wash.settings.import' },
    ]

    $scope.clickButton = (button) => {
      if (button.sref) {
        $state.go(button.sref);
      }

      if (button.callback) {
        button.callback();
      }
    }
  },
);
