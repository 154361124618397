

angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.services.summary_for_taxi', {
    url: '/summary_for_taxi',
    tab: 'summary_for_taxi',
    data: {
      permission: 'admin'
    },
    views: {
      'content@app.wash.services': {
        controller: 'WashServicesSummaryForTaxiController',
        templateUrl: 'pages/wash/services/summary_for_taxi/index.slim'
      }
    }
  });
});
