const TEMPLATE = `
<div class="shade rw-confirmation-modal">
  <div class="shade-click-interceptor" ng-click="$dismiss()"></div>
  <div class="popup">
     <div class="popup-block hide-overflow">
      <p class="subtitle-big"ng-bind-html="config.textTranslation | translate | multiline"></p>
      <div class="flex-container buttons mt-30">
        <a class="btn white-btn fix-btn" ng-click="$dismiss()" translate="buttons.cancel"></a>
        <input class="btn blue-btn fix-btn" ng-click="proceed()" type="submit" value="{{'buttons.proceed' | translate}}">
      </div>
    </div>
  </div>
</div>
`;

angular.module('RocketWash').service('rwConfirmationService', function($uibModal) {
  return {
    confirm: (config, callback) => {
      $uibModal.open({
        template: TEMPLATE,
        controller: ($scope) => {
          $scope.config = config;
          $scope.proceed = () => {
            callback();
            $scope.$dismiss();
          };
        },
      });
    }
  };
});
