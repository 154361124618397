angular.module('RocketWash').controller('WashCounterpartiesEditCabinetController', (
  $scope, $translate,
  Car, CarMake, CarModel, CarType, userSession, alertService,
) => {
  $scope.cabinetLink = $scope.contractor.personalCabinetUrl;

  $scope.sendPersonalCabinetLink = (params) => {
    if ($scope.contractor.email) {
      $scope.contractor.sendPersonalCabinetLink(params).then(() => {
        alertService.show({
          text: 'Сообщение было выслано',
        });
      });
    } else {
      alertService.show({
        text: 'Сообщение не было отправлено, почта не указана',
        type: 'error',
      });
    }
  };
});
