

angular.module('RocketWash').controller('WashSettingsDiscountActionsShowController', function($rootScope, $scope, $state, $filter, $stateParams, DiscountAction, DiscountActionStep) {
  var reloadDiscountActions;
  $scope.selected = {
    action: {}
  };
  (reloadDiscountActions = function() {
    return DiscountAction.query().then(function(data) {
      $scope.discountActions = data;
      $scope.selected.action = _.find($scope.discountActions, function(da) {
        return parseInt(da.id) === parseInt($stateParams.id);
      });
      return $scope.selected.actionSteps = $scope.selected.action.discountActionSteps;
    });
  })();
  $scope.newDiscountAction = function() {
    var action;
    action = new DiscountAction({
      name: $filter('translate')('settings.discount_actions.new_action_name'),
      priority: 0
    });
    return action.save().then(function(data) {
      return $state.go('app.wash.settings.discount_actions.show', {
        id: data.id
      }).then(function() {
        return reloadDiscountActions();
      });
    });
  };
  $scope.newDiscountActionStep = function(action) {
    var step;
    step = new DiscountActionStep({
      ordinal: 0,
      condition: [],
      discount_action_id: $scope.selected.action.id
    });
    return step.save().then(function(data) {
      if (data.id) {
        return $state.go('app.wash.settings.discount_actions.step_form', {
          id: data.id
        });
      }
    });
  };
  $scope.save = function(model) {
    model.save();
    return reloadDiscountActions();
  };
  $scope.goToNewDA = function() {
    if ($scope.selected.action && $scope.selected.action.id) {
      return $state.go('app.wash.settings.discount_actions.show', {
        id: $scope.selected.action.id
      });
    }
  };
  $scope.removeStep = function(step) {
    step = new DiscountActionStep(step);
    return step["delete"]().then(function() {
      return reloadDiscountActions();
    });
  };
  return $scope.saveStep = function(step) {
    step = new DiscountActionStep(step);
    return step.save();
  };
});
