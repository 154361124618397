import { env } from '@env';

angular.module('RocketWash').controller(
  'SignInController',
  function ($scope, $http, $timeout, $stateParams, $translate, $uibModal, userSession, alertService) {
    this.alertService = alertService;
    this.progress = { signIn: false };
    this.user = {phone: $stateParams.phone};
    this.subdomain = {name: null};
    this.subdomainSpecified = window.location.href.match(new RegExp(`^.+\\.(${env.appSubdomains})\\.rocketwash\\.`));
    this.currentYear = moment().format('YYYY');

    //$scope.googleAuthUrl = 'https://beta.rocketwash.me//session/auth/google_oauth2'

    const redirectIfSignedIn = () => {
      userSession.reload();
      userSession.promise.then(() => {
        if (userSession.isSignedIn()) {
          userSession.goToHomeState();
        };
      });
    };
    redirectIfSignedIn();

    if ($stateParams.just_registered) {
      $uibModal.open({
        templateUrl: 'pages/sign_in/just_registered_modal.slim',
      });
    }

    this.changeSubdomain = () => {
      if (!this.subdomain.name) { return };

      let newUrl = window.location.href.replace(
        new RegExp(`(https?:\\/\\/)(.*)(${env.appSubdomains})(\\.rocketwash[^\\/]*)(.*)`),
        `$1${this.subdomain.name}.$3$4$5`
      )

      window.location.href = newUrl;
    };

    this.signIn = () => {
      $timeout(() => {
        this.progress.signIn = true;
        $http.post('/session/sign_in', { user: this.user }).then(() => {
          this.progress.signIn = false;
          alertService.show({
            text: $translate.instant('landing_page.success'),
            type: 'success'
          });
          redirectIfSignedIn();
        }, () => {
          this.progress.signIn = false;
          console.log('Not signed in!');
          alertService.show({
            text: $translate.instant('landing_page.wrong_credentials'),
            type: 'error'
          });
        });
      });
    };
    $timeout(() => {
      if ($stateParams.phone) {
        $('#password-input').focus();
      } else {
        $('#phone-input').focus();
      };
    });
  },
);
