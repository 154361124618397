

angular.module('RocketWash').service('navigation', function($state) {
  var currentNavItem = '';

  return {
    setState: function(name) {
      currentNavItem = name;
    },
    getState: function() {
      return currentNavItem;
    }
  };
});
