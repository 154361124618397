angular.module('RocketWash').controller('AdminSettingsCarTypesGroupsBaseController', function($rootScope, $scope, $state, $translate, viewStore, CarTypesGroup, ResourceKind, Organization, serverConstants) {
  $scope.selected = {};

  Organization.allWithFilters().then((orgs) => {
    $scope.orgs = orgs;
  });

  $scope.reloadOrgCtgs = () => {
    CarTypesGroup.all({ organization_id: $scope.selected.org.id }, { queryRole: 'wash' }).then(function(data) {
      $scope.orgCtgs = data;
      $scope.selected.ctg = $scope.orgCtgs[0];
    });
  };

  const reloadCTG = () => {
    CarTypesGroup.query().then(function(groups) {
      return $scope.groups = _.map(groups, function(t) {
        return new CarTypesGroup(t);
      });
    });
  };
  reloadCTG();

  $scope.newCarTypesGroup = function() {
    return $scope.groups.unshift(new CarTypesGroup({
      name: ''
    }));
  };
  $scope.save = function(type) {
    return type.save();
  };
  $scope["delete"] = function(type) {
    type["delete"]();
    return $scope.groups = _.without($scope.groups, type);
  };

  $scope.duplicateFromOrg = () => {
    CarTypesGroup.duplicateFromOrganization({
      organization_id: $scope.selected.org.id,
      car_types_group_id: $scope.selected.ctg.id,
    }).then(() => {
      $scope.selected.org = undefined;
      reloadCTG();
    });
  };
});
