

angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.admin.service_locations.dashboard', {
    url: '/dashboard',
    views: {
      'content@app.admin.service_locations': {
        controller: 'AdminServiceLocationsDashboardController',
        templateUrl: 'pages/admin/service_locations/dashboard/index.slim'
      }
    }
  });
});
