angular.module('RocketWash').controller('AdminSettingsGlobalMobileServicesFormController', (
  $scope, GlobalMobileService, resolvedGlobalMobileService,
) => {

  $scope.types = [
    'GlobalMobileServiceBasic',
    'GlobalMobileServiceExtra',
  ];

  $scope.globalMobileService = new GlobalMobileService(resolvedGlobalMobileService);
  $scope.globalMobileService.type = $scope.globalMobileService.type || 'GlobalMobileServiceBasic';

  if ($scope.globalMobileService.id) {
    $scope.globalMobileService.get().then(() => {
      console.log('reloaded');
    });
  }

  $scope.save = () => {
    $scope.formDisabled = true;
    $scope.globalMobileService.save().then((globalMobileService) => {
      if (_.isEmpty(globalMobileService.errors)) {
        $scope.$dismiss();
      } else {
        $scope.errors = { create_global_mobile_service: globalMobileService.errors };
      }
    }).finally(() => { $scope.formDisabled = false; });
  };
});
