angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  $stateProvider.state('app.taxi.autopark.cars', {
    url: '/cars',
    tab: 'cars',
    views: {
      'content@app.taxi.autopark': {
        controller: 'TaxiAutoparkCarsController',
        templateUrl: 'pages/taxi/autopark/cars/index.slim',
      },
    },
  }).state('app.taxi.autopark.cars.form', {
    url: '/form',
    views: {
      'content@app.taxi.autopark': {
        controller: 'TaxiAutoparkCarsFormController',
        templateUrl: 'pages/taxi/autopark/cars/form.slim'
      }
    }
  });
});
