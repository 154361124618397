angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.charts.contractors', {
    url: '/contractors',
    tab: 'contractors',
    data: {
      permission: 'ContractorsReportTab'
    },
    views: {
      'content@app.wash.charts': {
        controller: 'WashChartsContractorsController',
        templateUrl: 'pages/wash/charts/contractors/index.slim'
      }
    }
  });
});
