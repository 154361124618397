angular.module('RocketWash').config(($stateProvider) => {
  $stateProvider.state('app.admin.finance', {
    url: '/finance',
    abstract: true,
    templateUrl: 'pages/admin/finance/index.slim',
    data: {
      permission: 'SettingsMenuItem',
      tabs: ['financial_operations'],
    },
  });
});
