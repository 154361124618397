angular.module('RocketWash').controller(
  'TaxiSettingsGeneralController',
  (
    $scope, $route, $location, $window,
    userSession, SettingValue, serverConstants, Organization, TimeZone,
  ) => {
    $scope.searchQuery = '';
    $scope.predicate = 'name';
    $scope.reverse = true;

    TimeZone.query().then((timeZones) => {
      $scope.timeZones = timeZones;
    });


    $scope.loadOrganization = () => {
      Organization.get(userSession.organization.id).then((organization) => {
        $scope.organization = organization;
      });
    };

    const reloadSettingValues = () => {
      SettingValue.query().then((settings) => {
        $scope.settings = [];

        const enable_yt = settings.find(x => x.key === 'enable_yandex_taxi_integration');
        const yt_park_id = settings.find(x => x.key === 'yandex_taxi_park_id');
        const yt_client_id = settings.find(x => x.key === 'yandex_taxi_client_id');
        const yt_api_key = settings.find(x => x.key === 'yandex_taxi_api_key');
        const yt_pay_from = settings.find(x => x.key === 'yandex_taxi_pay_from');

        $scope.settings.push(enable_yt);
        if (enable_yt && enable_yt.value == 'yes') {
          $scope.settings.push(yt_api_key);
          $scope.settings.push(yt_client_id);
          $scope.settings.push(yt_park_id);
          $scope.settings.push(yt_pay_from);
        }
      });
    };
    reloadSettingValues();

    $scope.SETTING_VALUES = serverConstants.setting_values;
    $scope.$on('search-change', (_event, data) => {
      $scope.searchQuery = data.value;
    });
    $scope.save = function (setting) {
      setting.save().then(() => {
        userSession.reload();
        $route.reload();
        reloadSettingValues();
      });
    };

    $scope.saveOrganization = () => {
      $scope.organization.save().then(
        (response) => {
          const newDomain =
            `${response.subdomainName}.${$location.host().split('.').splice(1).join('.')}`;
          const newUrl = `${$location.protocol()}://${newDomain}:${$location.port()}/`;
          $window.location.href = newUrl;
        },
        (error) => {
          console.log(error);
          $scope.loadOrganization();
        },
      );
    };

    $scope.loadOrganization();
  },
);
