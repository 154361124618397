/* eslint-disable no-bitwise */
angular.module('ngLocale', [], ['$provide', function ($provide) {
  const PLURAL_CATEGORY = {
    ZERO: 'zero', ONE: 'one', TWO: 'two', FEW: 'few', MANY: 'many', OTHER: 'other',
  };
  function getDecimals(n) {
    n = `${n}`;
    const i = n.indexOf('.');
    return (i === -1) ? 0 : n.length - i - 1;
  }

  function getVF(n, optPrecision) {
    let v = optPrecision;

    if (undefined === v) {
      v = Math.min(getDecimals(n), 3);
    }

    const base = 10 ** v;
    const f = ((n * base) | 0) % base;
    return { v: v, f: f };
  }

  $provide.value('$locale', {
    DATETIME_FORMATS: {
      AMPMS: [
        'ДП',
        'ПП',
      ],
      DAY: [
        'Воскресенье',
        'Понедельник',
        'Вторник',
        'Среда',
        'Четверг',
        'Пятница',
        'Суббота',
      ],
      ERANAMES: [
        'до Рождества Христова',
        'от Рождества Христова',
      ],
      ERAS: [
        'до н. э.',
        'н. э.',
      ],
      FIRSTDAYOFWEEK: 0,
      MONTH: [
        'Январь',
        'Февраль',
        'Марта',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь',
      ],
      SHORTDAY: [
        'вс',
        'пн',
        'вт',
        'ср',
        'чт',
        'пт',
        'сб',
      ],
      SHORTMONTH: [
        'янв.',
        'февр.',
        'мар.',
        'апр.',
        'мая',
        'июн.',
        'июл.',
        'авг.',
        'сент.',
        'окт.',
        'нояб.',
        'дек.',
      ],
      STANDALONEMONTH: [
        'январь',
        'февраль',
        'март',
        'апрель',
        'май',
        'июнь',
        'июль',
        'август',
        'сентябрь',
        'октябрь',
        'ноябрь',
        'декабрь',
      ],
      WEEKENDRANGE: [
        5,
        6,
      ],
      fullDate: "EEEE, d MMMM y 'г'.",
      longDate: "d MMMM y 'г'.",
      medium: "d MMM y 'г'. H:mm:ss",
      mediumDate: "d MMM y 'г'.",
      mediumTime: 'H:mm:ss',
      short: 'dd.MM.y H:mm',
      shortDate: 'dd.MM.y',
      shortTime: 'H:mm',
    },
    NUMBER_FORMATS: {
      CURRENCY_SYM: '₽',
      DECIMAL_SEP: ',',
      GROUP_SEP: ' ',
      PATTERNS: [
        {
          gSize: 3,
          lgSize: 3,
          maxFrac: 3,
          minFrac: 0,
          minInt: 1,
          negPre: '-',
          negSuf: '',
          posPre: '',
          posSuf: '',
        },
        {
          gSize: 3,
          lgSize: 3,
          maxFrac: 2,
          minFrac: 2,
          minInt: 1,
          negPre: '-',
          negSuf: ' ¤',
          posPre: '',
          posSuf: ' ¤',
        },
      ],
    },
    id: 'ru-ru',
    localeID: 'ru_RU',
    pluralCat: function (n, optPrecision) {
      const i = n | 0;
      const vf = getVF(n, optPrecision);
      if (vf.v === 0 && i % 10 === 1 && i % 100 !== 11) {
        return PLURAL_CATEGORY.ONE;
      }
      if (vf.v === 0 && i % 10 >= 2 && i % 10 <= 4 && (i % 100 < 12 || i % 100 > 14)) {
        return PLURAL_CATEGORY.FEW;
      }
      if (
        vf.v === 0 && i % 10 === 0 ||
        vf.v === 0 && i % 10 >= 5 && i % 10 <= 9 ||
        vf.v === 0 && i % 100 >= 11 && i % 100 <= 14
      ) {
        return PLURAL_CATEGORY.MANY;
      }
      return PLURAL_CATEGORY.OTHER;
    },
  });
}]);
