angular.module('RocketWash').directive(
  'rwSelectAll',
  () => ({
    restrict: 'E',

    scope: {
      pg: '=',
      reset: '=',
    },

    templateUrl: 'directives/rw-select-all/rw-select-all.slim',

    link(scope, _element, _attrs) {
      const resetSelected = () => {
        scope.pg.selectedKeys = [];
        scope.pg.selectAll = false;
      };
      scope.$watch('reset', resetSelected, true);

      const saveSelected = (items) => {
        (items || []).forEach((item) => {
          if (item.selected) {
            if (!scope.pg.selectedKeys.includes(item.id)) {
              scope.pg.selectedKeys.push(item.id);
            }
          } else {
            scope.pg.selectedKeys = scope.pg.selectedKeys.filter(x => x !== item.id);
          }
        });
      };
      const updatePageSelectedFlag = () => {
        scope.pg.pageSelected =
          scope.pg.items.every(item => item.selected) && scope.pg.items.length > 0;
      };
      const updateSelection = (items) => {
        saveSelected(items);
        updatePageSelectedFlag();
      };
      scope.$watch('pg.items', updateSelection, true);

      scope.showSelector = () => (
        scope.pg.items.every(item => item.selected) && scope.pg.items.length > 0
      );
      scope.selectAll = () => {
        scope.pg.selectAll = !scope.pg.selectAll;
        if (!scope.pg.selectAll) {
          scope.pg.selectedKeys = [];
          scope.pg.pageSelected = false;
          scope.pg.items.forEach((item) => {
            item.selected = false;
          });
        }
      };
    },
  }),
);
