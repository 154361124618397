angular.module('RocketWash').controller('RwSaveFilterGroupModalController', function($scope, filterGroup) {
  $scope.filterGroup = filterGroup;

  $scope.save = function() {
    filterGroup.save().then(function() {
      $scope.$close({success: true});
    }, function() {
      console.log('Error while saving filter group');
    });
  }
});
