angular.module('RocketWash').config(($stateProvider) => {
  $stateProvider.state('app.wash.organization.statistics', {
    url: '/statistics',
    tab: 'statistics',
    data: {
      permission: 'OrganizationStatisticsTab',
    },
    views: {
      'content@app.wash.organization': {
        controller: 'WashOrganizationStatisticsController',
        templateUrl: 'pages/wash/organization/statistics/index.slim'
      },
    },
    controller: 'WashOrganizationStatisticsController',
  });
});
