import { env } from '@env';

angular.module('RocketWash').controller(
  'TaxiAnalyticsGeneralController',
  ($scope, $http) => {

    $scope.data = {};

    return $http({
      method: 'GET',
      url: `${env.apiUrl}taxi/analytics.json`,
    }).then(function(response) {
      angular.extend($scope.data, response.data);
    })
  },
);
