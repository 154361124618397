angular.module('RocketWash').controller(
  'WashSettingsWarehouseIndexController',
  (
    $scope, $state, userSession, Warehouse, alertService, rwConfirmationService,
  ) => {
    $scope.clean = (key) => {

      rwConfirmationService.confirm(
        { textTranslation: 'Данная операция НЕОБРАТИМА! Вы точно хотите продолжить?' },
        () => {
          Warehouse.clean(key).then(() => {
            alertService.show({
              text: 'Удаление запущено. Полное удаление может занять длительное время.',
            });
          });
        },
      );
    };
  },
);
