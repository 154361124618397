angular.module('RocketWash').directive('rwCarTypesGroupSelect', () => ({
  restrict: 'E',
  replace: true,
  templateUrl: 'directives/rw-car-types-group-select/index.slim',
  scope: {
  },
  controller: (
    $scope, ctgSelectService
  ) => {
    $scope.ctgSelectService = ctgSelectService;
  },
}));
