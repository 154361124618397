angular.module('RocketWash').directive('rwShouldChangePlanAfterFreeTrial', () => ({
  restrict: 'E',
  templateUrl: 'directives/rw-should-change-plan-after-free-trial/index.slim',
  controller: (
    $scope, userSession, Plan,
  ) => {
    userSession.promise.then(() => {
      const trialEnd = $scope.userSession.organization.free_trial_ends_at;
      const onTrial = trialEnd && moment(trialEnd).diff(moment(), 'seconds') >= 0;
      const endsSoon = trialEnd && moment(trialEnd).diff(moment(), 'days') <= 5;

      $scope.shouldShow = endsSoon && onTrial && !userSession.service_location.plan_after_free_trial_id;
    });

    Plan.query().then((plans) => {
      $scope.defaultPlan = $scope.plans.find(plan => plan.default);
    });

  },
}));
