angular.module('RocketWash').factory(
  'PriceListItemForTaxi',
  ($http, railsResourceFactory, userSession) => {
    const resource = railsResourceFactory({
      url: (context) => { return "/" + (context.queryRole || userSession.controllerScope()) + "/price_list_item_for_taxis/" + (context.id || '') },
      name: 'price_list_item_for_taxi',
    });

    return resource;
  },
);
