angular.module('RocketWash').filter('humanizeDurationToDivs', function ($filter) {
  return function (minutes) {
    let string = $filter('humanizeDuration')(minutes);
    string = string.replace(/\./g, '');
    let result = string.split(' и ');
    result = result.map((part) => {
      const parts = part.split(' ');
      return `<div class='time-frame-digits'>${parts[0]}</div><div class='time-frame-text'>${parts[1]}</div>`
    }).join('');
    return `<div class='time-frame'>${result}</div>`;
  };
});
