angular.module('RocketWash').directive('rwRecognizedImagePreview', () => ({
  restrict: 'E',
  templateUrl: 'directives/rw-recognized-image-preview/index.slim',
  scope: {
    url: '=',
    objects: '=',
    showTable: '=',
    imageLoaded: '&',
  },
  controller: ($scope, $element, $timeout) => {
    $scope.ready = true;
    $scope.updateBoundingBoxes = () => {
      const img = $element.find('img');
      const width = img.width();
      const height = img.height();

      ($scope.objects || []).forEach((obj) => {
        obj.style = {
          width: `${obj.widthPercent * width}px`,
          height: `${obj.heightPercent * height}px`,
          left: `${obj.leftPercent * width}px`,
          top: `${obj.topPercent * height}px`,
        };
      });

      if ($scope.imageLoaded) {
        $scope.imageLoaded();
      }
    };
  },
}));
