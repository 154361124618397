angular.module('RocketWash').service('dateRanges', () => ({
  day: () => [moment().startOf('day'), moment().add(1, 'day').startOf('day')],
  yesterday: () => [moment().startOf('day').subtract(1, 'day'), moment().startOf('day')],
  week: () => [moment().startOf('week'), moment().startOf('week').add(7, 'days')],
  month: () => [moment().startOf('month'), moment().startOf('month').add(1, 'month')],
  last_month: () => [moment().startOf('month').subtract(1, 'month'), moment().startOf('month')],
  rolling_month: () => [
    moment().add(1, 'day').startOf('day').subtract(1, 'month'),
    moment().add(1, 'day').startOf('day'),
  ],
  default: () => [moment().startOf('day'), moment().add(1, 'day').startOf('day')],
}));
