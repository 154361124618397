angular.module('RocketWash').config(($stateProvider, $urlRouterProvider) => {
  return $stateProvider.state('app.wash.marketing', {
    url: '/marketing',
    abstract: true,
    controller: 'WashMarketingBaseController',
    templateUrl: 'pages/wash/marketing/index.slim',
    data: {
      permission: 'MarketingMenuItem',
      tabs: ['reservation_reviews'],
    },
  })
});
