

angular.module('RocketWash').factory('Trade', function(railsResourceFactory, railsSerializer, userSession) {
  return railsResourceFactory({
    url: (context) => { return "/" + (context.queryRole || userSession.controllerScope()) + "/trades/" + (context.id || '') },
    name: "trade",
    serializer: railsSerializer(function() {
      return this.nestedAttribute('tradeItems');
    })
  });
});
