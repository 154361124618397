angular.module('RocketWash').controller('AdminOnlineReservationsMobileUsersSendNotificationController', (
  $scope, $http, selectedFilters
) => {
  $scope.notification = {
    type: 'push',
    filters: JSON.stringify(selectedFilters),
  }

  $scope.send = () => {
    $http.post('/admin/notifications/bulk', $scope.notification).finally(() => {
      $scope.saveDisabled = false;
      $scope.$dismiss();
    });
  }
})
