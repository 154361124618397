angular.module('RocketWash').controller(
  'WashPrintedDocumentsReconciliationReportController',
  (
    $scope, PrintedDocument
  ) => {
    $scope.pds = $scope.pageDataService.new({
      page: 'reconciliation_report_printed_documents',
      filters: {
        attributes: [{
            name: 'time_from',
            type: 'datetime'
          },
          {
            name: 'time_to',
            type: 'datetime'
          },
          {
            name: 'document_name',
            type: 'string'
          },
          {
            name: 'user',
            type: 'string'
          },
        ],
        scopes: [
          'document_name', 'user'
        ],
        beforeSend: (filters) => {
          filters.push({
            attr: 'type',
            condition: 'equals',
            value: 'ReconciliationReportPrintedDocument',
          });
        },
      },
      pg: {
        url: '/wash/printed_documents',
        model: PrintedDocument,
        query: {
          fromToField: 'created_at',
          predicate: '-created_at',
          page: 1,
          per: 10,
        },
      },
      definePaginateMethodsOn: $scope,
    });
  },
);
