

var formatWithMask, maskTree, placeMaskInTheTree, searchForMask;

maskTree = {};

placeMaskInTheTree = function(tree, digits, mask) {
  var currentDigit;
  if (digits.length > 0) {
    currentDigit = digits.shift();
    tree[currentDigit] = tree[currentDigit] || {};
    return placeMaskInTheTree(tree[currentDigit], digits, mask);
  } else {
    return tree['mask'] = mask;
  }
};

$.get("/phone-codes.json").then(function(response) {
  return response.forEach(function(obj) {
    var digits, mask;
    mask = obj.mask;
    digits = mask.replace(/[^\d]/g, '').split('');
    return placeMaskInTheTree(maskTree, digits, mask);
  });
});

searchForMask = function(phone) {
  var bestMask, currentTree, shouldContinue;
  bestMask = null;
  shouldContinue = true;
  currentTree = maskTree;
  phone.split('').forEach(function(digit) {
    if (currentTree[digit] && shouldContinue) {
      if (currentTree[digit]['mask']) {
        bestMask = currentTree[digit]['mask'];
      }
      return currentTree = currentTree[digit];
    } else {
      return shouldContinue = false;
    }
  });
  return bestMask;
};

formatWithMask = function(phone) {
  var digits, mask, result;
  digits = phone.toString().replace(/[^\d]/g, '');
  mask = searchForMask(digits);
  if (mask) {
    digits = digits.split('');
    result = '';
    mask.split('').forEach(function(char) {
      var firstChar;
      firstChar = digits[0];
      if (char === '#' || firstChar === char) {
        return result += digits.shift() || '_';
      } else {
        return result += char;
      }
    });
    return result;
  } else {
    return phone;
  }
};

setTimeout((function() {
  return formatWithMask('79370099330');
}), 20000);

angular.module('RocketWash').filter('formatPhone', function($sce) {
  return function(phone) {
    if (phone) {
      return formatWithMask(phone);
    }
  };
});
