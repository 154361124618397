angular.module('RocketWash').config(($stateProvider) => {
  $stateProvider.state('app.admin.plans.plans_settings', {
    url: '/plans_settings',
    tab: 'plans_settings',
    views: {
      'content@app.admin.plans': {
        controller: 'AdminPlansBaseController',
        templateUrl: 'pages/admin/plans/plans_settings/index.slim',
      },
    },
  });
});
