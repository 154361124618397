angular.module('RocketWash').directive('rwPreReservationButton', (userSession) => {
  return {
    restrict: 'E',
    scope: {
    },
    templateUrl: 'directives/rw-pre-reservation-button/rw-pre-reservation-button.slim',
    link: function(scope, element, attrs) {
      element.draggable({
        revert: true,
        revertDuration: 400,
        cursorAt: {
          top: 25
        }
      });

      const fullDuration = userSession.preReservationDefaultDuration();
      element.data('duration', `00:${fullDuration}:00`);
      element.attr('type', 'PreReservation');
    }
  };
});
