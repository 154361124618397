angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.printed_documents.reciept', {
    url: '/reciept',
    tab: 'reciept',
    data: {
      permission: 'PrintedRecieptTab',
    },
    views: {
      'content@app.wash.printed_documents': {
        controller: 'WashPrintedDocumentsRecieptController',
        templateUrl: 'pages/wash/printed_documents/reciept/index.slim'
      }
    }
  });
});
