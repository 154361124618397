angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.charts.reservations', {
    url: '/reservations',
    tab: 'reservations',
    data: {
      permission: 'ReservationsReportTab'
    },
    views: {
      'content@app.wash.charts': {
        controller: 'WashChartsReservationsController',
        templateUrl: 'pages/wash/charts/reservations/index.slim'
      }
    }
  });
});
