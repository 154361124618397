angular.module('RocketWash').directive('rwActivityCircle', () => {
  return {
    restrict: 'E',
    scope: {
      for: '=',
    },
    templateUrl: 'directives/rw-activity-circle/rw-activity-circle.slim',
    link(scope, _element, _attrs) {
      const STATUS = {
        ACTIVE: true,
        INACTIVE: false,
      }

      switch(scope.for) {
        case STATUS.ACTIVE:
          scope.activityStatus = 'active';
          break
        case STATUS.INACTIVE:
          scope.activityStatus = 'inactive';
          break
        case null, undefined, 'unknown':
          scope.activityStatus = 'unknown';
          break
      }
    }
  }
});