angular.module('RocketWash').config(($stateProvider) => {
  $stateProvider.state('wash_sign_up', {
    url: '/wash_sign_up?partner_code',
    controller: 'WashSignUpController',
    templateUrl: 'pages/wash/sign_up/sign_up.slim',
    data: {
      permission: 'SignUp',
      publicPage: true,
    },
    resolve: {
      _serverTranslations: () => {
        return window.translationsPromise;
      },
    },
  });
});
