

angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  $urlRouterProvider.when('/wash/cars', '/wash/cars/dashboard');
  $stateProvider.state('app.wash.cars', {
    url: '/cars',
    abstract: true,
    controller: 'WashCarsBaseController',
    templateUrl: 'pages/wash/cars/index.slim',
    data: {
      permission: 'CarsMenuItem'
    }
  });
  return $stateProvider.state('app.wash.cars.dashboard', {
    url: '/dashboard',
    data: {
      permission: 'CarsMenuItem'
    },
    views: {
      'content@app.wash.cars': {
        controller: 'WashCarsDashboardBaseController',
        templateUrl: 'pages/wash/cars/dashboard/index.slim'
      }
    }
  }).state('app.wash.cars.dashboard.form', {
    url: '/form',
    data: {
      permission: 'CarsMenuItem'
    },
    views: {
      'content@app.wash.cars': {
        controller: 'WashCarsDashboardFormController',
        templateUrl: 'pages/wash/cars/dashboard/form.slim'
      }
    }
  });
});
