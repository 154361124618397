angular.module('RocketWash').controller(
  'WashServicesBasicBaseController',
  ($scope, $translate, $uibModal, $http, $filter, viewStore, BasicService, CarType, rwConfirmationService, ctgSelectService) => {
    $scope.includeArchived = false;
    $scope.includeArchivedColumn = $scope.includeArchived;

    $scope.rightMenuConfig = {
      buttons: [
        {
          type: 'export_xls',
          text: $translate.instant('buttons.export_xls'),
          action: () => { $scope.exportXLS(); },
          ngDisabled: () => (
            !$scope.canExport('BasicServicesExport')
          ),
        },
      ],
      tooltip: () => (
        !$scope.canExport('BasicServicesExport') ? $translate.instant('buttons.tooltip_message') : undefined
      ),
    };

    $scope.exportXLS = () => {
      $uibModal.open({
        templateUrl: 'pages/common/email_popup/email_popup.slim',
        controller: 'CommonEmailPopupController',
        windowClass: 'small',
        size: 'sm',
      }).result.then((email) => {
        return $http({
          url: `${$scope.env.apiUrl}/wash/basic_services/list.xlsx`,
          method: 'GET',
          params: { email: email },
        }).then(() => alertService.show({
          text: $filter('translate')('email_popup.success'),
        }));
      });
    };

    // Actions
    $scope.reload = () => {
      if (ctgSelectService.selected) {
        BasicService.indexSimple({
          include_archived: $scope.includeArchived,
          car_types_group_id: ctgSelectService.selected.id
        }).then((data) => {
          $scope.services = data.map(x => new BasicService(x));
          $scope.serviceCategories = $scope.getServiceCategories($scope.services);
        }).finally(() => {
          $scope.includeArchivedColumn = $scope.includeArchived;
        });
      }
    };
    $scope.newService = () => {
      const service = new BasicService({
        carTypesGroupId: ctgSelectService.selected.id,
      });
      $scope.edit(service);
    };
    $scope.edit = (service) => {
      viewStore.store({ service });
      $uibModal.open({
        templateUrl: 'pages/wash/services/common/edit_popup.slim',
        controller: "WashServicesCommonFormController",
        resolve: {
          serviceCategories: () => {
            return $scope.categories;
          }
        }
      }).result.finally(() => {
        $scope.reload();
      });
    };
    $scope.save = (service) => {
      service.maxDiscountPercentage = service.maxDiscountPercentage || 0;
      service.maxBonusesPercentage = service.maxBonusesPercentage || 0;
      service.save().then($scope.reload);
    };
    $scope.archive = (service) => {
      rwConfirmationService.confirm(
        { textTranslation: 'alert.are_you_sure' },
        () => {
          service.archived = true;
          service.save().then($scope.reload);
        },
      );
    };

    // Helpers
    $scope.humanizeNumber = (number, fallback) => number || fallback;

    // Code
    $scope.$on('ctgSelectService-selected', () => {
      $scope.reload();
    });
    $scope.reload();
  },
);
