angular.module('RocketWash').config(($stateProvider) => {
  $stateProvider.state('app.admin.organizations.organization.settings', {
    url: '/settings',
    abstract: true,
    views: {
      'content@app.admin.organizations': {
        controller: 'AdminOrganizationsSettingsIndexController',
        templateUrl: 'pages/admin/organizations/settings/index.slim',
      },
    },
  }).state('app.admin.organizations.organization.settings.general', {
    url: '/general',
    tab: 'general',
    views: {
      'settingsView@app.admin.organizations.organization.settings': {
        controller: 'AdminOrganizationsSettingsGeneralController',
        templateUrl: 'pages/admin/organizations/settings/general/general.slim',
      },
    },
  }).state('app.admin.organizations.organization.settings.users', {
    url: '/users',
    tab: 'users',
    views: {
      'settingsView@app.admin.organizations.organization.settings': {
        controller: 'AdminOrganizationsSettingsUsersController',
        templateUrl: 'pages/admin/organizations/settings/users/users.slim',
      },
    },
  }).state('app.admin.organizations.organization.settings.financial_centers', {
    url: '/financial_centers',
    tab: 'financial_centers',
    views: {
      'settingsView@app.admin.organizations.organization.settings': {
        controller: 'AdminOrganizationsSettingsFinancialCentersController',
        templateUrl: 'pages/admin/organizations/settings/financial_centers/financial_centers.slim',
      },
    },
  }).state('app.admin.organizations.organization.settings.service_locations', {
    url: '/service_locations',
    tab: 'service_locations',
    views: {
      'settingsView@app.admin.organizations.organization.settings': {
        controller: 'AdminOrganizationsSettingsServiceLocationsController',
        templateUrl: 'pages/admin/organizations/settings/service_locations/service_locations.slim',
      },
    },
  }).state('app.admin.organizations.organization.settings.legal_entities', {
    url: '/legal_entities',
    tab: 'legal_entities',
    views: {
      'settingsView@app.admin.organizations.organization.settings': {
        controller: 'AdminOrganizationsSettingsLegalEntitiesController',
        templateUrl: 'pages/admin/organizations/settings/legal_entities/legal_entities.slim',
      },
    },
  }).state('app.admin.organizations.organization.settings.export', {
    url: '/export',
    tab: 'export',
    views: {
      'settingsView@app.admin.organizations.organization.settings': {
        controller: 'AdminOrganizationsSettingsExportController',
        templateUrl: 'pages/admin/organizations/settings/export/export.slim',
      },
    },
  }).state('app.admin.organizations.organization.settings.reindex', {
    url: '/reindex',
    tab: 'reindex',
    views: {
      'settingsView@app.admin.organizations.organization.settings': {
        controller: 'AdminOrganizationsSettingsReindexController',
        templateUrl: 'pages/admin/organizations/settings/reindex/reindex.slim',
      },
    },
  });
});
