angular.module('RocketWash').directive('rwCustomFilters', (
  $translate, $filter, serverConstants, FilterGroup, rwFiltersService,
  FILTER_TYPE_CONDITIONS, FILTER_ATTRIBUTE_TYPES,
) => {
  return {
    restrict: 'E',
    scope: {
      filters: '=',
      config: '=',
    },
    templateUrl: 'directives/rw-filters/rw-custom-filters.slim',
    link: function (scope, _element, _attrs) {
      scope.menu = { isOpen: false };
      scope.conditions = serverConstants.filter_conditions;
      scope.filters = scope.filters || [{}];
      scope.attributes = scope.config.attributes;
      scope.rwfs = rwFiltersService.new(scope.config.page);

      scope.attributeType = (filter) => {
        const attr = scope.config.attributes.find(x => x.name === filter.attr);
        return attr && attr.type;
      };

      scope.conditionsFor = (filter) => {
        const attr = scope.config.attributes.find(x => x.name === filter.attr);
        return (attr && attr.conditions) || FILTER_TYPE_CONDITIONS[scope.attributeType(filter)];
      };

      scope.addFilterGroup = function () {
        const filterGroup = new FilterGroup();
        filterGroup.filters = angular.copy(scope.filters);
        filterGroup.name = _.map(filterGroup.filters, (filter) => {
          const attrName = $filter('translateFilterAttribute')(filter.attr, scope.config.page);
          const conditionName = $translate.instant(`filters.conditions.${filter.condition}`);

          return [attrName, conditionName, filter.value].join(' ');
        }).join('\n');

        scope.rwfs.addCustomFilterGroup(filterGroup);

        scope.filters = [{}];
      };

      scope.addFilter = function () {
        scope.filters.push({});
      };

      scope.removeFilter = function (filter) {
        scope.filters = _.without(scope.filters, filter);
      };

      const findConditionGroup = (condition) => {
        const lookup = {
          equality: [
            'equals', 'not_equals', 'includes', 'not_includes',
            'custom__job_in_service_location', 'custom__reservation_ordinal_in_suspicious_activities',
            'custom__box_in_suspicious_activities', 'custom__excess_in_suspicious_activities',
            'custom__old_price_in_suspicious_activities', 'custom__new_price_in_suspicious_activities'
          ],
          comparison: ['greater', 'greater_or_equals', 'less', 'less_or_equals'],
          presence: ['present', 'blank', 'custom__back_to_work_in_suspicious_activities'],
          date_range: ['date_range'],
        };
        return Object.keys(lookup).find(key => lookup[key].includes(condition));
      };

      scope.inputTypeFor = (filter) => {
        if (!(filter && filter.attr && filter.condition)) {
          return 'none';
        }
        const type = scope.attributeType(filter);
        if (!FILTER_ATTRIBUTE_TYPES.includes(type)) {
          console.warning(`rw-custom-filters: deprecated type: ${type}`);
          console.warning(scope.config);
          return scope.getValueTypeByCondition(filter);
        }
        const conditionGroup = findConditionGroup(filter.condition);
        switch (conditionGroup) {
          case 'equality':
            switch (type) {
              case 'string': return 'string';
              case 'enum': return 'enum';
              case 'number': return 'number';
            }
            break;
          case 'comparison':
            switch (type) {
              case 'number': return 'number';
              case 'datetime': return 'datetime';
            }
            break;
          case 'presence': return 'none';
          case 'date_range':
            switch (type) {
              case 'datetime': return 'date_range';
            }
        }
        return 'error';
      };

      scope.filterGroupValid = () => {
        return scope.filters.length > 0 && scope.filters.every((filter) => {
          const valuePresent = filter.value !== null && filter.value !== '';
          return filter.attr && filter.condition &&
            (valuePresent || findConditionGroup(filter.condition) === 'presence');
        });
      };

      scope.getSelectValues = function (attributeName) {
        const attribute = scope.config.attributes.find(x => x.name === attributeName);
        return attribute && attribute.values;
      };
    },
  };
});
