import L from 'leaflet';

const LOCAL_STORAGE_STATE_KEY = 'LOCAL_STORAGE_ONLINE_USERS_STATE_KEY';

angular.module('RocketWash').controller('AdminOnlineReservationsMobileUsersIndexController', (
  $window, $rootScope, $scope, $timeout, Reservation, ServiceLocation, userSession, dateRangeStorage, User, $uibModal,
) => {
  $scope.adminStatusVariants = [
    'new', 'approved', 'declined'
  ]

  $scope.pds = $scope.pageDataService.new({
    page: 'mobile_users',
    filters: {
      attributes: [
        { name: 'name', type: 'string' },
        { name: 'phone', type: 'string' },
        { name: 'created_at', type: 'datetime' },
      ],
      scopes: [
        'name', 'phone'
      ],
      beforeSend: (filters) => {
        filters.push({
          custom: true,
          condition: 'custom__latest_mobile_request_location',
          value: $scope.search,
        });
      },
    },
    pg: {
      url: '/admin/users/mobile_users',
      model: User,
      query: {
        skipDateFilter: true,
        fromToField: 'created_at',
        predicate: '-created_at',
      },
    },
    definePaginateMethodsOn: $scope,
  });

  $scope.save = (user) => {
    return user.save({organizationId: user.organizationId});
  };



  $scope.search = {
    radius: 50000,
    coordinates: {
      lat: 60.86388876054231,
      lng: 27.637996673583988,
    },
  };

  $scope.afterMapInit = (target) => {
    $scope.map = target;

    const savedState = $window.localStorage.getItem(LOCAL_STORAGE_STATE_KEY);
    if (savedState) {
      $scope.state = JSON.parse(savedState);

      angular.extend($scope.search, $scope.state.search);

      if ($scope.state.search.coordinates) {
        $scope.map.setView(
          angular.copy($scope.state.search.coordinates),
          8,
        );
      }
      dateRangeStorage.update($scope.state.dateRange);
    };

    $scope.$watch('search', () => {
      showCircleRadius();
      let query = $scope.pds.pg.query;
      [query.lat, query.lon] = [$scope.search.coordinates.lat, $scope.search.coordinates.lng];
      query.radius = $scope.search.radius;

      saveState();

      $scope.updatePage();
    }, true);
  };

  const saveState = () => {
    const state = JSON.stringify({
      search: $scope.search,
      dateRange: dateRangeStorage.range(),
    });
    $window.localStorage.setItem(LOCAL_STORAGE_STATE_KEY, state);
  };

  $rootScope.$on('date-range-updated', () => {
    saveState();
  });

  $scope.goToServiceLocation = (reservation) => {
    userSession.pretend({
      role: 'organization_director',
      organizationId: reservation.organizationId,
      serviceLocationId: reservation.serviceLocationId,
      inNewTab: true,
    });
  };

  const map = L.map('online-users-map').setView([53.750732672433465, 27.607492926269526], 8);

  L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  }).addTo(map);


  map.on('click', (e) => {
    $timeout(() => {
      $scope.search.coordinates = e.latlng;
    });
  });

  $scope.afterMapInit(map);

  $scope.circleLayer = null;
  const showCircleRadius = () => {

    const center = $scope.search.coordinates;

    const geojsonMarkerOptions = {
      radius: parseInt($scope.search.radius),
      fillColor: "#ff7800",
      color: "#000",
      weight: 1,
      opacity: 1,
      fillOpacity: 0.4
    };

    if ($scope.circleLayer) {
      $scope.circleLayer.remove();
    }

    $scope.circleLayer = L.circle([center.lat, center.lng], geojsonMarkerOptions);

    $scope.circleLayer.addTo(map);
  }

  $scope.sendNotification = () => {
    let selectedFilters = $scope.pds.selectedFilters.map((filter) => {
      delete filter.filterGroup;
      return filter;
    })
    $scope.pds.filters.beforeSend(selectedFilters);

    return $uibModal.open({
      templateUrl: `pages/admin/online_reservations/mobile_users/send_notification.slim`,
      controller: 'AdminOnlineReservationsMobileUsersSendNotificationController',
      resolve: {
        selectedFilters: () => { return selectedFilters },
      }
    })
  };

});
