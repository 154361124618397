angular.module('RocketWash').component('rwReservationRightMenuHistory', {
  templateUrl: 'components/rw-reservation/right-menu/history/index.slim',
  bindings: {
  },
  controller: function (
    $scope, reservationForm,
  ) {
    $scope.rf = reservationForm;

    $scope.$watch('rf.formTab.viewState.rightMenuHistory', (value) => {
      if (value) {
        reservationForm.reservations.loadHistory();
      };
    });

    $scope.repeat = (reservation) => {
      $scope.rf.services.reset();
      (reservation.attached_services || []).forEach((s) => {
        $scope.rf.services.add(s.service_id);
      });
    };

    // History table reservations expansion
    $scope.allExpanded = () => {
      console.log($scope.rf.reservations.history);
      return _.every($scope.rf.reservations.history, r => r.expanded);
    };

    $scope.toggleReservation = (reservation, state) => {
      if (state === undefined) {
        reservation.expanded = !reservation.expanded;
      } else {
        reservation.expanded = state;
      };
    };

    $scope.toggleAll = () => {
      console.log($scope.allExpanded());

      const newState = !$scope.allExpanded();

      _.forEach($scope.rf.reservations.history, r => {
        $scope.toggleReservation(r, newState);
      });
    };
  },
});
