angular.module('RocketWash').config(($stateProvider) => {
  return $stateProvider.state('app.wash.settings.organization', {
    url: '/organization',
    tab: 'organization',
    data: {
      permission: 'SettingsOrganizationTab',
    },
    views: {
      'content@app.wash.settings': {
        controller: 'WashSettingsOrganizationController',
        templateUrl: 'pages/wash/settings/organization/index.slim',
      },
    },
  });
});
