

angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  $urlRouterProvider.when('/admin/partners', '/admin/partners/dashboard');
  $urlRouterProvider.when('/admin/partners/{usrId}/settings', '/admin/partners/{usrId}/settings/general');
  return $stateProvider.state('app.admin.partners', {
    url: '/partners',
    abstract: true,
    controller: 'AdminPartnersIndexController',
    templateUrl: 'pages/admin/partners/index.slim'
  }).state('app.admin.partners.dashboard', {
    url: '/dashboard',
    views: {
      'content@app.admin.partners': {
        controller: 'AdminPartnersDashboardIndexController',
        templateUrl: 'pages/admin/partners/dashboard/index.slim'
      }
    }
  }).state('app.admin.partners.partner', {
    url: '/:usrId',
    abstract: true
  });
});
