angular.module('RocketWash').controller('WashCounterpartiesCreateController', function($rootScope, $scope, $translate, $state, User, Bank, CarMake, CarModel, Car, userSession, viewStore, serverConstants) {
  var linkCar;
  $scope.contractor = viewStore.data.contractor;
  if (!$scope.contractor) {
    return $state.go('^');
  }
  linkCar = function(car) {
    car.carMake = _.find($scope.carMakes, function(cm) {
      return cm.id === car.carMake.id;
    });
    return $scope.loadMake(car.carMake, function() {
      return car.carModel = _.find(car.carMake.carModels, function(cm) {
        var ref;
        return cm.id === ((ref = car.carModel) != null ? ref.id : void 0);
      });
    });
  };
  if ($scope.contractor.id) {
    User.get($scope.contractor.id).then(function(data) {
      var car, i, j, len, len1, ref, ref1, results;
      $scope.contractor = data;
      if ($scope.contractor.cars) {
        ref = $scope.contractor.cars;
        for (i = 0, len = ref.length; i < len; i++) {
          car = ref[i];
          linkCar(car);
        }
      }
    });
  }
  $scope.$translate = $translate;
  $scope.sex = serverConstants.user_sexes;
  $scope.types = serverConstants.user_types;
  $scope.loadMake = function(make, callback) {
    if (!make.carModels) {
      return CarMake.get(make.id).then(function(data) {
        data.carModels = _.map(data.carModels, function(cm) {
          return new CarModel(cm);
        });
        data.carModels = _.sortBy(data.carModels, 'name');
        angular.extend(make, data);
        return typeof callback === "function" ? callback() : void 0;
      });
    }
  };
  $scope.addCar = function() {
    var ref;
    return (ref = $scope.contractor.cars) != null ? ref.push(new Car({
      serviceLocationId: userSession.service_location_id
    })) : void 0;
  };
  $scope.deleteCar = function(car) {
    if (confirm($translate.instant('alert.are_you_sure'))) {
      return car._destroy = true;
    }
  };
  $scope["delete"] = function() {
    return $scope.contractor["delete"]();
  };
  $scope.carTypeName = function(id) {
    var type;
    type = _.find($scope.carTypes, function(ct) {
      return parseInt(ct.id) === parseInt(id);
    });
    return type && type.name;
  };
  $scope.saveForm = function() {
    var car, contractor, dob, i, j, len, len1, ref, ref1, ref2, ref3, ref4, ref5;
    $scope.errors = {
      userInfo: {}
    };
    contractor = viewStore.data.contractor;
    ref = contractor.cars != null;
    for (i = 0, len = ref.length; i < len; i++) {
      car = ref[i];
      car.carMakeId = (ref1 = car.carMake) != null ? ref1.id : void 0;
      car.carModelId = (ref2 = car.carModel) != null ? ref2.id : void 0;
    }
    contractor.phone = $("input.phone-input").val();
    dob = $(".dob input").val();
    if ((dob === $translate.instant('format.date') || !dob) || moment(dob, 'DD/MM/YYYY').isValid()) {
      if (moment(dob, 'DD/MM/YYYY').isValid()) {
        contractor.dateOfBirth = dob;
      }
      return contractor.save().then(function(data) {
        $scope.errors.userInfo = data.errors;
        if (_.isEmpty($scope.errors.userInfo)) {
          return $state.go('^');
        }
      });
    } else {
      return $scope.errors = {
        userInfo: {
          dateFormat: true
        }
      };
    }
  };
  return $scope.cancelCreate = function() {
    viewStore.clear();
    return $state.go('app.wash.counterparties.individuals');
  };
});
