angular.module('RocketWash').controller(
  'AdminOrganizationsSettingsUsersController',
  ($scope, $stateParams, $http, $translate, User, ServiceLocation, Role, paginator) => {
    User.tenantId = $stateParams.orgId;
    ServiceLocation.tenantId = $stateParams.orgId;

    $scope.roles = [];
    $scope.serviceLocations = [];

    $scope.pds = $scope.pageDataService.new({
      page: 'employees',
      filters: {
        attributes: [
          { name: 'name', type: 'string' },
          { name: 'phone', type: 'string' },
          { name: 'role', type: 'string' },
          { name: 'employee_status', type: 'string' },
        ],
        scopes: ['name', 'phone', 'role', 'employee_status'],
      },
      pg: {
        url: '/admin/users',
        model: User,
        headers: { 'Tenant-Id': $stateParams.orgId },
        query: {
          skipDateFilter: true,
          organization_id: $stateParams.orgId,
          searchQuery: '',
          predicate: 'id',
          role: '',
        },
      },
      definePaginateMethodsOn: $scope,
    });

    ServiceLocation.query({ organization_id: $stateParams.orgId }).then((serviceLocations) => {
      $scope.serviceLocations = serviceLocations;
    });
    Role.query().then((roles) => {
      $scope.roles = roles.map(x => x.name).filter(x => x !== '');
    });

    $scope.new = () => {
      $scope.pds.pg.items.unshift(new User());
    };
    $scope.save = (user) => {
      user.organizationId = $stateParams.orgId;
      user.save();
    };
    $scope.delete = (user) => {
      if (confirm($translate.instant('alert.are_you_sure'))) {
        user.delete().then(() => {
          $scope.pds.pg.items = $scope.pds.pg.items.filter(x => (x !== user));
        });
      }
    };
    $scope.resetPassword = (user) => {
      $http({
        method: 'GET',
        url: '/admin/users/notify',
        params: { user_id: user.id },
        headers: { 'Tenant-Id': user.organizationId },
      }).then(
        () => alert($translate.instant('alert.message_sent')),
        response => alert(response.data.error),
      );
    };
    $scope.serviceLocationName = (user) => {
      const id = user.serviceLocationId;
      const sl = $scope.serviceLocations.find(x => x.id === id);
      return (sl && sl.name) || user.serviceLocationName;
    };
    $scope.updatePage = () => {
      paginator.getData($scope.pds);
    };
    $scope.resetAndUpdate = () => {
      $scope.pds.pg.query.page = 1;
      $scope.updatePage();
    };
    $scope.$on('pds-update', $scope.resetAndUpdate);

    $scope.updatePage();
  },
);
