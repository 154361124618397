angular.module('RocketWash').config(($stateProvider) => {
  $stateProvider.state('app.wash.finance', {
    url: '/finance',
    abstract: true,
    controller: 'WashFinanceBaseController',
    templateUrl: 'pages/wash/finance/index.slim',
    data: {
      permission: 'SettingsMenuItem',
      tabs: ['financial_planning', 'wash_financial_operations', 'financial_projects', 'cash_shifts'],
    },
  });
});
