angular.module('RocketWash').controller('WashSettingsScheduleController', (
  $scope, Schedule, serverConstants,
) => {
  const setScheduleTimeSteps = (schedule) => {
    schedule.dayOpenAt = serverConstants.time_steps
      .find(timeStep => schedule.dayOpenAt.secondOfDay === timeStep.second_of_day);
    schedule.dayCloseAt = serverConstants.time_steps
      .find(timeStep => schedule.dayCloseAt.secondOfDay === timeStep.second_of_day);
    schedule.nightOpenAt = serverConstants.time_steps
      .find(timeStep => schedule.nightOpenAt.secondOfDay === timeStep.second_of_day);
    schedule.nightCloseAt = serverConstants.time_steps
      .find(timeStep => schedule.nightCloseAt.secondOfDay === timeStep.second_of_day);
    schedule.secondNightOpenAt = serverConstants.time_steps
      .find(timeStep => schedule.secondNightOpenAt.secondOfDay === timeStep.second_of_day);
    schedule.secondNightCloseAt = serverConstants.time_steps
      .find(timeStep => schedule.secondNightCloseAt.secondOfDay === timeStep.second_of_day);
  };
  const loadSchedules = () => {
    Schedule.query().then((schedules) => {
      schedules.forEach((schedule) => {
        setScheduleTimeSteps(schedule);
      });
      $scope.schedules = schedules;
    });
  };
  $scope.formatTime = time => moment(time).format('HH:mm');
  $scope.save = (schedule) => {
    schedule.save().then((newSchedule) => {
      if (_.isEmpty(newSchedule.errors)) {
        setScheduleTimeSteps(newSchedule);
        $scope.errors = {};
      } else {
        $scope.errors = {
          schedules: newSchedule.errors,
        };
        loadSchedules();
      }
    });
  };

  loadSchedules();
});
