angular.module('RocketWash').directive('rwReservationProductsSelect', () => {
  return {
    restrict: 'E',
    templateUrl: 'directives/rw-reservation-products-select/index.slim',
    scope: {
      reservation: '=',
    },
    controller: ($scope, $translate, Service, Product, total) => {
      $scope.selected = {};

      const safeContractor = () => {
        const r = $scope.reservation;
        return r && r.car && r.car.contractor || {};
      };

      $scope.getProducts = (value) => {
        return Service.typeahead(value, {
          serviceType: 'Product',
          userId: safeContractor().id,
        }).then((response) => {
          const items = response.data.map(item => new Product(item));

          items.forEach((product) => {
            product.line1 = [product.name].join(' ');
            product.line2 = [$translate.instant('warehouse.warehouse_operations.form.available_amount'), product.count || 0, product.unit,
                             '   ', $translate.instant('warehouse.warehouse_operations.form.article'), product.article,
                             $translate.instant('warehouse.warehouse_operations.form.barcode'), product.barcode].join(' ');
          });

          return items;
        });
      };

      const getProductPrice = (product) => {
        return (
          product &&
          product.priceListItems &&
          product.priceListItems[0] &&
          product.priceListItems[0].price
        ) || 0;
      };

      $scope.addProduct = (product) => {
        if (!product) {
          return;
        }
        const attachedProduct = {
          product: product,
          productId: product.id,
          name: product.name,
          count: 1,
          price: getProductPrice(product),
          discount: safeContractor().discount,
        };
        $scope.reservation.attachedProducts = $scope.reservation.attachedProducts || [];
        $scope.reservation.attachedProducts.push(attachedProduct);
        $scope.selected.product = null;
      };

      $scope.removeProduct = (product) => {
        product._destroy = true;
      };

      $scope.productSelected = (attachedProduct) => {
        attachedProduct.productId = attachedProduct.product && attachedProduct.product.id;
        attachedProduct.name = attachedProduct.product && attachedProduct.product.name;
        attachedProduct.price = attachedProduct.product && attachedProduct.product.price;
      };

      $scope.productsSum = () => {
        $scope.reservation.attachedProducts = $scope.reservation.attachedProducts || [];
        return $scope.reservation.attachedProducts
          .map(x => ((x.price * x.count) || 0)).reduce((x, y) => x + y, 0).toFixed(2);
      };

      $scope.productSubtotal = (product) => {
        return (total.productsPrice([product]) - total.productsDiscount([product])).toFixed(2);
      };
    },
  };
});
