angular.module('RocketWash').config(($stateProvider) => {
  return $stateProvider.state('app.admin.settings.country_phone_codes', {
    url: '/country_phone_codes',
    tab: 'country_phone_codes',
    views: {
      'content@app.admin.settings': {
        controller: 'AdminSettingsCountryPhoneCodesBaseController',
        templateUrl: 'pages/admin/settings/country_phone_codes/index.slim',
      },
    },
  });
});
