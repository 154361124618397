angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.finance.wash_financial_operations', {
    url: '/wash_financial_operations',
    tab: 'wash_financial_operations',
    data: {
      permission: 'FinancialOperationsTab',
    },
    views: {
      'content@app.wash.finance': {
        controller: 'WashFinanceWashFinancialOperationsBaseController',
        templateUrl: 'pages/wash/finance/wash_financial_operations/index.slim'
      }
    }
  }).state('app.wash.finance.wash_financial_operations.history', {
    url: '/history/:id',
    views: {
      'content@app.wash.finance': {
        controller: 'WashFinanceWashFinancialOperationsHistoryController',
        templateUrl: 'pages/wash/finance/wash_financial_operations/history.slim'
      }
    }
  });
});
