angular.module('RocketWash').controller('AdminChartsReservationsController', (
  $scope, $http, $translate, $timeout,
) => {
  $scope.config = { offline: true, mobile_individual: true, mobile: true, mobile_gazprom_neft: true, mobile_taxi: true, ya_maps: true, ya_fuel_online: true };

  const updateChart = function () {
    let data,
      ref,
      ref1,
      series,
      sum,
      timestamp,
      update,
      values;
    $scope.groupedData = _.groupBy($scope.rawData, (point) => {
      let date;
      date = moment(point.timestamp);
      if ($scope.group.range) {
        date = date.startOf($scope.group.range);
      }
      return +date;
    });
    let data_count = [];
    let data_price = [];
    ref = $scope.groupedData;
    for (timestamp in ref) {
      values = ref[timestamp];
      let sum_count = _.reduce(values, ((total, val) => {
        return total + val.count;
      }), 0);
      let sum_price = _.reduce(values, ((total, val) => {
        return total + val.total_price;
      }), 0);

      data_count.push([parseInt(timestamp), sum_count]);
      data_price.push([parseInt(timestamp), sum_price]);
    }
    series = [
      {
        name: $translate.instant('charts.reservations.name'),
        tooltip: {
          valueSuffix: $translate.instant('charts.reservations.tooltip.valueSuffix'),
        },
        color: $translate.instant('charts.reservations.color'),
        data: data_count,
        yAxis: 0,
      },
      {
        name: 'Сумма цен',
        tooltip: {
          valueSuffix: 'руб',
        },
        color: '#FF0000',
        data: data_price,
        yAxis: 1,
        type: 'spline',
      },
    ];
    $scope.rangeMin = 0;
    $scope.rangeMax = +moment();
    if ((ref1 = $scope.chart) != null) {
      ref1.destroy();
    }
    update = function () {
      if ($('#charts-container-count').length) {
        return $scope.chart = new Highcharts.StockChart({
          title: {
            text: $translate.instant('charts.reservations.title'),
          },
          subtitle: {
            text: null,
          },
          rangeSelector: {
            selected: 0,
          },
          xAxis: {
            type: 'datetime',
            title: {
              text: $translate.instant('charts.reservations.labels.date'),
              style: '',
            },
            events: {
              setExtremes: function (e) {
                return $scope.$apply(() => {
                  $scope.rangeMin = e.min;
                  return $scope.rangeMax = e.max;
                });
              },
            },
          },
          yAxis: [{
            title: {
              text: $translate.instant('charts.reservations.labels.count'),
              style: '',
            },
            labels: {
              plotLines: [
                {
                  value: 0,
                  width: 2,
                  color: 'silver',
                },
              ],
            },
          }, {
            title: {
              text: 'Сумма',
              style: '',
            },
            labels: {
              plotLines: [
                {
                  value: 0,
                  width: 2,
                  color: 'silver',
                },
              ],
            },
          }],
          plotOptions: {
            // turboThreshold: 10000,
            series: {
              stacking: 'normal',
              marker: {
                enabled: false,
              },
            },
          },
          series: series,
          legend: {
            borderRadius: 2,
            borderColor: '#c1c1c1',
            backgroundColor: '#f9f9f9',
          },
          chart: {
            renderTo: 'charts-container-count',
            borderRadius: 3,
            showAxes: true,
            borderColor: '#c4c4c4',
            reflow: false,
            backgroundColor: '#ffffff',
            spacing: '10,10,15,10',
            type: 'area',
            plotBackgroundImage: null,
            spacingLeft: 8,
            spacingBottom: 8,
            spacingRight: 8,
            plotBorderWidth: 6,
            borderWidth: 1,
            selectionMarkerFill: 'rgb(137,210,255)',
            zoomType: 'x',
            plotBorderColor: '#ffffff',
            plotBackgroundColor: '#fcfcfc',
            spacingTop: 8,
          },
          exporting: {
            scale: 1,
            enabled: false,
            sourceHeight: 10,
            sourceWidth: 10,
          },
        });
      }
    };
    return $timeout(update);
  };

  $scope.reload = () => {
    $http({
      url: `${$scope.env.apiUrl}/admin/charts/reservations`,
      method: 'GET',
      params: $scope.config,
    }).then((response) => {
      $scope.rawData = response.data;
      updateChart();
    });
  };

  $scope.reload();

  $scope.$watch('group.range', updateChart);
});
