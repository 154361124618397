angular.module('RocketWash').service(
  'printService',
  function (rwApi, Device, $compile, $rootScope, $timeout, userSession, PrintRequest) {
    return {

      printToPrinterThroughIframe: function ({ docs }) {
        console.log('#printToPrinterThroughIframe', docs);

        if (!docs || docs.length == 0) { return };

        const printingId = 'iframe-for-printing';

        if ($(`#${printingId}`).length > 0) {
          $(`#${printingId}`).remove();
        };

        $('html').append(`<iframe style="position: absolute; left: -10000px; top: -10000px;" id="${printingId}" name="${printingId}"></iframe>`);

        var printWindow = window.frames[printingId];

        $(printWindow.document).ready(function () {
          const plainHtml = docs.map(doc => doc.printed_html).join('');

          printWindow.document.open();
          printWindow.document.write(plainHtml);
          printWindow.document.close();
          printWindow.onload = function () {
            printWindow.print();

            // Remove iframe later to remove performance impact
            $timeout(() => {
              $(`#${printingId}`).remove();
            }, 2000);
          };
        });
      },

      printToCashMachine: function ({ docs }) {
        console.log('#printToCashMachine', docs);

        if (!docs || docs.length == 0) { return };

        $timeout(() => {
          const printDocsOneByOne = function (docs) {
            const doc = docs[0];
            if (doc) {
              let result = Device.printReceipts(doc);
              Device.checkResultFromKKM(result && result.IdCommand, (result) => {


                const fiscalPrinted = _.get(doc, 'printed_data.fiscal');
                if (_.get(result, 'success') && fiscalPrinted) {
                  rwApi.sendRequest({
                    method: 'POST',
                    path: `print_requests/${_.get(doc, 'printed_data.print_request_uuid')}/log_success`,
                    data: {
                      json: result
                    },
                  });
                } else {
                  rwApi.sendRequest({
                    method: 'POST',
                    path: `print_requests/${_.get(doc, 'printed_data.print_request_uuid')}/log_info`,
                    data: {
                      json: result
                    },
                  });
                };

                printDocsOneByOne(docs.slice(1));
              });
            };
          };
          return printDocsOneByOne(docs);
        })
      },

      printToAll: function ({ docs, targetId, targetType, fiscalCheckPrintedCallback = null }) {
        console.log('#printToAll start', { docs, targetId, targetType });

        const documents = docs.map((doc) => {
          return {
            id: doc.id,
            count: doc.quantity,
          }
        });

        return rwApi.sendRequest({
          method: 'POST',
          path: 'print_requests',
          data: {
            service_location_id: userSession.service_location_id,
            printable_documents: documents,
            target_id: targetId,
            target_type: targetType,
          },
        }).then((data) => {
          console.log('#printToAll PrintRequest created', data);

          this.printToPrinterThroughIframe({
            docs: data.printer,
          });


          if (typeof KkmServer !== 'undefined') {
            this.printToCashMachine({
              docs: data.kkm,
            });

            if (data.kkmserver && data.kkmserver.some((doc) => doc.should_print && doc.fiscal)) {
              fiscalCheckPrintedCallback && fiscalCheckPrintedCallback();
            }
          }
        });

      },

    };
  }
);
