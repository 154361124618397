angular.module('RocketWash').config(($stateProvider) => {
  $stateProvider.state('app.admin.partners.partner.settings', {
    url: '/settings',
    abstract: true,
    views: {
      'content@app.admin.partners': {
        controller: 'AdminPartnersSettingsIndexController',
        templateUrl: 'pages/admin/partners/settings/index.slim',
      },
    },
  }).state('app.admin.partners.partner.settings.general', {
    url: '/general',
    tab: 'general',
    views: {
      'settingsView@app.admin.partners.partner.settings': {
        controller: 'AdminPartnersSettingsGeneralController',
        templateUrl: 'pages/admin/partners/settings/general/general.slim',
      },
    },
    }).state('app.admin.partners.partner.settings.organizations', {
      url: '/organizations',
      tab: 'organizations',
      views: {
        'settingsView@app.admin.partners.partner.settings': {
          controller: 'AdminPartnersSettingsOrganizationsController',
          templateUrl: 'pages/admin/partners/settings/organizations/organizations.slim',
        },
      },
    });
});
