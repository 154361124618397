angular.module('RocketWash').config(($stateProvider, $urlRouterProvider) => {
  $urlRouterProvider.when('/taxi/reports', '/taxi/reports/reservations');

  $stateProvider.state('app.taxi.reports', {
    url: '/reports',
    abstract: true,
    controller: 'TaxiReportsBaseController',
    templateUrl: 'pages/taxi/reports/index.slim',
    data: {
      tabs: ['reservations', 'financial_operations'],
    },
  });
});
