angular.module('RocketWash').service('webcamService', () => {
  const WIDTH = 1920;
  const HEIGHT = 1080;

  let service = {
    webcam: {},
    showOverlay: false,
  };

  let getVideoData = (callback) => {
    let hiddenCanvas = document.createElement('canvas');
    hiddenCanvas.width = service.webcam.video.videoWidth;
    hiddenCanvas.height = service.webcam.video.videoHeight;
    let ctx = hiddenCanvas.getContext('2d');
    ctx.drawImage(service.webcam.video, 0, 0, hiddenCanvas.width, hiddenCanvas.height);

    hiddenCanvas.toBlob((blob) => {
      callback(blob);
    }, 'image/jpeg', 0.95);
  };

  service.makeSnapshot = () => {
    if (service.webcam.stream) {
      let canvas = document.querySelector('#snapshot');
      if (!canvas) return;

      let context = canvas.getContext('2d');

      getVideoData((blob) => {
        service.snapshotCallback(blob);
      });
    }
  };

  return service;
});
