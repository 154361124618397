angular.module('RocketWash').controller(
  'AdminServiceLocationsDashboardController',
  (
    $scope, $translate,
    ServiceLocation, Organization, TimeZone, Plan,
  ) => {
    $scope.plans = [];
    $scope.organizations = [];

    $scope.pds = $scope.pageDataService.new({
      page: 'service_locations',
      filters: {
        attributes: [
          { name: 'name', type: 'string' },
          { name: 'organization_name', type: 'string' },
          { name: 'address', type: 'string' },
          { name: 'phone', type: 'string' },
          { name: 'email', type: 'string' },
          { name: 'plan_name', type: 'string' },
          { name: 'agreement_number', type: 'string' },
        ],
        scopes: [
          'name', 'organization_name', 'address', 'phone', 'email', 'plan_name', 'agreement_number',
        ],
      },
      pg: {
        url: '/admin/service_locations/paginate',
        model: ServiceLocation,
        query: {
          skipDateFilter: true,
          predicate: '-created_at',
          page: 1,
          per: 10,
        },
      },
      definePaginateMethodsOn: $scope,
    });

    TimeZone.query().then((timeZones) => {
      $scope.timeZones = timeZones;
    });
    Plan.query().then((plans) => {
      $scope.plans = plans;
    });
    Organization.query().then((organizations) => {
      $scope.organizations = organizations;
    });

    $scope.new = () => {
      $scope.pds.pg.items.unshift(new ServiceLocation());
    };
    $scope.save = (serviceLocation) => {
      serviceLocation.save();
    };
    $scope.duplicate = (serviceLocation) => {
      serviceLocation.duplicate().then((sl) => {
        $scope.pds.pg.items.unshift(sl);
      });
    };
    $scope.delete = (serviceLocation) => {
      if (confirm($translate.instant('alert.are_you_sure'))) {
        serviceLocation.delete().then(() => {
          $scope.pds.pg.items = $scope.pds.pg.items.filter(x => x !== serviceLocation);
        });
      }
    };

    $scope.organizationName = (serviceLocation) => {
      const organization = $scope.organizations
        .find(x => x.id === serviceLocation.organizationId);
      console.log('organization');
      return (organization && organization.name) ||
        $translate.instant('models.service_location.organization');
    };
    $scope.organizationUrl = (orgId) => {
      let wl = window.location.origin.toString();
      return wl.replace(/admin/, orgId);
    }
    $scope.planName = (planId) => {
      const plan = $scope.plans.find(p => p.id === planId);
      return plan && plan.name;
    };
    $scope.humanizeNumber = (number, fallback) => (number != null ? number : fallback);
  },
);
