angular.module('RocketWash').config(($stateProvider, $urlRouterProvider) => {
  $stateProvider.state('app.admin.settings.finance_clauses', {
    url: '/finance_clauses',
    tab: 'finance_clauses',
    views: {
      'content@app.admin.settings': {
        controller: 'AdminSettingsFinanceClausesBaseController',
        templateUrl: 'pages/admin/settings/finance_clauses/index.slim',
      }
    }
  });
});
