

angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.services.summary', {
    url: '/summary',
    tab: 'summary',
    data: {
      permission: 'PriceListTab'
    },
    views: {
      'content@app.wash.services': {
        controller: 'WashServicesSummaryController',
        templateUrl: 'pages/wash/services/summary/index.slim'
      }
    }
  });
});
