angular.module('RocketWash').controller('WashCounterpartiesEditReviewsController', (
  $scope, viewStore, ReviewForCustomer, userSession,
) => {
  $scope.contractor = viewStore.data.contractor;

  $scope.pds = $scope.pageDataService.new({
    page: 'wash_reviews_for_service_location',
    filters: {
      attributes: [
        { name: 'id', type: 'number' },
        { name: 'reservation_id', type: 'number' },
        { name: 'stars_count', type: 'string' },
        { name: 'comment', type: 'string' },
      ],
      scopes: ['comment'],
      beforeSend: (filters) => {
        filters.push({
          attr: 'user_id',
          condition: 'equals',
          value: $scope.contractor.id,
        });

        filters.push({
          attr: 'service_location_id',
          condition: 'equals',
          value: userSession.service_location.id,
        });
      },
    },
    pg: {
      url: '/wash/review_for_customers',
      model: ReviewForCustomer,
      query: {
        predicate: '-id',
        per: 10,
        page: 1,
      },
    },
    definePaginateMethodsOn: $scope,
  });
});
