angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.taxi.service_locations.services', {
    url: '/services',
    tab: 'services',
    views: {
      'content@app.taxi.service_locations': {
        controller: 'TaxiServiceLocationsServicesController',
        templateUrl: 'pages/taxi/service_locations/services/index.slim'
      }
    }
  });
});
