angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.income.employees', {
    url: '/employees',
    tab: 'employees',
    data: {
      permission: 'EmployeeTab'
    },
    views: {
      'content@app.wash.income': {
        controller: 'WashIncomeEmployeesBaseController',
        templateUrl: 'pages/wash/income/employees/index.slim'
      }
    }
  }).state('app.wash.income.employees.form', {
    url: '/form',
    views: {
      'content@app.wash.income': {
        controller: 'WashIncomeEmployeesFormController',
        templateUrl: 'pages/wash/income/employees/form.slim'
      }
    }
  });
});
