// TODO: disable caching
angular.module('RocketWash').factory(
  'CarTypesGroup',
  ($http, railsResourceFactory, userSession, TenantSwitchInterceptor) => {
    const resource = railsResourceFactory({
      url: (context) => { return "/" + (context.queryRole || userSession.controllerScope()) + "/car_types_groups/" + (context.id || '') },
      name: 'car_types_group',
    });

    resource.addInterceptor(TenantSwitchInterceptor);

    resource.duplicateFromOrganization = function (params) {
      return resource.$post(
        `${resource.resourceUrl()}/duplicate_from_organization`,
        params,
        { serializer: null, rootWrapping: false },
      );
    };

    resource.all = function (params, context) {
      return resource.$get(
        `${resource.resourceUrl(context)}/all`,
        params
      );
    };

    resource.preconfigured = function (params) {
      return resource.$get(
        `${resource.resourceUrl()}/preconfigured`,
      );
    };

    resource.apply = function (params) {
      return resource.$post(
        `${resource.resourceUrl()}/apply`,
        params,
        { serializer: null, rootWrapping: false },
      );
    };


    return resource;
  },
);
