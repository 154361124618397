angular.module('RocketWash').service('reservationFormTab', ($http, $injector, userSession) => {
  const rfc = $injector.get('reservationFormCarContractor');
  const rfs = $injector.get('reservationFormServices');
  const rfp = $injector.get('reservationFormPayments');
  const rfe = $injector.get('reservationFormEmployees');

  const ALL_TABS = [
    {
      name: 'client',
      template: 'client',
      class: 'fas fa-car',
      checked: () => {
        return rfc.car.id;
      },
    },
    {
      name: 'services',
      template: 'services',
      class: 'fas fa-cart-plus',
      show: () => {
        return rfc.hasCarTypes();
      },
      checked: () => {
        return rfs.selected.length > 0;
      },
    },
    {
      name: 'payments',
      template: 'payments',
      class: 'fas fa-dollar-sign',
      show: () => {
        return rfc.hasCarTypes() && rfs.selected.length > 0;
      },
      checked: () => {
        return rfp.all.length > 0;
      },
    },
    {
      name: 'employee_participations',
      template: 'employee_participations',
      class: 'fas fa-users-cog',
      show: () => {
        return rfc.hasCarTypes() && rfs.selected.length > 0;
      },
      checked: () => {
        return (rfe.all || []).filter((e) => e.selected).length > 0;
      },
    },
  ];

  const EXTRA_TABS = [
    {
      name: 'photos',
      template: 'photos',
      class: 'far fa-list-alt',
    },
  ]

  const RIGHT_SECTION = {
    client: false,
    services: true,
    payments: true,
    employee_participations: false,
    photos: false,
  };

  const defaultIndex = 0;

  const service = {
    currentTab: ALL_TABS[defaultIndex],

    tabState: undefined,

    viewState: {},

    all: ALL_TABS,
    extra: EXTRA_TABS,

    showRightSection: () => {
      return RIGHT_SECTION[_.get(service, 'currentTab.name')];
    },

    toggleViewFlag: (name, value) => {
      const newValue = !service.viewState[name];

      if (name.match(/^rightMenu/)) {
        service.closeAllRightMenues();
      };

      if (value !== undefined) {
        service.viewState[name] = value;
      } else {
        service.viewState[name] = newValue;
      };
    },

    closeAllRightMenues: () => {
      Object.keys(service.viewState).forEach((key) => {
        if (key.match(/^rightMenu/)) {
          service.viewState[key] = false;
        }
      });
    },

    setTabState: (state) => {
      service.tabState = state;
    },

    setByName: (name) => {
      const tab = _.flatten(ALL_TABS, EXTRA_TABS).find((t) => t.name == name)
      service.set(tab);
    },

    set: (tab) => {
      if(!tab.show || tab.show()) {
        service.currentTab = tab;
      }
    },

    reset: () => {
      service.currentTab = service.all[defaultIndex];
      service.tabState = undefined;
      service.viewState = {};
    },

  }

  return service;
});
