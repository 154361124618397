angular.module('RocketWash').directive('rwSmartDateRange', ($filter) => {
  const SEPARATOR = '|';
  const RANGES = ['day', 'week', 'month'];

  return {
    restrict: 'E',
    scope: {
    },
    templateUrl: 'directives/rw-filters/rw-smart-date-range/rw-smart-date-range.slim',
    require: 'ngModel',
    link(scope, _element, _attrs, ngModelController) {
      ngModelController.$formatters.push((value) => {
        value = (value || '').toString();
        if (value.indexOf(SEPARATOR) < 0) {
          value += SEPARATOR;
        }
        const values = value.split(SEPARATOR).slice(0, 2);
        const result = { range: values[0], anchor: values[1] };
        if (!RANGES.includes(result.range)) {
          result.range = RANGES[0];
        }
        if (!moment(result.anchor).isValid()) {
          result.anchor = moment().format();
        }
        return result;
      });

      ngModelController.$parsers.push((value) => {
        return value.range + SEPARATOR + value.anchor;
      });

      ngModelController.$render = () => {
        scope.range = ngModelController.$viewValue.range;
        scope.anchor = ngModelController.$viewValue.anchor;
      };

      scope.ranges = RANGES;

      scope.$watchGroup(['range', 'anchor'], () => {
        ngModelController.$setViewValue({ range: scope.range, anchor: scope.anchor });
      });

      scope.formatDateRange = (range, anchor) => {
        anchor = moment(anchor);
        switch (range) {
        case 'day': return $filter('formatTime')(anchor, 'date');
        case 'week': return [
          $filter('formatTime')(moment(anchor).day(1), 'date'),
          $filter('formatTime')(moment(anchor).day(7), 'date'),
        ].join(' - ');
        case 'month': return $filter('formatTime')(anchor, 'month');
        default: return `invalid range: ${range}`;
        }
      };
    },
  };
});
