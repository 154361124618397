angular.module('RocketWash').controller(
  'AdminSettingsCarModelsController',
  ($q, $scope, userSession, CarType, CarMake, CarModel, CarTypesGroup) => {
    let reloadCarMakes;

    $scope.selected = {
      typeId: userSession.notifications.cars_without_categories ? 'unassigned' : 'all',
    };

    CarTypesGroup.query().then(function(groups) {
      $scope.groups = _.map(groups, function(t) {
        return new CarTypesGroup(t);
      });

      $scope.selected.group = $scope.groups[0];
      $scope.carTypesGroupChanged($scope.selected.group);
    });

    $scope.reloadCarTypes = () => {
      CarType.query().then((carTypes) => {
        $scope.carTypes = _.sortBy(carTypes, 'name');
      });
    };

    $scope.carTypesGroupChanged = (group) => {
      $scope.selected.group = group;
      $scope.reloadCarTypes();
      reloadCarMakes();
    };

    $scope.carTypeName = (model) => {
      const carType = $scope.carTypes
        .find(ct => parseInt(ct.id, 10) === parseInt(model.carTypeId, 10));
      return carType && carType.name;
    };
    $scope.changeSelected = (type) => {
      let promisses = [];
      $scope.selectedModels().forEach((model) => {
        model.carTypeIds = _.without(model.carTypeIds, $scope.selected.typeId);
        model.carTypeIds.push(type.id);
        promisses.push(model.save());
      });
      $q.all(promisses).then(() => {
        reloadCarMakes($scope.selected.typeId);
      });
    };
    $scope.selectedModels = () => {
      const results = [];
      ($scope.carMakes || []).forEach((make) => {
        (make.carModels || []).forEach((model) => {
          const all = $scope.selected.typeId == 'all';
          const typeEq = _.includes(model.carTypeIds, $scope.selected.typeId);
          const typeEqUnassigned = $scope.selected.typeId == 'unassigned' && _.intersection(model.carTypeIds, $scope.carTypes.map(ct => ct.id)).length == 0;
          if (model.selected && (all || typeEq || typeEqUnassigned)) {
            results.push(model);
          }
        });
      });
      return results;
    };
    $scope.selectMake = (make) => {
      const callback = () => {
        make.carModels.forEach((model) => {
          model.selected = make.selected;
        });
      };
      if (make.carModels) {
        callback();
      } else {
        $scope.loadMake(make, callback);
      }
    };
    $scope.addCar = (carMake) => {
      const newModel = new CarModel({
        carMakeId: carMake.id,
        carTypeId: $scope.selected.type.id,
      });
      if (carMake.carModels !== null) {
        carMake.carModels.unshift(newModel);
      } else {
        $scope.loadMake(carMake, () => {
          carMake.carModels = carMake.carModels || [];
          carMake.carModels.unshift(newModel);
        });
      }
    };
    $scope.$watch('selected.typeId', (carTypeId) => {
      reloadCarMakes(carTypeId);
    });
    reloadCarMakes = (carTypeId) => {
      const parsedCarTypeId =
        (carTypeId !== 'unassigned' && carTypeId !== 'all') ? carTypeId : null;
      const parsedUnassigned = carTypeId === 'unassigned' ? true : null;
      CarMake.query({
        car_type_id: parsedCarTypeId,
        unassigned: parsedUnassigned,
      }).then((data) => {
        $scope.carMakes = _.sortBy(data, 'name');
      });
    };
    $scope.save = (model) => {
      model.save();
    };
    $scope.toggleMake = (make) => {
      if (make.opened) {
        make.opened = false;
      } else {
        make.opened = true;
        $scope.loadMake(make);
      }
    };
    $scope.showImages = (make, model) => {
      if (!model.images) {
        model.searchImages().then((images) => {
          model.imagesLoaded = true;
          model.images = images;
        });
      };
      model.showImages = true;
    };
    $scope.hideImages = (make, model) => {
      model.showImages = false;
    };
    $scope.loadMake = (make, callback) => {
      if (make.carModels) {
        return;
      }
      CarMake.get(make.id).then((data) => {
        data.carModels = data.carModels.map(cm => new CarModel(cm));
        data.carModels = _.sortBy(data.carModels, 'name');
        angular.extend(make, data);
        if (typeof callback === 'function') {
          callback();
        }
      });
    };


    $scope.shouldShowCarModel = (model) => {
      return _.includes(model.carTypeIds, $scope.selected.typeId) ||
        $scope.selected.typeId == 'all' ||
        ($scope.selected.typeId == 'unassigned' && _.intersection(model.carTypeIds, $scope.carTypes.map(ct => ct.id)).length == 0 )
    };
  },
);
