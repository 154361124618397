angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.client_cabinet.dashboard.general_info', {
    url: '/general_info',
    tab: 'general_info',
    data: {
      publicPage: true,
      permission: 'DashboardTab'
    },
    views: {
      'content@app.client_cabinet.dashboard': {
        controller: 'ClientCabinetDashboardGeneralInfoController',
        templateUrl: 'pages/client_cabinet/dashboard/general_info/index.slim'
      }
    }
  })
})
