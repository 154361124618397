

var extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

angular.module('RocketWash').factory('Plan', function(railsResourceFactory, userSession, $http) {
  var Plan, PlanFactory;
  PlanFactory = railsResourceFactory({
    url: (context) => { return "/" + (context.queryRole || userSession.controllerScope()) + "/plans/" + (context.id || '') },
    name: "plan"
  });
  return Plan = (function(superClass) {
    extend(Plan, superClass);

    function Plan() {
      return Plan.__super__.constructor.apply(this, arguments);
    }

    Plan.current = function(context) {
      return $http({
        url: (this.resourceUrl(context)) + "/current",
        method: 'GET'
      }).then(function(response) {
        return response.data;
      });
    };

    return Plan;

  })(PlanFactory);
});
