angular.module('RocketWash').config(($stateProvider) => {
  $stateProvider.state('app.taxi.settings.integrations', {
    url: '/integrations',
    tab: 'integrations',
    views: {
      'content@app.taxi.settings': {
        controller: 'TaxiSettingsIntegrationsController',
        templateUrl: 'pages/taxi/settings/integrations/index.slim',
      },
    },
  });
});
