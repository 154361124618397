

angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.settings.devices', {
    url: '/devices',
    tab: 'devices',
    data: {
      permission: 'DevicesTab'
    },
    views: {
      'content@app.wash.settings': {
        controller: 'WashSettingsDevicesController',
        templateUrl: 'pages/wash/settings/devices/index.slim'
      }
    }
  }).state('app.wash.settings.devices.edit', {
    url: '/edit/:id',
    tab: 'devices',
    data: {
      permission: 'DevicesTab'
    },
    views: {
      'content@app.wash.settings': {
        controller: 'WashSettingsDevicesEditController',
        templateUrl: 'pages/wash/settings/devices/edit.slim'
      }
    }
  });
});
