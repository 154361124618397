angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  $urlRouterProvider.when('/partner', '/partner/income/report');
  return $stateProvider.state('app.partner', {
    url: '/partner',
    abstract: true,
    templateUrl: 'pages/partner/index.slim',
    resolve: {
      us: function(userSession) {
        return userSession.promise;
      }
    }
  });
});
