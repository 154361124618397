angular.module('RocketWash').service('reservationFormCarContractor', ($injector, alertService, rwApi) => {

  const service = {
    car: {},
    similarCar: null,
    contractor: {},
    contractorCars: [],
    selectedCar: null,
    searchText: '',
    viewState: {},
    canSave: true,

    assignState: (car) => {
      const rf = $injector.get('reservationForm');

      service.car = car;
      service.car.cars_car_types = service.car.cars_car_types || [];
      service.contractorCars = car.contractor.cars;
      service.contractor = car.contractor;

      car.contractor.dateOfBirthFormatted = moment(car.contractor.date_of_birth);

      service.selectedCar = service.contractorCars.find((c) => c.id == service.car.id);
    },

    searchCar: ({ query }) => {
      return rwApi.sendRequest({
        method: 'GET',
        path: 'reservation_form/cars/search',
        params: {
          query: query,
        }
      });
    },

    updateCarModel: () => {
      const data = service.getCarModel();
      return rwApi.sendSaveRequest({
        path: 'reservation_form/car_models',
        data,
      }).then((data) => {
        if (!data.errors) {
          alertService.show({
            text: 'Тип кузова для модели успешно сохранен!',
            timeout: 2000,
          });
        };
      }).catch(() => {
        service.getCarModel().car_type_id = null;
      });
    },

    searchSimilar: () => {
      const tag = _.get(service, 'car.tag');
      const phone = _.get(service, 'car.contractor.phone');
      const contractor_id = _.get(service, 'car.contractor.id');
      if (tag && phone && !contractor_id) {
        return rwApi.sendRequest({
          method: 'GET',
          path: 'reservation_form/cars/similar',
          params: {
            tag,
            phone,
          }
        }).then((car) => {
          service.similarCar = car;
        });
      } else {
        service.similarCar = null;
        return Promise.resolve();
      }
    },

    canContinue: () => {
      const rf = $injector.get('reservationForm');
      return (service.car.id || service.car.car_model_id) && service.hasCarTypes();
    },

    selectSimilarCar: () => {
      const rf = $injector.get('reservationForm');

      service.selectCar(service.similarCar);

      rf.formTab.toggleViewFlag('carEditable', false);
      rf.formTab.toggleViewFlag('contractorEditable', false);
    },

    selectCar: (car) => {
      if (!car || car.doNothing) {
        return Promise.resolve();
      };

      const rf = $injector.get('reservationForm');

      if (car.newCar) {
        if (car.newContractor) {
          service.car = {
            tag: car.tag,
            cars_car_types: [],
            contractor: {},
          };
        } else {
          const newCar = {
            contractor: service.contractor,
            cars_car_types: [],
          };
          service.car = newCar;
        }
        rf.services.reassign();
      }

      service.searchText = '';

      if (car.id) {
        const shouldResetServices = car.id != service.car.id;
        return service.loadCar(car.id).then(() => {
          if (shouldResetServices) {
            rf.services.reassign();
          }
          rf.marketing.loadAll();
        });
      } else {
        return Promise.resolve();
      }
    },

    loadCar: (carId) => {
      return rwApi.sendRequest({
        method: 'GET',
        path: 'reservation_form/cars',
        params: {
          id: carId,
        }
      }).then((c) => {
        service.assignState(c);
      });
    },

    reset: () => {
      service.searchText = '';
      service.car = {};
      service.contractor = {};
      service.contractorCars = [];
      service.selectedCar = null;
      service.viewState = {};
      service.similarCar = null;
    },

    prepareForSave: () => {
      const car = service.car || {};
      const contractor = car.contractor || {};

      const carAttributes = _.pick(car, ['id', 'tag', 'car_make_id', 'car_model_id', 'year', 'vin', 'color', 'cars_car_types']);
      const contractorAttributes = _.pick(contractor, ['id', 'name', 'phone', 'disable_sms', 'disable_whatsapp', 'sex', 'additional_info', 'users_price_lists']);
      carAttributes.contractor = contractorAttributes;
      carAttributes.contractor_id = contractorAttributes.id;

      contractorAttributes.date_of_birth = contractor.dateOfBirthFormatted && contractor.dateOfBirthFormatted.toISOString(true);

      return carAttributes;
    },

    getCarMake: () => {
      const rf = $injector.get('reservationForm');
      return _.get(rf, 'config.constants.car_makes', []).find((m) => m.id == service.car.car_make_id);
    },

    getCarModel: () => {
      const rf = $injector.get('reservationForm');
      const car_models = _.get(rf, 'config.constants.car_models', {});
      const models = car_models[service.car.car_make_id];
      return (models || []).find((m) => m.id == service.car.car_model_id);
    },

    hasCarTypes: () => {
      const ccts = _.get(service, 'car.cars_car_types', []);
      return ccts.length > 0;
    },

    saveCar: () => {
      let data = service.prepareForSave();

      return rwApi.sendSaveRequest({
        path: 'reservation_form/cars',
        data,
      }).then((data) => {
        if (!data.errors) {
          service.selectCar(data);

          alertService.show({
            text: 'Автомобиль успешно сохранен!',
            timeout: 2000,
          });
        }
      });
    },

    deleteCar: () => {
      return rwApi.sendRequest({
        path: 'reservation_form/cars',
        method: 'DELETE',
        params: {
          id: service.car.id
        },
      }).then((data) => {
        if (!data.errors) {
          service.contractorCars = service.contractorCars.filter((c) => {
            return service.car.id != c.id;
          });

          alertService.show({
            text: 'Автомобиль успешно удален!',
            timeout: 2000,
          });
        };
      });
    },

    saveContractor: () => {
      let data = service.prepareForSave().contractor;

      return rwApi.sendSaveRequest({
        path: 'reservation_form/customers',
        data,
      }).then((data) => {
        if (!data.errors) {
          service.selectCar(service.car);

          alertService.show({
            text: 'Клиент успешно сохранен!',
            timeout: 2000,
          });
        };
      });
    },

    checkCarTagUniqueness: () => {
      const rf = $injector.get('reservationForm');

      if (!rf.carContractor.car.tag) return

      return rwApi.sendRequest({
        path: '/reservation_form/cars/check_tag_uniqueness',
        method: 'GET',
        params: {
          tag: rf.carContractor.car.tag
        }
      }).then((data) => {
      })
    },

    checkCustomerPhoneUniqueness: () => {
      const rf = $injector.get('reservationForm');

      return rwApi.sendRequest({
        path: '/reservation_form/customers/check_phone_uniqueness',
        method: 'GET',
        params: {
          phone: rf.carContractor.car.contractor.phone
        }
      }).then((data) => {
      })
    },

    isValid: () => {
      const car = service.car;

      // Manufacture year check
      let validCarYear = !car.year || /^\d{4}/.test(car.year);

      return validCarYear;
    },

    onCarEdit: () => {
      if (!service.isValid()) {
        service.canSave = false;
      } else {
        service.canSave = true;
      }
    },

  };

  return service;
});
