angular.module('RocketWash').directive('rwSchedules', (
  dateRanges, pageDataService, dateRangeStorage, $rootScope, $templateCache, Schedule, SettingValue, $q,
) => {
  const DAYS = 7;
  const HOURS = 24;
  const PERIODS = HOURS * 4; // 24 hours with 15 minutes intervals

  const DAY = 'day';
  const NIGHT = 'night';
  const OFF = 'off';

  return {
    restrict: 'E',
    scope: {
    },
    // templateUrl: 'directives/rw-schedules/rw-schedules.slim',
    template: function () {
      const layout = $templateCache.get('directives/rw-schedules/rw-schedules.slim');

      let table = [];

      // TABLE HEADER
      table.push(`<table ng-class="'schedule-step-' + selected.period" class="rw-schedules__table" ng-mousedown="startFilling($event)" ng-mousemove="mouseMove($event)" ng-mouseup="endFilling($event)">
                    <tbody>
                       <tr>
                          <td class="day-name"></td>`);

      for (let hour = 0; hour < HOURS; hour++) {
        table.push(`<td class="hours-minutes" colspan="{{60 / selected.period}}">
                      <span class="hours">${hour}</span>
                      <span class="minutes">00</span>
                    </td>`);
      }

      table.push(`</tr>
                </tbody>`);

      // TABLE ROWS
      for (let day = 0; day < DAYS; day++) {
        table.push(`<tbody>
                      <tr class='periods-row'>
                         <td class="day-name">{{'settings.schedules.weekdays_full.${day}' | translate}}</td>`);

//  
        for (let period = 0; period < PERIODS; period++) {
          const hours = Math.floor(period / 4);
          const minutes = period * 15 % 60;
          table.push(`<td ng-if="${minutes} % selected.period == 0" ng-class="cellClass(days[${day}][${period}])" data-day='${day}' data-hours='${hours}' data-minutes='${minutes}' data-period='${period}'></td>`);
        }

        table.push(`</tr>
                    <tr class="spacing-row"></tr>
                 </tbody>`);

      }

      table.push(`</table>`);

      return layout.replace('SCHEDULES_TABLE_GOES_HERE', table.join(''));
    },
    link(scope, _element, _attrs) {
      const settingValuesLoaded = SettingValue.query().then((settings) => {
        scope.schedulePeriodSetting = settings.find(x => x.key === 'schedule_period');
        scope.selected.period = parseInt(scope.schedulePeriodSetting.value);
      });

      scope.isAnyError = () => {
        let error = false;
        scope.days.forEach((day) => {
          day.forEach((period) => {
            error = error || period.error;
          });
        });

        return error;
      };

      scope.cellClass = (cell) => {
        let classes = [cell.newState || cell.state];
        if (cell.error) {
          classes.push('error');
        };
        return classes.join(' ');
      };

      scope.selectPeriod = (period) => {
        scope.selected.period = period;
        scope.schedulePeriodSetting.value = period;
        scope.schedulePeriodSetting.save();

        // Make sure full period has the same state
        const quarterPeriodsInSinglePeriod = period / 15;
        scope.days.forEach((day) => {
          for (let periodIndex = 0; periodIndex < PERIODS; periodIndex++) {
            const getStateFromIndex = Math.floor(periodIndex / quarterPeriodsInSinglePeriod) * quarterPeriodsInSinglePeriod;
            day[periodIndex].state = day[getStateFromIndex].state;
          }
        });

        updateSchedules();
        saveSchedules();
        fillPeriodStates(false);
      };

      const schedulesLoaded = Schedule.query().then((schedules) => {
        scope.schedules = schedules.sort((a, b) => a.day_index - b.day_index);

        scope.days = [...Array(DAYS)].map((_, day) => {
          return [...Array(PERIODS)].map((_, period) => {
            let secondOfDay = period * 15 * 60;

            return {
              hours: Math.floor(period/4),
              minutes: (period % 4) * 15,
              state: OFF,
              error: false,
            };
          });
        });

        fillPeriodStates(false);
      });

      $q.all([settingValuesLoaded, schedulesLoaded]).then(() => {
        scope.readyToShow = true;
      });

      scope.types = [DAY, NIGHT, OFF];
      scope.periods = [15, 30, 60];
      scope.selected = {
        type: DAY,
        period: 60,
      };

      scope.filling = {
        active: false,
        from: null,
        to: null,
      };

      const fillPeriodStates = (showErrors = true) => {
        scope.days.forEach((_, day) => {
          scope.days[day].forEach((_, period) => {
            let secondOfDay = period * 15 * 60;
            let state = OFF;

            // Overlaps with day period
            if (secondOfDay >= scope.schedules[day].dayOpenAt.secondOfDay && secondOfDay < scope.schedules[day].dayCloseAt.secondOfDay) {
              state = DAY;
            }

            // Overlaps with previous day schedule night shift
            let prevSchedule = scope.schedules[(day + 6) % 7];
            if (prevSchedule.nightOpenAt.secondOfDay > prevSchedule.nightCloseAt.secondOfDay && secondOfDay < prevSchedule.nightCloseAt.secondOfDay) {
              state = NIGHT;
            }

            // Overlaps with current day schedule night shift
            let endOfNightSeconds = scope.schedules[day].nightCloseAt.secondOfDay;
            if (scope.schedules[day].nightOpenAt.secondOfDay > scope.schedules[day].nightCloseAt.secondOfDay) {
              endOfNightSeconds = 24 * 60 * 60;
            }
            if (secondOfDay >= scope.schedules[day].nightOpenAt.secondOfDay && secondOfDay < endOfNightSeconds) {
              state = NIGHT;
            }

            if (secondOfDay >= scope.schedules[day].secondNightOpenAt.secondOfDay && secondOfDay < scope.schedules[day].secondNightCloseAt.secondOfDay) {
              state = NIGHT;
            }

            if (showErrors) { // Just show error
              scope.days[day][period].error = scope.days[day][period].state != state;
            } else { // Fill cell state
              scope.days[day][period].state = state;
            }
          });
        });
      };

      const parseCellDataWrapper = (func) => {
        return _.throttle((event) => {
          let data = angular.copy(event.target.dataset);

          if (data && data.period) {
            Object.keys(data).forEach((key) => {
              data[key] = parseInt(data[key]);
            });
            func(data);
          } else {
            func(null);
          };
        }, 50, {leading: true, trailing: true});
      };

      const fillCellsFromTo = () => {
        scope.filling.to = scope.filling.to || scope.filling.from;
        let fromIndex = scope.filling.from.period;
        let toIndex = scope.filling.to.period;

        [fromIndex, toIndex] = [fromIndex,toIndex].sort((a, b) => a - b);

        // Fill all cells under specified period instead of 1 cell
        toIndex = toIndex - 1 + scope.selected.period / 15;

        scope.days[scope.filling.from.day].forEach((day, index) => {
          if (index >= fromIndex && index <= toIndex) {
            day.newState = scope.selected.type;
          } else {
            day.newState = undefined;
          }
        });
      }

      const applyStates = () => {
        [].concat(...scope.days).forEach((day) => {
          if (day.newState == scope.selected.type) {
            day.state = day.newState;
            day.newState = undefined;
          }
        });
      }

      const updateSchedules = () => {
        scope.days.forEach((day, dayIndex) => {
          let nextDay = scope.days[(dayIndex + 1) % 7];
          let schedule = scope.schedules[dayIndex];
          let prevDay = scope.days[(dayIndex + 6) % 7];

          let calc = {
            dayOpenAt: null,
            dayCloseAt: null,
            nightOpenAt: null,
            nightCloseAt: null,
            secondNightOpenAt: null,
            secondNightCloseAt: null,
          };

          // Calculate for day shift
          for (let periodIndex in day) {
            periodIndex = parseInt(periodIndex);
            let period = day[periodIndex];
            let nextPeriod = day[periodIndex + 1];
            if (period.state == DAY) {
              calc.dayOpenAt = calc.dayOpenAt || period;
              if (!nextPeriod || nextPeriod.state != DAY) {
                calc.dayCloseAt = period;
                break;
              }
            }
          };

          // Calculate for night shift
          let dayCloseAtIndex = calc.dayCloseAt ? day.indexOf(calc.dayCloseAt) : -1;
          let twoDays = day.slice(dayCloseAtIndex+1);
          if (day[day.length - 1].state == NIGHT) {
            twoDays = [].concat(twoDays, nextDay);
          };
          for (let periodIndex in twoDays) {
            periodIndex = parseInt(periodIndex);
            let period = twoDays[periodIndex];
            let nextPeriod = twoDays[periodIndex + 1];
            // Next day started. Abort night period search
            if (period.state == DAY) {
                break;
            }
            if (period.state == NIGHT) {
              calc.nightOpenAt = calc.nightOpenAt || period;
              if (!nextPeriod || nextPeriod.state != NIGHT) {
                calc.nightCloseAt = period;
                break;
              }
            }
          };

          //Calculate for night shift
          //Receiving the last element of massive
          let lastIndexOfMassive = prevDay.length - 1;
          if (!(day[0].state == NIGHT && prevDay[lastIndexOfMassive].state == NIGHT)) {
            for (let periodIndex in day) {
              periodIndex = parseInt(periodIndex);
              let period = day[periodIndex];
              let nextPeriod = day[periodIndex + 1];
              if (period.state == DAY) {
                break;
              }
              if (period.state == NIGHT) {
                calc.secondNightOpenAt = calc.secondNightOpenAt || period;
                if (!nextPeriod || nextPeriod.state != NIGHT) {
                  calc.secondNightCloseAt = period;
                  break;
                }
              }
            }
            ;
          }

          const defaultSecondsValues = {
            dayOpenAt:    0,
            dayCloseAt:   0,
            nightOpenAt:  24*60*60 - 1,
            nightCloseAt: 24*60*60 - 1,
            secondNightOpenAt: 0,
            secondNightCloseAt: 0,
          };

          //Convert periods to schedule TOD
          ['dayOpenAt', 'dayCloseAt', 'nightOpenAt', 'nightCloseAt', 'secondNightOpenAt', 'secondNightCloseAt'].forEach((key) => {
            let second = null;

            if (calc[key]) {
              let hours = calc[key].hours;
              let minutes = calc[key].minutes;
              second = hours * 3600 + minutes * 60;
            }

            //In the case if secondNightOpenAt absent
            if (second == null || key != "secondNightOpenAt") {
              second = second || defaultSecondsValues[key];
            }

            //Add 15 mins to each close time but use 23:59:59 instead of 24:00:00
            if (calc[key] && key.search(/Close/) >= 0) {
              second += 15*60;
              if (second == 24*60*60) { second -= 1; }
            }

            schedule[key] = { secondOfDay: second };
          });

        });
      };

      const saveSchedules = () => {
        scope.schedules.forEach((schedule) => {
          schedule.save();
        });
      };

      scope.startFilling = parseCellDataWrapper((data) => {
        if (data) {
          scope.filling.active = true;
          scope.filling.from = data;
          scope.fillCells(data);
        }
      });

      scope.endFilling = parseCellDataWrapper((data) => {
        if (data) {
          scope.filling.to = data;
          scope.fillCells(data);
        }
        applyStates();
        updateSchedules();
        saveSchedules();
        fillPeriodStates();

        scope.filling.active = false;
        scope.filling.from = null;
        scope.filling.to = null;
      });

      scope.mouseMove = parseCellDataWrapper((data) => {
        scope.filling.from = scope.filling.from || data;
        scope.filling.to = data;
        scope.fillCells(data);
        // scope.highlightHeaders(data);
      });

      scope.fillCells = (data) => {
        if (!data || !scope.filling.active) { return };
        fillCellsFromTo();
      };

      scope.todToTime = (tod) => {
        let hour = Math.floor(tod.secondOfDay / 3600);
        let minute = Math.floor((tod.secondOfDay % 3600) / 60);
        return `${('00' + hour).slice(-2)}:${('00' + minute).slice(-2)}`;
      }
    },
  };
});
