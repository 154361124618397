angular.module('RocketWash').controller(
  'WashControlSuspiciousActivitiesController',
  ($scope, userSession, $http, SuspiciousActivity, $filter) => {

    SuspiciousActivity.query().then((response) => {
      $scope.activities = response.data;
    });

    $scope.pds = $scope.pageDataService.new({
      page: 'suspicious_activities',
      filters: {
        attributes: [
          {
            name: 'target_type',
            type: 'string',
          },
          {
            name: 'reservation_ordinal',
            conditions: ['custom__reservation_ordinal_in_suspicious_activities'],
            type: 'number',
          },
          {
            name: 'back_to_work_time',
            conditions: ['custom__back_to_work_in_suspicious_activities'],
          },
          {
            name: 'box',
            conditions: ['custom__box_in_suspicious_activities'],
            type: 'string'
          },
          {
            name: 'excess',
            conditions: ['custom__excess_in_suspicious_activities'],
            type: 'string'
          },
          {
            name: 'old_price',
            conditions: ['custom__old_price_in_suspicious_activities'],
            type: 'number'
          },
          {
            name: 'new_price',
            conditions: ['custom__new_price_in_suspicious_activities'],
            type: 'number'
          },
        ],
        scopes: ['target_type', 'reservation_ordinal', 'box', 'excess', 'old_price', 'new_price'],
        beforeSend: (filters) => {
          filters.map(filter => {
            switch (filter.attr) {
              case 'reservation_ordinal':
                filter.condition = 'custom__reservation_ordinal_in_suspicious_activities'
                break;
              case 'box':
                filter.condition = 'custom__box_in_suspicious_activities'
                break;
              case 'excess':
                filter.condition = 'custom__excess_in_suspicious_activities'
                break;
              case 'old_price':
                filter.condition = 'custom__old_price_in_suspicious_activities'
                break;
              case 'new_price':
                filter.condition = 'custom__new_price_in_suspicious_activities'
                break;
            }
          })
        },
      },
      pg: {
        url: '/wash/suspicious_activities',
        model: SuspiciousActivity,
        query: {
          fromToField: 'created_at',
          predicate: '-created_at',
          page: 1,
          per: 10,
        },
      },
      definePaginateMethodsOn: $scope,
    });

    $scope.activityDescriptionPath = (type) => {
      const typePrefix = 'SuspiciousActivity::';

      let transformedType = type.split(typePrefix)[1].replace('::', '/');

      return $filter('toSnakeCase')(transformedType);
    }
  })
