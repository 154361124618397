angular.module('RocketWash').controller(
  'WashSettingsGeneralController',
  (
    $scope, $route, $location, $window,
    userSession, SettingValue, serverConstants, Organization,
  ) => {
    $scope.searchQuery = '';
    $scope.predicate = 'name';
    $scope.reverse = true;

    $scope.loadOrganization = () => {
      Organization.get(userSession.organization.id).then((organization) => {
        $scope.organization = organization;
      });
    };

    const reloadSettingValues = () => {
      SettingValue.query().then((settings) => {
        $scope.settings = settings.filter(x => x.keyType === 'CommonSettingKey' &&
          x.key !== 'notify_phone_on_reservation_create' && x.key !== 'forced_sms_notify_phone_on_reservation_create'
        );

        const prs1 = settings.find(x => x.key === 'use_pre_reservations');
        const prs2 = settings.find(x => x.key === 'pre_reservation_default_duration');
        const prs3 = settings.find(x => x.key === 'pre_reservation_time_start_round_to');

        if (prs1.value == 'yes') {
          $scope.preReservationSettings = [prs1, prs2, prs3];
        } else {
          $scope.preReservationSettings = [prs1];
        }

        const useShiftsAccountingSetting = $scope.settings.find(x => x.key === 'use_shifts_accounting');
        if (useShiftsAccountingSetting) {
          useShiftsAccountingSetting.readonly =
            !$scope.userSession.service_location.plan.settings.shifts_accounting;
        }
        const useDiscountProgramSetting = $scope.settings.find(x => x.key === 'use_discount_program');
        if (useDiscountProgramSetting) {
          useDiscountProgramSetting.readonly =
            !$scope.userSession.service_location.plan.settings.discount_program;
        }
        const useImmutablePricesSetting = $scope.settings.find(x => x.key === 'use_immutable_prices');
        if (useImmutablePricesSetting) {
          useImmutablePricesSetting.readonly =
            !$scope.userSession.service_location.plan.settings.immutable_prices;
        }
        const useRefundBonusesSetting = $scope.settings.find(x => x.key === 'use_refund_bonuses_only_when_phone_present');
        if (useRefundBonusesSetting) {
          useRefundBonusesSetting.readonly =
            !$scope.userSession.service_location.plan.settings.refund_bonuses_only_when_phone_present;
        }
        const useWithdrawBonusesSetting = $scope.settings.find(x => x.key === 'use_withdraw_bonuses_only_when_phone_confirmed');
        if (useWithdrawBonusesSetting) {
          useWithdrawBonusesSetting.readonly =
            !$scope.userSession.service_location.plan.settings.withdraw_bonuses_only_when_phone_confirmed;
        }
        const useTagsAnalyticsSetting = $scope.settings.find(x => x.key === 'use_tags_analytics');
        if (useTagsAnalyticsSetting) {
          useTagsAnalyticsSetting.readonly =
            !$scope.userSession.service_location.plan.settings.tags_analytics;
        }
        const roundingStepSetting = settings.find(x => x.key === 'rounding_step');
        if (roundingStepSetting && !roundingStepSetting.value) {
          roundingStepSetting.value = serverConstants.setting_values.rounding_step[4];
        }
      });
    };
    reloadSettingValues();

    $scope.SETTING_VALUES = serverConstants.setting_values;
    $scope.$on('search-change', (_event, data) => {
      $scope.searchQuery = data.value;
    });
    $scope.save = function (setting) {
      setting.save().then(() => {
        userSession.reload();
        $route.reload();
        reloadSettingValues();
      });
    };

    $scope.saveOrganization = () => {
      $scope.organization.save().then(
        (response) => {
          const newDomain =
            `${response.subdomainName}.${$location.host().split('.').splice(1).join('.')}`;
          const newUrl = `${$location.protocol()}://${newDomain}:${$location.port()}/`;
          $window.location.href = newUrl;
        },
        (error) => {
          console.log(error);
          $scope.loadOrganization();
        },
      );
    };

    $scope.loadOrganization();
  },
);
