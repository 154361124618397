angular.module('RocketWash').factory(
  'Consumable',
  ($http, railsResourceFactory, railsSerializer, userSession) => {
    const Resource = railsResourceFactory({
      url: (context) => { return "/" + (context.queryRole || userSession.controllerScope()) + "/consumables/" + (context.id || '') },
      name: 'consumable',
      serializer: railsSerializer(function () {
        this.nestedAttribute('contractor');
      }),
    });

    Resource.indexByServices = (context) => {
      return $http({
        method: 'GET',
        url: `${Resource.resourceUrl(context)}/index_by_services`,
      }).then((response) => {
        return response.data;
      });
    };

    Resource.destroyAll = (params, context) => {
      return $http({
        method: 'DELETE',
        url: `${Resource.resourceUrl(context)}/destroy_all`,
        params: params,
      }).then((response) => {
        return response.data;
      });
    };

    return Resource;
  },
);
