angular.module('RocketWash').controller(
  'WashChartsBaseController',
  ($scope, $rootScope, $http, $translate, CarType, ServiceLocation, userSession, serverConstants, ctgSelectService) => {
    $scope.ctgSelectService = ctgSelectService;
    $scope.group = {
      ranges: serverConstants.group_ranges,
      range: 'month',
    };
    $rootScope.loading = false;
    $scope.selected = {};

    ServiceLocation.query().then((sls) => {
      $scope.sls = [{ name: 'Все мойки' }].concat(sls);
    });

    CarType.query().then((carTypes) => {
      $scope.carTypes = carTypes;
    });
    Highcharts.setOptions({
      global: {
        useUTC: false,
        timezoneOffset: -moment(userSession.time_zone).utcOffset(),
      },
      colors: [
        '#b3fcb3', '#ffbfbf', '#96caff', '#50B432', '#ED561B', '#DDDF00',
        '#24CBE5', '#64E572', '#FF9655', '#FFF263', '#6AF9C4',
      ],
    });
  },
);
