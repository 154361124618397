angular.module('RocketWash').controller('AdminOrganizationsSettingsReindexController', ($scope, $http, $translate, $stateParams, serverConstants, alertService) => {
  $scope.models = serverConstants.tenant_indexable_models;

  $scope.reindex = (model) => {
    $http.post('/admin/reindices/reindex', {model_name: model, org_id: $stateParams.orgId}).then((resp) => {
      alertService.show({
        text: `Реиндексация модели ${model} успешно началась!`,
      });
    });
  };
});
