angular.module('RocketWash').component('rwReservationPrintButton', {
  templateUrl: 'components/rw-reservation/common/print-button/index.slim',
  controllerAs: 'vm',
  controller: function ($scope, $translate, userSession, reservationForm, printService) {
    $scope.rf = reservationForm;
    $scope.open = false;
    $scope.rf = reservationForm;
    $scope.ladda = {
      printing: false
    };

    $scope.documents = angular.copy(reservationForm.config.constants.printable_documents);
    $scope.documents.forEach((doc) => {
      doc.selected = doc.default;
    });

    $scope.toggle = () => {
      $scope.open = !$scope.open;
    };

    $scope.print = () => {
      if ($scope.ladda.printing) {
        return;
      };

      $scope.ladda.printing = true;
      reservationForm.reservations.save({ closeAfterSave: false }).then(() => {
        printService.printToAll({
          docs: $scope.documents.filter(d => d.selected),
          targetId: reservationForm.r().id,
          targetType: 'Reservation',
          fiscalCheckPrintedCallback: () => {
            reservationForm.r().kkm_fiscal_check_printed_at = moment();
            reservationForm.reservations.save();
          }
        }).finally(() => {
          $scope.ladda.printing = false;
        });
        $scope.open = false;
      });
    };

    $scope.showDocument = (doc) => {
      const kkmDoc = doc.deviceIdentifier && doc.deviceIdentifier.match('kkmserver');
      const canBypass = userSession.is('director') || userSession.is('admin') || userSession.is('organization_director');
      const alreadyPrinted = !!reservationForm.r().kkm_fiscal_check_printed_at;
      if (kkmDoc && doc.fiscal) {
        return !alreadyPrinted || canBypass;
      } else {
        return true;
      }
    };

    $scope.toggleDocument = (doc) => {
      if ($scope.showDocument(doc)) {
        doc.selected = !doc.selected;
      };
    };
  },
});
