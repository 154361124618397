angular.module('RocketWash').controller('WashSettingsUltrasonicSensorsBaseController', function($rootScope, $scope, $state, $translate, $interval, $timeout, UltrasonicSensor, ServiceLocationLane) {

  ServiceLocationLane.query().then(data => $scope.serviceLocationLanes = [{name: 'Не присвоен', id: null}, ...data]);

  $scope.selected = {};

  $scope.selectSensor = (sensor) => {
    if ($scope.selected.sensor && $scope.selected.sensor.id == sensor.id) { return };

    $scope.selected.sensor = new UltrasonicSensor(sensor);
    updateChart($scope.selected.sensor);
  };

  const updateSensors = () => {
    return UltrasonicSensor.query().then((uss) => {
      $scope.ultrasonicSensors = uss;
    });
  };
  updateSensors().then(() => {
    $scope.selectSensor($scope.ultrasonicSensors[0]);
  });
  const interval = $interval(() => {
    updateSensors();
  }, 10000);

  $scope.$on('$destroy', () => {
    $interval.cancel(interval);
  });

  $scope.save = function(us) {
    us.save().then(function() {
      updateSensors();
    });
  };

  $scope.deleteSensor = (us) => {
    if (confirm($translate.instant('alert.are_you_sure'))) {
      return us["delete"]().then(function() {
        return updateSensors();
      });
    }
  };

  $scope.latestValue = (us) => {
    if (us.latestUltrasonicMeasurements) {
      return us.latestUltrasonicMeasurements[us.latestUltrasonicMeasurements.length-1];
    } else {
      return [];
    };
  };

  const updateChart = (sensor) => {
    sensor.getMeasurements().then((response) => {
      const series = [{
        name: 'Дистанция',
        color: '#FFC175',
        data: response.data,
        tooltip: {
          valueSuffix: 'см',
        },
      }];
      $scope.chart = new Highcharts.StockChart({
        title: {
          text: $translate.instant('charts.income.title'),
        },
        subtitle: {
          text: null,
        },
        rangeSelector: {
          selected: 0,
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: $translate.instant('charts.income.labels.date'),
            style: '',
          },
          // events: {
          //   setExtremes: (e) => {
          //     $scope.$apply(() => {
          //       $scope.rangeMin = e.min;
          //       $scope.rangeMax = e.max;
          //     });
          //   },
          // },
          // plotBands: $scope.bands,
        },
        yAxis: {
          title: {
            text: 'Дистанция, см.',
            style: '',
          },
          labels: {
            plotLines: [
              {
                value: 0,
                width: 2,
                color: 'silver',
              },
            ],
          },
        },
        plotOptions: {
          // turboThreshold: 10000,
          series: {
            stacking: 'normal',
            marker: {
              enabled: false,
            },
          },
        },
        series: series,
        legend: {
          borderRadius: 2,
          borderColor: '#c1c1c1',
          backgroundColor: '#f9f9f9',
        },
        chart: {
          renderTo: 'ultrasonic-measurements-chart',
          borderRadius: 3,
          showAxes: true,
          borderColor: '#c4c4c4',
          reflow: false,
          backgroundColor: '#ffffff',
          spacing: '10,10,15,10',
          type: 'area',
          plotBackgroundImage: null,
          spacingLeft: 8,
          spacingBottom: 8,
          spacingRight: 8,
          plotBorderWidth: 6,
          borderWidth: 1,
          selectionMarkerFill: 'rgb(137,210,255)',
          zoomType: 'x',
          plotBorderColor: '#ffffff',
          plotBackgroundColor: '#fcfcfc',
          spacingTop: 8,
        },
        exporting: {
          scale: 1,
          enabled: false,
          sourceHeight: 10,
          sourceWidth: 10,
        },
        tooltip: {
          formatter() {
            let result = `<span> ${parseInt(this.y)}см</span>`;
            return result;
          }
        }
      });
    });
  };

});
