

var extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

angular.module('RocketWash').factory('CashShift', function(railsResourceFactory, userSession, $http) {
  var CashShift, CashShiftFactory;
  CashShiftFactory = railsResourceFactory({
    url: (context) => { return "/" + (context.queryRole || userSession.controllerScope()) + "/cash_shifts/" + (context.id || '') },
    name: "cash_shift"
  });
  return CashShift = (function(superClass) {
    extend(CashShift, superClass);

    function CashShift() {
      return CashShift.__super__.constructor.apply(this, arguments);
    }

    CashShift.previous = function(queryParams) {
      return $http({
        url: (this.$url()) + "/previous.json",
        params: queryParams,
        method: 'GET'
      }).then(function(response) {
        if (!_.isEmpty(response.data)) {
          return new CashShift(response.data);
        }
      });
    };

    CashShift.current = function(queryParams) {
      return $http({
        url: (this.$url()) + "/current.json",
        params: queryParams,
        method: 'GET'
      }).then(function(response) {
        if (!_.isEmpty(response.data)) {
          return new CashShift(response.data);
        }
      });
    };

    return CashShift;

  })(CashShiftFactory);
});
