angular.module('RocketWash').controller('WashFinancialProjectsFormController', function($scope, $state, $stateParams, FinancialProject) {
  if ($stateParams.id) {
    FinancialProject.get($stateParams.id).then(function(fp) {
      return $scope.fp = fp;
    });
  } else {
    $scope.fp = new FinancialProject({
      active: true
    });
  };
  return $scope.save = function() {
    return $scope.fp.save().then(function(fp) {
      if (_.isEmpty(fp.errors)) {
        return $state.go('^');
      } else {
        return $scope.errors = {
          financial_project: t.errors
        };
      }
    });
  };
});
