/* eslint-disable-next-line */
import { env } from '@env';

angular.module('RocketWash').controller('WashSettingsPrintIndexController', (
  $scope, Device, PrintableDocument, serverConstants, Upload, alertService, $translate,
) => {
  var reload;
  $scope.documents = [];
  $scope.document = false;
  $scope.currentDate = function() {
    return moment().format('HH:mm YYYY-MM-DD');
  };
  $scope.progress = { upload: 0 };

  serverConstants.promise.then(() => {
    $scope.printableDocumentSubjectTypes =
      serverConstants.printable_document_subject_types;
  });


  Device.indexAll((devices) => {
    $scope.devices = devices;
  });

  (reload = function() {
    return PrintableDocument.query({only_old: true}).then(function(docs) {
      var doc, i, len;
      $scope.documents = docs;
      for (i = 0, len = docs.length; i < len; i++) {
        doc = docs[i];
        doc.deviceId || (doc.deviceId = null);
      }
      if ($scope.documents[0] && ($scope.document === false)) {
        return $scope.edit($scope.documents[0]);
      }
    });
  })();
  $scope.edit = function(id) {
    return PrintableDocument.get(id).then(function(doc) {
      $scope.document = doc;
      $scope.documentId = doc.id;
    });
  };
  $scope.$watch('document.deviceId', function() {
    if ($scope.document.deviceId) {
      return $scope.document.quantity = 1;
    }
  });
  $scope.save = function() {
    var base;
    (base = $scope.document).deviceId || (base.deviceId = null);
    return $scope.document.save().then(function() {
      return reload();
    });
  };
  $scope.currentDate = function() {
    return moment().format('HH:mm YYYY-MM-DD');
  };
  $scope.table = {
    totalPrice: function() {
      return 1000;
    },
    totalDiscount: function() {
      return 200;
    }
  };
  $scope.reservation = {
    id: 523,
    paidAt: new Date(),
    car: {
      carMake: {
        name: "Honda"
      },
      carModel: {
        name: "Civic"
      },
      tag: "AE4234134",
      contractor: {
        name: "Vladimir"
      }
    },
    employees: [
      {
        id: 47767,
        name: "Кадилов Владимир",
        phone: "+7(951)488-69-94",
        serviceLocationId: 131,
        serviceLocationLaneId: null,
        role: 'category_1',
        jobId: 18,
        job: {
          id: 18,
          name: "Мойщик"
        },
        selected: true
      }, {
        id: 46741,
        name: "Остапов Евгений Степанович",
        phone: "+7(955)173-40-63",
        serviceLocationId: 131,
        serviceLocationLaneId: null,
        role: 'category_2',
        jobId: 43,
        job: {
          id: 43,
          name: "Помощник автомойщика"
        },
        selected: true
      }, {
        id: 45162,
        name: "Жонго Юрий",
        phone: "+7(120)342-53-68",
        serviceLocationId: 131,
        serviceLocationLaneId: null,
        role: 'category_3',
        jobId: 18,
        job: {
          id: 18,
          name: "Мойщик"
        },
        selected: true
      }, {
        id: 23529,
        name: "Боков Александр",
        phone: "+7(940)841-18-23",
        serviceLocationId: 131,
        serviceLocationLaneId: null,
        role: 'category_1',
        jobId: 18,
        job: {
          id: 18,
          name: "Мойщик"
        },
        selected: true
      }
    ]
  };
  $scope.basicServices = [
    {
      name: "Service 1",
      count: 1,
      discount: 15,
      selected: true,
      getPLI: function() {
        return {
          price: 20
        };
      }
    }, {
      name: "Service 2",
      count: 2,
      discount: 0,
      selected: true,
      getPLI: function() {
        return {
          price: 100
        };
      }
    }, {
      name: "Service 3",
      count: 2,
      discount: 10,
      selected: true,
      getPLI: function() {
        return {
          price: 10
        };
      }
    }
  ];
  $scope.extraServices = [
    {
      name: "Extra 1",
      count: 1,
      discount: 15,
      selected: true,
      getPLI: function() {
        return {
          price: 20
        };
      }
    }, {
      name: "Extra 2",
      count: 2,
      discount: 0,
      selected: true,
      getPLI: function() {
        return {
          price: 100
        };
      }
    }, {
      name: "Extra 3",
      count: 2,
      discount: 10,
      selected: true,
      getPLI: function() {
        return {
          price: 10
        };
      }
    }
  ];

  $scope.upload = (file) => {
    // File did not pass validation
    if (!file) {
      alertService.show({
        text: $translate.instant('upload.error'),
        type: 'error',
      });
      return;
    }

    $scope.progress.upload = 0;
    Upload.upload({
      url: `${env.apiUrl}/wash/printable_documents/${$scope.document.id}/upload_stamp`,
      method: 'POST',
      data: { printable_document: { stamp: file } },
      withCredentials: true,
    }).then((_response) => {
      alertService.show({
        text: $translate.instant('upload.success'),
      });
      $scope.progress.upload = undefined;
      $scope.document.get();
    }, (_error) => {
      $scope.progress.upload = undefined;
    }, (evt) => {
      const progress = parseInt(100.0 * evt.loaded / evt.total, 10);
      console.log(`progress: ${progress}`);
      $scope.progress.upload = progress;
    });
  };
});
