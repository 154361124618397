angular.module('RocketWash').controller('AdminChartsOrganizationsController', (
  $scope, $http, $translate, $timeout,
) => {
  $scope.ranges = ['day', 'week', 'month'];

  const updateChart = function () {
    let ac;
    let ref;
    let ref1;
    let timestamp;
    let update;
    $scope.groupedData = _.groupBy($scope.rawData, (point) => {
      let date;
      date = moment(point.timestamp);
      if ($scope.group.range) {
        date = date.startOf($scope.group.range);
      }
      return +date;
    });
    const series = {
      total: [],
      active: [],
    };
    ref = $scope.groupedData;
    for (timestamp in ref) {
      ac = ref[timestamp];
      series.total.push({
        x: parseInt(timestamp),
        y: Math.max(...ac.map(x => x.total)),
        dataLabels: {
          enabled: false,
        },
      });
      series.active.push({
        x: parseInt(timestamp),
        y: Math.max(...ac.map(x => x.active)),
        dataLabels: {
          enabled: false,
        },
      });
    }
    if ((ref1 = $scope.chart) != null) {
      ref1.destroy();
    }
    update = function () {
      if ($('#charts-container-organizations').length) {
        return $scope.chart = new Highcharts.StockChart({
          yAxis: {
            title: {
              text: 'Количество организаций',
            },
          },
          title: {
            text: 'Активность организаций',
          },
          subtitle: {
            text: null,
          },
          rangeSelector: {
            selected: 0,
          },
          legend: {
            backgroundColor: '#f4f4f4',
            borderColor: '#f2f2f2',
          },
          chart: {
            renderTo: 'charts-container-organizations',
            backgroundColor: '#ffffff',
            type: 'spline',
            plotBackgroundColor: '#ffffff',
            zoomType: 'xy',
          },
          plotOptions: {
            series: {
            turboThreshold: 10000,
              dataLabels: {
                enabled: true,
              },
            },
          },
          xAxis: {
            type: 'datetime',
          },
          series: [
            {
              type: 'area',
              index: 0,
              marker: {
                symbol: 'circle',
                enabled: false,
              },
              color: '#aaffd4',
              name: 'Зарегистрированых',
              data: series.total,
            }, {
              color: '#ffaad4',
              marker: {
                enabled: false,
              },
              type: 'area',
              index: 1,
              name: 'Активных',
              data: series.active,
            },
          ],
        });
      }
    };
    return $timeout(update);
  };

  $scope.reload = () => {
    $http({
      url: `${$scope.env.apiUrl}/admin/charts/organizations`,
      method: 'GET',
      params: { interval: $scope.group.range },
    }).then((response) => {
      $scope.rawData = response.data;
      updateChart();
    });
  };

  $scope.reload();

  $scope.$watch('group.range', $scope.reload);
});
