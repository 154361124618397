angular.module('RocketWash').constant('PREDEFINED_FILTERS', {
  counterparties: [
    {
      name: 'Дубликаты по номеру телефона',
      filters: [
        {
          custom: true,
          condition: 'custom__duplicates_by_phone',
        },
      ],
    },
  ],
});
