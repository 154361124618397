

var extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

angular.module('RocketWash').factory('EmployeeReservation', function(railsResourceFactory, userSession, $http) {
  var ERFactory, EmployeeReservation;
  ERFactory = railsResourceFactory({
    url: (context) => { return "/" + (context.queryRole || userSession.controllerScope()) + "/employee_reservations/" + (context.id || '') },
    name: "employee_reservation"
  });
  return EmployeeReservation = (function(superClass) {
    extend(EmployeeReservation, superClass);

    function EmployeeReservation() {
      return EmployeeReservation.__super__.constructor.apply(this, arguments);
    }

    EmployeeReservation.twoMonthReport = function(queryParams, context) {
      return $http({
        url: this.resourceUrl(context, 'two_month_report'),
        params: queryParams,
        method: 'GET'
      }).then(function(response) {
        return response.data;
      });
    };

    return EmployeeReservation;

  })(ERFactory);
});
