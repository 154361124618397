angular.module('RocketWash').directive('rwWarehouseOperationPaymentsList', () => {
  return {
    restrict: 'E',
    templateUrl: 'directives/rw-warehouse-operation-payments-list/index.slim',
    require: 'ngModel',
    scope: {
      payments: '=',
      totalSum: '&',
      user: '=',
      paidAt: '<',
      paidAtUpdated: '&',
    },
    controller: ($scope, ptfcVariantsService) => {
      $scope.payments = $scope.payments || [];
      $scope.ptfcVariantsService = ptfcVariantsService;
      ptfcVariantsService.promise.then(() => {
        ptfcVariantsService.initializeVariants($scope.payments);
      });

      $scope.addPayment = () => {
        $scope.payments.push({ amount: 0 });
      };

      $scope.removePayment = (payment) => {
        if (payment.id) {
          payment.amount = 0;
        } else {
          $scope.payments.splice($scope.payments.indexOf(payment), 1);
        }
      };

      $scope.paymentsTotalSum = () => {
        return $scope.payments.map(x => parseFloat(x.amount) || 0)
          .reduce((x, y) => x + y, 0);
      };

      $scope.$watch('payments', () => {
        console.log('payments updated');
        ptfcVariantsService.initializeVariants($scope.payments);
      });

      $scope.$watch('paymentsTotalSum() > 0', (paymentsPresent) => {
        if (paymentsPresent) {
          $scope.paidAt = $scope.paidAt || new Date();
        } else {
          $scope.paidAt = null;
        }
        $scope.paidAtUpdated({ paidAt: $scope.paidAt });
      });
    },
  };
});
