angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.warehouse.product_movements', {
    url: '/product_movements',
    tab: 'product_movements',
    data: {
      permission: 'WarehouseOperationsTab'
    },
    views: {
      'content@app.wash.warehouse': {
        controller: 'WashWarehouseProductMovementsBaseController',
        templateUrl: 'pages/wash/warehouse/product_movements/index.slim'
      }
    }
  });
});
