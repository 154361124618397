angular.module('RocketWash').directive(
  'marketingTableOutput',
  () => ({
    restrict: 'E',
    templateUrl: 'directives/marketing-table-output/marketing-table-output.slim',
    scope: {
      data: '=',
    },
    link: (scope, _element, _attrs) => {
      scope.typeOf = x => typeof x;
    },
  }),
);
