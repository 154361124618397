angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  $urlRouterProvider.when('/client_cabinet', '/client_cabinet/income/report');
  return $stateProvider.state('app.client_cabinet', {
    url: '/client_cabinet/:cabinetUUID',
    abstract: true,
    controller: 'ClientCabinetBaseController',
    templateUrl: 'pages/client_cabinet/index.slim',
    data: {
      publicPage: true,
    },
  });
});
