angular.module('RocketWash').controller(
  'WashDashboardPreReservationsCreatePopupController',
  (
    $rootScope, $scope, $translate, $q, $timeout, userSession, PreReservation, preReservation, ServiceLocationLane, $filter,
  ) => {

    const roundDateToMinutes = (date) => {
      const roundToMinutes = userSession.preReservationTimeStartRoundTo();
      let m = moment(date).clone();
      const changeMinutesTo = Math.round(m.minutes() / roundToMinutes) * roundToMinutes;
      return m.minutes(changeMinutesTo);
    };

    if (preReservation.id) {
      PreReservation.get(preReservation.id).then(pr => $scope.preReservation = pr);
    } else {
      preReservation.timeStart = roundDateToMinutes(preReservation.timeStart).toDate();
      $scope.preReservation = preReservation;
    };

    ServiceLocationLane.query().then((lanes) => {
      $scope.lanes = lanes.filter(x => x.active);
    });

    $scope.timeEnd = () => {
      if (!$scope.preReservation) { return };

      const timeEnd =  moment($scope.preReservation.timeStart).add($scope.preReservation.fullDuration, 'minutes');
      return $filter('formatTime')(timeEnd);
    };

    $scope.save = () => {
      $scope.preReservation.save().then(() => {
        $scope.$dismiss();
      });
    };

    $scope.destroy = () => {
      $scope.preReservation.delete().then(() => {
        $scope.$dismiss();
      });
    };
  },
);
