angular.module('RocketWash').controller('CommonTechnicalFixPopupController', function($scope, $timeout) {
  $scope.closeError = function(errors, key) {
    return delete errors[key];
  };
  $scope.errorsPresent = function() {
    return _.some($scope.errors, function(er) {
      return !_.isEmpty(er);
    });
  };

  return $scope.$watch('errors', function() {
    if ($scope.errorsPresent()) {
      return $timeout(function() {
        return $('body').animate({
          scrollTop: $('.errors').offset().top
        }, 1000);
      });
    }
  });
});
