angular.module('RocketWash').controller('WashMarketingActivityFormController', (
  $scope, MarketingActivity, resolvedMarketingActivity,
) => {
  $scope.progress = { save: false };
  $scope.marketingActivity = new MarketingActivity(resolvedMarketingActivity);
  if ($scope.marketingActivity.id) {
    $scope.marketingActivity.get();
  }

  $scope.save = (marketingActivity) => {
    marketingActivity.save().then((response) => {
      if (_.isEmpty(response.errors)) {
        $scope.$dismiss();
      } else {
        $scope.errors = { save_marketing_activity: response.errors };
      }
    });
  };
});
