angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.client_cabinet.analytics.general', {
    url: '/general',
    tab: 'general',
    views: {
      'content@app.client_cabinet.analytics': {
        controller: 'ClientCabinetAnalyticsGeneralController',
        templateUrl: 'pages/client_cabinet/analytics/general/index.slim'
      }
    }
  })
});
