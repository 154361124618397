

angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.settings.car_types_groups', {
    url: '/car_types_groups',
    tab: 'car_types_groups',
    data: {
      permission: 'CarTypesTab'
    },
    views: {
      'content@app.wash.settings': {
        controller: 'WashSettingsCarTypesGroupsBaseController',
        templateUrl: 'pages/wash/settings/car_types_groups/index.slim'
      }
    }
  }).state('app.wash.settings.car_types_groups.form', {
    url: '/form',
    views: {
      'content@app.wash.settings': {
        controller: 'WashSettingsCarTypesGroupsFormController',
        templateUrl: 'pages/wash/settings/car_types_groups/form.slim'
      }
    }
  });
});
