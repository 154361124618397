angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.taxi.reports.reservations', {
    url: '/reservations',
    tab: 'reservations',
    data: {
      publicPage: true,
      permission: 'ReservationsReportTab'
    },
    views: {
      'content@app.taxi.reports': {
        controller: 'TaxiReportsReservationsController',
        templateUrl: 'pages/taxi/reports/reservations/index.slim'
      }
    }
  });
});
