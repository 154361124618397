const MAX_RESERVATIONS_COUNT_FOR_CAR_TYPES_GROUP_APPLICATION = 20;

angular.module('RocketWash').controller('WashSettingsCarTypesSetCarTypesGroupController', (
  $scope, CarTypesGroup, alertService, userSession, ctgSelectService
) => {
  $scope.applyCarTypesGroupEnabled = () => {
    return userSession.organization.reservations_count < MAX_RESERVATIONS_COUNT_FOR_CAR_TYPES_GROUP_APPLICATION || userSession.superuser;
  };

  CarTypesGroup.preconfigured().then((ctgs) => $scope.ctgs = ctgs);

  $scope.apply = (ctg) => {
    if ($scope.applyCarTypesGroupEnabled()) {
      alertService.show({
        text: 'Изменение типов кузова в процессе. Подождите несколько секунд.',
      });

      CarTypesGroup.apply({
        source_car_types_group_id: ctg.id,
        overwrite_car_types_group_id: ctgSelectService.selected.id,
      }).then(() => {
        $scope.$close();
      });
    } else {
      alertService.show({
        text: `В вашей организации более ${MAX_RESERVATIONS_COUNT_FOR_CAR_TYPES_GROUP_APPLICATION} заказов. При смене типов кузов будут сброшены все цены и нормочасы по всем услугам. При неправильном использовании это может повлечь остановку в работе вашей мойки. \nДля массовой смены типов кузова обратитесь в техподдержку.`,
        type: 'warning',
      });
    };
  };
});
