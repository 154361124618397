angular.module('RocketWash').factory('Permission', (
  railsResourceFactory, userSession,
) => {
  const Resource = railsResourceFactory({
    url: context => `/${context.queryRole || userSession.controllerScope()}/permissions/${context.id || ''}`,
    name: 'permission',
  });

  return Resource;
});
