angular.module('RocketWash').factory('PaymentConfirmation', (
  railsResourceFactory, $http, userSession,
) => {
  const Resource = railsResourceFactory({
    url: context => `/${context.queryRole || userSession.controllerScope()}/payment_confirmations/${context.id || ''}`,
    name: 'payment_confirmation',
  });

  Resource.send = function (params) {
    const _class = Object.getPrototypeOf(this).constructor;
    return $http({
      method: 'POST',
      url: `${_class.resourceUrl(this)}/`,
      params: params,
    }).then((response) => {
      return response.data;
    });
  };

  return Resource;
});
