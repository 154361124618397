angular.module('RocketWash').config(($stateProvider, $urlRouterProvider) => {
  $urlRouterProvider.when('/wash/dashboard', '/wash/dashboard/control_panel');
  return $stateProvider.state('app.wash.dashboard', {
    url: '/dashboard',
    abstract: true,
    controller: 'WashDashboardBaseController',
    templateUrl: 'pages/wash/dashboard/index.slim',
    data: {
      permission: 'DashboardMenuItem',
      tabs: ['control_panel', 'operative_panel', 'completed_reservations', 'cash_shifts', 'statistics', 'work_shifts'],
    },
  });
});
