angular.module('RocketWash').config(($stateProvider) => {
  return $stateProvider.state('app.taxi.settings.organization', {
    url: '/organization',
    tab: 'organization',
    data: {
      permission: 'SettingsOrganizationTab',
    },
    views: {
      'content@app.taxi.settings': {
        controller: 'TaxiSettingsOrganizationController',
        templateUrl: 'pages/taxi/settings/organization/index.slim',
      },
    },
  });
});
