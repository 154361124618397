

var extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

angular.module('RocketWash').factory('PlansPermission', function(railsResourceFactory, userSession, $http) {
  var PlansPermission, PlansPermissionFactory;
  PlansPermissionFactory = railsResourceFactory({
    url: (context) => { return "/" + (context.queryRole || userSession.controllerScope()) + "/plans_permissions/" + (context.id || '') },
    name: "plans_permission"
  });
  return PlansPermission = (function(superClass) {
    extend(PlansPermission, superClass);

    function PlansPermission() {
      return PlansPermission.__super__.constructor.apply(this, arguments);
    }

    return PlansPermission;

  })(PlansPermissionFactory);
});
