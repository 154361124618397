angular.module('RocketWash').config(($stateProvider, $urlRouterProvider) => {
  $urlRouterProvider.when('/wash/charts', '/wash/charts/revenue');
  $stateProvider.state('app.wash.charts', {
    url: '/charts',
    abstract: true,
    controller: 'WashChartsBaseController',
    templateUrl: 'pages/wash/charts/index.slim',
    data: {
      permission: 'ReportsMenuItem',
      tabs: [
        'revenue', 'services_structure', 'average_bill', 'reconciliation_report',
        'sms', 'services_revenue', 'contractors', 'reservations', 'user_categories',
        'client_activity_categories', 'marketing_activities'
      ],
    },
  });
});
