

angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.settings.car_models', {
    url: '/car_models',
    tab: 'car_models',
    data: {
      permission: 'SelectCarsCarTypeTab'
    },
    views: {
      'content@app.wash.settings': {
        controller: 'WashSettingsCarModelsController',
        templateUrl: 'pages/wash/settings/car_models/index.slim'
      }
    }
  });
});
