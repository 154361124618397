angular.module('RocketWash').directive('rwCarsCarTypes', () => ({
  restrict: 'E',
  templateUrl: 'directives/rw-cars-car-types/index.slim',
  scope: {
    car: '=',
    editable: '=',
  },
  controller: (
    $scope, $uibModal, userSession, Car
  ) => {

    const reassignAndReload = _.debounce(() => {
      let newCar = new Car();
      $scope.car = angular.extend(newCar, $scope.car);

      Car.prototype.reassignDefaultCtgs.apply($scope.car).then(() => {
        Car.prototype.reloadAvailableCtgs.apply($scope.car);
      });
    }, 300);

    $scope.$watchGroup(['car.car_model_id', 'car.tag'], (was, become) => {
      reassignAndReload();
    }, true);

    $scope.editCarTypesGroup = () => {
      $uibModal.open({
        templateUrl: 'directives/rw-cars-car-types/form.slim',
        controller: 'RWCarsCarTypesCarTypesGroupFormController',
        resolve: {
          car: $scope.car,
        }
      });
    }
  },
}));
