

var extend = function (child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

angular.module('RocketWash').factory('PartnerPlan', function (railsResourceFactory, userSession, $http) {
  var PartnerPlan, PartnerPlanFactory;
  PartnerPlanFactory = railsResourceFactory({
    url: (context) => { return "/" + (context.queryRole || userSession.controllerScope()) + "/partner_plans/" + (context.id || '') },
    name: "partner_plan"
  });
  return PartnerPlan = (function (superClass) {
    extend(PartnerPlan, superClass);

    function PartnerPlan() {
      return PartnerPlan.__super__.constructor.apply(this, arguments);
    }

    // PartnerPlan.current = function (context) {
    //   return $http({
    //     url: (this.resourceUrl(context)) + "/current",
    //     method: 'GET'
    //   }).then(function (response) {
    //     return response.data;
    //   });
    // };

    return PartnerPlan;

  })(PartnerPlanFactory);
});
