angular.module('RocketWash').factory('ReservationReview', (
  railsResourceFactory, userSession,
) => {
  const Resource = railsResourceFactory({
    url: context => `/${context.queryRole || userSession.controllerScope()}/reservation_reviews/${context.id || ''}`,
    name: 'reservation_review',
  });

  return Resource;
});
