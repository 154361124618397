angular.module('RocketWash').controller('WashControlBaseController', (
  $scope, $rootScope, CarType, ServiceLocation, userSession, serverConstants
) => {
  Highcharts.setOptions({
    global: {
      useUTC: false,
      timezoneOffset: -moment(userSession.time_zone).utcOffset(),
    },
    colors: [
      '#b3fcb3', '#ffbfbf', '#96caff', '#50B432', '#ED561B', '#DDDF00',
      '#24CBE5', '#64E572', '#FF9655', '#FFF263', '#6AF9C4',
    ],
  });
},);
