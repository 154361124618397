

var extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

angular.module('RocketWash').factory('UserCategory', function(railsResourceFactory, railsSerializer, userSession, $http) {
  var UserCategory, UserCategoryFactory;
  UserCategoryFactory = railsResourceFactory({
    url: (context) => { return "/" + (context.queryRole || userSession.controllerScope()) + "/user_categories/" + (context.id || '') },
    name: 'user_category',
    serializer: railsSerializer(function() {
      this.nestedAttribute('userCategoryRule');
      return this.resource('userCategoryRule', 'UserCategoryRule');
    })
  });
  return UserCategory = (function(superClass) {
    extend(UserCategory, superClass);

    function UserCategory() {
      return UserCategory.__super__.constructor.apply(this, arguments);
    }

    UserCategory.recountAllForOrganization = function() {
      return $http({
        url: "/" + (userSession.controllerScope()) + "/user_categories/recount_all_for_organization",
        method: 'POST'
      });
    };

    return UserCategory;

  })(UserCategoryFactory);
});
