angular.module('RocketWash').directive(
  'ngQuickMoment',
  () => ({
    restrict: 'E',
    template: '<quick-datepicker ng-model="value">',
    require: 'ngModel',
    link: (scope, _element, _attrs, ngModelController) => {
      ngModelController.$parsers.push(x => moment(x));
      ngModelController.$formatters.push(x => moment(x).toDate());
      ngModelController.$render = () => {
        console.log('render');
        scope.value = ngModelController.$viewValue;
      };
      scope.$watch('value', (value) => {
        ngModelController.$setViewValue(value);
      });
      scope.value = moment(scope.$viewValue).toDate();
      console.log('scope.value');
      console.log(scope.value);
    },
  }),
);

