angular.module('RocketWash').component('rwProductSelector', {
  templateUrl: 'components/rw-product-selector/index.slim',
  bindings: {
    typeaheadOptions: '<',
    allowCreateProduct: '<',
    productAdded: '&',
  },
  controllerAs: 'vm',
  controller: function (
    $scope, $translate, Service, Product, ServiceFolder, warehouseForm,
  ) {
    const vm = this;

    vm.state = 'search';
    vm.autocomplete = { searchText: '', product: null };
    vm.progress = { saveProduct: false };
    vm.warehouseOperationType = warehouseForm.getWarehouseOperationType();
    vm.allowedTypes = ['WarehouseOperationArrival', 'WarehouseOperationOtherArrival'];
    warehouseForm.resetWarehouseSelect();
    $scope.errors = {};

    // Actions

    vm.getProducts = (searchText) => {
      return Service.typeahead(
        searchText, Object.assign({ serviceType: 'Product', warehouseId: warehouseForm.getWarehouseId() }, vm.typeaheadOptions),
      ).then((response) => {
        const items = response.data.map(item => new Product(item));

        items.forEach((product) => {
          product.line1 = [product.name].join(' ');
          product.line2 = [$translate.instant('warehouse.warehouse_operations.form.available_amount'), product.count || 0, product.unit,
                           '   ', $translate.instant('warehouse.warehouse_operations.form.article'), product.article,
                           $translate.instant('warehouse.warehouse_operations.form.barcode'), product.barcode].join(' ');
        });

        return items;
      });
    };

    vm.productSelected = (product) => {
      if (!product || !vm.allowedTypes.includes(vm.warehouseOperationType)) {
        if (!product || product.count === null || product.count === 0) {
          return;
        }
      }
      Product.get(product.id).then((data) => {
        const product = new Product(data);

        vm.productAdded({ product });
        vm.autocomplete.searchText = '';
        vm.autocomplete.product = null;
      })
    };

    vm.openNewProduct = () => {
      vm.state = 'create';
      vm.newProduct = new Product({ name: vm.autocomplete.searchText });
    };

    vm.saveNewProduct = () => {
      vm.progress.saveProduct = true;
      vm.newProduct.save().then(() => {
        if (vm.newProduct.id) {
          vm.productAdded({ product: vm.newProduct });
          vm.state = 'search';
          vm.newProduct = null;
          vm.autocomplete.searchText = '';
        } else {
          $scope.errors = vm.newProduct.errors;
        }
      }).finally(() => { vm.progress.saveProduct = false; });
    };

    vm.cancelSaveNewProduct = () => {
      vm.state = 'search';
      vm.newProduct = null;
    };

    // Helpers

    // Query

    ServiceFolder.indexAsTree().then((tree) => { vm.serviceFoldersTree = tree; });

    // Init

    vm.$onInit = () => {
      vm.typeaheadOptions = vm.typeaheadOptions || {};
    };
  },
});
