angular.module('RocketWash').controller(
  'WashServicesConsumablesFormController',
  ($q, $scope, $translate, consumableId, consumerId, Product, Service, ctSelectService, Consumable) => {
    const PREFERABLE_UNITS = ['each', 'g', 'ml', 'm'];

    const reloadConsumables = () => {
      const safeProductId = _.get($scope, 'selected.product.id');
      Consumable.query({
        consumer_service_id: consumerId,
        consumable_service_id: safeProductId || consumableId,
      }).then((items) => {
        $scope.consumables = {};

        items.forEach((item) => {
          $scope.consumables[item.carTypeId] = item;
        });

        // Populate missing consumablesk
        ctSelectService.items.forEach((ct) => {
          $scope.consumables[ct.id] = $scope.consumables[ct.id] || new Consumable({
            consumerServiceId: consumerId,
            consumableServiceId: $scope.selected.product.id,
            carTypeId: ct.id,
          });
        });

        // Set default units
        Object.keys($scope.consumables).forEach((key) => {
          const consumable = $scope.consumables[key];

          if (!consumable.unit) {
            const preferredUnit = _.intersection($scope.selected.product.compatibleUnits, PREFERABLE_UNITS)[0];
            consumable.unit = preferredUnit || $scope.selected.product.compatibleUnits[0];
          }
        });
      });
    };

    $scope.ctSelectService = ctSelectService;
    $scope.selected = {};

    if(consumerId && consumableId) {
      Product.get(consumableId).then((item) => {
        $scope.selected.productSearchText = item.name;
        $scope.selected.product = item;

        reloadConsumables();
      });
    }

    $scope.productSelected = (product) => {
      if (product) {
        Product.get(product.id).then((data) => {
          angular.extend($scope.selected.product, data);
          reloadConsumables();
        })
      }
    };

    // Actions
    $scope.save = () => {
      $scope.saveDisabled = true;

      const promisses = Object.keys($scope.consumables).map((key) => {
        const consumable = $scope.consumables[key];
        return consumable.save().then((response) => {
          if (_.isEmpty(response.errors)) {
            $scope.errors = undefined;
          } else {
            $scope.errors = { create_consumable: response.errors };
          }
        });
      });

      $q.all(promisses).then((response) => {
        $scope.$close();
      }).finally(() => {
        $scope.saveDisabled = false;
      });
    };

    $scope.getProducts = (value) => {
      return Service.typeahead(value, {
        serviceType: 'Product',
      }).then((response) => {
        const items = response.data.map(item => new Product(item));

        items.forEach((product) => {
          product.line1 = [product.name].join(' ');
          product.line2 = [$translate.instant('warehouse.warehouse_operations.form.available_amount'), product.count || 0, product.unit,
            '   ', $translate.instant('warehouse.warehouse_operations.form.article'), product.article,
          $translate.instant('warehouse.warehouse_operations.form.barcode'), product.barcode].join(' ');
        });

        return items;
      });
    };
  },
);
