angular.module('RocketWash').factory('WarehouseOperation', (
  $http, railsResourceFactory, userSession, railsSerializer,
) => {
  const Resource = railsResourceFactory({
    url: (context) => { return `/${context.queryRole || userSession.controllerScope()}/warehouse_operations/${context.id || ''}`; },
    name: 'warehouse_operation',
    serializer: railsSerializer(function () {
      this.nestedAttribute('warehouseOperationItems');
      this.nestedAttribute('warehouseOperationPayments');
      return this;
    }),
  });

  Resource.typeahead = function typeahead(searchQuery, params) {
    return $http({
      method: 'GET',
      url: `${this.$url()}/typeahead`,
      params: angular.merge({ search_query: searchQuery }, params),
    }).then((response) => {
      return response.data.map(x => new this(x));
    });
  };

  Resource.prototype.typeaheadLabel = function () {
    console.log(this);
    return [
      `#${this.ordinal} (${this.totalSum}) ${this.user && this.user.name}`,
      `${this.document} ${this.comment}`,
      JSON.stringify(this),
    ];
  };

  return Resource;
});
