angular.module('RocketWash').controller('AdminSettingsGlobalMobileServicesBaseController', (
  $scope, $uibModal, GlobalMobileService, rwConfirmationService,
) => {
  $scope.reloadGlobalMobileServices = () => {
    GlobalMobileService.query({}, { cache: false }).then((globalMobileServices) => {
      $scope.globalMobileServices = _.sortBy(globalMobileServices, 'name');
    });
  };

  $scope.delete = (globalMobileService) => {
    rwConfirmationService.confirm(
      { textTranslation: 'alert.are_you_sure' },
      () => { globalMobileService.delete().then($scope.reloadGlobalMobileServices); },
    );
  };

  $scope.$on('search-change', (_event, data) => {
    $scope.searchQuery = data.value;
  });

  $scope.openFormPopup = (globalMobileService) => {
    $uibModal.open({
      templateUrl: 'pages/admin/settings/global_mobile_services/form.slim',
      controller: 'AdminSettingsGlobalMobileServicesFormController',
      resolve: {
        resolvedGlobalMobileService: () => globalMobileService,
      },
    }).result.finally(() => {
      setTimeout($scope.reloadGlobalMobileServices, 1000);
    });
  };

  $scope.save = (globalMobileService) => {
    globalMobileService.save().then($scope.reloadGlobalMobileServices);
  };

  $scope.reloadGlobalMobileServices();
});
