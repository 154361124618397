angular.module('RocketWash').controller('WashWarehouseWarehousesBaseController', (
  $scope, $rootScope, $translate, $uibModal, Warehouse, rwConfirmationService,
) => {
  $rootScope.addTrade = null;
  $scope.reload = () => {
    Warehouse.query().then(warehouses => {
      $scope.warehouses = warehouses
    });
  };
  $scope.reload();

  $scope.save = (warehouse) => { warehouse.save().then($scope.reload); };
  $scope.delete = (warehouse) => {
    rwConfirmationService.confirm(
      { text: $translate.instant('alert.are_you_sure') },
      () => { warehouse.delete().then($scope.reload()); },
    );
  };
  $scope.searchQuery = '';

  $scope.openFormPopup = (warehouse) => {
    $uibModal.open({
      templateUrl: 'pages/wash/warehouse/warehouses/form.slim',
      controller: 'WashWarehouseWarehousesFormController',
      resolve: {
        resolvedWarehouse: warehouse,
      },
    }).result.finally(() => {
      setTimeout($scope.reload(), 1000);
    });
  };
});
