angular.module('RocketWash').config(($stateProvider, $urlRouterProvider) => {
  $urlRouterProvider.when('/wash/counterparties', '/wash/counterparties/clients');
  $stateProvider.state('app.wash.counterparties', {
    url: '/counterparties',
    abstract: true,
    controller: 'WashCounterpartiesBaseController',
    templateUrl: 'pages/wash/counterparties/index.slim',
    data: {
      permission: 'ContractorsMenuItem',
      tabs: ['individuals', 'entrepreneurs', 'companies', 'suppliers'],
    },
  }).state('app.wash.counterparties.individuals', {
    url: '/individuals',
    tab: 'individuals',
    data: {
      permission: 'ContractorsTab',
    },
    views: {
      'content@app.wash.counterparties': {
        controller: 'WashCounterpartiesClientsController',
        templateUrl: 'pages/wash/counterparties/clients/index.slim',
      },
    },
  }).state('app.wash.counterparties.entrepreneurs', {
    url: '/entrepreneurs',
    tab: 'entrepreneurs',
    data: {
      permission: 'ContractorsTab',
    },
    views: {
      'content@app.wash.counterparties': {
        controller: 'WashCounterpartiesClientsController',
        templateUrl: 'pages/wash/counterparties/clients/index.slim',
      },
    },
  }).state('app.wash.counterparties.companies', {
    url: '/companies',
    tab: 'companies',
    data: {
      permission: 'ContractorsTab',
    },
    views: {
      'content@app.wash.counterparties': {
        controller: 'WashCounterpartiesClientsController',
        templateUrl: 'pages/wash/counterparties/clients/index.slim',
      },
    },
  }).state('app.wash.counterparties.suppliers', {
    url: '/suppliers',
    tab: 'suppliers',
    data: {
      permission: 'ContractorsTab',
    },
    views: {
      'content@app.wash.counterparties': {
        controller: 'WashCounterpartiesClientsController',
        templateUrl: 'pages/wash/counterparties/clients/index.slim',
      },
    },
  });
});
