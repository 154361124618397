import { env } from '@env';

angular.module('RocketWash').controller('TaxiAutoparkEmployeesFormController', (
  $scope, userSession, User, Role, Job, resolvedEmployee, serverConstants,
  ServiceLocation, Employee, resolvedReadonly,
  alertService, $translate, Upload,
) => {
  $scope.readonly = resolvedReadonly;
  $scope.serverConstants = serverConstants;
  $scope.serviceLocations = [];
  $scope.progress = { upload: undefined, sendPassword: false };

  ServiceLocation.query().then((serviceLocations) => {
    $scope.serviceLocations = serviceLocations;
  });

  $scope.employee = new Employee(resolvedEmployee);
  if (!$scope.employee.serviceLocationIds) {
    $scope.employee.serviceLocationIds = [];
  }
  $scope.employee.serviceLocationId = userSession.service_location_id;
  if ($scope.employee.id) {
    $scope.employee.get().then(() => {
      console.log('reloaded');
    });
  }

  Role.query().then((roles) => {
    $scope.roles = roles;
  });

  Job.query().then((jobs) => {
    $scope.jobs = jobs;
  });

  $scope.validate = () => {
    const errors = {};
    if (!$scope.employee.name) {
      errors.name = true;
    }
    if (!$scope.employee.phone) {
      errors.phone = true;
    }
    if (!$scope.employee.employeeStatus) {
      errors.employeeStatus = true;
    }
    if (Object.keys(errors).length === 0) {
      return true;
    }
    $scope.errors = { create_employee: errors };
    return false;
  };

  $scope.save = () => {
    if (!$scope.validate()) {
      return;
    }
    $scope.formDisabled = true;
    $scope.employee.save().then((employee) => {
      if (_.isEmpty(employee.errors)) {
        $scope.$dismiss();
      } else {
        $scope.errors = { create_employee: employee.errors };
      }
    }).finally(() => { $scope.formDisabled = false; });
  };

  $scope.sendPasswordViaSms = (employee) => {
    employee = new User(employee);
    $scope.progress.sendPassword = true;
    employee.sendPasswordViaSms().then((response) => {
      if (_.isEmpty(response.errors)) {
        alertService.show({
          text: $translate.instant('employees.popup.password_sent'),
        });
        $scope.$dismiss();
      } else {
        $scope.errors = { send_password_via_sms: response.errors };
      }
    }).finally(() => { $scope.progress.sendPassword = false; });
  };

  $scope.upload = (file) => {
    // File did not pass validation
    if (!file) {
      alertService.show({
        text: $translate.instant('employees.popup.photo_upload_error'),
        type: 'error',
      });
      return;
    }

    $scope.progress.upload = 0;
    Upload.upload({
      url: `${env.apiUrl}taxi/employees/${$scope.employee.id}/change_avatar`,
      method: 'POST',
      data: { employee: { avatar: file } },
      withCredentials: true,
    }).then((_response) => {
      alertService.show({
        text: $translate.instant('employees.popup.photo_upload_success'),
      });
      $scope.progress.upload = undefined;
      $scope.employee.get();
    }, (_error) => {
      $scope.progress.upload = undefined;
    }, (evt) => {
      const progress = parseInt(100.0 * evt.loaded / evt.total, 10);
      console.log(`progress: ${progress}`);
      $scope.progress.upload = progress;
    });
  };
});
