import { env } from '@env';

angular.module('RocketWash').service('reservationForm', ($http, $injector) => {

  const service = {
    r: () => service.reservations.reservation,
  }

  service.formTab = $injector.get('reservationFormTab');
  service.config = $injector.get('reservationFormConfig');
  service.reservations = $injector.get('reservationFormReservations');
  service.carContractor = $injector.get('reservationFormCarContractor');
  service.users = $injector.get('reservationFormUsers');
  service.services = $injector.get('reservationFormServices');
  service.payments = $injector.get('reservationFormPayments');
  service.employees = $injector.get('reservationFormEmployees');
  service.employeeParticipations = $injector.get('reservationFormEmployeeParticipations');
  service.photos = $injector.get('reservationFormPhotos');
  service.marketing = $injector.get('reservationFormMarketing');
  service.preReservations = $injector.get('reservationFormPreReservations');

  service.resetAll = () => {
    service.formTab.reset();
    service.reservations.reset();
    service.carContractor.reset();
    service.services.reset();
    service.payments.reset();
    service.employees.reset();
    service.employeeParticipations.reset();
    service.photos.reset();
    service.config.reset();
    service.marketing.reset();
    service.preReservations.reset();
  };

  return service;
});
