angular.module('RocketWash').config(($stateProvider, $urlRouterProvider) => {
  $urlRouterProvider.when('/client_cabinet/charts', '/client_cabinet/charts/revenue');
  $stateProvider.state('app.client_cabinet.charts', {
    url: '/charts',
    abstract: true,
    controller: 'ClientCabinetChartsBaseController',
    templateUrl: 'pages/client_cabinet/charts/index.slim',
    data: {
      publicPage: true,
      permission: 'ReportsMenuItem',
      tabs: ['contractors', 'reservations'],
    },
  });
});
