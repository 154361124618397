angular.module('RocketWash').config(($stateProvider, $urlRouterProvider) => {
  return $stateProvider.state('app.wash.control', {
    url: '/control',
    abstract: true,
    controller: 'WashControlBaseController',
    templateUrl: 'pages/wash/control/index.slim',
    data: {
      permission: 'ControlMenuItem',
      tabs: [
        'boxes_control', 'suspicious_activities'
      ],
    },
  })
});
