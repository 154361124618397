angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  $urlRouterProvider.when('/wash/counterparties/clients', '/wash/counterparties/individuals');
  return $stateProvider.state('app.wash.counterparties.clients', {
    url: '/clients',
    tab: 'clients',
    data: {
      permission: 'ContractorsTab',
    },
    abstract: true,
    views: {
      'content@app.wash.counterparties': {
        controller: 'WashCounterpartiesClientsController',
        templateUrl: 'pages/wash/counterparties/clients/index.slim'
      }
    }
  }).state('app.wash.counterparties.clients.sms', {
    url: '/sms',
    views: {
      'content@app.wash.counterparties': {
        controller: 'WashCounterpartiesSMSController',
        templateUrl: 'pages/wash/counterparties/sms/index.slim'
      }
    }
  }).state('app.wash.counterparties.clients.notification', {
    url: '/notification',
    views: {
      'content@app.wash.counterparties': {
        controller: 'WashCounterpartiesNotificationController',
        templateUrl: 'pages/wash/counterparties/notification/index.slim',
      },
    },
  }).state('app.wash.counterparties.clients.create', {
    url: '/create',
    views: {
      'content@app.wash.counterparties': {
        controller: 'WashCounterpartiesCreateController',
        templateUrl: 'pages/wash/counterparties/clients/create.slim'
      }
    }
  }).state('app.wash.counterparties.clients.edit', {
    url: '/edit',
    abstract: true,
    views: {
      'content@app.wash.counterparties': {
        template: ''
      }
    }
  }).state('app.wash.counterparties.clients.edit.info', {
    url: '/info',
    tab: 'edit.info',
    views: {
      'content@app.wash.counterparties': {
        controller: 'WashCounterpartiesEditInfoController',
        templateUrl: 'pages/wash/counterparties/edit/info.slim'
      }
    }
  }).state('app.wash.counterparties.clients.edit.vehicles', {
    url: '/vehicles',
    tab: 'edit.vehicles',
    views: {
      'content@app.wash.counterparties': {
        controller: 'WashCounterpartiesEditVehiclesController',
        templateUrl: 'pages/wash/counterparties/edit/vehicles.slim'
      }
    }
  }).state('app.wash.counterparties.clients.edit.history', {
    url: '/history',
    tab: 'edit.history',
    views: {
      'content@app.wash.counterparties': {
        controller: 'WashCounterpartiesEditHistoryController',
        templateUrl: 'pages/wash/counterparties/edit/history.slim'
      }
    }
  }).state('app.wash.counterparties.clients.edit.financial_operations', {
    url: '/financial_operations',
    tab: 'edit.financial_operations',
    views: {
      'content@app.wash.counterparties': {
        controller: 'WashCounterpartiesEditFinancialOperationsController',
        templateUrl: 'pages/wash/counterparties/edit/financial_operations.slim'
      }
    }
  });
});
