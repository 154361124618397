angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.admin.settings.car_types', {
    url: '/car_types',
    tab: 'car_types',
    data: {
      permission: 'CarTypesTab'
    },
    views: {
      'content@app.admin.settings': {
        controller: 'AdminSettingsCarTypesBaseController',
        templateUrl: 'pages/admin/settings/car_types/index.slim'
      }
    }
  }).state('app.admin.settings.car_types.form', {
    url: '/form',
    views: {
      'content@app.admin.settings': {
        controller: 'AdminSettingsCarTypesFormController',
        templateUrl: 'pages/admin/settings/car_types/form.slim'
      }
    }
  });
});
