angular.module('RocketWash').directive('rwFiltersSelected', function($timeout, $translate, $filter, serverConstants, FilterGroup, rwFiltersService) {
  return {
    restrict: 'E',
    scope: {
      editable: '=',
      page: '=',
    },
    templateUrl: 'directives/rw-filters/rw-filters-selected.slim',
    link: function (scope, _element, _attrs) {
      if (!scope.page) {
        console.error('page attribute required');
      }
      scope.rwfs = rwFiltersService.new(scope.page);

      scope.isEditable = () => {
        // FIXME: return scope.editable === undefined ? true : scope.editable;
        return scope.editable === false ? false : true;
      };
    },
  };
});
