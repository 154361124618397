angular.module('RocketWash').controller(
  'WashManualClientMonitorDashboardController',
  ($scope, alertService, $uibModal, CarMake, CarModel, ManualReservationAnnouncement,
   userSession) => {
    $scope.selectedCarTag = null;
    $scope.selectedCarMake = null;
    $scope.selectedCarModel = null;

    $scope.save = () => {
      const makeName = $scope.selectedCarMake && $scope.selectedCarMake.name || '';
      const modelName = $scope.selectedCarModel && $scope.selectedCarModel.name || '';

      if (!$scope.selectedCarTag) {
        alertService.show({
          text: 'Необходимо ввести номер автомобиля',
          type: 'error',
          timeout: 4000,
        });
        alert('Необходимо ввести номер автомобиля');

        return;
      };

      let announcement_text = [$scope.selectedCarTag, makeName, modelName].join('|');

      let mra = new ManualReservationAnnouncement({
        announcement_text: announcement_text,
        service_location_id: userSession.service_location_id,
        organization_id: userSession.organization.id,
      });

      mra.save().then(() => {
        $uibModal.open({
          controller: 'WashManualClientMonitorDashboardPopupController',
          templateUrl: 'pages/wash/manual_client_monitor/dashboard/confirmation_popup.slim',
          resolve: {
            carTag: () => { return $scope.selectedCarTag },
            carMake: () => { return makeName },
            carModel: () => { return modelName },
          }
        })
      }).then(() => {
        $scope.cancel();
      });;
    };

    $scope.cancel = () => {
      $scope.selectedCarTag = null;
      $scope.selectedCarMake = null;
      $scope.selectedCarModel = null;
      $scope.monitorAnnouncementForm.$setPristine();
      $scope.monitorAnnouncementForm.$setUntouched();
    };

    $scope.loadModels = (make) => {
      if (make.carModels) {
        return;
      }
      CarMake.get(make.id).then((data) => {
        data.carModels = data.carModels.map(cm => new CarModel(cm));
        data.carModels = _.sortBy(data.carModels, 'name');
        angular.extend(make, data);
      });
    };

    CarMake.query().then((data) => {
      $scope.carMakes = data;
    });
  }
)
