angular.module('RocketWash').controller(
  'AdminOrganizationsSettingsLegalEntitiesController',
  ($scope, $stateParams, LegalEntity) => {
    $scope.legalEntities = [];

    LegalEntity.query({ organization_id: $stateParams.orgId }).then((les) => {
      $scope.legalEntities = les;
    });

    $scope.new = () => {
      new LegalEntity({ organizationId: $stateParams.orgId }).save().then((le) => {
        $scope.legalEntities.unshift(le);
      });
    };
    $scope.save = (le) => {
      le.save();
    };
    $scope.delete = (le) => {
      le.delete().then(() => {
        $scope.legalEntities = $scope.legalEntities.filter(x => x !== le);
      });
    };
  },
);
