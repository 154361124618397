angular.module('RocketWash').config(($stateProvider) => {
  return $stateProvider.state('app.wash.control.suspicious_activities', {
    url: '/suspicious_activities',
    tab: 'suspicious_activities',
    data: {
      permission: 'SuspiciousActivitiesTab'
    },
    views: {
      'content@app.wash.control': {
        controller: 'WashControlSuspiciousActivitiesController',
        templateUrl: 'pages/wash/control/suspicious_activities/index.slim'
      }
    }
  });
});
