angular.module('RocketWash').controller('WashFinanceWashFinancialOperationsFormController', (
  $scope, FINANCIAL_OPERATION_TYPE_GROUPS, FINANCIAL_OPERATION_FORM_CONFIG,
  FinancialCenter, FinancialOperation, FinanceClause, PaymentType, FinancialPeriod,
  FinancialProject, WarehouseOperation, PrintableDocument, $rootScope, printService,
  resolvedFinancialOperation, userSession, ServiceLocation, $state,
) => {
  $scope.FINANCIAL_OPERATION_TYPE_GROUPS = FINANCIAL_OPERATION_TYPE_GROUPS;
  $scope.FINANCIAL_OPERATION_FORM_CONFIG = FINANCIAL_OPERATION_FORM_CONFIG;
  $scope.selectedTabIndex = -1;
  $scope.userSession = userSession;
  const popupForOrganization = $state.current.name === 'app.wash.organization.financial_operations';

  // TODO: rewrite on print service refactor
  $rootScope.printDocuments = () => {
    printService.printToAll({
      targetId: $scope.fo.id,
      targetType: 'FinancialOperation',
      docs: $scope.documents.filter(d => d.selected),
    });
  };

  $scope.print = () => {
    $scope.save({ closeOnSuccess: false, callback: $rootScope.printDocuments });
  };

  $scope.save = ({ closeOnSuccess = true, callback } = {}) => {
    if ($scope.formDisabled) { return };

    $scope.replaceEmptyWithZero($scope.fo.amount);
    $scope.formDisabled = true;
    $scope.fo.prepareForSave();
    $scope.fo.save().then((fo) => {
      if (_.isEmpty(fo.errors)) {
        if (closeOnSuccess) {
          $scope.$dismiss();
        }
        if (typeof callback === 'function') {
          callback();
        }
      }
      $scope.errors = { financial_operation: fo.errors };
    }).finally(() => {
      $scope.formDisabled = false;
    });
  };
  let selectTabCalled = false;
  $scope.selectTab = (tab) => {
    $scope.operationTypes = FINANCIAL_OPERATION_TYPE_GROUPS[tab];
    if (selectTabCalled && !$scope.operationTypes.includes($scope.fo.type)) { // Tab changed by user. Initial event fires without selected tab.
      $scope.fo.type = $scope.operationTypes[0];
      $scope.resetFo();
    }
    $scope.selectedTab = tab;
    selectTabCalled = true;
  };
  if (popupForOrganization) {
    $scope.getFinancialCenters = (options = {}) => {
      const fieldConfig = angular.copy(
        FINANCIAL_OPERATION_FORM_CONFIG[$scope.fo.type].financialCenters[options.field] || {},
      );
      let filter = fieldConfig.filter || {};
      filter.restrict_by_service_location = false; // Do not apply any restrictions for organization view
      const typeaheadOptions = angular.extend(
        {}, filter, options, { service_location_id: $scope.fo.serviceLocationId },
      );
      return FinancialCenter.typeahead(typeaheadOptions);
    };
  } else {
    $scope.getFinancialCenters = (options = {}) => {
      const fieldConfig = angular.copy(
        FINANCIAL_OPERATION_FORM_CONFIG[$scope.fo.type].financialCenters[options.field] || {},
      );
      const filter = fieldConfig.filter || {};
      return FinancialCenter.typeahead(angular.extend(filter, options), fieldConfig.hideBalance);
    };
  }
  $scope.fcSelected = (field) => {
    const fc = $scope.fo[field];
    $scope.fo[`${field}Id`] = fc && fc.id;
    if (
      $scope.fo.type === 'FinancialOperationOutcomeSupplierPayment' &&
      field === 'fromSecondFinancialCenter'
    ) {
      $scope.fo.warehouseOperation = null;
      $scope.fo.warehouseOperationId = null;
    }
  };
  $scope.woSelected = () => {
    const wo = $scope.fo.warehouseOperation;
    $scope.fo.warehouseOperationId = wo && wo.id;
  };

  $scope.getWarehouseOperations = (searchQuery) => {
    const options = { operation_type: 'WarehouseOperationArrival' };
    const fc = $scope.fo.fromSecondFinancialCenter;
    const userId = fc && fc.userId;
    if (userId) {
      options.user_id = userId;
    }
    return WarehouseOperation.typeahead(searchQuery, options);
  };

  $scope.resetFo = () => {
    if (!userSession.can('access', $scope.fo.type)) {
      $scope.permissionDenied = true
    } else {
      $scope.permissionDenied = false
    }

    ['from', 'to', 'fromSecond', 'toSecond'].forEach((fieldPrefix) => {
      $scope.fo[`${fieldPrefix}FinancialCenter`] = null;
      $scope.fo[`${fieldPrefix}FinancialCenterId`] = null;
    });
    $scope.fo.warehouseOperation = null;
    $scope.fo.warehouseOperationId = null;
  };

  $scope.filterFinancialPeriods = (fp, query) => {
    if (!fp.label) {
      return null;
    }
    const label = fp.label().toLowerCase();
    const containsEveryWord = query.toLowerCase().split(' ').every(word => label.search(word) >= 0);
    return containsEveryWord;
  };

  $scope.suggestFinancialPeriods = (query) => {
    return $scope.fPeriods.filter((fp) => {
      if (!fp.label) {
        return null;
      }
      const label = fp.label().toLowerCase();
      const containsEveryWord = query.toLowerCase().split(' ')
        .every(word => label.search(word) >= 0);
      return containsEveryWord;
    });
  };

  $scope.performedCheckboxDisabled = () => {
    return $scope.fo && $scope.fo.date > moment();
  };

  $scope.replaceEmptyWithZero = (value) => {
    if (!value) {
      $scope.fo.amount = 0.0;
    }
  }

  $scope.isTabAllowed = (tab) => {
    tab = tab.charAt(0).toUpperCase() + tab.slice(1)
    return $scope.userSession.can('access', `FinancialOperation${tab}Type`)
  }

  // Query

  ServiceLocation.query().then((serviceLocations) => {
    $scope.serviceLocations = serviceLocations;
  });
  PrintableDocument.query().then((docs) => {
    docs = docs.filter(x => x.subjectType === 'financial_operation');
    $rootScope.documents = docs;
    $scope.documents.forEach((doc) => {
      doc.selected = doc.default;
    });
  });
  FinanceClause.query().then((fcs) => {
    $scope.fcs = _.sortBy(fcs, fc => fc.calculated_index);
  });
  PaymentType.query().then((pts) => {
    $scope.pts = pts;
  });
  FinancialPeriod.query({ from: -36, count: 48 }).then((fPeriods) => {
    $scope.fPeriods = fPeriods;
    $scope.fPeriods.forEach((fp) => {
      fp.display = fp.label();
    });

    if (!$scope.fo.id) {
      // Add current financial period
      $scope.fo.financialPeriods = $scope.fPeriods.filter((fp) => {
        return moment().isSame(fp.dateStart, 'month');
      });
    }
  });
  FinancialProject.queryAll().then((fProjects) => {
    $scope.fProjects = fProjects;
  });

  // Code

  $scope.allowedGroups = Object.keys(FINANCIAL_OPERATION_TYPE_GROUPS).filter(tab => $scope.isTabAllowed(tab))
  $scope.permissionDenied = $scope.allowedGroups.length === 0

  if (resolvedFinancialOperation) {
    $scope.fo = new FinancialOperation(resolvedFinancialOperation);

    if (!userSession.can('access', $scope.fo.recordType)) {
      $scope.permissionDenied = true
    }

    const callback = (fo) => {
      Object.keys(FINANCIAL_OPERATION_TYPE_GROUPS).forEach((key, index) => {
        FINANCIAL_OPERATION_TYPE_GROUPS[key].forEach((type) => {
          if (type === fo.type) {
            $scope.selectedTabIndex = index;
          }
        });
      });

      ($scope.fo.financialPeriods || []).forEach((fp) => {
        fp.display = fp.label();
      });
      if ($scope.fo.warehouseOperation) {
        $scope.fo.warehouseOperation = new WarehouseOperation($scope.fo.warehouseOperation);
      }
    };
    if ($scope.fo.id) {
      $scope.fo.get().then(callback);
    } else {
      callback($scope.fo);
    }
  } else {
    selectTabCalled = true; // next tab click triggers reset
    $scope.fo = new FinancialOperation({
      date: new Date(),
      performed: true,
    });
  }

  // Watch

  $scope.$watch('fo.date', () => {
    if (!$scope.fo) {
      return;
    }

    if ($scope.fo.date > moment()) {
      $scope.fo.performed = false;
    } else {
      $scope.fo.performed = true;
    }
  });
});
