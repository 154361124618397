angular.module('RocketWash').controller('WashSettingsLaneCamerasBaseController', function($rootScope, $scope, $state, $translate, $uibModal, viewStore, LaneCamera, ServiceLocationLane, LaneCameraEvent, ServiceLocation) {

  $scope.pds = $scope.pageDataService.new({
    page: 'lane_camera_events',
    pg: {
      url: '/wash/lane_camera_events',
      model: LaneCameraEvent,
      query: {
        skipDateFilter: true,
        page: 1,
        per: 10,
      },
    },
    definePaginateMethodsOn: $scope,
  });

  ServiceLocation.query().then(function(data) {
    $scope.serviceLocations = data;
  });

  ServiceLocationLane.query().then(function(data) {
    $scope.serviceLocationLanes = data;
  });

  let updateLaneCameras;
  (updateLaneCameras = function() {
    LaneCamera.query().then(function(data) {
      $scope.lane_cameras = _.map(data, function(o) {
        return new LaneCamera(o);
      });
    });
  })();

  $scope.save = function(lane_camera) {
    lane_camera.serviceLocationLaneId = lane_camera.serviceLocationLane && lane_camera.serviceLocationLane.id
    lane_camera.serviceLocationId = lane_camera.serviceLocation && lane_camera.serviceLocation.id
    lane_camera.save().then(function() {
      updateLaneCameras();
    });
  };

  $scope.deleteLaneCamera = function(lane_camera) {
    if (confirm($translate.instant('alert.are_you_sure'))) {
      return lane_camera["delete"]().then(function() {
        return updateLaneCameras();
      });
    }
  };

  $scope.toPercents = (value) => {
    return Math.round(parseFloat(value) * 10000) / 100.0;
  };

  $scope.editLaneCamera = function(lane_camera) {
    $uibModal.open({
      templateUrl: 'pages/wash/settings/lane_cameras/form.slim',
      controller: 'WashSettingsLaneCamerasFormController',
      resolve: {
        resolvedLaneCamera: () => new LaneCamera(lane_camera),
      },
    }).result.finally(() => {
      setTimeout(() => {
        updateLaneCameras();
      }, 1000);
    });
  };

  $scope.newLaneCamera = () => {
    $uibModal.open({
      templateUrl: 'pages/wash/settings/lane_cameras/form.slim',
      controller: 'WashSettingsLaneCamerasFormController',
      resolve: {
        resolvedLaneCamera: () => new LaneCamera(),
      },
    }).result.finally(() => {
      setTimeout(() => {
        updateLaneCameras();
      }, 1000);
    });
  };
});
