angular.module('RocketWash').filter('toSnakeCase', () => {
  return (str) => {
    let result = str.replace(/([A-Z])/g, (x) => {
      return `_${x.toLowerCase()}`;
    });

    if (result[0] === '_') {
      result = result.slice(1);
    }

    return result;
  };
});
