angular.module('RocketWash').filter('formatTime', () => {
  return (time, format) => {
    if (!time) {
      return null;
    }
    if (!moment(time).isValid()) {
      return '';
    }

    switch (format) {
    case undefined:
    case 'datetime':
      format = 'HH:mm DD.MM.YY';
      break;
    case 'date':
      format = 'DD.MM.YY';
      break;
    case 'compact':
      format = moment(time).isSame(moment(time).startOf('day')) ? 'DD.MM.YY' : 'HH:mm DD.MM.YY';
      break;
    case 'time':
      format = 'HH:mm';
      break;
    case 'month':
      format = 'MMMM YYYY';
      break;
    default: break;
    }

    return moment(time).format(format);
  };
});
