angular.module('RocketWash').controller('AdminSettingsCarTypesFormController', (
  $scope, CarType, resolvedCarType,
) => {
  $scope.carType = new CarType(resolvedCarType);
  if ($scope.carType.id) {
    $scope.carType.get().then(() => {
      console.log('reloaded');
    });
  }

  $scope.save = () => {
    $scope.formDisabled = true;
    $scope.carType.save().then((carType) => {
      if (_.isEmpty(carType.errors)) {
        $scope.$dismiss();
      } else {
        $scope.errors = { create_car_type: carType.errors };
      }
    }).finally(() => { $scope.formDisabled = false; });
  };
});
