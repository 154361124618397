angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.settings.ultrasonic_sensors', {
    url: '/ultrasonic_sensors',
    tab: 'ultrasonic_sensors',
    data: {
      permission: 'UltrasonicSensorsTab'
    },
    views: {
      'content@app.wash.settings': {
        controller: 'WashSettingsUltrasonicSensorsBaseController',
        templateUrl: 'pages/wash/settings/ultrasonic_sensors/index.slim'
      }
    }
  });
});
