angular.module('RocketWash').controller('AdminOrganizationsSettingsServiceLocationsController', (
  $scope, $translate, $stateParams, Organization,
  ServiceLocation, TimeZone, Plan, rwApi
) => {
  ServiceLocation.tenantId = $stateParams.orgId

  $scope.plans = []
  $scope.organizations = []

  ServiceLocation.query({ organization_id: $stateParams.orgId }).then((sls) => {
    $scope.serviceLocations = sls.map(sl => {
      sl.prevDevicesWithKkmserverLicense = []
      return sl
    })
  })

  Plan.query().then((plans) => {
    $scope.plans = plans
  })

  Organization.query().then((orgs) => {
    $scope.organizations = orgs
  })

  TimeZone.query().then((timeZones) => {
    timeZones.forEach((tz) => {
      const offset = moment().utcOffset(tz.utcOffset / 60).format('Z')
      tz.title = `(GMT ${offset}) ${tz.name}`
    })
    $scope.timeZones = timeZones
  })

  rwApi.sendRequest({
    method: 'GET',
    path: 'devices/all',
    params: {
      organization_id: $stateParams.orgId
    }
  }).then(devices => {
    $scope.devices = devices.filter(device => /ККМ/.test(device.name))
    $scope.allDevices = devices
  })

  $scope.getDevices = (serviceLocation) => {
    if (!$scope.devices) return

    return $scope.devices.filter(device => device.service_location_id === serviceLocation.id)
  }

  $scope.new = () => {
    $scope.serviceLocations.unshift(
      new ServiceLocation({ organizationId: $stateParams.orgId }),
    )
  }

  $scope.save = (serviceLocation) => {
    serviceLocation.save()
  }

  $scope.duplicate = (serviceLocation) => {
    serviceLocation.duplicate().then((sl) => {
      $scope.serviceLocations.unshift(sl)
    })
  }

  $scope.delete = (serviceLocation) => {
    if (confirm($translate.instant('alert.are_you_sure'))) {
      serviceLocation.delete().then(() => {
        $scope.serviceLocations = $scope.serviceLocations
          .filter(x => x !== serviceLocation)
      })
    }
  }

  $scope.planName = (planId) => {
    const plan = $scope.plans.find(p => p.id === planId)
    return plan && plan.name
  }

  $scope.assignKkmLicense = (serviceLocation, devicesToAssign) => {
    if (devicesToAssign.length > serviceLocation.prevDevicesWithKkmserverLicense.length) {
      // add kkmLicense to device
      devicesToAssign.forEach(device => {
        if (!serviceLocation.prevDevicesWithKkmserverLicense.includes(device)) {
          device.settings.has_kkm_license = true
          device.organization_id = $stateParams.orgId
          rwApi.sendRequest({
            method: 'PUT',
            path: 'devices',
            data: {
              organization_id: $stateParams.orgId,
              device
            }
          })
        }
      })
    } else {
      // remove kkmLicense from device
      serviceLocation.prevDevicesWithKkmserverLicense.forEach(device => {
        if (!devicesToAssign.includes(device)) {
          device.settings.has_kkm_license = false
          device.organization_id = $stateParams.orgId
          rwApi.sendRequest({
            method: 'PUT',
            path: 'devices',
            data: {
              organization_id: $stateParams.orgId,
              device
            }
          })
        }
      })
    }

    serviceLocation.prevDevicesWithKkmserverLicense = devicesToAssign
  }
})
