angular.module('RocketWash').factory(
  'ServiceFolder',
  ($http, railsResourceFactory, userSession) => {
    const resource = railsResourceFactory({
      url: (context) => { return "/" + (context.queryRole || userSession.controllerScope()) + "/service_folders/" + (context.id || '') },
        name: 'service_folder',
    });

    resource.indexAsTree = function(queryParams, context) {
      return $http({
        url: `${this.resourceUrl(context)}/index_as_tree`,
        params: queryParams,
        method: 'GET'
      }).then(function(response) {
        return response.data;
      });
    };

    return resource;
  },
);
