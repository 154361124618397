angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.settings.dashboard', {
    url: '/dashboard',
    tab: 'dashboard',
    data: {
      permission: 'SettingsDashboardTab'
    },
    views: {
      'content@app.wash.settings': {
        controller: 'WashSettingsDashboardIndexController',
        templateUrl: 'pages/wash/settings/dashboard/index.slim'
      }
    }
  });
});
