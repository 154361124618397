angular.module('RocketWash').config(($stateProvider, $urlRouterProvider) => {
  $urlRouterProvider.when('/wash/income', '/wash/income/report');

  $stateProvider.state('app.wash.income', {
    url: '/income',
    abstract: true,
    controller: 'WashIncomeBaseController',
    templateUrl: 'pages/wash/income/index.slim',
    data: {
      permission: 'SalariesMenuItem',
      tabs: ['salary_report', 'report', 'employees', 'jobs', 'motivation_percentage', 'motivation_fixed', 'salary_bonus'],
    },
  });
});
