angular.module('RocketWash').factory(
  'Contractor',
  (railsResourceFactory, railsSerializer, userSession) => {
    const Resource = railsResourceFactory({
      url: (context) => { return "/" + (context.queryRole || userSession.controllerScope()) + "/contractors/" + (context.id || '') },
      name: 'contractor',
      serializer: railsSerializer(function () {
        this.nestedAttribute('cars');
      }),
    });

    return Resource;
  },
);
