angular.module('RocketWash').factory(
  'PaymentSubtype',
  ($http, railsResourceFactory, railsSerializer, userSession) => {
    const Resource = railsResourceFactory({
      url: (context) => { return "/" + (context.queryRole || userSession.controllerScope()) + "/payment_subtypes/" + (context.id || '') },
      name: 'payment_subtype',
    });

    return Resource;
  },
);
