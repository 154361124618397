angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.partner.finances.dashboard', {
    url: '/dashboard',
    tab: 'dashboard',
    views: {
      'content@app.partner.finances': {
        controller: 'PartnerFinancesDashboardBaseController',
        templateUrl: 'pages/partner/finances/dashboard/index.slim'
      }
    }
  });
});
