angular.module('RocketWash').config(($stateProvider) => {
  $stateProvider.state('app.admin.settings.payment_types', {
    url: '/payment_types',
    tab: 'payment_types',
    views: {
      'content@app.admin.settings': {
        controller: 'AdminSettingsPaymentTypesBaseController',
        templateUrl: 'pages/admin/settings/payment_types/index.slim',
      },
    },
  });
});
