angular.module('RocketWash').directive('rwFullcalendar', (userSession) => {
  return {
    restrict: 'E',
    scope: {
      config: '=',
      showCameraEvents: '=',
    },
    templateUrl: 'directives/rw-fullcalendar/rw-fullcalendar.slim',
    controller: ($scope, $element, dateRangeStorage) => {
      $scope.usePreReservations = userSession.usePreReservations();

      // calendar element
      $scope.day = dateRangeStorage.from().toDate();

      $scope.pickDay = (day) => {
        $($element.find('#rw-fullcalendar')).fullCalendar('gotoDate', day);
      };
      $scope.prevDay = () => { $($element.find('#rw-fullcalendar')).fullCalendar('prev'); };
      $scope.nextDay = () => { $($element.find('#rw-fullcalendar')).fullCalendar('next'); };

      $scope.$on('date-range-updated', () => { $scope.day = dateRangeStorage.from().toDate(); });
      // end calendar element

      $scope.config.viewRender = (view, _element) => {
        if (!$scope.initialLoadPrevented) {
          $scope.initialLoadPrevented = true;
          return;
        }
        // hotfix for undefined timezone
        console.log('update view date');

        if(moment(view.intervalStart.format()).isSame(moment(), 'day')) {
          $($element.find('.fc-left').hide());
        } else {
          $($element.find('.fc-left').show());
        }

        dateRangeStorage.update({
          from: view.intervalStart.format(),
          to: view.intervalEnd.format(),
        });
      };

      $($element.find('#rw-fullcalendar')).fullCalendar($scope.config);
      $($element.find('#rw-fullcalendar')).fullCalendar('gotoDate', dateRangeStorage.from());
    },
  };
});
