angular.module('RocketWash').controller('WashSettingsActionsController', (
  $scope, $uibModal, SettingValue, userSession, serverConstants, alertService, User,
) => {
  $scope.SETTING_VALUES = serverConstants.setting_values;
  $scope.actions = [
    'HappyBirthday',
    'BonusesForPayment',
    'DiscountsForPayment',
    'SmsNotificationOnceReservationFinished',
    'SmsFeedbackRequest',
  ];
  $scope.selected = {
    actionIndex: 0,
  };
  $scope.progress = {
    resetIndividualDiscounts: false,
  };
  $scope.settings = [];
  $scope.enableBonusesKey = {};

  const BONUSES_KEYS_TO_HIDE = [
    'percent_of_payment_for_reservation_which_should_be_added_as_bonuses',
    'bonuses_percent_for_mobile_reservation',
  ];

  const PERIOD_KEY_TO_HIDE = [
    'period_of_days_to_withdraw_bonuses_if_no_reservation'
  ];

  SettingValue.query().then((settings) => {
    $scope.settings = settings;
    $scope.enableBonusesKey = $scope.settings
      .find(x => x.key === 'add_bonuses_as_percent_of_payment_for_reservation');
    $scope.useWithdrawBonusesIfNoReservation = $scope.settings
      .find(x => x.key === 'use_withdraw_bonuses_if_no_reservation');
  });

  $scope.actionSettings = () => {
    let results = $scope.settings
      .filter(x => x.keyType === `${$scope.selected.action}ActionSettingKey`);

    if (
      $scope.selected.action === 'BonusesForPayment' &&
      $scope.enableBonusesKey.value === 'no'
    ) {
      results = results.filter(x => !BONUSES_KEYS_TO_HIDE.includes(x.key));
    }

    if (
      $scope.useWithdrawBonusesIfNoReservation.value === 'no'
    ) {
      results = results.filter(x => !PERIOD_KEY_TO_HIDE.includes(x.key));
    }

    if (results.find(item => item.key == "delay_for_send_sms_feedback_request_to_user" && item.value == '0')) {
      for( let i = 0; i < results.length; i++){
        if ( results[i].key === 'send_sms_feedback_request_to_user_time') {
          results.splice(i, 1);
        }
      }
    }

    return results.sort((a,b)=>a.settingKeyId - b.settingKeyId);
  };

  const alertFeatureNotPurchased = () => {
    alertService.show({
      text: 'Функция недоступна на данном тарифном плане.',
      type: 'error',
    });
  };

  $scope.save = (setting) => {
    setting.save().then((response) => {
      if ((response.errors.featureNotPurchased)) {
        alertFeatureNotPurchased();
      }
      userSession.reload();
    });
  };

  const confirmAction = (callback) => {
    $uibModal.open({
      templateUrl: 'pages/common/confirmation-popup/index.slim',
      controller: 'CommonConfirmationPopupController',
      resolve: {
        config: {
          title: 'Подтверждение',
          text: `Данное действие нельзя отменить. Вы уверены, что хотите продолжить?`,
          buttons: [
            {
              text: 'Нет',
              class: 'btn blue-btn fix-btn',
              click: (scope) => {
                scope.$dismiss();
              }
            },
            {
              text: 'Да',
              class: 'right btn white-btn fix-btn',
              click: (scope) => {
                callback();
                scope.$dismiss();
              }
            },
          ]
        },
      },
    });
  };

  $scope.resetIndividualDiscounts = () => {
    confirmAction(() => {
      $scope.progress.resetIndividualDiscounts = true;
      User.resetIndividualDiscounts().finally(() => {
        $scope.progress.resetIndividualDiscounts = false;
      });
    });
  };

  $scope.resetIndividualBonuses = () => {
    confirmAction(() => {
      $scope.progress.resetIndividualBonuses = true;
      User.resetIndividualBonuses().finally(() => {
        $scope.progress.resetIndividualBonuses = false;
      });
    });
  };

  $scope.resetIndividualBonusAccounts = () => {
    confirmAction(() => {
      $scope.progress.resetIndividualBonuses = true;
      User.resetIndividualBonusAccounts().finally(() => {
        $scope.progress.resetIndividualBonuses = false;
      });
    });
  };

  $scope.$watch('selected.actionIndex', () => {
    $scope.selected.action = $scope.actions[$scope.selected.actionIndex];
  });
});
