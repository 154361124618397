angular.module('RocketWash').controller('WashIncomeBaseController', (
  $scope,
) => {
  $scope.fot = (er) => {
    return parseFloat(er.fot).toFixed(2);
  };
  $scope.setTimeRange = (period) => {
    $scope.pg.query.timeRange = period;
  };
});
