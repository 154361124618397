angular.module('RocketWash').constant('WAREHOUSE_OPERATION_FORM_CONFIG', {
  WarehouseOperationSale: {
    showItemDiscount: true,
    hasPayments: true,
    showAverageBuyPrice: false,
    showLastBuyPrice: false,
    showPriceListPrice: false,
    allowCreateProduct: false,
    showPriceAsInput: false,
  },
  WarehouseOperationArrival: {
    showItemDiscount: false,
    hasPayments: false,
    showAverageBuyPrice: false,
    showLastBuyPrice: false,
    showPriceListPrice: false,
    allowCreateProduct: true,
    showPriceAsInput: true,
  },
  WarehouseOperationOtherExpenditure: {
    showItemDiscount: false,
    hasPayments: false,
    showAverageBuyPrice: false,
    showLastBuyPrice: false,
    showPriceListPrice: true,
    allowCreateProduct: false,
    showPriceAsInput: false,
  },
  WarehouseOperationOtherArrival: {
    showItemDiscount: false,
    hasPayments: false,
    showAverageBuyPrice: true,
    showLastBuyPrice: true,
    showPriceListPrice: false,
    allowCreateProduct: true,
    showPriceAsInput: true,
  },
  WarehouseOperationInternalTransfer: {
    showItemDiscount: false,
    hasPayments: false,
    showAverageBuyPrice: false,
    showLastBuyPrice: false,
    showPriceListPrice: false,
    allowCreateProduct: false,
    showPriceAsInput: false,
  },
  WarehouseOperationPurchaseReturn: {
    showItemDiscount: false,
    hasPayments: false,
    showAverageBuyPrice: false,
    showLastBuyPrice: false,
    showPriceListPrice: false,
    allowCreateProduct: false,
    showPriceAsInput: false,
  },
});

