angular.module('RocketWash').directive('rwWarehouseSelect', () => ({
  restrict: 'E',
  replace: true,
  templateUrl: 'directives/rw-warehouse-select/index.slim',
  scope: {
  },
  controller: (
    $scope, warehouseSelectService
  ) => {
    $scope.warehouseSelectService = warehouseSelectService;
  },
}));
