angular.module('RocketWash').config(($stateProvider) => {
  $stateProvider.state('app.partner.finances', {
    url: '/finances',
    abstract: true,
    controller: 'PartnerFinancesBaseController',
    templateUrl: 'pages/partner/finances/index.slim',
    data: {
      tabs: ['dashboard', 'detailed_organizations'],
    },
  });
});
