angular.module('RocketWash').config(($stateProvider, $urlRouterProvider) => {
  $urlRouterProvider.when('/wash/warehouse', '/wash/warehouse/trades');
  return $stateProvider.state('app.wash.warehouse', {
    url: '/warehouse',
    abstract: true,
    controller: 'WashWarehouseBaseController',
    templateUrl: 'pages/wash/warehouse/index.slim',
    data: {
      permission: 'WarehouseMenuItem',
      tabs: [
        'warehouse_operations', 'products', 'warehouses', 'price_lists', 'consumables', 'product_movements', 'single_product_operations',
      ],
    }
  });
});
