angular.module('RocketWash').controller(
  'RWCarsCarTypesCarTypesGroupFormController',
  ($scope, car) => {
    $scope.car = car;

    $scope.isSelected = (ctg, ct) => {
      return ($scope.car.cars_car_types || []).some((item) => {
        return item.car_type_id == ct.id && item.car_types_group_id == ctg.id;
      });
    };

    $scope.isRemovable = (ctg) => {
      return !!$scope.car.findCarsCarType({
        car_types_group: ctg,
      });
    };

    $scope.toggle = (ctg, ct) => {
      if (ctg.editable) {
        $scope.car.toggleCarsCarType({
          car_type: ct,
          car_types_group: ctg,
        });
      }
    };

    $scope.removeCarsCarType = (ctg) => {
      if (ctg.editable) {
        $scope.car.removeCarsCarType({
          car_types_group: ctg,
        });
      }
    };

  },
);
