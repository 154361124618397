angular.module('RocketWash').factory(
  'Car',
  ($http, railsResourceFactory, railsSerializer, userSession, rwApi) => {
    const Resource = railsResourceFactory({
      url: (context) => { return "/" + (context.queryRole || userSession.controllerScope()) + "/cars/" + (context.id || '') },
      name: 'car',
      serializer: railsSerializer(function () {
        this.nestedAttribute('contractor');
      }),
    });

    angular.extend(Resource, {
      typeahead(searchQuery, context) {
        return $http({
          method: 'GET',
          url: `${this.resourceUrl(context)}/typeahead`,
          params: { search_query: searchQuery },
        }).then((response) => {
          const cars = response.data.map(car => new this(car));
          cars.forEach((car) => {
            const carFields = [
              car.tag,
              car.carMake && car.carMake.name,
              car.carModel && car.carModel.name,
            ];
            const contractorFields = [
              car.contractor && car.contractor.name,
              car.contractor && car.contractor.discountCardNumber,
              car.contractor && car.contractor.phone,
            ];
            car.typeahead = carFields.concat(contractorFields).map(x => x || '').join(' ');
            car.carTypeahead = carFields.map(x => x || '').join(' ');
            car.contractorTypeahead = contractorFields.map(x => x || '').join(' ');
          });
          return cars;
        });
      },
    });

    Resource.prototype.merge = function (otherCarId) {
      const _class = Object.getPrototypeOf(this).constructor;
      return _class.$post(
        `${_class.resourceUrl(this)}/merge`,
        { car: this, otherCarId: otherCarId },
        { serializer: null, rootWrapping: false },
      );
    };

    Resource.prototype.reloadAvailableCtgs = function () {
      return rwApi.sendRequest({
        method: 'POST',
        path: 'reservation_form/car_types_groups/available',
        data: {
          contractor_id: this.contractor.id,
          car_id: this.id,
          car_model_id: this.car_model_id,
          car_tag: this.tag,
          cars_car_types: this.cars_car_types || [],
          service_location_id: userSession.service_location.id,
        }
      }).then((data) => {
        this.availableCtgs = data;
      });
    };

    Resource.prototype.reassignDefaultCtgs = function () {
      return rwApi.sendRequest({
        method: 'POST',
        path: 'reservation_form/car_types_groups/default',
        data: {
          car_id: this.id,
          contractor_id: this.contractor.id,
          car_model_id: this.car_model_id,
          car_tag: this.tag,
          service_location_id: userSession.service_location.id,
          cars_car_types: this.cars_car_types || [],
        }
      }).then((data) => {
        this.cars_car_types = data;
      });
    };

    Resource.prototype.toggleCarsCarType = function ({car_type, car_types_group}) {
      this.removeCarsCarType({car_types_group});

      this.cars_car_types.push({
        car_type_id: car_type.id,
        car_types_group_id: car_types_group.id,
        car_type: car_type,
        car_types_group: car_types_group,
      });
    };

    Resource.prototype.removeCarsCarType = function ({car_types_group}) {
      const currentItem = this.findCarsCarType({car_types_group});

      this.cars_car_types = _.without(this.cars_car_types, currentItem);
    };

    Resource.prototype.findCarsCarType = function ({car_types_group}) {
      return this.cars_car_types.find((item) => {
        return item.car_types_group_id == car_types_group.id;
      });
    };

    return Resource;
  },
);
