angular.module('RocketWash').config(($stateProvider) => {
  $stateProvider.state('app.wash.settings.client_monitor', {
    url: '/client_monitor',
    controller: 'ClientMonitorSettingsController',
    templateUrl: 'pages/wash/settings/client_monitor/index.slim',
    data: {
      // permission: '...MenuItem',
    },
    views: {
      'content@app.wash.settings': {
        controller: 'ClientMonitorSettingsController',
        templateUrl: 'pages/wash/settings/client_monitor/index.slim',
      },
    },
  });
});
