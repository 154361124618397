angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.settings.cameras', {
    url: '/cameras',
    tab: 'cameras',
    data: {
      permission: 'CamerasTab'
    },
    views: {
      'content@app.wash.settings': {
        controller: 'WashSettingsCamerasBaseController',
        templateUrl: 'pages/wash/settings/cameras/index.slim'
      }
    }
  });
});
