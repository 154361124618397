angular.module('RocketWash').controller('WashIncomeSalaryBonusFormController', (
  $scope, SalaryBonus, viewStore, resolvedSalaryBonus
) => {
  $scope.salaryBonus = new SalaryBonus(resolvedSalaryBonus)

  $scope.calculationPeriods = [
    { value: '0', label: 'day' },
    { value: '1', label: 'day_work_shift' },
    { value: '2', label: 'night_work_shift' },
    { value: '3', label: 'cash_shift' },
    { value: '4', label: 'work_shift' },
    { value: '5', label: 'weekly' },
    { value: '6', label: 'monthly' },
    { value: '7', label: 'quarterly' },
    { value: '8', label: 'annually' },
  ]

  $scope.types = [
    { value: '0', label: 'percent' },
    { value: '1', label: 'fix' },
  ]

  $scope.amountsOfOutput = [
    { value: '0', label: 'all_orders' },
    { value: '1', label: 'orders_with_percent_participation' },
    { value: '2', label: 'orders_without_percent_participation' },
  ]

  $scope.reservationStatuses = [
    { value: '0', label: 'completed_reservations' },
    { value: '1', label: 'completed_and_paid_reservations' },
    { value: '2', label: 'not_completed_and_paid_reservations' },
  ]

  $scope.validate = () => {
    const errors = {};
    if (!$scope.salaryBonus.name) {
      errors.name = true;
    }
    if (!$scope.salaryBonus.calculationPeriod) {
      errors.calculationPeriod = true;
    }
    if (!$scope.salaryBonus.salaryBonusType) {
      errors.salaryBonusType = true;
    }
    if (!$scope.salaryBonus.salaryBonusSum) {
      errors.salaryBonusSum = true;
    }
    if (!$scope.salaryBonus.amountOfOutput) {
      errors.amountOfOutput = true;
    }
    if (!$scope.salaryBonus.calculationFrom || !$scope.salaryBonus.calculationTo) {
      errors.calculationRange = true;
    }
    if (!$scope.salaryBonus.reservationsStatus) {
      errors.reservationsStatus = true;
    }
    if (Object.keys(errors).length === 0) {
      return true;
    }
    $scope.errors = { create_salary_bonus: errors };
    return false;
  };

  $scope.save = () => {
    if (!$scope.validate()) {
      return;
    }

    $scope.formDisabled = true;

    $scope.salaryBonus.jobId = viewStore.data.activeJob

    $scope.salaryBonus.save().then((salaryBonus) => {
      if (_.isEmpty(salaryBonus.errors)) {
        $scope.$dismiss();
      } else {
        $scope.errors = { create_salary_bonus: salaryBonus.errors };
      }
    }).finally(() => { $scope.formDisabled = false; });
  };
});
