angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.taxi.analytics.general', {
    url: '/general',
    tab: 'general',
    views: {
      'content@app.taxi.analytics': {
        controller: 'TaxiAnalyticsGeneralController',
        templateUrl: 'pages/taxi/analytics/general/index.slim'
      }
    }
  })
})
