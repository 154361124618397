angular.module('RocketWash').controller('WashSettingsOrganizationController', (
  $scope, Organization, alertService,
) => {
  Organization.get($scope.userSession.organization.id).then((organization) => {
    $scope.organization = organization;
  });

  $scope.generalFields = [
    'legalName', 'inn', 'kpp', 'ogrn', 'legalAddress', 'realAddress', 'postAddress',
    'bankAccountNumber', 'bank', 'bic', 'correspondentAccountNumber',
    'headJobName', 'accountantJobName', 'managerActsOnBasisOf', 'officePhone',
  ];
  $scope.headFields = ['headName', 'headEmail', 'headPhone'];
  $scope.accountantFields = ['accountantName', 'accountantEmail', 'accountantPhone'];

  $scope.save = () => {
    $scope.organization.save().then(() => {
      $scope.userSession.reload();
      alertService.show({
        text: 'Реквизиты организации сохранены',
        type: 'success',
      });
    });
  };
});
