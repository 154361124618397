angular.module('RocketWash').config(($stateProvider) => {
  return $stateProvider.state('client_monitor', {
    url: '/client_monitor/:monitorUUID',
    controller: 'ClientMonitorController',
    templateUrl: 'pages/client_monitor/client_monitor.slim',
    data: {
      publicPage: true,
    },
    resolve: {
      _serverTranslations: () => {
        return window.translationsPromise;
      },
    },
  });
});
