angular.module('RocketWash').directive('rwReviewRatingTrend', () => {
  return {
    restrict: 'E',
    scope: {
      review: '=',
    },
    templateUrl: 'directives/rw-review-rating-trend/rw-review-rating-trend.slim',
    link(scope, _element, _attrs) {
      const TREND_DIRECTIONS = {
        UP: 'up',
        DOWN: 'down',
        NONE: 'none',
        FIRST_REVIEW: 'first_review'
      }

      const trend = () => {
        let currentStars = scope.review.starsCount;
        let previousStars = scope.review.previousStarsCount;

        if (previousStars == null) {
          return TREND_DIRECTIONS.FIRST_REVIEW;
        }

        if (currentStars > previousStars) {
          return TREND_DIRECTIONS.UP;
        } else if (currentStars < previousStars) {
          return TREND_DIRECTIONS.DOWN;
        } else {
          return TREND_DIRECTIONS.NONE;
        }
      };

      scope.ratingTrend = trend();
    }
  }
});