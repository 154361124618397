angular.module('RocketWash').config(($stateProvider, $urlRouterProvider) => {
  $urlRouterProvider.when('/taxi/autopark', '/taxi/autopark/cars');

  $stateProvider.state('app.taxi.autopark', {
    url: '/autopark',
    abstract: true,
    controller: 'TaxiAutoparkBaseController',
    templateUrl: 'pages/taxi/autopark/index.slim',
    data: {
      tabs: ['cars', 'drivers', 'employees'],
    },
  });
});
