import { env } from '@env';

angular.module('RocketWash').controller('AdminServiceLocationsSettingsController',
  function ($scope, $translate, $stateParams, $timeout, ServiceLocation,
            Organization, PrintableDocument, TimeZone, Plan, $q) {

  Plan.get('settings_schema').then((settingsSchema) => {
    // Select only switchable setting keys
    $scope.settingsSchema = settingsSchema;
    $scope.settingKeys = Object.keys(settingsSchema)
      .filter(k => k.endsWith('Active'));
    initAdminLockedKeys();
  });

  const reload = () => {
    ServiceLocation.get($stateParams.slId, { organizationId: $stateParams.tenantId }).then((sl) => {
      $scope.sl = new ServiceLocation(sl);

      Plan.get($scope.sl.planId).then((plan) => {
        $scope.plan = plan;
      })
    });
  }

  reload();

  // Initialize hash to create available checkbox ng-models
  // if they didn't exist
  const initAdminLockedKeys = () => {
    _.forEach($scope.settingKeys, (key) => {
      if ($scope.sl.adminLockedAdditionalFeatures[key] === undefined) {
        $scope.sl.adminLockedAdditionalFeatures[key] = false;
      }
    })
  };

  // Check whether key is in the 'locked' list
  $scope.keyIsLocked = (key) => {
    let lockedKeys = Object.keys($scope.sl.adminLockedAdditionalFeatures);
    if (lockedKeys.includes(key)) {
      return true;
    }

    return false;
  };

  // Looks up `key` state. If service location doesn't have its own
  // it means it uses a plan's default.
  $scope.keyIsActivated = (key) => {
    let activeFeatures = $scope.sl.additionalDailyPaidFeatures;

    if (activeFeatures[key] === undefined) {
      return $scope.plan.settings[key];
    } else {
      return activeFeatures[key];
    };
  };

  $scope.toggleKeyLock = (key) => {
    $scope.sl.adminLockedAdditionalFeatures[key] = !$scope.keyIsLocked(key);
  };

  // Filter boolean value ("switch") settings
  $scope.settingLockableAgainstSwitching = (settingKey) => {
    let value = $scope.settingsSchema[settingKey];

    if (value === "boolean") {
      return true;
    }

    return false;
  };

  $scope.save = () => {
    $scope.sl.additionalDailyPaidFeatures = JSON.parse($scope.sl.additionalDailyPaidFeaturesPretty);
    $scope.sl.save().then(reload);
  };

  $scope.exampleJson = {
    a: 10,
    b: 2,
    c: 3
  }

  $timeout(() => {
    $('#import-from-carmen').attr('action', `${env.apiUrl}/admin/carmen/import`);
  })

  $scope.submitCarmen = () => {
    $('#import-from-carmen').submit();
  };

  $scope.cdo = {};
  $scope.clearData = () => {
    $scope.sl.clearData({clear_data_options: $scope.cdo}, {organizationId: $stateParams.tenantId});
  };
});
