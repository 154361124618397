angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.dashboard.control_panel', {
    url: '/control_panel',
    tab: 'control_panel',
    data: {
      permission: 'DashboardTab'
    },
    views: {
      'content@app.wash.dashboard': {
        controller: 'WashDashboardControlPanelController',
        templateUrl: 'pages/wash/dashboard/control_panel/index.slim'
      }
    }
  })
})
