

angular.module('RocketWash').controller('WashSettingsCommonPhoneController', function($scope, $element) {
  $scope.clearPhone = function(setting) {
    setting.value = null;
    return setting.save();
  };
  return $scope.setPhoneValue = function(setting) {
    setting.value = $element.find('input').val();
    setting.save();
    return true;
  };
});
