angular.module('RocketWash').factory(
  'LegalEntity',
  (railsResourceFactory, userSession) => {
    const resource = railsResourceFactory({
      url: (context) => { return "/" + (context.queryRole || userSession.controllerScope()) + "/legal_entities/" + (context.id || '') },
      name: 'legal_entity',
    });

    return resource;
  },
);
