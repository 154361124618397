angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.admin.settings.global_mobile_services', {
    url: '/global_mobile_services',
    tab: 'global_mobile_services',
    data: {
      permission: 'GlobalMobileServicesTab'
    },
    views: {
      'content@app.admin.settings': {
        controller: 'AdminSettingsGlobalMobileServicesBaseController',
        templateUrl: 'pages/admin/settings/global_mobile_services/index.slim'
      }
    }
  }).state('app.admin.settings.global_mobile_services.form', {
    url: '/form',
    views: {
      'content@app.admin.settings': {
        controller: 'AdminSettingsGlobalMobileServicesFormController',
        templateUrl: 'pages/admin/settings/global_mobile_services/form.slim'
      }
    }
  });
});
