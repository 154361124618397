angular.module('RocketWash').controller('WashSettingsCamerasBaseController', function($rootScope, $scope, $state, $translate, viewStore, Camera, ServiceLocationLane, CameraEvent, ServiceLocation) {
  $scope.predicate = 'name';
  $scope.reverse = false;

  $scope.pds = $scope.pageDataService.new({
    page: 'camera_events',
    pg: {
      url: '/wash/camera_events',
      model: CameraEvent,
      query: {
        skipDateFilter: true,
        page: 1,
        per: 10,
      },
    },
    definePaginateMethodsOn: $scope,
  });

  ServiceLocation.query().then(function(data) {
    $scope.serviceLocations = data;
  });

  ServiceLocationLane.query().then(function(data) {
    $scope.serviceLocationLanes = data;
  });

  let updateCameras;
  (updateCameras = function() {
    Camera.query().then(function(data) {
      $scope.cameras = _.map(data, function(o) {
        return new Camera(o);
      });
    });
  })();

  $scope.save = function(camera) {
    camera.serviceLocationLaneId = camera.serviceLocationLane && camera.serviceLocationLane.id
    camera.serviceLocationId = camera.serviceLocation && camera.serviceLocation.id
    camera.save().then(function() {
      updateCameras();
    });
  };

  $scope.deleteCamera = function(camera) {
    if (confirm($translate.instant('alert.are_you_sure'))) {
      return camera["delete"]().then(function() {
        return updateCameras();
      });
    }
  };

  $scope.toPercents = (value) => {
    return Math.round(parseFloat(value) * 10000) / 100.0;
  };
});
