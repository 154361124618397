angular.module('RocketWash').controller(
  'AdminOrganizationsDashboardIndexController',
  ($scope, Organization, $translate) => {
    $scope.pds = $scope.pageDataService.new({
      page: 'organizations',
      filters: {
        attributes: [
          { name: 'name', type: 'string' },
          { name: 'address', type: 'string' },
          { name: 'phone', type: 'string' },
          { name: 'email', type: 'string' },
          { name: 'kpp', type: 'string' },
          { name: 'inn', type: 'string' },
          { name: 'subdomain_name', type: 'string' },
          {
            name: 'do_not_block',
            type: 'enum',
            values: [
              { value: 'да', label: 'Да' },
              { value: 'нет', label: 'Нет' },
            ],
          },
        ],
        scopes: ['name', 'address', 'phone', 'email', 'kpp', 'inn', 'subdomain_name'],
      },
      pg: {
        url: '/admin/organizations',
        model: Organization,
        query: {
          skipDateFilter: true,
          predicate: '-created_at',
        },
      },
      definePaginateMethodsOn: $scope,
    });
    $scope.new = () => {
      $scope.pds.pg.items.unshift(new Organization());
    };
    $scope.duplicate = (organization) => {
      organization.duplicate().then((org) => {
        $scope.pds.pg.items.unshift(org);
      });
    };
    $scope.save = (organization, index) => {
      if (index != null) {
        organization.phone = $(`#organization-${index} .phone-input`).val();
      }
      organization.save();
    };
    $scope.delete = function (organization) {
      if (confirm($translate.instant('alert.are_you_sure'))) {
        organization.delete().then(() => {
          $scope.updatePage();
        });
      }
    };
    $scope.invite = function (organization) {
      organization.invite().then(
        () => (alert($translate.instant('alert.invite_sent'))),
        response => (alert(response.data.error)),
      );
    };

    $scope.humanizeNumber = function (number, fallback) {
      return number != null ? number : fallback;
    };

    $scope.toggleOrganization = (organization, state) => {
      if (state === undefined) {
        organization.expanded = !organization.expanded;
      } else {
        organization.expanded = state;
      };
    };

    $scope.createTaxiCtgs = function (organization) {
      Organization.createTaxiCtgs().then(
        () => (alert('Группы будут созданы в ближайшее время')),
        response => (alert(response.data.error)),
      );
    };

  },
);
