angular.module('RocketWash').config(($stateProvider, $urlRouterProvider) => {
  $urlRouterProvider.when('/wash/settings', '/wash/settings/dashboard');
  return $stateProvider.state('app.wash.settings', {
    url: '/settings',
    abstract: true,
    controller: 'WashSettingsBaseController',
    templateUrl: 'pages/wash/settings/index.slim',
    data: {
      permission: 'SettingsMenuItem',
      tabs: [
        'general', 'organization', 'schedule', 'lanes', 'mobile_settings','ya_fuel_online', 'mobile_services', 'client_monitor', 'salary', 'import',
        'printable_documents', 'warehouse', 'kassa', 'security', 'suspicious_activity', 'car_types_groups', 'car_types', 'car_models',
        'permissions', 'actions', 'user_categories', 'payment_subtype_fuel_cards', 'cameras',
        'lane_cameras', 'ultrasonic_sensors', 'api_keys', 'integrations',
      ],
    },
  });
});
