angular.module('RocketWash').factory('MarketingActivity', (
  railsResourceFactory, railsSerializer, userSession, $http,
) => {
  const Resource = railsResourceFactory({
    url: context => `/${context.queryRole || userSession.controllerScope()}/marketing_activities/${context.id || ''}`,
    name: 'marketing_activity',
    serializer: railsSerializer(function () {
      this.add('dateStart', (ma) => {
        if (ma.dateStart) {
          return moment(ma.dateStart).format('YYYY-MM-DD');
        }
        return ma.dateStart;
      });
      this.add('dateEnd', (ma) => {
        if (ma.dateEnd) {
          return moment(ma.dateEnd).format('YYYY-MM-DD');
        }
        return ma.dateEnd;
      });
    }),
  });

  Resource.queryAll = function (queryParams, context) {
    return $http({
      url: `${this.resourceUrl(context)}/all`,
      params: queryParams,
      method: 'GET',
    }).then((response) => {
      return response.data.map(x => new Resource(x));
    });
  };

  return Resource;
});
