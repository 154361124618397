angular.module('RocketWash').config(($stateProvider, $urlRouterProvider) => {
  $urlRouterProvider.when('/taxi/analytics', '/taxi/analytics/general');
  return $stateProvider.state('app.taxi.analytics', {
    url: '/analytics',
    abstract: true,
    controller: 'TaxiAnalyticsBaseController',
    templateUrl: 'pages/taxi/analytics/index.slim',
    data: {
      tabs: ['general', 'external_payments'],
    },
  });
});
