/* eslint-disable-next-line */
import { env } from '@env';

angular.module('RocketWash').controller(
  'AdminServiceLocationSettingsPrintController',
  (
    $scope, $stateParams, $translate, alertService, Upload,
    ServiceLocation, PrintableDocument, PrintScopeStub, serverConstants,
  ) => {
    ServiceLocation.tenantId = $stateParams.orgId;
    PrintableDocument.tenantId = $stateParams.orgId;
    $scope.progress = { upload: 0 };

    serverConstants.promise.then(() => {
      $scope.printableDocumentSubjectTypes =
        serverConstants.printable_document_subject_types;
    });

    $scope.documents = [];
    $scope.document = null;

    $scope.reload = () => {
      ServiceLocation.get(
        $stateParams.slId,
      ).then((sl) => {
        $scope.documents = sl.printableDocuments;
        if ($scope.documen628ts.length && !$scope.document) {
          $scope.edit($scope.documents[0].id);
        }
      });
    };

    $scope.create = () => {
      new PrintableDocument({
        name: 'new document',
        service_location_id: $stateParams.slId,
      }).save().then((doc) => {
        $scope.document = doc;
        $scope.reload();
      });
    };
    $scope.edit = (id) => {
      PrintableDocument.get(id).then((doc) => {
        $scope.document = doc;
      });
    };
    $scope.delete = () => {
      $scope.document.delete().then(() => {
        $scope.document = null;
        $scope.reload();
      });
    };
    $scope.save = () => {
      $scope.document.save().then(() => {
        $scope.reload();
      });
    };
    $scope.currentDate = () => moment().format('HH:mm YYYY-MM-DD');
    $scope.table = {
      totalPrice: () => 1000,
      totalDiscount: () => 200,
      totalRoundedDiscount: () => 200,
    };

    angular.extend($scope, PrintScopeStub);

    $scope.reload();

    $scope.upload = (file) => {
      // File did not pass validation
      if (!file) {
        alertService.show({
          text: $translate.instant('upload.error'),
          type: 'error',
        });
        return;
      }

      $scope.progress.upload = 0;
      Upload.upload({
        url: `${env.apiUrl}/admin/printable_documents/${$scope.document.id}/upload_stamp`,
        method: 'POST',
        data: { printable_document: { stamp: file } },
        headers: { 'Tenant-Id': $stateParams.orgId },
        withCredentials: true,
      }).then((_response) => {
        alertService.show({
          text: $translate.instant('upload.success'),
        });
        $scope.progress.upload = undefined;
        $scope.document.get();
      }, (_error) => {
        $scope.progress.upload = undefined;
      }, (evt) => {
        const progress = parseInt(100.0 * evt.loaded / evt.total, 10);
        console.log(`progress: ${progress}`);
        $scope.progress.upload = progress;
      });
    };
  },
);
