angular.module('RocketWash').directive('rwUnitOrPackageSelect', () => ({
  restrict: 'E',
  replace: true,
  templateUrl: 'directives/rw-unit-or-package-select/index.slim',
  scope: {
  },
  controller: (
    $scope, unitOrPackageSelectService
  ) => {
    $scope.unitOrPackageSelectService = unitOrPackageSelectService;
  },
}));
