angular.module('RocketWash').controller(
  'CommonErrorsController',
  ($scope, $timeout) => {
    const scrollDuration = 1000;
    const heightAnimateDuration = 400;
    const closeErrorDelay = 5000;
    $scope.closeError = (errors, key) => {
      delete errors[key];
    };
    // TODO: remove underscore
    $scope.errorsPresent = () => _.some(($scope.errors || []), er => !_.isEmpty(er));
    $scope.$watch('errors', () => {
      if ($scope.errorsPresent()) {
        $timeout(() => $('body').animate({
          scrollTop: $('.errors').offset().top,
        }, scrollDuration));
        $timeout(() => {
          $('.errors').animate(
            { height: 0 },
            heightAnimateDuration,
            undefined,
            () => {
              $scope.$apply(() => {
                Object.keys($scope.errors).forEach((x) => {
                  delete $scope.errors[x];
                });
              });
              $('.errors').removeAttr('style');
            },
          );
        }, closeErrorDelay);
      }
    });
  },
);
