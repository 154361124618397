angular.module('RocketWash').controller('WashSettingsPrintableDocumentsNewDocumentPopupController', (
  $rootScope, $scope, $compile, Device, PrintableDocument, serverConstants, Upload, alertService, $translate, $uibModal,
) => {
  $scope.selected = {
    tab: 'blank',
  };

  $scope.tabs = ['blank'];

  PrintableDocument.query({templates: true}).then(function(docs) {
    docs.forEach((d) => {
      // d.toHtml({scope: PRINTABLE_DOCUMENT_VARIABLES.exampleScope});
    });
    $scope.groupedTemplates = _.groupBy(docs, (d) => d.subjectType);

    const keys = _.without(Object.keys($scope.groupedTemplates), 'none', 'undefined', 'null', 'nil');
    $scope.tabs = $scope.tabs.concat(keys);
  });

  $scope.document = new PrintableDocument();

  $scope.save = () => {
    let doc = $scope.document;

    if ($scope.selected.tab != 'blank' && $scope.selected.template) {
      doc = $scope.selected.template;
      delete doc.id;
    }

    doc.save().then((doc) => {
      $scope.$close(doc);
    });
  };
});
