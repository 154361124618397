angular.module('RocketWash').directive('rwWebcamOpenOverlayButton', ($translate, webcamService, reservationForm, alertService) => {
  return {
    restrict: 'E',
    templateUrl: 'directives/rw-webcam-open-overlay-button/index.slim',
    link(scope, _element, _attrs) {
      scope.openWebcamOverlay = () => {
        webcamService.showOverlay = true;
        webcamService.start((blob) => {
          webcamService.stop();

          webcamService.showOverlay = false;

          const photo = new File([blob], "camera_photo.jpeg");
          reservationForm.photos.recognize({
            photo: photo
          }).then((data) => {
            if (data.best_result) {
              reservationForm.carContractor.searchText = data.best_result.number;

              alertService.show({
                text: $translate.instant('dashboard.reservation_popup.general.autocomplete_car.recognition.success', {number: data.best_result.number, accuracy: data.best_result.reliability}),
                type: 'success recognition-result',
                timeout: 3000,
              });
            } else {
              alertService.show({
                text: $translate.instant('dashboard.reservation_popup.general.autocomplete_car.recognition.fail'),
                type: 'error recognition-result',
                timeout: 3000,
              });
            };
            document.querySelector('#reservation-car-autocomplete').focus();
          });
        });
      }

      if (reservationForm.config.getAutostartCamera()) {
        scope.openWebcamOverlay();
      };
    },
  };
});
