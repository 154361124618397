angular.module('RocketWash').controller(
  'WashDashboardCompletedReservationsController',
  (
    $filter, $scope, $uibModal, $translate, $http,
    Reservation, total, viewStore, rwConfirmationService, alertService,
    screenService, reservationForm, userSession,
  ) => {
    // INITIALIZATION
    $scope.total = total;
    $scope.selectedReservation = {};
    $scope.pds = $scope.pageDataService.new({
      page: 'completed_reservations',
      filters: {
        attributes: [
          { name: 'time_start', type: 'datetime' },
          { name: 'time_end', type: 'datetime' },
          { name: 'paid_at', type: 'datetime' },
          { name: 'name', type: 'string' },
          { name: 'phone', type: 'string' },
          { name: 'rounded_price', type: 'number' },
          { name: 'discount_sum', type: 'number' },
          { name: 'discount_contragent', type: 'number' },
          { name: 'discount_percentage', type: 'number' },
          {
            name: 'channel',
            type: 'enum',
            values: [
              { value: 'offline', label: 'Офлайн' },
              { value: 'mobile', label: 'Приложение RocketWash' },
              { value: 'mobile_individual', label: 'Индивидуальное приложение' },
              { value: 'ya_maps', label: 'Яндекс Карты' },
              { value: 'ya_fuel_online', label: 'Заправки онлайн' },
            ],
          },
          { name: 'order_ordinal', type: 'number' },
          { name: 'status', type: 'string' },
          { name: 'paid_type', type: 'string' },
          { name: 'all_payment_types_full_name', type: 'string' },
          { name: 'created_responsible', type: 'string' },
          { name: 'paid_responsible', type: 'string' },
          { name: 'completed_responsible', type: 'string' },
          { name: 'added_bonuses', type: 'number' },
          { name: 'service_location_lane_name', type: 'string' },
          { name: 'comments', type: 'string' },
          { name: 'tags', type: 'string' },
          { name: 'attached_services', type: 'string' },
          { name: 'client_activity_category', type: 'string' }
        ],
        scopes: [
          'name', 'phone', 'order_ordinal', 'rounded_price', 'make_model',
          'tag', 'channel', 'status', 'paid_type', 'all_payment_types_full_name',
          'created_responsible', 'paid_responsible', 'completed_responsible',
          'comments', 'tags', 'attached_services', 'client_activity_category',
          'discount_sum', 'discount_contragent', 'discount_percentage',
        ],
      },
      pg: {
        url: '/wash/reservations_history',
        model: Reservation,
        query: {
          fromToField: 'time_start',
          predicate: '-time_start',
          page: 1,
          per: 10,
        },
      },
      definePaginateMethodsOn: $scope,
    });

    const rolesWithAccessToPersonalInfo = ['admin', 'support_officer', 'organization_director'];

    $scope.accessToPersonalInfo = userSession.can('visit', 'AccessToPersonalInformation') ||
      rolesWithAccessToPersonalInfo.some(role => userSession.is(role));

    $scope.checkPeriodForPersonalInfo = () => {
      if($scope.accessToPersonalInfo){
        return
      }
      const fromDate = new Date($scope.pds.pg.query.from);
      const comparisonDate = new Date('2024-01-01');
      if (fromDate < comparisonDate) {
        alertService.show({
          text: 'Ошибка! Доступные данные с 01.01.2024.',
          type: 'error',
        })
      }
    }

    $scope.rightMenuConfig = {
      buttons: [
        {
          type: 'export_xls',
          text: $translate.instant('buttons.export_xls'),
          action: () => { $scope.exportXLS(); },
        },
      ],
    };
    $scope.fromToFieldVariants = (() => {
      const prefix = 'completed_services.index.from_to_field_variants';
      return ['time_start', 'time_end', 'paid_at']
        .map(x => ({ field: x, label: $filter('translate')(`${prefix}.${x}`) }));
    })();

    // HELPERS
    $scope.searchParams = () => $.param($scope.pds.pg.query);
    $scope.totalPrice = (reservation) => {
      const price = total.productsPrice(reservation.attachedProducts) +
        total.servicesPrice(reservation.attachedServices);
      const discount = total.productsDiscount(reservation.attachedProducts) +
        total.servicesDiscount(reservation.attachedServices);
      return total.ceil(price - discount);
    };
    $scope.paidLabel = reservation => (
      reservation.fullyPaid ? 'label label-success' : 'label label-important'
    );
    $scope.allExpanded = () => $scope.pds.pg.items.every(x => x.expanded);

    // ACTIONS
    $scope.edit = (reservation) => {
      reservationForm.reservations.editReservationPopup(reservation).finally(() => {
        $scope.updatePage();
      });
    };

    $scope.toggleAll = () => {
      const newValue = !$scope.allExpanded();
      $scope.pds.pg.items.forEach((x) => {
        x.expanded = newValue;
      });
      $scope.loadServicesInfo($scope.pds.pg.items);
    };

    $scope.loadServicesInfo = (reservations) => {
      if (!$scope.accessToPersonalInfo) {
        $scope.services = reservations.map((r) => {
          return $scope.servicesInfo(r.id);
        });
      }
    };

    $scope.servicesReservationInfo = {};

    $scope.servicesInfo = (reservationId) => {
      // Check if the reservationId already exists in $scope.servicesReservationInfo
      if (!$scope.servicesReservationInfo.hasOwnProperty(reservationId)) {
        reservationForm.reservations.load({ id: reservationId }).then(function(response) {
          Object.assign($scope.servicesReservationInfo, { [reservationId]: response.services });
        });
      }
    };

    $scope.checkPeriodForExport = () => {
      const fromDate = new Date($scope.pds.pg.query.from);
      const toDate = new Date($scope.pds.pg.query.to);

      const differenceInMonths = (toDate.getFullYear() - fromDate.getFullYear()) * 12 + (toDate.getMonth() - fromDate.getMonth());

      if (differenceInMonths > 6) {
        alertService.show({
          text: 'Выбранный период не должен превышать полгода!',
          type: 'error',
        });
        return true;
      }
      return false;
    }

    $scope.exportXLS = () => {
      if (!$scope.userSession.can('export', 'ReservationHistoryExport')) {
        alertService.show({
          text: $translate.instant('errors.export.unauthorized'),
          type: 'error',
        });
        return;
      }

      if ($scope.checkPeriodForExport()) {
        return;
      }

      $uibModal.open({
        templateUrl: 'pages/common/email_popup/email_popup.slim',
        controller: 'CommonEmailPopupController',
        windowClass: 'small',
        size: 'sm',
      }).result.then((email) => {
        const options = angular.extend({ email }, Object.fromEntries(new URLSearchParams($scope.searchParams())));
        return $http({
          url: `${$scope.env.apiUrl}/wash/reservations_history.xlsx`,
          method: 'GET',
          params: options,
        }).then(() => alertService.show({
          text: $filter('translate')('email_popup.success'),
        }));
      });
    };
  },
);
