angular.module('RocketWash').factory('FinancialProject', (
  $http, railsResourceFactory, userSession, TenantSwitchInterceptor,
) => {
  const Resource = railsResourceFactory({
    url: (context) => { return "/" + (context.queryRole || userSession.controllerScope()) + "/financial_projects/" + (context.id || '') },
    name: 'financial_project',
  });

  Resource.queryAll = function(queryParams, context) {
    return $http({
      url: `${this.resourceUrl(context)}/index_all`,
      params: queryParams,
      method: 'GET'
    }).then(function(response) {
      return response.data;
    });
  }

  Resource.addInterceptor(TenantSwitchInterceptor);

  return Resource;
});

