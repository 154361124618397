angular.module('RocketWash').directive('rwMaskedDatetime', (userSession) => {
  const getFormatString = (format) => {
    switch (format) {
    case undefined: return 'YYYY-MM-DD HH:mm:ss';
    case 'day': return 'DD.MM.YY';
    case 'time': return 'HH:mm';
    case 'daytime': return 'HH:mm DD.MM.YY';
    default: return format;
    }
  }

  return {
    restrict: 'E',
    templateUrl: 'directives/rw-masked-datetime/rw-masked-datetime.slim',
    require: 'ngModel',
    scope: {
      formatString: '=format',
      ngDisabled: '=',
    },
    link: (scope, element, _attrs, ngModelController) => {
      const formatString = getFormatString(scope.formatString);
      const format = formatString.replace(/[^\w]/g, '');
      scope.mask = formatString.replace(/[\w]/g, '9');

      ngModelController.$formatters.push((value) => {
        if (value) {
          return moment(value).format(format);
        } else {
          return null;
        }
      });

      ngModelController.$parsers.push((value) => {
        const tmp = moment(value);
        const current = moment(ngModelController.$modelValue);
        const zone = userSession.time_zone.utc_offset / 3600;
        if (scope.formatString == 'day') {
          return moment.utc({
            years: tmp.year(),
            months: tmp.month(),
            date: tmp.date(),
            hours: current.hours() || 0,
            minutes: current.minutes() || 0,
          }).utcOffset(zone).subtract(zone, 'hours');
        } else if (scope.formatString == 'time') {
          return moment.utc({
            years: current.year() || 2000,
            months: current.month(),
            date: current.date() || 1,
            hours: tmp.hours(),
            minutes: tmp.minutes(),
          }).utcOffset(zone).subtract(zone, 'hours');
        } else {
          return tmp;
        }
      });

      ngModelController.$render = () => {
        scope.rwValue = ngModelController.$viewValue;
      };

      scope.update = () => {
        const result = moment(scope.rwValue, format);
        if (result.isValid()) {
          ngModelController.$setViewValue(result);
          scope.lastSavedValue = scope.rwValue;
        } else {
          scope.rwValue = scope.lastSavedValue;
        }
      };
      element.find('input').on('keypress', (event) => {
        if (event.which === 13) {
          scope.update();
        }
      });
    },
  };
});
