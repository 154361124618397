angular.module('RocketWash').service('reservationFormServices', ($injector, rwApi, userSession) => {

  const service = {
    all: null,
    total: null,
    selected: [],
    searchText: null,

    assignState: (services) => {
      service.selected = services;
      service.calculate();
    },

    search: ({query}) => {
      const rf = $injector.get('reservationForm');

      return rwApi.sendRequest({
        method: 'POST',
        path: 'reservation_form/services/search',
        data: {
          service_location_id: userSession.service_location_id,
          car_id: rf.carContractor.car.id,
          cars_car_types: rf.carContractor.car.cars_car_types,
          query: query,
        }
      });
    },

    isSelected: (serv) => {
      return !!service.selected.find((s) => s.id == serv.id);
    },

    selectedCountForCategory: (category) => {
      const count = service.selected.filter((s) => s.category == category).length;
      if (count > 0) {
        return `(${count})`;
      } else {
        return '';
      }
    },

    calculate: _.debounce(() => {
      const rf = $injector.get('reservationForm');

      return rwApi.sendRequest({
        method: 'POST',
        path: 'reservation_form/services/calculate',
        data: {
          service_location_id: userSession.service_location_id,
          service_location_lane_id: rf.r().service_location_lane_id,
          time_start: rf.r().time_start,
          services: service.selected,
        }
      }).then((data) => {
        service.total = data;
        if (data.duration > rf.r().full_duration && !rf.r().time_end) {
          if(!rf.r().isManullyChanged){
            rf.r().full_duration = data.duration
          }
        }
      });
    }, 100),

    loadAll: ({force} = {force: false}) => {
      const rf = $injector.get('reservationForm');

      if (!service.all || force) {
        return rwApi.sendRequest({
          method: 'POST',
          path: 'reservation_form/services/all',
          data: {
            service_location_id: userSession.service_location_id,
            car_id: rf.carContractor.car.id,
            contractor_id: rf.carContractor.car.contractor.id,
            cars_car_types: rf.carContractor.car.cars_car_types
          }
        }).then((services) => {
          service.all = services;
        });
      } else {
        return Promise.resolve();
      };
    },

    isValid: () => {
      const servicesSelected = service.selected.length > 0;

      return servicesSelected;
    },

    toggle: (s) => {
      if(service.isSelected(s)) {
        service.remove(s);
      } else {
        service.add(s.id);
      };
    },

    add: (serviceId) => {
      if (service.selected.map(s => s.id).includes(serviceId)) {
        return;
      };

      const rf = $injector.get('reservationForm');

      return rwApi.sendRequest({
        method: 'POST',
        path: 'reservation_form/services',
        data: {
          id: serviceId,
          service_location_id: userSession.service_location.id,
          contractor_id: (rf.carContractor.car.contractor && rf.carContractor.car.contractor.id),
          cars_car_types: rf.carContractor.car.cars_car_types,
        }
      }).then((s) => {
        s.count = 1;
        service.selected.push(s);
        service.servicesChanged();
      });
    },

    remove: (s) => {
      service.selected = _.reject(service.selected, (serv) => serv.id == s.id);
      service.servicesChanged();
    },

    fullServicesDuration: () => {
      const fullDuration = service.selected.map((s) => s.count * s.duration).reduce((a, b) => a + b, 0);

      const rf = $injector.get('reservationForm');
      if (!rf.r().time_end){
        rf.r().full_duration = fullDuration;
      }

      return fullDuration;
    },

    prepareForSave: () => {
      return service.selected.map((s) => {
        return {
          id: s.id,
          price: s.price,
          count: s.count,
          discount: s.discount,
        };
      });
    },

    servicesChanged: () => {
      const rf = $injector.get('reservationForm');
      rf.r().full_duration = service.selected.map((s) => s.count * s.duration).reduce((a,b) => a+b, 0);
    },

    reset: () => {
      service.all = null;
      service.total = null;
      service.selected = [];
      service.searchText = null;
    },

    reassign: () => {
      const rf = $injector.get('reservationForm');

      if (_.get(rf.carContractor, 'car.car_model_id')) {
        const selectedServices = service.selected;
        service.reset();
        (selectedServices || []).forEach((s) => {
          service.add(s.id);
        });
      }
    },

    updateDiscounts: (discount) => {
      service.selected.forEach((s) => {
        service.updateDiscount(s, discount);
      });
    },

    updateDiscount: (s, discount) => {
      s.discount = Math.min(discount, s.max_discount_percentage);
    },

  };

  return service;
});
