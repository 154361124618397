angular.module('RocketWash').controller(
  'WashSettingsCarModelsController',
  ($q, $scope, userSession, CarMake, CarModel, ctSelectService, ctgSelectService, POPULAR_CAR_MODEL_IDS, POPULAR_CAR_MAKE_IDS, alertService) => {
    $scope.selected.typeId = 'all';
    $scope.selected.popular = false;

    $scope.carTypeName = (model) => {
      const carType = _.get(ctSelectService, 'items', [])
        .find(ct => parseInt(ct.id, 10) === parseInt(model.carTypeId, 10));
      return carType && carType.name;
    };
    $scope.changeSelected = (type) => {
      let promisses = [];

      alertService.show({
        text: 'Изменениия сохраняются. Пожалуйста, подождите',
      });

      $scope.selectedModels().forEach((model) => {
        model.carTypeId = type.id;
        promisses.push(model.save());
      });
      $q.all(promisses).then(() => {
        $scope.reloadCarMakes();
        $scope.type = undefined;
        alertService.show({
          text: 'Изменения успешно сохранены',
        });
      });
    };
    $scope.selectedModels = () => {
      const results = [];
      ($scope.carMakes || []).forEach((make) => {
        (make.carModels || []).forEach((model) => {
          const typeEq = $scope.selected.typeId == model.carTypeId
          const typeAll = $scope.selected.typeId == 'all'
          const typeEqUnassigned = $scope.selected.typeId == 'unassigned' && !model.carTypeId
          if (model.selected && (typeEq || typeEqUnassigned || typeAll)) {
            results.push(model);
          }
        });
      });
      return results;
    };
    $scope.selectMake = (make) => {
      const callback = () => {
        make.carModels.forEach((model) => {
          model.selected = make.selected;
        });
      };
      if (make.carModels) {
        callback();
      } else {
        $scope.loadMake(make, callback);
      }
    };
    $scope.addCar = (carMake) => {
      const newModel = new CarModel({
        carMakeId: carMake.id,
        carTypeId: $scope.selected.type.id,
      });
      if (carMake.carModels !== null) {
        carMake.carModels.unshift(newModel);
      } else {
        $scope.loadMake(carMake, () => {
          carMake.carModels = carMake.carModels || [];
          carMake.carModels.unshift(newModel);
        });
      }
    };
    $scope.$watch('[selected.typeId]', (carTypeId) => {
      $scope.reloadCarMakes();
    }, true);

    $scope.$on('ctSelectService-selected', () => {
      $scope.reloadCarMakes();
    });

    $scope.reloadCarMakes = () => {
      if (ctgSelectService.selected) {
        const carTypeId = $scope.selected.typeId;
        const carTypesGroupId = ctgSelectService.selected.id;

        const parsedCarTypeId = (carTypeId !== 'unassigned' && carTypeId !== 'all') ? carTypeId : null;
        const parsedUnassigned = carTypeId === 'unassigned' ? true : null;
        CarMake.query({
          car_type_id: parsedCarTypeId,
          car_types_group_id: carTypesGroupId,
          unassigned: parsedUnassigned,
          popular: $scope.selected.popular,
        }).then((data) => {
          let carMakes = _.sortBy(data, 'name');
          if ($scope.selected.popular) {
            carMakes = carMakes.filter(cm => POPULAR_CAR_MAKE_IDS.includes(cm.id));
          }
          $scope.carMakes = carMakes;
        });
      }
    };
    $scope.save = (model) => {
      model.save();
    };
    $scope.toggleMake = (make) => {
      if (make.opened) {
        make.opened = false;
      } else {
        make.opened = true;
        $scope.loadMake(make);
      }
    };
    $scope.showImages = (make, model) => {
      if (!model.images) {
        model.searchImages().then((images) => {
          model.imagesLoaded = true;
          model.images = images;
        });
      };
      model.showImages = true;
    };
    $scope.hideImages = (make, model) => {
      model.showImages = false;
    };
    $scope.loadMake = (make, callback) => {
      if (make.carModels) {
        return;
      }
      const carTypesGroupId = ctgSelectService.selected.id;
      CarMake.get(make.id, {car_types_group_id: carTypesGroupId}).then((data) => {
        data.carModels = data.carModels.map(cm => new CarModel(cm));
        data.carModels = _.sortBy(data.carModels, 'name');
        if ($scope.selected.popular) {
          data.carModels = data.carModels.filter(cm => POPULAR_CAR_MODEL_IDS.includes(cm.id));
        }
        angular.extend(make, data);
        if (typeof callback === 'function') {
          callback();
        }
      });
    };
  },
);
