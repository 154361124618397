import { env } from '@env';

angular.module('RocketWash').service(
  'userSession',
  ($http, $interval, $rootScope, $q, $state, $location, $uibModal, $window, alertService) => {
    const HOME_STATES = {
      admin: 'app.admin.organizations.dashboard',
      rw_operator: 'app.admin.online_reservations',
      support_officer: 'app.admin.online_reservations',
      organization_director: 'app.wash.dashboard.control_panel',
      director: 'app.wash.dashboard.control_panel',
      accountant: 'app.wash.dashboard.control_panel',
      category_3: 'app.wash.dashboard.control_panel',
      category_2: 'app.wash.dashboard.control_panel',
      category_1: 'app.wash.dashboard.control_panel',
      partner: 'app.partner.organizations.dashboard',
      taxi: 'app.taxi.analytics.general',
    };
    const EMPLOYEE_ROLES = [
      'organization_director',
      'director',
      'accountant',
      'category_3',
      'category_2',
      'category_1',
      'support_officer',
    ];
    const SCOPES = {
      admin: 'admin',
      rw_operator: 'admin',
      support_officer: 'admin',
      organization_director: 'wash',
      director: 'wash',
      accountant: 'wash',
      category_3: 'wash',
      category_2: 'wash',
      category_1: 'wash',
      partner: 'partner',
      taxi: 'taxi',
      unauthorized_client: 'client_cabinet',
    };
    const storedUserSession = {};
    window.storedUserSession = storedUserSession;

    const loadUserSession = function () {
      const deffered = $q.defer();
      storedUserSession.promise = deffered.promise;

      $http.get('/user_session').then((session) => {
        angular.extend(storedUserSession, session.data);
        storedUserSession.notifications.wrong_timezone = !storedUserSession.timezoneCorrect();

        if (
          ($location.$$url.search(/\/sign_in/) >= 0 || $location.$$url === '/') &&
          storedUserSession.isSignedIn()
        ) {
          let state = storedUserSession.homeState();
          console.log('user_session: trying to go to:', state);
          storedUserSession.goHomeState();
        }

        const userUid = `org_id_${storedUserSession.organization && storedUserSession.organization.id}___user_id_${storedUserSession.id}`;

        // FullStory
        if (window.FS && window.FS.identify && !storedUserSession.anonymous) {
          window.FS.identify(userUid, {
            displayName: storedUserSession.name,
            email: storedUserSession.email,
            phone_str: storedUserSession.phone,
            userId_int: (storedUserSession.id || 0),
            organizationId_int: (storedUserSession.organization && storedUserSession.organization.id || 0),
            serviceLocationId_int: (storedUserSession.service_location_id || 0),
            roles_str: storedUserSession.roles.map((role) => { return role.name }).join(' '),
            timezone_str: (storedUserSession.time_zone && storedUserSession.time_zone.name),
          });
        };

        // CarrotQuest
        if (window.carrotquest && carrotquest.identify) {
          carrotquest.identify({
            by_user_id: true,
            $user_id: userUid,
            $name: storedUserSession.name,
            $email: storedUserSession.email,
            $phone: storedUserSession.phone,
            user_id: (storedUserSession.id || 0),
            organization: (storedUserSession.organization && storedUserSession.organization.name),
            organization_id: (storedUserSession.organization && storedUserSession.organization.id || 0),
            serviceLocation: (storedUserSession.service_location && storedUserSession.service_location.name),
            serviceLocation_id: (storedUserSession.service_location_id || 0),
            roles: storedUserSession.roles.map((role) => { return role.name }),
            timezone: (storedUserSession.time_zone && storedUserSession.time_zone.name),
          });
        };

        $rootScope.$emit('userSession:loaded', storedUserSession);
        deffered.resolve(storedUserSession);
        const safeOrgId =
          storedUserSession.organization &&
          storedUserSession.organization.id;
        const safeSlId =
          storedUserSession.service_location &&
          storedUserSession.service_location.id;
        console.log(`DEBUG: userSession loaded: organization_id: ${safeOrgId}, service_location_id: ${safeSlId}`);
      }, () => {
        deffered.reject('Can not load user session!');
      }).finally(() => {
        storedUserSession.loaded = true;
      });

      angular.extend(storedUserSession, {
        reload: loadUserSession,
        isSignedIn() {
          return !!this.session_id;
        },
        isConsolidated() {
          const serLoc = this.service_location;
          const org = serLoc && serLoc.organization;
          return org && org.consolidated;
        },
        is(role) {
          return this.roles && this.roles.some(x => x.name === role);
        },
        getCashierName() {
          let customName = this.settings.custom_cashier_name_for_checks;
          if (customName) {
            return customName;
          } else {
            return this.name;
          }
        },
        getKkmserverSublicense() {
          return this.kkmserver_sublicense;
        },
        getServiceLocationName() {
          const serLoc = this.service_location;
          return serLoc && serLoc.name;
        },
        getAgreementNumber() {
          const serLoc = this.service_location;
          return serLoc && serLoc.agreement_number;
        },
        getName() {
          return this.name || 'no-name';
        },
        getRole() {
          if ($state.current.name.match(/client_cabinet/)) {
            return 'unauthorized_client'
          }
          const roles = this.roles;
          const role = roles && roles[0];
          return role && role.name;
        },
        getJobName() {
          if (!this.service_location) {
            return null;
          }
          const job = this.jobs.find(x => x.service_location_id === this.service_location.id);
          return job && job.name;
        },
        getRoundingStep() {
          return this.service_location.rounding_step;
        },
        additionalFeatures() {
          return this.service_location.additional_daily_paid_features;
        },
        hasAdditionalFeature(feature) {
          return this.additionalFeatures()[feature] !== undefined;
        },
        allStateNames() {
          const TOP_LEVEL_STATES = [
            'dashboard', 'income', 'finance', 'services', 'counterparties',
            'charts', 'settings', 'organization',
          ];
          const results = TOP_LEVEL_STATES.map((stateName) => {
            const state = $state.get(`app.wash.${stateName}`);
            const tabs = state && state.data && state.data.tabs || [];
            return tabs.map(tab => `app.wash.${stateName}.${tab}`);
          }).reduce((x, y) => x.concat(y));
          return results;
        },
        permittedStateNames() {
          return this.allStateNames().filter((stateName) => {
            const state = $state.get(stateName);
            if (!state) {
              return false;
            }
            const permission = state.data && state.data.permission;
            return !permission || this.can('visit', permission) || this.is('admin') || this.superuser;
          });
        },
        employeeHomeState() {
          return this.permittedStateNames()[0] || HOME_STATES[this.getRole()];
        },
        isTaxi() {
          return storedUserSession.organization && storedUserSession.organization.is_taxi;
        },
        isSupportOfficer() {
          return storedUserSession.getRole() === 'support_officer';
        },
        homeState() {
          if (this.isSupportOfficer()) {
            return HOME_STATES['support_officer'];
          }

          if (this.isTaxi()) {
            return HOME_STATES['taxi'];
          }

          if (EMPLOYEE_ROLES.includes(this.getRole())) {
            return this.employeeHomeState();
          }

          return HOME_STATES[this.getRole()];
        },
        controllerScope() {
          if (this.isTaxi()) {
            return SCOPES['taxi'];
          } else {
            return SCOPES[this.getRole()];
          }
        },
        signOut() {

          $uibModal.open({
            templateUrl: 'pages/common/confirmation-popup/index.slim',
            controller: 'CommonConfirmationPopupController',
            resolve: {
              config: {
                title: 'Выход из аккаунта',
                text: `Вы уверены, что хотите выйти из аккаунта?`,
                buttons: [
                  {
                    text: 'Нет',
                    class: 'btn blue-btn fix-btn',
                    click: (scope) => {
                      scope.$dismiss();
                    }
                  },
                  {
                    text: 'Да',
                    class: 'right btn white-btn fix-btn',
                    click: (scope) => {
                      this.sesion_id = null;
                      scope.$dismiss();
                      return $http.get('/session/sign_out').then(() => {
                        $state.go('sign_in');
                        console.log('logged out');
                      });
                    }
                  },
                ]
              },
            },
          });
        },
        goHomeState() {
          return $state.go(this.homeState());
        },
        isOrganizationMode() {
          return !this.service_location_id;
        },
        isEditionForDatePrevented(date) {
          const hoursCount =
            parseInt(this.settings.prevent_changes_for_data_older_than_hours, 10);
          return (
            hoursCount > 0 &&
            moment(date) < moment().subtract(hoursCount, 'hours')
          );
        },
        can(action, subjectClass) {
          return (this.permissions || [])
            .some(p => p.action === action && p.subject_class === subjectClass);
        },
        notificationsCount() {
          return Object.values(this.notifications).map(x => (x ? 1 : 0)).reduce((x, y) => x + y, 0);
        },

        useKKMServerAcquiring() {
          return this.settings.use_kkm_server_acquiring === 'yes';
        },
        usePreReservations() {
          return this.settings.use_pre_reservations === 'yes';
        },
        preReservationDefaultDuration() {
          return this.settings.pre_reservation_default_duration;
        },
        preReservationTimeStartRoundTo() {
          return this.settings.pre_reservation_time_start_round_to;
        },

        useComplexSalaryCalculationWithEmployeeParticipations() {
          return this.settings.use_complex_salary_calculation_with_employee_participations === 'yes';
        },
        setEmployeesManuallyByDefault() {
          return this.settings.set_employees_manually === 'yes';
        },
        useShiftsAccounting() {
          return this.settings.use_shifts_accounting === 'yes';
        },
        useDiscountProgram() {
          return this.settings.use_discount_program === 'yes';
        },
        allowToSetDiscountManually() {
          return this.settings.allow_to_set_discount_manually === 'yes';
        },
        useImmutablePrices() {
          return this.settings.use_immutable_prices === 'yes';
        },
        useTagsAnalytics() {
          return this.settings.use_tags_analytics === 'yes';
        },
        useModuleKassa() {
          return this.settings.use_module_kassa === 'yes';
        },
        dailyLanePrice() {
          return this.service_location.plan.settings.daily_lane_price;
        },
        planSettings() {
          return this.service_location.plan.settings;
        },
        timezoneCorrect() {
          if (!this.service_location) {
            return true;
          }
          return moment().utcOffset() * 60 === this.time_zone.utc_offset;
        },
        shouldBlock() {
          if (this.service_location) {
            return (
              !this.superuser &&
              this.service_location.blocked &&
              this.is('organization_director')
            );
          }
        },
        useBulkNotifications() {
          return this.service_location.plan.settings.notifications_bulk_delivery;
        },
        pretend({role, partnerId, organizationId, serviceLocationId, inNewTab = false}) {
          let params = {role: role};
          if (role == "partner") {
            params['user_id'] = partnerId;
          } else {
            params["organization_id"] =  organizationId;
            params["service_location_id"] = serviceLocationId;
          }
          $http({
            method: 'GET',
            url: '/admin/users/pretend',
            params: params,
          }).then((response) => {
            let newDomain = '';
            if (response.data.subdomain_name == "partner") {
              newDomain = `${response.data.subdomain_name}.${response.data.user_id}.${$location.host().split('.').splice(1).join('.')}`;
            } else {
              newDomain = `${response.data.subdomain_name}.${$location.host().split('.').splice(1).join('.')}`;
            };
            const newUrl = `${$location.protocol()}://${newDomain}:${$location.port()}/`;

            if (inNewTab) {
              let win = $window.open(newUrl, '_blank');
              win.focus();
            } else {
              $window.location.href = newUrl;
            };
          });
        },
      });
    };

    loadUserSession();

    return storedUserSession;
  },
);
