import { env } from '@env';

angular.module('RocketWash').controller(
  'WashSettingsMobileSettingsSlPhotosPopupController',
  (
    $q, $scope, $route, $location, $window, $timeout, $translate, $http, alertService, Upload,
    userSession, ServiceLocation, SettingValue, serverConstants, Organization, Photo,
  ) => {

    const reloadPhotos = () => {
      const query = {
        owner_type: 'ServiceLocation',
        owner_id: userSession.service_location.id,
      };
      Photo.query(query).then((photos) => {
        $scope.photos = photos;
      });
    };
    reloadPhotos();

    $scope.delete = (photo) => {
      photo.delete().then(() => {
        alertService.show({
          text: 'Фотография успешно удалена!',
        });
        reloadPhotos();
      });
    };

    $scope.save = () => {
      $scope.$dismiss();
    }

    $scope.upload = (files, resource) => {
      // File did not pass validation
      if (files.length == 0) {
        alertService.show({
          text: $translate.instant('settings.import.file_is_invalid'),
          type: 'error'
        });
        return;
      }

      const promises = files.map((file) => {
        return Upload.upload({
          url: `${env.apiUrl}wash/photos`,
          data: { photo:
            {image: file, owner_type: 'ServiceLocation', owner_id: userSession.service_location.id}
          },
          withCredentials: true,
        });
      });

      $q.all(promises).then(() => {
        reloadPhotos();
        alertService.show({
          text: 'Все фотографии успешно загружены!',
        });
      });
    };
  },
);
