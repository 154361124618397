angular.module('RocketWash').controller(
  'WashCounterpartiesSMSController',
  ($scope, $http, $state, Sms, SettingValue, userSession, serverConstants) => {
    $scope.pds = $scope.pageDataService.new({ page: 'counterparties' });
    $scope.pg = $scope.pds.pg;
    if (!$scope.pg) {
      $state.go('app.wash.counterparties.individuals');
    }

    $scope.recepientsCount = $scope.pg.selectAll ?
      $scope.pg.total.count :
      $scope.pg.selectedKeys.length;
    $scope.message = { text: '' };

    Sms.$get('wash/sms/balance').then((data) => {
      $scope.balance = data.balance;
      $scope.useCustomSmsGateway = data.useCustomSmsGateway;
      $scope.customSmsGatewayBalance = data.customSmsGatewayBalance;
    });
    SettingValue.query().then((values) => {
      const smsSign = values.find(v => v.key === 'sms_sign').value || '';
      $scope.message.text = `\n${smsSign}`;
      $scope.smsBalance = Math.floor($scope.balance / userSession.organization.sms_price);
    });

    $scope.send = () => {
      const options = {
        message: $scope.message.text,
        customer_ids: $scope.pg.selectedKeys,
        select_all: $scope.pg.selectAll,
        filters: JSON.stringify($scope.pds.selectedFilters.map((filter) => {
          delete filter.filterGroup;
          return filter;
        })),
      };
      $scope.saveDisabled = true;
      $http.post('/wash/notifications/bulk', options).finally(() => {
        $scope.saveDisabled = false;
        $state.go('app.wash.counterparties.individuals');
      });
    };

    $scope.messagesCount = () => (
      Math.max(Math.ceil($scope.message.text.length / serverConstants.single_sms_size), 1)
    );
    $scope.totalMessages = () => (
      $scope.messagesCount() * $scope.recepientsCount
    );
  },
);
