

angular.module('RocketWash').controller('AdminSettingsCountryPhoneCodesBaseController', function($rootScope, $scope, $state, $translate, viewStore, CountryPhoneCode) {
  var updateCodes;
  var values = [true, false]
  $scope.providers = ['twilio', 'sms_aero', 'brand_sms', 'smsc', 'turbo_sms', 'empty'];
  (updateCodes = function() {
    return CountryPhoneCode.query().then(function(data) {
      return $scope.codes = _.map(data, function(o) {
        return new CountryPhoneCode(o);
      });
    });
  })();
  return $scope.save = function(code) {
    return code.save();
  };
});
