angular.module('RocketWash').controller(
  'AdminOrganizationsSettingsGeneralController',
  ($scope, $stateParams, Organization) => {
    Organization.get($stateParams.orgId).then((org) => {
      $scope.organization = org;
    });
    $scope.save = () => {
      $scope.organization.save();
    };
  },
);
