angular.module('RocketWash').constant('PREDEFINED_FILTERS_FOR_INTEGRATIONS_RESERVATIONS', {
  online_reservations: [
    {
      name: 'Только Яндекс Карты',
      filters: [
        {
          custom: true,
          condition: 'custom__only_yandex_maps_reservations',
        },
      ],
    },
    {
      name: 'Только Яндекс Заправки',
      filters: [
        {
          custom: true,
          condition: 'custom__only_ya_fuel_online_reservations',
        },
      ],
    },
    {
      name: 'Только Carbox',
      filters: [
        {
          custom: true,
          condition: 'custom__only_carbox_reservations',
        },
      ],
    },
    {
      name: 'Только Лейка',
      filters: [
        {
          custom: true,
          condition: 'custom__only_leyka_reservations',
        },
      ],
    },
  ],
});
