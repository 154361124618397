angular.module('RocketWash').controller('WashOrganizationStatisticsController', (
  $scope, ServiceLocation, Plan, TimeZone, alertService, $translate,
) => {
  $scope.serviceLocations = [];
  $scope.plans = [];

  ServiceLocation.query().then((serviceLocations) => {
    $scope.serviceLocations = serviceLocations;
  });

  Plan.query().then((plans) => {
    $scope.plans = _.sortBy(plans, 'ordinal');
  });

  $scope.planName = (planId) => {
    const plan = $scope.plans.find(x => x.id === planId);
    return plan && plan.name;
  };

  TimeZone.query().then((timeZones) => {
    $scope.timeZones = timeZones;
  });

  $scope.save = (sl) => {
    sl.save().then(() => {
      alertService.show({
        text: $translate.instant('settings.wash_profile.saved_successfully'),
        type: 'success',
      });
    });
  };
});
