angular.module('RocketWash').controller(
  'WashServicesBaseController',
  ($scope, userSession, CarType, CarTypesGroup, PriceList, ctgSelectService, ctSelectService) => {
    $scope.ctgSelectService = ctgSelectService;
    $scope.ctSelectService = ctSelectService;
    $scope.getServiceCTGCategories = (services) => {
      $scope.categories = [...new Set(services.map((s)=> {
        return `${s.carTypesGroup.name} -> ${s.category || '-'}`
      }))];
      const groups = _.groupBy(services, service => `${service.carTypesGroup.name} -> ${service.category || '-'}` || '');
      const groupsArray =
        Object.entries(groups).map(([groupName, groupServices]) => [
          groupName,
          _.sortBy(groupServices, (s) => {
            return s.name && s.name.toLowerCase();
          }),
        ]);
      return _.sortBy(groupsArray, g => g[1][0].carTypesGroup.order || 0);
    };

    $scope.getServiceCategories = (services) => {
      $scope.categories = [...new Set(services.map((s)=> {
        return s.category
      }))];
      const groups = _.groupBy(services, service => service.category || '');
      const groupsArray =
        Object.entries(groups).map(([groupName, groupServices]) => [
          groupName,
          _.sortBy(groupServices, (s) => {
            return s.name && s.name.toLowerCase();
          }),
        ]);
      return _.sortBy(groupsArray, g => g[0].toLowerCase());
    };

    $scope.groupExpanded = (groupServices) => {
      return (groupServices || []).every(x => x.expanded);
    };
    $scope.toggleGroup = (groupServices) => {
      const newValue = !$scope.groupExpanded(groupServices);
      (groupServices || []).forEach((x) => {
        x.expanded = newValue;
      });
    };

    $scope.canExport = (permission) => {
      return userSession.can('export', permission)
             || userSession.is('admin') || userSession.is('organization_director');
    }

    $scope.selected = {
      ctg: null
    };

    $scope.reloadPriceLists = () => {
      return PriceList.all().then((data) => {
        data = data.filter(x => !x.warehouse && x.carTypesGroupId == $scope.selected.ctg.id);
        $scope.priceLists = _.sortBy(data, x => x.name);
        $scope.selected.priceList = $scope.priceLists.find((pl) => pl.default);
        $scope.defaultPriceList = $scope.priceLists.find((pl) => pl.default);
      });
    };
  },
);
