angular.module('RocketWash').controller(
  'WashSettingsSecurityIndexController',
  (
    $scope, $route, $location, $window,
    userSession, SettingValue, serverConstants, Organization,
  ) => {
    const reloadSettingValues = () => {
      SettingValue.query().then((settings) => {
        $scope.settings = settings.filter(x => x.keyType === 'SecuritySettingKey');

        const wlEnabled = settings.find(x => x.key === 'ip_whitelist_enabled');
        const wl = settings.find(x => x.key === 'ip_whitelist');

        $scope.securitySettings = [wlEnabled, wl];
      });
    };
    reloadSettingValues();

    $scope.save = function (setting) {
      setting.save().then(() => {
        $route.reload();
        reloadSettingValues();
      });
    };
  },
);
