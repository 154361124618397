angular.module('RocketWash').controller('ClientCabinetChartsContractorsController', (
  $scope, $q, $filter, $translate, $controller, Customer, ServiceLocation, userSession,
) => {

  $controller('WashChartsContractorsController', {$scope: $scope});

  $scope.exportXLS = () => {
    window.open(`${$scope.env.apiUrl}/client_cabinet/charts/contractors_report.xlsx?personal_cabinet_uuid=${userSession.personal_cabinet_uuid}&${$.param($scope.pds.pg.query)}`, '_blank');
  };

  $scope.showImages = (item) => {
    item.showImage = true;
  };
  $scope.hideImages = (item) => {
    item.showImage = false;
  };

  $scope.pds.pg.url = 'client_cabinet/charts/contractors_report';

});
