angular.module('RocketWash').filter('humanizeDuration', function ($translate) {
  return function (minutes) {
    var d, days, hours, months, ref, years;
    minutes = parseInt(minutes);
    d = moment.duration(minutes, 'minutes');
    ref = [d.years(), d.months(), d.days(), d.hours(), d.minutes()], years = ref[0], months = ref[1], days = ref[2], hours = ref[3], minutes = ref[4];
    if (years > 0 && months > 0) {
      return $translate.instant('format.duration.yearsAndMonths', {
        years: years,
        months: months
      });
    }
    if (years > 0) {
      return $translate.instant('format.duration.years', {
        years: years
      });
    }
    if (months > 0 && days > 0) {
      return $translate.instant('format.duration.monthsAndDays', {
        months: months,
        days: days
      });
    }
    if (months > 0) {
      return $translate.instant('format.duration.months', {
        months: months
      });
    }
    if (days > 0 && hours > 0) {
      return $translate.instant('format.duration.daysAndHours', {
        days: days,
        hours: hours
      });
    }
    if (days > 0) {
      return $translate.instant('format.duration.days', {
        days: days
      });
    }
    if (hours > 0 && minutes > 0) {
      return $translate.instant('format.duration.hoursAndMinutes', {
        hours: hours,
        minutes: minutes
      });
    }
    if (hours > 0) {
      return $translate.instant('format.duration.hours', {
        hours: hours
      });
    }
    if (minutes > 0) {
      return $translate.instant('format.duration.minutes', {
        minutes: minutes
      });
    }
    return $translate.instant('format.duration.fallback');
  };
});
