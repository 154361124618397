angular.module('RocketWash').filter('translateFilterScope', function($translate) {
  return function(scopeName, page) {
    let common = $translate.instant('filters.scope.common.' + scopeName);
    let pageSpecific = $translate.instant('filters.scope.' + page + '.' + scopeName);
    let string = _.chain([pageSpecific, common]).reject((translation) => {
      return translation.search('filters.scope.') == 0; // Reject missing translations
    }).first().value();
    return string;
  };
});
