angular.module('RocketWash').controller(
  'AdminOrganizationsSettingsReindexController',
  (
    $scope, $translate, $stateParams,
    Organization, PrintableDocument, TimeZone, Plan, $filter, alertService, dateRanges,
  ) => {
    $scope.email = null;
    $scope.from = dateRanges.day()[0].toDate();
    $scope.to = dateRanges.day()[1].toDate();

    Organization.get($stateParams.orgId).then((org) => {
      $scope.org = org;
    });

    $scope.exportCustomers = () => {
      $scope.org.exportCustomers({
        email: $scope.email,
      }).then(() => {
        alertService.show({
          text: $translate.instant('organizations.nested_settings.export.started'),
        });
      });
    };

    $scope.exportCustomersFinancialDataForPeriod = () => {
      $scope.org.exportCustomersFinancialDataForPeriod({
        email: $scope.email,
        from: $scope.from,
        to: $scope.to,
      }).then(() => {
        alertService.show({
          text: $filter('translate')('organizations.nested_settings.export.started'),
        });
      });
    };
  },
);
