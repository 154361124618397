import { env } from '@env';

// For cleaner code enforcing string ids, with magic values:
// unassigned, income, outcome

angular.module('RocketWash').controller('WashFinanceFinancialPlanningBaseController', (
  $scope, $http, $translate, dateRangeStorage, FinanceClause,
  resolvedFinanceClauses, FinancialCenter, $state,
) => {
  $scope.fcList = [];
  $scope.saldosAdded = false;
  $scope.financialCenters = [];
  $scope.selected = {
    grouping: 'month'
  };
  $scope.cashShiftItems = {
    grouping: ''
  };
  $scope.showCashShift = false;
  $scope.cashShiftId = undefined;

  $scope.groupingOptions = [{
    id: 'month',
    name: 'По месяцам'
  }, {
    id: 'week',
    name: 'По неделям'
  }, {
    id: 'day',
    name: 'По дням'
  },{
    id: 'cash_shift',
    name: 'По кассовым сменам'
  }];

  $scope.groupingSelected = () => {
    if ($scope.selected.grouping === 'cash_shift'){
      $scope.showCashShift = true;
      if (typeof $scope.selected.cashShiftItems === 'number'){
        $scope.cashShiftId = $scope.selected.cashShiftItems;
        $scope.selected.grouping = 'cash_shift';
        $scope.updateStats().then(prepareStatsData);
      } else {
        $scope.cashShiftId = $scope.cashShifts[0].id;
        $scope.selected.cashShiftItems = $scope.cashShifts[0].id;
        $scope.selected.grouping = 'cash_shift';
        $scope.updateStats().then(prepareStatsData);
      }
    } else {
      $scope.cashShiftId = undefined;
      $scope.selected.cashShiftItems = '';
      $scope.showCashShift = false;
      $scope.updateStats().then(prepareStatsData);
    }
  };
  const forOrganization = $state.current.name === 'app.wash.organization.financial_planning';

  $http({
    url: `${env.apiUrl}/wash/financial_planning/financial_centers`,
    method: 'GET',
    params: { for_organization: forOrganization },
  }).then((response) => {
    $scope.financialCenters = response.data.map(x => new FinancialCenter(x));
    $scope.financialCenters.forEach((x) => { x.selected = true; });

    if ($scope.userSession.taxi_ctg_enabled) {
      $scope.financialCenters.push($scope.userSession.organization.financial_center_taxi_debt_balance);
    }

    if ($scope.userSession.service_location.yandex_fuel_available) {
      $scope.financialCenters.push($scope.userSession.organization.financial_center_yandex_fuel_balance);
    }

    $scope.updateStats().then(prepareStatsData);
  });

  const addUnassignedClauses = (fcList) => {
    fcList.unshift(
      new FinanceClause({
        id: 'unassigned',
        name: $translate.instant('finance.financial_planning.not_distributed'),
        calculatedLevel: 1,
      }),
      new FinanceClause({
        id: 'unassigned_income',
        name: $translate.instant('finance.financial_planning.not_distributed_income'),
        ancestry: 'unassigned',
        calculatedLevel: 2,
      }),
      new FinanceClause({
        id: 'unassigned_outcome',
        name: $translate.instant('finance.financial_planning.not_distributed_outcome'),
        ancestry: 'unassigned',
        calculatedLevel: 2,
      }),
    );
  };

  const addSaldos = (fcList, saldos) => {
    fcList.unshift(...saldos.filter(x => x.type !== 'FinancialCenterRocketwashBalance').map((financialCenter) => {
      return {
        id: `saldo_before_${financialCenter.id}`,
        name: financialCenter.name,
        ancestry: 'saldos_before',
        calculatedLevel: 2,
      };
    }));
    fcList.unshift(
      new FinanceClause({
        id: 'saldos_before',
        name: 'Входящий остаток',
        calculatedLevel: 1,
      }),
    );

    fcList.unshift(...saldos.filter(x => x.type === 'FinancialCenterRocketwashBalance').map((financialCenter) => {
      return {
        id: `saldo_before_${financialCenter.id}`,
        name: financialCenter.name,
        ancestry: 'saldos_for_org_before',
        calculatedLevel: 2,
      };
    }));
    fcList.unshift(
      new FinanceClause({
        id: 'saldos_for_org_before',
        name: 'Входящий остаток внутреннего счёта',
        calculatedLevel: 1,
      }),
    );

    fcList.push(
      new FinanceClause({
        id: 'saldos_for_org_after',
        name: 'Исходящий остаток внутреннего счёта',
        calculatedLevel: 1,
      }),
    );
    fcList.push(...saldos.filter(x => x.type === 'FinancialCenterRocketwashBalance').map((financialCenter) => {
      return {
        id: `saldo_after_${financialCenter.id}`,
        name: financialCenter.name,
        ancestry: 'saldos_for_org_after',
        calculatedLevel: 2,
      };
    }));

    fcList.push(
      new FinanceClause({
        id: 'saldos_after',
        name: 'Исходящий остаток',
        calculatedLevel: 1,
      }),
    );
    fcList.push(...saldos.filter(x => x.type !== 'FinancialCenterRocketwashBalance').map((financialCenter) => {
      return {
        id: `saldo_after_${financialCenter.id}`,
        name: financialCenter.name,
        ancestry: 'saldos_after',
        calculatedLevel: 2,
      };
    }));

    fcList.push(new FinanceClause({
      id: 'revenue',
      name: 'Прибыль',
      calculatedLevel: 1,
    }));
  };

  const expandByDefault = (fcList) => {
    fcList.forEach((fc) => {
      fc.expanded = (fc.ancestry ? fc.ancestry.split('/') : []).length < 1;
    });
  };

  const markParents = (fcList) => {
    const isParent = {};
    fcList.forEach((fc) => {
      (fc.ancestry ? fc.ancestry.split('/') : [])
        .forEach((fcId) => { isParent[fcId] = true; });
    });
    fcList.forEach((fc) => { fc.isParent = isParent[fc.id]; });
  };

  const parseFcList = ((fcList) => {
    $scope.fcList = fcList;
    $scope.fcList.forEach((fc) => { fc.id = fc.id.toString(); });
    addUnassignedClauses($scope.fcList);
    expandByDefault($scope.fcList);
    markParents($scope.fcList);
  });

  parseFcList(resolvedFinanceClauses);

  $scope.allExpanded = () => {
    return $scope.fcList.every(x => x.expanded || !x.isParent);
  };

  $scope.toggleAll = () => {
    const newValue = !$scope.allExpanded();
    $scope.fcList.forEach((x) => {
      x.expanded = newValue;
    });
  };

  $scope.updateStats = function () {
    return $http({
      method: 'GET',
      url: `${env.apiUrl}/wash/financial_planning/stats_by_period_by_finance_clause`,
      params: {
        from: dateRangeStorage.from(),
        to: dateRangeStorage.to(),
        cash_shift_id: $scope.cashShiftId,
        grouping: $scope.selected.grouping,
        financial_center_ids: JSON.stringify(
          $scope.financialCenters.filter(x => x.selected).map(x => x.id),
        ),
      },
    }).then((response) => {
      $scope.statsData = response.data;
      if (Object.keys($scope.statsData).length === 0) {
        return;
      }
      $scope.cashShifts = response.data['cash_shifts'];
      delete response.data['cash_shifts'];
      if ($scope.saldosAdded) {
        return;
      }
      addSaldos($scope.fcList, Object.values($scope.statsData)[0].saldos_before);
      markParents($scope.fcList);
      $scope.saldosAdded = true;
    });
  };

  const prepareStatsData = () => {
    const stats = angular.copy($scope.statsData);
    $scope.columns = _.sortBy(
      Object.keys($scope.statsData),
      x => moment(x, 'DD.MM.YYYY').unix(),
    );
    $scope.fcList.forEach((fc) => {
      if (fc.ancestry) {
        const ancestors = fc.ancestry.split('/');

        Object.entries(stats).forEach(([_date, rootStatsHash]) => {
          rootStatsHash.assigned = rootStatsHash.assigned || {};
          const statsHash = rootStatsHash.assigned;
          const unassigned = rootStatsHash.unassigned || {};
          (rootStatsHash.saldos_before || []).forEach((financialCenter) => {
            statsHash[`saldo_before_${financialCenter.id}`] = financialCenter.amount_at;
          });
          (rootStatsHash.saldos_after || []).forEach((financialCenter) => {
            statsHash[`saldo_after_${financialCenter.id}`] = financialCenter.amount_at;
          });
          statsHash.unassigned_income = unassigned && unassigned[1];
          statsHash.unassigned_outcome = unassigned && unassigned[-1];
          statsHash.revenue = rootStatsHash.revenue || {};

          statsHash[fc.id] = statsHash[fc.id] || 0;
          ancestors.forEach((fcId) => {
            statsHash[fcId] = (statsHash[fcId] || 0) + parseFloat(statsHash[fc.id] || 0);
          });
        });
      }
    });
    $scope.preparedStats = stats;
  };

  $scope.showFc = (financialClause) => {
    const ancestry = financialClause.ancestry;
    const ancestors = (ancestry ? ancestry.split('/') : []);
    return ancestors.every((fcId) => {
      const fc = $scope.fcList.find(x => x.id === fcId);
      if (!fc) {
        console.warn('ancestor not found');
        console.warn(fcId);
        console.warn(fc);
        return true;
      }
      return fc.expanded;
    });
  };

  $scope.reloadStats = () => {
    $scope.updateStats().then(prepareStatsData);
  };

  $scope.$on('date-range-updated', $scope.reloadStats);
});
