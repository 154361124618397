angular.module('RocketWash').directive('rwContractorTags', (
  Tag
) => {
  return {
    templateUrl: 'directives/rw-contractor-tags/index.slim',
    restrict: 'E',
    scope: {
      user: '=',
    },
    link: function(scope, _element, _attrs) {
      scope.onTagAdding = (tag) => {
        if (tag.id) {
          scope.user.tags.push(tag);
        } else {
          return new Tag(tag).save().then((response) => {
            if (!response.id) {
              throw new Error('failed to create tag');
            }

            tag.id = response.id;
            scope.user.tags.push(tag);
          });
        };

        return scope.user;
      };

      scope.removeTag = (tag) => {
        scope.user.tags = _.without(scope.user.tags, tag);
        return scope.user;
      };

      scope.typeaheadTags = (searchQuery) => {
        return Tag.typeahead(searchQuery);
      };
    },
    }
});
