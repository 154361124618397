angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.settings.ya_fuel_online', {
    url: '/ya_fuel_online',
    tab: 'ya_fuel_online',
    data: {
      permission: 'MobileSettingsTab'
    },
    views: {
      'content@app.wash.settings': {
        controller: 'WashSettingsYaFuelOnlineIndexController',
        templateUrl: 'pages/wash/settings/ya_fuel_online/index.slim'
      }
    }
  });
});
