angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.printed_documents.completed_services', {
    url: '/completed_services',
    tab: 'completed_services',
    data: {
      permission: 'PrintedCompletedServicesTab',
    },
    views: {
      'content@app.wash.printed_documents': {
        controller: 'WashPrintedDocumentsCompletedServicesController',
        templateUrl: 'pages/wash/printed_documents/completed_services/index.slim'
      }
    }
  });
});
