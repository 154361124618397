angular.module('RocketWash').controller(
  'WashServicesConsumablesController',
  ($q, $scope, $translate, $uibModal, viewStore, userSession, Product, Service, BasicService, Consumable) => {
    $scope.selected = {};

    const reload = () => {
      if ($scope.ctgSelectService.selected) {
        const consumablesPromise = Consumable.indexByServices().then((data) => {
          $scope.consumablesByServices = data;
        });

        const basicServicePromise = BasicService.indexWithConsumables({
          car_types_group_id: $scope.ctgSelectService.selected.id,
        }).then((data) => {
          $scope.basicServices = data.map(x => new BasicService(x));
        });

        $q.all([consumablesPromise, basicServicePromise]).then(() => {
          $scope.serviceCategories = $scope.getServiceCategories($scope.basicServices);

          $scope.basicServices.forEach((service) => {
            service.consumables = $scope.consumablesByServices[service.id];
          });
        })
      }
    };

    $scope.edit = (consumerId, consumableId=null) => {
      return $uibModal.open({
        templateUrl: 'pages/wash/services/consumables/form.slim',
        controller: 'WashServicesConsumablesFormController',
        resolve: {
          consumerId: () => (consumerId),
          consumableId: () => (consumableId),
        }
      }).result.then(() => {
        reload();
      });
    };

    $scope.addConsumable = (service) => {
      $scope.edit(service.id)
    };

    $scope.removeConsumables = (consumerId, consumableId) => {
      Consumable.destroyAll({
        consumer_service_id: consumerId,
        consumable_service_id: consumableId,
      }).then(reload());
    };

    $scope.save = (service) => {
      service.save();
    };

    $scope.$on('ctgSelectService-selected', () => {reload();});
    reload();
  },
);
