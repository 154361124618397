
const DEVICES = [
  {
    id: 'printer',
    name: 'Принтер'
  },
  {
    id: 'kkmserver 1',
    name: 'KKM сервер. Устройство №1'
  },
  {
    id: 'kkmserver 2',
    name: 'KKM сервер. Устройство №2'
  },
  {
    id: 'module_kassa',
    name: 'Модуль касса'
  }
];

var extend = function (child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

angular.module('RocketWash').factory('Device', function (railsResourceFactory, userSession, $http, $timeout, $rootScope, PaymentType, PrintableDocument, TenantSwitchInterceptor, alertService) {
  let KkmServer = window.KkmServer;

  // Make sure we do not have any errors if KKMServer is not setup
  // if (!KkmServer || !KkmServer.List) {
  //   return { indexAll: (callback) => callback(DEVICES) };
  // }

  let Device, DeviceFactory, paymentTypes, paymentTypesById, printableDocuments;
  paymentTypes = {};
  paymentTypesById = {};
  PaymentType.query().then(function (ptsData) {
    var i, item, len, results;
    results = [];
    for (i = 0, len = ptsData.length; i < len; i++) {
      item = ptsData[i];
      paymentTypes[item.rwConstName] = item;
      results.push(paymentTypesById[item.id] = item);
    }
    return results;
  });
  PrintableDocument.query().then(pds => {
    printableDocuments = pds
  })
  DeviceFactory = railsResourceFactory({
    url: (context) => { return "/" + (context.queryRole || userSession.controllerScope()) + "/devices/" + (context.id || '') },
    name: "device"
  });

  const setKkmserverSublicense = (device) => {
    if (device.settings.has_kkm_license || device.settings.hasKkmLicense) {
      KkmServer.DefaultSettings.KeySubLicensing = device.settings.kkm_license || device.settings.kkmLicense
    } else {
      KkmServer.DefaultSettings.KeySubLicensing = null
    }
  }

  const getInn = () => {
    return userSession.organization.legal_info.inn;
  };

  const getCashierName = () => {
    return userSession.getCashierName();
  };

  // ИНН продавца тег ОФД 1203
  const getCashierVATIN = () => {
    return userSession.inn_for_kkm;
  };

  const safeApply = function (fn) {
    var phase = $rootScope.$$phase;
    if (phase == '$apply' || phase == '$digest') {
      if (fn && (typeof (fn) === 'function')) {
        fn();
      }
    } else {
      $rootScope.$apply(fn);
    }
  };

  const handleErrors = (callback, errCallback) => {
    return (response = {}) => {
      console.log(`KKMServer response to ${response.Command}:`, response);

      safeApply(() => {
        if (response.Error && !response.Error.match(/undefined/)) {
          alertService.show({
            text: response.Error,
            type: 'error',
          });

          if (errCallback) {
            errCallback(response.Error);
          };
        } else {
          if (callback) {
            callback(response);
          };
        };
      });
    };
  };

  if (KkmServer) {
    KkmServer.DefaultSettings.funCallBack = handleErrors;
  }

  Device = (function (superClass) {
    extend(Device, superClass);

    function Device() {
      return Device.__super__.constructor.apply(this, arguments);
    }

    Device.serviceOnline = false;

    Device.indexAll = (callback) => {
      // let data = KkmServer.List();
      callback(DEVICES);

      // data.Execute(handleErrors((response) => {
      //   let kkmDevices = response.ListUnit || [];
      //   kkmDevices.forEach((d) => {
      //     d.name = `${d.NumDevice}: ${d.NameDevice}`;
      //     d.id = `kkmserver ${d.NumDevice}`;
      //   });
      //
      //   devices = devices.concat(kkmDevices);
      //
      //   callback(devices);
      // }, (error) => {
      //   callback(devices);
      // }));
    };

    Device.all = (queryParams) => {
      return $http({
        url: `/${userSession.controllerScope()}/devices/all`,
        params: queryParams,
        method: 'GET',
      }).then(response => response.data);
    }

    Device.kassaSettings = (device) => {
      return $http({
        url: `/wash/devices/${device.id}/kassa_settings`,
        method: 'GET'
      })
    }

    Device.useKKMServerAcquiring = (device) => {
      return Device.kassaSettings(device).then(response => {
        return response.data.find(key => key.setting_key === 'use_kkm_server_acquiring').value === 'yes'
      })
    }

    Device.indexKKM = (callback) => {
      Device.all().then((devices) => {
        const filtered = devices.filter((d) => d.id.match(/kkmserver/));
        callback(filtered);
      });
    };

    Device.defaultDevice = (callback) => {
      Device.indexKKM((devices) => {
        callback(devices[0]);
      });
    };

    // I don't want to refactor all this callback stuff, so this is replacement for defaultDevice function
    Device.currentDevice = (device, callback) => {
      callback(device)
    };

    Device.cashIn = function (sum, device) {
      setKkmserverSublicense(device)

      const numDevice = device.settings.num_device || device.settings.numDevice
      const Data = KkmServer.DepositingCash(numDevice, sum, getCashierName(), getCashierVATIN());
      console.log('Sending to KKMServer:', Data);

      const result = Data.Execute(handleErrors)
      console.log('From KKMServer:', result);
    };

    Device.cashOut = function (sum, device) {
      setKkmserverSublicense(device)

      const numDevice = device.settings.num_device || device.settings.numDevice
      const Data = KkmServer.PaymentCash(numDevice, sum, getCashierName(), getCashierVATIN())
      console.log('Sending to KKMServer:', Data);

      const result = Data.Execute(handleErrors)
      console.log('From KKMServer:', result)
    };

    Device.openShift = function (callback, device) {
      setKkmserverSublicense(device)

      const numDevice = device.settings.num_device || device.settings.numDevice
      const result = KkmServer.OpenShift(numDevice, getCashierName(), getCashierVATIN()).Execute();

      handleErrors(() => {
        alertService.show({
          text: 'Кассовая смена открыта успешно',
        })

        if (callback) {
          callback();
        }
      }, () => {
        alertService.show({
          text: 'Ошибка при открытии кассовой смены. Попробуйте снова',
          type: 'error',
        });
      })(result);

      return result;
    };

    Device.printReceipts = function (document) {
      const device = printableDocuments.find(doc => doc.id === document.printable_document_id).device
      setKkmserverSublicense(device)

      if (document.printed_data.fiscal) {
        return printFiscalCheck(document);
      } else {
        return printSlipCheck(document);
      };
    };

    const printSlipCheck = function (document) {
      console.log('#printSlipCheck', document);

      const data = document.printed_data;

      let Data = KkmServer.GetDataCheck_1_0(0, data.device_number);

      // Это фискальный или не фискальный чек
      Data.IsFiscalCheck = data.fiscal;

      data.template.split("\n").forEach((line) => {
        Data.AddTextString((line || ''), 0, 10);
      });

      console.log('Sending to KKMServer:', Data);

      const result = Data.Execute(handleErrors);

      console.log('From KKMServer:', result);

      return result;
    };

    const printFiscalCheck = function (document) {
      console.log('#printFiscalCheck', document);

      const data = document.printed_data;

      if (!data.should_print) {
        console.log('We should not print this check since financial center device identifier does not match current device id. Rocketwash administrator should go to Organization->Financial centers->Set device identifier. After that cash checks will be printed normally.');
        return;
      }

      let Data = KkmServer.GetDataCheck(0, data.device_number, "", getCashierName(), getCashierVATIN());
      Data.KeySubLicensing = KkmServer.DefaultSettings.KeySubLicensing
      Data.SignMethodCalculation = 4
      Data.SignCalculationObject = 4

      // Это фискальный или не фискальный чек
      Data.IsFiscalCheck = data.fiscal;
      // Тип чека;
      // 0 – продажа;                             10 – покупка;
      // 1 – возврат продажи;                     11 - возврат покупки;
      // 8 - продажа только по ЕГАИС (обычный чек ККМ не печатается)
      // 9 - возврат продажи только по ЕГАИС (обычный чек ККМ не печатается)
      Data.TypeCheck = data.type_check;

      const copiesCount = (parseInt(data.number_copies) || 1) - 1;
      Data.NumberCopies = copiesCount;

      Data.PayByProcessing = data.pay_by_processing;
      Data.ReceiptNumber = data.receipt_number;
      // Data.PrintSlipForCashier = true;

      // Наличная оплата (2 знака после запятой)
      Data.Cash = data.cash;
      // Сумма электронной оплаты (2 знака после запятой)
      Data.ElectronicPayment = data.electronic_payment;

      Data.AddTextString((data.head || ''), 0, 10);

      data.line_items.forEach((item) => {
        // Добавление печати фискальной строки
        let DataStr = Data.AddRegisterString(
          // НаименованиеТовара(64 символа)
          item.name,
          // Количество (3 знака после запятой)
          item.count,
          // ЦенаБезСкидки (2 знака после запятой)
          item.price,
          // СуммаСтроки (2 знака после запятой) ???
          item.sum,
          // СтавкаНДС(0(НДС 0%), 10(НДС 10%), 18(НДС 18%), -1(НДС не облагается), 118 (НДС 18/118), 110 (НДС 10/110))
          item.vat,
          // Отдел
          item.department,
          // Код товара EAN13 - не обязательно
          item.ean13,
          // Признак способа расчета. тег ОФД 1214. Для ФФД.1.05 и выше обязательное поле
          // 1: "ПРЕДОПЛАТА 100% (Полная предварительная оплата до момента передачи предмета расчета)"
          // 2: "ПРЕДОПЛАТА (Частичная предварительная оплата до момента передачи предмета расчета)"
          // 3: "АВАНС"
          // 4: "ПОЛНЫЙ РАСЧЕТ (Полная оплата, в том числе с учетом аванса в момент передачи предмета расчета)"
          // 5: "ЧАСТИЧНЫЙ РАСЧЕТ И КРЕДИТ (Частичная оплата предмета расчета в момент его передачи с последующей оплатой в кредит )"
          // 6: "ПЕРЕДАЧА В КРЕДИТ (Передача предмета расчета без его оплаты в момент его передачи с последующей оплатой в кредит)"
          // 7: "ОПЛАТА КРЕДИТА (Оплата предмета расчета после его передачи с оплатой в кредит )"
          item.payment_method_code,
          // Признак предмета расчета. тег ОФД 1212. Для ФФД.1.05 и выше обязательное поле
          // 1: "ТОВАР (наименование и иные сведения, описывающие товар)"
          // 2: "ПОДАКЦИЗНЫЙ ТОВАР (наименование и иные сведения, описывающие товар)"
          // 3: "РАБОТА (наименование и иные сведения, описывающие работу)"
          // 4: "УСЛУГА (наименование и иные сведения, описывающие услугу)"
          // 5: "СТАВКА АЗАРТНОЙ ИГРЫ (при осуществлении деятельности по проведению азартных игр)"
          // 6: "ВЫИГРЫШ АЗАРТНОЙ ИГРЫ (при осуществлении деятельности по проведению азартных игр)"
          // 7: "ЛОТЕРЕЙНЫЙ БИЛЕТ (при осуществлении деятельности по проведению лотерей)"
          // 8: "ВЫИГРЫШ ЛОТЕРЕИ (при осуществлении деятельности по проведению лотерей)"
          // 9: "ПРЕДОСТАВЛЕНИЕ РИД (предоставлении прав на использование результатов интеллектуальной деятельности или средств индивидуализации)"
          // 10: "ПЛАТЕЖ (аванс, задаток, предоплата, кредит, взнос в счет оплаты, пени, штраф, вознаграждение, бонус и иной аналогичный предмет расчета)"
          // 11: "АГЕНТСКОЕ ВОЗНАГРАЖДЕНИЕ (вознаграждение (банковского)платежного агента/субагента, комиссионера, поверенного или иным агентом)"
          // 12: "СОСТАВНОЙ ПРЕДМЕТ РАСЧЕТА (предмет расчета, состоящем из предметов, каждому из которых может быть присвоено вышестоящее значение"
          // 13: "ИНОЙ ПРЕДМЕТ РАСЧЕТА (предмет расчета, не относящемуся к предметам расчета, которым может быть присвоено вышестоящее значение"
          item.payment_attribution_code,
          // Код товарной номенклатуры Тег ОФД 1162 (Новый классификатор товаров и услуг. Пока не утвержден налоговой. Пока не указывать)
          // 4 символа – код справочника; последующие 8 символовт – код группы товаров; последние 20 символов – код идентификации товара
          item.commodity_item_code,
          // Единица измерения предмета расчета. Можно не указывать
          item.unit
        );
      });;

      Data.AddTextString((data.footer || ''), 0, 10);

      console.log('Sending to KKMServer:', Data);

      const result = Data.Execute(handleErrors)

      console.log('From KKMServer:', result);

      return result;
    };

    Device.xReport = (device) => {
      setKkmserverSublicense(device)

      const numDevice = device.settings.num_device || device.settings.numDevice
      const Data = KkmServer.XReport(numDevice);
      Data.PayByProcessing = false;
      console.log(Data.Execute())
    };

    Device.acquiringTerminalReport = (device) => {
      Device.currentDevice(device, handleErrors(() => {
        const numDevice = device.settings.num_device || device.settings.numDevice
        const data = {
          Command: "TerminalReport",
          NumDevice: numDevice,
          Detailed: true,
        };
        console.log('Sending to KKMServer:', data);
        const resultTR = KkmServer.Execute(handleErrors, data);
        Device.checkResultFromKKM(resultTR && resultTR.IdCommand, (result) => {

          let Data = KkmServer.GetDataCheck_1_0(0, numDevice);
          Data.IsFiscalCheck = false;

          result.Slip.split("\n").forEach((line) => {
            Data.AddTextString((line || ''), 0, 10);
          });

          console.log('Sending to KKMServer:', Data);
          const resultPrintSlipReport = Data.Execute(handleErrors);
          Device.checkResultFromKKM(resultPrintSlipReport && resultPrintSlipReport.IdCommand, (result) => {
          });
        });
      }));
    };

    Device.closeShift = (callback, device) => {
      Device.zReport(() => {
        if (callback) {
          callback();
        }
      }, device);
    };

    Device.zReport = function (callback, device) {
      setKkmserverSublicense(device)

      alertService.show({
        text: 'Инициируем печать Z-отчета',
      });

      const closeShift = () => {
        const numDevice = device.settings.num_device || device.settings.numDevice
        const Data = KkmServer.CloseShift(numDevice, getCashierName(), getCashierVATIN());
        Data.PayByProcessing = false;
        console.log('Sending to KKMServer:', Data);
        const result = Data.Execute();

        handleErrors(() => {
          alertService.show({
            text: 'Z-отчет успешно распечатан',
          })

          if (!userSession.useKKMServerAcquiring() && callback) {
            callback();
          }
        }, () => {
          alertService.show({
            text: 'Ошибка при печати Z-отчета. Попробуйте снова',
            type: 'error',
          });
        })(result);

        return result;
      };

      Device.kassaSettings(device).then(response => {
        if (response.data.find(key => key.setting_key === 'use_kkm_server_acquiring').value === 'yes') {
          const resultCloseShift = closeShift();
          Device.checkResultFromKKM(resultCloseShift && resultCloseShift.IdCommand, (result) => {
            const data = KkmServer.Settlement(2) // NumDevice
            console.log('Sending to KKMServer:', data);
            const resultS = data.Execute(handleErrors, data);

            Device.checkResultFromKKM(resultS && resultS.IdCommand, (result) => {
              const numDevice = device.settings.num_device || device.settings.numDevice
              let Data = KkmServer.GetDataCheck_1_0(0, numDevice);
              Data.IsFiscalCheck = false;

              result.Slip.split("\n").forEach((line) => {
                Data.AddTextString((line || ''), 0, 10);
              });

              console.log('Sending to KKMServer:', Data);
              const aCresult = Data.Execute(handleErrors);
              handleErrors(() => {
                alertService.show({
                  text: 'Смена эквайринга успешно закрыта',
                })

                if (callback) {
                  callback();
                }
              }, () => {
                alertService.show({
                  text: 'Ошибка при закрытии смены эквайринга. Попробуйте снова',
                  type: 'error',
                });
              })(aCresult);
            });
          });
        } else {
          return closeShift();
        }
      })
    };

    // Wait while printing
    Device.checkResultFromKKM = (commandId, callback, options = {}) => {

      if (!commandId) {
        callback({ no_command_id: true });
        return;
      };

      const Data = KkmServer.GetRezult(commandId)
      console.log('Sending to KKMServer:', Data);
      Data.Execute((response) => {
        let result = response;
        if (response && response.Rezult) {
          result = response.Rezult;
        };

        console.log('Command result on KKMServer:', result);
        if (result.Status == 1 || result.Status == 4) {
          console.log('Command is in process on KKMServer:', commandId);
          $timeout(() => {
            Device.checkResultFromKKM(commandId, callback, options);
          }, 1000);
        } else {
          const success = result.Status == 1;
          result.success = success;

          console.log('Command finished on KKMServer:', commandId, 'With status:', result.Status);

          callback(result);
        };
      });
    };


    return Device;

  })(DeviceFactory);

  Device.serviceStatus = true; // Even if some devices does not work

  Device.addInterceptor(TenantSwitchInterceptor);

  return Device;
});
