angular.module('RocketWash').controller('WashSettingsLanesFormController', (
  $scope, ServiceLocationLane, resolvedLane,
) => {
  $scope.lane = new ServiceLocationLane(resolvedLane);
  if ($scope.lane.id) {
    $scope.lane.get().then(() => {
      console.log('reloaded');
    });
  }

  $scope.save = () => {
    $scope.formDisabled = true;
    $scope.lane.save().then((lane) => {
      if (_.isEmpty(lane.errors)) {
        $scope.$dismiss();
      } else {
        $scope.errors = { create_lane: lane.errors };
      }
    }).finally(() => { $scope.formDisabled = false; });
  };
});
