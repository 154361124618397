angular.module('RocketWash').factory(
  'TenantSwitchInterceptor',
  () => ({
    beforeRequest: (httpConfig, resourceConstructor, context) => {
      const config = angular.copy(httpConfig);
      config.headers = config.headers || {};

      if (resourceConstructor.tenantId) {
        config.headers['Tenant-Id'] = resourceConstructor.tenantId;
      }
      if (context && context.organizationId) {
        config.headers['Tenant-Id'] = context.organizationId;
      }
      if (config.params && config.params.organization_id) {
        config.headers['Tenant-Id'] = config.params.organization_id;
      }
      return config;
    },
  }),
);
