angular.module('RocketWash').config(($stateProvider, $urlRouterProvider) => {
  $urlRouterProvider.when('/admin/settings', '/admin/settings/car_types');
  return $stateProvider.state('app.admin.settings', {
    url: '/settings',
    abstract: true,
    controller: 'AdminSettingsBaseController',
    templateUrl: 'pages/admin/settings/index.slim',
    data: {
      permission: 'SettingsMenuItem',
      tabs: [
        'general', 'finance_clauses', 'payment_types', 'resource_kinds', 'global_mobile_services',
        'country_phone_codes', 'help_articles', 'review_scales', 'car_types_groups',
        'car_types', 'car_models', 'printable_documents', 'promo_codes',
      ],
    },
  }).state('app.admin.settings.carModels', {
    url: '/car_models',
    tab: 'car_models',
    views: {
      'content@app.admin.settings': {
        controller: 'AdminSettingsCarModelsController',
        templateUrl: 'pages/admin/settings/car_models/index.slim',
      },
    },
  });
});
