

var extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

angular.module('RocketWash').factory('BasicService', function(railsResourceFactory, railsSerializer, userSession, ServiceForCarType, PriceListItem, PriceListItemForTaxi, PriceListSetting, plSelectService) {
  var BasicService, Factory;
  Factory = railsResourceFactory({
    url: (context) => { return "/" + (context.queryRole || userSession.controllerScope()) + "/basic_services/" + (context.id || '') },
    name: 'basic_service',
    serializer: railsSerializer(function() {
      this.resource('priceListItems', 'PriceListItem');
      this.nestedAttribute('priceListItems');

      this.resource('priceListItemForTaxis', 'PriceListItemForTaxi');
      this.nestedAttribute('priceListItemForTaxis');

      this.resource('priceListSettings', 'PriceListSetting');
      this.nestedAttribute('priceListSettings');

      this.resource('consumables', 'Consumable');
      this.nestedAttribute('consumables');
      return this.add('type', 'BasicService');
    }),
    interceptors: [{
      'beforeRequest': function (httpConfig, resourceConstructor, context) {
        if (!httpConfig.params || !httpConfig.params.price_list_id) {
          httpConfig.params = httpConfig.params || {};
          httpConfig.params.price_list_id = _.get(plSelectService, 'selected.id');
        }
        return httpConfig;
      },
    }]
  });
  return BasicService = (function(superClass) {
    extend(BasicService, superClass);

    function BasicService() {
      return BasicService.__super__.constructor.apply(this, arguments);
    }

    BasicService.prototype.getPLI = function(carTypeId, copy) {
      const plId = _.get(plSelectService, 'selected.id');

      if (!plId) { return; }

      var newPriceListItem, service;
      if (copy == null) {
        copy = true;
      }
      carTypeId = parseInt(carTypeId);
      service = _.find(this.priceListItems, (pli) => {
        return pli.carTypeId === carTypeId && pli.priceListId === plId;
      });
      const defaultService = _.find(this.priceListItems, (pli) => {
        return pli.carTypeId === carTypeId && pli.custom == false;
      });
      service = service || defaultService
      if (service) {
        if (copy) {
          return angular.copy(service);
        } else {
          return service;
        }
      } else {
        newPriceListItem = new PriceListItem({
          serviceId: this.id,
          priceListId: plId,
          carTypeId: carTypeId
        });
        this.priceListItems || (this.priceListItems = []);
        if (carTypeId) {
          this.priceListItems.push(newPriceListItem);
        }
        return newPriceListItem;
      }
    };

    BasicService.prototype.getPLIFT = function(carTypeId, copy) {
      const plId = _.get(plSelectService, 'selected.id');

      if (!plId) { return; }

      var newPriceListItemForTaxi, service;
      if (copy == null) {
        copy = true;
      }
      carTypeId = parseInt(carTypeId);
      service = _.find(this.priceListItemForTaxis, (plift) => {
        return plift.carTypeId === carTypeId && plift.priceListId === plId;
      });
      if (service) {
        if (copy) {
          return angular.copy(service);
        } else {
          return service;
        }
      } else {
        newPriceListItemForTaxi = new PriceListItemForTaxi({
          serviceId: this.id,
          priceListId: plId,
          carTypeId: carTypeId
        });
        this.priceListItemForTaxis || (this.priceListItemForTaxis = []);
        if (carTypeId) {
          this.priceListItemForTaxis.push(newPriceListItemForTaxi);
        }
        return newPriceListItemForTaxi;
      }
    };

    BasicService.prototype.getPLS = function () {
      if (this.priceListSettings.length == 0) {
        const newPLS = new PriceListSetting({
          serviceId: this.id,
          priceListId: plSelectService.selected.id,
          permission: 'everybody',
        });

        this.priceListSettings.push(newPLS);
      }

      return this.priceListSettings[0];
    };

    BasicService.indexWithPriceListSettings = function (params) {
      return BasicService.$get(
        `${BasicService.resourceUrl()}/index_with_price_list_settings`,
        params
      );
    };

    BasicService.indexWithConsumables = function (params) {
      return BasicService.$get(
        `${BasicService.resourceUrl()}/index_with_consumables`,
        params
      );
    };

    BasicService.indexSimple = function (params) {
      return BasicService.$get(
        `${BasicService.resourceUrl()}/index_simple`,
        params
      );
    };

    BasicService.indexMobile = function (params) {
      return BasicService.$get(
        `${BasicService.resourceUrl()}/index_mobile`,
        params
      );
    };

    return BasicService;

  })(Factory);
});
