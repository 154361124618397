angular.module('RocketWash').controller('CommonHeaderNotificationsController', (
  $scope, $state, viewStore,
) => {
  $scope.onHangingCashShiftsClick = () => {
    viewStore.store({ statisticsTab: 'for_cash_shift' });
    $state.go('app.wash.dashboard.statistics');
  };
  $scope.onHangingWorkShiftsClick = () => {
    viewStore.store({ workShiftsUnclosedOnly: true });
    $state.go('app.wash.shifts.work_shifts');
  };
  $scope.onLowBalanceClick = () => {
    $state.go('app.wash.organization.external_payments');
  };
  $scope.notificationsCount = () => {
    return $scope.userSession.notificationsCount();
  };
  $scope.close = () => {
    $scope.notificationsPopupOpen = false;
  };
});
