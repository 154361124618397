angular.module('RocketWash').controller('WashSettingsLaneCamerasFormController', (
  $scope, LaneCamera, resolvedLaneCamera, ServiceLocationLane, LaneCameraEvent,
) => {
  $scope.laneCamera = new LaneCamera(resolvedLaneCamera);

  $scope.selected = {};
  ServiceLocationLane.query().then((slls) => {
    $scope.slls = slls;
    if (!$scope.laneCamera.serviceLocationLaneId) {
      $scope.laneCamera.serviceLocationLaneId = slls[0].id;
    }

    $scope.$watch('laneCamera.serviceLocationLaneId', () => {
      $scope.selected.lane = ($scope.slls || []).find((sll) => sll.id == $scope.laneCamera.serviceLocationLaneId);
    });
  });

  if ($scope.laneCamera.id) {
    $scope.laneCamera.get().then(() => {
      console.log('reloaded');
    });
  } else {
    $scope.laneCamera.payment_type_const_name = 'fuel_card';
    $scope.laneCamera.active = true;
  }

  $scope.save = () => {
    $scope.formDisabled = true;
    $scope.laneCamera.save().then((laneCamera) => {
      if (_.isEmpty(laneCamera.errors)) {
        $scope.selected.lane.save();
        $scope.$dismiss();
      } else {
        $scope.errors = { create_payment_subtype: laneCamera.errors };
      }
    }).finally(() => { $scope.formDisabled = false; });
  };
});
