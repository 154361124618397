angular.module('RocketWash').controller('TaxiReportsFinancialOperationsBaseController', (
  $scope, userSession, FinancialCenter, $uibModal, $filter,
  FinancialOperation,
) => {
  $scope.pds = $scope.pageDataService.new({
    page: 'taxi_financial_operations',
    filters: {
      attributes: [
        { name: 'date', type: 'datetime' },
        { name: 'ordinal', type: 'number' },
        { name: 'amount', type: 'number' },
        { name: 'record_type', type: 'string' },
        { name: 'comment', type: 'string' },
        { name: 'document', type: 'string' },
        { name: 'finance_clause', type: 'string' },
        { name: 'from_financial_center_name', type: 'string' },
        { name: 'to_financial_center_name', type: 'string' },
        { name: 'from_second_financial_center_name', type: 'string' },
        { name: 'to_second_financial_center_name', type: 'string' },
        ],
        scopes: ['record_type', 'comment', 'document', 'finance_clause',
                 'from_financial_center_name', 'to_financial_center_name',
                 'from_second_financial_center_name',
                 'to_second_financial_center_name'],
    },
    pg: {
      url: '/taxi/financial_operations',
      model: FinancialOperation,
      query: {
        scope_to_service_location: false,
        fromToField: 'date',
        predicate: '-date',
        reverse: true,
        per: 10,
        page: 1,
      },
    },
    definePaginateMethodsOn: $scope,
  });


  $scope.amountWithSign = (fo) => {
    const amount = fo.amount;
    const roundedAmount = $filter('fract')(amount);
    if (fo.recordType == 'FinancialOperationTaxiOutcomeForServices') {
      return `-${roundedAmount}`;
    } else {
      return roundedAmount;
    };
  };
});
