

angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.settings.discount_actions', {
    url: '/discount_actions',
    tab: 'discount_actions',
    data: {
      permission: 'DiscountActionsTab'
    },
    views: {
      'content@app.wash.settings': {
        controller: 'WashSettingsDiscountActionsBaseController',
        templateUrl: 'pages/wash/settings/discount_actions/index.slim'
      }
    }
  }).state('app.wash.settings.discount_actions.show', {
    url: '/:id',
    tab: 'discount_actions',
    data: {
      permission: 'DiscountActionsTab'
    },
    views: {
      'content@app.wash.settings': {
        controller: 'WashSettingsDiscountActionsShowController',
        templateUrl: 'pages/wash/settings/discount_actions/index.slim'
      }
    }
  }).state('app.wash.settings.discount_actions.step_form', {
    url: '/:id/step_form',
    tab: 'discount_actions',
    data: {
      permission: 'DiscountActionsTab'
    },
    views: {
      'content@app.wash.settings': {
        controller: 'WashSettingsDiscountActionsStepFormController',
        templateUrl: 'pages/wash/settings/discount_actions/step_form.slim'
      }
    }
  });
});
