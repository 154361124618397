angular.module('RocketWash').controller(
  'RWPriceListsCarTypesGroupFormController',
  ($scope, car, user) => {
    $scope.car = car;
    $scope.user = user;

    $scope.isSelected = (ctg, pl) => {
      return ($scope.user.users_price_lists || []).some((item) => {
        return item.price_list_id == pl.id && item.car_types_group_id == ctg.id;
      });
    };

    $scope.isRemovable = (ctg) => {
      return !!$scope.user.findUsersPriceList({
        car_types_group: ctg,
      });
    };

    $scope.toggle = (ctg, pl) => {
      $scope.user.togglePriceList({
        price_list: pl,
        car_types_group: ctg,
      });
    };

    $scope.removeUsersPriceList = (ctg) => {
      if (ctg.editable) {
        $scope.user.removeUsersPriceList({
          car_types_group: ctg,
        });
      }
    };

  },
);
