angular.module('RocketWash').directive('rwPhoneFlag', (
  CountryPhoneCode
) => {
  return {
    restrict: 'E',
    scope: {
      phone: '=',
    },
    templateUrl: 'directives/rw-phone-flag/rw-phone-flag.slim',
    link(scope, _element, _attrs) {
      CountryPhoneCode.query({}, {queryRole: '/'}).then((cpcs) => {
        scope.cpcs = cpcs.sort((a, b) => {
          if (a.code < b.code) {
            return 1;
          } else {
            return -1;
          };
        });;
      });

      const calculateIsos = (phone) => {
        phone = phone || '';
        const phoneDigits = phone.replace(/[^\d]/g, '');
        const cpc = scope.cpcs && scope.cpcs.find((cpc) => {
          const cpcDigits = cpc.code.replace(/[^\d]/g, '');
          return phoneDigits.indexOf(cpcDigits) == 0;
        });
        scope.countryIsos = cpc && cpc.countryIso.map(iso => iso.toLowerCase());
      };

      scope.$watch('phone', calculateIsos);

      setTimeout(() => {
        calculateIsos(scope.phone);
        scope.$apply();
      }, 500);
    },
  };
});
