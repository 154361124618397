angular.module('RocketWash').controller('WashDashboardWorkShiftsController', (
  $scope, $translate, $filter, $uibModal, $http,
  WorkShift, Employee, Job, alertService, userSession,
) => {
  $scope.pds = $scope.pageDataService.new({
    page: 'work_shifts',
    filters: {
      attributes: [
        { name: 'started_at', type: 'datetime' },
        { name: 'ended_at', type: 'datetime' },
        { name: 'user_name', type: 'string' },
        { name: 'comment', type: 'string' },
        { name: 'job', type: 'string' },
        { name: 'responsible_user_name', type: 'string' },
      ],
      scopes: ['user_name', 'comment', 'job', 'responsible_user_name'],
    },
    pg: {
      url: '/wash/work_shifts',
      model: WorkShift,
      query: {
        fromToField: 'started_at',
        predicate: '-started_at',
        page: 1,
        per: 10,
      },
    },
    definePaginateMethodsOn: $scope,
  });

  $scope.rightMenuConfig = {
    buttons: [
      {
        type: 'export_xls',
        text: $translate.instant('buttons.export_xls'),
        action: () => { $scope.exportXLS(); },
      },
    ],
  };

  // HELPERS
  $scope.searchParams = () => $.param($scope.pds.pg.query);

  $scope.exportXLS = () => {
    $uibModal.open({
      templateUrl: 'pages/common/email_popup/email_popup.slim',
      controller: 'CommonEmailPopupController',
      windowClass: 'small',
      size: 'sm',
    }).result.then((email) => {
      const options = angular.extend({ email }, Object.fromEntries(new URLSearchParams($scope.searchParams())));
      return $http({
        url: `${$scope.env.apiUrl}/wash/work_shifts.xlsx`,
        method: 'GET',
        params: options,
      }).then(() => alertService.show({
        text: $filter('translate')('email_popup.success'),
      }));
    });
  };

  $scope.jobs = [];
  $scope.employees = [];

  Employee.all().then((employees) => {
    $scope.employees = employees;
    $scope.availableEmployees = employees.filter(x => x.employee_status === 'works');
  });
  Job.query().then((jobs) => {
    $scope.jobs = jobs;
  });
  $scope.newWorkShift = () => {
    const date = moment().format();
    $scope.pds.pg.items.unshift(new WorkShift({ startedAt: date }));
  };
  $scope.employeeName = (employeeId) => {
    const employee = $scope.employees.find(e => e.id === employeeId);
    return employee && employee.name || $translate.instant('shifts.work_shifts.table.employee');
  };
  $scope.jobName = (jobId) => {
    const job = $scope.jobs.find(e => e.id === jobId);
    return job && job.name || $translate.instant('shifts.work_shifts.table.job');
  };
  $scope.save = (workShift, countDuration) => {
    if (countDuration == null) {
      countDuration = true;
    }
    if (workShift.endedAt && countDuration) {
      workShift.duration =
        Math.ceil((moment(workShift.endedAt) - moment(workShift.startedAt)) / 1000 / 60);
    }
    return workShift.save();
  };
  $scope.showRecalculationNotification = (ws) => {
    return alertService.show({
      text: $filter('translate')('shifts.work_shifts.recalculate_notification', {
        name: $scope.employeeName(ws.userId),
        from: $filter('formatTime')(ws.startedAt),
        to: $filter('formatTime')(ws.endedAt || moment()),
      }),
    });
  };
  $scope.closeWorkShift = (workShift) => {
    workShift.endedAt = moment().format();
    $scope.save(workShift);
  };
  $scope.allExpanded = () => $scope.pds.pg.items.every(x => x.expanded);
  $scope.toggleAll = () => {
    const newValue = !$scope.allExpanded();
    $scope.pds.pg.items.forEach((x) => {
      x.expanded = newValue;
    });
  };
});
