angular.module('RocketWash').config(($stateProvider) => {
  $stateProvider.state('partner_sign_up', {
    url: '/partner_sign_up',
    controller: 'PartnerSignUpController',
    templateUrl: 'pages/partner/sign_up/sign_up.slim',
    data: {
      permission: 'SignUp',
      publicPage: true,
    },
    resolve: {
      _serverTranslations: () => {
        return window.translationsPromise;
      },
    },
  });
});
