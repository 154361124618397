angular.module('RocketWash').factory('Recognition', (
  $http, railsResourceFactory, userSession, TenantSwitchInterceptor,
) => {
  const Resource = railsResourceFactory({
    url: (context) => { return "/" + (context.queryRole || userSession.controllerScope()) + "/recognitions/" + (context.id || '') },
    name: 'recognition',
  });

  Resource.carPlateRecognition = function(data, context) {
    return $http({
      url: `${this.resourceUrl(context)}/car_plate_recognition`,
      data: data,
      method: 'POST',
      withCredentials: true,
      headers: {'Content-Type': undefined },
      transformRequest: angular.identity,
    }).then(function(response) {
      return response.data;
    });
  }

  Resource.addInterceptor(TenantSwitchInterceptor);

  return Resource;
});

