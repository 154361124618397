angular.module('RocketWash').config(($stateProvider, $urlRouterProvider) => {
  $urlRouterProvider.when('/taxi/settings', '/taxi/settings/dashboard');
  return $stateProvider.state('app.taxi.settings', {
    url: '/settings',
    abstract: true,
    controller: 'TaxiSettingsBaseController',
    templateUrl: 'pages/taxi/settings/index.slim',
    data: {
      tabs: [
        'general', 'organization', 'integrations'
      ],
    },
  });
});
