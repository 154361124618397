angular.module('RocketWash').directive('rwPhoneInputMask', ($timeout) => {
  const maskList = $.masksSort($.masksLoad("phone-codes.json"), ['#'], /[0-9]|#/, "mask");

  return {
    restrict: 'A',
    require: 'ngModel',
    link: function(scope, elem, attr, ctrl) {
      // const phone = new Inputmask('phone');
      // phone.mask(elem[0]);


      $timeout(() => {
        const maskOpts = {
          inputmask: {
            definitions: {
              '#': {
                validator: "[0-9]",
                cardinality: 1
              }
            },
            //clearIncomplete: true,
            showMaskOnHover: false,
            autoUnmask: true
          },
          match: /[0-9]/,
          replace: '#',
          list: maskList,
          listKey: "mask",
        };


        elem.inputmasks(maskOpts);
      });

      elem.on('keyup', function() {
        scope.$apply(function() {
          ctrl.$commitViewValue();
        });
      });

      ctrl.$parsers.push((x) => {
        console.log(x);
        return x;
      });
      ctrl.$formatters.push((x) => {
        console.log(x);
        return x;
      });

    }
  };
});
