

angular.module('RocketWash').service('notifier', function($mdToast) {
  var toaster = function(messages, colors) {
    $mdToast.show({
      hideDelay   : 3500,
      position    : 'top right',
      template    : `
        <md-toast>
          <div class="md-toast-content" md-colors="colors">
            <div class="md-toast-text" md-colors="colors">
              <div class="padding-6" ng-repeat="msg in ::messages">{{msg}}</div>
            </div>
          </div>
        </md-toast>`,
      controller  : function($scope) {
        $scope.messages = messages;
        $scope.colors = colors;
      }
    });
  };

  return {
    warn: function(messages) {
      toaster(messages, {background: 'red', color: 'grey-A100'})
    },
    info: function(messages) {
      toaster(messages, {background: 'blue-grey', color: 'grey-A100'})
    }
  };
});

