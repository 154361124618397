angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  $urlRouterProvider.when('/taxi', '/taxi/income/report');

  return $stateProvider.state('app.taxi', {
    url: '/taxi',
    abstract: true,
    templateUrl: 'pages/taxi/index.slim',
    resolve: {
      us: function(userSession) {
        return userSession.promise;
      }
    }
  });
});
