angular.module('RocketWash').controller('AdminSettingsCarTypesBaseController', (
  $scope, $uibModal, CarType, CarTypesGroup, rwConfirmationService,
) => {
  $scope.reloadCarTypes = () => {
    CarType.query({}, { cache: false }).then((carTypes) => {
      $scope.carTypes = _.sortBy(carTypes, 'name');
    });
  };

  CarTypesGroup.query().then(function(groups) {
    return $scope.groups = _.map(groups, function(t) {
      return new CarTypesGroup(t);
    });
  });

  $scope.delete = (carType) => {
    rwConfirmationService.confirm(
      { textTranslation: 'alert.are_you_sure' },
      () => { carType.delete().then($scope.reloadCarTypes); },
    );
  };

  $scope.$on('search-change', (_event, data) => {
    $scope.searchQuery = data.value;
  });

  $scope.openFormPopup = (carType) => {
    $uibModal.open({
      templateUrl: 'pages/admin/settings/car_types/form.slim',
      controller: 'AdminSettingsCarTypesFormController',
      resolve: {
        resolvedCarType: () => carType,
      },
    }).result.finally(() => {
      setTimeout($scope.reloadCarTypes, 1000);
    });
  };

  $scope.save = (carType) => {
    carType.save().then($scope.reloadCarTypes);
  };

  $scope.reloadCarTypes();
});
