angular.module('RocketWash').config(($stateProvider, $urlRouterProvider) => {
  return $stateProvider.state('app.wash.setup_wizard', {
    url: '/setup_wizard',
    controller: 'WashSetupWizardBaseController',
    templateUrl: 'pages/wash/setup_wizard/index.slim',
    data: {
      publicPage: true,
    },
  });
});
