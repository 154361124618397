

angular.module('RocketWash').directive("select", function($window, $timeout) {
  return {
    priority: 0,
    restrict: "E",
    require: "ngModel",
    link: function($scope, element) {
      if ($window.navigator.userAgent.match(/iPhone|iPad|MSIE/)) {
        return element.on("change", function() {
          var correctValue;
          correctValue = element.val();
          return $timeout(function() {
            return element.val(correctValue);
          });
        });
      }
    }
  };
});
