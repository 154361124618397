angular.module('RocketWash').config(($stateProvider, $urlRouterProvider) => {
  $urlRouterProvider.when('/client_cabinet/analytics', '/client_cabinet/analytics/general');
  return $stateProvider.state('app.client_cabinet.analytics', {
    url: '/analytics',
    abstract: true,
    controller: 'ClientCabinetAnalyticsController',
    templateUrl: 'pages/client_cabinet/analytics/index.slim',
    data: {
      tabs: ['general'],
    },
  });
});
