angular.module('RocketWash').factory('ExternalPayment', (
  railsResourceFactory, userSession, TenantSwitchInterceptor,
) => {
  const Resource = railsResourceFactory({
    url: (context) => { return "/" + (context.queryRole || userSession.controllerScope()) + "/external_payments/" + (context.id || '') },
    name: 'external_payment',
  });

  Resource.addInterceptor(TenantSwitchInterceptor);

  return Resource;
});

