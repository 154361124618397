// TODO: rework

angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  $urlRouterProvider.when('/admin/service_locations', '/admin/service_locations/dashboard');
  return $stateProvider.state('app.admin.service_locations', {
    url: '/service_locations',
    abstract: true,
    controller: 'AdminServiceLocationsBaseController',
    templateUrl: 'pages/admin/service_locations/index.slim',
  }).state('app.admin.service_locations.service_location', {
    url: '/:slId?tenantId',
    abstract: true
  });;
});
