angular.module('RocketWash').controller('WashDashboardStatisticsForDayController', (
  $scope, $http, dateRangeStorage,
) => {
  const deepParseFloat = (obj) => {
    Object.entries(obj).forEach(([key, value]) => {
      if (typeof value === 'object') {
        deepParseFloat(value);
      } else {
        obj[key] = parseFloat(value);
      }
    });
  };

  dateRangeStorage.update({ to: moment(dateRangeStorage.from()).add(1, 'day') });
  $scope.data = {
    date: {
      from: dateRangeStorage.from(),
    },
  };

  const adjusteDate = x => moment(moment.parseZone(x).format('YYYY-MM-DD'));

  const loadData = (options) => {
    return $http.post('/wash/statistics/for_day', options).then((resp) => {
      $scope.data = resp.data;
      $scope.data.date.from = adjusteDate($scope.data.date.from);
      $scope.data.date.to = adjusteDate($scope.data.date.to);
      dateRangeStorage.update($scope.data.date);
      deepParseFloat($scope.data.payments_for_reservations);
      deepParseFloat($scope.data.payments_by_service_type);
      deepParseFloat($scope.data.key_indexes);
      return deepParseFloat($scope.data.count_indexes);
    });
  };
  $scope.prevDay = () => {
    $scope.leftRightDisabled = true;
    loadData({ day: $scope.data.date.from.format('YYYY-MM-DD'), prev: true }).then(() => {
      $scope.leftRightDisabled = false;
    });
  };

  $scope.nextDay = () => {
    $scope.leftRightDisabled = true;
    loadData({ day: $scope.data.date.from.format('YYYY-MM-DD'), next: true }).then(() => {
      $scope.leftRightDisabled = false;
    });
  };

  $scope.stripTimeZone = (date) => {
    return moment.parseZone(date).format('YYYY-MM-DD HH:mm:ss');
  };

  loadData({ day: $scope.data.date.from.format('YYYY-MM-DD') });
});
