angular.module('RocketWash').controller('WashChartsAverageBillController', (
  $scope, $http, $translate, $timeout, $q, MarketingActivity,
) => {
  const updateChart = function () {
    let avg;
    let max;
    let min;
    let minArr;
    let total;
    let totalCount;
    $scope.groupedData = _.groupBy($scope.rawData, (point) => {
      let date;
      date = moment(point.timestamp);
      if ($scope.group.range) {
        date = date.startOf($scope.group.range);
      }
      return +date;
    });
    const series = {
      max: [],
      average: [],
      min: [],
    };
    Object.entries($scope.groupedData).forEach(([timestamp, ac]) => {
      max = _.maxBy(ac, (o) => {
        return o.max;
      }).max;
      total = ac.reduce(((sum, o) => {
        return sum + o.average * o.count;
      }), 0);
      totalCount = ac.reduce(((sum, o) => {
        return sum + o.count;
      }), 0);
      avg = totalCount > 0 ? Math.round(total / totalCount) : 0;
      minArr = _.chain(ac).map('min').filter((val) => {
        return val > 0;
      }).value();
      min = minArr.length > 0 ? _.minBy(minArr) : 0;
      series.max.push({
        x: parseInt(timestamp, 10),
        y: max,
        dataLabels: {
          enabled: false,
        },
      });
      series.average.push({
        x: parseInt(timestamp, 10),
        y: avg,
      });
      series.min.push({
        x: parseInt(timestamp, 10),
        y: min,
        dataLabels: {
          enabled: false,
        },
      });
    });
    if ($scope.chart) {
      $scope.chart.destroy();
    }
    const update = () => {
      if (!$('#charts-container-average-check').length) {
        return;
      }
      $scope.chart = new Highcharts.StockChart({
        yAxis: {
          title: {
            text: $translate.instant('charts.average_check.labels.total'),
          },
        },
        title: {
          text: $translate.instant('charts.average_check.labels.average_check'),
        },
        subtitle: {
          text: null,
        },
        rangeSelector: {
          selected: 0,
        },
        legend: {
          backgroundColor: '#f4f4f4',
          borderColor: '#f2f2f2',
        },
        chart: {
          renderTo: 'charts-container-average-check',
          backgroundColor: '#ffffff',
          type: 'spline',
          plotBackgroundColor: '#ffffff',
          zoomType: 'xy',
        },
        plotOptions: {
          series: {
            turboThreshold: 20000,
            dataLabels: {
              enabled: true,
            },
          },
        },
        xAxis: {
          type: 'datetime',
          plotBands: $scope.bands,
        },
        series: [
          {
            type: 'area',
            index: 0,
            marker: {
              symbol: 'circle',
              enabled: false,
            },
            color: '#aaffd4',
            name: 'Max',
            data: series.max,
          },
          {
            index: 2,
            color: '#ffaad4',
            marker: {
              symbol: 'triangle',
            },
            name: $translate.instant('charts.average_check.labels.average_check'),
            data: series.average,
          },
          {
            color: '#88c2fc',
            marker: {
              enabled: false,
            },
            type: 'area',
            index: 1,
            name: 'Min',
            data: series.min,
          },
        ],
      });
    };
    return $timeout(update);
  };

  const bandsEvents = {
    mouseover: function (_event) {
      this.label.element.classList.add('label-visible');
    },
    mouseout: function (_event) {
      this.label.element.classList.remove('label-visible');
    },
  };

  const activitiesToBands = (marketingActivities) => {
    const n = marketingActivities.length;
    if (n === 0) {
      return [];
    }
    const shift = 180 - 180 / n;
    return _.sortBy(marketingActivities, 'dateStart').map((ma, i) => {
      return {
        from: +moment(ma.dateStart),
        to: +moment(ma.dateEnd),
        color: `hsla(${Math.round((shift * i) % 360)}, 100%, 50%, 0.1)`,
        label: {
          text: ma.name,
          y: 15 + (i % 2) * 10,
        },
        events: bandsEvents,
      };
    });
  };

  $scope.updateChartData = (drawOptions) => {
    const chartPromise = $http.get('/wash/charts/average_check', {params: $scope.group}).then((response) => {
      $scope.rawData = response.data;
    });

    const maPromise = MarketingActivity.queryAll().then((response) => {
      $scope.marketingActivities = response;
      $scope.bands = activitiesToBands($scope.marketingActivities);
    });

    const allPromise = $q.all([chartPromise, maPromise]);
    allPromise.then(updateChart);
  };
  $scope.updateChartData();

  $scope.$watch('group', $scope.updateChartData, true);
  $scope.$on('left-menu-state-changed', () => {
    $timeout(() => {
      if (!$scope.chart) {
        return;
      }
      $scope.chart.reflow();
    });
  });
});
