angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.admin.online_reservations', {
    url: '/online',
    controller: 'AdminOnlineReservationsIndexController',
    templateUrl: 'pages/admin/online_reservations/index.slim',
    data: {
      tabs: ['dashboard', 'service_locations', 'location_requests', 'mobile_users'],
    },
  });
});
