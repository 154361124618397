angular.module('RocketWash').controller(
  'AdminPartnersSettingAddOrganizationController',
  (
    $scope, $translate, User, Organization, resolvedPartner
  ) => {
    $scope.partnerId = resolvedPartner.id;

    $scope.getOrganizations = (query) => {
      var filters = [];
      filters.push({ attr: 'registered_by_partner_id', condition: 'blank' } )
      return Organization.allWithFilters({ search_query: query, filters: JSON.stringify(filters), });
    };

    $scope.save = () => {
      var organization = $scope.organization;
      organization.registeredByPartnerId = $scope.partnerId;
      var scope = $scope;
      organization.save().then((org) => {
        $scope.$close({ org });
      });
    };
  },
);
