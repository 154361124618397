import { env } from '@env';

angular.module('RocketWash').service('rwApi', ($http, userSession, alertService) => {
  const service = {
    requestOptions: ({method, path, data, params}) => {
      const apiUrl = `${env.apiUrl}/${env.apiVersion}/${path}`;
      const options = {
        method: method,
        url: apiUrl,
        data: data,
        params: params,
        headers: {
          'X-Rocketwash-Session-Id': userSession.session_id,
        },
      }
      if (userSession.organization) {
        options.headers['X-Rocketwash-Organization-Id'] = userSession.organization.id
      }

      return options
    },

    sendRequest: ({method, path, data, params}) => {
      const options = service.requestOptions({method, path, data, params})

      return $http(options).then((response) => {
        const data = response.data;

        if (data.status == 'success') {
          return data.data;
        } else {
          (data.errors || []).forEach((error) => {
            if (error.message.ru) {
              alertService.show({
                text: error.message.ru,
                type: 'error',
              });
            };
          });
          return {errors: data.errors};
        }
      });
    },

    sendSaveRequest: ({path, data, params}) => {
      const method = data.id ? 'PUT' : 'POST';

      return service.sendRequest({
        method,
        path,
        data,
        params,
      }).then((data) => {
        if (data && data.errors) {
          data.errors.forEach((error) => {
            alertService.show({
              text: error.message.ru,
              type: 'error',
            });
          });
        };

        return data;
      });
    },

  };

  return service;
});
