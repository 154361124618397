angular.module('RocketWash').controller('WashSettingsPaymentSubtypeFuelCardsBaseController', (
  $scope, $uibModal, PaymentSubtype, rwConfirmationService, Deduction,
) => {

  $scope.$on('search-change', (_event, data) => {
    $scope.searchQuery = data.value;
  });

  $scope.openFormPopup = (paymentSubtype) => {
    $uibModal.open({
      templateUrl: 'pages/wash/settings/payment_subtype_fuel_cards/form.slim',
      controller: 'WashSettingsPaymentSubtypeFuelCardsFormController',
      resolve: {
        resolvedPaymentSubtype: () => paymentSubtype,
      },
    }).result.finally(() => {
      setTimeout($scope.reloadPaymentSubtypes, 1000);
    });
  };

  $scope.deductionForSubtype = (paymentSubtype) => {
    return $scope.deductionsInSl.find(deduction => deduction.paymentSubtypeId === paymentSubtype.id);
  }

  $scope.save = (paymentSubtype) => {
    paymentSubtype.save().then($scope.reloadPaymentSubtypes);
  };

  $scope.reloadPaymentSubtypes = () => {
    PaymentSubtype.query({}, { cache: false }).then((paymentSubtypes) => {
      $scope.paymentSubtypes = _.sortBy(paymentSubtypes, 'name');
    });
    Deduction.query().then((deductionsInServiceLocation) => {
      $scope.deductionsInSl = deductionsInServiceLocation.filter(deduction => deduction.paymentSubtypeId !== null);
    });
  };

  $scope.reloadPaymentSubtypes();
});
