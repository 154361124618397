angular.module('RocketWash').controller('ReviewForCustomerController', function (
  $scope, $http, $stateParams, alertService, $translate
) {
  const reservationUUID = $stateParams.reservationUUID;

  $scope.loadData = () => {
    $http({
      url: `/wash/review_for_customers/${reservationUUID}`,
      method: 'GET',
      params: {
        uuid: reservationUUID,
      }
    }).then((response) => {
      $scope.review = response.data;
      $scope.reservation = $scope.review.reservation;

      $scope.reservation.duration = $scope.reservation.attached_services.map((as) => {
        return as.duration;
      }).reduce((a, b) => { return a + b });

      $scope.reservation.total_paid = $scope.reservation.attached_services.map((as) => {
        return as.rounded_price_with_corrections;
      }).reduce((a, b) => { return a + b });

      if ($scope.reservation.recognized_car_plate_images[0]) {
        $scope.reservation.car_plate_image = $scope.reservation.recognized_car_plate_images[0].image.url;
       }
    });
  }
  $scope.loadData();

  $scope.rated = (stars_count) => {
    $scope.review.stars_count = stars_count;
  };

  $scope.save = () => {
    if (!$scope.review.comment) {
      alertService.show({
        text: $translate.instant('reservation_review.alert.empty_comment'),
        type: 'error',
        timeout: 5000,
      });
      return;
    }

    $http({
      url: `/wash/review_for_customers`,
      method: 'POST',
      data: {
        stars_count: $scope.review.stars_count,
        comment: $scope.review.comment,
        uuid: reservationUUID,
      }
    }).then(() => {
      $scope.loadData();
    })
  }
}, );
