angular.module('RocketWash').controller('WashSettingsPermissionsController', function($rootScope, $scope, userSession, Role, Permission, RolesPermission, Plan) {
  var generateTree;
  $scope.userSession = userSession;
  $scope.permissionTree = {};
  generateTree = function() {
    var base, i, len, name, permission, ref, results, role;
    if ($scope.roles && $scope.permissions && $scope.rolesPermissions) {
      ref = $scope.roles;
      results = [];
      for (i = 0, len = ref.length; i < len; i++) {
        role = ref[i];
        (base = $scope.permissionTree)[name = role.id] || (base[name] = {});
        results.push((function() {
          var j, len1, ref1, results1;
          ref1 = $scope.permissions;
          results1 = [];
          for (j = 0, len1 = ref1.length; j < len1; j++) {
            permission = ref1[j];
            results1.push($scope.permissionTree[role.id][permission.id] = !!_.find($scope.rolesPermissions, function(rp) {
              return rp.roleId === role.id && rp.permissionId === permission.id;
            }));
          }
          return results1;
        })());
      }
      return results;
    }
  };
  Plan.current().then(function(plan) {
    return $scope.currentPlan = plan;
  });
  $scope.permissionIsInPlan = function(permissionId) {
    return $scope.currentPlan && _.find($scope.currentPlan.plans_permissions, function(pp) {
      return pp.permission_id === permissionId;
    });
  };
  Role.query().then(function(roles) {
    var order;
    order = {
      category_3: 1,
      category_2: 2,
      category_1: 3,
      accountant: 4,
      director: 5,
      organization_director: 6,
    };
    $scope.roles = _.sortBy(roles, function(r) {
      return order[r.name];
    });
    return generateTree();
  });
  Permission.query().then(function(permissions) {
    $scope.permissions = permissions;
    return generateTree();
  });
  RolesPermission.query().then(function(rolesPermissions) {
    $scope.rolesPermissions = _.map(rolesPermissions, function(o) {
      return new RolesPermission(o);
    });
    return generateTree();
  });
  $scope.triggerPermission = function(permission, role, presence) {
    var rp;
    if ($scope.permissionTree[role.id][permission.id]) {
      rp = new RolesPermission({
        permission_id: permission.id,
        role_id: role.id
      });
      rp.save().then(function(rp) {
        return $scope.rolesPermissions.push(new RolesPermission(rp));
      });
    } else {
      rp = _.find($scope.rolesPermissions, function(r) {
        return r.roleId === role.id && r.permissionId === permission.id;
      });
      rp["delete"]().then(function() {
        return $scope.rolesPermissions = _.without($scope.rolesPermissions, rp);
      });
    }
    return userSession.reload();
  };
  return $scope.permissionLevel = function(permission) {
    const level = (permission.ordinal.match(/\./g) || []).length;
    return `permission-level-${level}`;
  };
});
