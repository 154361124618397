angular.module('RocketWash').controller(
  'WashGetUrlQrAuthEmpIndexController',
  ($scope, $http, $timeout, $translate, $state, $stateParams, $cookies, userSession, alertService, $window,) => {
    $scope.progress = {
      reset_password: false
    };

    $scope.tgResetPassQr = () => {
      const domain = $window.location.host.split('.')[0]
      const params = !isNaN(domain) ? {org_id: domain} : {subdomain_name: domain}

      $http.get("/reset_passwords/get_url_qr_auth_emp", {params}).then((response) => {
        $scope.qr_url_data = response.data.url;
        $scope.qr_image_data = response.data.qr_code;
        $scope.imageData = 'data:image/png;base64,'+$scope.qr_image_data;
      });
    };

    $scope.tgResetPassQr();
  },
);
