angular.module('RocketWash').controller('TaxiServiceLocationsServicesController', (
  $scope, $q, $filter, $translate, $controller, Service, ServiceLocation, userSession,
) => {
  Service.configured().then((data) => {
    $scope.data = data;
  });


  $scope.getPLI = (service, carType) => {
    return service.price_list_items.find(item => item.car_type_id == carType.id);
  };
});
