angular.module('RocketWash').factory('Tag', (
  $http, railsResourceFactory, userSession,
) => {
  const Resource = railsResourceFactory({
    url: context => `/${context.queryRole || userSession.controllerScope()}/tags/${context.id || ''}`,
    name: 'tag',
  });

  Resource.typeahead = function typeahead(searchQuery, params) {
    return $http({
      method: 'GET',
      url: `${this.$url()}/typeahead`,
      params: angular.merge({ search_query: searchQuery }, params),
    }).then((response) => {
      return response.data.map(x => new this(x));
    });
  };

  return Resource;
});

