angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  $urlRouterProvider.when('/partner/organizations', '/partner/organizations/dashboard');
  $urlRouterProvider.when('/partner/organizations/{orgId}/settings', '/partner/organizations/{orgId}/settings/general');
  return $stateProvider.state('app.partner.organizations', {
    url: '/organizations',
    abstract: true,
    controller: 'PartnerOrganizationsIndexController',
    templateUrl: 'pages/partner/organizations/index.slim'
  }).state('app.partner.organizations.dashboard', {
    url: '/dashboard',
    views: {
      'content@app.partner.organizations': {
        controller: 'PartnerOrganizationsDashboardIndexController',
        templateUrl: 'pages/partner/organizations/dashboard/index.slim'
      }
    }
  }).state('app.partner.organizations.organization', {
    url: '/:orgId',
    abstract: true
  });
});
