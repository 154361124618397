angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.settings.kkm', {
    url: '/kkm',
    tab: 'kkm',
    data: {
      permission: 'KkmTab',
    },
    views: {
      'content@app.wash.settings': {
        controller: 'WashSettingsKkmController',
        templateUrl: 'pages/wash/settings/kkm/index.slim'
      }
    }
  });
});
