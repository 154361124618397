angular.module('RocketWash').factory(
  'ApiKey',
  ($http, railsResourceFactory, userSession, TenantSwitchInterceptor,) => {
    const resource = railsResourceFactory({
      url: (context) => { return "/" + (context.queryRole || userSession.controllerScope()) + "/api_keys/" + (context.id || '') },
      name: 'api_key',
    });

    resource.addInterceptor(TenantSwitchInterceptor);

    return resource;
  },
);
