const DEFAULT_FROM_TO_FIELD = 'created_at';

angular.module('RocketWash').service('paginator', ($http, progress, dateRangeStorage) => {
  const paginatorService = {
    getData: (pds) => {
      if (!pds) {
        console.error('pds argument required');
      }
      progress.show();
      const pg = pds.pg;

      const filters = (pds.selectedFilters || []).map((filter) => {
        return _.pick(filter, 'attr', 'condition', 'value');
      });

      const fromToField =
        pg && pg.query && pg.query.fromToField ||
        DEFAULT_FROM_TO_FIELD;

      if (!pg.query.skipDateFilter) {
        filters.push({
          attr: fromToField,
          condition: 'greater_or_equals',
          value: dateRangeStorage.from().format(),
        });
      }
      if (!pg.query.skipDateFilter) {
        filters.push({
          attr: fromToField,
          condition: 'less',
          value: dateRangeStorage.to().format(),
        });
      }

      if (pds.filters && pds.filters.beforeSend) {
        pds.filters.beforeSend(filters);
      }

      angular.extend(pg.query, {
        from: dateRangeStorage.from().format(),
        to: dateRangeStorage.to().format(),
        filters: JSON.stringify(filters),
      });

      return $http({
        url: pg.url,
        headers: pg.headers,
        params: pg.query,
      }).then((response) => {
        let items = response.data.items ? response.data.items : response.data;
        if (pg.model) {
          items = items.map(x => new pg.model(x));
        }
        angular.extend(pg, { items, total: response.data.total });
        progress.hide();
        return items;
      }, (error) => {
        console.log(error);
        progress.hide();
      });
    },
  };

  return paginatorService;
});
