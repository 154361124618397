angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.admin.online_reservations.location_requests', {
    url: '/location_requests',
    views: {
      'content@app.admin.online_reservations': {
        controller: 'AdminOnlineReservationsLocationRequestsIndexController',
        templateUrl: 'pages/admin/online_reservations/location_requests/index.slim'
      }
    }
  });
});
