

angular.module('RocketWash').directive('focusMe', function($timeout, $parse) {
  return {
    link: function(scope, element, attrs) {
      return scope.$watch(attrs.focusMe, function(val) {
        if (angular.isDefined(val) && val) {
          return $timeout(function() {
            return element[0].focus();
          });
        }
      });
    }
  };
});
