angular.module('RocketWash').controller('AdminChartsRwIncomeController', (
  $scope, $http, $translate, $timeout,
) => {
  $scope.ranges = ['day', 'week', 'month'];

  $scope.group = {
    range: $scope.ranges[2],
    field: 'organization_id',
    sumAll: true,
    incomeOutcome: 'outcome',
  };


  const updateChart = function () {
    let ac;
    let ref;
    let ref1;
    let timestamp;
    let update;
    $scope.groupedData = _.groupBy($scope.rawData, (point) => {
      let date;
      date = moment(point.timestamp);
      if ($scope.group.range) {
        date = date.startOf($scope.group.range);
      }
      return +date;
    });
    const series = Object.keys($scope.rawData).map((key, index) => {
      let value = $scope.rawData[key];
      return {
        type: 'area',
        index: index,
        marker: {
          symbol: 'circle',
          enabled: false,
        },
        // color: '#aaffd4',
        name: key,
        data: value,
      };
    });

    if ((ref1 = $scope.chart) != null) {
      ref1.destroy();
    }
    update = function () {
      if ($('#charts-container-organizations').length) {
        return $scope.chart = new Highcharts.StockChart({
          yAxis: {
            title: {
              text: 'Количество организаций',
            },
          },
          title: {
            text: 'Активность организаций',
          },
          subtitle: {
            text: null,
          },
          rangeSelector: {
            selected: 0,
          },
          legend: {
            backgroundColor: '#f4f4f4',
            borderColor: '#f2f2f2',
          },
          chart: {
            renderTo: 'charts-container-organizations',
            backgroundColor: '#ffffff',
            type: 'spline',
            plotBackgroundColor: '#ffffff',
            zoomType: 'xy',
          },
          plotOptions: {
            series: {
            stacking: 'normal',
            turboThreshold: 10000,
              dataLabels: {
                enabled: true,
              },
            },
          },
          xAxis: {
            type: 'datetime',
          },
          series: series,
        });
      }
    };
    return $timeout(update);
  };

  $scope.reload = () => {
    $http({
      url: `${$scope.env.apiUrl}/admin/charts/rw_income`,
      method: 'GET',
      params: {
        interval: $scope.group.range,
        field: $scope.group.field,
        sumAll: $scope.group.sumAll,
        incomeOutcome: $scope.group.incomeOutcome,
      },
    }).then((response) => {
      $scope.rawData = response.data;
      updateChart();
    });
  };

  $scope.reload();

  $scope.$watch('group', $scope.reload, true);
});
