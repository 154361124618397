import { env } from '@env';
angular.module('RocketWash').controller(
  'PartnerSignUpController',
  ($scope, $http, $timeout, $translate, $state, userSession, alertService, Plan) => {
    $scope.alertService = alertService;

    $scope.userOptions = {
      role: 'partner',
    };

    $scope.progress = { registration: false };

    const runValidations = () => {
      let errors = [];

      if (!$scope.userOptions.organization_name) {
        errors.push('organization_name');
      }

      if (!$scope.userOptions.user_name) {
        errors.push('user_name');
      }

      if (!$scope.userOptions.user_phone || $scope.userOptions.user_phone.indexOf('_') >= 0) {
        errors.push('user_phone');
      }

      if (!$scope.userOptions.user_email) {
        errors.push('user_email');
      }

      errors.forEach((error) => {
        alertService.show({
          text: $translate.instant(`sign_up.errors.${error}`),
          type: 'error'
        });
      });

      return errors.length == 0;
    };

    $scope.register = () => {
      const valid = runValidations();

      if (!valid) { return };

      $scope.progress.registration = true;
      alertService.show({
        text: $translate.instant('sign_up.wait_please'),
      });

      $http.post('/registrations', $scope.userOptions).then((response) => {
        $scope.progress.registration = false;
        alertService.show({
          text: $translate.instant('sign_up.success')
        });

        let user = response.data.user;

        let newUrl = window.location.href.replace(
          new RegExp(`(https?:\\/\\/)(.*)(${env.appSubdomains})(\\.rocketwash[^\\/]*).*`),
          '$1partners.$3$4/#!/sign_in?just_registered=true&phone=' + user.phone)
        window.location.href = newUrl;
      }, () => {
        $scope.progress.registration = false;
        alertService.show({
          text: $translate.instant('errors.other'),
          type: 'error',
          timeout: 60000
        });
      });
    };
  },
);
