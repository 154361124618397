angular.module('RocketWash').controller('RWServiceFoldersFormController', (
  $scope, ServiceFolder, serviceFolder, parent,
) => {
  if (serviceFolder) {
    $scope.serviceFolder = new ServiceFolder(serviceFolder);
  } else {
    let parentId = parent ? parent.id : null;
    $scope.serviceFolder = new ServiceFolder({
      parentId: parentId,
      extraChargePercent: 20,
    });
  }

  $scope.save = () => {
    $scope.formDisabled = true;
    $scope.serviceFolder.save().then((serviceFolder) => {
      if (_.isEmpty(serviceFolder.errors)) {
        $scope.$dismiss();
      } else {
        $scope.errors = { create_service_folder: serviceFolder.errors };
      }
    }).finally(() => { $scope.formDisabled = false; });
  };
});
