angular.module('RocketWash').factory('ReservationReviewRatingScale', (
  railsResourceFactory, userSession,
) => {
  const Resource = railsResourceFactory({
    url: context => `/${context.queryRole || userSession.controllerScope()}/reservation_review_rating_scales/${context.id || ''}`,
    name: 'reservation_review_rating_scale',
  });

  return Resource;
});
