angular.module('RocketWash').controller('WashWarehouseWarehouseOperationsFormController', (
  $scope, $translate, alertService, Service, Product, Warehouse, WarehouseOperation,
  WarehouseOperationItem, Customer, userSession, WAREHOUSE_OPERATION_FORM_CONFIG,
  resolvedWarehouseOperation, warehouseForm, printService, PrintableDocument,
) => {
  $scope.selected = {};
  $scope.tabIndex = 0;
  $scope.itemsScope = 0;
  $scope.progress = { createProduct: false, addingProduct: false };
  $scope.edited = false;
  $scope.disableStatus = false;

  $scope.activeTabs = () => {
    if (!$scope.warehouseOperation) {
      return [];
    }
    if ($scope.formConfig && $scope.formConfig.hasPayments) {
      return ['products', 'sale_payments',]; // 'history'
    }
    return ['products',]; //'history'
  };

  const setDefaultWarehouse = (wo) => {
    switch (wo.type) {
      case 'WarehouseOperationSale': {
        const result = $scope.warehouses.find(x => x.defaultForSale);
        wo.fromId = result && result.id;
        warehouseForm.setWarehouseId(wo.fromId);
        break;
      }
      case 'WarehouseOperationArrival': {
        const result = $scope.warehouses.find(x => x.defaultForArrival);
        wo.toId = result && result.id;
        warehouseForm.setWarehouseId(wo.toId);
        break;
      }
      default: break;
    }
  };

  $scope.save = ({ closeOnSuccess, callback } = { closeOnSuccess: true, callback: undefined }) => {
    if (!event.detail || event.detail == 1) {
      $scope.formDisabled = true;
      $scope.warehouseOperation.save().then((warehouseOperation) => {
        if (_.isEmpty(warehouseOperation.errors) && !_.isEmpty(warehouseOperation.warehouseOperationItems)) {
          if (callback) {
            callback();
          }

          if (closeOnSuccess) {
            $scope.$dismiss();
          }
        } else {
          $scope.errors = { create_warehouse_operation: warehouseOperation.errors };
        }
      }).finally(() => {
        $scope.formDisabled = false;
      });
    }
  };

  $scope.getProducts = (value) => {
    return Service.typeahead(value, {
      serviceType: 'Product',
      warehouseId: $scope.warehouseOperation.toId || $scope.warehouseOperation.fromId,
    }).then((response) => {
      const items = response.data.map(item => new Product(item));

      items.forEach((product) => {
        product.line1 = [product.name].join(' ');
        product.line2 = [$translate.instant('warehouse.warehouse_operations.form.available_amount'), product.count || 0, product.unit,
          '   ', $translate.instant('warehouse.warehouse_operations.form.article'), product.article,
        $translate.instant('warehouse.warehouse_operations.form.barcode'), product.barcode].join(' ');
      });

      return items;
    });
  };

  $scope.getUsers = (value, options = { supplier: false }) => {
    return Customer.typeahead(value, { supplier: options.supplier }).then((items) => {
      items.forEach((user) => {
        user.line1 = [user.name, user.phone].join(' ');
        user.line2 = [$translate.instant(`warehouse.warehouse_operations.form.user_types.${user.userType}`), user.email].join(' ');
      });

      return items;
    });
  };

  const getPrice = (product) => {
    let safePrice =
      product.priceListItems &&
      product.priceListItems[0] &&
      product.priceListItems[0].price;
    if ($scope.warehouseOperation.type == 'WarehouseOperationInternalTransfer') {
      safePrice = 0;
    }

    return safePrice;
  };

  $scope.addProduct = (product) => {
    if (!product) {
      return;
    }

    const existingItem = $scope.warehouseOperation.warehouseOperationItems.find(i => i.productId == product.id);
    if (existingItem) {
      alertService.show({
        text: 'Этот продукт уже присутствует в списке!',
        timeout: 5000,
        type: 'error',
      });
      return;
    }

    let safePrice = getPrice(product);
    const woItem = new WarehouseOperationItem({
      product: product,
      productId: product.id,
      productName: product.name,
      discount: $scope.warehouseOperation.discount,
      price: safePrice,
      avgItemsPrice: product.avgItemsPrice,
      count: 1,
      unit: product.compatibleUnits[0],
    });
    $scope.warehouseOperation.warehouseOperationItems.push(woItem);
    $scope.selected.product = null;
  };

  $scope.unitsChanged = (woItem) => {
    if ($scope.warehouseOperation.type == 'WarehouseOperationSale') {
      let safePrice = getPrice(woItem.product); // price for package
      if (woItem.unit != woItem.product.compatibleUnits[0]) { // if measure unit selected
        safePrice = safePrice * woItem.product.priceListItems[0].unitCoefficient[woItem.unit];
      };
      woItem.price = safePrice;
    };
  };

  $scope.removeProduct = (product) => {
    product._destroy = true;
    $scope.changeWarehouseDisable('remove');
  };

  $scope.productSelected = (woItem) => {
    woItem.productId = woItem.product.id;
    woItem.productName = woItem.product.name;
  };

  $scope.resetSelect = () => {
    warehouseForm.resetWarehouseSelect();
  };

  $scope.changeWarehouseDisable = (item) => {
    switch (item) {
      case 'remove':
        $scope.itemsScope -= 1;
      case 'show':
        if (($scope.itemsScope + warehouseForm.getWarehouseSelect()) === 0) {
          $scope.disableStatus = false;
          return false;
        } else {
          $scope.disableStatus = true;
          return true;
        }
    }
  };

  $scope.userSelected = (user) => {
    $scope.warehouseOperation.user = user;
    $scope.warehouseOperation.userId = user && user.id;
  };

  $scope.subtotalSum = (product) => {
    return (
      (parseFloat(product.price) || 0) *
      (parseFloat(product.count) || 0) *
      (1 - (parseInt(product.discount, 10) || 0) / 100)
    );
  };

  $scope.totalSum = () => {
    const sum = ($scope.warehouseOperation.warehouseOperationItems || [])
      .filter(x => !x._destroy)
      .map(x => $scope.subtotalSum(x))
      .reduce((x, y) => x + y, 0);
    return +sum.toFixed(2);
  };

  $scope.paidAtUpdated = (paidAt) => {
    $scope.warehouseOperation.paidAt = paidAt;
  };

  $scope.showAlert = () => {
    if ($scope.disableStatus === true) {
      alertService.show({
        text: $translate.instant('create_warehouse_operation.alert.change_warehouse'),
        timeout: 5000,
      });
    }
  };

  $scope.changeWarehouse = () => {
    warehouseForm.setWarehouseId($scope.warehouseOperation.fromId || $scope.warehouseOperation.toId);
  };

  // Query

  Warehouse.query().then((warehouses) => {
    $scope.warehouses = warehouses;
    if ($scope.warehouseOperation.type === "WarehouseOperationInternalTransfer") {
      Warehouse.organization_scope(true).then((warehouses) => {
        $scope.warehouses = warehouses.data;
      });
    }
    if ($scope.warehouseOperation.id) {
      return;
    }
    setDefaultWarehouse($scope.warehouseOperation);
    warehouseForm.setWarehouseId($scope.warehouseOperation.fromId || $scope.warehouseOperation.toId);
  });

  // Code

  $scope.warehouseOperation = new WarehouseOperation(resolvedWarehouseOperation);
  $scope.formConfig = WAREHOUSE_OPERATION_FORM_CONFIG[$scope.warehouseOperation.type];
  warehouseForm.setWarehouseOperationType($scope.warehouseOperation.type);

  if ($scope.warehouseOperation.id) {
    $scope.warehouseOperation.get().then(() => {
      $scope.selected.user = $scope.warehouseOperation.user;
      warehouseForm.setWarehouseId($scope.warehouseOperation.toId || $scope.warehouseOperation.fromId);
      $scope.edited = true;
    });
  } else {
    $scope.warehouseOperation.responsibleUser = { name: userSession.name };
    $scope.warehouseOperation.warehouseOperationItems =
      $scope.warehouseOperation.warehouseOperationItems || [];
    $scope.warehouseOperation.date = $scope.warehouseOperation.date || new Date();
  }

  PrintableDocument.query().then((docs) => {
    docs = docs.filter(x => x.subjectType === 'warehouse_operation');
    $scope.documents = docs;
    $scope.documents.forEach((doc) => {
      doc.selected = doc.default;
    });
  });

  const printDocuments = () => {
    printService.printToAll({
      targetId: $scope.warehouseOperation.id,
      targetType: 'WarehouseOperation',
      docs: $scope.documents.filter(d => d.selected),
    });
  };

  $scope.print = () => {
    $scope.save({ closeOnSuccess: false, callback: printDocuments });
  };
});
