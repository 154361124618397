angular.module('RocketWash').directive('rwOnImageLoad', function($timeout) {
  return {
    restrict: 'A',
    link: function(scope, element, attrs) {
      element.bind('load', function() {
        scope.$apply(attrs.rwOnImageLoad);
      });
    }
  };
});
