angular.module('RocketWash').config(($stateProvider, $urlRouterProvider) => {
  $urlRouterProvider.when('/wash/printed_documents', '/wash/printed_documents/reconciliation_report');
  $stateProvider.state('app.wash.printed_documents', {
    url: '/printed_documents',
    abstract: true,
    controller: 'WashPrintedDocumentsBaseController',
    templateUrl: 'pages/wash/printed_documents/index.slim',
    data: {
      permission: 'PrintedDocumentsMenuItem',
      tabs: ['reconciliation_report', 'reciept', 'completed_services', ]
    },
  });
});
