angular.module('RocketWash').controller('WashSettingsCarTypesBaseController', (
  $scope, $uibModal, CarType, rwConfirmationService, ctgSelectService, ctSelectService, ServiceLocation, userSession,
) => {
  $scope.userSession = userSession;
  $scope.ctgSelectService = ctgSelectService;

  $scope.reloadCarTypes = () => {
    if (ctgSelectService.selected) {
      return CarType.query({car_types_group_id: ctgSelectService.selected.id}, { cache: false }).then((carTypes) => {
        $scope.carTypes = carTypes;
      });
    }
  };

  $scope.loadServiceLocation= () => {
    ServiceLocation.get($scope.userSession.service_location.id).then((sl) => {
      $scope.sl = sl;
    });
  };


  $scope.$on('ctgSelectService-selected', $scope.reloadCarTypes)

  $scope.delete = (carType) => {
    rwConfirmationService.confirm(
      { textTranslation: 'alert.are_you_sure' },
      () => {
        carType.delete().then(() => {
          ctSelectService.reload();
          $scope.reloadCarTypes();
        });
      },
    );
  };

  $scope.$on('search-change', (_event, data) => {
    $scope.searchQuery = data.value;
  });

  $scope.applyCarTypesGroup = () => {
    $uibModal.open({
      templateUrl: 'pages/wash/settings/car_types/set_car_types_group.slim',
      controller: 'WashSettingsCarTypesSetCarTypesGroupController',
    }).result.then(() => {
      location.reload();
      ctSelectService.reload();
    });
  };

  $scope.openFormPopup = (carType) => {
    if (!carType) {
      carType = {
        carTypesGroupId: ctgSelectService.selected.id
      }
    }

    $uibModal.open({
      templateUrl: 'pages/wash/settings/car_types/form.slim',
      controller: 'WashSettingsCarTypesFormController',
      resolve: {
        resolvedCarType: () => carType,
      },
    }).result.finally(() => {
      setTimeout(() => {
        $scope.reloadCarTypes();
        ctSelectService.reload();
      }, 1000);
    });
  };

  $scope.save = (carType) => {
    carType.save().then(() => {
      $scope.reloadCarTypes();
      ctSelectService.reload();
    });
  };

  $scope.reloadCarTypes();
  $scope.loadServiceLocation();
});
