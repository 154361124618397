angular.module('RocketWash').controller('WashCounterpartiesBaseController', (
  $scope, $translate, $state, PriceList,
  viewStore, Bank, CarType, CarMake, serverConstants,
) => {
  // Initialization
  $scope.sex = serverConstants.user_sexes;
  $scope.types = serverConstants.user_types;
  $scope.contractors = [];

  // Query
  Bank.query().then((data) => {
    $scope.banks = data;
  });
  CarType.query().then((data) => {
    $scope.carTypes = data;
  });
  $scope.carMakesPromise = CarMake.query().then((data) => {
    $scope.carMakes = data;
  });
  PriceList.all().then((data) => {
    $scope.priceLists = _.sortBy(data, x => x.name);
  });

  // Helpers
  $scope.sexIdToName = (sexId) => {
    const sex = $scope.sex.find(x => x.id === sexId);
    return sex && sex.name;
  };
  $scope.validate = {
    tin(tin) {
      tin = tin.toString();
      if (!tin || tin.length < 8 || tin.length > 10 || tin.match(/[^\d]/)) {
        return $translate.instant('errors.create_contractor.tin');
      }
      return null;
    },
    kpp(kpp) {
      kpp = kpp.toString();
      if (!kpp || kpp.length !== 9 || kpp.match(/[^\d]/)) {
        return $translate.instant('errors.create_contractor.kpp');
      }
      return null;
    },
    accountNumber(accountNumber) {
      accountNumber = accountNumber.toString();
      if (!accountNumber || accountNumber.length > 20 || accountNumber.match(/[^\d]/)) {
        return $translate.instant('errors.create_contractor.account_number');
      }
      return null;
    },
  };
  $scope.edit = (contractor) => {
    viewStore.store({ contractor });
    return $state.go('app.wash.counterparties.clients.edit.info');
  };
  $scope.cancelEditing = () => {
    viewStore.clear();
    $state.go('^.^');
  };
});
