angular.module('RocketWash').controller('WashSettingsUserCategoriesController', function($scope, SettingValue, UserCategory, userSession, serverConstants) {
  $scope.SETTING_VALUES = serverConstants.setting_values;
  UserCategory.query().then(function(userCategories) {
    return $scope.userCategories = _.map(userCategories, function(uc) {
      return new UserCategory(uc);
    });
  });
  $scope.save = function(uc) {
    return uc.save();
  };
  $scope.saveRule = function(rule) {
    return rule.save();
  };
  $scope.newUserCategory = function() {
    return $scope.userCategories.push(new UserCategory);
  };
  $scope.deleteUserCategory = function(uc) {
    return uc["delete"]().then(function() {
      return $scope.userCategories = _.without($scope.userCategories, uc);
    });
  };
  return $scope.refreshAll = function() {
    return UserCategory.recountAllForOrganization();
  };
});
