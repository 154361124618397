

angular.module('RocketWash').controller('WashDashboardStatisticsForCashShiftController', 
function ($scope, $http, $translate, $state, viewStore, $filter, userSession, rwApi,
  CashShift, SettingValue, Device, $mdDialog, PrintableDocument, printService, $timeout) {
  
    var closeCashShiftAndSave, createAndSaveCashShift, loadData, reloadCurrentShift;
  (loadData = function(options) {
    return $http.post('/wash/statistics/for_cash_shift', options).then(function(resp) {      
      return $scope.data = resp.data;
    });
  })({});
  $scope.prevCS = function() {
    $scope.leftRightDisabled = true;
    return loadData({
      cs_id: $scope.data.cash_shift.id,
      prev: true
    }).then(function() {
      return $scope.leftRightDisabled = false;
    });
  };
  $scope.nextCS = function() {
    $scope.leftRightDisabled = true;
    return loadData({
      cs_id: $scope.data.cash_shift.id,
      next: true
    }).then(function() {
      return $scope.leftRightDisabled = false;
    });
  };

  SettingValue.query().then(function(values) {
    var ref;
    values = _.map(values, function(v) {
      return new SettingValue(v);
    });
    return $scope.useCashMachine = ((ref = _.find(values, function(v) {
      return v.key === 'use_cash_machine';
    })) != null ? ref.value : void 0) === 'yes';
  });
  (reloadCurrentShift = function() {
    return rwApi.sendRequest({
      method: 'GET',
      path: 'cash_shifts/current',
      params: {
        service_location_id: userSession.service_location_id,
      }
    }).then(function(shift) {
      $scope.currentCashShiftLoaded = true;
      $scope.currentCashShift = shift;
      return $scope.noCurrentCashShift = !(shift && shift.id);
    });
  })();
  createAndSaveCashShift = function() {
    return rwApi.sendRequest({
      method: 'POST',
      path: 'cash_shifts/open',
      data: {
        service_location_id: userSession.service_location_id,
      }
    }).then(function(savedCs) {
      $scope.leftRightDisabled = true;
      reloadCurrentShift();
      return loadData({
        cs_id: savedCs.id
      }).then(function() {
        alertService.show({
          text: 'Кассовая смена успешно открыта в программе',
        })

        return $scope.leftRightDisabled = false;
      });
    });
  };
  $scope.newCashShift = function() {
    triggerLadda();
    if ($scope.useCashMachine) {
      if (confirm($filter('translate')('shifts.cash_shifts.openShiftWithCashRegister'))) {
        if (userSession.useModuleKassa()) {
          // Do nothing
          Device.openShift();
        } else {
          Device.openShift(() => {
            createAndSaveCashShift();
          });
        }
      }
    } else {
      return createAndSaveCashShift();
    }
  };
  closeCashShiftAndSave = function(cashShift) {
    return rwApi.sendRequest({
      method: 'POST',
      path: 'cash_shifts/close',
      data: {
        id: cashShift.id,
      }
    }).then((savedCs) => {
      reloadCurrentShift();
      return loadData({
        cs_id: savedCs.id
      }).then(function() {
        alertService.show({
          text: 'Кассовая смена успешно закрыта в программе',
        })

        return $scope.leftRightDisabled = false;
      });
    })
  };
  $scope.closeCashShift = function() {
    var cashShift;
    triggerLadda();
    cashShift = $scope.currentCashShift;
    if ($scope.useCashMachine) {
      if (confirm($filter('translate')('shifts.cash_shifts.closeShiftWithCashRegister'))) {
        if (userSession.useModuleKassa()) {
          Device.closeShift();
        } else {
          Device.closeShift(() => {
            closeCashShiftAndSave(cashShift);
          });
        }
      }
    } else {
      return closeCashShiftAndSave(cashShift);
    }
  };
  $scope.acquiringTerminalReport = function() {
    if (confirm($filter('translate')('shifts.cash_shifts.acquiringTerminalReportCashRegister'))) {
      triggerLadda();
      Device.acquiringTerminalReport();
    }
  };

  $scope.xReport = function() {
    if (confirm($filter('translate')('shifts.cash_shifts.xReportCashRegister'))) {
      triggerLadda();
      Device.xReport();
    }
  };

  PrintableDocument.query().then((docs) => {
    docs = docs.filter(x => x.subjectType === 'cash_shift');
    $scope.documents = docs;
    $scope.documents.forEach((doc) => {
      doc.selected = doc.default;
    });
  });

  $scope.printDocuments = () => {  
    $scope.selectedShift = $scope.data.cash_shift;
    $scope.selectedShift.data = $scope.data;

    printService.printToAll({
      targetId: $scope.selectedShift.id,
      targetType: 'CashShift',
      docs: $scope.documents.filter(d => d.selected),
    });
  };

  $scope.print = () => {  
    $scope.printDocuments();
  };

  $scope.progress = {
    asyncOperation: undefined,
  };
  const triggerLadda = () => {
    $scope.progress.asyncOperation = true;
    $timeout(() => {
      $scope.progress.asyncOperation = undefined;
    }, 1000);
  };

  return $scope.cashInOut = function(ev) {
    return $mdDialog.show({
      targetEvent: ev,
      parent: angular.element(document.body),
      templateUrl: 'pages/wash/finance/cash_shifts/cash_in_out_modal.slim',
      clickOutsideToClose: true,
      controller: function($scope, $mdDialog) {
        $scope.closeDialog = function(params) {
          $mdDialog.hide(params);
        };
      }
    }).then(function(result) {
      result.amount = parseFloat(result.amount);
      if (result.direction === 'in') {
        if (confirm($filter('translate')('shifts.cash_shifts.cashInOutAreYouSureIn', {
          amount: result.amount,
          currency: userSession.service_location.currency
        }))) {
          Device.cashIn(result.amount);
        }
      } else {
        if (confirm($filter('translate')('shifts.cash_shifts.cashInOutAreYouSureOut', {
          amount: result.amount,
          currency: userSession.service_location.currency
        }))) {
          Device.cashOut(result.amount);
        }
      }
    });
  };
  });
