angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.settings.lane_cameras', {
    url: '/lane_cameras',
    tab: 'lane_cameras',
    data: {
      permission: 'LaneCamerasTab'
    },
    views: {
      'content@app.wash.settings': {
        controller: 'WashSettingsLaneCamerasBaseController',
        templateUrl: 'pages/wash/settings/lane_cameras/index.slim'
      }
    }
  });
});
