angular.module('RocketWash').controller('WashLogsUserCategoriesBaseController', (
  $scope, UserCategoryLog, paginator,
) => {
  $scope.pg = {
    url: '/wash/user_category_logs',
    model: UserCategoryLog,
    query: {
      from: moment().startOf('day').format('DD/MM/YYYY'),
      to: moment().add(1, 'day').startOf('day').format('DD/MM/YYYY'),
      searchQuery: '',
      predicate: 'createdAt',
      reverse: true,
    },
  };
  $scope.updatePage = function (page, limit) {
    if (_.isNumber(page)) {
      $scope.pg.query.page = page;
    } else {
      $scope.pg.query.page = 1;
      $scope.pg.query.reverse = !$scope.pg.query.reverse;
    }
    $scope.pg.query.per = limit || 10;
    paginator.getData($scope.pg);
  };
});
