const beautifyHtml = require('js-beautify').html;

angular.module('RocketWash').directive('rwWysiwyg', ($uibModal) => {
  return {
    templateUrl: 'directives/rw-wysiwyg/rw-wysiwyg.slim',
    restrict: 'E',
    require: '?ngModel',
    link: function postLink($scope, element, attrs, ngModel) {

      const sn = $('#rw-wysiwyg');

      const VariablesInfoButton = function (context) {
        const ui = $.summernote.ui;

        const button = ui.button({
          contents: '<i class="fa fa-question-circle"/> Переменные',
          tooltip: 'Format',
          click: () => {
            $uibModal.open({
              templateUrl: 'pages/wash/settings/printable_documents/variables_info_popup.slim',
              controller: 'WashSettingsPrintableDocumentsVariablesInfoPopupController',
            });
          }
        });

        return button.render();   // return button as jquery object
      }


      sn.summernote({
        height: 500,
        callbacks: {
          onChange: (contents) => {
            ngModel.$setViewValue(contents);
          },
          onBlur: () => {
          }
        },

        toolbar: [
          ['style', ['bold', 'italic', 'underline', 'clear']],
          ['font', ['fontname']],
          ['fontsize', ['fontsize']],
          ['color', ['color', 'forecolor', 'backcolor']],
          ['para', ['ul', 'ol', 'paragraph']],
          ['height', ['height']],
          ['insert', ['link', 'picture', 'video', 'hr', 'table']],
          ['misc', ['codeview', 'fullscreen']],
          ['other', ['variablesInfo']],
        ],

        buttons: {
          variablesInfo: VariablesInfoButton
        }
      });

      ngModel.$render = function() {
        sn.summernote('code', ngModel.$viewValue);
      };

      $('rw-wysiwyg .btn-codeview').on('mousedown', () => {
        $scope.$apply(() => {
          ngModel.$setViewValue(beautifyHtml(ngModel.$viewValue));
          sn.summernote('code', ngModel.$viewValue);
        });
      });

      $scope.$on('$destroy', () => {});
    }
  };
});
