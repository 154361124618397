angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.settings.import', {
    url: '/import',
    tab: 'import',
    data: {
      permission: 'CommonSettingsTab'
    },
    views: {
      'content@app.wash.settings': {
        controller: 'WashSettingsImportIndexController',
        templateUrl: 'pages/wash/settings/import/index.slim'
      }
    }
  });
});
