angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.charts.reconciliation_report', {
    url: '/reconciliation_report',
    tab: 'reconciliation_report',
    data: {
      permission: 'ReconciliationReportTab'
    },
    views: {
      'content@app.wash.charts': {
        controller: 'WashChartsReconciliationReportController',
        templateUrl: 'pages/wash/charts/reconciliation_report/index.slim'
      }
    }
  });
});
