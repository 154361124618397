angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  $urlRouterProvider.when('/wash', '/wash/income/report');
  return $stateProvider.state('app.wash', {
    url: '/wash',
    abstract: true,
    templateUrl: 'pages/wash/index.slim',
    resolve: {
      us: function(userSession) {
        return userSession.promise;
      }
    }
  });
});
