angular.module('RocketWash').component('rwReservationGeneralInfo', {
  templateUrl: 'components/rw-reservation-general-info/index.slim',
  bindings: {
  },
  controllerAs: 'vm',
  controller: function ($scope, reservationForm) {

    $scope.rf = reservationForm;

    const vm = this;

  },
});
