

var extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

angular.module('RocketWash').factory('CarModel', function($http, railsResourceFactory, userSession) {
  var CarModel, CarModelFactory;
  CarModelFactory = railsResourceFactory({
    url: (context) => { return "/" + (context.queryRole || userSession.controllerScope()) + "/car_models/" + (context.id || '') },
    name: "car_model"
  });
  return CarModel = (function(superClass) {
    extend(CarModel, superClass);

    function CarModel() {
      return CarModel.__super__.constructor.apply(this, arguments);
    }

    CarModel.query = function(queryParams, context) {
      return $http({
        url: this.resourceUrl(context),
        params: queryParams,
        method: 'GET',
        cache: true
      }).then(function(response) {
        return response.data;
      });
    };

    CarModel.prototype.searchImages = function() {
      return $http({
        url: `${CarModel.resourceUrl(this)}/search_images`,
        method: 'GET',
        cache: true
      }).then(function(response) {
        return response.data;
      });
    };

    CarModel.mergeTwoCarModel = (params = {}) => {
      return $http({
        method: 'POST',
        url: `${CarModelFactory.resourceUrl()}/merge_two_car_models`,
        data: params,
      });
    };

    CarModel.checkMergeTwoCarModel = (params = {}) => {
      return $http({
        method: 'POST',
        url: `${CarModelFactory.resourceUrl()}/check_merge_two_car_models`,
        data: params,
      });
    };


    return CarModel;

  })(CarModelFactory);
});
