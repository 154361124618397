angular.module('RocketWash').controller(
  'WashServicesPriceListsBaseController',
  ($scope, $timeout, $translate, $uibModal, viewStore, PriceList, CarType, rwConfirmationService, plSelectService, userSession, alertService) => {

    const rolesWithAccessToDownloadCustomers = ['support_officer', 'organization_director'];

    $scope.accessToPersonalInfo = userSession.superuser ||
      rolesWithAccessToDownloadCustomers.some(role => userSession.is(role));

    $scope.pds = $scope.pageDataService.new({
      page: 'price_lists',
      filters: {
        attributes: [
          { name: 'name', type: 'string' },
        ],
        scopes: [
          'name', 'user', 'description',
        ],
        beforeSend: (filters) => {
          filters.push({
            attr: 'price_list_type.sortable',
            condition: 'equals',
            value: 'pricelistservice',
          });
          filters.push({
            attr: 'car_types_group_id',
            condition: 'equals',
            value: $scope.ctgSelectService.selected && $scope.ctgSelectService.selected.id,
          });
        },
      },
      pg: {
        url: '/wash/price_lists',
        model: PriceList,
        query: {
          skipDateFilter: true,
          predicate: 'name',
          page: 1,
          per: 10,
        },
      },
      definePaginateMethodsOn: $scope,
    });
    // Actions

    $scope.newPriceList = () => {
      const priceList = new PriceList();
      priceList.carTypesGroupId = $scope.ctgSelectService.selected.id,
      priceList.type = 'PriceListService';
      $scope.edit(priceList);
    };

    $scope.edit = (priceList) => {
      $uibModal.open({
        templateUrl: 'pages/wash/services/price_lists/form.slim',
        controller: 'WashServicesPriceListsFormController',
        resolve: {
          priceList: () => (priceList),
        }
      }).result.finally(() => {
        $timeout(() => {
          $scope.updatePage();
          plSelectService.reload();
        }, 500);
      });;
    };

    $scope.downloadXLS = (plId) => {
      $uibModal.open({
        templateUrl: 'pages/common/email_popup/email_popup.slim',
        controller: 'CommonEmailPopupController',
        windowClass: 'small',
        size: 'sm',
      }).result.then((email) => {
        const options = angular.extend({ email }, { pl_id: plId });
        PriceList.downloadXLS(options);
        alertService.show({
          text: $filter('translate')('email_popup.success'),
        });
      });
    };

    $scope.save = (priceList) => {
      priceList.save().then(() => {
        $scope.updatePage();
        plSelectService.reload();
      });
    };
    $scope.delete = (priceList) => {
      if (priceList.usersCount){
        alert('Удалить прайс лист с активными пользователями нельзя');
      }
      else {
        rwConfirmationService.confirm(
          { textTranslation: 'alert.are_you_sure' },
          () => {
            priceList.delete().then(() => {
              $timeout(() => {
                $scope.updatePage();
                plSelectService.reload();
              }, 500);
            });
          },
        );
      }
    };

    $scope.$on('ctgSelectService-selected', () => {
      $scope.updatePage();
    });
    $scope.updatePage();
  },
);
