angular.module('RocketWash').controller(
  'CommonContentHeaderController',
  ($scope, $state, $translate, $uibModal, ExternalPayment, viewStore) => {
    $scope.stateBasename = () => $state.current.name.split('.')[2];
    $scope.stateTabName = () => $state.current.name.split('.')[3];
    $scope.tranlatedStateBasename = () => (
      $translate.instant(`left_menu.${$scope.stateBasename()}`)
    );

    $scope.pay = () => {
      const externalPayment = new ExternalPayment();
      viewStore.store({ externalPayment });
      $uibModal.open({
        templateUrl: 'pages/wash/organization/external_payments/form_popup.slim',
      });
    };
  },
);
