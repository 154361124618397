angular.module('RocketWash').controller(
  'WashCounterpartiesEditHistoryController',
  (
    $scope, $translate, total, Reservation,
  ) => {
    $scope.total = total;
    $scope.pds = $scope.pageDataService.new({
      page: 'contractor_history',
      filters: {
        attributes: [
          { name: 'time_start', type: 'datetime' },
          { name: 'time_end', type: 'datetime' },
          { name: 'name', type: 'string' },
          { name: 'phone', type: 'string' },
          { name: 'rounded_price', type: 'number' },
          { name: 'discount', type: 'number' },
          {
            name: 'channel',
            type: 'enum',
            values: [
              { value: 'offline', label: 'Офлайн' },
              { value: 'mobile', label: 'Приложение RocketWash' },
              { value: 'mobile_individual', label: 'Индивидуальное приложение' },
            ],
          },
          { name: 'order_ordinal', type: 'number' },
          { name: 'status', type: 'string' },
          { name: 'paid_type', type: 'string' },
          { name: 'created_responsible', type: 'string' },
          { name: 'paid_responsible', type: 'string' },
          { name: 'completed_responsible', type: 'string' },
        ],
        scopes: [
          'name', 'phone', 'make_model', 'tag', 'status', 'paid_type',
          'created_responsible', 'paid_responsible', 'completed_responsible',
        ],
      },
      pg: {
        url: '/wash/contractor_history',
        model: Reservation,
        query: {
          fromToField: 'time_end',
          searchQuery: '',
          predicate: '-time_start',
          user_id: $scope.contractor.id,
        },
      },
      definePaginateMethodsOn: $scope,
    });
    $scope.rightMenuConfig = {
      buttons: [
        {
          type: 'export_xls',
          text: $translate.instant('buttons.export_xls'),
          action: () => { $scope.exportXLS(); },
        },
      ],
    };

    $scope.searchParams = () => $.param($scope.pds.pg.query);
    $scope.exportXLS = () => {
      window.open(`${$scope.env.apiUrl}/wash/contractor_history.xlsx?${$scope.searchParams()}`, '_blank');
    };

    $scope.paidLabel = reservation => (
      reservation.fullyPaid ? 'label label-success' : 'label label-important'
    );
    $scope.allExpanded = () => $scope.pds.pg.items.every(x => x.expanded);
    $scope.toggleAll = () => {
      const newValue = !$scope.allExpanded();
      $scope.pds.pg.items.forEach((x) => {
        x.expanded = newValue;
      });
    };
  },
);
