import {
  env
} from '@env';

angular.module('RocketWash').controller(
  'WashSettingsClientMonitorSettingsCmBgimagePopupController',
  (
    $q, $scope, $translate, $http, alertService, Upload, userSession,
    ServiceLocation,
  ) => {

    const updateImageSettings = () => {
      ServiceLocation.query().then((serviceLocations) => {
        $scope.sl = new ServiceLocation(serviceLocations.find(x => x.id == userSession.service_location.id));
        $scope.currentImageUrl = $scope.sl.clientMonitorImage.url;
      });
    }
    updateImageSettings();

    $scope.delete = () => {
      $scope.sl.clientMonitorImage.url = null;
      $scope.currentImageUrl = null;
      $scope.sl.save();
    };

    $scope.upload = (files, resource) => {
      if (!files) {
        alertService.show({
          text: $translate.instant('settings.import.file_is_invalid'),
          type: 'error'
        });
        return;
      }

      Upload.upload({
        url: `${env.apiUrl}wash/service_locations/upload_monitor_image`,
        method: 'POST',
        data: {
          client_monitor_image: {
            image: files[0],
          }
        },
      }).then(() => {
        updateImageSettings();
        alertService.show({
          text: 'Изображение успешно загружено!',
        });
      }).catch((e) => {
        alertService.show({
          text: `Ошибка: ${e}`,
          type: error
        });
      });
    }
  },
);
