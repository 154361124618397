angular.module('RocketWash').controller('WashCounterpartiesNotificationController', (
  $scope, $http, $state, SettingValue,
) => {
  $scope.maxNotificationLength = 300;

  $scope.pds = $scope.pageDataService.new({ page: 'counterparties' });
  $scope.pg = $scope.pds.pg;
  if (!$scope.pg) {
    $state.go('app.wash.counterparties.individuals');
  }

  $scope.recepientsCount = $scope.pg.selectAll ?
    $scope.pg.total.count :
    $scope.pg.selectedKeys.length;
  $scope.message = { title: '', text: '' };

  SettingValue.query().then((values) => {
    const smsSign = values.find(v => v.key === 'sms_sign').value || '';
    $scope.message.text = `\n${smsSign}`;
  });

  $scope.canSend = () => {
    return $scope.message.title && $scope.message.text.length >= 2
           && $scope.message.text.length <= $scope.maxNotificationLength;
  };

  $scope.send = () => {
    const options = {
      message: $scope.message.text,
      title: $scope.message.title,
      customer_ids: $scope.pg.selectedKeys,
      select_all: $scope.pg.selectAll,
      filters: JSON.stringify($scope.pds.selectedFilters.map((filter) => {
        delete filter.filterGroup;
        return filter;
      })),
    };
    $scope.saveDisabled = true;
    $http.post('/wash/notifications/bulk_mobile', options).finally(() => {
      $scope.saveDisabled = false;
      $state.go('app.wash.counterparties.individuals');
    });
  };
  $scope.totalMessages = () => $scope.recepientsCount;
});
