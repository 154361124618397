

angular.module('RocketWash').factory('ServiceLocationLane', function(railsResourceFactory, userSession) {
  let sll = railsResourceFactory({
    url: (context) => { return "/" + (context.queryRole || userSession.controllerScope()) + "/service_location_lanes/" + (context.id || '') },
    name: "service_location_lane"
  });

  sll.getCurrentStatuses = (options={}) => {
    return sll.$get(
      `${sll.resourceUrl()}/current_statuses`,
      options,
      { rootWrapping: false },
    );
  };


  return sll;
});
