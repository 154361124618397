angular.module('RocketWash').config(($stateProvider) => {
  $stateProvider.state('app.taxi.reports.financial_operations', {
    url: '/financial_operations',
    tab: 'financial_operations',
    data: {
      permission: 'OrganizationFinancialOperationsTab',
    },
    views: {
      'content@app.taxi.reports': {
        controller: 'TaxiReportsFinancialOperationsBaseController',
        templateUrl: 'pages/taxi/reports/financial_operations/index.slim',
      },
    },
  });
});
