import { env } from '@env';

angular.module('RocketWash').controller('WashWarehouseProductMovementsBaseController', (
  $q, $http, $scope, $rootScope, $translate, ServiceFolder, Product,
  dateRangeStorage, unitOrPackageSelectService, warehouseSelectService,
) => {

  $scope.unitOrPackageSelectService = unitOrPackageSelectService;

  const productsReady = Product.all().then((products) => {
    $scope.products = products;
  });

  const sfReady = ServiceFolder.indexAsTree().then(function (serviceFolders) {
    $scope.serviceFolders = serviceFolders;
  });

  $q.all([productsReady, sfReady]).then(() => {
    $scope.foldersList = [];

    const processItem = (item, level = 1) => {
      item.products = [];
      item.level = level;
      $scope.foldersList.push(item);
      item.children.forEach((child) => {
        processItem(child, level + 1);
      });
    };

    $scope.serviceFolders.forEach((sf) => {
      processItem(sf);
    });

    $scope.products.forEach((p) => {
      const sf = $scope.foldersList.find(x => x.id == p.serviceFolderId);
      sf && sf.products.push(p);
    });
  })


  $scope.reload = () => {
    const options = {
      from: dateRangeStorage.from(),
      to: dateRangeStorage.to(),
      unit: unitOrPackageSelectService.selected,
      warehouse_id: warehouseSelectService.selected.id,
    };

    $http.post(
      `${env.apiUrl}/wash/warehouse_reports/product_movements`,
      options,
      { rootWrapping: false },
    ).then((data) => {
      $scope.data = data.data;
    });
  };

  $rootScope.$on('date-range-updated', $scope.reload);
  $rootScope.$on('warehouseSelectService-selected', $scope.reload);
  $rootScope.$on('warehouseSelectService-loaded', $scope.reload);
  $rootScope.$on('unitOrPackageSelectService-selected', $scope.reload);

  $scope.rightMenuConfig = {
    buttons: [
      {
        type: 'export_xls',
        text: $translate.instant('buttons.export_xls'),
        action: () => { $scope.exportXLS(); },
        ngDisabled: () => (
          !$scope.isEnable()
        ),
      },
    ],
    tooltip: () => (
      !$scope.isEnable() ? $translate.instant('buttons.tooltip_message') : undefined
    ),
  };

  $scope.exportXLS = () => {
    $http.post(
      `${$scope.env.apiUrl}/wash/warehouse_reports/product_movements_xlsx`,
      {
        folders: $scope.serviceFolders,
        data: $scope.data,
        products: $scope.products
      },
      { rootWrapping: false },
    );
  };

  $scope.isEnable = () => (
    moment(dateRangeStorage.to()).diff(dateRangeStorage.from(), 'hours') <= 744
  );
});
