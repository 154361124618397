angular.module('RocketWash').directive('rwPaymentConfirmation', (
  alertService, reservationForm, userSession,
) => {
  const STAGES = {
    not_confirmed: 'not_confirmed',
    enter_code: 'enter_code',
    confirmed: 'confirmed',
  };

  return {
    restrict: 'E',
    scope: {
      payment: '=',
    },
    templateUrl: 'directives/rw-payment-confirmation/index.slim',
    link(scope, _element, _attrs) {
      scope.data = { confirmationCode: null };
      scope.STAGES = STAGES;

      scope.$watch('payment.payment_confirmation_id', () => {
        if (scope.payment && scope.payment.payment_confirmation_id) {
          scope.stage = STAGES.confirmed;
        } else {
          scope.stage = STAGES.not_confirmed;
        }
      });

      scope.back = () => {
        scope.stage = STAGES.not_confirmed;
      };

      scope.sendConfirmation = () => {
        scope.stage = STAGES.enter_code;

        reservationForm.payments.sendConfirmationCode(scope.payment).catch(() => {
          alertService.show({
            text: 'Не удалось послать СМС на телефонный номер клиента!',
            type: 'error',
          });
          scope.back();
        });
      };

      scope.confirm = () => {
        scope.confirmDisabled = true;

        reservationForm.payments.confirm(scope.payment, scope.data.confirmationCode).then((data) => {
          if (data.confirmed) {
            alertService.show({
              text: 'Платеж успешно подтвержден!',
            });
          } else {
            alertService.show({
              text: 'Введен не верный код подтверждения!',
              type: 'error',
            });
          }
        }).finally(() => {
          scope.confirmDisabled = false;
        });
      };
    },
  };
});
