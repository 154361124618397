import { env } from '@env';

angular.module('RocketWash').controller('TaxiAutoparkDriversFormController', (
  $scope, YandexTaxiDriver, $translate, alertService, resolvedDriver, Upload,
) => {

  $scope.driver = new YandexTaxiDriver(resolvedDriver);

  $scope.validate = () => {
    const errors = {};

    if (!$scope.driver.name) {
      errors.name = true;
    }

    if (!$scope.driver.phone) {
      errors.phone = true;
    }

    if (Object.keys(errors).length === 0) {
      return true;
    }

    $scope.errors = { create_driver: errors };

    return false;
  };

  $scope.save = () => {
    if (!$scope.validate()) {
      return;
    }

    $scope.formDisabled = true;

    $scope.driver.save().then((driver) => {
      if (_.isEmpty(driver.errors)) {
        $scope.$dismiss();
      } else {
        $scope.errors = { create_driver: driver.errors };
      }
    }).finally(() => { $scope.formDisabled = false; });
  };

});
