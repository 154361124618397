angular.module('RocketWash').controller(
  'WashWarehousePriceListsBaseController',
  ($scope, $translate, $uibModal, $timeout, viewStore, PriceList,) => {

    $scope.pds = $scope.pageDataService.new({
      page: 'price_lists',
      filters: {
        attributes: [
          { name: 'name', type: 'string' },
          { name: 'user', type: 'string'},
          { name: 'description', type: 'string' },
          { name: 'warehouse', type: 'boolean'}
        ],
        scopes: [
          'name', 'user', 'description', 'warehouse',
        ],
        beforeSend: (filters) => {
          filters.push({
            attr: 'price_list_type.sortable',
            condition: 'equals',
            value: 'pricelistwarehouse',
          });
        },
      },
      pg: {
        url: '/wash/price_lists',
        model: PriceList,
        query: {
          skipDateFilter: true,
          predicate: 'name',
          page: 1,
          per: 10,
        },
      },
      definePaginateMethodsOn: $scope,
    });
    // Actions

    $scope.newPriceList = () => {
      const priceList = new PriceList();
      priceList.type = 'PriceListWarehouse';
      $scope.edit(priceList);
    };
    $scope.edit = (priceList, allPriceLists) => {
      $uibModal.open({
        templateUrl: 'pages/wash/warehouse/price_lists/form.slim',
        controller: 'WashWarehousePriceListsFormController',
        resolve: {
          priceList: () => (priceList),
          allPriceLists: () => (allPriceLists),
        }
      }).result.finally(() => {
        $timeout(() => {
          $scope.updatePage();
        }, 500);
      });
    };
    $scope.save = (priceList) => {
      priceList.save().then(() => {
        $scope.updatePage();
      });
    };
    $scope.delete = (priceList) => {
      if (confirm($translate.instant('alert.are_you_sure'))) {
        priceList.delete()
      }
    };
  },
);
