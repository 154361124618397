angular.module('RocketWash').directive('rwCarTypesGroupSelectForCharts', () => ({
  restrict: 'E',
  replace: true,
  templateUrl: 'directives/rw-car-types-group-select-for-charts/index.slim',
  scope: {
  },
  controller: (
    $scope, ctgSelectServiceForCharts
  ) => {
    $scope.ctgSelectServiceForCharts = ctgSelectServiceForCharts;
  },
}));
