angular.module('RocketWash').directive('rwProductSelect', () => ({
  restrict: 'E',
  replace: true,
  templateUrl: 'directives/rw-product-select/index.slim',
  scope: {
  },
  controller: (
    $scope, productSelectService
  ) => {
    $scope.productSelectService = productSelectService;
  },
}));
