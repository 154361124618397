angular.module('RocketWash').config(
  ($stateProvider, $urlRouterProvider) => {
    $stateProvider.state('app', {
      url: '',
      abstract: true,
      controller: 'AppController',
      templateUrl: 'pages/layout.slim',
      resolve: {
        _serverConstants: serverConstants => serverConstants.promise,
        _serverTranslations: () => { window.translationsPromise},
      },
    });
    $urlRouterProvider.otherwise(($injector, $location) => {
      $location.path('/sign_in');
    });
  },
);
