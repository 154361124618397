angular.module('RocketWash').directive('rwDraggableCameraEvent', () => {
  return {
    restrict: 'A',
    scope: {
      rwDraggableCameraEvent: '='
    },
    link: function(scope, element, attrs) {
      element.draggable({
        revert: true,
        revertDuration: 400,
        cursorAt: {
          top: 36
        }
      });
      element.data('duration', '00:15');
      element.attr('type', 'CameraEvent');
      element.attr('camera_event_id', scope.rwDraggableCameraEvent.id);
    }
  };
});
