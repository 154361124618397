angular.module('RocketWash').controller(
  'WashDashboardStatisticsForDayNightShiftController',
  ($scope, $http) => {
    // Initialization
    $scope.progress = {};
    $scope.data = {};

    // Helpers
    const deepParseFloat = (obj) => {
      Object.entries(obj).forEach(([key, value]) => {
        if (typeof value === 'object') {
          deepParseFloat(value);
        } else {
          obj[key] = parseFloat(value);
        }
      });
    };
    $scope.shiftChangeDisabled = () => !$scope.data.date || $scope.progress.changeShift;

    // Actions
    $scope.loadData = function (options) {
      return $http.post(
        '/wash/statistics/for_day_night_shift', options,
      ).then((resp) => {
        $scope.data = resp.data;
        deepParseFloat($scope.data.payments_for_reservations);
        deepParseFloat($scope.data.payments_by_service_type);
        deepParseFloat($scope.data.key_indexes);
        deepParseFloat($scope.data.count_indexes);
      });
    };
    $scope.prevShift = function () {
      $scope.progress.changeShift = true;
      $scope.loadData({
        date: $scope.data.date.from,
        prev: true,
      }).then(() => {
        $scope.progress.changeShift = false;
      });
    };
    $scope.nextShift = function () {
      $scope.progress.changeShift = true;
      $scope.loadData({
        date: $scope.data.date.from,
        next: true,
      }).then(() => {
        $scope.progress.changeShift = false;
      });
    };

    // Code
    $scope.loadData({});
  },
);
