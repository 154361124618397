

angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.settings.car_types', {
    url: '/car_types',
    tab: 'car_types',
    data: {
      permission: 'CarTypesTab'
    },
    views: {
      'content@app.wash.settings': {
        controller: 'WashSettingsCarTypesBaseController',
        templateUrl: 'pages/wash/settings/car_types/index.slim'
      }
    }
  }).state('app.wash.settings.car_types.form', {
    url: '/form',
    views: {
      'content@app.wash.settings': {
        controller: 'WashSettingsCarTypesFormController',
        templateUrl: 'pages/wash/settings/car_types/form.slim'
      }
    }
  });
});
