angular.module('RocketWash').service('warehouseForm', () => ({
  warehouseId: 'id',
  warehouseOperationType: 'type',
  warehouseSelect: 0,

  getWarehouseId(){
    return this.warehouseId;
  },

  setWarehouseId(id){
    this.warehouseId = id;
  },

  getWarehouseOperationType(){
    return this.warehouseOperationType;
  },

  setWarehouseOperationType(type){
    this.warehouseOperationType = type;
  },

  addWarehouseSelect(){
    this.warehouseSelect += 1;
  },

  getWarehouseSelect(){
    return this.warehouseSelect;
  },

  resetWarehouseSelect(){
    this.warehouseSelect = 0;
  },
}));
