angular.module('RocketWash').controller('TaxiAutoparkDriversController', (
  $scope, $state, $translate, $uibModal, $timeout, viewStore, YandexTaxiDriver, Role
) => {

  $scope.pds = $scope.pageDataService.new({
    page: 'drivers',
    filters: {
      attributes: [
        { name: 'name', type: 'string' },
        { name: 'phone', type: 'string' }
      ],
      scopes: ['name', 'phone'],
    },
    pg: {
      url: '/taxi/drivers',
      model: YandexTaxiDriver,
      query: {
        skipDateFilter: true,
        predicate: 'name',
        reverse: false
      },
    },
    definePaginateMethodsOn: $scope,
  });

  $scope.save = function (driver, index) {
    $scope.errors = null;

    if (index != null) {
      driver.phone = $(`#driver-${index} .phone-input`).val();
    }

    const persistedDriver = driver.id != null;

    return driver.save().then((drver) => {
      if (_.isEmpty(drver.errors)) {
        if (!persistedDriver) {
          $scope.pds.pg.items.push(empl);
        }
      } else {
        $scope.errors = {
          create_driver: empl.errors,
        };
        $scope.reload();
      }
    });
  };

  $scope.delete = (driver) => {
    if (confirm($translate.instant('alert.are_you_sure'))) {
      driver.delete().then(() => {
        $scope.pds.pg.items = _.without($scope.pds.pg.items, driver);
      });
    }
  };

  $scope.openFormPopup = (driver) => {
    $uibModal.open({
      templateUrl: 'pages/taxi/autopark/drivers/form.slim',
      controller: 'TaxiAutoparkDriversFormController',
      resolve: {
        resolvedDriver: () => driver
      },
    }).result.finally(() => {
      $timeout($scope.resetAndUpdate, 1000);
    });
  };
});
