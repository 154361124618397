angular.module('RocketWash').config(($stateProvider) => {
  $stateProvider.state('app.admin.settings.review_scales', {
    url: '/review_scales',
    tab: 'review_scales',
    views: {
      'content@app.admin.settings': {
        controller: 'AdminSettingsReviewScalesController',
        templateUrl: 'pages/admin/settings/review_scales/index.slim',
      },
    },
  });
});
