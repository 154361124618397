angular.module('RocketWash').component('rwReservationRightMenuPromotion', {
  templateUrl: 'components/rw-reservation/right-menu/promotion/index.slim',
  bindings: {
  },
  controller: function (
    $scope, reservationForm,
  ) {
    $scope.rf = reservationForm;


  },
});
