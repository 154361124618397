angular.module('RocketWash').filter('highlightMultiLayout', ($sce, keyboardLayout) => {
  const qwerty = keyboardLayout.use('qwerty');

  const sanitize = (term) => {
    if (!term) return term;
    return term.replace(/[\*\[\]\(\)\{\}\\\^\$]/g, '\\$&');
  };

  return (string, searchText) => {
    string = (string || '').toString();
    searchText = (searchText || '').toString();

    const words = [].concat(...searchText.split(/ +/).map((word) => {
      return qwerty.allLayoutVariants(word).map(x => sanitize(x));
    }));

    const wordsRegExp = new RegExp(`(${_.uniq(words).join('|')})`, 'gi');
    string = string.replace(wordsRegExp, '<span class="highlight">$&</span>');

    return $sce.trustAsHtml(string);
  };
});
