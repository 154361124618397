// TODO: disable caching
angular.module('RocketWash').factory(
  'CarType',
  ($http, railsResourceFactory, userSession) => {
    const resource = railsResourceFactory({
      url: (context) => { return "/" + (context.queryRole || userSession.controllerScope()) + "/car_types/" + (context.id || '') },
      name: 'car_type',
    });

    resource.query = function query(queryParams, { cache = false } = {}) {
      console.log(`cache: ${cache}`);
      return $http({
        cache,
        url: this.resourceUrl(),
        params: queryParams,
        method: 'GET',
      }).then(response => (
        response.data.slice().sort((x, y) => x.name > y.name).map(x => new this(x))
      ));
    };

    return resource;
  },
);
