angular.module('RocketWash').controller('AdminFinanceFinancialOperationsController', (
  $scope, $translate, $uibModal, FinancialOperation, rwConfirmationService,
) => {
  $scope.rightMenuConfig = {
    buttons: [
      {
        type: 'export_xls',
        text: $translate.instant('buttons.export_xls'),
        action: () => { $scope.exportXLS(); },
      },
    ],
  };

  $scope.searchParams = () => $.param($scope.pds.pg.query);
  $scope.exportXLS = () => {
    rwConfirmationService.confirm(
      { textTranslation: 'completed_services.index.confirm_xls_export' },
      () => { window.open(`${$scope.env.apiUrl}/admin/financial_operations.xlsx?${$scope.searchParams()}`, '_blank'); },
    );
  };
  $scope.pds = $scope.pageDataService.new({
    page: 'admin_financial_operations',
    filters: {
      attributes: [
        { name: 'organization_name', type: 'string' },
        { name: 'service_location_name', type: 'string' },
        { name: 'date', type: 'datetime' },
        { name: 'created_at', type: 'datetime' },
        { name: 'ordinal', type: 'number' },
        { name: 'amount', type: 'number' },
        { name: 'record_type', type: 'string' },
        { name: 'comment', type: 'string' },
        { name: 'document', type: 'string' },
        { name: 'finance_clause', type: 'string' },
        { name: 'from_financial_center_name', type: 'string' },
        { name: 'to_financial_center_name', type: 'string' },
        { name: 'from_second_financial_center_name', type: 'string' },
        { name: 'to_second_financial_center_name', type: 'string' },
      ],
      scopes: ['organization_name', 'service_location_name', 'record_type',
        'comment', 'document', 'finance_clause', 'created_at',
        'from_financial_center_name', 'to_financial_center_name',
        'from_second_financial_center_name',
        'to_second_financial_center_name'],
    },
    pg: {
      url: '/admin/financial_operations',
      model: FinancialOperation,
      query: {
        fromToField: 'date',
        predicate: '-date',
        reverse: true,
        per: 10,
        page: 1,
      },
    },
    definePaginateMethodsOn: $scope,
  });

  $scope.create = () => { $scope.openFormPopup(new FinancialOperation()); };

  $scope.openFormPopup = (fo) => {
    $uibModal.open({
      templateUrl: 'pages/admin/finance/financial_operations/form.slim',
      controller: 'AdminFinanceFinancialOperationsFormController',
      resolve: { resolvedFinancialOperation: () => fo },
    }).result.finally(() => {
        setTimeout($scope.updatePage, 1000);
      });
  };
});
