angular.module('RocketWash').controller('CommonHeaderSupportOfficerController', (
  $scope, SettingValue, userSession, $route
) => {

  $scope.reloadSettingValues = () => {
    return SettingValue.query().then((settings) => {
      $scope.slApplyManageSupportOfficer = settings.find(x => x.key === 'apply_manage_support_officer');
      $scope.supportOfficerSettingValue = $scope.slApplyManageSupportOfficer.value === 'yes'
    });
  };
  $scope.reloadSettingValues();

  $scope.toggleSupportOfficer = function() {
    if ($scope.slApplyManageSupportOfficer.value === 'yes') {
      $scope.slApplyManageSupportOfficer.value = 'no';
    } else {
      $scope.slApplyManageSupportOfficer.value = 'yes';
    }
    $scope.slApplyManageSupportOfficer.save().then(() => {
      userSession.reload();
      $route.reload();
      $scope.reloadSettingValues();
    });
  };

  $scope.close = () => {
    $scope.supportOfficerPopupOpen = false;
  };

});

