angular.module('RocketWash').controller(
  'WashServicesSummaryForTaxiController',
  ($q, $scope, $timeout, BasicService, ExtraService, CarType, PriceList, plSelectService) => {
    const SHOW_N_CAR_TYPES_DEFAULT = 7;

    $scope.plSelectService = plSelectService;

    $scope.tabIndex = 0;
    $scope.tabs = ['BasicService', 'ExtraService'];
    $scope.selected.activeCarTypes = [];

    $('.services-summary-page').on('keyup', (e) => {
      if( e.which == 9 ) {
        $timeout(() => {
          e.target.click();
        });
      }
    });

    $scope.recalculateServiceCategories = () => {
      $scope.serviceCategories = $scope.getServiceCategories(
        $scope.basicServices
      );
      $scope.extraServiceCategories = $scope.getServiceCategories(
        $scope.extraServices
      );
    };

    $scope.isServiceGroupVisible = (services) => {
      return services.some((service) => {
        return $scope.isServiceVisible(service);
      });
    };

    $scope.isServiceVisible = (service) => {
      return service.visible;
    };

    $scope.$on('plSelectService-selected', () => {$scope.reloadServices();});
    $scope.$on('ctSelectService-selected', () => {
      $scope.selected.activeCarTypes = $scope.ctSelectService.items;
      $scope.reloadServices();
    });

    $scope.reloadServices = (options={}) => {
      if ($scope.ctSelectService.items && plSelectService.items && plSelectService.selected) {
        let ctIds = $scope.ctSelectService.items.map((ct) => ct.id);
        BasicService.query({
          price_list_id: plSelectService.selected.id,
          car_types_group_id: $scope.ctgSelectService.selected.id,
          car_type_ids: ctIds,
        }).then((data) => {
          $scope.basicServices = data.map(o => new BasicService(o));
          $scope.recalculateServiceCategories();
        });
      } else {
        $scope.basicServices = [];
        $scope.serviceCategories = [];
      }
    };

    $scope.savePLIFT = function (service, plift) {
      plift.price = plift.price || 0;
      plift.duration = plift.duration || 0;
      if (plSelectService.selected && plift.priceListId != plSelectService.selected.id) {
        plift.priceListId = plSelectService.selected.id;
        delete plift.id;
      };
      service.save().then(() => {
        $scope.recalculateServiceCategories();
      });
    };

    $scope.deletePLIFT = function(service, plift) {
      let item = service.priceListItems.find((i) => i.id == plift.id);
      let index = service.priceListItems.indexOf(item);
      plift['delete']().then(() => {
        service.priceListItems.splice(index, 1);
        $scope.recalculateServiceCategories();
      });
    };

    $scope.reloadServices();
    $scope.selected.activeCarTypes = $scope.ctSelectService.items;
  },
);
