angular.module('RocketWash').config(($stateProvider) => {
  $stateProvider.state('app.wash.settings.integrations', {
    url: '/integrations',
    tab: 'integrations',
    data: {
      permission: 'SettingsIntegrationsTab',
    },
    views: {
      'content@app.wash.settings': {
        controller: 'WashSettingsIntegrationsController',
        templateUrl: 'pages/wash/settings/integrations/index.slim',
      },
    },
  });
});
