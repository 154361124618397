angular.module('RocketWash').config(($stateProvider) => {
  $stateProvider.state('app.wash.charts.sms', {
    url: '/sms',
    data: {
      permission: 'SMSMenuItem',
    },
    views: {
      'content@app.wash.charts': {
        controller: 'WashChartsSmsController',
        templateUrl: 'pages/wash/charts/sms/index.slim',
      },
    },
  });
});
