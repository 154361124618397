

angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  $urlRouterProvider.when('/wash/logs', '/wash/logs/user_categories');
  return $stateProvider.state('app.wash.logs', {
    url: '/logs',
    abstract: true,
    controller: 'WashLogsBaseController',
    templateUrl: 'pages/wash/logs/index.slim',
    data: {
      permission: 'LogsMenuItem'
    }
  }).state('app.wash.logs.user_categories', {
    url: '/user_categories',
    tab: 'user_categories',
    data: {
      permission: 'LogsUserCategoriesTab'
    },
    views: {
      'content@app.wash.logs': {
        controller: 'WashLogsUserCategoriesBaseController',
        templateUrl: 'pages/wash/logs/user_categories/index.slim'
      }
    }
  });
});
