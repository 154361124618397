import { env } from '@env';

angular.module('RocketWash').controller('WashWarehouseSingleProductOperationsBaseController', (
  $q, $http, $scope, $rootScope, $translate, ServiceFolder, Product,
  dateRangeStorage, unitOrPackageSelectService, warehouseSelectService, productSelectService,
) => {

  $scope.unitOrPackageSelectService = unitOrPackageSelectService;

  $scope.reload = () => {
    if (warehouseSelectService.selected && productSelectService.selected) {
      const options = {
        from: dateRangeStorage.from(),
        to: dateRangeStorage.to(),
        unit: unitOrPackageSelectService.selected,
        warehouse_id: warehouseSelectService.selected.id,
        product_id: productSelectService.selected.id,
      };

      $http.post(
        `${env.apiUrl}/wash/warehouse_reports/single_product_operations`,
        options,
        { rootWrapping: false },
      ).then((data) => {
        $scope.data = data.data;
      });
    }
  };

  $q.all([productSelectService.load(), warehouseSelectService.load()]).then(() => {
    $scope.reload();
  });

  $rootScope.$on('date-range-updated', $scope.reload);
  $rootScope.$on('warehouseSelectService-selected', $scope.reload);
  $rootScope.$on('productSelectService-selected', $scope.reload);
  $rootScope.$on('unitOrPackageSelectService-selected', $scope.reload);

});
