angular.module('RocketWash').service('reservationFormUsers', (rwApi) => {
  const service = {

    save: (data) => {
      return rwApi.sendSaveRequest({
        path: 'reservation_form/users',
        data
      });
    },
  };

  return service;
});
