angular.module('RocketWash').factory(
  'CameraEvent',
  ($http, railsResourceFactory, userSession) => {
    const resource = railsResourceFactory({
      url: (context) => { return "/" + (context.queryRole || userSession.controllerScope()) + "/camera_events/" + (context.id || '') },
      name: 'camera_event',
    });

    angular.extend(resource, {
      getLatest(options) {
        return this.$get(`${this.resourceUrl()}/latest`);
      },
    });

    return resource;
  },
);
