angular.module('RocketWash').config(function ($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.income.salary_report', {
    url: '/salary_report',
    tab: 'salary_report',
    data: {
      permission: 'SalaryReportTab'
    },
    views: {
      'content@app.wash.income': {
        controller: 'WashIncomeSalaryReportController',
        templateUrl: 'pages/wash/income/salary_report/index.slim'
      }
    }
  });
});
