angular.module('RocketWash').controller(
  'AdminPartnerPlansCreateController',
  (
    $scope, $translate, PartnerPlan,
  ) => {
    $scope.partnerPlan = new PartnerPlan();

    $scope.save = () => {
      var plan = $scope.partnerPlan;
      var scope = $scope;
      plan.save().then((plan) => {
        $scope.$close({ plan });
      });
    };
  },
);
