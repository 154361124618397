angular.module('RocketWash').directive(
  'rwServiceFolders',
  ($uibModal, $translate, ServiceFolder, alertService, $q) => ({
    restrict: 'E',

    scope: {
      selectedFolderId: '=',
    },

    templateUrl: 'directives/rw-service-folders/rw-service-folders.slim',

    link(scope, _element, _attrs) {
      scope.treeOptions = {
        beforeDrop: (event) => {
          const toParentId = (src) => {
            const x = src.nodesScope;
            return x && x.$parent && x.$parent.$modelValue && x.$parent.$modelValue.id;
          };
          if (toParentId(event.source) === toParentId(event.dest)) {
            return false;
          }
          const element = event.source.nodeScope.$modelValue;
          const parent = event.dest.nodesScope.$parent.$modelValue;
          const parentId = parent && parent.id;
          if (parentId) {
            element.parentId = parentId;
          } else {
            element.parentId = null;
          }
          return scope.saveFolder(element);
        },
      };

      let reloadServiceFolders = () => {
        ServiceFolder.indexAsTree().then(function(serviceFolders) {
          scope.serviceFolders = serviceFolders;
        });
      };
      reloadServiceFolders();

      scope.saveFolder = (f) => {
        f = new ServiceFolder(f);
        return f.save().then((response) => {
          if (_.isEmpty(response.errors)) {
            return true;
          }
          return $q.reject();
        });
      };

      scope.nodeClasses = (f, collapsed) => {
        let classes = [];

        if (f.id == scope.selectedFolderId) {
          classes.push('selected');
        };

        if (f.children && f.children.length > 0 && collapsed) {
          classes.push('folder-closed');
        } else {
          classes.push('folder-opened');
        };

        return classes.join(' ');
      };

      scope.selectFolder = (f) => {
        if (scope.selectedFolderId == f.id) {
          scope.selectedFolderId = undefined;
        } else {
          scope.selectedFolderId = f.id;
        }
      };

      scope.openFormPopup = (options={}) => {
        $uibModal.open({
          templateUrl: 'directives/rw-service-folders/form.slim',
          controller: 'RWServiceFoldersFormController',
          resolve: {
            parent: options.parent,
            serviceFolder: options.serviceFolder,
          },
        }).result.finally(() => {
          setTimeout(reloadServiceFolders(), 100);
        });
      };

      scope.removeServiceFolder = (serviceFolder) => {
        let sf = new ServiceFolder(serviceFolder);
        sf["delete"]().then((result) => {
          if (result.hasFolders || result.hasServices) {
            alertService.show({
              text: $translate.instant('service_folders.form.errors.has_folders_or_services'),
              type: 'error',
            });
          } else {
            setTimeout(reloadServiceFolders(), 100);
          }
        });
      };
    },
  }),
);
