angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.printed_documents.reconciliation_report', {
    url: '/reconciliation_report',
    tab: 'reconciliation_report',
    data: {
      permission: 'PrintedReconciliationReportTab',
    },
    views: {
      'content@app.wash.printed_documents': {
        controller: 'WashPrintedDocumentsReconciliationReportController',
        templateUrl: 'pages/wash/printed_documents/reconciliation_report/index.slim'
      }
    }
  });
});
