angular.module('RocketWash').service('accessMenuService', (userSession, $state) => {
  const service = {
    canVisit: (state) => {
      state = $state.get(state);
      const permission = state && state.data && state.data.permission;
      const publicPage = state && state.data && state.data.publicPage;
      return !permission || userSession.can('visit', permission) || userSession.is('admin') || userSession.superuser || publicPage;
    },
    canVisitLeft: (state) => {
      state = $state.get(state);
      const tabs = state && state.data && state.data.tabs;
      return !tabs || tabs.some(tab => service.canVisit([state.name, tab].join('.')));
    },
    isCurrentState: (name) => {
      return $state.current.name.startsWith(name);
    },
    stateName: (state) => {
      // return 'app.wash.income.employees';
      state = $state.get(state);
      const tabs = state && state.data && state.data.tabs;
      const tab = tabs && tabs.find(tab => service.canVisit([state.name, tab].join('.')));
      return [state && state.name, tab].join('.');
    },
    navigateLeft: (state) => {
      state = $state.get(state);
      const tabs = state && state.data && state.data.tabs;
      const tab = tabs && tabs.find(tab => service.canVisit([state.name, tab].join('.')));
      tab && $state.go([state.name, tab].join('.'));
    },
  };

  return service;
});
