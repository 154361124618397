angular.module('RocketWash').config(($stateProvider) => {
  return $stateProvider.state('app.wash.marketing.reservation_reviews', {
    url: '/reservation_reviews',
    tab: 'reservation_reviews',
    data: {
      permission: 'ReservationReviewsTab'
    },
    views: {
      'content@app.wash.marketing': {
        controller: 'WashMarketingReservationReviewsController',
        templateUrl: 'pages/wash/marketing/reservation_reviews/index.slim'
      }
    }
  });
});
