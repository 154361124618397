angular.module('RocketWash').controller('WashWarehouseConsumablesBaseController', (
  $scope, $translate, $uibModal,
  WarehouseOperation, serverConstants,
) => {
  $scope.pds = $scope.pageDataService.new({
    page: 'warehouse_operations',
    filters: {
      attributes: [
        { name: 'name', type: 'string' },
      ],
      scopes: [
        'name', 'price',
      ],
    },
    pg: {
      url: '/wash/warehouse_operations',
      model: WarehouseOperation,
      query: {
        operation_type: 'WarehouseOperationConsumable',
        fromToField: 'date',
        predicate: '-date',
        page: 1,
        per: 10,
      },
    },
    definePaginateMethodsOn: $scope,
  });
  $scope.rightMenuConfig = {
    buttons: [
      {
        type: 'export_xls',
        text: $translate.instant('buttons.export_xls'),
        action: () => { $scope.exportXLS(); },
      },
    ],
  };

  $scope.openFormPopup = () => {
    $uibModal.open({
      templateUrl: 'pages/wash/warehouse/warehouse_operations/form.slim',
      controller: 'WashWarehouseWarehouseOperationsFormController',
      resolve: {
        resolvedWarehouseOperation: { type: 'WarehouseOperationOtherExpenditure' },
      },
    }).result.finally(() => {
      setTimeout(() => { $scope.$broadcast('pds-update'); }, 1000);
    });
  };

  $scope.openDetailsPopup = (warehouseOperation) => {
    $uibModal.open({
      templateUrl: 'pages/wash/warehouse/consumables/details.slim',
      controller: 'WashWarehouseConsumablesDetailsController',
      resolve: {
        warehouseOperation: warehouseOperation,
      },
    });
  };

});
