angular.module('RocketWash').service('ctSelectServiceForCharts', ($rootScope, $injector, CarType) => {
  const service = {
    items: null,
    selected: null,
    load: () => {
      const ctgSelectServiceForCharts = $injector.get('ctgSelectServiceForCharts');
      if (!service.items && ctgSelectServiceForCharts.selected) {
        return CarType.query({car_types_group_id: ctgSelectServiceForCharts.selected.id}, { cache: false }).then((carTypes) => {
          service.items = carTypes;
          service.select(service.items[0]);
        });
      } else {
        Promise.resolve();
      }
    },
    reload: () => {
      service.reset();
      return service.load();
    },
    reset: () => {
      service.selected = null;
      service.items = null;
    },
    select: (ct) => {
      service.selected = service.items.find(x => x.id == ct.id);
      $rootScope.$broadcast('ctSelectServiceForCharts-selected');
    },
  };

  return service;
});
