angular.module('RocketWash').controller(
  'WashSettingsIntegrationsController',
  (
    $scope, $route, $location, $window, $http,
    userSession, SettingValue, serverConstants, Organization, ServiceLocation, Sms, Deduction,
  ) => {
    $scope.searchQuery = '';
    $scope.predicate = 'name';
    $scope.reverse = true;
    $scope.progress = { balance: false };

    $scope.setOrgParams = () => {
      $scope.orgParams = {
        org_id: $scope.userSession.service_location.organization.id,
        sl_id: $scope.userSession.service_location.id,
      };
    };

    $scope.yaFuelPersonalCabinetUrl = () => {
      $http.get("/ya_fuel_reservations/get_url_qr", {params: $scope.orgParams}).then((response) => {
        $scope.qr_url_data = response.data.url;
        $scope.qr_image_data = response.data.qr_code;
        $scope.imageData = 'data:image/png;base64,'+$scope.qr_image_data;
      });
    };

    $scope.print_qr = () => {
      const printingId = 'iframe-for-printing';

      if ($(`#${printingId}`).length > 0) {
        $(`#${printingId}`).remove();
      };

      $('html').append(`<iframe style="position: absolute; left: -10000px; top: -10000px;" id="${printingId}" name="${printingId}"></iframe>`);

      var printWindow = window.frames[printingId];

      $(printWindow.document).ready(function () {

        printWindow.document.open();
        printWindow.document.write(`
        <html>
          <head>
            <title>Print Image</title>
          </head>
          <body>
            <img src="${$scope.imageData}" style="width: 60%; height: auto;" />
          </body>
        </html>
      `);
        printWindow.document.close();
        printWindow.onload = function () {
          printWindow.print();
          $timeout(() => {
            $(`#${printingId}`).remove();
          }, 2000);
        };
      });
    };

    $scope.loadOrganization = () => {
      Organization.get(userSession.organization.id).then((organization) => {
        $scope.organization = organization;
      });
    };

    $scope.loadServiceLocation= () => {
      ServiceLocation.get($scope.userSession.service_location.id).then((sl) => {
        $scope.sl = sl;
      });
    };

    Deduction.query().then((deductionsInServiceLocation) => {
      $scope.deductionsInSl = deductionsInServiceLocation;
      $scope.deductionsInSlYandex = deductionsInServiceLocation.find(deduction => deduction.paymentSubtypeId === null);
    });

    SettingValue.query().then((settings) => {
      $scope.settings = settings.filter(x => x.keyType === 'CustomSmsGatewaySettingKey');
      $scope.useSetting = $scope.settings.find(x => x.key === 'use_custom_sms_gateway');
      $scope.useSetting.readonly = !$scope.userSession.organization.custom_sms_gateway_available;
      $scope.loginSetting = $scope.settings.find(x => x.key === 'custom_sms_gateway_login');
      $scope.passwordSetting = $scope.settings.find(x => x.key === 'custom_sms_gateway_password');

      $scope.useTurboSmsSetting = $scope.settings.find(x => x.key === 'use_custom_turbo_sms_gateway');
      $scope.useTurboSmsSetting.readonly = !$scope.userSession.organization.custom_sms_gateway_available;
      $scope.turboSmsToken = $scope.settings.find(x => x.key === 'custom_turbo_sms_gateway_token');
      $scope.turboSmsSender = $scope.settings.find(x => x.key === 'custom_turbo_sms_gateway_sender_name');

      $scope.smsSignature = $scope.settings.find(x => x.key === 'sms_sign');
      $scope.organizationSmsSignature = $scope.settings.find(x => x.key === 'sms_signature_for_organization');
    });

    $scope.SETTING_VALUES = serverConstants.setting_values;

    $scope.save = function (setting) {
      setting.save().then(() => {
        userSession.reload();
        $scope.refreshGatewayBalance();
        $route.reload();
      });
    };

    $scope.refreshGatewayBalance = () => {
      $scope.progress.balance = true;
      Sms.$get('wash/sms/balance').then((data) => {
        $scope.balance = data.balance;
        $scope.useCustomSmsGateway = data.useCustomSmsGateway;
        $scope.customSmsGatewayBalance = data.customSmsGatewayBalance;
        $scope.gatewayConnectionError = $scope.customSmsGatewayBalance === null;
      }).finally(() => { $scope.progress.balance = false; });
    };

    $scope.setOrgParams();
    $scope.loadOrganization();
    $scope.yaFuelPersonalCabinetUrl();
    $scope.loadServiceLocation();
    $scope.refreshGatewayBalance();
  },
);
