angular.module('RocketWash').service('pageDataService', (
  $rootScope, dateRangeStorage, paginator,
) => {
  const clearObject = (object) => {
    Object.keys(object).forEach((key) => { delete object[key]; });
  };
  const clearArray = (x) => { x.splice(0, x.length); };
  const assignObject = (dst, src) => {
    clearObject(dst);
    Object.assign(dst, src);
  };
  const assignArray = (dst, src) => {
    clearArray(dst);
    dst.push(...src);
  };
  const UPDATE_EVENTS = [
    'filters-updated',
    'date-range-updated',
  ];
  const AGGREGATED_EVENT = 'pds-update';
  UPDATE_EVENTS.forEach((event) => {
    $rootScope.$on(event, () => {
      $rootScope.$broadcast(AGGREGATED_EVENT);
    });
  });

  const $storage = {};
  const createStorage = (page) => {
    if (!$storage[page]) {
      $storage[page] = {
        pgConfig: {},
        filtersConfig: {},
        selectedFilters: [],
      };
    }
  };

  return {
    // options: { page, pg, filters, definePaginateMethodsOn: scope }
    // adding unknown parameters check to avoid errors
    new: (options) => {
      let unknownOptions = false;
      Object.keys(options).forEach((key) => {
        if (!['page', 'pg', 'filters', 'definePaginateMethodsOn'].includes(key)) {
          console.error(`pageDataService.new: unknown key: ${key}`);
          unknownOptions = true;
        }
      });
      if (unknownOptions) {
        return null;
      }
      const { page, pg, filters, definePaginateMethodsOn: scope } = options;
      if (!page) {
        console.error('cannot create storage without page');
        return null;
      }
      createStorage(page);
      const storage = $storage[page];
      storage[page] = page;
      if (pg) {
        assignObject(storage.pgConfig, pg);
        storage.pgConfig.items = storage.pgConfig.items || [];
        storage.pgConfig.query.per = storage.pgConfig.query.per || 10;
        storage.pgConfig.query.page = storage.pgConfig.query.page || 1;
      }
      if (filters) {
        assignObject(storage.filtersConfig, filters);
        storage.filtersConfig.page = page; // FIXME: remove
      }
      const result = {
        page: page,
        pg: storage.pgConfig,
        filters: storage.filtersConfig,
        selectedFilters: storage.selectedFilters, // FIXME: remove
        assignSelectedFilters: () => { assignArray(storage.selectedFilters, filters); },
      };
      if (scope) {
        scope.updatePage = () => paginator.getData(result);
        scope.resetAndUpdate = () => {
          result.pg.query.page = 1;
          return scope.updatePage();
        };
        scope.$on('pds-update', scope.resetAndUpdate);
        scope.$evalAsync(scope.resetAndUpdate);
      }
      return result;
    },
    assignSelectedFilters(page, filters) { // FIXME: remove
      createStorage(page);
      const storage = $storage[page];
      assignArray(storage.selectedFilters, filters);
    },
  };
});
