angular.module('RocketWash').controller('AdminChartsBalancesController', (
  $scope, Organization,
) => {
  $scope.pds = $scope.pageDataService.new({
    page: 'admin_charts_organizations',
    filters: {
      attributes: [
        { name: 'name', type: 'string' },
        { name: 'organization_name', type: 'string' },
        { name: 'balance', type: 'number' },
      ],
      scopes: ['name', 'organization_name'],
    },
    pg: {
      url: '/admin/charts/balances',
      model: Organization,
      query: {
        skipDateFilter: true,
        predicate: 'name',
      },
    },
    definePaginateMethodsOn: $scope,
  });

  $scope.allExpanded = () => $scope.pds.pg.items.every(x => x.expanded);

  $scope.toggleAll = () => {
    const newValue = !$scope.allExpanded();
    $scope.pds.pg.items.forEach((x) => {
      x.expanded = newValue;
    });
  };
});
