angular.module('RocketWash').config(($stateProvider) => {
  return $stateProvider.state('app.wash.finance.cash_shifts', {
    url: '/cash_shifts',
    tab: 'cash_shifts',
    data: {
      permission: 'CashShiftsTab',
    },
    views: {
      'content@app.wash.finance': {
        controller: 'WashFinanceCashShiftsBaseController',
        templateUrl: 'pages/wash/finance/cash_shifts/index.slim',
      },
    },
  }).state('app.wash.finance.cash_shifts.view', {
    url: '/{id}/view',
    tab: 'cash_shifts',
    data: {
      permission: 'CashShiftsTab',
    },
    views: {
      'content@app.wash.finance': {
        controller: 'WashFinanceCashShiftsViewController',
        templateUrl: 'pages/wash/finance/cash_shifts/view.slim',
      },
    },
  });
});
