angular.module('RocketWash').controller('WashChartsSmsController', ($scope, Sms, PushNotification, total, $state, $location) => {
  $scope.total = total;
  Sms.$get('wash/sms/balance').then((data) => {
    $scope.balance = data.balance;
  });

  $scope.tabs = [
    'sms',
    'onlinesms',
    'push',
  ];
  $scope.tabIndex = 0;
  $scope.reverse = true;

  $scope.reloadPds =() => {
    if ($scope.tabIndex === 0) {
      $scope.pds = $scope.pageDataService.new({
        page: 'sms',
        filters: {
          attributes: [
            { name: 'receiver_name', type: 'string' },
            { name: 'receiver_phone', type: 'string' },
            { name: 'sender_name', type: 'string' },
            { name: 'status', type: 'string' },
            { name: 'text', type: 'string' },
          ],
          scopes: ['receiver_name', 'receiver_phone', 'sender_name', 'status', 'text'],
        },
        pg: {
          url: '/wash/sms?sms',
          model: Sms,
          query: {
            fromToField: 'created_at',
            predicate: '-created_at',
            reverse: true,
            page: 1,
            per: 10,
            tabIndex: $scope.tabIndex,
          },
        },
        definePaginateMethodsOn: $scope,
      });
    } else if ($scope.tabIndex === 1) {
      $scope.pds = $scope.pageDataService.new({
        page: 'sms',
        filters: {
          attributes: [
            { name: 'receiver_name', type: 'string' },
            { name: 'receiver_phone', type: 'string' },
            { name: 'sender_name', type: 'string' },
            { name: 'status', type: 'string' },
            { name: 'text', type: 'string' },
          ],
          scopes: ['receiver_name', 'receiver_phone', 'sender_name', 'status', 'text'],
        },
        pg: {
          url: '/wash/sms?whatsapp',
          model: Sms,
          query: {
            fromToField: 'created_at',
            predicate: '-created_at',
            reverse: true,
            page: 1,
            per: 10,
            tabIndex: $scope.tabIndex,
          },
        },
        definePaginateMethodsOn: $scope,
      });
    }
    else if ($scope.tabIndex === 2) {
      $scope.pds = $scope.pageDataService.new({
        page: 'sms',
        filters: {
          attributes: [
            { name: 'status', type: 'string' },
            { name: 'message', type: 'string' },
            { name: 'receiver_phone', type: 'string' },
            { name: 'receiver_name', type: 'string' },
          ],
          scopes: ['status', 'message', 'receiver_name', 'receiver_phone'],
        },
        pg: {
          url: '/wash/push_notifications',
          model: PushNotification,
          query: {
            fromToField: 'created_at',
            predicate: '-created_at',
            page: 1,
            per: 10,
          },
        },
        definePaginateMethodsOn: $scope,
      });
    }
  }

  $scope.getSms = () => {
    if ($scope.pds.pg.url !== '/wash/sms?sms'){
      $scope.reloadPds();
    }
    if ($scope.pds && $scope.pds.pg && $scope.pds.pg.items && $scope.pds.pg.items.length > 1) {
      return $scope.pds.pg.items
    } else {
      return [];
    }
  }
  $scope.getWhatsApp = () => {
    if ($scope.pds.pg.url !== '/wash/sms?whatsapp' ){
      $scope.reloadPds();
    }
    if ($scope.pds && $scope.pds.pg && $scope.pds.pg.items && $scope.pds.pg.items.length > 1) {
      return $scope.pds.pg.items
    } else {
      return [];
    }
  }

  $scope.getPush = () => {
    if ($scope.pds.pg.url !== '/wash/push_notifications'){
      $scope.reloadPds();
    }
    if ($scope.pds && $scope.pds.pg && $scope.pds.pg.items && $scope.pds.pg.items.length > 1) {
      return $scope.pds.pg.items
    } else {
      return [];
    }
  };

  $scope.senderName = (sms) => {
    const superuser = sms.sentBySuperuser ? 'Администратор (superuser)' : null;
    const sender = (sms.sender && sms.sender.name);
    const serLoc = (sms.serviceLocation && sms.serviceLocation.name);
    const org = (sms.organization && sms.organization.name);
    return superuser || sender || serLoc || org;
  };

  $scope.allExpanded = () => $scope.pds.pg.items.every(x => x.expanded);
  $scope.toggleAll = () => {
    const newValue = !$scope.allExpanded();
    $scope.pds.pg.items.forEach((x) => {
      x.expanded = newValue;
    });
  };
  $scope.reloadPds();
});
