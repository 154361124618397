angular.module('RocketWash').factory(
  'ManualReservationAnnouncement',
  ($http, railsResourceFactory, userSession) => {
    const resource = railsResourceFactory({
      url: (context) => {
        return "/" + (context.queryRole || userSession.controllerScope()) + "/manual_reservation_announcements/" + (context.id || '')
      },
      name: 'manual_reservation_announcement',
    });

    return resource;
  },
);
