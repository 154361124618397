// TODO: disable caching
angular.module('RocketWash').factory(
  'Deduction',
  ($http, railsResourceFactory, userSession) => {
    const resource = railsResourceFactory({
      url: (context) => { return "/" + (context.queryRole || userSession.controllerScope()) + "/deductions/" + (context.id || '') },
      name: 'deduction',
    });

    return resource;
  },
);
