export const env = {
  keepAliveInterval: 5 * 1000,
  production: false,
  apiUrl: `${window.location.origin}/`,
  apiVersion: 'v9',
  appSubdomains: 'beta|test|pre|stage|stage2|stage3|stage4|stage5|stage6|stage7|stage8|stage9|staging|app',
};

export const secretKey = {
  key: '8qNDUDNYAS@D8m*3!br'
};
