angular.module('RocketWash').controller('WashCounterpartiesEditVehiclesController', (
  $scope, $translate, alertService,
  Car, CarMake, CarModel, CarType, viewStore, serverConstants,
) => {

  $scope.serverConstants = serverConstants;

  $scope.pds = $scope.pageDataService.new({
    page: 'user_cars',
    filters: {
      attributes: [
        { name: 'tag', type: 'string' },
        { name: 'car_make_name', type: 'string' },
        { name: 'car_model_name', type: 'string' },
      ],
      scopes: ['tag', 'car_make_name', 'car_model_name'],
      beforeSend: (filters) => {
        filters.push({
          attr: 'contractor_id',
          condition: 'equals',
          value: viewStore.data.contractor.id,
        });
      },
    },
    pg: {
      url: '/wash/cars',
      model: Car,
      query: {
        scope_to_service_location: false,
        skipDateFilter: true,
        fromToField: 'date',
        predicate: '-date',
        per: 10,
        page: 1,
      },
    },
    definePaginateMethodsOn: $scope,
  });

  $scope.ATTRS_TO_MERGE = [
    'tag', 'carMake', 'carModel', 'vin', 'year', 'engineVolume', 'enginePower', 'color',
  ];
  $scope.carTypes = [];

  CarType.query().then((carTypes) => {
    $scope.carTypes = carTypes;
  });

  $scope.carTypeName = (carTypeId) => {
    const carType = $scope.carTypes
      .find(ct => parseInt(ct.id, 10) === carTypeId);
    return carType && carType.name;
  };
  viewStore.data.editingCars = []

  $scope.editCar = (car) => {
    if (!Array.isArray($scope.isCarEditProgressArr)) {
      $scope.isCarEditProgressArr = [];
    }

    let isCarEditProgress = !!(car && car.carMakeId && car.carModelId && $scope.serverConstants.getCarMake(car.carMakeId).car_models.some(item => item.id === car.carModelId));
    let carEditProgressObj = { [car.id ? car.id : 'newCar']: isCarEditProgress };
    let existingIndex = $scope.isCarEditProgressArr.findIndex(entry => Object.keys(entry)[0] === (car.id ? car.id.toString() : 'newCar'));

    if (existingIndex !== -1) {
      $scope.isCarEditProgressArr[existingIndex] = carEditProgressObj;
    } else {
      $scope.isCarEditProgressArr.push(carEditProgressObj);
    }

    viewStore.data.isCarEditProgress = $scope.isCarEditProgressArr.some(entry => Object.values(entry)[0] === false)

    if (Array.isArray(viewStore.data.editingCars)) {
      if (!viewStore.data.editingCars.some(existingCar => existingCar.id === car.id)) {
        viewStore.data.editingCars.push(car);
      }
    } else {
      viewStore.data.editingCars = [car];
    }
  }


  $scope.addCar = () => {
    $scope.pds.pg.items.unshift(new Car({ active: true, contractor_id: $scope.contractor.id }));
  };
  $scope.deleteCar = function (car) {
    // eslint-disable-next-line no-restricted-globals
    if (confirm($translate.instant('alert.are_you_sure'))) {
      car._destroy = true;
    }
  };

  $scope.allExpanded = () => $scope.contractor && $scope.pds.pg.items && $scope.pds.pg.items.every(x => x.expanded);

  $scope.toggleAll = () => {
    const newValue = !$scope.allExpanded();
    $scope.pds.pg.items.forEach((x) => {
      x.expanded = newValue;
    });
  };

  // merging

  $scope.accordion = {
    showMergeButton: false,
    showMergeForm: false,
  };

  $scope.merging = {
    car0: {},
    car1: {},
    result: {},
  };

  $scope.progress = {
    merge: false,
  };

  $scope.canMergeSelection = () => {
    const selectedCars = $scope.pds.pg.items.filter(x => x.selectedForMerge);
    if (selectedCars.length !== 2) {
      return false;
    }
    const c0 = selectedCars[0];
    const c1 = selectedCars[1];
    return (
      c0.id && c1.id &&
      c0.active && c1.active &&
      c0.carMakeId && (c0.carMakeId === c1.carMakeId) &&
      (c0.carModel && c0.carModel.carTypeId) &&
      (c1.carModel && c1.carModel.carTypeId) &&
      c0.carModel.carTypeId === c1.carModel.carTypeId
    );
  };

  $scope.updateCanMerge = () => {
    $scope.accordion.showMergeButton = $scope.canMergeSelection();
    if (!$scope.accordion.showMergeButton) {
      $scope.accordion.showMergeForm = false;
    }
  };

  $scope.prepareMergeForm = () => {
    const selectedCars = $scope.pds.pg.items.filter(x => x.id && x.selectedForMerge);
    if (selectedCars.length !== 2) {
      return;
    }
    $scope.merging.car0 = selectedCars[0];
    $scope.merging.car1 = selectedCars[1];
    $scope.merging.result = angular.copy($scope.merging.car0);

    $scope.accordion.showMergeForm = true;
  };

  $scope.showValue = (car, attr) => {
    let result = car[attr];
    if (['carMake', 'carModel'].includes(attr)) {
      result = result.name;
    }
    return result;
  };

  $scope.setAttributeForResult = (car, attr) => {
    $scope.merging.result[attr] = car[attr];
    if (['carMake', 'carModel'].includes(attr)) {
      $scope.merging.result[`${attr}Id`] = car[`${attr}Id`];
    }
  };

  $scope.isSelected = (car, attr) => {
    const cmSelected = ['carMake', 'carModel'].includes(attr) && $scope.merging.result[`${attr}Id`] === car[`${attr}Id`];
    return $scope.merging.result[attr] === car[attr] || cmSelected;
  };

  $scope.completeMerge = () => {
    const result = new Car($scope.merging.result);
    $scope.progress.merge = true;
    result.merge($scope.merging.car1.id).then(
      (response) => {
        if (response.success) {
          $scope.accordion.showMergeForm = false;
          $scope.accordion.showMergeButton = false;
          $scope.updatePage();
        } else {
          $scope.errors = { merge_cars: { unknown_error: true } };
        }

        alertService.show({
          text: 'Объединение прошло успешно',
        });
      },
      (error) => {
        console.log(error);
        alertService.show({
          text: 'Ошибка при объединении',
          type: 'error',
        });
      },
    ).finally(() => {
      $scope.progress.merge = false;
    });
  };
});
