angular.module('RocketWash').controller('TaxiAutoparkEmployeesBaseController', (
  $scope, $state, $translate, $uibModal, $timeout, viewStore, Employee, Role,
  Job, ServiceLocation
) => {
  $scope.showFiredEmployees = viewStore.data.showFiredEmployees;
  $scope.toggleShowFiredEmployees = () => {
    viewStore.data.showFiredEmployees = !viewStore.data.showFiredEmployees;
    $scope.updatePage();
  }

  $scope.pds = $scope.pageDataService.new({
    page: 'employees',
    filters: {
      attributes: [
        { name: 'name', type: 'string' },
        { name: 'phone', type: 'string' },
        { name: 'role', type: 'string' },
        { name: 'employee_status', type: 'string' },
        { name: 'balance_salary', type: 'number' },
      ],
      scopes: ['name', 'phone', 'role', 'employee_status'],
      beforeSend: (filters) => {
        if (!viewStore.data.showFiredEmployees) {
          filters.push({
            attr: 'employee_status',
            condition: 'not_equals',
            value: 'fired',
          });
        }
      },
    },
    pg: {
      url: '/taxi/employees',
      model: Employee,
      query: {
        skipDateFilter: true,
        predicate: 'name',
        reverse: false,
        status: null,
      },
    },
    definePaginateMethodsOn: $scope,
  });

  ServiceLocation.query().then((serviceLocations) => {
    $scope.serviceLocations = serviceLocations;
  });
  Role.query().then((roles) => {
    $scope.roles = roles;
  });
  Job.query().then((jobs) => {
    $scope.jobs = jobs;
  });

  $scope.newEmployee = function () {
    viewStore.store({
      employee: new Employee(),
    });
    return $state.go('.form');
  };
  $scope.editEmployee = function (employee) {
    viewStore.store({
      employee,
    });
    return $state.go('.form');
  };
  $scope.save = function (employee, index) {
    $scope.errors = null;
    if (index != null) {
      employee.phone = $(`#employee-${index} .phone-input`).val();
    }
    const persistedEmployee = employee.id != null;
    return employee.save().then((empl) => {
      if (_.isEmpty(empl.errors)) {
        if (!persistedEmployee) {
          $scope.pds.pg.items.push(empl);
        }
      } else {
        $scope.errors = {
          create_employee: empl.errors,
        };
        $scope.reload();
      }
    });
  };
  $scope.delete = (employee) => {
    if (confirm($translate.instant('alert.are_you_sure'))) {
      employee.delete().then(() => {
        $scope.pds.pg.items = _.without($scope.pds.pg.items, employee);
      });
    }
  };
  $scope.jobName = (jobId) => {
    const job = _.find($scope.jobs, (e) => {
      return e.id === jobId;
    });
    return job && job.name;
  };

  $scope.openFormPopup = (employee) => {
    $uibModal.open({
      templateUrl: 'pages/taxi/autopark/employees/form.slim',
      controller: 'TaxiAutoparkEmployeesFormController',
      resolve: {
        resolvedEmployee: () => employee,
        resolvedReadonly: false,
      },
    }).result.finally(() => {
      $timeout($scope.resetAndUpdate, 1000);
    });
  };
});
