angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.admin.online_reservations.dashboard', {
    url: '/dashboard',
    views: {
      'content@app.admin.online_reservations': {
        controller: 'AdminOnlineReservationsDashboardIndexController',
        templateUrl: 'pages/admin/online_reservations/dashboard/index.slim'
      }
    }
  });
});
