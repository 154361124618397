

angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  $urlRouterProvider.when('/admin', '/admin/organizations/dashboard');
  return $stateProvider.state('app.admin', {
    url: '/admin',
    abstract: true,
    templateUrl: 'pages/admin/index.slim',
    resolve: {
      us: function(userSession) {
        return userSession.promise;
      }
    }
  });
});
