angular.module('RocketWash').component('rwReservationClient', {
  templateUrl: 'components/rw-reservation/steps/client/index.slim',
  controllerAs: 'vm',
  controller: function ($scope, $rootScope, $filter, $translate, $uibModal, $timeout, userSession, reservationForm, Car) {
    $scope.rf = reservationForm;

    const vm = this;

    if (!reservationForm.formTab.tabState) {
      reservationForm.formTab.setTabState('search');
    };

    const pr = reservationForm.config.getPreReservation();

    $rootScope.searchTmp = {
      car: ''
    };

    $scope.$watch('rf.formTab.tabState', () => {
      if ($scope.rf.formTab.tabState == 'search') {
        $timeout(() => {
          $('#reservation-car-autocomplete').focus();
        }, 50);
      }
    });

    $scope.backToSearch = () => {
      $scope.rf.carContractor.reset();
      reservationForm.formTab.setTabState('search');
    };

    if (pr) {
      $scope.rf.carContractor.searchText = pr.car_tag;
      $scope.rf.r().comments = pr.comment;
    }

    vm.showCarPlateRecognition = true; //userSession.hasAdditionalFeature('gpu_car_plate_recognition');

    vm.userSession = userSession;

    vm.carSelected = (car) => {
      $scope.rf.carContractor.selectCar(car);
      reservationForm.formTab.setTabState('form');
    };

    vm.newPreReservation = () => {
      reservationForm.preReservations.init({
        time_start: reservationForm.config.calendar.clickedDate,
        service_location_lane_id: reservationForm.config.calendar.sllId,
        full_duration: userSession.preReservationDefaultDuration(),
      });
      reservationForm.config.setPopupMode('pre_reservation');
    };

    vm.getCars = (searchQuery) => {
      if (searchQuery.length < 3) {
        return [{
          typeahead_car: 'Введите минимум 3 символа',
          doNothing: true,
        }]
      } else {
        return reservationForm.carContractor.searchCar({query: searchQuery}).then((items) => {
          items.push({
            typeahead_car: $translate.instant('dashboard.form.car_not_found', {searchText: $scope.rf.carContractor.searchText}),
            doNothing: true,
          });
          return items;
        });
      }
    };

    vm.newClient = () => {
      $scope.rf.carContractor.selectCar({
        newCar: true,
        newContractor: true,
        tag: reservationForm.photos.latestRecognizedTag,
      });

      if (pr) {
        $scope.rf.carContractor.car.tag = pr.car_tag;
        $scope.rf.carContractor.car.contractor.name = pr.user_name;
        $scope.rf.carContractor.car.contractor.phone = pr.phone;
        $scope.rf.carContractor.car.contractor.disable_sms = pr.disable_sms;
        $scope.rf.carContractor.car.contractor.disable_whatsapp = pr.disable_whatsapp;
        $scope.rf.r().comments = pr.comment;
      }

      reservationForm.formTab.setTabState('form');
      reservationForm.formTab.toggleViewFlag('contractorEditable', true);
      reservationForm.formTab.toggleViewFlag('carEditable', true);
    };

    vm.editContractor = () => {
      reservationForm.formTab.toggleViewFlag('contractorEditable', true);
      reservationForm.formTab.toggleViewFlag('showPopupShade', true);
    };

    vm.editCar = () => {
      reservationForm.formTab.toggleViewFlag('carEditable', true);
      reservationForm.formTab.toggleViewFlag('showPopupShade', true);
      reservationForm.formTab.toggleViewFlag('carMenuToggle', false);
    };

    vm.deleteCar = () => {
      const make = $scope.rf.carContractor.getCarMake();
      const model = $scope.rf.carContractor.getCarModel();
      const makeModel = _.compact([
        _.get(make, 'name', null),
        _.get(model, 'name', null),
      ]).join(' ');

      reservationForm.formTab.toggleViewFlag('carMenuToggle', false);

      $uibModal.open({
        templateUrl: 'pages/common/confirmation-popup/index.slim',
        controller: 'CommonConfirmationPopupController',
        resolve: {
          config: {
            title: 'Удалить автомобиль',
            text: `Вы уверены, что хотите удалить автомобиль "${makeModel}" c номером "${$scope.rf.carContractor.car.tag}"?`,
            buttons: [
              {
                text: 'Отмена',
                class: 'btn blue-btn fix-btn',
                click: (scope) => {
                  scope.$dismiss();
                }
              },
              {
                text: 'Удалить',
                class: 'right btn white-btn fix-btn',
                click: (scope) => {
                  scope.$dismiss();
                  $scope.rf.carContractor.deleteCar().then(() => {
                    const newCar = $scope.rf.carContractor.contractorCars[0];
                    if (newCar && newCar.id) {
                      $scope.rf.carContractor.selectCar(newCar);
                    } else {
                      $scope.backToSearch();
                    };
                  });
                }
              },
            ]
          },
        },
      });
    };

    vm.cancelEdit = () => {
      reservationForm.formTab.toggleViewFlag('carEditable', false);
      reservationForm.formTab.toggleViewFlag('contractorEditable', false);
      reservationForm.formTab.toggleViewFlag('showPopupShade', false);
      $scope.rf.carContractor.selectCar($scope.rf.carContractor.car).then(function() {
        vm.reassignAndReloadCarType($scope.rf.carContractor.car);
      });
    };

    vm.reassignAndReloadCarType = (car) => {
      console.log('reassignAndReloadCarType')
      Car.prototype.reassignDefaultCtgs.apply(car).then(() => {
        Car.prototype.reloadAvailableCtgs.apply(car);
      });
    };

    vm.saveCar = () => {
      $scope.rf.carContractor.saveCar();
      vm.cancelEdit();
    };

    vm.saveContractor = () => {
      $scope.rf.carContractor.saveContractor().then(function() {
        vm.cancelEdit();
      });
    };

    vm.newCar = () => {
      reservationForm.formTab.toggleViewFlag('carEditable', true);
      reservationForm.formTab.toggleViewFlag('carMenuToggle', false);
      reservationForm.carContractor.selectCar({newCar: true});
    };

    $scope.$watch('[rf.carContractor.car.tag, rf.carContractor.car.contractor.phone]', () => {
      $scope.rf.carContractor.searchSimilar();
    });

    $scope.$watch('rf.carContractor.car.tag', () => {
      if ($scope.rf.formTab.viewState.carEditable) {
        $scope.rf.carContractor.checkCarTagUniqueness();
      }
    });

    $scope.$watch('rf.carContractor.car.contractor.phone', () => {
      const phoneInput = $('input[ng-model$="contractor.phone"]');

      if ($scope.rf.formTab.viewState.contractorEditable && phoneInput.inputmask("isComplete")) {
        $scope.rf.carContractor.checkCustomerPhoneUniqueness();
      }
    });

  },
});
