angular.module('RocketWash').controller('WashSettingsLanesController', (
  $scope, $uibModal, ServiceLocationLane, rwConfirmationService,
) => {
  $scope.lanes = [];
  $scope.errors = {};

  $scope.reloadLanes = () => {
    ServiceLocationLane.query().then((lanes) => {
      $scope.lanes = _.sortBy(lanes, 'name');
    });
  };

  $scope.delete = (lane) => {
    rwConfirmationService.confirm(
      { textTranslation: 'alert.are_you_sure' },
      () => {
        lane.delete().then(() => {
          $scope.reloadLanes();
        });
      },
    );
  };

  $scope.save = (lane) => {
    lane.save().then(() => {
      $scope.errors = { lanes: lane.errors };
    });
  };

  $scope.changeActive = (lane) => {
    const callback = () => {
      lane.active = !lane.active;
      $scope.save(lane);
    };
    if (!lane.active && $scope.userSession.dailyLanePrice() > 0) {
      rwConfirmationService.confirm(
        { textTranslation: 'service_location_lanes.index.confirm_lane_activation' },
        callback,
      );
    } else {
      callback();
    }
  };

  $scope.changeOnline = (lane) => {
    lane.online = !lane.online;
    $scope.save(lane);
  };

  $scope.openFormPopup = (lane) => {
    $uibModal.open({
      templateUrl: 'pages/wash/settings/lanes/form.slim',
      controller: 'WashSettingsLanesFormController',
      resolve: {
        resolvedLane: () => lane,
      },
    }).result.finally(() => {
      setTimeout($scope.reloadLanes, 1000);
    });
  };

  $scope.reloadLanes();
});
