angular.module('RocketWash').controller('AdminVehiclesNewPopupController', (
  $scope, $translate, $filter, selectedModelsForMerge, alertService, CarModel, CarMake, $http,
) => {

  [$scope.model1Id, $scope.model2Id] = Object.keys(selectedModelsForMerge);
  $scope.make1Id = selectedModelsForMerge[$scope.model1Id];
  $scope.make2Id = selectedModelsForMerge[$scope.model2Id];

  $scope.canMergeBtn = false
  $scope.hasCheckedCanMerge = false

  $scope.checkCanMergeTable = () => {
    if (!$scope.hasCheckedCanMerge) {
      let params = {
        car_model1_id: $scope.model1Id,
        car_model2_id: $scope.model2Id
      };
      $http.get("admin/car_models/get_can_merge_models_info", { params: params }).then((response) => {
        $scope.mergeErrors = response.data.merge_errors
        $scope.canMergeBtn = response.data.can_merge
      });

      $scope.hasCheckedCanMerge = true;
    }
  }

  Promise.all([
    CarModel.get($scope.model1Id),
    CarModel.get($scope.model2Id),
    CarMake.showShortInfo($scope.make1Id),
    CarMake.showShortInfo($scope.make2Id),
  ]).then(([res1, res2, res3, res4]) => {
    $scope.$apply(() => {
      $scope.carModel1 = res1;
      $scope.carModel2 = res2;
      $scope.carMake1 = res3;
      $scope.carMake2 = res4;
      $scope.result = angular.copy(res1);
      $scope.result.carMakeName = res3.name;
    });
  });

  $scope.setResultModel = (carModel) => {
    $scope.result.name = carModel.name;
    $scope.result.id = carModel.id
  };

  $scope.setResultMake = (carMake) => {
    $scope.result.carMakeId = carMake.id
    $scope.result.carMakeName = carMake.name
  };

  $scope.isSelectedModel = (carModel) => {
    return $scope.result.id === carModel.id ;
  };

  $scope.isSelectedMake = (carMake) => {
    return $scope.result.carMakeId === carMake.id;
  };

  $scope.merge = () => {
    const result = {
      from_ids: [$scope.model1Id, $scope.model2Id],
      selected_model_id: $scope.result.id,
      selected_make_id: $scope.result.carMakeId,
    };

    $scope.mergeDisabled = true;
    CarModel.mergeTwoCarModel({ carModel: result }).then(() => {
      $scope.mergeDisabled = false;
      alertService.show({
        text: 'Объединение моделей в процессе и будет завершено через несколько минут!',
      });
      $scope.$dismiss();
    }).finally(() => {
      $scope.mergeDisabled = false;
    });
  };

  $scope.checkMerge = () => {
    const result = {
      from_ids: [$scope.model1Id, $scope.model2Id],
      selected_model_id: $scope.result.id,
      selected_make_id: $scope.result.carMakeId,
    };

    $scope.mergeDisabled = true;
    CarModel.checkMergeTwoCarModel({ carModel: result }).then(() => {
      $scope.mergeDisabled = false;
      alertService.show({
        text: 'Проверка возможности объединение моделей в процессе и будет завершено через несколько минут!',
      });
      $scope.$dismiss();
    }).finally(() => {
      $scope.mergeDisabled = false;
    });
  };

});
