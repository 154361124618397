angular.module('RocketWash').directive('rwAutofocus', function($timeout) {
  return {
    restrict: 'A',
    link: function(scope, element, attrs) {
      $timeout(() => {
        element.focus();
      }, 50);
    }
  };
});
