angular.module('RocketWash').directive(
  'autocompile',
  $compile => ({
    restrict: 'A',
    replace: true,
    link: (scope, ele, attrs) => {
      scope.$watch(attrs.autocompile, (html) => {
        ele.html(html);
        return $compile(ele.contents())(scope);
      });
    },
  }),
);
