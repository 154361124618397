angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.warehouse.warehouse_operations', {
    url: '/warehouse_operations',
    tab: 'warehouse_operations',
    data: {
      permission: 'WarehouseOperationsTab'
    },
    views: {
      'content@app.wash.warehouse': {
        controller: 'WashWarehouseWarehouseOperationsBaseController',
        templateUrl: 'pages/wash/warehouse/warehouse_operations/index.slim'
      }
    }
  });
});
