angular.module('RocketWash').component('rwReservationEmployeeParticipations', {
  templateUrl: 'components/rw-reservation/steps/employee-participations/index.slim',
  bindings: {
  },
  controller: function ($scope, $injector, reservationForm) {
    reservationForm.employees.loadAll().then(() => {
      reservationForm.employeeParticipations.calculate();
    });

    $scope.rf = reservationForm;

    $scope.editable = () => {
      const rf = $injector.get('reservationForm');
      const setManually = reservationForm.r().set_employees_manually;
      const featureActive = reservationForm.config.settings.use_complex_salary_calculation_with_employee_participations;
      return rf.reservations.canEdit() && setManually && featureActive;
    };

    $scope.epChanged = (ep) => {
      ep.manual = true;
      reservationForm.employeeParticipations.calculate();
    };

    $scope.epRollback = (ep) => {
      ep.manual = false;
      reservationForm.employeeParticipations.calculate();
    };

    $scope.participationPercentageClicked = (ep, event) => {
      if (!reservationForm.r().setEmployeesManually) { return; };
      if (!ep.manual) {
        ep.manual = true;
        $timeout(() => {
          let input = $(event.target);
          if (event.target.tagName != 'INPUT') {
            input = input.find('input');
          };

          input.focus();
          input.select();
        });
      }
    };

    $scope.selectedEmployeesCount = () => {
      return (_.get($scope.rf, 'employees.all') || []).filter((e) => {
        return e.selected;
      }).length;
    };

  },
});
