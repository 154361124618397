

angular.module('RocketWash').controller('WashChartsUserCategoriesController', function($scope, $rootScope, $http, $translate, $timeout, $q, UserCategory) {
  var dataReady, ucReady;
  $rootScope.loading = 'user_categories';
  ucReady = UserCategory.query().then(function(userCategories) {
    return $scope.userCategories = userCategories;
  });
  dataReady = $http.get("/wash/charts/user_categories").then(function(response) {
    return $scope.rawData = response.data;
  });
  $q.all([ucReady, dataReady]).then(function() {
    $scope.percentage = false;
    $scope.updateChart();
    if ($rootScope.loading === 'user_categories') {
      return $rootScope.loading = false;
    }
  });
  $scope.updateChart = function(options) {
    var categoryId, data, gTimestamp, gValues, groupedValues, nullCategory, ref, ref1, ref2, serieName, series, update, values;
    data = {};
    if (options) {
      $scope.percentage = options.percentage;
    }
    serieName = $scope.percentage ? 'percentage' : 'count';
    $scope.groupedData = _.groupBy($scope.rawData, function(point) {
      return point.user_category_id;
    });
    ref = $scope.groupedData;
    for (categoryId in ref) {
      values = ref[categoryId];
      data[categoryId] = [];
      groupedValues = _.groupBy(values, function(value) {
        var date;
        date = moment(value.timestamp);
        if ($scope.group.range) {
          date = date.startOf($scope.group.range);
        }
        return +date;
      });
      for (gTimestamp in groupedValues) {
        gValues = groupedValues[gTimestamp];
        data[categoryId].push([parseInt(gTimestamp), _.last(gValues).count]);
      }
    }
    nullCategory = {
      name: $translate.instant("charts.user_categories.labels.null_category"),
      color: '#eee',
      index: 100,
      id: null
    };
    series = _.map((ref1 = $scope.userCategories) != null ? ref1.concat(nullCategory) : void 0, function(userCategory, i) {
      return {
        index: userCategory.ordinal,
        name: userCategory.name,
        color: userCategory.color,
        data: data[userCategory.id]
      };
    });
    if ((ref2 = $scope.chart) != null) {
      ref2.destroy();
    }
    update = function() {
      if ($('#charts-container-user-categories').length) {
        return $scope.chart = new Highcharts.StockChart({
          yAxis: {
            title: {
              text: $translate.instant("charts.user_categories.title." + serieName)
            }
          },
          title: {
            text: $translate.instant("charts.user_categories.title." + serieName)
          },
          xAxis: {
            type: "category"
          },
          chart: {
            renderTo: 'charts-container-user-categories',
            borderWidth: 1,
            type: "area",
            height: '600px',
          },
          tooltip: {
            borderColor: '#ffffff',
            backgroundColor: 'rgba(255, 255, 255, 0.85)',
            headerFormat: '<span style="font-size: 1.0em">{this.total}</span><br/> <span style="font-size: 1.0em">{point.key}</span><br/>',
            formatter: function() {
              var result;
              result = _.reduce(this.points, (function(_this) {
                return function(result, point) {
                  var value;
                  value = ($scope.percentage ? point.point.percentage.toFixed(2) : point.point.y) || 0;
                  return result += "<span style=\"color: " + point.series.color + "\"> " + point.series.name + " </span>: <b>" + value + " " + ($translate.instant("charts.user_categories.tooltip.valueSuffix." + serieName)) + "</b> <br/>";
                };
              })(this), "<span> " + (moment(this.points[0].key).format('MMMM Do YYYY')) + "</span> <br/>");
              if (!$scope.percentage) {
                result += "<span> " + ($translate.instant('charts.income.total')) + "</span>: <b>" + this.points[0].total + " " + ($translate.instant("charts.user_categories.tooltip.valueSuffix." + serieName)) + "</b> <br/>";
              }
              return result;
            }
          },
          plotOptions: {
            area: {
              stacking: $scope.percentage ? 'percent' : 'normal'
            }
          },
          exporting: {
            enabled: false
          },
          series: series
        });
      }
    };
    return $timeout(update);
  };
  $scope.$watch('group.range', () => {
    return $scope.updateChart();
  });
  $scope.$on('left-menu-state-changed', () => {
    $timeout(() => {
      if (!$scope.chart) {
        return;
      }
      $scope.chart.reflow();
    });
  });
});
