angular.module('RocketWash').controller(
  'WashSettingsMobileSettingsSlFacilitiesPopupController',
  (
    $q, $scope, $route, $location, $window, $timeout, $translate, $http, alertService, Upload,
    userSession, ServiceLocation, SettingValue, serverConstants, Organization, Photo,
  ) => {
    $scope.facilities = serverConstants.facilities;

    ServiceLocation.get(userSession.service_location.id).then((sl) => {
      $scope.sl = sl;
    });

    $scope.facilityPresent = (facility) => {
      return $scope.sl && $scope.sl.facilities.indexOf(facility) >= 0;
    };

    $scope.toggleFacility = (facility) => {
      if ($scope.sl) {
        if ($scope.facilityPresent(facility)) {
          $scope.sl.facilities = _.without($scope.sl.facilities, facility);
        } else {
          $scope.sl.facilities.push(facility);
        }
      }
    };

    $scope.save = () => {
      $scope.sl.save().then(() => {
        alertService.show({
          text: 'Список удобств успешно обновлен!',
        });
        $scope.$dismiss();
      });
    };
  },
);
