angular.module('RocketWash').controller('WashIncomeMotivationPercentageFormController', (
  $http, $scope, userSession, ctgSelectService, resolvedGroup, resolvedActiveJobs, resolvedAllJobs,
) => {
  $scope.groupName = resolvedGroup[0]
  $scope.services = resolvedGroup[1]
  $scope.allJobs = resolvedAllJobs
  $scope.activeJobs = resolvedActiveJobs
  $scope.servicesIds = $scope.services.map(service => service.id);

  $scope.groupMotivationParams = {
    brigade_percent_day: 0,
    brigade_percent_night: 0,
    individual_percent_day: 0,
    individual_percent_night: 0,
  };

  $scope.save = () => {
    $scope.groupMotivationParams.services_ids = $scope.servicesIds
    $scope.groupMotivationParams.active_jobs_ids = $scope.activeJobs.map(job => job.id);
    $http.post('wash/job_options_for_services/group_update', $scope.groupMotivationParams);
    $scope.$dismiss();
  };

});
