angular.module('RocketWash').config(($stateProvider) => {
  $stateProvider.state('app.wash.organization.financial_operations', {
    url: '/financial_operations',
    tab: 'financial_operations',
    data: {
      permission: 'OrganizationFinancialOperationsTab',
    },
    views: {
      'content@app.wash.organization': {
        controller: 'WashOrganizationFinancialOperationsController',
        templateUrl: 'pages/wash/organization/financial_operations/index.slim',
      },
    },
  });
});
