angular.module('RocketWash').service('alertService', ($timeout) => {
  const ALERT_DEFAULTS = {
    timeout: 6000,
    type: 'success',
  };
  const service = {
    alerts: [],
    show: (alert) => {
      alert = Object.assign({}, ALERT_DEFAULTS, alert);
      alert.close = () => { service.alerts.splice(service.alerts.indexOf(alert), 1); };
      $timeout(alert.close, alert.timeout);
      service.alerts.push(alert);
    },
  };

  return service;
});
