angular.module('RocketWash').directive('rwLanePositionOnImage', (
  LaneCameraEvent
) => {
  return {
    restrict: 'E',
    scope: {
      positionPercentX: '=',
      positionPercentY: '=',
      laneCamera: '=',
    },
    templateUrl: 'directives/rw-lane-position-on-image/index.slim',
    link($scope, element, attrs) {
      $scope.page = 1;
      const updateImage = () => {
        LaneCameraEvent.query({page: $scope.page, per: 1, lane_camera_id: $scope.laneCamera.id }).then((lces) => {
          $scope.laneCameraEvent = lces[0];
        });
      };
      updateImage();

      $scope.prevPage = (pages) => {
        if ($scope.page > 1) {
          $scope.page = $scope.page - pages;
          updateImage();
        }
      };

      $scope.nextPage = (pages) => {
        $scope.page = $scope.page + pages;
        updateImage();
      };

      $scope.changePositionTo = (event) => {
        const img = element.find('.clickable-image img');
        const {left, top} = img.offset();

        const newPositionPercentX = (event.clientX - left) / img.width() * 100;
        const newPositionPercentY = (event.clientY - top) / img.height() * 100;

        if (newPositionPercentX > 0 && newPositionPercentX < 100 && newPositionPercentY > 0 && newPositionPercentY < 100) {
          $scope.positionPercentX = newPositionPercentX;
          $scope.positionPercentY = newPositionPercentY;
        }
      };

      let watchInitialized = false;
      $scope.imageLoaded = () => {
        if (!watchInitialized) {
          $scope.$watch('[positionPercentX, positionPercentY]', () => {
            const img = element.find('.clickable-image img');
            const left = $scope.positionPercentX / 100 * img.width() - 13;
            const top = $scope.positionPercentY / 100 * img.height() - 13;
            $scope.pointStyle = {
              left: `${left}px`,
              top: `${top}px`,
            };
          }, true);
        };
      };
    },
  };
});
