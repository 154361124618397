angular.module('RocketWash').directive('rwPagination', function() {
  return {
    restrict: 'E',
    scope: {
      itemsLength: '=',
      currentPage: '='
    },
    templateUrl: 'directives/rw-pagination/rw-pagination.slim',
    link: function(scope, element, attrs) {
    }
  };
});
