angular.module('RocketWash').directive('rwBlockedBanner', () => ({
  restrict: 'E',
  templateUrl: 'directives/rw-blocked-banner/index.slim',
  controller: (
    $scope, ExternalPayment, FinancialOperation, userSession, ServiceLocationLane,
    tinkoffFormService,
  ) => {
    $scope.progress = { saveExternalPayment: false };
    $scope.accordeon = { paymentForm: false };

    let activeLanesCount = 0;

    $scope.minimalPaymentAmount = () => {
      return Math.max(0, (userSession.organization && -userSession.organization.financial_center_rocketwash_balance.amount || 0));
    };

    $scope.recommendedPaymentAmount = () => {
      const dailyLanePrice = userSession.service_location.plan.settings.daily_lane_price;
      return $scope.minimalPaymentAmount() + activeLanesCount * dailyLanePrice * 30;
    };

    $scope.pay = () => {
      $scope.progress.saveExternalPayment = true;
      $scope.externalPayment.save().then((response) => {
        if (_.isEmpty(response.errors)) {
          tinkoffFormService.openForm(response);
        } else {
          $scope.errors = { external_payment: response.errors };
        }
      }).finally(() => {
        $scope.progress.saveExternalPayment = false;
      });
    };

    $scope.pds = $scope.pageDataService.new({
      page: 'wash_financial_operations',
      filters: {
        attributes: [
          { name: 'date', type: 'datetime' },
          { name: 'ordinal', type: 'number' },
          { name: 'amount', type: 'number' },
          { name: 'record_type', type: 'string' },
          { name: 'comment', type: 'string' },
          { name: 'document', type: 'string' },
        ],
        scopes: ['record_type', 'comment', 'document'],
        beforeSend: (filters) => {
          filters.push({
            attr: 'from_financial_center_id',
            condition: 'equals',
            value: userSession && userSession.organization && userSession.organization.financial_center_rocketwash_balance && userSession.organization.financial_center_rocketwash_balance.id,
          });
        },
      },
      pg: {
        url: '/wash/financial_operations',
        model: FinancialOperation,
        query: {
          scope_to_service_location: false,
          fromToField: 'date',
          predicate: '-date',
          reverse: true,
          per: 10,
          page: 1,
        },
      },
      definePaginateMethodsOn: $scope,
    });

    $scope.externalPayment = new ExternalPayment({
      organizationId: userSession.organization && userSession.organization.id,
      status: 'new',
      amount: $scope.minimalPaymentAmount(),
    });

    ServiceLocationLane.query().then((lanes) => {
      activeLanesCount = lanes.filter(x => x.active).length;
      $scope.externalPayment.amount = $scope.recommendedPaymentAmount();
    });
  },
}));
