// adds __duplicate__ method to resource instances
angular.module('RocketWash').factory(
  'DuplicatableMixin',
  () => ({
    duplicate: function duplicate() {
      const _class = this.__proto__.constructor;
      return _class.$get(
        `${_class.$url(this)}/duplicate`,
        { organization_id: this.organizationId },
      ).then(response => new _class(response));
    },
  }),
);
