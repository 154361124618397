angular.module('RocketWash').controller(
  'WashSettingsYaFuelOnlineIndexController',
  (
    $scope, userSession, $http, alertService,
  ) => {
    $scope.userSession = userSession;

    $scope.yaFuelOnlineRegister = () => {
      let serviceLocationId = userSession.service_location.id;
      let payload = {
        sl_id: serviceLocationId
      };
      $http.post("/ya_fuel_online/register", payload).then((response) => {
        if(response.data.code == '200'){
          let url = response.data.result;
          window.open(url, '_blank');
        }else{
          alertService.show({
            text: 'Что-то пошло не так, обратитесь в техподдержку',
            type: 'error',
          });
        }

      });
    };

    $scope.yaFuelOnlineFinish = () => {
      let organizationId = userSession.service_location.organization.id;
      let serviceLocationId = userSession.service_location.id;
      let payload = {
        org_id: organizationId,
        sl_id: serviceLocationId
      };
      $http.post("/ya_fuel_online/finish", payload).then((response) => {
        if(response.data.code == '200'){
          alertService.show({
            text: 'Заявка на активацию отправлена успешно!',
          });
        }else{
          alertService.show({
            text: 'Что-то пошло не так, обратитесь в техподдержку',
            type: 'error',
          });
        }

      });
    };

  },
);
