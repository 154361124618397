angular.module('RocketWash').service('screenService', ($timeout) => {
  return {
    screenSize: () => {
      let screenWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
      if (screenWidth > 1281) {
        return 'desktop';
      } else {
        return 'tablet';
      }
    }
  };
});
