angular.module('RocketWash').controller('WashDashboardBaseController', function($scope, $rootScope, $q, CarType, CarMake, BasicService, ExtraService, Warehouse, SettingValue, serverConstants) {
  var sv, cm, ct, wh;
  $rootScope.payment_methods = serverConstants.payment_methods;
  sv = SettingValue.query().then(function(settingValues) {
    return $rootScope.settingValues = settingValues;
  });
  ct = CarType.query().then(function(carTypes) {
    return $rootScope.carTypes = carTypes;
  });
  cm = CarMake.query().then(function(carMakes) {
    return $rootScope.carMakes = carMakes;
  });
  wh = Warehouse.query().then(function(warehouses) {
    $rootScope.warehouses = _.map(warehouses, function(w) {
      return new Warehouse(w);
    });
    return $rootScope.warehouses.push(new Warehouse({
      id: null
    }));
  });
  return $rootScope.allReady = $q.all([sv, ct, cm, wh]);
});
