

angular.module('RocketWash').directive('rwCan', function(userSession) {
  return {
    restrict: 'A',
    scope: {
      rwCan: '='
    },
    transclude: true,
    template: "<div data-ng-if='permitted()' data-ng-transclude></div>",
    link: function(scope, el, options) {
      return scope.permitted = function() {
        return userSession.can(scope.rwCan.action, scope.rwCan.subjectClass);
      };
    }
  };
});
