angular.module('RocketWash').controller(
  'AdminPartnersSettingsGeneralController',
  ($scope, $stateParams, User, PartnerPlan) => {
    User.get($stateParams.usrId).then((usr) => {
      $scope.partner = usr;
    });
    PartnerPlan.query({}).then((partnerPlans) => {
      $scope.partnerPlans = partnerPlans;
    });
    $scope.save = () => {
      $scope.partner.save();
    };
    $scope.planNameById = (id) => {
      if (id) {
        var partnerPlan =  $scope.partnerPlans.filter(function(el) {return el.id === id})[0];
        if (partnerPlan) {
          return partnerPlan.name
        }
      }
    }
  },
);
