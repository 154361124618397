angular.module('RocketWash').config(($stateProvider) => {
  return $stateProvider.state('app.wash.charts.marketing_activities', {
    url: '/marketing_activities',
    tab: 'marketing_activities',
    data: {
      permission: 'MarketingActivitiesTab',
    },
    views: {
      'content@app.wash.charts': {
        controller: 'WashChartsMarketingActivitiesController',
        templateUrl: 'pages/wash/charts/marketing_activities/index.slim',
      },
    },
  });
});

