angular.module('RocketWash').controller(
  'LeftMenuController',
  ($scope, $state, userSession) => {
    console.log(userSession.controllerScope());

    $scope.isCurrentState = function (name) {
      return $state.current.name.startsWith(name);
    };
  },
);
