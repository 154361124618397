angular.module('RocketWash').factory(
  'PreReservation',
  (railsResourceFactory, railsSerializer, userSession) => {
    const Resource = railsResourceFactory({
      url: (context) => { return "/" + (context.queryRole || userSession.controllerScope()) + "/pre_reservations/" + (context.id || '') },
      name: 'pre_reservation',
    });

    return Resource;
  },
);
