

angular.module('RocketWash').directive('rwSlCurrency', function(userSession) {
  return {
    restrict: 'E',
    replace: true,
    template: "<span class='rw-sl-currency'>{{currency}}</span>",
    link: function(scope, ele, attrs) {
      return scope.currency = userSession.service_location.currency;
    }
  };
});
