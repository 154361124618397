angular.module('RocketWash').controller(
  'AdminPartnerPlansController',
  (
    $scope, $translate, $q, PartnerPlan, Permission, alertService,
    SettingValue, $uibModal,
  ) => {

    // Load
    $scope.partnerPlansReady = PartnerPlan.query().then((partnerPlans) => {
      $scope.partnerPlans = partnerPlans.map(x => new PartnerPlan(x));
      $scope.selectLastPlan();
      $scope.$watch('selectedPartnerPlan', $scope.loadPartnerPlan);
    });

    // Actions
    $scope.buildPermissionTree = () => {
      $scope.permissionTree = {};
      $scope.plansPermissions.forEach((pp) => {
        $scope.permissionTree[pp.permissionId] = true;
      });
    };
    $scope.selectLastPlan = () => {
      $scope.selectedPlan = $scope.partnerPlans[$scope.partnerPlans.length - 1];
    };
    $scope.addPlan = () => {
      var opened_model = $uibModal.open({
        templateUrl: 'pages/admin/partner_plans/create_partner_plan.slim',
        controller: 'AdminPartnerPlansCreateController',
      });
      opened_model.result.finally(() => {
        setTimeout($scope.updatePage, 1000);
      });
    };
    $scope.savePlan = (plan) => {
      plan.save();
    };
    $scope.destroyPlan = () => {
      if (confirm($translate.instant('alert.are_you_sure'))) {
        $scope.selectedPlan.delete().then(() => {
          $scope.updatePage();
        });
      }
    };

    $scope.updatePage = () => {
      PartnerPlan.query().then((partnerPlans) => {
        $scope.partnerPlans = partnerPlans.map(x => new PartnerPlan(x));
      })
    }

    // Helpers

  },
);
