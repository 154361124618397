angular.module('RocketWash').controller(
  'WashSettingsKkmController',
  (
    $scope, $route, $location, $window,
    userSession, SettingValue, serverConstants, Organization,
  ) => {
    $scope.searchQuery = '';
    $scope.predicate = 'name';
    $scope.reverse = true;

    let reloadSettings = () => {
      SettingValue.query().then((settings) => {
        $scope.settings = settings.filter(x => x.keyType === 'KkmSettingKey');

        $scope.kkmSettings = $scope.settings;
      });
    };
    reloadSettings();

    $scope.SETTING_VALUES = serverConstants.setting_values;
    $scope.save = function (setting) {
      setting.save().then(() => {
        userSession.reload();
        reloadSettings();
      });
    };

  },
);
