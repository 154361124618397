angular.module('RocketWash').controller(
  'CommonHeaderWashController',
  (
    $scope, $http, $location, $window, $translate, userSession, ServiceLocation,
    $uibModal, User,
  ) => {
    $scope.$translate = $translate;
    $scope.selected = {};
    $scope.progress = { substituteRole: false };
    $scope.pretendedRoles = [
      'organization_director', 'director', 'accountant', 'category_3',
      'category_2', 'category_1', 'partner',
    ];
    $scope.substituteRoles = [
      'organization_director', 'director', 'accountant', 'category_3',
      'category_2', 'category_1', 'partner,'
    ];
    userSession.promise.then((newUserSession) => {
      if (newUserSession.superuser) {
        ServiceLocation.query({}, { queryRole: 'admin' }).then((sls) => {
          $scope.serviceLocations = sls;
          $scope.serviceLocations.forEach((sl) => {
            sl.display = `${sl.name}, ${sl.address}`;
          });
          $scope.selected.serviceLocation = $scope.serviceLocations.find(sl => (
            newUserSession.organization.id === sl.organizationId &&
            newUserSession.service_location.id === sl.id
          ));
          $scope.selected.pretendedRole = $scope.pretendedRoles.includes(newUserSession.getRole()) ?
            newUserSession.getRole() :
            $scope.pretendedRoles[0];
        });
        User.loadAllPartners().then((partners) => {
          $scope.partners = partners;
          $scope.partners.forEach((partner) => {
            partner.display = `${partner.name}`;
          });
          $scope.selected.partner = $scope.partners.find(partner => (
            newUserSession.user.id === partner.id
          ));
          $scope.selected.pretendedRole = $scope.pretendedRoles.includes(newUserSession.getRole()) ?
            newUserSession.getRole() :
            'partner';
        });
      } else {
        $scope.serviceLocations = newUserSession.organization.service_locations;
        $scope.serviceLocations.forEach((sl) => {
          sl.display = `${sl.name}, ${sl.address}`;
        });
        $scope.selected.serviceLocation = $scope.serviceLocations
          .find(x => x.id === newUserSession.service_location.id);
        $scope.selected.substituteRole = newUserSession.getRole();
      }
    });
    $scope.pretend = () => {
      const partnerId = $scope.selected.partner && $scope.selected.partner.id;
      const slId = $scope.selected.serviceLocation && $scope.selected.serviceLocation.organizationId;
      const orgId = $scope.selected.serviceLocation && $scope.selected.serviceLocation.id;

      userSession.pretend({
        role: $scope.selected.pretendedRole,
        partnerId: partnerId,
        organizationId: slId,
        serviceLocationId: orgId,
      });
    };
    $scope.switch = () => {
      $http({
        method: 'POST',
        url: '/wash/users/switch_service_location',
        params: {
          service_location_id: $scope.selected.serviceLocation.id,
        },
      }).then(() => {
        $window.location.reload();
      });
    };
    $scope.substituteRole = () => {
      $scope.progress.substituteRole = true;
      $http({
        method: 'POST',
        url: '/wash/users/substitute_role',
        params: {
          role: $scope.selected.substituteRole,
        },
      }).then(() => {
        $window.location.reload();
      }).finally(() => {
        $scope.progress.substituteRole = false;
      });
    };

    $scope.openEmployeeFormPopup = () => {
      if (userSession.superuser) {
        return;
      }
      $uibModal.open({
        templateUrl: 'pages/wash/income/employees/form.slim',
        controller: 'WashIncomeEmployeesFormController',
        resolve: {
          resolvedEmployee: () => ({ id: userSession.id }),
          resolvedReadonly: true,
        },
      });
    };
  },
);
