

angular.module('RocketWash').controller('WashCarsDashboardFormController', function($rootScope, $scope, $state, $translate, viewStore, Car, CarModel, CarMake, CarType) {
  $scope.car = viewStore.data.car;
  if (!$scope.car) {
    return $state.go('^');
  }
  $scope.carTypeName = function(id) {
    var type;
    type = _.find($scope.carTypes, function(ct) {
      return parseInt(ct.id) === parseInt(id);
    });
    return type && type.name;
  };
  $scope.loadMake = function(make, callback) {
    if (make) {
      return CarMake.get(make.id).then(function(data) {
        data.carModels = _.map(data.carModels, function(cm) {
          return new CarModel(cm);
        });
        data.carModels = _.sortBy(data.carModels, 'name');
        angular.extend(make, data);
        return typeof callback === "function" ? callback() : void 0;
      });
    }
  };
  CarMake.query().then(function(data) {
    return $scope.carMakes = data;
  });
  if ($scope.car.carMake) {
    $scope.loadMake($scope.car.carMake);
  }
  return $scope.save = function() {
    if ($scope.car.carMake) {
      $scope.car.carMakeId = $scope.car.carMake.id;
    }
    if ($scope.car.carModel) {
      $scope.car.carModelId = $scope.car.carModel.id;
    }
    return $scope.car.save().then(function(c) {
      if (_.isEmpty(c.errors)) {
        return $state.go('^');
      } else {
        return $scope.errors = {
          create_car: c.errors
        };
      }
    });
  };
});
