

angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.settings.permissions', {
    url: '/permissions',
    tab: 'permissions',
    data: {
      permission: 'PermissionsTab'
    },
    views: {
      'content@app.wash.settings': {
        controller: 'WashSettingsPermissionsController',
        templateUrl: 'pages/wash/settings/permissions/index.slim'
      }
  }});
});
