angular.module('RocketWash').directive(
  'onDimensionsChanged',
  () => ({
    restrict: 'A',
    scope: {
      onDimensionsChanged: '&',
      intervalDuration: '<',
    },
    controller: ($scope, $element, $interval) => {
      const getDims = () => ({ width: $element.width(), height: $element.height() });
      const dimsEqual = (d1, d2) => d1.height === d2.height && d1.width === d2.width;
      let prevDims;

      const intervalCallback = () => {
        const dims = getDims();
        if (dimsEqual(dims, prevDims)) {
          return;
        }
        $scope.onDimensionsChanged({ dims });
        prevDims = dims;
      };

      prevDims = getDims();
      const interval = $interval(intervalCallback, $scope.intervalDuration || 250);

      $scope.$on('$destroy', () => {
        $interval.cancel(interval);
      });
    },
  }),
);
