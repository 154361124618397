angular.module('RocketWash').config(($stateProvider, $urlRouterProvider) => {
  $urlRouterProvider.when('/taxi/service_locations', '/taxi/service_locations/financial_operations');

  $stateProvider.state('app.taxi.service_locations', {
    url: '/service_locations',
    abstract: true,
    controller: 'TaxiServiceLocationsBaseController',
    templateUrl: 'pages/taxi/service_locations/index.slim',
    data: {
      tabs: ['services', 'map'],
    },
  });
});
