angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.charts.services_structure', {
    url: '/services_structure',
    tab: 'services_structure',
    data: {
      permission: 'ServicesStructureTab'
    },
    views: {
      'content@app.wash.charts': {
        controller: 'WashChartsServicesStructureController',
        templateUrl: 'pages/wash/charts/services_structure/index.slim'
      }
    }
  });
});
