

angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.settings.user_categories', {
    url: '/user_categories',
    tab: 'user_categories',
    data: {
      permission: 'UserCategoriesTab'
    },
    views: {
      'content@app.wash.settings': {
        controller: 'WashSettingsUserCategoriesController',
        templateUrl: 'pages/wash/settings/user_categories/index.slim'
      }
    }
  });
});
