import { env } from '@env';

angular.module('RocketWash').controller('WashOrganizationExternalPaymentsIndexController', (
  $scope, userSession, FinancialCenter, ExternalPayment, viewStore, $uibModal, tinkoffFormService,
) => {
  $scope.env = env;

  $scope.pds = $scope.pageDataService.new({
    page: 'external_payments',
    pg: {
      url: '/wash/external_payments',
      model: ExternalPayment,
      query: {
        predicate: '-created_at',
        reverse: true,
        per: 10,
        page: 1,
      },
    },
    definePaginateMethodsOn: $scope,
  });
  $scope.pg = $scope.pds.pg;
  $scope.accordion = { hideFinancialCentersDropdown: true };

  FinancialCenter.query({
    service_location_id: userSession.service_location_id,
    type: 'FinancialCenterForWashAndLegalEntity',
  }).then((fcs) => {
    $scope.fcs = fcs;
  });

  $scope.new = () => {
    $scope.edit(new ExternalPayment());
  };
  $scope.edit = (externalPayment) => {
    viewStore.store({ externalPayment });
    $uibModal.open({
      templateUrl: 'pages/wash/organization/external_payments/form_popup.slim',
    }).result.finally(() => {
      $scope.resetAndUpdate();
    });
  };

  $scope.payCard = (id) => {
    ExternalPayment.get(id).then((ep) => {
      tinkoffFormService.openForm(ep);
      $scope.$dismiss();
    });
  };

  $scope.payBankTransfer = (id) => {
    ExternalPayment.get(id).then((ep) => {
      $scope.$dismiss();
    });
  };
});
