angular.module('RocketWash').config(($stateProvider) => {
  $stateProvider.state('app.wash.dashboard.completed_reservations', {
    url: '/completed_reservations',
    tab: 'completed_reservations',
    data: {
      permission: 'CompletedReservationsTab',
    },
    views: {
      'content@app.wash.dashboard': {
        controller: 'WashDashboardCompletedReservationsController',
        templateUrl: 'pages/wash/dashboard/completed_reservations/index.slim',
      },
    },
  });
});
