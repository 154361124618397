angular.module('RocketWash').config(($stateProvider) => {
  $stateProvider.state('app.admin.settings.help_articles', {
    url: '/help_articles',
    tab: 'help_articles',
    views: {
      'content@app.admin.settings': {
        controller: 'AdminSettingsHelpArticlesBaseController',
        templateUrl: 'pages/admin/settings/help_articles/index.slim',
      },
    },
  }).state('app.admin.settings.help_articles.form', {
    url: '/form/:id',
    views: {
      'content@app.admin.settings': {
        controller: 'AdminSettingsHelpArticlesFormController',
        templateUrl: 'pages/admin/settings/help_articles/form.slim',
      },
    },
  });
});
