angular.module('RocketWash').config(function ($stateProvider, $urlRouterProvider) {
    return $stateProvider.state('app.wash.dashboard.cash_shifts', {
        url: '/cash_shifts',
        tab: 'cash_shifts',
        data: {
            permission: 'DevicesCashShiftsTab'
        },
        views: {
            'content@app.wash.dashboard': {
                controller: 'WashDashboardCashShiftsController',
                templateUrl: 'pages/wash/dashboard/cash_shifts/index.slim'
            }
        }
    });
});
