angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.income.motivation_percentage', {
    url: '/motivation_percentage',
    tab: 'motivation_percentage',
    data: {
      permission: 'MotivationPercentageTab'
    },
    views: {
      'content@app.wash.income': {
        controller: 'WashIncomeMotivationPercentageController',
        templateUrl: 'pages/wash/income/motivation_percentage/index.slim'
      }
    }
  });
});
