angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.taxi.reports.contractors', {
    url: '/contractors',
    tab: 'contractors',
    data: {
      publicPage: true,
      permission: 'ContractorsReportTab'
    },
    views: {
      'content@app.taxi.reports': {
        controller: 'TaxiReportsContractorsController',
        templateUrl: 'pages/taxi/reports/contractors/index.slim'
      }
    }
  });
});
