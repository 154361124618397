angular.module('RocketWash').controller('WashDashboardCashShiftsController', (
    $scope, $http, $filter, $translate, $timeout, userSession, rwApi, Device, alertService,
    ModuleKassa, $mdDialog, PaymentSubtype, PrintableDocument, printService, ActionCableChannel
) => {
    const getCurrentShiftForDevice = (device) => {
        return rwApi.sendRequest({
            method: 'GET',
            path: 'cash_shifts/current',
            params: {
                service_location_id: userSession.service_location_id,
                device_id: device.id
            }
        }).then((cashShift) => {
            return loadData({
                cs_id: cashShift.id,
                device_id: device.id
            }).then(data => {
                device.data = data
                device.data.currentCashShiftLoaded = true
                return device
            })
        })
    }

    const loadData = (options) => {
        return $http.post('/wash/statistics/for_cash_shift', options).then((resp) => {
            resp.data.noCurrentCashShift = !(resp.data.cash_shift && resp.data.cash_shift.id)
            return resp.data
        })
    }

    const createAndSaveCashShift = (device) => {
        return rwApi.sendRequest({
            method: 'POST',
            path: 'cash_shifts/open',
            data: {
                service_location_id: userSession.service_location_id,
                device_id: device.id
            }
        }).then((savedCs) => {
            return loadData({
                cs_id: savedCs.id,
                device_id: device.id
            }).then((data) => {
                device.data = data

                alertService.show({
                    text: 'Кассовая смена успешно открыта в программе',
                })

                return $scope.leftRightDisabled = false
            })
        })
    }

    const closeCashShiftAndSave = (device) => {
        return rwApi.sendRequest({
            method: 'POST',
            path: 'cash_shifts/close',
            data: {
                id: device.data.cash_shift.id,
                service_location_id: device.data.cash_shift.service_location_id,
            }
        }).then((savedCs) => {
            device.data.cash_shift = savedCs
            device.data.currentCashShiftLoaded = true

            alertService.show({
                text: 'Кассовая смена успешно закрыта в программе',
            })

            return device.data.leftRightDisabled = false
        })
    }

    const isKassaRegistered = (kassa) => {
        return kassa.settings && kassa.settings.app_id
    }

    const assignButtonsToDevice = (device) => {
        const buttons = [
            {
                type: 'action',
                text: $translate.instant('shifts.cash_shifts.add'),
                action: (device) => { newCashShift(device) },
                ngIf: (device) => { return device.data && device.data.currentCashShiftLoaded && (!device.data.cash_shift.created_at || device.data.cash_shift.ended_at) },
            },
            {
                type: 'action',
                text: $translate.instant('shifts.cash_shifts.table.close_shift'),
                action: (device) => { closeCashShift(device) },
                ngIf: (device) => { return device.data && device.data.currentCashShiftLoaded && device.data.cash_shift.created_at && !device.data.cash_shift.ended_at },
            },
        ]

        if (device.id) {
            buttons.push(
                {
                    type: 'action',
                    text: $translate.instant('shifts.cash_shifts.printXReport'),
                    action: (device) => { xReport(device) },
                    ngIf: (device) => { return device.data && !device.data.noCurrentCashShift },
                }
            )
        }

        if (device.type === "KkmDevice") {
            Device.useKKMServerAcquiring(device).then(useAcquiring => {
                buttons.push(
                    {
                        type: 'action',
                        text: $translate.instant('shifts.cash_shifts.cashInOut'),
                        action: (device) => { cashInOut(device) },
                        ngIf: (device) => { return device.data && !device.data.noCurrentCashShift },
                    },
                    {
                        type: 'action',
                        text: $translate.instant('shifts.cash_shifts.printAcquiringTerminalReport'),
                        action: (device) => { acquiringTerminalReport(device) },
                        ngIf: (device) => { return device.data && !device.data.noCurrentCashShift && useAcquiring },
                    }
                )
            })
        }

        return device.buttons = buttons
    }

    PaymentSubtype.query().then(pss => {
        $scope.fuelCardSubtypes = pss.filter(ps => ps.paymentTypeConstName == 'fuel_card')
    })

    PrintableDocument.query().then((docs) => {
        docs = docs.filter(x => x.subjectType === 'cash_shift')
        $scope.documents = docs
        $scope.documents.forEach((doc) => {
            doc.selected = doc.default
        })
    })

    // get all devices
    $scope.loadDevices = () => {
        rwApi.sendRequest({
            method: 'GET',
            path: 'devices',
            params: {
                service_location_id: userSession.service_location_id,
                with_deleted: $scope.includeDeleted
            }
        }).then(devices => {
            $scope.devices = []

            if (devices.length > 0) {
                devices.forEach(device => {
                    getCurrentShiftForDevice(device).then(device => {
                        assignButtonsToDevice(device)

                        $scope.devices.push(device)
                        $scope.devices = _.orderBy(devices, ['deleted_at', 'name'], ['desc', 'asc'])
                    })
                })
            } else {
                const device = {
                    id: null,
                    name: 'Не указан',
                    type: null
                }

                getCurrentShiftForDevice(device).then(device => {
                    assignButtonsToDevice(device)

                    $scope.devices.push(device)
                })
            }
        })
    }
    $scope.loadDevices()

    $scope.prevCS = (device) => {
        $scope.leftRightDisabled = true
        return loadData({
            cs_id: device.data.cash_shift.id,
            device_id: device.id,
            prev: true
        }).then((data) => {
            device.data = data
            return $scope.leftRightDisabled = false
        })
    }
    $scope.nextCS = (device) => {
        $scope.leftRightDisabled = true
        return loadData({
            cs_id: device.data.cash_shift.id,
            device_id: device.id,
            next: true
        }).then((data) => {
            device.data = data
            return $scope.leftRightDisabled = false
        })
    }

    const newCashShift = (device) => {
        triggerLadda()
        device.data.leftRightDisabled = true

        switch (device.type) {
            case null:
                createAndSaveCashShift(device)
                break

            case 'KkmDevice':
                if (confirm($filter('translate')('shifts.cash_shifts.openShiftWithCashRegister'))) {
                    Device.openShift(() => {
                        createAndSaveCashShift(device)
                    }, device)
                }
                break

            case 'ModuleKassaDevice':
                ModuleKassa.openCashShift(device.id)
                break

            case 'RocketKassaDevice':
                if (!isKassaRegistered(device)) {
                    alertService.show({
                        text: 'Касса не авторизована',
                        type: 'error'
                    })
                }

                ModuleKassa.openCashShift(device.id)
                break
        }
    }

    const closeCashShift = (device) => {
        triggerLadda()
        device.data.leftRightDisabled = true

        switch (device.type) {
            case null:
                closeCashShiftAndSave(device)
                break

            case 'KkmDevice':
                if (confirm($filter('translate')('shifts.cash_shifts.closeShiftWithCashRegister'))) {
                    Device.closeShift(() => {
                        closeCashShiftAndSave(device)
                    }, device)
                }
                break

            case 'ModuleKassaDevice':
                ModuleKassa.closeCashShift(device.id)
                break

            case 'RocketKassaDevice':
                ModuleKassa.closeCashShift(device.id)
                break
        }
    }

    const acquiringTerminalReport = (device) => {
        if (confirm($filter('translate')('shifts.cash_shifts.acquiringTerminalReportCashRegister'))) {
            triggerLadda()
            Device.acquiringTerminalReport(device)
        }
    }

    const xReport = (device) => {
        triggerLadda()

        switch (device.type) {
            case 'KkmDevice':
                if (confirm($filter('translate')('shifts.cash_shifts.xReportCashRegister'))) {
                    Device.xReport(device)
                }
                break

            case 'ModuleKassaDevice':
                ModuleKassa.xReport(device.id)
                break

            case 'RocketKassaDevice':
                ModuleKassa.xReport(device.id)
                break
        }
    }

    $scope.print = (device) => {
        printService.printToAll({
            targetId: device.data.cash_shift.id,
            targetType: 'CashShift',
            docs: $scope.documents.filter(d => d.selected),
        })
    }

    $scope.progress = {
        asyncOperation: undefined,
    }

    const triggerLadda = () => {
        $scope.progress.asyncOperation = true
        $timeout(() => {
            $scope.progress.asyncOperation = undefined
        }, 1000)
    }

    const cashInOut = (device) => {
        return $mdDialog.show({
            parent: angular.element(document.body),
            templateUrl: 'pages/wash/finance/cash_shifts/cash_in_out_modal.slim',
            clickOutsideToClose: true,
            controller: ($scope, $mdDialog) => {
                $scope.closeDialog = (params) => {
                    $mdDialog.hide(params)
                }
            }
        }).then((result) => {
            result.amount = parseFloat(result.amount)
            if (result.direction === 'in') {
                if (confirm($filter('translate')('shifts.cash_shifts.cashInOutAreYouSureIn', {
                    amount: result.amount,
                    currency: userSession.service_location.currency
                }))) {
                    Device.cashIn(result.amount, device)
                }
            } else {
                if (confirm($filter('translate')('shifts.cash_shifts.cashInOutAreYouSureOut', {
                    amount: result.amount,
                    currency: userSession.service_location.currency
                }))) {
                    Device.cashOut(result.amount, device)
                }
            }
        })
    }

    // websocket for refreshing cash shifts
    const cashShiftsChannel = new ActionCableChannel("CashShiftsChannel", {
        organization_id: userSession.organization.id,
        service_location_id: userSession.service_location.id,
        name: 'cash_shifts',
    })
    cashShiftsChannel.subscribe((data) => {
        let deviceIndex = _.findIndex($scope.devices, { id: data.device_id })
        getCurrentShiftForDevice($scope.devices[deviceIndex]).then(device => {
            $scope.devices.splice(deviceIndex, 1, device)
        })
    }).then(() => {
        $scope.$on("$destroy", function () {
            cashShiftsChannel.unsubscribe()
        })
    })
})
