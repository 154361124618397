angular.module('RocketWash').controller('AdminPlansMarketingInfoController', function ($scope, Plan, SettingValue) {
  $scope.plansReady = Plan.query().then((plans) => {
    $scope.plans = plans.map(x => new Plan(x));
  });

  $scope.treeOptions = {
    dropped: function(event) {
      if (event.source.index != event.dest.index) {
        return $scope.save();
      }
    }
  };

  $scope.removeTreeNode = function(tree) {
    return tree.remove();
  };

  $scope.save = function() {
    $scope.pmtsv.value = JSON.stringify({"basic": $scope.basicServiceTree,
                                        "extra": $scope.extraServiceTree});
    return $scope.pmtsv.save();
  };

  $scope.planSettingsPromise = Plan.get('settings_schema').then((settingsSchema) => {
    $scope.planSettingKeys = Object.keys(settingsSchema)
    .filter(key => !key.endsWith('Active'));
    return true;
  });

  SettingValue.query().then((settingValues) => {
    $scope.pmtsv = settingValues.find(x => x.key == 'plans_marketing_tree');
    $scope.pmTreeCollection = JSON.parse($scope.pmtsv.value);
    $scope.basicServiceTree = $scope.pmTreeCollection.basic;
    $scope.extraServiceTree = $scope.pmTreeCollection.extra;

    return true;
  });

  $scope.settingValue = (planVariable) => {
    let plan = $scope.plans.find(x => x.id == $scope.selectedMarketingPlan);

    if (plan) {
      return plan.settings[planVariable];
    } else {
      return '—';
    }
  };

  $scope.valueTypesDict = {
    'string': 'Текст',
    'boolean': 'Логический',
  };

  $scope.lockSwitchDict = {
    true: 'Запрещено вкл./выкл.',
    false: 'Разрешено вкл./выкл.'
  }

  $scope.pricePerDict = [
    'not_payable',
    'per_day',
    'per_lane_per_day',
    'per_camera_per_day',
    'per_ultrasonic_sensor_per_day',
  ];

  $scope.$watch("basicServiceTree", () => {
    $scope.save();
  }, true);

  $scope.$watch("extraServiceTree", () => {
    $scope.save();
  }, true);

  return $scope.newSubItem = function(node, service_type) {
    let currentNode = node;
    var tree;

    let emptySettingsNode = {
      name: '',
      value_type: 'string',
      plan_variable: '',
      nodes: [],
      price_per: 'per_day',
    };

    if (service_type == 'basic') {
      tree = $scope.basicServiceTree;
    } else if (service_type == 'extra') {
      tree = $scope.extraServiceTree;
    }

    if (currentNode == null) {
      tree.push(emptySettingsNode);
    } else {
      currentNode.nodes.push(emptySettingsNode);
    }
  };
});
