angular.module('RocketWash').controller('WashCarsDashboardBaseController', function($scope, $translate, $state, viewStore, $filter, Car, CarModel, CarMake, CarType, paginator) {
  CarType.query().then(function(data) {
    return $scope.carTypes = data;
  });
  $scope.pg = {
    url: '/wash/cars',
    model: Car,
    query: {
      searchQuery: '',
      predicate: 'tag',
      reverse: true
    }
  };
  $scope.carTypeName = function(id) {
    var type;
    type = _.find($scope.carTypes, function(ct) {
      return parseInt(ct.id) === parseInt(id);
    });
    return type && type.name;
  };
  CarMake.query().then(function(data) {
    return $scope.carMakes = data;
  });
  $scope.save = function(car) {
    if (car.carMake) {
      car.carMakeId = car.carMake.id;
    }
    if (car.carModel) {
      car.carModelId = car.carModel.id;
    }
    return car.save().then(function(c) {
      return $scope.loadMake(c.carMake);
    });
  };
  $scope["delete"] = function(car) {
    if (confirm($translate.instant('alert.are_you_sure'))) {
      return car["delete"]().then(function() {
        return $scope.pg.items = _.without($scope.pg.items, car);
      });
    }
  };
  $scope.loadMake = function(make, callback) {
    if (make) {
      return CarMake.get(make.id).then(function(data) {
        data.carModels = _.map(data.carModels, function(cm) {
          return new CarModel(cm);
        });
        data.carModels = _.sortBy(data.carModels, 'name');
        angular.extend(make, data);
        return typeof callback === "function" ? callback() : void 0;
      });
    }
  };
  $scope.edit = function(car) {
    viewStore.store({
      car: car
    });
    return $state.go('.form');
  };
  $scope.updatePage = function(page, limit) {
    if(_.isNumber(page)) {
      $scope.pg.query.page = page;
    } else {
      $scope.pg.query.page = 1;
      $scope.pg.query.reverse = !$scope.pg.query.reverse;
    }
    $scope.pg.query.per = limit || 10;
    paginator.getData($scope.pg);
  };
  $scope.$on('search-change', function(ev, data) {
    $scope.pg.query.searchQuery = data.value;
    $scope.updatePage(1);
  });
  $scope.updatePage(1);
  return $scope.newCar = function(car) {
    viewStore.store({
      car: new Car()
    });
    return $state.go('.form');
  };
});
