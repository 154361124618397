angular.module('RocketWash').directive('rwSelectWithSearch', ($timeout) => {
  return {
    restrict: 'E',
    scope: true,
    template: function (elem, attrs) {
      const rwUniqClass = `rw-${Math.random() * 10**17}`;

      attrs.rwUniqClass = rwUniqClass;

      return `
      <md-select
        md-container-class='${rwUniqClass}'
        md-on-close="onClose()"
        md-on-open="onOpen()"
        ng-change="${attrs.rwChange}"
        ng-model="${attrs.rwModel}"
        ng-disabled='${attrs.rwDisabled}'
      >
        <md-select-header ng-keydown="$event.stopPropagation()">
          <input
            ng-if='search.showInput'
            class="select-header-searchbox _md-text"
            ng-model="search.text"
            placeholder="${attrs.rwSearchPlaceholder}"
            type="search"
            ng-keyup="$event.keyCode == 13 ? selectFirst() : null"
          >
        </md-select-header>
        <md-option
          ng-disabled="item.disabled"
          ng-repeat="item in ${attrs.rwItems} | rwSelectWithSearchFilter:'${attrs.rwItemLabelAttr}':search.text:'${attrs.rwNotFoundPlaceholder}' track by item.id"
          ng-value="${attrs.rwItemValue}"
          ng-bind-html="item['${attrs.rwItemLabelAttr}'] | highlightMultiLayout:search.text"
        >
        </md-option>
      </md-select>`;
    },
    link: function(scope, elem, attrs) {
      scope.search = {
        showInput: false,
        text: ''
      };

      scope.onOpen = () => {
        scope.search.showInput = true;
        scope.focusSearchInput();
      }

      scope.onClose = () => {
        scope.search.showInput = false;
        scope.search.text = '';
      }

      scope.selectFirst = () => {
        $timeout(() => {
          $(`.${attrs.rwUniqClass} md-option`)[0].click();
        });
      };

      scope.focusSearchInput = () => {
        $timeout(() => {
          $(`.${attrs.rwUniqClass} input`).focus();
        }, 300);
      };
    }
  };
});
