angular.module('RocketWash').constant('FINANCIAL_OPERATION_FORM_CONFIG', {
  FinancialOperationIncomeCardFromReservation: {
    useFinanceClause: true,
    financialCenters: {
      toFinancialCenter: {
        filter: {
          types: ['FinancialCenterForWashAndLegalEntity'],
          restrict_by_service_location: true,
        },
      },
    },
  },
  FinancialOperationIncomeCashFromReservation: {
    useFinanceClause: true,
    financialCenters: {
      toFinancialCenter: {
        filter: {
          types: ['FinancialCenterForWashAndLegalEntity'],
          restrict_by_service_location: true,
        },
      },
    },
  },
  FinancialOperationIncomeClientBonuses: {
    useFinanceClause: true,
    financialCenters: {
      toFinancialCenter: {
        filter: {
          types: ['FinancialCenterUserBonusesBalance'],
          restrict_by_service_location: false,
        },
      },
    },
  },
  FinancialOperationIncomeEmployeeSalary: {
    useFinanceClause: true,
    financialCenters: {
      toFinancialCenter: {
        filter: {
          types: ['FinancialCenterEmployeeSalaryBalance'],
          restrict_by_service_location: true,
        },
      },
    },
  },
  FinancialOperationIncomeManualReplenishment: {
    useFinanceClause: true,
    financialCenters: {
      toFinancialCenter: {
        filter: {
          restrict_by_service_location: true,
        },
      },
    },
  },
  FinancialOperationIncomePrepaymentCard: {
    useFinanceClause: true,
    financialCenters: {
      toFinancialCenter: {
        filter: {
          types: ['FinancialCenterForWashAndLegalEntity'],
          restrict_by_service_location: true,
        },
      },
      toSecondFinancialCenter: {
        filter: {
          types: ['FinancialCenterUserMoneyBalance'],
          restrict_by_service_location: false,
        },
      },
    },
  },
  FinancialOperationIncomePrepaymentCash: {
    useFinanceClause: true,
    financialCenters: {
      toFinancialCenter: {
        filter: {
          types: ['FinancialCenterForWashAndLegalEntity'],
          restrict_by_service_location: true,
        },
      },
      toSecondFinancialCenter: {
        filter: {
          types: ['FinancialCenterUserMoneyBalance'],
          restrict_by_service_location: false,
        },
      },
    },
  },
  FinancialOperationIncomePrepaymentWire: {
    useFinanceClause: true,
    financialCenters: {
      toFinancialCenter: {
        filter: {
          types: ['FinancialCenterForWashAndLegalEntity'],
          restrict_by_service_location: false,
          payment_type_names: ['wire'],
        },
        hideBalance: true,
      },
      toSecondFinancialCenter: {
        filter: {
          types: ['FinancialCenterUserMoneyBalance'],
          restrict_by_service_location: false,
        },
      },
    },
  },
  FinancialOperationOutcomeManualExpenditure: {
    useFinanceClause: true,
    financialCenters: {
      fromFinancialCenter: {
        filter: {
          restrict_by_service_location: true,
        },
      },
    },
  },
  FinancialOperationOutcomeFine: {
    useFinanceClause: false,
    financialCenters: {
      fromFinancialCenter: {
        filter: {
          restrict_by_service_location: true,
        },
      },
    },
  },
  FinancialOperationOutcomePaySalary: {
    useFinanceClause: true,
    financialCenters: {
      fromFinancialCenter: {
        filter: {
          types: ['FinancialCenterForWashAndLegalEntity'],
          restrict_by_service_location: true,
        },
      },
      fromSecondFinancialCenter: {
        filter: {
          types: ['FinancialCenterEmployeeSalaryBalance'],
        },
      },
    },
  },
  FinancialOperationOutcomeSpendBonuses: {
    useFinanceClause: true,
    financialCenters: {
      fromFinancialCenter: {
        filter: {
          types: ['FinancialCenterUserBonusesBalance'],
          restrict_by_service_location: false,
        },
      },
    },
  },
  FinancialOperationOutcomeSpendInternalClientMoney: {
    useFinanceClause: true,
    financialCenters: {
      fromFinancialCenter: {
        filter: {
          types: ['FinancialCenterUserMoneyBalance'],
          restrict_by_service_location: false,
        },
      },
    },
  },
  FinancialOperationOutcomeSupplierPayment: {
    useFinanceClause: true,
    useWarehouseOperation: true,
    financialCenters: {
      fromFinancialCenter: {
        filter: {
          types: ['FinancialCenterForWashAndLegalEntity'],
          restrict_by_service_location: false,
        },
      },
      fromSecondFinancialCenter: {
        filter: {
          types: ['FinancialCenterUserMoneyBalance'],
          supplier: true,
          restrict_by_service_location: false,
        },
      },
    },
  },
  FinancialOperationInternalTransfer: {
    useFinanceClause: false,
    financialCenters: {
      fromFinancialCenter: {
        filter: {
          types: ['FinancialCenterForWashAndLegalEntity'],
          restrict_by_service_location: true,
        },
      },
      toFinancialCenter: {
        filter: {
          types: ['FinancialCenterForWashAndLegalEntity'],
          restrict_by_service_location: false,
        },
        hideBalance: true,
      },
    },
  },
  FinancialOperationCorrection: {
    useFinanceClause: false,
    financialCenters: {
      toFinancialCenter: {
        filter: {
          types: ['FinancialCenterForWashAndLegalEntity'],
          restrict_by_service_location: true,
        },
      },
    },
  },
  FinancialOperationExternalPayment: {
    useFinanceClause: true,
    financialCenters: {
      toFinancialCenter: {
        filter: {
          types: ['FinancialCenterRocketwashBalance'],
          restrict_by_service_location: false,
        },
      },
    },
  },
  FinancialOperationIncomeManualToRwBalance: {
    useFinanceClause: true,
    financialCenters: {
      toFinancialCenter: {
        filter: {
          types: ['FinancialCenterRocketwashBalance'],
          restrict_by_service_location: false,
        },
      },
    },
  },
  FinancialOperationOutcomeManualFromRwBalance: {
    useFinanceClause: true,
    financialCenters: {
      fromFinancialCenter: {
        filter: {
          types: ['FinancialCenterRocketwashBalance'],
          restrict_by_service_location: false,
        },
      },
    },
  },
  FinancialOperationCorrectionRwBalance: {
    useFinanceClause: true,
    financialCenters: {
      toFinancialCenter: {
        filter: {
          types: ['FinancialCenterRocketwashBalance'],
          restrict_by_service_location: false,
        },
      },
    },
  },
});
