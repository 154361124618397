angular.module('RocketWash').config(function ($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.settings.kassa', {
    url: '/kassa',
    tab: 'kassa',
    data: {
      permission: 'KassaTab',
    },
    views: {
      'content@app.wash.settings': {
        controller: 'WashSettingsKassaController',
        templateUrl: 'pages/wash/settings/kassa/index.slim'
      }
    }
  });
});
