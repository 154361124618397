angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.finance.financial_projects', {
    url: '/financial_projects',
    tab: 'financial_projects',
    data: {
      permission: 'FinancialProjectsTab'
    },
    views: {
      'content@app.wash.finance': {
        controller: 'WashFinancialProjectsBaseController',
        templateUrl: 'pages/wash/finance/financial_projects/index.slim'
      }
    }
  }).state('app.wash.finance.financial_projects.form', {
    url: '/form/:id',
    views: {
      'content@app.wash.finance': {
        controller: 'WashFinancialProjectsFormController',
        templateUrl: 'pages/wash/finance/financial_projects/form.slim'
      }
    }
  });
});
