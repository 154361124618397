

var extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

angular.module('RocketWash').factory('DiscountAction', function(railsResourceFactory, railsSerializer, userSession, $http) {
  var DiscountAction, DiscountActionFactory;
  DiscountActionFactory = railsResourceFactory({
    url: (context) => { return "/" + (context.queryRole || userSession.controllerScope()) + "/discount_actions/" + (context.id || '') },
    name: "discount_action"
  });
  return DiscountAction = (function(superClass) {
    extend(DiscountAction, superClass);

    function DiscountAction() {
      return DiscountAction.__super__.constructor.apply(this, arguments);
    }

    DiscountAction.matchToReservation = function(data, context) {
      if (context == null) {
        context = {};
      }
      return $http({
        url: (this.resourceUrl(context)) + "/match_to_reservation",
        data: data,
        method: 'POST'
      }).then(function(response) {
        return response.data;
      });
    };

    return DiscountAction;

  })(DiscountActionFactory);
});
