angular.module('RocketWash').config(($stateProvider) => {
  return $stateProvider.state('app.wash.manual_client_monitor.dashboard', {
    url: '/dashboard',
    tab: 'dashboard',
    data: {
      permission: 'ManualMonitorDashboardTab'
    },
    views: {
      'content@app.wash.manual_client_monitor': {
        controller: 'WashManualClientMonitorDashboardController',
        templateUrl: 'pages/wash/manual_client_monitor/dashboard/index.slim'
      }
    }
  });
});
