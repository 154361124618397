angular.module('RocketWash').service('reservationFormConfig', ($injector, rwApi, userSession) => {
  const KEYS = ['settings', 'payment_destinations', 'constants', 'car_makes', 'last_updated_at'];

  const service = {
    dynamic: null,
    preReservation: null,
    autostartCamera: false,
    calendar: {},
    popupMode: null,

    setPopupMode: (mode) => {
      service.popupMode = mode;
    },

    setDefaultPopupMode: () => {
      if (!service.popupMode) {
        service.setPopupMode('reservation');
      };
    },

    loadDynamic: () => {
      const rf = $injector.get('reservationForm');

      const cm_id = rf.carContractor.car.car_model_id;

      if (cm_id) {
        return rwApi.sendRequest({
          method: 'GET',
          path: 'reservation_form/config/dynamic',
          params: {
            service_location_id: userSession.service_location_id,
            car_model_id: cm_id,
          }
        }).then((data) => {
          service.dynamic = data;
        });
      }
    },

    loadGeneral: () => {
      return rwApi.sendRequest({
        method: 'GET',
        path: 'reservation_form/config/general',
        params: {
          service_location_id: userSession.service_location_id,
          last_updated_at: service.last_updated_at,
        }
      }).then((data) => {
        if (!data.cache_up_to_date) {
          KEYS.forEach((key) => {
            service[key] = data[key];
          });

          service.constants.car_models = {};
          service.constants.car_makes.forEach((make) => {
            service.constants.car_models[make.id] = make.car_models;
            delete make.cars;
          });
        }
      });
    },

    getPreReservation: () => service.preReservation,
    setPreReservation: (pr) => service.preReservation = pr,

    getAutostartCamera: () => service.autostartCamera,
    setAutostartCamera: (bool) => service.autostartCamera = bool,

    filteredPaymentDestinations: (pdId) => {
      const rf = $injector.get('reservationForm');

      service.constants.filtered_payment_destinations = service.constants.filtered_payment_destinations || [];

      let copy = angular.copy(service.constants.payment_destinations);

      let isTaxi = rf.carContractor.car.cars_car_types.find((cct) => {
        let ctg = rf.config.constants.car_types_groups.find((x) => {
          return cct.car_types_group_id == x.id;
        })

        return !!ctg && !!ctg.taxi_organization_id;
      });

      if (!isTaxi) {
        _.remove(copy, x => x.payment_type == "taxi_driver_account");
      }

      if (!_.isEqual(_.map(service.constants.filtered_payment_destinations, 'id'), _.map(copy, 'id'))) {
        service.constants.filtered_payment_destinations = copy;
      }

      return service.constants.filtered_payment_destinations;
    },

    paymentDestinationRequiresConfirmation: (pdId) => {
      const pd = service.constants.payment_destinations.find((item) => item.id == pdId);
      return pd && pd.requires_confirmation;
    },

    reset: () => {
      service.preReservation = null;
      service.autostartCamera = false;
      service.dynamic = null;
      service.calendar = {};
      service.popupMode = null;
    },

  };

  service.loadGeneral();

  return service;
});
