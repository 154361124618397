angular.module('RocketWash').controller('WashSettingsCarTypesGroupsFormController', (
  $scope, CarTypesGroup, resolvedCarTypesGroup,
) => {

  $scope.ctg = new CarTypesGroup(resolvedCarTypesGroup);
  if ($scope.ctg.id) {
    $scope.ctg.get().then(() => {
      console.log('reloaded');
    });
  };

  $scope.save = () => {
    $scope.formDisabled = true;
    $scope.ctg.save().then((ctg) => {
      if (_.isEmpty(ctg.errors)) {
        $scope.$dismiss();
      } else {
        $scope.errors = { create_car_types_group: ctg.errors };
      }
    }).finally(() => { $scope.formDisabled = false; });
  };
});
