angular.module('RocketWash').directive('rwDateFilter', (
  dateRanges, pageDataService, dateRangeStorage, $rootScope,
) => {
  // TODO: detach view from dateRangeStorage
  return {
    restrict: 'E',
    scope: {
      page: '=',
    },
    templateUrl: 'directives/rw-date-filter/rw-date-filter.slim',
    link(scope, _element, _attrs) {
      scope.updateFromStorage = () => {
        scope.from = dateRangeStorage.from().toDate();
        scope.to = dateRangeStorage.to().toDate();
      };
      $rootScope.$on('date-range-updated', scope.updateFromStorage);
      scope.updateFromStorage();

      scope.timeRanges = ['day', 'yesterday', 'week', 'rolling_month', 'month', 'last_month', 'custom'];
      scope.setTimeRange = function (range) {
        scope.timeRange = range;
        if (scope.timeRange === 'custom') {
          return;
        }
        const [from, to] = (dateRanges[range] || dateRanges.default)();
        dateRangeStorage.update({
          from: from.toDate(),
          to: to.toDate(),
        });
      };
      scope.updateFilterParams = ({ fromToField } = {}) => {
        // FIXME: move fromToField out
        if (fromToField) {
          if (!scope.page) {
            console.error('page attribute required');
          }
          scope.pds = pageDataService.new({ page: scope.page });
          scope.pds.pg.query.fromToField = fromToField;
        }
        dateRangeStorage.update({ from: scope.from, to: scope.to });
        const selectedRange = [
          moment(scope.from),
          moment(scope.to),
        ];
        scope.timeRange = Object.keys(dateRanges).find((key) => {
          const range = dateRanges[key]();
          return selectedRange[0].isSame(range[0]) && selectedRange[1].isSame(range[1]);
        }) || 'custom';
      };
      scope.updateFilterParams();
    },
  };
});
