

angular.module('RocketWash').directive('rwPikaday', function() {
  return {
    restrict: 'A',
    scope: {
      value: '=rwPikaday'
    },
    template: '<input data-ng-model="value" type="text">',
    replace: true,
    link: function(scope, el, options) {
      var locales, opts, picker;
      locales = {
        ru: {
          previousMonth: 'Предыдущий месяц',
          nextMonth: 'Следующий месяц',
          months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
          weekdays: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
          weekdaysShort: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']
        }
      };
      opts = {
        field: el[0],
        format: 'DD/MM/YYYY',
        defaultDate: moment(scope.value),
        minDate: new Date('1-1-2013')
      };
      if (locales[$('html').data('lang')]) {
        opts.i18n = locales[$('html').data('lang')];
      }
      return picker = new Pikaday(opts);
    }
  };
});
