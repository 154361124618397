angular.module('RocketWash').controller('AdminSettingsPaymentTypesBaseController', function($rootScope, $scope, $state, $translate, viewStore, PaymentType, ResourceKind, serverConstants) {
  PaymentType.query().then(function(types) {
    return $scope.types = _.map(types, function(t) {
      return new PaymentType(t);
    });
  });
  ResourceKind.query().then(function(kinds) {
    return $scope.kinds = _.map(kinds, function(k) {
      return new ResourceKind(k);
    });
  });
  $scope.rwConstNames = serverConstants.payment_types;
  $scope.newPaymentType = function() {
    return $scope.types.unshift(new PaymentType({
      name: ''
    }));
  };
  $scope.save = function(type) {
    return type.save();
  };
  $scope["delete"] = function(type) {
    type["delete"]();
    return $scope.types = _.without($scope.types, type);
  };
  return $scope.kindName = function(id) {
    var ref;
    return (ref = _.find($scope.kinds, function(k) {
      return k.id === id;
    })) != null ? ref.name : void 0;
  };
});
