

angular.module('RocketWash').controller('CommonLowBalanceNotificationController', function($scope, SettingValue, userSession, $translate) {
  $scope.$translate = $translate;
  return userSession.promise.then(function() {
    return SettingValue.query().then(function(values) {
      $scope.balanceNotificationThreshold = _.find(values, function(v) {
        return v.key === 'balance_notification_threshold';
      });
      return $scope.lowBalance = function() {
        var ref;
        return parseFloat((ref = userSession.organization) != null ? ref.balance : void 0) < parseInt($scope.balanceNotificationThreshold.value);
      };
    });
  });
});
