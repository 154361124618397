angular.module('RocketWash').service('tinkoffFormService', (userSession) => {
  const service = {
    openForm: (externalPayment) => {
      const form = $('#tinkoff-form-container').find('form[name="TinkoffPayForm"]');
      if (!form) {
        throw new Error('Tinkoff form not found');
      }
      const set = (obj) => {
        Object.entries(obj).forEach(([key, value]) => {
          form.find(`input[name="${key}"]`).val(value);
        });
      };
      set({
        terminalkey: userSession.tinkoff_settings.tinkoff_terminal_key,
        order: `${externalPayment.organizationId}_${externalPayment.id}`,
        name: externalPayment.payerName,
        email: externalPayment.payerEmail,
        phone: externalPayment.payerPhone,
        description: userSession.tinkoff_settings.tinkoff_payment_description,
        amount: externalPayment.amount,
        receipt: JSON.stringify(service.receiptInfo(externalPayment)),
      });
      form.submit();
    },
    receiptInfo: (externalPayment) => {
      const centsInRouble = 100;
      return {
        Email: externalPayment.payerEmail,
        Phone: externalPayment.payerPhone,
        Taxation: 'usn_income_outcome',
        Items: [
          {
            Name: userSession.tinkoff_settings.tinkoff_payment_description,
            Price: externalPayment.amount * centsInRouble,
            Quantity: 1,
            Amount: externalPayment.amount * centsInRouble,
            Tax: 'none',
          },
        ],
      };
    },
  };

  return service;
});
