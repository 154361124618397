angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  $urlRouterProvider.when('/admin/plans', '/admin/plans/plans_settings');
  return $stateProvider.state('app.admin.plans', {
      url: '/plans',
      templateUrl: 'pages/admin/plans/index.slim',
      controller: 'AdminPlansBaseController',
      data: {
        tabs: ['plans_settings', 'marketing_info']
      }
    });
});
