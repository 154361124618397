angular.module('RocketWash').controller('WashSettingsPaymentSubtypeFuelCardsFormController', (
  $scope, PaymentSubtype, resolvedPaymentSubtype,
) => {
  $scope.paymentSubtype = new PaymentSubtype(resolvedPaymentSubtype);

  if ($scope.paymentSubtype.id) {
    $scope.paymentSubtype.get().then(() => {
      console.log('reloaded');
    });
  } else {
    $scope.paymentSubtype.payment_type_const_name = 'fuel_card';
    $scope.paymentSubtype.active = true;
  }

  $scope.save = () => {
    $scope.formDisabled = true;
    $scope.paymentSubtype.save().then((paymentSubtype) => {
      if (_.isEmpty(paymentSubtype.errors)) {
        $scope.$dismiss();
      } else {
        $scope.errors = { create_payment_subtype: paymentSubtype.errors };
      }
    }).finally(() => { $scope.formDisabled = false; });
  };
});
