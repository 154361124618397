angular.module('RocketWash').config(($stateProvider) => {
  return $stateProvider.state('app.wash.dashboard.work_shifts', {
    url: '/work_shifts',
    tab: 'work_shifts',
    data: {
      permission: 'WorkShiftsTab',
    },
    views: {
      'content@app.wash.dashboard': {
        controller: 'WashDashboardWorkShiftsController',
        templateUrl: 'pages/wash/dashboard/work_shifts/index.slim',
      },
    },
  });
});
