angular.module('RocketWash').config(($stateProvider) => {
  return $stateProvider.state('reservation_review', {
    url: '/review_for_customer/:reservationUUID',
    controller: 'ReviewForCustomerController',
    templateUrl: 'pages/review_for_customer/review_for_customer.slim',
    data: {
      publicPage: true,
    },
    resolve: {
      _serverTranslations: () => {
        return window.translationsPromise;
      },
    },
  });
});
