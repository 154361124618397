angular.module('RocketWash').factory('Transaction', (
  railsResourceFactory, userSession, TenantSwitchInterceptor,
) => {
  const Resource = railsResourceFactory({
    url: (context) => { return "/" + (context.queryRole || userSession.controllerScope()) + "/transactions/" + (context.id || '') },
    name: 'transaction',
  });

  Resource.addInterceptor(TenantSwitchInterceptor);

  return Resource;
});
