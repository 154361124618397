angular.module('RocketWash').service('unitOrPackageSelectService', ($rootScope, $injector, UNIT_OR_PACKAGE) => {
  const service = {
    items: UNIT_OR_PACKAGE,
    selected: null,
    load: () => {
      service.select(service.items[0]);
    },
    reload: () => {
      service.reset();
      return service.load();
    },
    reset: () => {
      service.selected = null;
      service.items = UNIT_OR_PACKAGE;
    },
    select: (item) => {
      service.selected = item;
      $rootScope.$broadcast('unitOrPackageSelectService-selected');
    },
  };

  service.load();

  return service;
});
