angular.module('RocketWash').component('rwReservationServices', {
  templateUrl: 'components/rw-reservation/steps/services/index.slim',
  bindings: {
  },
  controller: function ($scope, $timeout, reservationForm, userSession) {

    $scope.userSession = userSession;
    $scope.rf = reservationForm;
    $scope.selected = {};

    $timeout(() => {
      $('#reservation-services-autocomplete').focus();
    }, 100);

    $scope.$watch('rf.services.selected', () => {
      $scope.rf.services.calculate();
    }, true);

    $scope.getServices = (searchQuery) => {
      return reservationForm.services.search({query: searchQuery});
    };

    $scope.serviceSelected = (service) => {
      if (service && service.id) {
        reservationForm.services.add(service.id);
      }
      reservationForm.services.searchText = null;
    };

    $scope.showTimeWarning = () => {
      const maxDuration = _.get($scope.rf, 'services.total.max_duration');
      return maxDuration && maxDuration < $scope.rf.r().full_duration;
    };

    $scope.reduceReservationDuration = () => {
      const maxDuration = $scope.rf.services.total.max_duration;
      return $scope.rf.r().full_duration = maxDuration;
    };

  },
});
