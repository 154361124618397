angular.module('RocketWash').service('keyboardLayout', function(serverConstants) {
  return {
    use: function(keyboardType) {
      const layout = serverConstants.keyboard_layouts[keyboardType];

      return {
        keyboard: layout.keyboard,
        languages: layout.languages,

        mapToLanguage: function(string, language) {
          return string.split('').map((char) => {
            const letter = this.keyboard.find((k) => {
              return Object.values(k).includes(char);
            });

            return letter[language];
          }).join('');
        },

        allLayoutVariants: function(string) {
          return this.languages.map((language) => {
            return this.mapToLanguage(string, language);
          });
        },
      };
    }
  };
});
