angular.module('RocketWash').factory('FinanceClause', (
  railsResourceFactory, userSession,
) => {
  const Resource = railsResourceFactory({
    url: context => `/${context.queryRole || userSession.controllerScope()}/finance_clauses/${context.id || ''}`,
    name: 'finance_clause',
  });

  return Resource;
});
