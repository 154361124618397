angular.module('RocketWash').controller('TaxiReportsReservationsController', (
  $scope, $controller, $q, $filter, $translate, Customer, ServiceLocation, userSession,
) => {

  $controller('WashChartsReservationsController', {$scope: $scope});

  $scope.exportXLS = () => {
    window.open(`${$scope.env.apiUrl}/taxi/charts/reservations_report.xlsx?personal_cabinet_uuid=${userSession.personal_cabinet_uuid}&${$.param($scope.pds.pg.query)}`, '_blank');
  };

  $scope.showImages = (item) => {
    item.showImage = true;
  };
  $scope.hideImages = (item) => {
    item.showImage = false;
  };

  $scope.pds.pg.url = 'taxi/charts/reservations_report';

});
