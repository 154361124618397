angular.module('RocketWash').controller(
  'WashSettingsSuspiciousActivityController',
  (
    $scope, $route, $location, $window,
    userSession, SettingValue, serverConstants, Organization,
  ) => {
    $scope.searchQuery = '';
    $scope.predicate = 'name';
    $scope.reverse = true;
    $scope.settings = {};

    let reloadSettings = () => {
      SettingValue.query().then((settings) => {
        $scope.settings.reservation =
          settings.filter(x => x.keyType === 'ReservationSuspiciousActivitySettingKey');
      });
    };
    reloadSettings();

    $scope.SETTING_VALUES = serverConstants.setting_values;
    $scope.save = function (setting) {
      setting.save().then(() => {
        userSession.reload();
        reloadSettings();
      });
    };

  },
);
