angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.income.motivation_fixed', {
    url: '/motivation_fixed',
    tab: 'motivation_fixed',
    data: {
      permission: 'MotivationFixedTab'
    },
    views: {
      'content@app.wash.income': {
        controller: 'WashIncomeMotivationFixedController',
        templateUrl: 'pages/wash/income/motivation_fixed/index.slim'
      }
    }
  });
});
