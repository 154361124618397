import L from 'leaflet';
import 'leaflet.markercluster';

const LOCAL_STORAGE_STATE_KEY = 'LOCAL_STORAGE_ONLINE_LOCATION_REQUESTS_STATE_KEY';

angular.module('RocketWash').controller('AdminOnlineReservationsLocationRequestsIndexController', (
  $window, $scope, Reservation, ServiceLocation, userSession, MobileRequestLocation, dateRangeStorage,
) => {

  $scope.$on('date-range-updated', () => {
    loadData();
  });

  $scope.slLayer = null;
  const loadData = () => {
    if (!$scope.map) { return; };

    const from = dateRangeStorage.from().toISOString();
    const to = dateRangeStorage.to().toISOString();

    MobileRequestLocation.all({from, to}).then((data) => {

      if ($scope.slLayer) {
        $scope.slLayer.remove();
      };
      $scope.slLayer = L.markerClusterGroup();
      const items = data.map(([id, lat, lon]) => {

        const geojsonMarkerOptions = {
          radius: 2,
          fillColor: "#7F0",
          color: "#000",
          weight: 1,
          opacity: 1,
          fillOpacity: 0.4,
          id: id,
        };

        const newLayer = L.circleMarker({lat, lon}, geojsonMarkerOptions);

        newLayer.on('click', (e) => {
          newLayer.bindPopup(hintContent);
        });

        $scope.slLayer.addLayer(newLayer);
      });

      $scope.slLayer.addTo($scope.map);
    });
  };


  $scope.map = L.map('location-requests').setView([53.750732672433465, 27.607492926269526], 8);

  L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  }).addTo($scope.map);

  loadData();

});
