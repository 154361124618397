angular.module('RocketWash').component('rwPreReservation', {
  templateUrl: 'components/rw-pre-reservation/index.slim',
  controllerAs: 'vm',
  controller: function ($rootScope, $scope, $translate, $q, $timeout, $uibModalStack, userSession, ServiceLocationLane, $filter, reservationForm) {

    // if (preReservation.id) {
    //   reservationForm.preReservations.load(preReservation);
    // } else {
    //   reservationForm.preReservations.init(preReservation);
    // };
    //

    $scope.rf = reservationForm;
    $scope.progress = {
      savePreReservation: false
    };

    $scope.save = () => {
      if ($scope.progress.savePreReservation) { return; };

      $scope.progress.savePreReservation = true;
      $scope.rf.preReservations.save().finally(() => {
        $scope.progress.savePreReservation = false;
      });;
    };

    $scope.pr = () => {
      return reservationForm.preReservations.preReservation;
    };

    $scope.backToReservation = () => {
      reservationForm.config.setPopupMode('reservation');
    };

    $scope.destroyPreReservation = () => {
      if ($scope.progress.savePreReservation) { return; };

      $scope.progress.savePreReservation = true;
      $scope.rf.preReservations.delete().finally(() => {
        $scope.progress.savePreReservation = false;
      });
      $uibModalStack.dismissAll();
      $scope.rf.resetAll();
    };

    ServiceLocationLane.query().then((lanes) => {
      $scope.lanes = lanes.filter(x => x.active);
    });
  },
});
