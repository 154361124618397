angular.module('RocketWash').constant('PREDEFINED_FILTERS_FOR_INTEGRATIONS_SL', {
  service_locations: [
    {
      name: 'Только Яндекс Карты включены',
      filters: [
        {
          custom: true,
          condition: 'custom__only_yandex_maps_sl',
        },
      ],
    },
    {
      name: 'Только Яндекс Заправки включены',
      filters: [
        {
          custom: true,
          condition: 'custom__only_yandex_fuel_sl',
        },
      ],
    },
    {
      name: 'Только Carbox включены',
      filters: [
        {
          custom: true,
          condition: 'custom__only_carbox_sl',
        },
      ],
    },
    {
      name: 'Только Лейка включены',
      filters: [
        {
          custom: true,
          condition: 'custom__only_leyka_sl',
        },
      ],
    },
  ],
});
