angular.module('RocketWash').directive('rwRecentCameraEvents', () => {
  return {
    restrict: 'E',
    scope: {
      config: '=',
    },
    templateUrl: 'directives/rw-recent-camera-events/rw-recent-camera-events.slim',
    controller: ($scope, $element, CameraEvent, userSession) => {

      let reload = () => {
        CameraEvent.getLatest().then((events) => {
          $scope.cameraEvents = events;
        });
      };
      reload();

      // let controlPanelChannel = new ActionCableChannel("CameraEventsChannel", {service_location_id: userSession.service_location_id});
      // controlPanelChannel.subscribe((data) => {
      //   switch (data.message) {
      //     case 'reload_recent':
      //       reload();
      //     break;
      //   };
      // });

    },
  };
});
