angular.module('RocketWash').controller('AdminSettingsPromoCodesBaseController', function($rootScope, $scope, $state, $translate, viewStore, PromoCode, ResourceKind, serverConstants) {
  PromoCode.query().then(function(codes) {
    $scope.codes = _.map(codes, function(c) {
      return new PromoCode(c);
    });
  });
  $scope.newPromoCode = function() {
    $scope.codes.unshift(new PromoCode({}));
  };
  $scope.save = function(code) {
    if (code.code && code.startsAt && code.endsAt) {
      code.save();
    }
  };
  $scope["delete"] = function(code) {
    code["delete"]();
    $scope.codes = _.without($scope.codes, code);
  };
});
