import { env } from '@env';

angular.module('RocketWash').controller('TaxiAutoparkCarsFormController', (
  $scope, YandexTaxiCar, CarMake, CarModel, $translate, alertService, resolvedCar, Upload,
) => {

  $scope.car = new YandexTaxiCar(resolvedCar);

  CarMake.query().then(function(carMakes) {
    $scope.carMakes = carMakes;
    if($scope.car.carMake) {
      $scope.loadMake($scope.car.carMake);
    }
    return;
  });

  $scope.loadMake = (carMake) => {
    if (!carMake.carModels) {
      return CarMake.get(carMake.id).then(function(data) {
        data.carModels = _.sortBy(data.carModels, 'name');
        angular.extend(carMake, data);
        return typeof callback === "function" ? callback() : void 0;
      });
    }
  }

  $scope.validate = () => {
    const errors = {};

    if (!$scope.car.carMake) {
      errors.carMake = true;
    }

    if (!$scope.car.carModel) {
      errors.carModel = true;
    }

    if (!$scope.car.tag) {
      errors.tag = true;
    }

    if (Object.keys(errors).length === 0) {
      return true;
    }

    $scope.errors = { create_car: errors };

    return false;
  };

  $scope.save = () => {
    if (!$scope.validate()) {
      return;
    }

    $scope.car.carMakeId = $scope.car.carMake.id;
    $scope.car.carModelId = $scope.car.carModel.id;

    $scope.formDisabled = true;

    $scope.car.save().then((car) => {
      if (_.isEmpty(car.errors)) {
        $scope.$dismiss();
      } else {
        $scope.errors = { create_car: car.errors };
      }
    }).finally(() => { $scope.formDisabled = false; });
  };

});
