angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.income.report', {
    url: '/report',
    tab: 'report',
    data: {
      permission: 'SalariesTab',
    },
    views: {
      'content@app.wash.income': {
        controller: 'WashIncomeReportController',
        templateUrl: 'pages/wash/income/report/index.slim'
      }
    }
  });
});
