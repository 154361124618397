angular.module('RocketWash').directive('rwPriceListSelect', () => ({
  restrict: 'E',
  replace: true,
  templateUrl: 'directives/rw-price-list-select/index.slim',
  scope: {
  },
  controller: (
    $scope, plSelectService
  ) => {
    $scope.plSelectService = plSelectService;
  },
}));
