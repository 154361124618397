angular.module('RocketWash').controller(
  'PartnerFinancesDashboardBaseController',
  (
    $scope, $translate, $uibModal,
    userSession, FinancialCenter, FinancialOperation,
  ) => {
    $scope.pds = $scope.pageDataService.new({
      page: 'dashboard',
      filters: {
        attributes: [
          { name: 'date', type: 'datetime' },
          { name: 'ordinal', type: 'number' },
          { name: 'amount', type: 'number' },
          { name: 'record_type', type: 'string' },
          { name: 'comment', type: 'string' },
          { name: 'document', type: 'string' },
          { name: 'organization_name', type: 'string' },
        ],
        scopes: ['record_type', 'comment', 'document', 'organization_name'],
      },
      pg: {
        url: '/partner/financial_centers/partner_common_details',
        model: null,
        query: {
          fromToField: 'date',
          predicate: '-date',
          reverse: true,
          per: 10,
          page: 1,
        },
      },
      definePaginateMethodsOn: $scope,
    });
  },
);
