

angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.settings.payment_subtype_fuel_cards', {
    url: '/payment_subtype_fuel_cards',
    tab: 'payment_subtype_fuel_cards',
    data: {
      permission: 'PaymentSubtypeFuelCardsTab'
    },
    views: {
      'content@app.wash.settings': {
        controller: 'WashSettingsPaymentSubtypeFuelCardsBaseController',
        templateUrl: 'pages/wash/settings/payment_subtype_fuel_cards/index.slim'
      }
    }
  }).state('app.wash.settings.payment_subtype_fuel_cards.form', {
    url: '/form',
    views: {
      'content@app.wash.settings': {
        controller: 'WashSettingsPaymentSubtypeFuelCardsFormController',
        templateUrl: 'pages/wash/settings/payment_subtype_fuel_cards/form.slim'
      }
    }
  });
});
