angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.warehouse.single_product_operations', {
    url: '/single_product_operations',
    tab: 'single_product_operations',
    data: {
      permission: 'WarehouseOperationsTab'
    },
    views: {
      'content@app.wash.warehouse': {
        controller: 'WashWarehouseSingleProductOperationsBaseController',
        templateUrl: 'pages/wash/warehouse/single_product_operations/index.slim'
      }
    }
  });
});
