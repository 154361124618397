angular.module('RocketWash').config(($stateProvider) => {
  return $stateProvider
    .state('app.admin.finance.financial_operations', {
      url: '/financial_operations',
      tab: 'financial_operations',
      views: {
        'content@app.admin.finance': {
          controller: 'AdminFinanceFinancialOperationsController',
          templateUrl: 'pages/admin/finance/financial_operations/index.slim',
        },
      },
    });
});
