angular.module('RocketWash').config(($stateProvider) => {
  return $stateProvider.state('sign_in', {
    url: '/sign_in?phone?just_registered',
    controller: 'SignInController',
    controllerAs: 'sic',
    templateUrl: 'pages/sign_in/sign_in.slim',
    data: {
      permission: 'SignIn',
      publicPage: true,
    },
    resolve: {
      _serverTranslations: () => {
        return window.translationsPromise;
      },
    },
  });
});
