angular.module('RocketWash').config(($stateProvider) => {
  $stateProvider.state('app.wash.organization.plans', {
    url: '/plans',
    tab: 'plans',
    data: {
      permission: 'OrganizationPlansTab',
    },
    views: {
      'content@app.wash.organization': {
        controller: 'WashOrganizationPlansController',
        templateUrl: 'pages/wash/organization/plans/index.slim',
      },
    },
  });
});
