angular.module('RocketWash').directive(
  'rwNumberPicker',
  () => ({
    restrict: 'E',
    templateUrl: 'directives/rw-number-picker/rw-number-picker.slim',
    require: 'ngModel',
    scope: {
      ngDisabled: '='
    },
    link: (scope, _element, attrs, ngModelController) => {
      ngModelController.$render = () => {
        scope.rwValue = ngModelController.$viewValue;
      };
      scope.$watch('rwValue', () => {
        ngModelController.$setViewValue(parseInt(scope.rwValue, 10) || 0);
      });
      scope.min = attrs.min;
      scope.max = attrs.max;
    },
    controller: (
      $scope,
    ) => {
      // TODO: check for string
      const value = () => parseInt($scope.rwValue, 10) || 0;

      $scope.canDecrement =
        () => !($scope.min && (value() - 1) < $scope.min);
      $scope.canIncrement =
        () => !($scope.max && (value() + 1) > $scope.max);

      $scope.decrement = () => {
        if ($scope.canDecrement()) {
          $scope.rwValue -= 1;
        }
      };
      $scope.increment = () => {
        if ($scope.canIncrement()) {
          $scope.rwValue += 1;
        }
      };
    },
  }),
);
