angular.module('RocketWash').controller('WashOrganizationBaseController', (
  $scope, total, Sms,
) => {
  console.log('dbg');
  $scope.total = total;
  $scope.balancePromise = Sms.$get('wash/sms/balance').then((data) => {
    $scope.balance = data.balance;
  });
  $scope.humanizePrice = price => Math.round(price * 100) / 100;
});
