angular.module('RocketWash').controller(
  'WashCounterpartiesClientsController',
  ($scope, $translate, $state, viewStore, Customer, $uibModal, CarType) => {
    // Initialization
    const stateTabName = () => $state.current.name.split('.')[3];
    const stateToType = {
      individuals: 'Individual',
      entrepreneurs: 'Entrepreneur',
      companies: 'Company',
      suppliers: 'Supplier',
    };

    $scope.getUserType = () => stateToType[stateTabName()];

    $scope.canMergeUsers = () => {
      const selected = ($scope.pds.pg.items || []).filter(x => x.selected);
      const properLength = selected.length === 2;
      const phones = selected.map(item => item.phone);
      const phonesMatch = properLength && phones.reduce((a, b) => {
        return (a === b) ? a : NaN;
      }) === phones[0];

      return phonesMatch && properLength;
    };

    $scope.mergeUsers = () => {
      const selected = ($scope.pds.pg.items || []).filter(x => x.selected);
      $uibModal.open({
        templateUrl: 'pages/wash/counterparties/merge/popup.slim',
        controller: 'WashCounterpartiesMergePopupController',
        resolve: {
          id1: selected[0].id,
          id2: selected[1].id,
        },
      }).result.finally(() => {
        $scope.updatePage();
      });
    };

    // FIXME reassigning attributes should be impossible or made explicit
    CarType.query().then((carTypes) => {
      $scope.carTypes = carTypes;
      const carTypeValues = carTypes.map(x => ({ value: x.id, label: x.name }));
      const targetAttribute = $scope.pds.filters.attributes.find(x => x.name === 'active_car_type_ids');
      targetAttribute.values = carTypeValues;
    });

    $scope.pds = $scope.pageDataService.new({
      page: 'counterparties',
      filters: {
        attributes: [
          { name: 'name', type: 'string' },
          { name: 'phone', type: 'string' },
          { name: 'tin', type: 'string' },
          { name: 'kpp', type: 'string' },
          { name: 'sex', type: 'string' },
          { name: 'all_car_tags', type: 'string' },
          { name: 'all_car_make_models', type: 'string' },
          { name: 'user_category_name', type: 'string' },
          { name: 'balance_bonuses', type: 'number' },
          { name: 'bonuses_percentage', type: 'number' },
          { name: 'discount', type: 'number' },
          { name: 'balance_money', type: 'number' },
          { name: 'date_of_birth', type: 'datetime' },
          { name: 'phone_confirmed', type: 'enum', values: [
            { value: 'нет', label: 'Нет' },
            { value: 'да', label: 'Да' },
          ] },
          { name: 'active_car_type_ids', type: 'enum', values: [] },
          { name: 'tags_body', type: 'string' },
          {
            name: 'can_send_notification',
            type: 'enum',
            values: [
              { value: 'нет', label: 'Нет' },
              { value: 'да', label: 'Да' },
            ],
          },
          { name: 'client_activity_category', type: 'string' },
          { name: 'discount_card_number', type: 'string' },
        ],
        scopes: ['name', 'phone', 'tin', 'kpp', 'sex', 'all_car_tags',
          'all_car_make_models', 'user_category_name', 'tags_body',
          'bonuses_percentage', 'discount', 'client_activity_category', 'discount_card_number'],

        beforeSend: (filters) => {
          if ($scope.getUserType() == 'Supplier') {
            filters.push({
              attr: 'supplier',
              condition: 'equals',
              value: true,
            });
          } else {
            filters.push({
              attr: 'user_type.value',
              condition: 'equals',
              value: $scope.getUserType().toLowerCase(),
            });
          }
        },
      },
      pg: {
        url: '/wash/customers',
        model: Customer,
        query: {
          skipDateFilter: true,
          predicate: 'name',
          reverse: true,
          user_type: null,
          role: null,
          page: 1,
          per: 10,
        },
        items: [],
        selectedKeys: [],
        selectAll: false,
      },
      definePaginateMethodsOn: $scope,
    });

    // Actions
    $scope.save = (contractor) => {
      contractor.save();
    };
    $scope.saveDate = (contractor) => {
      const dob = moment(contractor.dateOfBirth, 'DD/MM/YYYY');
      if (!dob.isValid()) {
        return $translate.instant('errors.userInfo.dateFormat');
      }
      contractor.dateOfBirth = dob.format('YYYY-MM-DD');
      $scope.save(contractor);
      return null;
    };
    $scope.delete = (contractor) => {
      if (confirm($translate.instant('alert.are_you_sure'))) {
        contractor.delete().then(() => {
          $scope.pds.pg.items = $scope.pds.pg.items.filter(x => x !== contractor);
        });
      }
    };
    $scope.sms = () => {
      if (!$scope.pds.pg.selectedKeys.length) {
        return;
      }
      if ($scope.getUserType() == 'Supplier') {
        $scope.pds.selectedFilters.push({
          attr: 'supplier',
          condition: 'equals',
          value: true,
        });
      } else {
        $scope.pds.selectedFilters.push({
          attr: 'user_type.value',
          condition: 'equals',
          value: $scope.getUserType().toLowerCase(),
        });
      }
      $state.go('app.wash.counterparties.clients.sms');
    };
    $scope.sendNotification = () => {
      if (!$scope.pds.pg.selectedKeys.length) {
        return;
      }
      if ($scope.getUserType() === 'Supplier') {
        $scope.pds.selectedFilters.push({
          attr: 'supplier',
          condition: 'equals',
          value: true,
        });
      } else {
        $scope.pds.selectedFilters.push({
          attr: 'user_type.value',
          condition: 'equals',
          value: $scope.getUserType().toLowerCase(),
        });
      }
      $state.go('app.wash.counterparties.clients.notification');
    };
    $scope.triggerFullPage = (pageSelected) => {
      $scope.pds.pg.items.forEach((item) => {
        item.selected = pageSelected;
      });
    };
    $scope.newClient = () => {
      const userType = ($scope.getUserType() === 'Supplier') ? null : $scope.getUserType();
      const supplier = ($scope.getUserType() === 'Supplier');
      $scope.edit(new Customer({
        role: 'customer',
        type: userType,
        supplier: supplier,
      }));
    };
    $scope.carsList = user => {
      return user.cars.filter(x => x.active === true).map((car) => {
        const carMake = (car.carMake && car.carMake.name) || '';
        const carModel = (car.carModel && car.carModel.name) || '';
        return `${carMake} ${carModel} ${car.tag}`;
      });
    };
    $scope.allExpanded = () => $scope.pds.pg.items.every(x => x.expanded);
    $scope.toggleAll = () => {
      const newValue = !$scope.allExpanded();
      $scope.pds.pg.items.forEach((x) => {
        x.expanded = newValue;
      });
    };

    $scope.edit = (contractor) => {
      viewStore.store({ contractor: new Customer(contractor) });
      $uibModal.open({
        templateUrl: 'pages/wash/counterparties/edit/popup.slim',
        controller: 'WashCounterpartiesBaseController',
      }).result.finally(() => {
        $scope.updatePage();
      });
    };

    $scope.showSendSms = () => {
      return $scope.pds.pg.selectedKeys.length > 0 || $scope.pds.pg.selectAll;
    };

    $scope.showSendNotification = () => {
      return $scope.userSession.useBulkNotifications() &&
        ($scope.pds.pg.selectedKeys.length > 0 || $scope.pds.pg.selectAll);
    };

    // Code
    $scope.pds.pg.selectAll = false;
  },
);
