const PRICE_LIST_ID = 'SelectedPriceList';
const DEFAULT_PRICE_LIST_ID = 'DefaultPriceList';
const WAREHOUSE_ID = 'SelectedWarehouse';

angular.module('RocketWash').controller('WashWarehousesProductsBaseController', (
  $scope, $translate, $uibModal, $cookies, Warehouse, Product, PriceList,
) => {
  PriceList.all({warehouse: true}).then((data) => {
    $scope.priceLists = _.sortBy(data, x => x.name);
    if ($cookies.get(PRICE_LIST_ID)) {
      $scope.priceList = $scope.priceLists.find(pl => pl.id === parseInt($cookies.get(PRICE_LIST_ID)));
    } else {
      $scope.priceList = $scope.priceLists.find(pl => pl.default);
    }
    $scope.setPriceList();
  });

  $scope.setPriceList = () => {
    if (!$cookies.get(DEFAULT_PRICE_LIST_ID)) {
      $cookies.put(DEFAULT_PRICE_LIST_ID, $scope.priceList.id);
      $cookies.put(PRICE_LIST_ID, $scope.priceList.id);
    } else {
      $cookies.put(PRICE_LIST_ID, $scope.priceList.id);
    }
    $scope.pds.pg.query.price_list_id = $scope.priceList.id;
    $scope.updatePage();
  };

  $scope.savePLI = function (product, pli) {
    pli.duration = pli.duration || 0;
    if (pli.priceListId !== $scope.priceList.id) {
      pli.priceListId = $scope.priceList.id;
      delete pli.id;
    }
    product.save().then(() => {
      $scope.updatePage();
    });
  };

  $scope.deletePLI = function (pli) {
    pli.delete().then(() => {
      $scope.updatePage();
    });
  };

  $scope.pds = $scope.pageDataService.new({
    page: 'warehouse_products',
    filters: {
      attributes: [
        { name: 'name', type: 'string' },
        { name: 'price', type: 'number' },
        { name: 'article', type: 'string' },
        { name: 'barcode', type: 'string' },
        { name: 'unit', type: 'string' },
      ],
      scopes: [
        'name', 'price', 'article', 'barcode', 'unit',
      ],
    },
    pg: {
      url: '/wash/products',
      model: Product,
      query: {
        include_archived: false,
        skipDateFilter: true,
        predicate: 'name',
        page: 1,
        per: 10,
      },
    },
    definePaginateMethodsOn: $scope,
  });
  $scope.rightMenuConfig = {
    buttons: [
      {
        type: 'export_xls',
        text: $translate.instant('buttons.export_xls'),
        action: () => { $scope.exportXLS(); },
      },
    ],
  };

  $scope.reload = () => {
    $scope.resetAndUpdate()
      .then(() => { $scope.includeArchivedColumn = $scope.pds.pg.query.include_archived; });
  };

  $scope.$watch('[pds.pg.query.serviceFolderId, pds.pg.query.warehouseId]', () => {
    $scope.resetAndUpdate();
  }, true);

  Warehouse.query().then((whs) => {
    const allWarehouses = { name: $translate.instant('warehouse.products.all_warehouses') };
    $scope.warehouses = [allWarehouses, ...whs];
    $scope.pds.pg.query.warehouseId = $cookies.get(WAREHOUSE_ID);
  });

  $scope.setWarehouse = () => {
    $cookies.put(WAREHOUSE_ID, $scope.pds.pg.query.warehouseId);
    $scope.pds.pg.query.warehouseId = $cookies.get(WAREHOUSE_ID);
  };

  $scope.newProduct = () => {
    $scope.openFormPopup({
      measure_unit: 'each',
      measure_amount: 1,
      package_unit: 'packaging',
    });
  };

  $scope.openFormPopup = (product) => {
    $uibModal.open({
      templateUrl: 'pages/wash/warehouse/products/form.slim',
      controller: 'WashWarehouseProductsFormController',
      resolve: {
        product: product,
      },
    }).result.finally(() => {
      setTimeout($scope.resetAndUpdate, 1000);
    });
  };
});
