angular.module('RocketWash').controller('ClientMonitorSettingsController',
  ($scope, $route, $uibModal, userSession, SettingValue) => {

  $scope.clientMonitorLink = `${window.location.origin}` +
    `/#!/client_monitor/${userSession.service_location.client_monitor_uuid}`;

  SettingValue.query().then((settings) => {
    $scope.settings = settings.filter(x => x.keyType === 'ClientMonitorSettingKey');
  });

  $scope.editBackgroundImage = () => {
    $uibModal.open({
      templateUrl: 'pages/wash/settings/client_monitor/bgimage/cm_bgimage_popup.slim',
      controller: 'WashSettingsClientMonitorSettingsCmBgimagePopupController',
    });
  };

  $scope.save = (setting) => {
    setting.save().then(() => {
      userSession.reload();
      $route.reload();
    });
  };
  },
);
