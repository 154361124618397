angular.module('RocketWash').controller('WashDashboardStatisticsBaseController', (
  $scope, viewStore, ApiKey,
) => {
  $scope.selected = {
    view: viewStore.data.statisticsTab || 'consolidated_report',
  };

  ApiKey.query().then((keys) => {
    $scope.apiKeys = keys.filter(key => key.type == 'ApiKeyForElectronicCashier');
  });
});
