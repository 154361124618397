import { env } from '@env';

angular.module('RocketWash').service('reservationFormPhotos', ($injector, $http, $q, rwApi, Upload, userSession, alertService) => {

  const service = {
    latestRecognizedTag: null,
    photos: [],
    recognized_photos: [],
    loaded: false,

    recognize: ({photo}) => {
      const rf = $injector.get('reservationForm');

      let fd = new FormData();
      fd.append('photo', photo);
      fd.append('service_location_id', userSession.service_location_id);

      let options = rwApi.requestOptions({
        method: 'POST',
        path: 'reservation_form/photos/recognize',
        data: fd,
      });

      options.headers['Content-Type'] = undefined;
      options.transformRequest = angular.identity;

      return $http(options).then((response) => {
        const photo = response.data;
        service.reservation_photos.push(photo);

        if (photo.best_result) {
          service.latestRecognizedTag = photo.best_result.number;
        }

        return photo;
      });
    },

    loadAll: () => {
      const rf = $injector.get('reservationForm');

      if (!rf.r().id) {
        service.loaded = true;
        return Promise.resolve();
      }

      if (!service.loaded) {
        const photos = rwApi.sendRequest({
          method: 'GET',
          path: 'reservation_form/photos/',
          params: {
            reservation_id: rf.r().id
          }
        }).then((photos) => {
          service.photos = photos;
        });

        const recognizedPhotos = rwApi.sendRequest({
          method: 'GET',
          path: 'reservation_form/photos/recognized_photos',
          params: {
            reservation_id: rf.r().id
          }
        }).then((photos) => {
          service.recognized_photos = photos;
        });

        return $q.all([photos, recognizedPhotos]).then(() => {
          service.loaded = true;
        });
      };
    },

    prepareForSave: () => {
      const photos = service.photos.map((p) => {
        return {
          id: p.id,
        }
      });

      const recognized_photos = service.recognized_photos.map((p) => {
        return {
          id: p.id,
        }
      });

      if (service.loaded) {
        return {
          photos,
          recognized_photos,
        }
      } else {
        return {};
      }
    },

    reset: () => {
      service.latestRecognizedTag = null;
      service.photos = [];
      service.recognized_photos = [];
      service.loaded = false;
    },

    delete: (photo) => {
      return rwApi.sendRequest({
        path: 'reservation_form/photos',
        method: 'DELETE',
        params: {
          id: photo.id
        },
      }).then((data) => {
        if (!data.errors) {
          service.photos = service.photos.filter((p) => {
            return photo.id != p.id;
          });

          alertService.show({
            text: 'Фотография успешно удалена!',
            timeout: 2000,
          });
        };
      });
    },

    create: (file) => {
      return Upload.upload({
        url: `${env.apiUrl}/${env.apiVersion}/reservation_form/photos`,
        data: {
          image: file
        },
        headers: {
          'X-Rocketwash-Session-Id': userSession.session_id,
          'X-Rocketwash-Organization-Id': userSession.organization.id,
        },
      }).then((response) => {
        const data = response.data;
        if (!data.errors) {
          service.photos.push(data.data);
        }
        return data;
      });
    },

  };

  return service;
});
