angular.module('RocketWash').controller('AdminFinanceFinancialOperationsFormController', (
  $scope, FINANCIAL_OPERATION_TYPE_GROUPS, FINANCIAL_OPERATION_FORM_CONFIG,
  FinancialCenter, FinancialOperation, FinanceClause, PaymentType, FinancialPeriod,
  FinancialProject,
  Organization,
  ServiceLocation,
  resolvedFinancialOperation,
) => {
  FINANCIAL_OPERATION_TYPE_GROUPS = Object.assign({}, FINANCIAL_OPERATION_TYPE_GROUPS, {
    rw_balance: [
      'FinancialOperationIncomeManualToRwBalance',
      'FinancialOperationOutcomeManualFromRwBalance',
      'FinancialOperationCorrectionRwBalance',
      'FinancialOperationExternalPayment',
    ],
  });

  $scope.fo = new FinancialOperation(resolvedFinancialOperation);
  if (!$scope.fo) {
    console.error('resolvedFinancialOperation required');
    $scope.$evalAsync(() => { $scope.$dismiss(); });
    return;
  }

  if ($scope.fo.id) {
    $scope.fo.get().then((fo) => {
      Organization.get($scope.fo.organizationId).then((organization) => {
        const x = organization;
        x.typeahead = `[${x.id}] ${x.name}, ${x.address}, ${x.phone}`;
        $scope.fo.organization = organization;
      });
      Object.keys(FINANCIAL_OPERATION_TYPE_GROUPS).forEach((key, index) => {
        FINANCIAL_OPERATION_TYPE_GROUPS[key].forEach((type) => {
          if (type === fo.type) {
            $scope.selectedTabIndex = index;
          }
        });
      });

      $scope.fo.financialPeriods.forEach((fp) => {
        fp.display = fp.label();
      });
    });
  } else {
    $scope.fo = new FinancialOperation({
      date: new Date(),
      performed: true,
      type: FINANCIAL_OPERATION_TYPE_GROUPS.income[0],
    });
  }

  $scope.switchTenant = () => {
    const tenantId = $scope.fo.organization && $scope.fo.organization.id;
    $scope.fPeriods = [];
    FinancialPeriod.tenantId = tenantId;
    FinancialPeriod.query({ from: -36, count: 48 }, { queryRole: 'wash' }).then((fPeriods) => {
      $scope.fPeriods = fPeriods;

      if (!$scope.fo.id) {
        // Add current financial period
        $scope.fo.financialPeriods = $scope.fPeriods.filter((fp) => {
          return moment().isSame(fp.dateStart, 'month');
        });
      }
      $scope.fPeriods.forEach((fp) => {
        fp.display = fp.label();
      });
    });

    $scope.fProjects = [];
    FinancialProject.tenantId = tenantId;
    FinancialProject.queryAll(null, { queryRole: 'wash' }).then((fProjects) => {
      $scope.fProjects = fProjects;
    });

    $scope.serviceLocations = [];
    ServiceLocation.tenantId = tenantId;
    ServiceLocation.query({
      organization_id: tenantId,
    }).then((serviceLocations) => {
      $scope.serviceLocations = [{ name: 'Не выбрано' }, ...serviceLocations];
    });
  };

  $scope.FINANCIAL_OPERATION_TYPE_GROUPS = FINANCIAL_OPERATION_TYPE_GROUPS;
  $scope.FINANCIAL_OPERATION_FORM_CONFIG = FINANCIAL_OPERATION_FORM_CONFIG;

  FinanceClause.query().then((fcs) => {
    $scope.fcs = _.sortBy(fcs, fc => fc.calculated_index);
  });
  PaymentType.query().then((pts) => {
    $scope.pts = pts;
  });

  $scope.save = () => {
    $scope.formDisabled = true;
    $scope.fo.prepareForSave();
    $scope.fo.save().then((fo) => {
      if (_.isEmpty(fo.errors)) {
        $scope.$dismiss();
      }
      $scope.errors = { financial_operation: fo.errors };
    }).finally(() => {
      $scope.formDisabled = false;
    });
  };
  let selectTabCalled = false;
  $scope.selectTab = (tab) => {
    $scope.operationTypes = FINANCIAL_OPERATION_TYPE_GROUPS[tab];
    if (selectTabCalled && !$scope.operationTypes.includes($scope.fo.type)) { // Tab changed by user. Initial event fires without selected tab.
      $scope.fo.type = $scope.operationTypes[0];
      $scope.resetFo();
    }
    $scope.selectedTab = tab;
    selectTabCalled = true;
  };
  $scope.getFinancialCenters = (options = {}) => {
    const fieldConfig = angular.copy(
      FINANCIAL_OPERATION_FORM_CONFIG[$scope.fo.type].financialCenters[options.field] || {},
    );
    const filter = fieldConfig.filter || {};
    const sl = $scope.fo.serviceLocation;
    Object.assign(filter, {
      organization_id: $scope.fo.organization.id,
      service_location_id: sl && sl.id,
    });
    return FinancialCenter.typeahead(angular.extend(filter, options));
  };
  $scope.fcSelected = (field) => {
    const fc = $scope.fo[field];
    $scope.fo[`${field}Id`] = fc && fc.id;
  };
  $scope.resetFo = () => {
    ['from', 'to', 'fromSecond', 'toSecond'].forEach((fieldPrefix) => {
      $scope.fo[`${fieldPrefix}FinancialCenter`] = null;
      $scope.fo[`${fieldPrefix}FinancialCenterId`] = null;
    });
  };

  $scope.filterFinancialPeriods = (fp, query) => {
    if (!fp.label) {
      return null;
    }
    const label = fp.label().toLowerCase();
    const containsEveryWord = query.toLowerCase().split(' ').every(word => label.search(word) >= 0);
    return containsEveryWord;
  };

  $scope.suggestFinancialPeriods = (query) => {
    return $scope.fPeriods.filter((fp) => {
      if (!fp.label) {
        return null;
      }
      const label = fp.label().toLowerCase();
      const containsEveryWord = query.toLowerCase().split(' ')
        .every(word => label.search(word) >= 0);
      return containsEveryWord;
    });
  };


  $scope.getOrganizations = (query) => {
    return Organization.typeahead({ search_query: query });
  };

  $scope.performedCheckboxDisabled = () => {
    return $scope.fo && $scope.fo.date > moment();
  };

  $scope.$watch('fo.date', () => {
    if (!$scope.fo) {
      return;
    }

    if ($scope.fo.date > moment()) {
      $scope.fo.performed = false;
    } else {
      $scope.fo.performed = true;
    }
  });
});
