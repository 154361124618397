angular.module('RocketWash').directive('rwSearch', $rootScope => ({
  restrict: 'E',
  replace: true,
  template: `
    <div class="header__search">
      <md-input-container class="search-box">
        <label translate="global.search"></label>
        <input name="search" ng-model="searchQuery" ng-model-options="{debounce : 600}" ng-change="onChange()">
        <i class="fa fa-search"></i>
      </md-input-container>
    </div>
  `,
  link: (scope) => {
    scope.onChange = () => {
      $rootScope.$broadcast('search-change', { value: scope.searchQuery });
    };
  },
}));
