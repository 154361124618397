angular.module('RocketWash').factory(
  'UltrasonicSensor',
  ($http, railsResourceFactory, userSession) => {
    const resource = railsResourceFactory({
      url: (context) => { return "/" + (context.queryRole || userSession.controllerScope()) + "/ultrasonic_sensors/" + (context.id || '') },
      name: 'ultrasonic_sensor',
    });

    resource.prototype.getMeasurements = function (params) {
      const path = `${resource.$url(this)}/measurements`;
      return $http.get(
        path,
        params,
        { performRootWrapping: false },
      );
    };

    return resource;
  },
);
