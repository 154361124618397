angular.module('RocketWash').controller(
  'WashIncomeReportResaveReservationsPopupController',
  (
    $scope, $translate, Reservation, alertService, from, to, userSession,
  ) => {
    $scope.from = from;
    $scope.to = to;
    $scope.userSession = userSession;

    $scope.options = {
      recalculate_salary: true,
      recalculate_hourly_salary: true,
      reassign_employees_according_to_work_shifts: false,
      reassign_jobs_according_to_work_shifts: false,
      split_participations_equally: false,
      from: from.toISOString(),
      to: to.toISOString(),
    };

    $scope.resaveReservations = () => {
      Reservation.resaveInTimeRange(
        $scope.options,
      ).then(() => {
        alertService.show({
          text: $translate.instant('income.index.resave_reservations_notification'),
        });

        $scope.$close();
      });
    };
  },
);
