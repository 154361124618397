angular.module('RocketWash').controller('WashSettingsPrintableDocumentsVariablesInfoPopupController', (
  $scope, Device, PrintableDocument, serverConstants, Upload, alertService, $translate, $uibModal,
) => {


  serverConstants.promise.then(() => {
    $scope.substitutions = serverConstants.printable_document_substitutions;

    let types = angular.copy(serverConstants.printable_document_subject_types);
    delete types.none;
    $scope.printableDocumentSubjectTypes = ['general', ...Object.keys(types)];

    $scope.selected = {
      tab: $scope.printableDocumentSubjectTypes[0],
    };

  });

  $scope.withCurlyBraces = (str) => {
    return `{{${str}}}`
  };

  $scope.copyToClipboard = (variable) => {
    const str = `{{${variable.full_name}}}`;
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    alertService.show({
      text: 'Переменная скопирована!'
    });
  };

  $scope.variableType = (variable) => {
    if (variable.full_name.match(/\.table\./)) {
      return 'table';
    } else {
      return 'plain';
    }
  };

});
