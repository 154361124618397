angular.module('RocketWash').config(($stateProvider) => {
  $stateProvider.state('app.wash.organization', {
    url: '/organization',
    templateUrl: 'pages/wash/organization/index.slim',
    controller: 'WashOrganizationBaseController',
    data: {
      permission: 'OrganizationTab',
      tabs: [
        'statistics', 'financial_planning', 'financial_operations', 'external_payments', 'plans',
      ],
    },
  });
});
