angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  $stateProvider.state('app.taxi.autopark.drivers', {
    url: '/drivers',
    tab: 'drivers',
    views: {
      'content@app.taxi.autopark': {
        controller: 'TaxiAutoparkDriversController',
        templateUrl: 'pages/taxi/autopark/drivers/index.slim',
      },
    },
  }).state('app.taxi.autopark.drivers.form', {
    url: '/form',
    views: {
      'content@app.taxi.autopark': {
        controller: 'TaxiAutoparkDriversFormController',
        templateUrl: 'pages/taxi/autopark/drivers/form.slim'
      }
    }
  });
});
