angular.module('RocketWash').service('productSelectService', ($rootScope, $timeout, Product) => {
  const service = {
    items: null,
    selected: null,
    load: () => {
      if (!service.items) {
        return Product.all({}, { cache: false }).then((products) => {
          service.items = products;
          service.select(service.items[0]);
          $rootScope.$broadcast('productSelectService-loaded');
        });
      } else {
        Promise.resolve();
      }
    },
    reload: () => {
      service.reset();
      return service.load();
    },
    reset: () => {
      service.selected = null;
      service.items = null;
    },
    select: (product) => {
      service.selected = service.items.find(x => x.id == product.id);
      $rootScope.$broadcast('productSelectService-selected');
    },
  };

  $timeout(service.load);

  return service;
});
