angular.module('RocketWash').filter('rwSelectWithSearchFilter', (keyboardLayout) => {
  const defaultArray = [{
    disabled: true
  }];

  const qwerty = keyboardLayout.use('qwerty');

  return (collection, field, query, fallback) => {
    const result = (collection || []).filter((item) => {
      let value = item[field];
      value = String(value).toLowerCase();
      query = String(query).toLowerCase();

      return qwerty.allLayoutVariants(query).some((variant) => {
        return value.match(variant);
      });
    });

    if (result.length > 0) {
      return result;
    } else {
      defaultArray[0].label = fallback;
      return defaultArray;
    }
  };
});
