angular.module('RocketWash').factory(
  'RecognizedReservation',
  ($http, railsResourceFactory, userSession) => {
    const resource = railsResourceFactory({
      url: (context) => { return "/" + (context.queryRole || userSession.controllerScope()) + "/recognized_reservations/" + (context.id || '') },
      name: 'recognized_reservation',
    });

    resource.prototype.split = function (date) {
      return resource.$post(
        `${resource.resourceUrl(this)}/split`,
        { date: date},
      );
    };

    return resource;
  },
);
