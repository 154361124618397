angular.module('RocketWash').controller(
  'WashServicesPriceListSettingsController',
  ($q, $scope, BasicService, ExtraService, CarType, PriceList, plSelectService) => {
    $scope.plSelectService = plSelectService;
    $scope.permissions = [{
      value: 'everybody'
    },{
      value: 'specific_car_tags'
    },{
      value: 'nobody'
    }]


    $scope.reloadPriceLists();

    $scope.recalculateServiceCategories = () => {
      $scope.serviceCategories = $scope.getServiceCategories(
        $scope.basicServices
      );
    };

    const plq = $scope.reloadPriceLists();

    $scope.$on('plSelectService-selected', () => {
      $scope.reloadServices();
    });

    $scope.reloadServices = (options={}) => {
      if (plSelectService.selected && $scope.ctgSelectService.selected) {
        BasicService.indexWithPriceListSettings({
          car_type_ids: [],
          price_list_id: plSelectService.selected.id,
          car_types_group_id: $scope.ctgSelectService.selected.id,
        }).then((data) => {
          $scope.basicServices = data.map(o => new BasicService(o));
        }).then(() => {
          $scope.recalculateServiceCategories();
        });
      };
    };
    $scope.reloadServices();

    $scope.savePLSForGroup = function (categoryGroup) {
      const promisses = categoryGroup[1].map((service) => {
        if (service.getPLS().permission != categoryGroup.permission) {
          service.getPLS().permission = categoryGroup.permission;
          return service.save();
        } else {
          return Promise.resolve();
        };
      });

      $q.all(promisses).then(() => {
        $scope.recalculateServiceCategories();
      })
    };

    $scope.savePLS = function (service) {
      service.save({price_list_id: plSelectService.selected.id}).then(() => {
        $scope.recalculateServiceCategories();
      });
    };

    $scope.toggleDefault = (service) => {
      if (service.getPLS().defaultInMobile) {
        $scope.basicServices.forEach((s) => {
          let pls = s.getPLS();
          pls.defaultInMobile = false;
        });
        service.getPLS().defaultInMobile = true;
        $scope.savePLS(service);
      } else {
        service.getPLS().defaultInMobile = true;
      }
    };
  },
);
