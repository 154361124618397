angular.module('RocketWash').config(($stateProvider, $urlRouterProvider) => {
  $urlRouterProvider.when('/partner/settings', '/partner/settings/dashboard');
  return $stateProvider.state('app.partner.settings', {
    url: '/settings',
    abstract: true,
    controller: 'PartnerSettingsBaseController',
    templateUrl: 'pages/partner/settings/index.slim',
    data: {
      permission: 'SettingsMenuItem',
      tabs: [
        'general',
      ],
    },
  });
});
