angular.module('RocketWash').component('rwReservationEmployees', {
  templateUrl: 'components/rw-reservation-employees/index.slim',
  bindings: {
  },
  controller: function ($scope, reservationForm) {
    $scope.rf = reservationForm;

    reservationForm.employees.loadAll();
  },
});
