angular.module('RocketWash').factory('ExtraService', (
  railsResourceFactory, railsSerializer, userSession, PriceListItem, PriceListSetting,
) => {
  const Resource = railsResourceFactory({
    url: context => `/${context.queryRole || userSession.controllerScope()}/extra_services/${context.id || ''}`,
    name: 'extra_service',
    serializer: railsSerializer(function () {
      this.resource('priceListItems', 'PriceListItem');
      this.nestedAttribute('priceListItems');

      this.resource('priceListSettings', 'PriceListSetting');
      this.nestedAttribute('priceListSettings');

      this.resource('consumables', 'Consumable');
      this.nestedAttribute('consumables');
      return this.add('type', 'ExtraService');
    }),
  });

  Resource.prototype.getPLI = function (_carTypeId, copy) {
    if (copy === undefined) {
      copy = true;
    }
    const pli = this.priceListItems[0]; // same for all car types
    if (pli) {
      if (copy) {
        return angular.copy(pli);
      }
      return pli;
    }

    const newPli = new PriceListItem({
      serviceId: this.id,
      priceListId: this.priceListId,
    });
    this.priceListItems = this.priceListItems || [];
    this.priceListItems.push(newPli);
    return newPli;
  };

  Resource.prototype.getPLS = function () {
    if (this.priceListSettings.length == 0) {
      const newPLS = new PriceListSetting({
        serviceId: this.id,
        priceListId: this.priceListId,
        permission: 'everybody',
      });

      this.priceListSettings.push(newPLS);
    }

    return this.priceListSettings[0];
  };


  return Resource;
});
