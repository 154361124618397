angular.module('RocketWash').filter('translateFilterAttribute', function($translate) {
  return function(attrName, page) {
    let common = $translate.instant('filters.attributes.common.' + attrName);
    let pageSpecific = $translate.instant('filters.attributes.' + page + '.' + attrName);
    let string = _.chain([pageSpecific, common]).reject((translation) => {
      return translation.search('filters.attributes.') == 0; // Reject missing translations
    }).first().value();
    return string;
  };
});
