angular.module('RocketWash').controller('WashWarehouseWarehouseOperationsBaseController', (
  $scope, $translate, $uibModal, rwConfirmationService, alertService,
  WarehouseOperation, serverConstants,
) => {
  $scope.warehouseOperationTypes = serverConstants.warehouse_operation_types;
  $scope.selectedTabIndex = 0;
  $scope.selectedType = $scope.warehouseOperationTypes[0];
  $scope.pds = $scope.pageDataService.new({
    page: 'warehouse_operations',
    filters: {
      attributes: [
        { name: 'name', type: 'string' },
      ],
      scopes: [
        'name', 'price',
      ],
    },
    pg: {
      url: '/wash/warehouse_operations',
      model: WarehouseOperation,
      query: {
        fromToField: 'date',
        predicate: '-date',
        page: 1,
        per: 10,
        operation_type: $scope.warehouseOperationTypes[0],
      },
    },
    definePaginateMethodsOn: $scope,
  });
  $scope.rightMenuConfig = {
    buttons: [
      {
        type: 'export_xls',
        text: $translate.instant('buttons.export_xls'),
        action: () => { $scope.exportXLS(); },
      },
    ],
  };

  $scope.$watch('selectedTabIndex', () => {
    $scope.selectedType = $scope.warehouseOperationTypes[$scope.selectedTabIndex];
    $scope.pds.pg.query.operation_type = $scope.selectedType;
    $scope.$broadcast('pds-update');
  });

  $scope.openFormPopup = (warehouseOperation) => {
    if (!warehouseOperation) {
      warehouseOperation = new WarehouseOperation({ type: $scope.selectedType });
    }
    $uibModal.open({
      templateUrl: 'pages/wash/warehouse/warehouse_operations/form.slim',
      controller: 'WashWarehouseWarehouseOperationsFormController',
      resolve: {
        resolvedWarehouseOperation: warehouseOperation,
      },
    }).result.finally(() => {
      setTimeout(() => { $scope.$broadcast('pds-update'); }, 1000);
    });
  };

  $scope.destroy = (warehouseOperation) => {
    rwConfirmationService.confirm(
      { textTranslation: 'alert.are_you_sure' },
      () => {
        warehouseOperation.delete().then(() => {
          alertService.show({
            text: 'Операция успешно удалена',
          });
          $scope.$broadcast('pds-update');
        });
      },
    );
  };
});
