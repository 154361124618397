angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.partner.finances.detailed_organizations', {
    url: '/detailed_organizations',
    tab: 'detailed_organizations',
    views: {
      'content@app.partner.finances': {
        controller: 'PartnerFinancesDetailedOrganizationsBaseController',
        templateUrl: 'pages/partner/finances/detailed_organizations/index.slim'
      }
    }
  });
});
