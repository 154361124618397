angular.module('RocketWash').controller('WashOrganizationPlansController', (
  $q, $scope, LaneCamera, ServiceLocation, Plan, ServiceLocationLane, rwConfirmationService, userSession,
  treeHelpers, alertService, $translate, SettingValue,
) => {
  $scope.serviceLocations = [];
  $scope.plans = [];
  $scope.selected = {
    serviceLocation: null
  };
  $scope.settingsSchema = {};

  $scope.mergedExtraSettings = {};

  $scope.activeUltrasonicSensorsCount = 0;
  $scope.activeCamerasCount = 0;
  $scope.activeLanesCount = 0;
  $scope.treeHelpers = treeHelpers;

  $scope.basicServiceList = [];
  $scope.extraServiceList = [];

  ServiceLocation.query().then((serviceLocations) => {
    $scope.serviceLocations = serviceLocations;
    $scope.selected.serviceLocation = $scope.serviceLocations[0];
  }).then(() => {
    const slLoaded = ServiceLocation.get($scope.userSession.service_location.id).then((sl) => {
      angular.extend($scope.selected.serviceLocation, sl);
      $scope.setCurrPlanId($scope.selected.serviceLocation.plan.id);
    });

    const sllLoaded = ServiceLocationLane.query().then((lanes) => {
      $scope.activeLanesCount = lanes.filter(x => x.active).length;
    });

    const lcLoaded = LaneCamera.query().then((cameras) => {
      $scope.activeCamerasCount = cameras.length;
    });

    const plansLoaded = Plan.query().then((plans) => {
      $scope.plans = _.sortBy(plans, 'ordinal');
      $scope.paidPlans = $scope.plans.filter(plan => plan.settings.dailyLanePrice > 0 || plan.settings.maxMonthlyReservations > 0);
      $scope.plans.forEach((plan) => {
        plan.marketingInfo.key = 'root';
      });
      $scope.marketingTree = treeHelpers.mergeTrees(plans.map(x => x.marketingInfo));
      $scope.marketingTreePaths = treeHelpers.flattenTree($scope.marketingTree);
      treeHelpers.dig($scope.marketingTree, ['root', 'a1']);
    });

    $q.all([slLoaded, sllLoaded, lcLoaded, plansLoaded]).then(() => {
      const defaultPlan = $scope.plans.find(plan => plan.default);
      $scope.serviceLocations.forEach((sl) => {
        sl.planAfterFreeTrialId = sl.planAfterFreeTrialId || defaultPlan.id;
      });

      $scope.updateMergedSettings();
    });
  });

  $scope.settingValue = (planVariable) => {
    let plan = $scope.plans.find(x => x.id == $scope.selected.serviceLocation.planId);
    return plan.settings[planVariable];
  };

  $scope.updateMergedSettings = () => {
    $scope.mergedExtraSettings = {};
    $scope.plans.forEach((plan) => {
      $scope.mergedExtraSettings[plan.id] = Object.assign({}, plan.settings);
    });
    $scope.mergedExtraSettings[$scope.selected.serviceLocation.planId] = Object.assign(
      {},
      $scope.mergedExtraSettings[$scope.selected.serviceLocation.planId],
      $scope.selected.serviceLocation.additionalDailyPaidFeatures
    );
  }

  $scope.changePlan = (serviceLocation, plan) => {
    if ($scope.onTrial()) {
      alertService.show({
        text: $translate.instant('organization.plans.cannot_change_plan_on_trial'),
        type: 'error',
      });
      return;
    }
    if (serviceLocation.isOrgNetwork === true && plan.settings.useMultipleServiceLocations === false && !userSession.superuser ) {
      alertService.show({
        text: $translate.instant('organization.plans.multi_washes_cannot_change_plan_on_easy_or_standard'),
        type: 'error',
      });
      return;
    }
    rwConfirmationService.confirm({
      textTranslation: 'alert.are_you_sure'
    },
    () => {
      serviceLocation.planId = plan.id;
      serviceLocation.changePlan(serviceLocation.planId);
      $scope.setCurrPlanId(serviceLocation.planId);
    },
                                 );
  };

  $scope.planName = (planId) => {
    const plan = $scope.plans.find(x => x.id === planId);
    return plan && plan.name;
  };

  $scope.onTrial = () => {
    const trialEnd = $scope.userSession.organization.free_trial_ends_at;
    const orgOnFreeTrial = trialEnd && moment(trialEnd).diff(moment(), 'seconds') >= 0;
    return orgOnFreeTrial;
  };

  $scope.mergeServiceTree = (tree, list, parent, level = 0) => {
    tree.forEach(element => {
      element.parent = parent;
      element.expanded = true;
      element.level = level;
      list.push(element);
      if (element.nodes.length > 0) {
        $scope.mergeServiceTree(element.nodes, list, element, level + 1);
      }
    });
  };

  $scope.everyParentExpanded = (element) => {
    var parent = element.parent;

    if (!parent) { return true; } // root element

    while (parent) {
      if (parent.expanded == false) {
        return false;
      }
      parent = parent.parent;
    };

    return true;
  };

  SettingValue.query().then((settingValues) => {
    $scope.pmtsv = settingValues.find(x => x.key == 'plans_marketing_tree');
    $scope.pmTreeCollection = JSON.parse($scope.pmtsv.value);

    console.log($scope.pmTreeCollection.extra);


    $scope.basicServiceTree = $scope.pmTreeCollection.basic;
    $scope.mergeServiceTree($scope.basicServiceTree, $scope.basicServiceList, null);

    $scope.extraServiceTree = $scope.pmTreeCollection.extra;
    $scope.mergeServiceTree($scope.extraServiceTree, $scope.extraServiceList, null);

    return true;
  });

  $scope.lockSwitch = (key) => {
    const extraServices = $scope.pmTreeCollection.extra;
    const service = extraServices.find(x => x.plan_variable == key);
    if (!service['lock_switch'] || service['lock_switch'] == 'false') {
      return false
    } else {
      return true;
    }
  }

  const calculateServicePrice = (plan, service) => {
    const price = $scope.floatOrZero(plan.settings[service.plan_variable]);
    const active = $scope.mergedExtraSettings[plan.id] && $scope.mergedExtraSettings[plan.id][service.plan_variable + 'Active'];

    if (active === false) { return 0 };

    switch(service.price_per) {
      case 'per_day':
        return price;
      case 'per_lane_per_day':
        return price * $scope.activeLanesCount;
      case 'per_camera_per_day':
        return price * $scope.activeCamerasCount;
      case 'per_ultrasonic_sensor_per_day':
        return price * $scope.activeUltrasonicSensorsCount;
      default:
        return 0;
    }
  };

  const calculatePrice = (plan, services) => {
    if (!services || services.length == 0) { return 0 };

    return services.map((service) => {
      return calculateServicePrice(plan, service);
    }).reduce((sum, price) => sum + price, 0);
  };

  $scope.overallSum = (planId) => {
    const plan = $scope.plans.find(x => x.id === planId);

    return calculatePrice(plan, $scope.extraServiceList) + calculatePrice(plan, $scope.basicServiceList);
  }

  $scope.floatOrZero = (value) => {
    return parseFloat(value) || 0.0;
  }

  $scope.updateSlExtraSettings = (plan, key) => {
    if ($scope.selected.serviceLocation.planId != plan.id) { return; };

    const key_active = key + 'Active';

    if ($scope.mergedExtraSettings[plan.id][key_active] == plan.settings[key_active]) {
      delete $scope.selected.serviceLocation.additionalDailyPaidFeatures[key_active];
    } else {
      $scope.selected.serviceLocation.additionalDailyPaidFeatures[key_active] = $scope.mergedExtraSettings[plan.id][key_active];
    };

    $scope.selected.serviceLocation.save();

    return;
  }

  $scope.savePlanAfterFreeTrial = () => {
    $scope.selected.serviceLocation.save().then(() => {
      location.reload();
    });
  };

  $scope.additionalDailyPaidFeaturesPresent = () => {
    if (!$scope.selected.serviceLocation) {
      return false;
    }
    return Object.keys($scope.selected.serviceLocation.additionalDailyPaidFeatures).length !== 0;
  };

  $scope.additionalDailyPaidServiceSum = () => {
    // TODO: use extraService list to get price values
    return Object.values($scope.selected.serviceLocation.additionalDailyPaidFeatures).reduce((a, b) => a + b, 0);
  };

  $scope.setCurrPlanId = (planId) => {
    $scope.currentPlanId = planId;
  };

  $scope.planStyles = (plan) => {
    if (plan.id == $scope.currentPlanId) {
      return {
        'border-left-color': plan.settings.color,
        'border-right-color': plan.settings.color,
        'box-shadow': '-5px 0px 10px -3px rgba(0, 0, 255, .2), 5px 0px 10px -3px rgba(0, 0, 255, .2)'
      };
    } else {
      return {};
    }
  };
 });
