angular.module('RocketWash').controller(
  'AdminPartnersSettingsOrganizationsController',
  (
    $scope, $compile, $translate, $stateParams, $uibModal,
    User, Organization
  ) => {

    $scope.pds = $scope.pageDataService.new({
      page: 'organizations',
      pg: {
        url: '/admin/organizations',
        model: Organization,
        query: {
          skipDateFilter: true,
          predicate: 'name',
        },
      },
      definePaginateMethodsOn: $scope,
    });
    $scope.pds.selectedFilters.push({
      attr: 'registered_by_partner_id',
      condition: 'equals',
      value: $stateParams.usrId
    })
    Organization.registeredByPartnerId = $stateParams.usrId;

    User.get($stateParams.usrId).then((usr) => {
      $scope.usr = usr;
    });

    $scope.new = () => {
      var opened_model = $uibModal.open({
        templateUrl: 'pages/admin/partners/settings/organizations/add_organization_popup.slim',
        controller: 'AdminPartnersSettingAddOrganizationController',
        resolve: {
          resolvedPartner: () => ({ id: $stateParams.usrId })
        }
      });
      opened_model.result.finally(() => {
        setTimeout($scope.updatePage, 1000);
      });
    };
  },
);
