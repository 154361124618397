angular.module('RocketWash').controller('AdminSettingsPrintableDocumentsIndexController', (
  $scope, $interval, Device, PrintableDocument, serverConstants, Upload, alertService, $translate, $uibModal,
) => {
  var reload;
  $scope.documents = [];
  $scope.selected = {
    document: null,
    documentId: null,
  };
  $scope.currentDate = function() {
    return moment().format('HH:mm YYYY-MM-DD');
  };
  $scope.progress = { upload: 0 };

  serverConstants.promise.then(() => {
    $scope.printableDocumentSubjectTypes =
      serverConstants.printable_document_subject_types;
  });

  $scope.newDocument = () => {
    const doc = new PrintableDocument({name: 'Новый документ'});
    doc.save().then((d) => {
      reload().then(() => {
        $scope.edit(d);
      });
    });
  };

  Device.indexAll((devices) => {
    $scope.devices = devices;
  });

  (reload = function() {
    return PrintableDocument.query({only_new: true}).then(function(docs) {
      var doc, i, len;
      $scope.documents = docs;
      for (i = 0, len = docs.length; i < len; i++) {
        doc = docs[i];
        doc.deviceId || (doc.deviceId = null);
      }
      if ($scope.documents[0] && !$scope.selected.document) {
        return $scope.edit($scope.documents[0]);
      }
    });
  })();

  let previewUpdateInterval = null;
  $scope.$on('$destroy', () => {
    $interval.cancel(previewUpdateInterval);
  });
  $scope.edit = function(id) {
    return PrintableDocument.get(id).then(function(doc) {
      $scope.selected.document = doc;
      $scope.selected.documentId = doc.id;

      if (previewUpdateInterval) {
        $interval.cancel(previewUpdateInterval);
      }
      previewUpdateInterval = $interval(() => {
        if ($scope.selected.document) {
          // $scope.selected.document.toHtml({scope: PRINTABLE_DOCUMENT_VARIABLES.exampleScope});
        };
      }, 500);
    });
  };
  $scope.$watch('selected.document.deviceId', function() {
    if ($scope.selected.document.deviceId) {
      return $scope.selected.document.quantity = 1;
    }
  });
  $scope.save = function() {
    var base;
    (base = $scope.selected.document).deviceId || (base.deviceId = null);
    return $scope.selected.document.save().then(function() {
      return reload();
    });
  };
});
