angular.module('RocketWash').controller('WashWarehouseWarehousesFormController', (
  $scope, Warehouse, resolvedWarehouse, PriceList,
) => {
  $scope.warehouse = new Warehouse(resolvedWarehouse);

  $scope.save = () => {
    $scope.formDisabled = true;
    $scope.warehouse.save().then((warehouse) => {
      if (_.isEmpty(warehouse.errors)) {
        $scope.$dismiss();
      } else {
        $scope.errors = { create_warehouse: warehouse.errors };
      }
    }).finally(() => { $scope.formDisabled = false; });
  };

  const query = {
    filters: JSON.stringify([
      {
        attr: "price_list_type.sortable",
        condition: "equals",
        value: "pricelistwarehouse",
      }
    ])
  }
  PriceList.query(query).then((data) => {
    $scope.priceLists = _.sortBy(data.items, x => x.name);
  });
});
