angular.module('RocketWash').factory(
  'Organization',
  (
    railsResourceFactory, userSession, $http, $interpolate, DuplicatableMixin,
  ) => {
    const resource = railsResourceFactory({
      url: (context) => { return "/" + (context.queryRole || userSession.controllerScope()) + "/organizations/" + (context.id || '') },
      name: 'organization',
    });

    angular.extend(resource.prototype, DuplicatableMixin);

    resource.prototype.exportCustomers = function (params) {
      const _class = this.__proto__.constructor;
      return $http({
        url: `${_class.$url(this)}/export_customers`,
        method: 'POST',
        data: params,
      });
    };

    resource.prototype.exportCustomersFinancialDataForPeriod = function (params) {
      if (params == null) {
        params = {};
      }
      return $http({
        url: `/${userSession.controllerScope()}/organizations/${this.id}/export_customers_financial_data_for_period`,
        method: 'POST',
        data: params,
      }).then(response => response.data);
    };

    resource.prototype.invite = function () {
      return $http({
        url: '/admin/organizations/invite',
        method: 'GET',
        data: { org_id: this.id },
      });
    };


    resource.createTaxiCtgs = function (params) {
      return this.$post(
        `${this.$url('create_taxi_ctgs')}`,
        params,
      );
    };

    resource.typeahead = function typeahead(params) {
      return this.$get(
        `${this.$url('typeahead')}`,
        params,
      ).then((response) => {
        const organizations = response.items.map(x => new this(x));
        organizations.forEach((x) => {
          x.typeahead = `[${x.id}] ${x.name}, ${x.address}, ${x.phone}`;
        });

        return organizations;
      });
    };

    resource.allWithFilters = function allWithFilters(params) {
      return this.$get(
        `${this.$url('all_with_filters')}`,
        params,
      ).then((response) => {
        const organizations = response.items.map(x => new this(x));
        organizations.forEach((x) => {
          x.typeahead = `[${x.id}] ${x.name}, ${x.address}, ${x.phone}`;
        });

        return organizations;
      });
    }

    return resource;
  },
);
