angular.module('RocketWash').controller(
  'WashSettingsSalaryController',
  (
    $scope, $route, $location, $window,
    userSession, SettingValue, serverConstants, Organization, Deduction, PaymentSubtype, PaymentType,
  ) => {
    $scope.searchQuery = '';
    $scope.predicate = 'name';
    $scope.reverse = true;

    $scope.returnPaymentName = (deduction) => {
      if (deduction.paymentSubtypeId === null) {
        return $scope.paymentTypes.find(type => type.id === deduction.paymentTypeId).name;
      } else {
        return $scope.paymentSubtypes.find(subtype => subtype.id === deduction.paymentSubtypeId).name;
      }
    };

    $scope.returnPaymentCommission = (deduction) => {
      return deduction.bfotSalaryPercentage;
    }

    Deduction.query().then((deductionsInServiceLocation) => {
      $scope.deductionsInSl = deductionsInServiceLocation;
    });

    PaymentSubtype.query().then((paymentSubtypeInServiceLocation) => {
      $scope.paymentSubtypes = paymentSubtypeInServiceLocation;
    });

    PaymentType.query().then((paymentTypesInServiceLocation) => {
      $scope.paymentTypes = paymentTypesInServiceLocation;
    });

    const SALARY_SETTING_KEYS = [
      'use_shifts_accounting',
      'use_complex_salary_calculation_with_employee_participations',
      'use_full_reservation_price_without_discount_for_fot',
      'form_fot_minus_commissions_by_types_of_payments',
      'how_to_use_bonuses_when_calculating_employee_salary',
    ];

    let reloadSettings = () => {
      SettingValue.query().then((settings) => {
        $scope.settings = settings.filter(x => x.keyType === 'SalarySettingKey').sort((a, b) => {
          return SALARY_SETTING_KEYS.indexOf(a.key) - SALARY_SETTING_KEYS.indexOf(b.key);
        });
        const setManually = $scope.settings.find(x => x.key === 'set_employees_manually');
        const salaryThreshold = $scope.settings.find(x => x.key === 'change_salary_calculation_if_reservation_discoiunt_passes_threshold');
        const forcedSalaryThreshold = $scope.settings.find(x => x.key === 'use_discount_for_salary_calculation_if_reservation_discoiunt_passes_threshold');
        const useShifts = $scope.settings.find(x => x.key === 'use_shifts_accounting');
        const usePriceWithoutDiscounts = $scope.settings.find(x => x.key === 'use_full_reservation_price_without_discount_for_fot');
        const formFotMinusCommissionsByTypesOfPayments = $scope.settings.find(x => x.key === 'form_fot_minus_commissions_by_types_of_payments');
        const howToUseBonuses = $scope.settings.find(x => x.key === 'how_to_use_bonuses_when_calculating_employee_salary');
        const useComplexSalarySetting = $scope.settings.find(x => x.key === 'use_complex_salary_calculation_with_employee_participations');

        if (usePriceWithoutDiscounts && usePriceWithoutDiscounts.value == 'yes') {
          salaryThreshold.hide = true;
          forcedSalaryThreshold.hide = true;
        }

        if ($scope.userSession.service_location.plan.settings.form_fot_by_payment_type === false) {
          formFotMinusCommissionsByTypesOfPayments.hide = true;
        }

        $scope.fotByPaymentType = () => {
          return formFotMinusCommissionsByTypesOfPayments.value === 'yes' && $scope.userSession.service_location.plan.settings.form_fot_by_payment_type === true;
        }

        $scope.salarySettings = [
          setManually,
          useShifts,
          howToUseBonuses,
          useComplexSalarySetting,
          usePriceWithoutDiscounts,
          formFotMinusCommissionsByTypesOfPayments,
          salaryThreshold,
          forcedSalaryThreshold,
        ];
      });
    };
    reloadSettings();

    $scope.SETTING_VALUES = serverConstants.setting_values;
    $scope.$on('search-change', (_event, data) => {
      $scope.searchQuery = data.value;
    });
    $scope.save = function (setting) {
      setting.save().then(() => {
        userSession.reload();
        reloadSettings();
      });
    };

  },
);
