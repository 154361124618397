angular.module('RocketWash').factory(
  'ModuleKassa',
  ($http, railsResourceFactory, railsSerializer, userSession) => {
    const Resource = railsResourceFactory({
      url: (context) => { return "/" + (context.queryRole || userSession.controllerScope()) + "/module_kassas/" + (context.id || '') },
      name: 'module_kassa',
    });

    angular.extend(Resource, {
      openCashShift(mkId) {
        return $http({
          method: 'POST',
          url: `/${userSession.controllerScope()}/module_kassas/open_cash_shift`,
          params: {
            mk_id: mkId
          }
        });
      },
      closeCashShift(mkId) {
        return $http({
          method: 'POST',
          url: `/${userSession.controllerScope()}/module_kassas/close_cash_shift`,
          params: {
            mk_id: mkId
          }
        });
      },
      xReport(mkId) {
        return $http({
          method: 'POST',
          url: `/${userSession.controllerScope()}/module_kassas/x_report`,
          params: {
            mk_id: mkId
          }
        });
      },
    });

    return Resource;
  },
);
