angular.module('RocketWash').controller(
  'AdminChartsActiveServiceLocationsDeductionsController',
  ($scope, $rootScope, $http, $location) => {
    $rootScope.loading = 'active_service_locations_deductions';
    $scope.sls = [];

    $http.get('/admin/charts/active_service_locations').then((response) => {
      $scope.data = response.data;
    }).then(() => {
      $http.get('/admin/charts/active_service_locations_deductions').then((response) => {
        $scope.deductionsData = response.data;
        if ($rootScope.loading === 'active_service_locations_deductions') {
          $rootScope.loading = false;
        }
      });
    });
    $scope.dataByPeriodAndId = (period, orgId, slId) => {
      let orgData = $scope.data[period].find(org => org.org_id == orgId);
      if (slId) {
        return orgData && orgData.sls.find(sl => sl.sl_id == slId);
      } else {
        return orgData;
      }
    };

    $scope.domainLink = (orgId) => {
      return `${$location.protocol()}://${orgId}.${$location.host().split('.').splice(1).join('.')}:${$location.port()}/`;
    };
  },
);
