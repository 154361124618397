angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.charts.average_bill', {
    url: '/average_bill',
    tab: 'average_bill',
    data: {
      permission: 'AverageCheckReportTab'
    },
    views: {
      'content@app.wash.charts': {
        controller: 'WashChartsAverageBillController',
        templateUrl: 'pages/wash/charts/average_bill/index.slim'
      }
    }
  });
});
