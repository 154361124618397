angular.module('RocketWash').directive('leftNavItem', (accessMenuService) => {
  return {
    restrict: 'E',
    scope: {
      state: '=',
    },
    transclude: true,
    templateUrl: 'directives/left-nav-item/left-nav-item.slim',
    link(scope, _element, _attrs) {
      scope.accessMenuService = accessMenuService;
    },
  };
});

