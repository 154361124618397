angular.module('RocketWash').constant('FILTER_TYPE_CONDITIONS', {
  string: [
    'equals', 'not_equals', 'includes', 'not_includes',
    'present', 'blank'
  ],
  enum: [
    'equals', 'not_equals', 'includes', 'not_includes',
    'present', 'blank',
  ],
  number: [
    'equals', 'not_equals', 'includes', 'not_includes',
    'greater', 'greater_or_equals', 'less', 'less_or_equals',
    'present', 'blank',
  ],
  datetime: [
    'greater', 'greater_or_equals', 'less', 'less_or_equals',
    'present', 'blank',
    'date_range',
  ],
});
