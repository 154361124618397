angular.module('RocketWash').service('leftMenuStateService', ($window, $rootScope) => {
  const KEY = 'left_menu_collapsed';

  const readState = () => {
    return $window.localStorage.getItem(KEY) === 'true';
  };
  const storeState = (value) => {
    $window.localStorage.setItem(KEY, value);
  };

  const service = {
    collapsed: readState(),
    toggle: () => {
      service.collapsed = !service.collapsed;
      storeState(service.collapsed);
      $rootScope.$broadcast('left-menu-state-changed');
    },
  };
  return service;
});
