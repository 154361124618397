

var extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

angular.module('RocketWash').factory('RolesPermission', function(railsResourceFactory, userSession, $http) {
  var RolesPermission, RolesPermissionFactory;
  RolesPermissionFactory = railsResourceFactory({
    url: (context) => { return "/" + (context.queryRole || userSession.controllerScope()) + "/roles_permissions/" + (context.id || '') },
    name: "roles_permission"
  });
  return RolesPermission = (function(superClass) {
    extend(RolesPermission, superClass);

    function RolesPermission() {
      return RolesPermission.__super__.constructor.apply(this, arguments);
    }

    return RolesPermission;

  })(RolesPermissionFactory);
});
