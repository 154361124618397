

angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.admin.settings.general', {
    url: '/general',
    tab: 'general',
    views: {
      'content@app.admin.settings': {
        controller: 'AdminSettingsGeneralBaseController',
        templateUrl: 'pages/admin/settings/general/index.slim'
      }
    }
  });
});
