angular.module('RocketWash').controller('WashIncomeJobsFormController', (
  $scope, userSession, Job, resolvedJob,
) => {
  $scope.userSession = userSession;

  $scope.job = new Job(resolvedJob);
  if ($scope.job.id) {
    $scope.job.get().then(() => {
      console.log('reloaded');
    });
  }

  $scope.save = () => {
    $scope.formDisabled = true;
    $scope.job.save().then((job) => {
      if (_.isEmpty(job.errors)) {
        $scope.$dismiss();
      } else {
        $scope.errors = { create_job: job.errors };
      }
    }).finally(() => { $scope.formDisabled = false; });
  };
});
