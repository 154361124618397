angular.module('RocketWash').config(($stateProvider) => {
  $stateProvider.state('wash_reset_password', {
    url: '/wash_reset_password',
    controller: 'WashResetPasswordIndexController',
    templateUrl: 'pages/wash/reset_password/index.slim',
    data: {
      publicPage: true,
    },
    resolve: {
      _serverTranslations: () => {
        return window.translationsPromise;
      },
    },
  });
});
