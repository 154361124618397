angular.module('RocketWash').config(($stateProvider, $urlRouterProvider) => {
  return $stateProvider.state('app.wash.manual_client_monitor', {
    url: '/manual_client_monitor',
    abstract: true,
    controller: 'WashManualClientMonitorBaseController',
    templateUrl: 'pages/wash/manual_client_monitor/index.slim',
    data: {
      permission: 'ManualMonitorMenuItem',
      tabs: ['dashboard'],
    },
  })
});
