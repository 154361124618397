import L from 'leaflet';

const LOCAL_STORAGE_STATE_KEY = 'LOCAL_STORAGE_ONLINE_SERVICE_LOCATIONS_STATE_KEY';

angular.module('RocketWash').controller('AdminOnlineReservationsServiceLocationsIndexController', (
  $window, $scope, Reservation, ServiceLocation, userSession,
) => {

  $scope.selected = {sl: {}};
  $scope.slLayer = null;
  const loadSls = () => {
    ServiceLocation.query({online: true}).then((sls) => {
      if ($scope.slLayer) {
        $scope.slLayer.remove();
      };
      $scope.slLayer = L.layerGroup();

      $scope.sls = sls;
      sls.forEach((sl) => {
        const valid = sl.showOnline && sl.location && sl.location.lat && sl.location.lon;

        const hintContent = [
          sl.organizationName,
          sl.name,
          `Адрес: ${sl.address}`,
          `Тел: ${sl.phone}`,
          `План: ${sl.planName}`,
          `Часовой пояс: ${sl.timeZone}`,
        ].join("<br>");


        let color = '#ff7800';
        if (valid) {
          color =  "#787800";
        };
        if (valid && sl.activeAndHasServiceLocationLaneForOnlineOrders) {
          color =  "#78ff00";
        };
        const geojsonMarkerOptions = {
          radius: 8,
          fillColor: color,
          color: "#000",
          weight: 1,
          opacity: 1,
          fillOpacity: 0.4,
          label: 'asdf',
          slId: sl.id,
          orgId: sl.organizationId,
        };

        const newLayer = L.circleMarker(sl.location, geojsonMarkerOptions);

        newLayer.on('click', (e) => {
          newLayer.bindPopup(hintContent);
        });

        $scope.slLayer.addLayer(newLayer);

        // const placemark = new ymaps.Placemark([sl.location.lat, sl.location.lon], {
        //   iconContent: `${sl.organizationId}-${sl.id}`,
        //   hintContent: hintContent,
        //   hasBalloon: false,
        // }, {
        //   preset: preset,
        // });

        // placemark.events.add('click', function () {
        //   sl.generalOnlineInfo({organization_id: sl.organizationId}).then((res) => {
        //     $scope.selected.sl = res;
        //   });
        // });
        //
        // $scope.map.geoObjects.add(placemark);
      });


      $scope.slLayer.addTo($scope.map);
    });
  };

  $scope.mapCenter = [27.607492926269526, 53.750732672433465];

  $scope.search = {
    source: {
      geometry: {
        type: "Circle",
        coordinates: [27.607492926269526, 53.750732672433465],
        radius: 50000,
      },
    },
    options: {
      draggable: false,
      fillColor: "#009DEB",
      fillOpacity: 0.2,
      strokeColor: "#009DEB",
      strokeOpacity: 0.8,
      strokeWidth: 5,
    },
  };

  loadSls();

  $scope. map = L.map('service-locations-map').setView([53.750732672433465, 27.607492926269526], 8);

  L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  }).addTo($scope.map);
});
