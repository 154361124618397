angular.module('RocketWash').service('dummySettingsSchemaService', (SettingValue) => {
  const result = {
    TYPES: ['string', 'boolean', 'integer', 'float'],
    settingValue: null,
    promise: null,
    schema: null,
    add() {
      this.schema.push({
        key: Math.random().toString(),
        name: 'name',
        type: 'string',
      });
    },
    remove(setting) {
      const index = this.schema.indexOf(setting);
      if (index < 0) {
        return;
      }
      this.schema.splice(index, 1);
    },
    save() {
      this.settingValue.value = JSON.stringify(this.schema);
      return this.settingValue.save();
    },
  };

  // const exampleSchema = [
  //   {
  //     key: 'ChemistryAccounting',
  //     name: 'Учёт химии',
  //     type: 'string',
  //   },
  // ];

  result.promise = SettingValue.query().then((settingValues) => {
    result.settingValue = settingValues.find(x => x.key === 'DummySettingsSchema');
    try {
      result.schema = JSON.parse(result.settingValue.value);
    } catch (e) {
      console.error(e);
      console.log('dbg');
    }
    result.schema = result.schema || [];
    console.log('dbg2');
  });

  return result;
});
