angular.module('RocketWash').controller('WashWarehouseProductsFormController', (
  $scope, Product, product, ServiceFolder, serverConstants,
) => {
  $scope.serverConstants = serverConstants;
  if (product && product.id) {
    Product.get(product.id).then((data) => {
      $scope.product = new Product(data);
    });
    $scope.product
  } else {
    $scope.product = new Product(product);
  }
  $scope.search = { term: '' };

  ServiceFolder.indexAsTree().then((tree) => {
    $scope.serviceFoldersTree = tree;
  });

  $scope.save = () => {
    $scope.formDisabled = true;
    $scope.product.save().then((product) => {
      if (_.isEmpty(product.errors)) {
        $scope.$dismiss();
      } else {
        $scope.errors = { create_product: product.errors };
      }
    }).finally(() => { $scope.formDisabled = false; });
  };
});
