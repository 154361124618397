angular.module('RocketWash').filter('formatDateRange', ($translate, $filter) => {
  const SEPARATOR = '|';
  const formatWithoutPrefix = (range, anchor) => {
    anchor = moment(anchor);
    switch (range) {
    case 'day': return $filter('formatTime')(anchor, 'date');
    case 'week': return [
      $filter('formatTime')(moment(anchor).day(1), 'date'),
      $filter('formatTime')(moment(anchor).day(7), 'date'),
    ].join(' - ');
    case 'month': return $filter('formatTime')(anchor, 'month');
    default: return `invalid range: ${range}`;
    }
  };

  return (string) => {
    const [range, anchor] = (string || '').split(SEPARATOR);
    return `${$translate.instant(`date.range.${range}`)}, ${formatWithoutPrefix(range, anchor)}`;
  };
});

