angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.dashboard.statistics', {
    url: '/statistics',
    tab: 'statistics',
    data: {
      permission: 'CompletedReservationsStatisticsTab'
    },
    views: {
      'content@app.wash.dashboard': {
        controller: 'WashDashboardStatisticsBaseController',
        templateUrl: 'pages/wash/dashboard/statistics/index.slim'
      }
    }
  });
});
