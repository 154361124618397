angular.module('RocketWash').controller('WashDashboardOperativePanelDetailsModalController', ($scope, reservationId, alertService, reservationForm) => {

  reservationForm.reservations.load({id: reservationId});

  $scope.rf = reservationForm
  $scope.ladda = false;

  $scope.editReservation = () => {
    $scope.$close({editReservation: true});
  };

  $scope.$on('$destroy', () => {
    reservationForm.resetAll();
  });
});
