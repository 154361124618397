// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../shared/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../shared/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../fonts/icon/MaterialIcons-Regular.eot");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../fonts/icon/MaterialIcons-Regular.woff2");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../fonts/icon/MaterialIcons-Regular.woff");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../fonts/icon/MaterialIcons-Regular.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
exports.push([module.id, "@font-face {\n  font-family: 'Material Icons';\n  font-style: normal;\n  font-weight: 400;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "); /* For IE6-8 */\n  src: local('../../fonts/icon/Material Icons'),\n       local('../../fonts/icon/MaterialIcons-Regular'),\n       url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff2'),\n       url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('woff'),\n       url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format('truetype');\n}\n\n", ""]);
// Exports
module.exports = exports;
