angular.module('RocketWash').factory(
  'PrintedDocument',
  (railsResourceFactory, railsSerializer, userSession) => {
    const resource = railsResourceFactory({
      url: (context) => {
        return "/" + (context.queryRole || userSession.controllerScope()) + "/printed_documents/" + (context.id || '')
      },
      name: 'printed_document',
      serializer: railsSerializer(function () {
        this.resource('user', 'User');
        this.resource('organization', 'Organization');
        this.resource('serviceLocation', 'ServiceLocation');
      }),
    });

    return resource;
  },
);
