angular.module('RocketWash').service('reservationFormPayments', ($injector, rwApi, userSession, SettingValue) => {
  const paymentsWithAllowedZeroAmount = ['ya_fuel']
  const paymentsWithFotCommission = ['ya_fuel', 'fuel_card']

  const setSettingValue = SettingValue.query().then((settings) => {
    let formFotMinusCommissionsByTypesOfPayments = settings.find(x => x.key === 'form_fot_minus_commissions_by_types_of_payments');
    if (formFotMinusCommissionsByTypesOfPayments) {
      return formFotMinusCommissionsByTypesOfPayments.value === 'yes';
    } else {
      return false;
    }
  });

  const service = {
    all: [],
    grouped: [],
    paymentGroups: [],
    total: {},

    add: () => {
      service.all.push({
        editable: true,
        deletable: true,
        amount: service.total.remaining_amount,
      });
      service.regroupPayments();
    },

    isAllowZeroPayment: (payment) => {
      const rf = $injector.get('reservationForm');
      const currentPaymentDestination = rf.config.constants.payment_destinations.find(pd => pd.id == payment.payment_destination_id)
      if (!currentPaymentDestination) {
        return false;
      }

      const currentPaymentType = currentPaymentDestination.payment_type
      if (currentPaymentType){
        return !!paymentsWithAllowedZeroAmount.includes(currentPaymentType);
      }
      else{
        return true;
      }
    },

    canAddPayment: () => {
      const rf = $injector.get('reservationForm');
      if (rf.payments.all.length >= 1 && setSettingValue.$$state.value){
        const PaymentsWithFotCommission = rf.config.constants.payment_destinations.filter(pd => paymentsWithFotCommission.includes(pd.payment_type));

        const PaymentsWithFotCommissionIds = PaymentsWithFotCommission.map(function(p) {
          return p.id;
        });

        const currentPaymentsWithFotComCount = rf.payments.all.filter(payment => PaymentsWithFotCommissionIds.includes(payment.payment_destination_id)).length
        return currentPaymentsWithFotComCount === 0;
      }
      else{
        return true;
      }
    },

    canRemovePayment: (payment) => {
      const rf = $injector.get('reservationForm');
      const isOrgDirector = userSession.roles.map(role => role.name).includes('organization_director');
      const reservation = rf.reservations.reservation
      const isReservationNotSaved = !reservation.id
      const paymentAmountZero = payment.amount === 0
      return isReservationNotSaved ? true : ((!rf.payments.isAllowZeroPayment(payment) && payment.editable) || paymentAmountZero || (userSession.superuser || isOrgDirector));
    },

    change: (payment) =>{
      const rf = $injector.get('reservationForm');
      if (rf.payments.isAllowZeroPayment(payment)){
        service.all.find(p => payment === p ).amount = 0
      }
    },

    remove: (payment) => {
      service.all = _.without(service.all, payment);
      service.regroupPayments();
    },

    isValid: () => {
      const rf = $injector.get('reservationForm');
      const PaymentDistanitionToSkip = rf.config.constants.payment_destinations.filter(pd => paymentsWithAllowedZeroAmount.includes(pd.payment_type))
      const arrIdsPDtoSkip = PaymentDistanitionToSkip.map(pd => pd.id);
      const paymentsForValidate = service.all.filter((p) => !arrIdsPDtoSkip.includes(p.payment_destination_id));
      // Check that every payment has a valid amount and payment_destination_id
      const validAmounts = paymentsForValidate.every((payment) => payment.amount > 0);
      // Check that every payment has a payment_destination_id
      const hasDestinationIds = paymentsForValidate.every((payment) => payment.payment_destination_id !== null);
      // Return true if all payments have a valid amount and payment_destination_id
      return validAmounts && hasDestinationIds;
    },

    sendConfirmationCode: (payment) => {
      const rf = $injector.get('reservationForm');
      const taxiDriver = rf.config.constants.payment_destinations.find(pd => pd.id == payment.payment_destination_id).payment_type == "taxi_driver_account";

      let sendConfirmation = () => {
        return rwApi.sendRequest({
          method: 'POST',
          path: 'reservation_form/payments/send_confirmation_code',
          params: {
            user_id: rf.carContractor.car.contractor.id,
            amount: payment.amount,
            taxi_driver: taxiDriver,
            car_tag: rf.carContractor.car.tag,
          }
        }).then((data) => {
          payment.sent_payment_confirmation_id = data.payment_confirmation_id;
          return data;
        });
      }

      if (!rf.carContractor.car.contractor.id) {
        return rf.reservations.save({closeAfterSave: false}).then(() => {
          return sendConfirmation();
        })
      } else {
        return sendConfirmation();
      };
    },

    confirm: (payment, code) => {
      return rwApi.sendRequest({
        method: 'POST',
        path: 'reservation_form/payments/confirm',
        params: {
          payment_confirmation_id: payment.sent_payment_confirmation_id,
          amount: payment.amount,
          code: code,
        }
      }).then((data) => {
        if (data.confirmed) {
          payment.payment_confirmation_id = data.payment_confirmation_id;
          payment.confirmed = data.confirmed;
          payment.editable = false;
        }
        return data;
      });
    },

    calculate: _.debounce(() => {
      const rf = $injector.get('reservationForm');

      service.all.forEach((rp) => {
        if (rp.payment_group) {
          rp.payment_group_id = rp.payment_group.id;
        }
      });

      return rwApi.sendRequest({
        method: 'POST',
        path: 'reservation_form/payments/calculate',
        data: {
          service_location_id: userSession.service_location_id,
          car_id: rf.carContractor.car.id,
          car_model_id: rf.carContractor.car.car_model_id,
          payments: service.all,
          services: rf.services.selected,
          time_start: rf.r().time_start,
          channel: rf.r().channel,
        }
      }).then((data) => {
        service.total = data;
      });
    }, 100),

    prepareForSave: () => {
      return service.all;
    },

    assignState: (payments=[]) => {
      service.all = payments;
      service.regroupPayments();
    },

    reset: () => {
      service.all = [];
      service.grouped = [];
      service.paymentGroups = [];
      service.total = {};
    },

    regroupPayments: () => {
      service.paymentGroups = [];
      service.grouped = _.groupBy(service.all, (s) => {
        if (s.payment_group) {
          service.paymentGroups.push(s.payment_group);
        };
        return _.get(s, 'payment_group.id');
      });

      const nextOrdinal = (_.chain(service.paymentGroups).map(g => g.ordinal).max().value() || 0) + 1;

      service.paymentGroups = _.chain(service.paymentGroups).uniqBy((group) => {
        return group && group.id;
      }).compact().orderBy((group) => {
        return -group.ordinal;
      }).value();

      service.paymentGroups.unshift({
        ordinal: nextOrdinal,
        editable: true,
      });
    }
  };

  return service;
});
