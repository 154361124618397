angular.module('RocketWash').directive('rwWebcamCaptureOverlay', (webcamService) => {
  return {
    restrict: 'E',
    templateUrl: 'directives/rw-webcam-capture-overlay/rw-webcam-capture-overlay.slim',
    link(scope, _element, _attrs) {
      scope.ws = webcamService;

      scope.closeOverlay = () => {
        webcamService.showOverlay = false;
        webcamService.stop();
      };
    },
  };
});
