angular.module('RocketWash').controller('WashDashboardStatisticsConsolidatedReportController', (
    $rootScope, $scope, $http, rwApi, dateRangeStorage, userSession
) => {
    const loadData = (params) => {
        return rwApi.sendRequest({
            method: 'GET',
            path: 'reservation_form/statistics/consolidated_report',
            params
        }).then(data => {
            $scope.data = data
            if (!_.isEmpty(data.warehouse_operations_by_type)) {
                $scope.warehouse_operations_by_type_loaded = true
                $scope.data.warehouse_operations_by_type.total = _.sum(Object.values(data.warehouse_operations_by_type))
            } else {
                $scope.warehouse_operations_by_type_loaded = false
            }
        })
    }

    loadData({
        from: dateRangeStorage.from(),
        to: dateRangeStorage.to(),
        service_location_id: userSession.service_location_id
    })

    $rootScope.$on('date-range-updated', () => {
        loadData({
            from: dateRangeStorage.from(),
            to: dateRangeStorage.to(),
            service_location_id: userSession.service_location_id
        })
    })
})
