angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.finance.financial_planning', {
    url: '/financial_planning',
    tab: 'financial_planning',
    data: {
      permission: 'FinancialPlanningTab'
    },
    resolve: {
      resolvedFinanceClauses: (FinanceClause) => {
        return FinanceClause.query({}, { queryRole: 'wash' });
      },
    },
    views: {
      'content@app.wash.finance': {
        controller: 'WashFinanceFinancialPlanningBaseController',
        templateUrl: 'pages/wash/finance/financial_planning/index.slim'
      }
    }
  });
});
