angular.module('RocketWash').config(($stateProvider) => {
  return $stateProvider.state('app.admin.settings.resource_kinds', {
    url: '/resource_kinds',
    tab: 'resource_kinds',
    views: {
      'content@app.admin.settings': {
        controller: 'AdminSettingsResourceKindsBaseController',
        templateUrl: 'pages/admin/settings/resource_kinds/index.slim',
      },
    },
  });
});
