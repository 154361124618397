angular.module('RocketWash').controller(
  'WashResetPasswordIndexController',
  ($scope, $http, $timeout, $translate, $state, $stateParams, $cookies, userSession, alertService, Plan) => {
    $scope.progress = {
      reset_password: false
    };

    $scope.resetPassword = (phone) => {
      $scope.progress.reset_password = true;

      $http.post('/reset_passwords/reset_password', {phone}).then((response) => {
        $scope.progress.reset_password = false;
        if (response.data.sent) {
          alertService.show({
            text: $translate.instant('reset_password.send_code.success')
          });
          $state.go('^.sign_in', {phone});
        } else {
          alertService.show({
            text: $translate.instant('reset_password.send_code.fail'),
            type: 'error'
          });
        }

      }, () => {
        $scope.progress.reset_password = false;
        alertService.show({
          text: $translate.instant('errors.other'),
          type: 'error',
          timeout: 10000
        });
      });
    };
  },
);
