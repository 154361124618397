angular.module('RocketWash').controller('AdminSettingsFinanceClausesBaseController', function($rootScope, $scope, $state, $translate, viewStore, FinanceClause, serverConstants) {
  var setAllOrdinals, sortAllItems, updateFinanceClauses;
  $scope.financialOperationTypes = serverConstants.financial_operation_types;
  $scope.selectedFinancialOperationTypes = function(node) {
    return node.defaultFinancialOperationTypes.map(function(type) {
      return `${type}<br>`;
    }).join('');
  };
  $scope.treeOptions = {
    dropped: function(event) {
      if(event.source.index != event.dest.index) {
        var element;
        setAllOrdinals();
        element = event.source.nodeScope.$modelValue;
        return $scope.save(element);
      }
    }
  };
  (updateFinanceClauses = function() {
    return FinanceClause.query().then(function(data) {
      $scope.list = _.sortBy(data, function(item) {
        return item.ordinal;
      });
      $scope.list = $scope.list.map(function(item) {
        return new FinanceClause(item);
      });
      sortAllItems();
      return setAllOrdinals();
    });
  })();
  sortAllItems = function(list) {
    var i, item, j, len, results;
    if (list == null) {
      list = $scope.list;
    }
    results = [];
    for (i = j = 0, len = list.length; j < len; i = ++j) {
      item = list[i];
      item.items = _.sortBy(item.items, function(item) {
        return item.ordinal;
      });
      if (item.items) {
        results.push(sortAllItems(item.items));
      } else {
        results.push(void 0);
      }
    }
    return results;
  };
  setAllOrdinals = function(list, prefix, parent) {
    var i, item, j, len, results;
    if (list == null) {
      list = $scope.list;
    }
    if (prefix == null) {
      prefix = '';
    }
    if (parent == null) {
      parent = null;
    }
    results = [];
    for (i = j = 0, len = list.length; j < len; i = ++j) {
      item = list[i];
      if (item.ordinal !== i) {
        item.ordinal = i;
        $scope.save(item);
      }
      item.fullOrdinal = "" + prefix + (i + 1) + ".";
      item.parent_id = parent != null ? parent.id : void 0;
      if (item.items) {
        results.push(setAllOrdinals(item.items, item.fullOrdinal, item));
      } else {
        results.push(void 0);
      }
    }
    return results;
  };
  $scope.removeTreeNode = function(scope, node) {
    var fc;
    fc = new FinanceClause(node);
    fc["delete"]();
    return scope.remove();
  };
  $scope.save = function(node) {
    var fc;
    fc = new FinanceClause(node);
    return fc.save().then(function(item) {
      return node.id = item.id;
    });
  };
  $scope.toggleSign = function(node) {
    node.sign = -node.sign;
    $scope.save(node);
  };
  $scope.nestLevel = function(node) {
    return node.fullOrdinal.split('.').length - 2;
  };
  $scope.nodeClasses = function(node) {
    let nestingLevel = `level-${$scope.nestLevel(node)}`;
    let sign = node.sign > 0 ? 'positive' : 'negative';
    return [nestingLevel, sign].join(' ');
  };
  return $scope.newSubItem = function(scope) {
    var fc, nodeData;
    nodeData = scope != null ? scope.$modelValue : void 0;
    fc = new FinanceClause({
      name: $translate.instant('models.finance_clause.name'),
      parent_id: nodeData != null ? nodeData.id : void 0
    });
    return fc.save().then(function(fc) {
      if (nodeData) {
        nodeData.items || (nodeData.items = []);
        nodeData.items.push(fc);
      } else {
        $scope.list.push(fc);
      }
      return setAllOrdinals();
    });
  };
});
