angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.settings.api_keys', {
    url: '/api_keys',
    tab: 'api_keys',
    data: {
      permission: 'ApiKeysTab'
    },
    views: {
      'content@app.wash.settings': {
        controller: 'WashSettingsApiKeysBaseController',
        templateUrl: 'pages/wash/settings/api_keys/index.slim'
      }
    }
  });
});
