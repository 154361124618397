angular.module('RocketWash').config(($stateProvider, $urlRouterProvider) => {
  $urlRouterProvider.when('/client_cabinet/dashboard', '/client_cabinet/dashboard/control_panel');
  return $stateProvider.state('app.client_cabinet.dashboard', {
    url: '/dashboard',
    abstract: true,
    controller: 'ClientCabinetDashboardBaseController',
    templateUrl: 'pages/client_cabinet/dashboard/index.slim',
    data: {
      permission: 'DashboardMenuItem',
      publicPage: true,
      tabs: ['general_info'],
    },
  });
});
