angular.module('RocketWash').service('reservationFormEmployees', ($injector, rwApi, userSession) => {

  const service = {
    all: null,

    loadAll: () => {
      if (service.all) {
        return Promise.resolve();
      } else {
        const rf = $injector.get('reservationForm');

        return rwApi.sendRequest({
          method: 'GET',
          path: 'reservation_form/employees/all',
          params: {
            service_location_id: userSession.service_location_id,
            from: rf.reservations.reservation.time_start,
            to: rf.reservations.timeEnd(),
          },
        }).then((employees) => {
          service.all = employees;
        });
      }
    },

    assignState: (selectedEmployees) => {
      service.loadAll().then(() => {
        (service.all || []).forEach((e) => {
          e.selected = !!selectedEmployees.find((se) => se.id == e.id);
        });
      });
    },

    prepareForSave: () => {
      return (service.all || []).filter((e) => e.selected).map((e) => {
        return {
          id: e.id,
        }
      });
    },

    editable: () => {
      const rf = $injector.get('reservationForm');
      return rf.reservations.canEdit() && rf.reservations.reservation.set_employees_manually;
    },

    makeEditable: () => {
      const rf = $injector.get('reservationForm');
      rf.reservations.reservation.set_employees_manually = true;
    },

    toggleEmployee: (employee) => {
      const rf = $injector.get('reservationForm');
      if (rf.reservations.canEdit()) {
        service.makeEditable()
        employee.selected = !employee.selected;

        const rf = $injector.get('reservationForm');
        rf.employeeParticipations.calculate();
      }
    },

    toggleAll: () => {
      const rf = $injector.get('reservationForm');
      if (rf.reservations.canEdit()) {
        service.makeEditable()
        const value = !service.allSelected();

        (service.all || []).forEach((e) => e.selected = value);

        const rf = $injector.get('reservationForm');
        rf.employeeParticipations.calculate();
      }
    },

    getAllSelected: () => {
      return (service.all || []).filter((e) => e.selected);
    },

    allSelected: () => {
      return (service.all || []).every((e) => e.selected);
    },

    reset: () => {
      service.all = null;
    }
  };

  return service;
});
