angular.module('RocketWash').factory(
  'FilterGroup',
  ($http, railsSerializer, railsResourceFactory, userSession) => {
    const resource = railsResourceFactory({
      url: (context) => { return "/" + (context.queryRole || userSession.controllerScope()) + "/filter_groups/" + (context.id || '') },
      name: 'filter_group',
      serializer: railsSerializer(function() {
        return this.nestedAttribute('filters');
      })
    });

    return resource;
  },
);
