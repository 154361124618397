angular.module('RocketWash').service('dateRangeStorage', ($rootScope, dateRanges) => {
  let $storedFrom = dateRanges.day()[0].toDate();
  let $storedTo = dateRanges.day()[1].toDate();
  return {
    from() { return moment($storedFrom); },
    to() { return moment($storedTo); },
    range() { return { from: this.from(), to: this.to()}; },
    update({ from = null, to = null }) {
      let shouldEmit = false;
      if (from && !moment($storedFrom).isSame(from)) {
        shouldEmit = true;
        $storedFrom = moment(from).toDate();
        console.log('updating from');
      }
      if (to && !moment($storedTo).isSame(to)) {
        shouldEmit = true;
        $storedTo = moment(to).toDate();
        console.log('updating to');
      }
      if (shouldEmit) {
        $rootScope.$broadcast('date-range-updated');
        console.log('emited');
        console.log({
          from: $storedFrom,
          to: $storedTo,
        });
      }
    },
  };
});
