

angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  $urlRouterProvider.when('/admin/organizations', '/admin/organizations/dashboard');
  $urlRouterProvider.when('/admin/organizations/{orgId}/settings', '/admin/organizations/{orgId}/settings/general');
  return $stateProvider.state('app.admin.organizations', {
    url: '/organizations',
    abstract: true,
    controller: 'AdminOrganizationsIndexController',
    templateUrl: 'pages/admin/organizations/index.slim'
  }).state('app.admin.organizations.dashboard', {
    url: '/dashboard',
    views: {
      'content@app.admin.organizations': {
        controller: 'AdminOrganizationsDashboardIndexController',
        templateUrl: 'pages/admin/organizations/dashboard/index.slim'
      }
    }
  }).state('app.admin.organizations.organization', {
    url: '/:orgId',
    abstract: true
  });
});
