angular.module('RocketWash').service('plSelectService', ($rootScope, $injector, PriceList) => {
  const service = {
    items: null,
    selected: null,
    load: () => {
      const ctgSelectService = $injector.get('ctgSelectService');
      if (!service.items && ctgSelectService.selected) {
        return PriceList.all({car_types_group_id: ctgSelectService.selected.id}, { cache: false }).then((priceLists) => {
          service.items = priceLists;
          const item = service.items.find(x => x.default) || service.items[0];
          service.select(item);
        });
      } else {
        Promise.resolve();
      }
    },
    reload: () => {
      service.reset();
      return service.load();
    },
    reset: () => {
      service.selected = null;
      service.items = null;
    },
    select: (pl) => {
      service.selected = service.items.find(x => x.id == pl.id);
      $rootScope.$broadcast('plSelectService-selected');
    },
  };

  return service;
});
