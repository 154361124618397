angular.module('RocketWash').controller('WashCounterpartiesMergePopupController', (
  $scope, $translate, $filter, id1, id2, Customer, alertService,
) => {
  Customer.get(id1).then((res) => {
    $scope.user1 = res;
    $scope.result = angular.copy(res);
  });
  Customer.get(id2).then((result) => {
    $scope.user2 = result;
  });

  $scope.attributes = [
    'name',
    'full_name',
    'phone',
    'email',
    'address',
    'date_of_birth',
    'sex',
    'bonuses_percentage',
    'disable_bonuses',
    'discount',
    'discount_card_number',
    'disable_sms',
    'supplier',
    'bic',
    'tin',
    'kpp',
    'account_number',
  ];

  $scope.fcs = [
    'financial_center_user_bonuses_balance',
    'financial_center_user_money_balance',
  ];

  $scope.setResult = (user, attr) => {
    const cAttr = $filter('toCamelCase')(attr);
    $scope.result[cAttr] = user[cAttr];
  };

  $scope.isSelected = (user, attr) => {
    const cAttr = $filter('toCamelCase')(attr);
    return $scope.result[cAttr] === user[cAttr];
  };

  $scope.merge = () => {
    const result = {
      from_ids: [id1, id2],
    };

    Object.keys($scope.result).forEach((key) => {
      result[$filter('toSnakeCase')(key)] = $scope.result[key];
    });

    $scope.mergeDisabled = true;
    Customer.mergeTwoCustomers({ customer: result }).then(() => {
      $scope.mergeDisabled = false;
      alertService.show({
        text: 'Объединение пользователей в процессе и будет завершено через несколько минут!',
      });
      $scope.$dismiss();
    }).finally(() => {
      $scope.mergeDisabled = false;
    });
  };

  $scope.filterByAttr = (value, attr) => {
    if (['phone'].includes(attr)) {
      return $filter('formatPhone')(value);
    } else if (['sex'].includes(attr)) {
      return $translate.instant(`common.sex.${value}`);
    } else if (['disable_bonuses', 'disable_sms', 'supplier'].includes(attr)) {
      return $translate.instant(`common.yesno.${value}`);
    }
    return value;
  };
});
