angular.module('RocketWash').controller('AdminSettingsReviewScalesController', (
  $scope, ReservationReviewRatingScale,
) => {
  $scope.new = () => {
    $scope.scales.unshift(new ReservationReviewRatingScale({ name: 'Параметр' }));
  };
  $scope.save = (scale) => {
    return scale.save();
  };
  $scope.delete = (scale) => {
    scale.delete();
    $scope.scales.splice($scope.scales.indexOf(scale), 1);
  };

  ReservationReviewRatingScale.query().then((scales) => {
    $scope.scales = scales;
  });
});
