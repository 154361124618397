angular.module('RocketWash').controller('TaxiAutoparkCarsController', (
  $scope, $state, $translate, $uibModal, $timeout, viewStore, YandexTaxiCar, Role
) => {

  $scope.pds = $scope.pageDataService.new({
    page: 'cars',
    filters: {
      attributes: [
        { name: 'car_make_name', type: 'string' },
        { name: 'car_model_name', type: 'string' },
        { name: 'tag', type: 'string' },
        { name: 'car_type_name', type: 'string' },
        { name: 'vin', type: 'string' },
        { name: 'year', type: 'string' }
      ],
      scopes: ['car_make_name', 'car_model_name', 'tag', 'car_type_name', 'vin', 'year'],
    },
    pg: {
      url: '/taxi/cars',
      model: YandexTaxiCar,
      query: {
        skipDateFilter: true,
        predicate: 'name',
        reverse: false
      },
    },
    definePaginateMethodsOn: $scope,
  });

  $scope.delete = (car) => {
    if (confirm($translate.instant('alert.are_you_sure'))) {
      car.delete().then(() => {
        $scope.pds.pg.items = _.without($scope.pds.pg.items, car);
      });
    }
  };

  $scope.openFormPopup = (car) => {
    $uibModal.open({
      templateUrl: 'pages/taxi/autopark/cars/form.slim',
      controller: 'TaxiAutoparkCarsFormController',
      resolve: {
        resolvedCar: () => car
      },
    }).result.finally(() => {
      $timeout($scope.resetAndUpdate, 1000);
    });
  };
});
