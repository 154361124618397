angular.module('RocketWash').controller('WashSettingsPrintableDocumentsIndexController', (
  $uibModal, $interval, $scope, Device, PrintableDocument, serverConstants, Upload, alertService, $translate, Reservation, FinancialOperation, $q,
) => {
  var reload;
  $scope.selected = {
    document: null,
    documentId: null,
  };
  $scope.documents = [];
  $scope.selected.document = false;
  $scope.currentDate = function () {
    return moment().format('HH:mm YYYY-MM-DD');
  };
  $scope.progress = { upload: 0 };

  serverConstants.promise.then(() => {
    $scope.printableDocumentSubjectTypes =
      serverConstants.printable_document_subject_types;
  });

  $scope.newDocument = () => {
    $uibModal.open({
      templateUrl: 'pages/wash/settings/printable_documents/new_document_popup/index.slim',
      controller: 'WashSettingsPrintableDocumentsNewDocumentPopupController',
    }).result.then((doc) => {
      setTimeout(() => {
        reload().then(() => {
          const document = $scope.documents.find((d) => d.id == doc.id);
          $scope.edit(document.id);
        });
      }, 100);
    });
  };

  $scope.devices = [{
    deviceIdentifier: 'printer',
    name: 'Принтер',
    id: null
  }];


  Device.query().then((devices) => {
    devices.items.forEach(device => {
      $scope.devices.push({
        deviceIdentifier: _.snakeCase(device.type.slice(0, -6)),
        name: device.name,
        id: device.id,
        type: device.type
      })
    });
  });

  (reload = function () {
    return PrintableDocument.query({ only_new: true }).then(function (docs) {
      var doc, i, len;
      $scope.documents = docs;
      for (i = 0, len = docs.length; i < len; i++) {
        doc = docs[i];
        doc.deviceId || (doc.deviceId = null);
      }
      if ($scope.documents[0] && !$scope.selected.document) {
        return $scope.edit($scope.documents[0].id);
      }
    });
  })();


  let previewUpdateInterval = null;
  $scope.$on('$destroy', () => {
    $interval.cancel(previewUpdateInterval);
  });
  $scope.edit = function (id) {
    $scope.selected.document = null;
    return PrintableDocument.get(id).then(function (doc) {
      $scope.selected.document = doc;
      $scope.selected.documentId = doc.id;

      if (previewUpdateInterval) {
        $interval.cancel(previewUpdateInterval);
      }
      previewUpdateInterval = $interval(() => {
        if ($scope.selected.document) {
          // $scope.selected.document.toHtml({scope: PRINTABLE_DOCUMENT_VARIABLES.exampleScope});
        };
      }, 500);
    });
  };
  $scope.$watch('selected.document.deviceId', function (id) {
    if ($scope.selected.document.deviceId) {
      $scope.selected.document.quantity = 1;
    }

    if (!$scope.selected.document) return

    const device = $scope.devices.find(device => device.id == id)
    if (device.type === "KkmDevice") {
      $scope.selected.document.deviceIdentifier = `${device.deviceIdentifier} ${device.name.split("№")[1]}`
    } else {
      $scope.selected.document.deviceIdentifier = device.deviceIdentifier
    }
  });
  $scope.save = function () {
    var base;
    (base = $scope.selected.document).deviceId || (base.deviceId = null);
    return $scope.selected.document.save().then(function () {
      alertService.show({
        text: 'Документ успешно сохранен!',
      });
      return reload();
    });
  };

  $scope.deleteDocument = () => {
    $scope.selected.document['delete']().then(() => {
      reload().then(() => {
        const id = $scope.documents[0] && $scope.documents[0].id;
        if (id) {
          $scope.edit(id);
        } else {
          $scope.selected.document = null;
          $scope.selected.documentId = null;
        };
      });
    });
  };

  $scope.showVariables = () => {
    $uibModal.open({
      templateUrl: 'pages/wash/settings/printable_documents/variables_info_popup.slim',
      controller: 'WashSettingsPrintableDocumentsVariablesInfoPopupController',
    });
  };
});
