

angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.settings.actions', {
    url: '/actions',
    tab: 'actions',
    data: {
      permission: 'ActionsTab'
    },
    views: {
      'content@app.wash.settings': {
        controller: 'WashSettingsActionsController',
        templateUrl: 'pages/wash/settings/actions/index.slim'
      }
    }
  });
});
