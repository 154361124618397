angular.module('RocketWash').config(($stateProvider) => {
  return $stateProvider.state('app.wash.services.consumables', {
    url: '/consumables',
    tab: 'consumables',
    data: {
      permission: 'ConsumablesTab',
    },
    views: {
      'content@app.wash.services': {
        controller: 'WashServicesConsumablesController',
        templateUrl: 'pages/wash/services/consumables/index.slim',
      },
    },
  });
});
