angular.module('RocketWash').constant('FINANCIAL_OPERATION_TYPE_GROUPS', {
  'income': [
    'FinancialOperationIncomeCardFromReservation',
    'FinancialOperationIncomeCashFromReservation',
    'FinancialOperationIncomeClientBonuses',
    'FinancialOperationIncomeEmployeeSalary',
    'FinancialOperationIncomeManualReplenishment',
    'FinancialOperationIncomePrepaymentCard',
    'FinancialOperationIncomePrepaymentCash',
    'FinancialOperationIncomePrepaymentWire',
  ],
  'outcome': [
    'FinancialOperationOutcomeManualExpenditure',
    'FinancialOperationOutcomePaySalary',
    'FinancialOperationOutcomeSpendBonuses',
    'FinancialOperationOutcomeSpendInternalClientMoney',
    'FinancialOperationOutcomeSupplierPayment',
    'FinancialOperationOutcomeFine',
  ],
  'transfer': [
    'FinancialOperationInternalTransfer',
  ],
  'correction': [
    'FinancialOperationCorrection',
  ],
});
