angular.module('RocketWash').controller('WashDashboardOperativePanelController', (
  $scope, $uibModal, $timeout, $interval, $filter, $controller, rwApi,
) => {
  $controller('WashDashboardControlPanelController', { $scope: $scope });

  const reloadSllStatuses = () => {
    rwApi.sendRequest({
      method: 'GET',
      path: 'service_location_lanes/current_statuses',
      params: {
        service_location_id: $scope.userSession.service_location_id
      }
    }).then(data => {
      $scope.statuses = data;
    })
  };
  reloadSllStatuses();

  const interval = $interval(() => {
    reloadSllStatuses();
  }, 30000);

  $scope.$on('$destroy', () => {
    $interval.cancel(interval);
  });

  $scope.durationToHtml = (time) => {
    const durationInMinutes = Math.abs(moment(time).diff(moment()) / 60000);
    return $filter('humanizeDurationToDivs')(durationInMinutes);
  };

  $scope.preReservationTiming = (preReservation) => {
    const timeStart = $filter('formatTime')(preReservation.time_start, 'time')
    const timeEnd = $filter('formatTime')(preReservation.time_end, 'time')
    return `${timeStart}-${timeEnd}`
  }

  $scope.isWaitingForClient = (reservation) => {
    return !!reservation.status.match(/waiting_for_client/);
  };

  $scope.isDelayed = (reservation) => {
    return moment().diff(moment(reservation.timeEnd || reservation.time_end)) > 0;
  };

  $scope.showNextPreReservation = (sll) => {
    const events = Object.entries(sll).filter(entry => entry[0] == 'pre_reservation' || entry[0] == 'next_pre_reservation')
    const firstEvent = _.sortBy(events, event => event[1].timeStart)[0]
    return firstEvent && firstEvent[0] === 'next_pre_reservation'
  }

  $scope.openModal = (reservation) => {
    $uibModal.open({
      templateUrl: 'pages/wash/dashboard/operative_panel/details_modal.slim',
      controller: 'WashDashboardOperativePanelDetailsModalController',
      resolve: {
        reservationId: reservation.id,
      },
    }).result.then((result) => {
      if (result.editReservation) {
        $timeout(() => {
          $scope.edit(reservation).finally(() => {
            reloadSllStatuses();
          });
        });
      };
    }).finally(() => {
      setTimeout(reloadSllStatuses(), 100);
    });
  };

  $scope.createNewReservationOnFreeBox = (sll, autostartCamera = false) => {
    $timeout(() => {
      let date = moment();
      // date = date.add(date.utcOffset(), 'minutes');
      console.log(date.format());
      $scope.createNewReservation({ date: date, resourceId: sll.id, autostartCamera: autostartCamera }).finally(() => {
        reloadSllStatuses();
      });
    });
  };

  $scope.getCurrentDate = () => moment();

});
