angular.module('RocketWash').config(($stateProvider, $urlRouterProvider) => {
  $urlRouterProvider.when('/wash/services', '/wash/services/summary');
  $stateProvider.state('app.wash.services', {
    url: '/services',
    abstract: true,
    controller: 'WashServicesBaseController',
    templateUrl: 'pages/wash/services/index.slim',
    data: {
      permission: 'ServicesMenuItem',
      tabs: ['summary', 'summary_for_taxi', 'basic', 'price_lists', 'price_list_settings', 'consumables'],
    },
  });
});
