angular.module('RocketWash').factory('Customer', function($http, railsResourceFactory, railsSerializer, userSession) {
  let resource = railsResourceFactory({
    url: (context) => { return "/" + (context.queryRole || userSession.controllerScope()) + "/customers/" + (context.id || '') },
    name: "customer",
    serializer: railsSerializer(function() {
      return this.nestedAttribute('cars');
    })
  });

  resource.typeahead = (searchQuery, params = {}) => {
    return $http({
      method: 'GET',
      url: `${resource.resourceUrl()}/typeahead`,
      params: angular.merge({ search_query: searchQuery }, params),
    }).then((response) => {
      const users = response.data.map(user => new resource(user));
      return users;
    });
  };

  resource.mergeTwoCustomers = (params = {}) => {
    return $http({
      method: 'POST',
      url: `${resource.resourceUrl()}/merge_two_customers`,
      data: params,
    });
  };

  return resource;
});
