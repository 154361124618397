/* eslint semi: 0 */

require('leaflet/dist/leaflet.css')
// require('leaflet/dist/leaflet.js')

require('leaflet.markercluster/dist/MarkerCluster.css')
require('leaflet.markercluster/dist/MarkerCluster.Default.css')
// require('leaflet.markercluster/dist/leaflet.markercluster.js')

// require('leaflet-control-geocoder/dist/Control.Geocoder.css')
// require('leaflet-control-geocoder/dist/Control.Geocoder.js')

require('jquery')
require("jquery-ui/ui/widgets/draggable");
require('angular')

require('angular-material')
require('angular-material/angular-material.css')

require('angular-material-datetimepicker/js/angular-material-datetimepicker.js')
require('angular-material-datetimepicker/css/material-datetimepicker.css')

require('angular-material-data-table')
require('angular-material-data-table/dist/md-data-table.css')

require('angular-xeditable')
require('angular-xeditable/dist/css/xeditable.css')

require('angular-ui-tree')
require('angular-ui-tree/dist/angular-ui-tree.css')

require('angular-websocket')
require('angular-actioncable')

require('angular-sanitize')
require('angular-ui-bootstrap')
require('angular-translate')
require('angular-route')
require('angular-ui-mask')
require('angular-ui-validate')
require('angular-ui-router')
require('angular-cookies')
require('angularjs-rails-resource')
require('bootstrap-ui-datetime-picker/datetime-picker')

require('angular-ripple/angular-ripple')

require('ui-select')
require('ui-select/dist/select.css')
require('ui-select/src/bootstrap/select.tpl.html')
require('!ng-cache-loader?name=bootstrap/select.tpl.html!slim-lang-loader!./pages/common/ui_select/bootstrap/select.slim')

require('fullcalendar/dist/fullcalendar')
require('fullcalendar/dist/fullcalendar.css')
// require('fullcalendar/dist/fullcalendar.print.css')

require('fullcalendar-scheduler/dist/scheduler')
require('fullcalendar-scheduler/dist/scheduler.css')

// require('bootstrap')
// require('bootstrap/dist/css/bootstrap.css')

require('pikaday')
require('pikaday/css/pikaday.css')

// require('@fortawesome/fontawesome-free/js/all.js')
require('@fortawesome/fontawesome-free/css/all.css')
// require('@fortawesome/fontawesome-free/scss/fontawesome.scss')

require('datetimepicker')
require('datetimepicker/dist/DateTimePicker.css')

require('ngQuickDate/dist/ng-quick-date')
require('ngQuickDate/dist/ng-quick-date.css')
require('ngQuickDate/dist/ng-quick-date-default-theme.css')

require('ladda/dist/ladda-themeless.min.css')
require('ladda/dist/spin.min.js')
require('ladda/dist/ladda.min.js')
require('angular-ladda/dist/angular-ladda.min.js')

require('angular-wizard/dist/angular-wizard.min.js')
require('angular-wizard/dist/angular-wizard.min.css')

require('ng-file-upload/dist/ng-file-upload.min.js')

require('ng-tags-input/build/ng-tags-input.js')
// require('ng-tags-input/build/ng-tags-input.css')

require('rubles')

require('codemirror/lib/codemirror')
require('codemirror/mode/xml/xml')
require('codemirror/lib/codemirror.css')
require('codemirror/theme/monokai.css')

require('summernote/dist/summernote-lite')
require('summernote/dist/summernote-lite.css')

require('inputmask/dist/jquery.inputmask.js')
require('inputmask-multi')


require('highcharts/highcharts-more.src')
require('highcharts/modules/sankey.src')(Highcharts);
require('highcharts/modules/exporting.src')(Highcharts);

require('rw-star-rating/dist/ng-rateit');
require('rw-star-rating/dist/ng-rateit.css');
