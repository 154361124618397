angular.module('RocketWash').controller('WashSettingsApiKeysBaseController', function($rootScope, $scope, $state, $translate, viewStore, ApiKey) {
  $scope.predicate = 'name';
  $scope.reverse = false;

  $scope.keyTypes = ['ApiKeyForCamera', 'ApiKeyForUltrasonicSensor', 'ApiKeyForElectronicCashier', 'ApiKeyForReview', 'ApiKeyOthers'];
  $scope.keyType = $scope.keyTypes[0];

  let updateApiKeys;
  (updateApiKeys = function() {
    ApiKey.query().then(function(data) {
      $scope.apiKeys = _.map(data, function(o) {
        return new ApiKey(o);
      });
    });
  })();

  $scope.create = function(keyType) {
    let apiKey = new ApiKey();
    apiKey.type = keyType;
    apiKey.save().then(function() {
      updateApiKeys();
    });
  };

  $scope.save = function(key) {
    key.save().then(function() {
      updateApiKeys();
    });
  };

  $scope.deleteApiKey = function(apiKey) {
    if (confirm($translate.instant('alert.are_you_sure'))) {
      return apiKey["delete"]().then(function() {
        return updateApiKeys();
      });
    }
  };
});
