angular.module('RocketWash').factory(
  'Reservation',
  (railsResourceFactory, railsSerializer, userSession, TenantSwitchInterceptor, ReservationLog) => {
    const Resource = railsResourceFactory({
      url: (context) => { return "/" + (context.queryRole || userSession.controllerScope()) + "/reservations/" + (context.id || '') },
      name: 'reservation',
      serializer: railsSerializer(function () {
        this.nestedAttribute('attachedServices');
        this.nestedAttribute('attachedProducts');
        this.nestedAttribute('car');
        this.nestedAttribute('employees');
        this.nestedAttribute('employeeParticipations');
        this.nestedAttribute('reservationPayments');
        this.nestedAttribute('recognizedCarPlateImages');
        this.resource('car', 'Car');
        this.resource('employees', 'Employee');
        this.resource('employeeParticipations', 'EmployeeParticipation');
      }),
    });

    Resource.addInterceptor(TenantSwitchInterceptor);

    angular.extend(Resource, {
      resaveInTimeRange(options) {
        return this.$post(
          `${this.resourceUrl()}/resave_in_time_range`,
          options,
          { rootWrapping: false },
        );
      },
    });

    angular.extend(Resource.prototype, {
      getLanesWorkloadData() {
        return Resource.$get(`${Resource.$url(this)}/lanes_workload_data`);
      },
      fiscalCheckPrinted() {
        return Resource.$post(`${Resource.$url(this)}/fiscal_check_printed`);
      },
      statusCategory() {
        const reservation = this;
        if (!reservation.id) {
          return 'not_saved';
        }
        if (reservation.status === 'cancelled') {
          return 'cancelled';
        }
        if (reservation.status === 'cancelled') {
          return 'cancelled';
        }
        let status = '';
        status += (reservation.status === 'success') ? 'completed' : 'not_completed';
        status += '_';
        status += reservation.fullyPaid ? 'paid' : 'not_paid';
        return status;
      },
      mobile() {
        return this.channel !== 'offline' && this.channel !== 'ya_fuel_online';
      },
      isYandex() {
        return this.channel === 'ya_fuel_online';
      },
      log({type, json={}, text=''}) {
        if (this.id) {
          const rl = new ReservationLog({
            reservation_id: this.id,
            json: JSON.stringify(json),
            type,
            text,
          });

          return rl.save();
        } else {
          console.log('Can not create ReservationLog when reservation is not saved');
        }
      },
    });

    angular.extend(Resource, {
      NEW: 'new',
      SUCCESS: 'success',
      FAIL: 'fail',
      CANCELLED: 'cancelled',
      CANCELLED_BY_CLIENT: 'cancelled_by_client',
    });
    Resource.STATUSES = [
      Resource.NEW, Resource.SUCCESS, Resource.FAIL, Resource.CANCELLLED,
      Resource.CANCELLED_BY_CLIENT,
    ];
    Resource.FAIL_STATUSES = [Resource.FAIL, Resource.CANCELLED, Resource.CANCELLED_BY_CLIENT];

    return Resource;
  },
);
