

var extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

angular.module('RocketWash').factory('Bank', function(railsResourceFactory, userSession, $http) {
  var Bank, BankFactory;
  BankFactory = railsResourceFactory({
    url: "/banks/{{id}}",
    name: "bank"
  });
  return Bank = (function(superClass) {
    extend(Bank, superClass);

    function Bank() {
      return Bank.__super__.constructor.apply(this, arguments);
    }

    Bank.query = function(queryParams, context) {
      return $http({
        url: this.resourceUrl(context),
        params: queryParams,
        method: 'GET',
        cache: true
      }).then(function(response) {
        return response.data;
      });
    };

    return Bank;

  })(BankFactory);
});
