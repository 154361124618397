angular.module('RocketWash').controller(
  'WashServicesCommonFormController',
  ($scope, viewStore, serviceCategories, $filter, ServiceLocation, userSession,) => {
    $scope.userSession = userSession;

    // Guard
    $scope.service = angular.copy(viewStore.data.service);
    if (!$scope.service) {
      $scope.$dismiss();
    }

    $scope.loadServiceLocation= () => {
      ServiceLocation.get($scope.userSession.service_location.id).then((sl) => {
        $scope.sl = sl;
      });
    };

    $scope.categoryEdit = !$scope.service.category;

    // Autocomplete

    $scope.autocomplete = {
      searchText: '',
      serviceCategory: null,
      selectedOption: null,
    };

    $scope.preSavedCategory = '';

    $scope.searchServiceCategories = (searchText) => {
      $scope.preSavedCategory = searchText;
      // const serviceCategories = basicServiceCategories || extraServiceCategories;
      return $filter('filter')(serviceCategories, searchText);
    };

    $scope.serviceCategorySelected = (category) => {
      if (!category) {
        return;
      }
      $scope.autocomplete.selectedOption = category;
      $scope.autocomplete.searchText = '';
      $scope.autocomplete.product = null;
    };

    $scope.changeCategory = () => {
      $scope.categoryEdit = true;
    }

    $scope.cancelChangeCategory = () => {
      $scope.categoryEdit = false;
    }

    $scope.saveCategory = () => {
      $scope.service.category = $scope.autocomplete.selectedOption || $scope.preSavedCategory;
      $scope.categoryEdit = false;
    }

    // Actions
    $scope.save = () => {
      $scope.saveDisabled = true;
      $scope.service.save().then((response) => {
        if (_.isEmpty(response.errors)) {
          $scope.$dismiss();
        } else {
          $scope.errors = { create_service: response.errors };
        }
      }).finally(() => {
        $scope.saveDisabled = false;
      });
    };
    $scope.loadServiceLocation();
  },
);
