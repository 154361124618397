angular.module('RocketWash').controller('WashFinanceCashShiftsBaseController', (
  $scope, $translate, $filter, userSession,
  CashShift, Device, $mdDialog,
) => {
  $scope.useCashMachine = userSession.settings.use_cash_machine === 'yes';
  $scope.pds = $scope.pageDataService.new({
    page: 'cash_shifts',
    filters: {
      attributes: [
        { name: 'started_at', type: 'datetime' },
        { name: 'ended_at', type: 'datetime' },
        { name: 'starter_name', type: 'string' },
        { name: 'ender_name', type: 'string' },
      ],
      scopes: ['starter_name', 'ender_name'],
    },
    pg: {
      url: '/wash/cash_shifts',
      model: CashShift,
      query: {
        fromToField: 'started_at',
        predicate: '-started_at',
        reverse: true,
      },
    },
    definePaginateMethodsOn: $scope,
  });
  $scope.rightMenuConfig = {
    buttons: [
      {
        type: 'create',
        text: $translate.instant('shifts.cash_shifts.add'),
        action: () => { $scope.newCashShift(); },
        ngIf: () => { return $scope.noCurrentCashShift; },
      },
      {
        type: 'cash_machine_x_report',
        text: $translate.instant('shifts.cash_shifts.printXReport'),
        action: () => { $scope.xReport(); },
        ngIf: () => { return !$scope.noCurrentCashShift && $scope.useCashMachine; },
      },
      {
        type: 'cash_machine_manual_in_out',
        text: $translate.instant('shifts.cash_shifts.cashInOut'),
        action: () => { $scope.cashInOut(); },
        ngIf: () => { return !$scope.noCurrentCashShift && $scope.useCashMachine; },
      },
    ],
  };

  const reloadCurrentShift = () => {
    CashShift.current().then((shift) => {
      $scope.noCurrentCashShift = !shift;
    });
  };
  const createAndSaveCashShift = () => {
    const cs = new CashShift({ startedAt: moment().format() });
    cs.save().then((savedCs) => {
      reloadCurrentShift();
      $scope.pds.pg.items.unshift(savedCs);
    });
  };
  const closeCashShiftAndSave = (cashShift) => {
    cashShift.endedAt = moment().format();
    cashShift.enderId = userSession.id;
    return $scope.save(cashShift);
  };

  $scope.newCashShift = () => {
    if (
      $scope.useCashMachine &&
      !confirm($filter('translate')('shifts.cash_shifts.openShiftWithCashRegister'))
    ) {
      return;
    }
    createAndSaveCashShift();
  };

  $scope.save = (cashShift, countDuration) => {
    if (countDuration == null) {
      countDuration = true;
    }
    if (cashShift.endedAt && countDuration) {
      cashShift.duration = Math.ceil((moment(cashShift.endedAt) - moment(cashShift.startedAt)) / 1000 / 60);
    }
    return cashShift.save().then(() => {
      return reloadCurrentShift();
    });
  };
  $scope.closeCashShift = (cashShift) => {
    if ($scope.useCashMachine) {
      if (confirm($filter('translate')('shifts.cash_shifts.closeShiftWithCashRegister'))) {
        closeCashShiftAndSave(cashShift);
        // FIXME
        // return Device.get(cashShift.cashRegisterDeviceId).then((device) => {
        //   device = new Device(device);
        //   return device.zReport();
        // });
      }
    } else {
      return closeCashShiftAndSave(cashShift);
    }
  };
  $scope.xReport = () => {
    if (confirm($filter('translate')('shifts.cash_shifts.xReportCashRegister'))) {
      Device.xReport();
    }
  };

  $scope.cashInOut = () => {
    return $mdDialog.show({
      parent: angular.element(document.body),
      templateUrl: 'pages/wash/shifts/cash_shifts/cash_in_out_modal.slim',
      clickOutsideToClose: true,
      controller: ($scope, $mdDialog) => {
        $scope.closeDialog = (params) => {
          $mdDialog.hide(params);
        };
      },
    }).then((result) => {
      result.amount = parseFloat(result.amount) * 100;
      if (result.direction === 'in') {
        if (confirm($filter('translate')('shifts.cash_shifts.cashInOutAreYouSureIn', {
          amount: result.amount / 100.0,
        }))) {
          // FIXME
          // Device.indexAll().then((devices) => {
          //   let device;
          //   device = devices[0];
          //   device = new Device(device);
          //   device.cashIn(result.amount);
          // });
        }
      } else if (confirm($filter('translate')('shifts.cash_shifts.cashInOutAreYouSureOut', {
        amount: result.amount / 100.0,
      }))) {
        // FIXME
        // Device.indexAll().then((devices) => {
        //   let device;
        //   device = devices[0];
        //   device = new Device(device);
        //   device.cashOut(result.amount);
        // });
      }
    });
  };

  reloadCurrentShift();
});
