angular.module('RocketWash').component('rwReservationPayments', {
  templateUrl: 'components/rw-reservation/steps/payments/index.slim',
  bindings: {
  },
  controller: function (
    $scope, userSession, total, PaymentConfirmation, reservationForm,
  ) {
    $scope.rf = reservationForm;
    $scope.userSession = userSession;
    $scope.isYaFuelAvailable = userSession.service_location.yandex_fuel_available

    reservationForm.payments.regroupPayments();

    $scope.$watch('rf.payments.all', () => {
      $scope.rf.payments.calculate();
    }, true);

    $scope.groupSum = (groupId) => {
      return _.sumBy(($scope.rf.payments.grouped[groupId] || []), 'amount');
    };

    $scope.paymentName = (payment) => {
      if (payment.payment_group.data.api_key) {
        let payment_type = payment.payment_group.data.payment_type;
        return $scope.getPaymentMethodDescription(payment_type);
      } else if (payment.payment_group.data.is_ya_fuel_online === true) {
        return 'Яндекс Заправки Онлайн';
      } else {
        return $scope.rf.config.constants.payment_destinations.find(item => item.id === payment.payment_destination_id).label;
      }
    };

    $scope.getPaymentMethodDescription = function(paymentType) {
      switch (paymentType) {
        case 'card':
          return 'Банковская карта - Эл. Кассир';
        case 'wire':
          return 'Перечисление - Эл. Кассир';
        case 'cash':
          return 'Наличные - Эл. Кассир';
        default:
          return 'Не удалось определить тип оплаты, обратитесь в тех.поддержку';
      }
    };

  },
});
