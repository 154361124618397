const STEPS_COUNT = 3;

angular.module('RocketWash').controller(
  'WashSetupWizardBaseController',
  ($scope, $state, $sce, userSession, SettingValue, ServiceLocation, ServiceLocationLane,
   Organization, TimeZone, alertService, Schedule, WizardHandler,
  ) => {

    // VK pixel
    (window.Image ? (new Image()) : document.createElement('img')).src = 'https://vk.com/rtrg?p=VK-RTRG-214059-cqbCO';

    if (!userSession.isSignedIn()) { $state.go('sign_in'); }

    $scope.currentWizzardStep = () => {
      return WizardHandler.wizard().currentStepNumber();
    };

    $scope.steps = Array(STEPS_COUNT).fill().map((_, i) => i);
    $scope.show = { demoVideo: false };

    const loadOrganization = () => {
      Organization.get(userSession.organization.id).then(org => $scope.organization = new Organization(org));
    };
    loadOrganization();

    ServiceLocation.current().then(sl => $scope.serviceLocation = new ServiceLocation(sl));
    ServiceLocationLane.query().then(slls => $scope.serviceLocationLanes = slls);
    TimeZone.query().then(timeZones => $scope.timeZones = timeZones);

    SettingValue.query().then((svs) => {
      $scope.settingValues = svs;

      let csv = svs && svs.find(sv => sv.key == 'service_location_currency');
      $scope.currencySV = new SettingValue(csv);

      let demoVideoSv = svs && svs.find(sv => sv.key == 'demo_video_in_setup_wizzard');
      $scope.demoVideoUrl = $sce.trustAsResourceUrl(demoVideoSv.value);
    });


    $scope.save = () => {
      $scope.organization.save().then((resp) => {
        const subdomain = resp.subdomainName;
        const wl = window.location;
        // Redirect to new subdomain
        if (subdomain != wl.hostname.split('.')[0]) {
          const newHost = [subdomain, ...wl.host.split('.').slice(1)].join('.');
          const newHref = [wl.protocol, '//', newHost, '/', wl.hash].join('');
          window.location.href = newHref;
        }
      }, (resp) => {
        const data = resp.data;
        if (data.errors) {
          data.errors.forEach((error) => {
            alertService.show({
              text: error,
              type: 'error'
            });
          });
        };
        loadOrganization();
      });

      $scope.serviceLocation.save();
      $scope.currencySV.save();
    };

    $scope.goToApplication = () => {
      $state.go('app.wash.dashboard.control_panel');
    };

    $scope.finishedWizard = () => {
      $scope.organization.finishedSetupWizard = true;
      $scope.organization.save().then(() => {
        if (window.ym) {
          ym(25870445, 'reachGoal', 'org_registered');
        }
        userSession.reload();
        userSession.promise.then(() => {
          $scope.show.demoVideo = true;
        });
      });
    };
});
