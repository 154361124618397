angular.module('RocketWash').directive('rwFilters', (serverConstants, rwFiltersService) => {
  return {
    restrict: 'E',
    scope: {
      config: '=',
      onChange: '&',
    },
    templateUrl: 'directives/rw-filters/rw-filters.slim',
    link(scope, _element, _attrs) {
      scope.autocomplete = {};
      scope.menu = { isOpen: false, isHistoryOpen: false };
      scope.rwfs = rwFiltersService.new(scope.config.page);
      scope.conditions = serverConstants.filter_conditions;
      scope.timeNow = moment();
    },
  };
});
