angular.module('RocketWash').controller('WashSettingsDevicesController', (
  $scope, Device,
) => {
  $scope.pds = $scope.pageDataService.new({
    page: 'devices',
    pg: {
      url: '/wash/devices',
      model: Device,
      query: {},
    },
    definePaginateMethodsOn: $scope,
  });
  $scope.getStatus = (device) => {
    return device.getStatus().then(() => {
      return alert('OK!');
    });
  };
  $scope.delete = (device) => {
    device.delete().then(() => {
      $scope.updatePage();
    });
  };
});
