angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.dashboard.operative_panel', {
    url: '/operative_panel',
    tab: 'operative_panel',
    data: {
      permission: 'OperativePanelTab'
    },
    views: {
      'content@app.wash.dashboard': {
        controller: 'WashDashboardOperativePanelController',
        templateUrl: 'pages/wash/dashboard/operative_panel/index.slim'
      }
    }
  })
})
