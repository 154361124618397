angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.charts.services_revenue', {
    url: '/services_revenue',
    tab: 'services_revenue',
    data: {
      permission: 'ServicesRevenueReportTab'
    },
    views: {
      'content@app.wash.charts': {
        controller: 'WashChartsServicesRevenueController',
        templateUrl: 'pages/wash/charts/services_revenue/index.slim'
      }
    }
  });
});
