angular.module('RocketWash').factory('PrintScopeStub', () => {
  const paymentTypes = [
    { id: 1, name: 'Бонусы' },
    { id: 2, name: 'Внутренние средства' },
    { id: 3, name: 'Наличные' },
    { id: 4, name: 'Перечисление' },
    { id: 5, name: 'Пластиковая карта' },
  ];
  const result = {
    financialOperation: {
      id: 123,
      type: 'FinancialOperationOutcomePaySalary',
      date: '2013-11-30T12:45:00Z',
      comment: 'Комментарий',
      amount: '1500',
      ordinal: 'Номер ФинОперации',
      document: 'Документ ведомость 123',
      createdManually: true,
      performed: true,
      fromFinancialCenter: {
        id: 1001,
        name: 'Название Счета Источник',
        userName: 'Имя Пользователя Источник',
        userFullName: 'ООО "ИсточникПолноеИмяОрганизации"',
      },
      toFinancialCenter: {
        id: 1002,
        name: 'Название Счета Приемник',
        userName: 'Имя Пользователя Приемник',
        userFullName: 'ООО "ПриемникПолноеИмяОрганизации"',
      },
      fromSecondFinancialCenter: {
        id: 1003,
        name: 'Название Счета Источник Два',
        userName: 'Имя Пользователя Источник Два',
        userFullName: 'ООО "ИсточникДваПолноеИмяОрганизации"',
      },
      toSecondFinancialCenter: {
        id: 1004,
        name: 'Название Счета Приемник Два',
        userName: 'Имя Пользователя Приемник Два',
        userFullName: 'ООО "ПриемникДваПолноеИмяОрганизации"',
      },
    },
    reservation: {
      id: 523,
      paidAt: new Date(),
      timeStart: new Date(),
      ordinal: 42,
      reservationPayments: [
        {
          id: 123,
          amount: 4060,
          paymentTypeId: 3,
        },
        {
          id: 124,
          amount: 221,
          paymentTypeId: 1,
        },
        {
          id: 125,
          amount: 995,
          paymentTypeId: 2,
        },
      ],
      car: {
        carMake: {
          name: 'Honda',
        },
        carModel: {
          name: 'Civic',
        },
        tag: 'AE4234134',
        contractor: {
          name: 'vladimir',
          fullName: 'ООО "КонтрагентПримерПлюс"',
          accountNumber: 'РСКОНТРАГЕНТА123',
          phone: '1234567890123',
          tin: '[ИННКлиента]',
          kpp: '[KППКлиента]',
          address: '[г. Клиента, ул. Клиента, д. 1, офис 5]',
        },
      },
      employees: [
        {
          id: 47767,
          name: 'Кадилов Владимир',
          phone: '+7(951)488-69-94',
          serviceLocationId: 131,
          serviceLocationLaneId: null,
          role: 'category_3',
          jobId: 18,
          job: {
            id: 18,
            name: 'Мойщик',
          },
          selected: true,
        },
        {
          id: 46741,
          name: 'Остапов Евгений Степанович',
          phone: '+7(955)173-40-63',
          serviceLocationId: 131,
          serviceLocationLaneId: null,
          role: 'category_2',
          jobId: 43,
          job: {
            id: 43,
            name: 'Помощник автомойщика',
          },
          selected: true,
        },
        {
          id: 45162,
          name: 'Жонго Юрий',
          phone: '+7(120)342-53-68',
          serviceLocationId: 131,
          serviceLocationLaneId: null,
          role: 'category_1',
          jobId: 18,
          job: {
            id: 18,
            name: 'Мойщик',
          },
          selected: true,
        },
        {
          id: 23529,
          name: 'Боков Александр',
          phone: '+7(940)841-18-23',
          serviceLocationId: 131,
          serviceLocationLaneId: null,
          role: 'category_1',
          jobId: 18,
          job: {
            id: 18,
            name: 'Мойщик',
          },
          selected: true,
        },
      ],
    },
    basicServices: [
      {
        name: 'Service 1',
        count: 1,
        discount: 15,
        selected: true,
        getPLI: () => ({ price: 20 }),
      }, {
        name: 'Service 2',
        count: 2,
        discount: 0,
        selected: true,
        getPLI: () => ({ price: 100 }),
      }, {
        name: 'Service 3',
        count: 2,
        discount: 10,
        selected: true,
        getPLI: () => ({ price: 10 }),
      },
    ],
    extraServices: [
      {
        name: 'Extra 1',
        count: 1,
        discount: 15,
        selected: true,
        getPLI: () => ({ price: 20 }),
      },
      {
        name: 'Extra 2',
        count: 2,
        discount: 0,
        selected: true,
        getPLI: () => ({ price: 100 }),
      },
      {
        name: 'Extra 3',
        count: 2,
        discount: 10,
        selected: true,
        getPLI: () => ({ price: 10 }),
      },
    ],
    sumPayments: () => 500,
    ptById: id => paymentTypes.find(x => x.id === id),
    legalInfo: {
      legal_name: 'ООО "ЮрЛицоПримерОрганизации"',
      inn: 'ИНН1234567890',
      kpp: 'KПП123456789',
      ogrn: 'ОГРН1234567890123',
      legal_address: 'г. Н-ск, улица Юридического Адреса, д.1, офис 1',
      real_address: 'г. Н-ск, улица Фактического Адреса, д.1, офис 1',
      post_address: 'г. Н-ск, улица Почтового Адреса, д.1, офис 1',
      bank_account_number: 'РC12345678901234567890',
      bank: 'ПримерБанк',
      bic: 'БИК123456789',
      correspondent_account_number: 'КоррС12345678901234567890',
      manager_acts_on_basis_of: 'Постановление 123 основание действия',
      office_phone: '+7900СЛУЖЕБНЫЙ',
      head_job_name: 'Главный Директор Должность',
      head_name: 'Директоров Иван Иванович',
      head_email: 'director@example.com',
      head_phone: '+7900ДИРЕКТОР',
      accountant_job_name: 'Главный Бухгалтер Должность',
      accountant_name: 'Бухгалтерова Инна Ивановна',
      accountant_email: 'accountant@example.com',
      accountant_phone: '+7900БУХГАЛТЕР',
    },
  };

  return result;
});
