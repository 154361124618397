angular.module('RocketWash').config(($stateProvider) => {
  return $stateProvider.state('app.wash.services.basic', {
    url: '/basic',
    tab: 'basic',
    data: {
      permission: 'BasicServicesTab',
    },
    views: {
      'content@app.wash.services': {
        controller: 'WashServicesBasicBaseController',
        templateUrl: 'pages/wash/services/basic/index.slim',
      },
    },
  });
});
