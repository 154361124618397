angular.module('RocketWash').factory(
  'Service',
  ($http, railsResourceFactory, userSession) => {
    const resource = railsResourceFactory({
      url: context => `/${context.queryRole || userSession.controllerScope()}/services/${context.id || ''}`,
      name: 'service',
    });

    resource.typeahead = (searchQuery, context = {}) => {
      return $http({
        method: 'GET',
        url: `${resource.resourceUrl()}/typeahead`,
        params: {
          search_query: searchQuery,
          service_type: context.serviceType,
          warehouse_id: context.warehouseId,
          user_id: context.userId,
        },
      });
    };

    resource.configured = (context={}) => {
      return $http({
        method: 'GET',
        url: `${resource.resourceUrl()}/configured`,
      }).then((response) => {
        return response.data;
      });
    };

    return resource;
  },
);
