angular.module('RocketWash').config(($stateProvider) => {
  $stateProvider.state('app.admin.settings.promo_codes', {
    url: '/promo_codes',
    tab: 'promo_codes',
    views: {
      'content@app.admin.settings': {
        controller: 'AdminSettingsPromoCodesBaseController',
        templateUrl: 'pages/admin/settings/promo_codes/index.slim',
      },
    },
  });
});
