angular.module('RocketWash').controller('WashFinanceWashFinancialOperationsHistoryController', (
  $scope, $state, $stateParams, $filter,
  FinancialOperation,
) => {
  if ($stateParams.id) {
    FinancialOperation.get($stateParams.id).then((fo) => {
      $scope.fo = fo;
    });
    FinancialOperation.history({}, {
      id: $stateParams.id,
    }).then((versions) => {
      $scope.versions = versions;
    });
  } else {
    $state.go('^');
  }
  $scope.isValueChanged = function (version, attr) {
    return version.changeset[attr];
  };
  $scope.firstOrSecond = function (first, second) {
    return (first != null) ? first : second;
  };
});
