angular.module('RocketWash').factory('FinancialOperation', (
  railsResourceFactory, railsSerializer, userSession, $http, TenantSwitchInterceptor,
) => {
  const Resource = railsResourceFactory({
    url: context => `/${context.queryRole || userSession.controllerScope()}/financial_operations/${context.id || ''}`,
    name: 'financial_operation',
    serializer: railsSerializer(function () {
      this.resource('toFinancialCenter', 'FinancialCenter');
      this.resource('fromFinancialCenter', 'FinancialCenter');
      this.resource('toSecondFinancialCenter', 'FinancialCenter');
      this.resource('fromSecondFinancialCenter', 'FinancialCenter');
      this.resource('financeClause', 'FinanceClause');
      this.resource('financialPeriods', 'FinancialPeriod');
      this.resource('financialProjects', 'FinancialProject');
    }),
  });

  Resource.history = function (queryParams, context) {
    return $http({
      url: `${this.resourceUrl(context)}/history`,
      params: queryParams,
      method: 'GET',
    }).then((response) => {
      return response.data;
    });
  };

  Resource.prototype.prepareForSave = function () {
    [
      'toFinancialCenter', 'fromFinancialCenter', 'toSecondFinancialCenter',
      'fromSecondFinancialCenter', 'financeClause', 'serviceLocation', 'organization',
    ].forEach((key) => {
      this[`${key}Id`] = this[key] && this[key].id;
    });

    ['financialPeriod', 'financialProject'].forEach((key) => {
      this[`${key}Ids`] = (this[`${key}s`] || []).map(x => x.id);
    });
  };

  Resource.addInterceptor(TenantSwitchInterceptor);

  return Resource;
});
