angular.module('RocketWash').component('rwReservationRightMenuAllServices', {
  templateUrl: 'components/rw-reservation/right-menu/all-services/index.slim',
  controller: function ($scope, reservationForm) {

    $scope.opened = false;
    $scope.selected = {
    };

    $scope.rf = reservationForm;

    $scope.$watch('rf.formTab.viewState.rightMenuAllServices', (value) => {
      if (value) {
        reservationForm.services.loadAll().then(() => {
          $scope.selectGroup($scope.rf.services.all.groups[0]);
        });
      };
    });

    $scope.$watch('rf.carContractor.car.cars_car_types', (value) => {
      if (value) {
        reservationForm.services.loadAll({force: true}).then(() => {
          $scope.selectGroup($scope.rf.services.all.groups[0]);
        });
      };
    }, true);

    $scope.toggleService = (service) => {
      if (service && service.id) {
        reservationForm.services.add(service.id);
      }
    };

    $scope.selectOften = () => {
      $scope.selected.group = null;
      $scope.selected.template = 'often';
    };

    $scope.selectGroup = (group) => {
      $scope.selected.group = group;
      $scope.selected.template = 'basic';
    };


  },
});
