

angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.warehouse.warehouses', {
    url: '/warehouses',
    tab: 'warehouses',
    data: {
      permission: 'WarehousesTab'
    },
    views: {
      'content@app.wash.warehouse': {
        controller: 'WashWarehouseWarehousesBaseController',
        templateUrl: 'pages/wash/warehouse/warehouses/index.slim'
      }
    }
  });
});
