angular.module('RocketWash').controller('WashOrganizationFinancialOperationsController', (
  $scope, userSession, FinancialCenter, $uibModal, $http, $filter,
  FinancialOperation, $translate
) => {
  if ($scope.userSession.service_location.yandex_fuel_available) {
    $scope.financialCentersYaFuelId = $scope.userSession.organization.financial_center_yandex_fuel_balance.id
  }
  $scope.userSession.organization.financial_center_yandex_fuel_balance.id;
  $scope.pds = $scope.pageDataService.new({
    page: 'wash_financial_operations',
    filters: {
      attributes: [
        { name: 'date', type: 'datetime' },
        { name: 'created_at', type: 'datetime' },
        { name: 'ordinal', type: 'number' },
        { name: 'amount', type: 'number' },
        { name: 'record_type', type: 'string' },
        { name: 'comment', type: 'string' },
        { name: 'document', type: 'string' },
        { name: 'finance_clause', type: 'string' },
        { name: 'from_financial_center_name', type: 'string' },
        { name: 'to_financial_center_name', type: 'string' },
        { name: 'from_second_financial_center_name', type: 'string' },
        { name: 'to_second_financial_center_name', type: 'string' },
      ],
      scopes: ['record_type', 'comment', 'document', 'finance_clause', 'created_at',
        'from_financial_center_name', 'to_financial_center_name',
        'from_second_financial_center_name',
        'to_second_financial_center_name'],
    },
    pg: {
      url: '/wash/financial_operations',
      model: FinancialOperation,
      query: {
        scope_to_service_location: false,
        fromToField: 'date',
        predicate: '-date',
        reverse: true,
        per: 10,
        page: 1,
      },
    },
    definePaginateMethodsOn: $scope,
  });

  $scope.isEditAllowed = function (fo) {
    let isPrevented = userSession.isEditionForDatePrevented(fo.date);
    let isManuallyCreated = fo.createdManually;
    let canCreateFinancialOperation = userSession.can('access', 'CreateFinancialOperation');
    let isAdminOrSuperUser = userSession.is('admin') || userSession.superuser;
    let isFcYaFuelId = (fo.fromFinancialCenterId || fo.toFinancialCenterId) === $scope.financialCentersYaFuelId;
    let defaultEditAllowed = !isPrevented && isManuallyCreated && canCreateFinancialOperation || isAdminOrSuperUser
    let YaFoEditAllowed = isFcYaFuelId ? userSession.superuser : true

    return YaFoEditAllowed ? defaultEditAllowed : YaFoEditAllowed;
  };

  $scope.rightMenuConfig = {
    buttons: [
      {
        type: 'export_xls',
        text: $translate.instant('buttons.export_xls'),
        action: () => { $scope.exportXLS(); },
        ngDisabled: () => (
          !$scope.isEnable()
        ),
      },
    ],
    tooltip: () => (
      !$scope.isEnable() ? $translate.instant('buttons.tooltip_message') : undefined
    )
  };

  $scope.isEnable = () => (
    moment($scope.pds.pg.query.to).diff($scope.pds.pg.query.from, 'hours') <= 745
  );

  $scope.exportXLS = () => {
    $uibModal.open({
      templateUrl: 'pages/common/email_popup/email_popup.slim',
      controller: 'CommonEmailPopupController',
      windowClass: 'small',
      size: 'sm',
    }).result.then((email) => {
      const options = angular.extend({ email }, $scope.pds.pg.query);
      return $http({
        url: `${$scope.env.apiUrl}/wash/financial_operations/export_xlsx`,
        method: 'GET',
        params: options,
      }).then(() => alertService.show({
        text: $filter('translate')('email_popup.success'),
      }));
    });
  };

  FinancialCenter.query({
    service_location_id: userSession.service_location_id,
    type: 'FinancialCenterForWashAndLegalEntity',
  }).then((fcs) => {
    $scope.fcs = fcs;
  });

  $scope.openFormPopup = (fo) => {
    $uibModal.open({
      templateUrl: 'pages/wash/organization/financial_operations/form.slim',
      controller: 'WashFinanceWashFinancialOperationsFormController',
      resolve: {
        resolvedFinancialOperation: () => fo,
      },
    }).result.finally(() => {
      setTimeout($scope.updatePage, 1000);
    });
  };
});
