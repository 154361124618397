angular.module('RocketWash').factory('Warehouse', function(railsResourceFactory, userSession, $http) {
  const Resource = railsResourceFactory({
    url: (context) => { return "/" + (context.queryRole || userSession.controllerScope()) + "/warehouses/" + (context.id || '') },
    name: "warehouse"
  });

  Resource.clean = (key, context) => {
    return $http({
      method: 'DELETE',
      url: `${Resource.resourceUrl(context)}/clean`,
      params: { key: key },
    })
  };

  Resource.organization_scope = (key, context) => {
    return $http({
      method: 'GET',
      url: `${Resource.resourceUrl(context)}`,
      params: { organization_scope: key },
    })
  }

  return Resource;
});
