angular.module('RocketWash').service('reservationFormEmployeeParticipations', ($injector, rwApi, userSession) => {

  const service = {
    all: [],
    grouped: [],

    calculate: () => {
      const rf = $injector.get('reservationForm');

      const employees = rf.employees.all.filter((e) => {
        return e.selected;
      }).map((e) => {
        return {
          id: e.id,
          job_id: e.job.id,
        };
      });

      const services = rf.services.selected.map((s) => {
        return {
          id: s.id
        };
      });

      const employee_participations = service.all.filter((ep) => {
        return ep.manual;
      });

      return rwApi.sendRequest({
        method: 'POST',
        path: 'reservation_form/employee_participations/calculate',
        data: {
          service_location_id: userSession.service_location_id,
          employees,
          services,
          employee_participations,
        },
      }).then((eps) => {
        eps.forEach((ep) => ep.id = `${ep.service_id}_${ep.employee_id}`);

        service.all = eps;

        service.grouped = _.chain(service.all).groupBy('service_id').values().map((eps) => {
          const groupedEps = _.chain(eps).groupBy('brigade_group_number').values().map((eps) => {
            return {
              eps: _.sortBy(eps, ['employee_name']),
              brigade_group_number: eps[0].brigade_group_number,
              sum: _.chain(eps).sumBy('participation_percentage').round(2).value(),
            };
          }).value();

          return {
            eps: groupedEps,
            service_name: eps[0].service_name,
            service_id: eps[0].service_id,
          };
        }).sortBy('service_name').value();
      });
    },

    prepareForSave: () => {
      return service.all && service.all.filter((ep) => ep.manual);
    },

    assignState: (manualEPs) => {
      manualEPs.forEach((ep) => ep.manual = true);
      service.all = manualEPs;
    },

    reset: () => {
      service.all = [];
      service.grouped = [];
    },

    editable: () => {
      const rf = $injector.get('reservationForm');
      return rf.reservations.reservation.set_employees_manually;
    },

  };

  return service;
});
