angular.module('RocketWash').service('ptfcVariantsService', (
  $q, PaymentType, PaymentSubtype, FinancialCenter, userSession,
) => {
  const financialCenters = []; // TODO: fix according to access control
  const paymentSubtypes = []; // TODO: fix according to access control
  const ptLookup = {};
  let service;

  const preparePtFcs = () => {
    const results = [];
    financialCenters.forEach((fc) => {
      if (fc.paymentTypeIds.includes(ptLookup.cash.id)) {
        results.push({
          paymentTypeId: ptLookup.cash.id,
          toFinancialCenterId: fc.id,
          label: `${ptLookup.cash.name} - ${fc.name}`,
        });
      }
    });
    results.push({
      paymentTypeId: ptLookup.bonuses.id,
      toFinancialCenterId: null,
      label: `${ptLookup.bonuses.name}`,
    });
    results.push({
      paymentTypeId: ptLookup.card.id,
      toFinancialCenterId: null,
      label: `${ptLookup.card.name}`,
    });
    results.push({
      paymentTypeId: ptLookup.wire.id,
      toFinancialCenterId: null,
      label: `${ptLookup.wire.name}`,
    });

    paymentSubtypes.filter(x => x.paymentTypeConstName == 'fuel_card').forEach((pst) => {
      results.push({
        paymentTypeId: ptLookup.fuel_card.id,
        paymentSubtypeId: pst.id,
        toFinancialCenterId: null,
        label: `${ptLookup.fuel_card.name} = ${pst.name}`,
      });
    });
    results.push({
      paymentTypeId: ptLookup.mobile.id,
      toFinancialCenterId: null,
      label: `${ptLookup.mobile.name}`,
    });
    service.variants = results;
    return results;
  };

  const fcLoaded = FinancialCenter.query({
    type: 'FinancialCenterForWashAndLegalEntity',
  }).then((response) => {
    financialCenters.push(...response.filter(x => x.accessPermitted()));
  });
  // TODO: load from userSession
  const ptLoaded = PaymentType.query().then((paymentTypes) => {
    const typeNames = ['wire', 'card', 'cash', 'bonuses', 'mobile', 'fuel_card'];
    typeNames.forEach((name) => {
      ptLookup[name] = paymentTypes.find(pt => pt.rwConstName === name);
    });
  });
  const pstLoaded = PaymentSubtype.query().then((pst) => {
    paymentSubtypes.push(...pst.filter(x => x.active));
  });
  const promise = $q.all([fcLoaded, ptLoaded, pstLoaded]).then(preparePtFcs);

  service = {
    financialCenters: () => financialCenters.slice(),
    variants: [],
    promise: promise,
    variantsFor: (payment) => {
      if (payment.paymentTypeId === ptLookup.mobile.id) {
        return service.variants.filter((ptfc) => {
          return ptfc.paymentTypeId === ptLookup.mobile.id;
        });
      }
      const filteredVariants = service.variants.filter(x => x.paymentTypeId !== ptLookup.mobile.id);
      const settingEnabled =
        userSession.settings.require_bonuses_payment_confirmation === 'yes';
      if (!settingEnabled || !(payment && payment.id)) {
        return filteredVariants;
      }
      if (payment.paymentTypeId === ptLookup.bonuses.id) {
        return filteredVariants.filter((ptfc) => {
          return ptfc.paymentTypeId === ptLookup.bonuses.id;
        });
      }
      return filteredVariants.filter((ptfc) => {
        return ptfc.paymentTypeId !== ptLookup.bonuses.id;
      });
    },
    paymentConfirmationBlockVisible: (payment) => {
      const settingEnabled =
        userSession.settings.require_bonuses_payment_confirmation === 'yes';
      const paymentTypeCorrect =
        payment.ptfc && payment.ptfc.paymentTypeId === ptLookup.bonuses.id;
      return settingEnabled && paymentTypeCorrect;
    },
    paymentEditable: (payment) => {
      if (payment.paymentTypeId === ptLookup.mobile.id) {
        return false;
      }
      if (payment.apiKeyForElectronicCashierId) {
        return false;
      }
      const settingEnabled = userSession.settings.require_bonuses_payment_confirmation === 'yes';
      if (!settingEnabled) {
        return true;
      }
      if (payment.id && (payment.ptfc && payment.ptfc.paymentTypeId) === ptLookup.bonuses.id) {
        return false;
      }
      return true;
    },
    defaultPayments: () => {
      const ptfc = service.variants.find(x => x.paymentTypeId === ptLookup.cash.id);
      if (!ptfc) {
        return [];
      }
      return [
        {
          paymentTypeId: ptfc.paymentTypeId,
          toFinancialCenterId: ptfc.toFinancialCenterId,
          amount: 0,
        },
      ];
    },
    variantSelected: (variant, payment) => {
      payment.ptfc = variant;
      payment.paymentTypeId = variant.paymentTypeId;
      payment.paymentSubtypeId = variant.paymentSubtypeId;
      payment.toFinancialCenterId = variant.toFinancialCenterId;
    },
    initializeVariants: (payments) => {
      payments.forEach((payment) => {
        payment.ptfc = service.variants.find(variant => (
          variant.paymentTypeId === payment.paymentTypeId &&
          ((!variant.paymentSubtypeId && !payment.paymentSubtypeId) || variant.paymentSubtypeId === payment.paymentSubtypeId) &&
          variant.toFinancialCenterId === payment.toFinancialCenterId
        ));
      });
    },
    findOrCreatePayment: (payments, paymentTypeName) => {
      let payment = payments.find(x => x.paymentTypeId === ptLookup[paymentTypeName].id);
      if (payment) {
        return payment;
      }
      payment = { amount: 0 };
      payment.ptfc = service.variants
        .find(ptfc => ptfc.paymentTypeId === ptLookup[paymentTypeName].id);
      service.variantSelected(payment.ptfc, payment);
      payments.push(payment);
      return payment;
    },
    paymentsSum: (payments) => {
      return payments.map(x => parseFloat(x.amount, 10) || 0).reduce((x, y) => x + y, 0);
    },
    clearZeroPayments: (payments) => {
      const zeroIndices = payments
        .map((payment, i) => [payment, i])
        .filter(([payment, _i]) => !payment.id && parseInt(payment.amount || 0, 10) === 0)
        .map(([_payment, i]) => i);
      zeroIndices.reverse().forEach((index) => { payments.splice(index, 1); });
    },
    payRestWithCash: (totalAmount, payments) => {
      const payment = service.findOrCreatePayment(payments, 'cash');
      const sumToPay = totalAmount - service.paymentsSum(payments);
      payment.amount = parseInt(payment.amount || 0, 10);
      payment.amount += sumToPay;
    },
    payRestWithBonuses: (totalAmount, payments, balance) => {
      balance = balance || Infinity;
      if (balance <= 0) {
        return;
      }
      const payment = service.findOrCreatePayment(payments, 'bonuses');
      payment.amount = parseFloat(payment.amount || 0);
      const paidWithBonusesAmount = payments
        .filter(x => x.paymentTypeId === ptLookup.bonuses.id)
        .map(x => parseFloat(x.amount) || 0).reduce((x, y) => x + y, 0);
      let bonusesLeft = balance - paidWithBonusesAmount;
      const sumToPay = Math.max(
        Math.min(totalAmount - service.paymentsSum(payments), bonusesLeft), 0,
      );
      payment.amount = parseFloat(payment.amount || 0);

      payment.amount += sumToPay;
      bonusesLeft -= sumToPay;

      payments.filter(x => [ptLookup.cash.id, ptLookup.card.id].includes(x.paymentTypeId))
        .forEach((reducedPayment) => {
          reducedPayment.amount = parseFloat(reducedPayment.amount) || 0;
          const reducedSum = Math.max(0, Math.min(reducedPayment.amount, bonusesLeft));

          reducedPayment.amount -= reducedSum;
          bonusesLeft -= reducedSum;
          payment.amount += reducedSum;
        });
      service.clearZeroPayments(payments);
    },
    payRestWithMoney: (totalAmount, payments, balance) => {
      balance = balance || Infinity;
      if (balance <= 0) {
        return;
      }
      const payment = service.findOrCreatePayment(payments, 'wire');
      payment.amount = parseFloat(payment.amount || 0);
      const paidWithWireAmount = payments
        .filter(x => x.paymentTypeId === ptLookup.wire.id)
        .map(x => parseFloat(x.amount) || 0).reduce((x, y) => x + y, 0);
      const bonusesLeft = balance - paidWithWireAmount;
      const sumToPay = Math.max(
        Math.min(totalAmount - service.paymentsSum(payments), bonusesLeft), 0,
      );
      payment.amount = parseFloat(payment.amount || 0);
      payment.amount += sumToPay;

      service.clearZeroPayments(payments);
    },
  };

  return service;

  // // add separate method to init new payments
  // $scope.reservation.reservationPayments.forEach((rp) => {
  //   rp.ptfc = $scope.ptAndFcVariants.find(ptfc => (
  //     ptfc.paymentTypeId === rp.paymentTypeId &&
  //     ptfc.toFinancialCenterId === rp.toFinancialCenterId
  //   ));
  // });
});
