angular.module('RocketWash').config(($stateProvider) => {
  $stateProvider.state('app.wash.settings.lanes', {
    url: '/lanes',
    controller: 'WashSettingsLanesController',
    templateUrl: 'pages/wash/settings/lanes/index.slim',
    data: {
      permission: 'ServiceLocationLanesMenuItem',
    },
    views: {
      'content@app.wash.settings': {
        controller: 'WashSettingsLanesController',
        templateUrl: 'pages/wash/settings/lanes/index.slim',
      },
    },
  }).state('app.wash.settings.lanes.form', {
    url: '/form',
    views: {
      'content@app.wash.settings': {
        controller: 'WashSettingsLanesFormController',
        templateUrl: 'pages/wash/settings/lanes/form.slim',
      },
    },
  });
});
