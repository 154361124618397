angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.settings.suspicious_activity', {
    url: '/suspicious_activity',
    tab: 'suspicious_activity',
    data: {
      permission: 'SettingsSuspciousActivitiesTab',
    },
    views: {
      'content@app.wash.settings': {
        controller: 'WashSettingsSuspiciousActivityController',
        templateUrl: 'pages/wash/settings/suspicious_activity/index.slim'
      }
    }
  });
});
