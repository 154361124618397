angular.module('RocketWash').controller(
  'AdminSettingsHelpArticlesBaseController',
  ($scope, $state, $translate, HelpArticle) => {
    $scope.predicate = 'name';
    $scope.updatePage = () => {
      console.log('updating page');
      HelpArticle.query().then((helpArticles) => {
        console.log('queried:');
        $scope.helpArticles = helpArticles;
      });
    };
    $scope.save = (helpArticle) => {
      helpArticle.save().then(() => {
        $scope.updatePage();
      });
    };
    $scope.delete = (helpArticle) => {
      if (confirm($translate.instant('alert.are_you_sure'))) {
        helpArticle.delete().then(() => {
          console.log('deleted');
          $scope.updatePage();
        });
      }
    };
    $scope.edit = (helpArticle) => {
      return $state.go('.form', {id: helpArticle.id});
    };
    $scope.newHelpArticle = () => {
      //fixme
      $state.go('.form');
    };

    $scope.updatePage();
  },
);
