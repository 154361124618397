angular.module('RocketWash').controller(
  'WashMarketingReservationReviewsController',
  ($scope, ServiceLocation, ReviewForCustomer, $filter) => {
    const ALL_SERVICE_LOCATIONS = 'all_sls';
    const MINIMUM_CHARACTERS_PER_TEXT = 20;
    const MINIMUM_HEIGHT = 4;
    const AVG_LINE_HEIGHT = 10;

    $scope.selectedSl = ALL_SERVICE_LOCATIONS;

    ServiceLocation.query().then((serviceLocations) => {
      $scope.serviceLocations = serviceLocations;
    }).then(() => {
      $scope.pds = $scope.pageDataService.new({
        page: 'review_for_customers',
        filters: {
          attributes: [
            {
              name: 'created_at',
              type: 'datetime'
            },
            {
              name: 'reservation_time_end',
              type: 'datetime',
            },
            {
              name: 'comment',
              type: 'string'
            },
            {
              name: 'car_tag',
              type: 'string'
            },
            {
              name: 'car_make',
              type: 'string'
            },
            {
              name: 'car_model',
              type: 'string'
            },
            {
              name: 'contractor_name',
              type: 'string'
            },
            {
              name: 'contractor_phone',
              type: 'string'
            },
            {
              name: 'stars_count',
              type: 'number',
            }
          ],
          scopes: [
            'comment', 'car_tag', 'car_make', 'car_model',
            'contractor_name', 'contractor_phone'
          ],
          beforeSend: (filters) => {
            filters.push({
              attr: 'service_location_id',
              condition: 'includes_array',
              value: chosenServiceLocationIds(),
            });
          },
        },
        pg: {
          url: '/wash/review_for_customers',
          model: ReviewForCustomer,
          query: {
            fromToField: 'created_at',
            predicate: '-created_at',
            page: 1,
            per: 10,
          },
        },
        definePaginateMethodsOn: $scope,
      });
    });

    const chosenServiceLocationIds = () => {
      if ($scope.selectedSl === ALL_SERVICE_LOCATIONS) {
        return $scope.serviceLocations.map((sl) => {
          return sl.id;
        });
      } else {
        return [$scope.selectedSl.id];
      }
    };

    $scope.handleHover = (event) => {
      console.log(event);

      let element = getElement(event);

      let charLen = element.val().length;
      var height;

      if (charLen < MINIMUM_CHARACTERS_PER_TEXT) {
        height = MINIMUM_HEIGHT;
      } else {
        height = $(element).height(element[0].scrollHeight);
      }

      element.mouseenter(() => {
        element.css('height', `${height}rem`);
      });

      element.mouseleave(() => {
        element.css('height', '4rem');
      });
    };

    const getElement = (event) => {
      return angular.element(event.srcElement || event.target);
    };

    // table
    $scope.allExpanded = () => $scope.pds.pg.items.every(x => x.expanded);

    $scope.toggleAll = () => {
      const newValue = !$scope.allExpanded();
      $scope.pds.pg.items.forEach((x) => {
        x.expanded = newValue;
      });
    };
  }
)
