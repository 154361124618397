angular.module('RocketWash').factory(
  'PriceListItem',
  ($http, railsResourceFactory, userSession) => {
    const resource = railsResourceFactory({
      url: (context) => { return "/" + (context.queryRole || userSession.controllerScope()) + "/price_list_items/" + (context.id || '') },
      name: 'price_list_item',
    });

    return resource;
  },
);
