// TODO: rework

angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  const statePrefix = 'app.admin.organizations.organization.settings.service_locations';
  const urlPrefix = 'pages/admin/organizations/settings/service_locations/settings';
  // I am terribly sorry if you weren't able to find this line because of me
  return $stateProvider.state(`${statePrefix}.service_location`, {
    url: '/{slId}',
    abstract: true,
  }).state(`${statePrefix}.service_location.settings`, {
    url: '/settings',
    abstract: true,
    views: {
      'content@app.admin.organizations': {
        controller: 'AdminServiceLocationSettingsController',
        templateUrl: `${urlPrefix}/index.slim`,
      },
    },
  }).state(`${statePrefix}.service_location.settings.general`, {
    url: '/general',
    tab: 'general',
    views: {
      [`settingsView@${statePrefix}.service_location.settings`]: { // TODO: fix
        controller: 'AdminServiceLocationSettingsGeneralController',
        templateUrl: `${urlPrefix}/general.slim`,
      },
    },
  }).state(`${statePrefix}.service_location.settings.print`, { // TODO: fix
    url: '/print',
    tab: 'print',
    views: {
      [`settingsView@${statePrefix}.service_location.settings`]: { // TODO: fix
        controller: 'AdminServiceLocationSettingsPrintController',
        templateUrl: `${urlPrefix}/print.slim`,
      },
    },
  });
});
