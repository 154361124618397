

angular.module('RocketWash').controller('SelectServicesController', function($scope, $route) {
  $scope.accordion = {
    basic: true,
    extra: false
  };
  return $scope.resetAccordion = function(key) {
    var tmp;
    tmp = {};
    tmp[key] = !$scope.accordion[key];
    return $scope.accordion = tmp;
  };
});
