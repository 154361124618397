angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.settings.warehouse', {
    url: '/warehouse',
    tab: 'warehouse',
    data: {
      permission: 'SettingsWarehouseTab'
    },
    views: {
      'content@app.wash.settings': {
        controller: 'WashSettingsWarehouseIndexController',
        templateUrl: 'pages/wash/settings/warehouse/index.slim'
      }
    }
  });
});
