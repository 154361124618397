

angular.module('RocketWash').controller('WashSettingsDiscountActionsBaseController', function($rootScope, $state, $scope, $filter, DiscountAction, DiscountActionStep) {
  DiscountAction.query().then(function(data) {
    if (data.length > 0) {
      return $state.go('.show', {
        id: data[0].id
      });
    }
  });
  return $scope.newDiscountAction = function() {
    var action;
    action = new DiscountAction({
      name: $filter('translate')('settings.discount_actions.new_action_name'),
      priority: 0
    });
    return action.save().then(function(data) {
      return $state.go('.show', {
        id: data.id
      });
    });
  };
});
