angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.services.price_lists', {
    url: '/price_lists',
    tab: 'price_lists',
    data: {
      permission: 'PriceListsTab'
    },
    views: {
      'content@app.wash.services': {
        controller: 'WashServicesPriceListsBaseController',
        templateUrl: 'pages/wash/services/price_lists/index.slim'
      }
    }
  }).state('app.wash.services.price_lists.form', {
    url: '/form',
    views: {
      'content@app.wash.services': {
        controller: 'WashServicesPriceListsFormController',
        templateUrl: 'pages/wash/services/price_lists/form.slim'
      }
    }
  });
});
