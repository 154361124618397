angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.settings.mobile_settings', {
    url: '/mobile_settings',
    tab: 'mobile_settings',
    data: {
      permission: 'MobileSettingsTab'
    },
    views: {
      'content@app.wash.settings': {
        controller: 'WashSettingsMobileSettingsIndexController',
        templateUrl: 'pages/wash/settings/mobile_settings/index.slim'
      }
    }
  });
});
