angular.module('RocketWash').factory(
  'PrintableDocument',
  (
    $timeout, $compile, $rootScope, railsResourceFactory, userSession, TenantSwitchInterceptor,
  ) => {
    const resource = railsResourceFactory({
      url: (context) => { return "/" + (context.queryRole || userSession.controllerScope()) + "/printable_documents/" + (context.id || '') },
        name: 'printable_document',
    });

    resource.addInterceptor(TenantSwitchInterceptor);

    resource.prototype.numDeviceFromKKM = function() {
      return this.deviceIdentifier.substring(10);
    };

    return resource;
  },
);
