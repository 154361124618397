angular.module('RocketWash').controller(
  'AdminPartnersDashboardIndexController',
  ($scope, User, $translate) => {
    $scope.pds = $scope.pageDataService.new({
      page: 'partners',
      filters: {
        attributes: [
          { name: 'name', type: 'string' },
          { name: 'phone', type: 'string' },
          { name: 'email', type: 'string' },
          { name: 'registered_organizations', type: 'integer'}
        ],
        scopes: ['name', 'phone', 'email', 'registered_organizations'],
      },
      pg: {
        url: '/admin/users/partners',
        model: User,
        query: {
          skipDateFilter: true,
          predicate: '-created_at',
        },
      },
      definePaginateMethodsOn: $scope,
    });
    $scope.new = () => {
      $scope.pds.pg.items.unshift(new User());
    };
    $scope.duplicate = (partner) => {
      partner.duplicate().then((usr) => {
        $scope.pds.pg.items.unshift(usr);
      });
    };
    $scope.save = (partner, index) => {
      if (index != null) {
        partner.phone = $(`#partner-${index} .phone-input`).val();
      }
      partner.save();
    };
    $scope.delete = function (partner) {
      if (confirm($translate.instant('alert.are_you_sure'))) {
        partner.delete().then(() => {
          $scope.updatePage();
        });
      }
    };
    $scope.invite = function (partner) {
      partner.invite().then(
        () => (alert($translate.instant('alert.invite_sent'))),
        response => (alert(response.data.error)),
      );
    };

    $scope.humanizeNumber = function (number, fallback) {
      return number != null ? number : fallback;
    };
  },
);
