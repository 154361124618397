angular.module('RocketWash').controller('WashWarehouseConsumablesDetailsController', (
  $scope, $translate, WarehouseOperation, warehouseOperation,
) => {
  WarehouseOperation.get(warehouseOperation.id).then((wo) => {
    $scope.wo = wo;
  });

  $scope.grandTotal = () => {
    return $scope.wo.warehouseOperationItems.map((item) => item.price * item.count).reduce((a,memo) => a + memo, 0);
  };
});
