angular.module('RocketWash').controller('WashIncomeEmployeesBaseController', (
  $scope, $state, $translate, $uibModal, $timeout, viewStore, Employee, Role,
  Job, ServiceLocation
) => {
  $scope.allSlsAllowed = ['admin', 'organization_director', 'director'].includes($scope.userSession.getRole())

  $scope.showFiredEmployees = viewStore.data.showFiredEmployees;
  $scope.toggleShowFiredEmployees = () => {
    viewStore.data.showFiredEmployees = !viewStore.data.showFiredEmployees;
    $scope.updatePage();
  }

  const CUSTOM_JOB_FILTER = {
    name: 'job',
    conditions: ['custom__job_in_service_location'],
    type: 'enum',
    values: [],
  };

  $scope.pds = $scope.pageDataService.new({
    page: 'employees',
    filters: {
      attributes: [
        { name: 'name', type: 'string' },
        { name: 'phone', type: 'string' },
        { name: 'role', type: 'string' },
        { name: 'employee_status', type: 'string' },
        { name: 'balance_salary', type: 'number' },
        CUSTOM_JOB_FILTER,
      ],
      scopes: ['name', 'phone', 'role', 'employee_status'],
      beforeSend: (filters) => {
        if (!viewStore.data.showFiredEmployees) {
          filters.push({
            attr: 'employee_status',
            condition: 'not_equals',
            value: 'fired',
          });
        }
        if ($scope.activeServiceLocation !== 'all_sls') {
          filters.push({
            condition: 'custom__users_in_service_location',
            value: $scope.activeServiceLocation || -1,
          })
        }
      },
    },
    pg: {
      url: '/wash/employees',
      model: Employee,
      query: {
        skipDateFilter: true,
        predicate: 'name',
        reverse: false,
        status: null,
      },
    },
    definePaginateMethodsOn: $scope,
  });

  ServiceLocation.query().then((serviceLocations) => {
    $scope.serviceLocations = serviceLocations;

    if (!viewStore.data.activeServiceLocation) {
      viewStore.store($scope.allSlsAllowed ? { activeServiceLocation: 'all_sls' } : { activeServiceLocation: $scope.serviceLocations[0].id });
    }
    $scope.activeServiceLocation = viewStore.data.activeServiceLocation;

    $scope.updatePage()
  });
  Role.query().then((roles) => {
    $scope.roles = roles;
  });
  Job.query().then((jobs) => {
    $scope.jobs = jobs;

    jobs.forEach((job) => {
      CUSTOM_JOB_FILTER.values.push({
        value: job.name,
        label: job.name,
      });
    });
  });

  $scope.newEmployee = function () {
    viewStore.store({
      employee: new Employee(),
    });
    return $state.go('.form');
  };
  $scope.editEmployee = function (employee) {
    viewStore.store({
      employee,
    });
    return $state.go('.form');
  };
  $scope.save = function (employee, index) {
    $scope.errors = null;
    if (index != null) {
      employee.phone = $(`#employee-${index} .phone-input`).val();
    }
    const persistedEmployee = employee.id != null;
    return employee.save().then((empl) => {
      if (_.isEmpty(empl.errors)) {
        if (!persistedEmployee) {
          $scope.pds.pg.items.push(empl);
        }
      } else {
        $scope.errors = {
          create_employee: empl.errors,
        };
        $scope.reload();
      }
    });
  };
  $scope.delete = (employee) => {
    if (confirm($translate.instant('alert.are_you_sure'))) {
      employee.delete().then(() => {
        $scope.pds.pg.items = _.without($scope.pds.pg.items, employee);
      });
    }
  };
  $scope.jobName = (jobId) => {
    const job = _.find($scope.jobs, (e) => {
      return e.id === jobId;
    });
    return job && job.name;
  };

  $scope.openFormPopup = (employee) => {
    $uibModal.open({
      templateUrl: 'pages/wash/income/employees/form.slim',
      controller: 'WashIncomeEmployeesFormController',
      resolve: {
        resolvedEmployee: () => employee,
        resolvedReadonly: false,
      },
    }).result.finally(() => {
      $timeout($scope.resetAndUpdate, 1000);
    });
  };

  $scope.setValue = () => {
    viewStore.store({ activeServiceLocation: $scope.activeServiceLocation });
    setTimeout($scope.updatePage, 1000);
  }
});
