

angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.settings.schedule', {
    url: '/schedule',
    tab: 'schedule',
    data: {
      permission: 'SchedulesTab'
    },
    views: {
      'content@app.wash.settings': {
        controller: 'WashSettingsScheduleController',
        templateUrl: 'pages/wash/settings/schedule/index.slim'
      }
    }
  });
});
