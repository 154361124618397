import { env } from '@env';

angular.module('RocketWash').controller('TaxiAnalyticsExternalPaymentsFormController', (
  $scope, viewStore, userSession, FinancialCenter, ExternalPayment, tinkoffFormService,
) => {
  if (viewStore.data.externalPayment.id) {
    ExternalPayment.get(viewStore.data.externalPayment.id).then((externalPayment) => {
      $scope.externalPayment = externalPayment;
    });
  } else {
    $scope.externalPayment = new ExternalPayment({
      organizationId: userSession.organization.id,
      status: 'new',
    });
  }

  FinancialCenter.query({
    service_location_id: userSession.service_location_id,
    type: 'FinancialCenterForWashAndLegalEntity',
  }).then((fcs) => {
    $scope.fcs = fcs;
  });

  $scope.save = () => {
    $scope.externalPayment.save().then((response) => {
      if (_.isEmpty(response.errors)) {
        angular.extend($scope.externalPayment, response);
      } else {
        $scope.errors = { external_payment: response.errors };
      }
    });
  };

  $scope.payCard = (id) => {
    ExternalPayment.get(id).then((ep) => {
      tinkoffFormService.openForm(ep);
      $scope.$dismiss();
    });
  };

  $scope.payBankTransfer = (id) => {
    ExternalPayment.get(id).then((ep) => {
      const url = `${env.apiUrl}wash/external_payments/${ep.id}/document.pdf`;
      var win = window.open(url, '_blank');
      win.focus();

      $scope.$dismiss();
    });
  };
});
