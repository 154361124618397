angular.module('RocketWash').config(($stateProvider, $urlRouterProvider) => {
  $urlRouterProvider.when('/admin/charts', '/admin/charts/reservations');
  return $stateProvider.state('app.admin.charts', {
    url: '/charts',
    abstract: true,
    controller: 'AdminChartsBaseController',
    templateUrl: 'pages/admin/charts/index.slim',
    data: {
      tabs: ['reservations', 'organizations', 'rw_income', 'active_service_locations', 'active_service_locations_deductions', 'balances'],
    },
  }).state('app.admin.charts.reservations', {
    url: '/reservations',
    tab: 'reservations',
    views: {
      'content@app.admin.charts': {
        controller: 'AdminChartsReservationsController',
        templateUrl: 'pages/admin/charts/reservations/index.slim',
      },
    },
  }).state('app.admin.charts.organizations', {
    url: '/organizations',
    tab: 'organizations',
    views: {
      'content@app.admin.charts': {
        controller: 'AdminChartsOrganizationsController',
        templateUrl: 'pages/admin/charts/organizations/index.slim',
      },
    },
  }).state('app.admin.charts.rw_income', {
    url: '/rw_income',
    tab: 'rw_income',
    views: {
      'content@app.admin.charts': {
        controller: 'AdminChartsRwIncomeController',
        templateUrl: 'pages/admin/charts/rw_income/index.slim',
      },
    },
  }).state('app.admin.charts.active_service_locations', {
    url: '/active_service_locations',
    tab: 'active_service_locations',
    views: {
      'content@app.admin.charts': {
        controller: 'AdminChartsActiveServiceLocationsController',
        templateUrl: 'pages/admin/charts/active_service_locations/index.slim',
      },
    },
  }).state('app.admin.charts.active_service_locations_deductions', {
    url: '/active_service_locations_deductions',
    tab: 'active_service_locations_deductions',
    views: {
      'content@app.admin.charts': {
        controller: 'AdminChartsActiveServiceLocationsDeductionsController',
        templateUrl: 'pages/admin/charts/active_service_locations_deductions/index.slim',
      },
    },
  });
});
