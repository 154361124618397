angular.module('RocketWash').directive('rwRemotePagination', function($http) {
  return {
    restrict: 'E',
    scope: {
      pg: '='
    },
    templateUrl: 'directives/rw-remote-pagination/rw-remote-pagination.slim',
    link: function(scope, element, attrs) {
      var updatePage;
      updatePage = function() {
        var params;
        params = angular.extend({}, scope.pg.query, {
          page: scope.pg.currentPage || 1,
          per: scope.pg.numPerPage || 10
        });
        return $http({
          method: 'GET',
          url: scope.pg.url,
          params: params
        }).then(function(response) {
          var i, item, len, ref, results;
          if (scope.pg.model) {
            response.data.items = _.map(response.data.items, function(o) {
              return new scope.pg.model(o);
            });
          }
          angular.extend(scope.pg, response.data);
          ref = scope.pg.items || [];
          results = [];
          for (i = 0, len = ref.length; i < len; i++) {
            item = ref[i];
            results.push(item.selected = scope.pg.selectAll || _.includes(scope.pg.selectedKeys, item.id));
          }
          return results;
        });
      };
      scope.pg.update = updatePage;
      return scope.$watch('[pg.currentPage, pg.numPerPage, pg.query]', updatePage, true);
    }
  };
});
