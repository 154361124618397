angular.module('RocketWash').config(function($stateProvider, $urlRouterProvider) {
  return $stateProvider.state('app.wash.charts.user_categories', {
    url: '/user_categories',
    tab: 'user_categories',
    data: {
      permission: 'UserCategoriesReportTab'
    },
    views: {
      'content@app.wash.charts': {
        controller: 'WashChartsUserCategoriesController',
        templateUrl: 'pages/wash/charts/user_categories/index.slim'
      }
    }
  });
});
