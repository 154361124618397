angular.module('RocketWash').service('total', function(userSession) {
  const total = {
    servicesPrice: function(services, carTypeId) {
      return _.reduce(services, (function(_this) {
        return function(totalPrice, service) {
          var price, wservice;
          wservice = service.getPLI ? service.getPLI(carTypeId) : service;
          price = (parseFloat(wservice.price) || 0) * (parseFloat(service.count) || 0);
          return totalPrice + price;
        };
      })(this), 0);
    },
    productsPrice(products) {
      return (products || []).map((product) => {
        return (parseFloat(product.price, 10) || 0) * (parseFloat(product.count, 10) || 0);
      }).reduce((x, y) => x + y, 0);
    },
    servicesDiscount: function(services, carTypeId) {
      return _.reduce(services, (function(_this) {
        return function(totalDiscount, service) {
          var discount, price, wservice;
          wservice = service.getPLI ? service.getPLI(carTypeId) : service;
          price = (parseFloat(wservice.price) || 0) * (parseFloat(service.count) || 0);
          discount = price - _this.discount(price, service.discount);
          return totalDiscount + discount;
        };
      })(this), 0);
    },
    productsDiscount(products) {
      return (products || []).map((product) => {
        const price = (parseFloat(product.price, 10) || 0) * (parseFloat(product.count, 10) || 0);
        return price - total.discount(price, product.discount);
      }).reduce((x, y) => x + y, 0);
    },
    servicesDuration: function(services, carTypeId) {
      return _.reduce(services, (function(totalDuration, service) {
        return totalDuration + (parseFloat(service.getPLI(carTypeId).duration || 0) * parseFloat(service.count || 0));
      }), 0);
    },
    formatDecimal: function(amount) {
      var decimalPoints, step;
      amount = parseFloat(amount) || 0;
      step = parseFloat(userSession.getRoundingStep());
      decimalPoints = -this.floor(Math.log(step) / Math.log(10));
      return amount.toFixed(decimalPoints);
    },
    discount: function(amount, discount) {
      if (discount) {
        return (parseFloat(amount) || 0) * (100 - (parseFloat(discount) || 0)) / 100.0;
      } else {
        return amount;
      }
    },
    round: function(amount) {
      return Math.round(amount * 100) / 100;
    },
    ceil: function(amount) {
      const result = Math.ceil(amount / userSession.getRoundingStep()) * userSession.getRoundingStep();
      return this.round(result);
    },
    floor: function(amount) {
      const result = Math.floor(amount / userSession.getRoundingStep()) * userSession.getRoundingStep();
      return this.round(result);
    },
    selected: function(services) {
      return _.filter(services, function(service) {
        return service.selected;
      });
    },
    quantity: function(services) {
      return _.reduce(services, (function(tmp, service) {
        return tmp + (parseFloat(service.quantity) || 0);
      }), 0);
    },
    count: function (services) {
      return (services || []).map(service => (parseFloat(service.count, 10) || 0))
        .reduce((x, y) => x + y, 0);
    }
  };
  return total;
});
