angular.module('RocketWash').controller(
  'AdminStaffController',
  ($scope, $http, $translate, User, Role) => {
    $scope.pds = $scope.pageDataService.new({
      page: 'admin_users',
      filters: {
        attributes: [
          { name: 'name', type: 'string' },
          { name: 'phone', type: 'string' },
          { name: 'tin', type: 'string' },
          { name: 'kpp', type: 'string' },
          { name: 'sex', type: 'string' },
          { name: 'all_car_tags', type: 'string' },
          { name: 'all_car_make_models', type: 'string' },
          { name: 'user_category_name', type: 'string' },
        ],
        scopes: [
          'name', 'phone', 'tin', 'kpp', 'sex', 'all_car_tags', 'all_car_make_models',
          'user_category_name',
        ],
      },
      pg: {
        url: '/admin/users',
        model: User,
        query: {
          skipDateFilter: true,
          predicate: 'id',
          page: 1,
          per: 10,
        },
      },
      definePaginateMethodsOn: $scope,
    });
    Role.query().then((roles) => {
      $scope.roles = roles.map(x => x.name).filter(x => x);
    });
    $scope.new = () => {
      $scope.pds.pg.items.unshift(new User({ role: 'admin' }));
    };
    $scope.save = (user) => {
      user.save();
    };
    $scope.delete = (user) => {
      if (confirm($translate.instant('alert.are_you_sure'))) {
        user.delete().then(() => {
          $scope.pds.pg.items = $scope.pds.pg.items.filter(x => (x !== user));
        });
      }
    };
    $scope.resetPassword = (user) => {
      $http({
        method: 'GET',
        url: '/admin/users/notify',
        params: { user_id: user.id },
        headers: { 'Tenant-Id': user.organizationId },
      }).then(
        () => alert($translate.instant('alert.message_sent')),
        response => alert(response.data.error),
      );
    };
  },
);
