angular.module('RocketWash').component('rwReservationRightMenuStatistics', {
  templateUrl: 'components/rw-reservation/right-menu/statistics/index.slim',
  bindings: {
  },
  controller: function (
    $scope, reservationForm,
  ) {
    $scope.rf = reservationForm;

    $scope.$watch('rf.formTab.viewState.rightMenuStatistics', (value) => {
      if (value) {
        reservationForm.marketing.loadAll();
      };
    });

  },
});
