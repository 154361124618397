angular.module('RocketWash').factory(
  'MobileRequestLocation',
  ($http, railsResourceFactory, userSession) => {
    const resource = railsResourceFactory({
      url: (context) => { return "/" + (context.queryRole || userSession.controllerScope()) + "/mobile_request_locations/" + (context.id || '') },
      name: 'mobile_request_location',
    });

    angular.extend(resource, {
      all(options) {
        return this.$post(
          `${this.resourceUrl()}/all`,
          options,
          { rootWrapping: false },
        );
      },
    });

    return resource;
  },
);
