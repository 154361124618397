import { env } from '@env';

angular.module('RocketWash').controller('LayoutController', (
  $scope, $rootScope, $timeout, $translate, $state, userSession,
  alertService, serverConstants, accessMenuService,
  rwHelpArticlesService, screenService,
) => {
  $scope.env = env;
  $scope.screenService = screenService;
  $scope.rwHelpArticlesService = rwHelpArticlesService;
  $scope.serverConstants = serverConstants;
  $scope.alertService = alertService;
  $scope.userSession = userSession;
  $scope.$translate = $translate;
  $scope.$state = $state;
  $scope.currentTime = moment().format('HH:mm');
  $scope.accessMenuService = accessMenuService;
  $scope.sideNavIs = { opened: true };
  $scope.isClientCabinet = () => {
    return !!$state.current.name.match(/client_cabinet/);
  };

  $scope.currentStateClass = () => {
    return $state.current.name.replace(/\./g, '__');
  };

  const tick = function () {
    $scope.$apply(() => {
      $scope.currentTime = moment().format('HH:mm');
    });
  };
  setInterval(tick, 60 * 1000);

  userSession.promise.then(() => {
    const balance = userSession.organization.financial_center_rocketwash_balance.amount;
    $scope.negativeBalance = balance < 0;
  });
});
