angular.module('RocketWash').service('rwHelpArticlesService', (
  $rootScope, HelpArticle, alertService, $translate,
) => {
  const data = {
    helpArticles: [],
  };

  const service = {
    open: false,
    editorOpen: false,
    init: function () {
      HelpArticle.query().then((helpArticles) => {
        data.helpArticles = helpArticles;
      });
    },
    currentArticle: function () {
      return data.helpArticles.find((ha) => {
        return window.location.hash.match(ha.pageRegexp);
      });
    },
    saveCurrentArticle: function () {
      const ha = this.currentArticle();
      ha.queryRole = 'admin';
      ha.saving = true;
      ha.save().then(() => {
        console.log('Help article was saved!');

        alertService.show({
          text: $translate.instant('common.save_success'),
          type: 'success',
        });
      }).finally(() => { ha.saving = false; });
    },
    toggle: function () {
      this.open = !this.open;
      this.editorOpen = this.open;
    },
  };

  $rootScope.$on('userSession:loaded', () => {
    service.init();
  });

  $rootScope.$on('$stateChangeStart', () => {
    service.open = false;
  });

  return service;
});
