angular.module('RocketWash').factory('FinancialPeriod', (
  railsResourceFactory, userSession, TenantSwitchInterceptor,
) => {
  const Resource = railsResourceFactory({
    url: (context) => { return "/" + (context.queryRole || userSession.controllerScope()) + "/financial_periods/" + (context.id || '') },
    name: 'financial_period',
  });

  Resource.addInterceptor(TenantSwitchInterceptor);

  Resource.prototype.label = function() {
    return moment(this.dateStart).format('YYYY MMMM');
  };

  return Resource;
});

