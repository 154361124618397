angular.module('RocketWash').factory(
  'ServiceLocation',
  (
    railsResourceFactory, userSession, $http, TenantSwitchInterceptor,
    DuplicatableMixin,
  ) => {
    const resource = railsResourceFactory({
      url: context => `/${context.queryRole || userSession.controllerScope()}/service_locations/${context.id || ''}`,
      name: 'service_location',
    });

    angular.extend(resource.prototype, DuplicatableMixin);
    resource.addInterceptor(TenantSwitchInterceptor);

    resource.prototype.clearData = function (options, context) {
      return resource.$http({
        url: `${resource.$url(this)}/clear_data`,
        method: 'POST',
        data: options,
      }, context);
    };

    resource.prototype.changePlan = function (planId) {
      const _class = Object.getPrototypeOf(this).constructor;
      return $http({
        url: `${_class.$url(this)}/change_plan`,
        method: 'POST',
        data: { plan_id: planId },
      }).finally(() => {
        return this.get();
      });
    };

    resource.prototype.generalOnlineInfo = function (data) {
      return resource.$get(`${resource.$url(this)}/general_online_info`,data);
    };

    resource.current = function () {
      return $http({
        url: `${this.$url()}/current`,
        method: 'GET',
      }).then((resp) => {
        return resp.data;
      });
    };

    return resource;
  },
);
