angular.module('RocketWash').directive('rwPriceLists', () => ({
  restrict: 'E',
  templateUrl: 'directives/rw-price-lists/index.slim',
  scope: {
    car: '=',
    user: '=',
    editable: '=',
    showAll: '=',
  },
  controller: (
    $scope, $uibModal, userSession, Car, User
  ) => {
    $scope.$watch('[user.id]', () => {
      User.prototype.reassignDefaultPriceLists.apply($scope.user).then(() => {
        User.prototype.reloadAvailablePriceLists.apply($scope.user);
      });
    }, true);

    $scope.isVisible = (upl) => {
      return $scope.showAll || _.get($scope, 'car.cars_car_types', []).map(x => x.car_types_group_id).includes(upl.car_types_group_id);
    };

    $scope.editPriceLists = () => {
      $uibModal.open({
        templateUrl: 'directives/rw-price-lists/form.slim',
        controller: 'RWPriceListsCarTypesGroupFormController',
        resolve: {
          user: $scope.user,
          car: $scope.car,
        }
      });
    }
  },
}));
