angular.module('RocketWash').config(function($stateProvider) {
  return $stateProvider.state('app.wash.control.boxes_control', {
    url: '/boxes_control',
    tab: 'boxes_control',
    data: {
      permission: 'BoxesControlTab'
    },
    views: {
      'content@app.wash.control': {
        controller: 'WashControlBoxesControlController',
        templateUrl: 'pages/wash/control/boxes_control/index.slim'
      }
    }
  });
});
