angular.module('RocketWash').factory(
  'LaneCameraEvent',
  ($http, railsResourceFactory, userSession) => {
    const resource = railsResourceFactory({
      url: (context) => { return "/" + (context.queryRole || userSession.controllerScope()) + "/lane_camera_events/" + (context.id || '') },
      name: 'lane_camera_event',
    });

    resource.nearest = (options) => {
      return resource.$get(
        `${resource.resourceUrl()}/nearest`,
        options,
        { rootWrapping: false },
      );
    };


    return resource;
  },
);
