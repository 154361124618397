

angular.module('RocketWash').controller('CommonHeaderOrganizationController', function($scope, userSession, ServiceLocation, $http, $state, $stateParams) {
  return userSession.promise.then(function(newUserSession) {
    newUserSession.previousServiceLocationId = newUserSession.service_location_id;
    ServiceLocation.query().then(function(organizationServiceLocations) {
      return $scope.organizationServiceLocations = organizationServiceLocations;
    });
    return $scope.serviceLocationSelected = function() {
      var slId;
      slId = newUserSession.service_location_id;
      if (parseInt(slId) !== parseInt(newUserSession.previousServiceLocationId)) {
        newUserSession.previousServiceLocationId = slId;
        return $http.post("/organization/service_locations/assign_organization_director", {
          service_location_id: slId
        }).then(function() {
          userSession.reload();
          return $state.transitionTo('organization.dashboard.info', $stateParams, {
            reload: true,
            inherit: false,
            notify: true
          });
        });
      }
    };
  });
});
