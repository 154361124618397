angular.module('RocketWash').controller('WashCounterpartiesEditFinancialOperationsController', (
  $scope, total, viewStore, FinancialOperation,
) => {
  $scope.pds = $scope.pageDataService.new({
    page: 'wash_financial_operations',
    filters: {
      attributes: [
        { name: 'date', type: 'datetime' },
        { name: 'ordinal', type: 'number' },
        { name: 'amount', type: 'number' },
        { name: 'record_type', type: 'string' },
        { name: 'comment', type: 'string' },
        { name: 'document', type: 'string' },
      ],
      scopes: ['record_type', 'comment', 'document'],
    },
    pg: {
      url: '/wash/financial_operations',
      model: FinancialOperation,
      query: {
        scope_to_service_location: false,
        fromToField: 'date',
        predicate: '-date',
        customer_id: viewStore.data.contractor.id,
        per: 10,
        page: 1,
      },
    },
    definePaginateMethodsOn: $scope,
  });

  $scope.contractor = viewStore.data.contractor;
  $scope.total = total;
});
