angular.module('RocketWash').config(function($stateProvider) {
  return $stateProvider.state('app.wash.charts.client_activity_categories', {
    url: '/client_activity_categories',
    tab: 'client_activity_categories',
    data: {
      permission: 'ClientActivityCategoriesReportTab'
    },
    views: {
      'content@app.wash.charts': {
        controller: 'WashChartsClientActivityCategoriesController',
        templateUrl: 'pages/wash/charts/client_activity_categories/index.slim'
      }
    }
  });
});
