angular.module('RocketWash').controller('WashSettingsKassaFormController', (
    $scope, rwApi, Device, resolvedDevice, serverConstants, userSession
) => {
    $scope.kassaDevice = new Device(resolvedDevice)

    $scope.serverConstants = serverConstants
    $scope.kassaDevice.type = serverConstants.device_types[0]

    $scope.save = () => {
        $scope.formDisabled = true
        $scope.kassaDevice.type = _.camelCase($scope.kassaDevice.type)
        $scope.kassaDevice.type = $scope.kassaDevice.type.charAt(0).toUpperCase() + $scope.kassaDevice.type.slice(1)
        if ($scope.kassaDevice.type === 'KkmDevice') $scope.kassaDevice.settings = { has_kkm_license: false }

        rwApi.sendRequest({
            method: 'POST',
            path: 'devices',
            data: {
                service_location_id: userSession.service_location_id,
                type: $scope.kassaDevice.type,
                settings: $scope.kassaDevice.settings
            }
        }).then(device => {
            if (_.isEmpty(device.errors)) {
                $scope.$dismiss()
            } else {
                $scope.errors = { create_device: device.errors }
            }
        }).finally(() => { $scope.formDisabled = false })
    }
})
